const NDASH = '&dash;',
    filterLogicConstants = {
        OR: '$or',
        AND: '$and',
        EQ: '$eq',
        NOT: '$not',
        LT: '$lt',
        LE: '$le',
        GT: '$gt',
        GE: '$ge',
        IN: '$in',
        ANY: '$any',
        idToIn: {
            Demographic$Age: 'Age',
            Operating$System: 'Operating system',
            Demographic$Gender: 'Gender',
            Frequency: 'Frequency',
            Demographic$Region: 'Region',
            Question$Choice: 'Question choice',
            Beacon$Pool: 'Beacon pool',
            Custom$Variable: 'Custom variable',
            ImpressionParameterGroups: 'Impression parameter groups',
            'Rounded Days Since Last Exposure': 'Rounded days since last exposure',
            Demographic$State: 'State',
            // It seems that this is from backend...
            // eslint-disable-next-line camelcase
            rural_urbanicity: 'Urbanicity (Rural)',
            // eslint-disable-next-line camelcase
            smalltown_urbanicity: 'Urbanicity (Smalltown)',
            // eslint-disable-next-line camelcase
            is_control: 'Is control',
            ControlFor$: 'Control for exposed beacon pool',
            ControlBeacon$: 'Control beacon',
            ControlBeaconPool$: 'Control beacon pool',
            'Date (UTC)': 'Survey completion date',
        },
        inToId: {
            Age: 'Demographic$Age',
            'Operating System': 'Operating$System',
            Gender: 'Demographic$Gender',
            Frequency: 'Frequency',
            Region: 'Demographic$Region',
            'Beacon Pool': 'Beacon$Pool',
            'Question Choice': 'Question$Choice',
            'Custom Variable': 'Custom$Variable',
            'Impression Parameter Groups': 'ImpressionParameterGroups',
        },
        criteriaNodeLogics: {
            $eq: true,
            $lt: true,
            $gt: true,
            $le: true,
            $ge: true,
            $in: true,
            $any: true,
        },
        comparisonOperatorMap: {
            $eq: '',
            $lt: '<',
            $le: '<=',
            $gt: '>',
            $ge: '>=',
            $not: '!',
        },
        filterLogicToStringMap: {
            $and: 'and',
            $or: 'or',
            $eq: 'equals',
            $not: 'is not',
        },
        criteriaTypes: {
            GENDER: 'Demographic$Gender',
            AGE: 'Demographic$Age',
            QUESTION: 'Question$Choice',
            CUSTOM_VARIABLE: 'Custom$Variable',
            BEACON_POOL: 'Beacon$Pool',
            COLLECTION_PERIOD: 'Demographic$Period',
            CREATIVE_GROUP: 'Demographic$Creative_group',
            STATE: 'Demographic$State',
            OPERATING_SYSTEM: 'Operating$System',
            FREQUENCY: 'Frequency',
            CUSTOM: 'Custom',
            REGION: 'Demographic$Region',
            IMPRESSION_PARAMETER_GROUPS: 'ImpressionParameterGroups',
            CONTROL: 'is_control',
            ROUNDED_DAYS_SINCE_LAST_EXPOSURE: 'Rounded Days Since Last Exposure',
            SURVEY_COMPLETION_DATE: 'Date (UTC)',
            METRO_AREA: 'Metro Area',
            MEDIAN_INCOME_RANGE_OF_POSTAL_CODE: 'MedianIncomeRangeOfPostalCode',
        },
        Demographic$AgeOptions: [{
            id: '13 to 17',
            set: true,
            name: '13' + NDASH + '17 yrs',
        },
        {
            id: '18 to 24',
            set: true,
            name: '18' + NDASH + '24 yrs',
        },
        {
            id: '25 to 34',
            set: true,
            name: '25' + NDASH + '34 yrs',
        },
        {
            id: '35 to 44',
            set: true,
            name: '35' + NDASH + '44 yrs',
        },
        {
            id: '45 to 54',
            set: true,
            name: '45' + NDASH + '54 yrs',
        },
        {
            id: '55 to 64',
            set: true,
            name: '55' + NDASH + '64 yrs',
        },
        {
            id: '65 and over',
            set: true,
            name: '65+ yrs',
        }],
        Demographic$GenderOptions: [{
            id: '',
            name: 'Either',
            disabled: true,
            isAvailable: false,
        },
        {
            id: 'male',
            name: 'Male',
            isAvailable: true,
        },
        {
            id: 'female',
            name: 'Female',
            isAvailable: true,
        }],
        Demographic$StateOptions: [{
            name: 'Alabama',
            id: 'AL',
        },
        {
            name: 'Alaska',
            id: 'AK',
        },
        {
            name: 'Arizona',
            id: 'AZ',
        },
        {
            name: 'Arkansas',
            id: 'AR',
        },
        {
            name: 'California',
            id: 'CA',
        },
        {
            name: 'Colorado',
            id: 'CO',
        },
        {
            name: 'Connecticut',
            id: 'CT',
        },
        {
            name: 'Delaware',
            id: 'DE',
        },
        {
            name: 'Dist. of Columbia',
            id: 'DC',
        },
        {
            name: 'Florida',
            id: 'FL',
        },
        {
            name: 'Georgia',
            id: 'GA',
        },
        {
            name: 'Hawaii',
            id: 'HI',
        },
        {
            name: 'Idaho',
            id: 'ID',
        },
        {
            name: 'Illinois',
            id: 'IL',
        },
        {
            name: 'Indiana',
            id: 'IN',
        },
        {
            name: 'Iowa',
            id: 'IA',
        },
        {
            name: 'Kansas',
            id: 'KS',
        },
        {
            name: 'Kentucky',
            id: 'KY',
        },
        {
            name: 'Louisiana',
            id: 'LA',
        },
        {
            name: 'Maine',
            id: 'ME',
        },
        {
            name: 'Maryland',
            id: 'MD',
        },
        {
            name: 'Massachusetts',
            id: 'MA',
        },
        {
            name: 'Michigan',
            id: 'MI',
        },
        {
            name: 'Minnesota',
            id: 'MN',
        },
        {
            name: 'Mississippi',
            id: 'MS',
        },
        {
            name: 'Missouri',
            id: 'MO',
        },
        {
            name: 'Montana',
            id: 'MT',
        },
        {
            name: 'Nebraska',
            id: 'NE',
        },
        {
            name: 'Nevada',
            id: 'NV',
        },
        {
            name: 'New Hampshire',
            id: 'NH',
        },
        {
            name: 'New Jersey',
            id: 'NJ',
        },
        {
            name: 'New Mexico',
            id: 'NM',
        },
        {
            name: 'New York',
            id: 'NY',
        },
        {
            name: 'North Carolina',
            id: 'NC',
        },
        {
            name: 'North Dakota',
            id: 'ND',
        },
        {
            name: 'Ohio',
            id: 'OH',
        },
        {
            name: 'Oklahoma',
            id: 'OK',
        },
        {
            name: 'Oregon',
            id: 'OR',
        },
        {
            name: 'Pennsylvania',
            id: 'PA',
        },
        {
            name: 'Rhode Island',
            id: 'RI',
        },
        {
            name: 'South Carolina',
            id: 'SC',
        },
        {
            name: 'South Dakota',
            id: 'SD',
        },
        {
            name: 'Tennessee',
            id: 'TN',
        },
        {
            name: 'Texas',
            id: 'TX',
        },
        {
            name: 'Utah',
            id: 'UT',
        },
        {
            name: 'Vermont',
            id: 'VT',
        },
        {
            name: 'Virginia',
            id: 'VA',
        },
        {
            name: 'Washington',
            id: 'WA',
        },
        {
            name: 'West Virginia',
            id: 'WV',
        },
        {
            name: 'Wisconsin',
            id: 'WI',
        },
        {
            name: 'Wyoming',
            id: 'WY',
        }],
        Operating$SystemOptions: [{
            id: 'windows',
            name: 'Windows',
        },
        {
            id: 'macos',
            name: 'Mac OS X',
        },
        {
            id: 'ios',
            name: 'iOS',
        },
        {
            id: 'android',
            name: 'Android',
        },
        {
            id: 'other',
            name: 'Other',
        }],
        Demographic$RegionOptions: [{
            id: 'Northeast',
            name: 'Northeast',
        },
        {
            id: 'South',
            name: 'South',
        },
        {
            id: 'Midwest',
            name: 'Midwest',
        },
        {
            id: 'West',
            name: 'West',
        }],
        MedianIncomeRangeOfPostalCodeOptions:
            [{
                name: '$1 - 25,000',
                id: '$1 - 25,000',
            },
            {
                name: '$25,001 - $50,000',
                id: '$25,001 - $50,000',
            },
            {
                name: '$50,001 - $75,000',
                id: '$50,001 - $75,000',
            },
            {
                name: '$75,001 - $100,000',
                id: '$75,001 - $100,000',
            },
            {
                name: '$100,000+',
                id: '$100,000+',
            },
            {
                name: 'No Data',
                id: 'Not Available',
            }],
        urbanicityOptions:
            [{
                name: 'Rural',
                id: 'Rural',
            },
            {
                name: 'Small Town',
                id: 'Small Town',
            },
            {
                name: 'Large City',
                id: 'Large City',
            },
            {
                name: 'No Data',
                id: 'Not Available',
            }],

    };
export default filterLogicConstants;
