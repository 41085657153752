import './intro-statement';
import './rating';
import './ranking';
import './long-free-response';
import './click-map';
import './grid';
import './select';
import './numeric';

angular.module('questionTypes.service', [
    'questionTypes.introStatement',
    'questionTypes.rating',
    'questionTypes.ranking',
    'questionTypes.longFreeResponse',
    'questionTypes.clickMap',
    'questionTypes.grid',
    'questionTypes.select',
    'questionTypes.numeric',
])
    .service('questionTypesService', questionTypesService);
questionTypesService.$inject = [
    'questionTypes.introStatement.service',
    'questionTypes.select.singleSelect',
    'questionTypes.select.multiSelect',
    'questionTypes.rating.GlyphService',
    'questionTypes.rating.SliderService',
    'questionTypes.ranking.service',
    'questionTypes.longFreeResponse.service',
    'questionTypes.clickMap.service',
    'questionTypes.grid.service',
    'questionTypes.select.dropdownSelect',
    'questionTypes.select.comboboxSelect',
    'questionTypes.select.sliderSelect',
    'questionTypes.numeric.service',
];

function questionTypesService(
    questionTypeIntroStatementService,
    questionTypeSelectSingleService,
    questionTypeSelectMultiService,
    questionTypeRatingGlyphService,
    questionTypeRatingSliderService,
    questionTypeRankingService,
    questionTypeLongFreeResponseService,
    questionTypeClickMapService,
    questionTypeGridService,
    questionTypeSelectDropdownService,
    questionTypeSelectComboboxService,
    questionTypeSelectSliderService,
    questionTypeNumericService
) {
    return {
        name: 'Question Types',
        typeList: [
            questionTypeIntroStatementService,
            questionTypeSelectSingleService,
            questionTypeSelectMultiService,
            questionTypeRatingGlyphService,
            questionTypeRatingSliderService,
            questionTypeRankingService,
            questionTypeLongFreeResponseService,
            questionTypeClickMapService,
            questionTypeGridService,
            questionTypeSelectDropdownService,
            questionTypeSelectComboboxService,
            questionTypeSelectSliderService,
            questionTypeNumericService,
        ],
    };
}
