angular
    .module('analysisPlanOverview.view', [])
    .directive('analysisPlanOverview', analysisPlanOverview);

function analysisPlanOverview() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/analysis-plan-overview.html',
    };
}
