var regions = [{
        id: 'americas',
        name: 'Americas',
    },
    {
        id: 'europe',
        name: 'Europe',
    },
    {
        id: 'asiaOceania',
        name: 'Asia/Other',
    }],
    countries = [{
        id: 'DZ',
        name: 'Algeria',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'AR',
        name: 'Argentina',
        region: 'americas',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'AU',
        name: 'Australia',
        region: 'asiaOceania',
        basic: true,
        extended: true,
        advanced: true,
    },
    {
        id: 'AT',
        name: 'Austria',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'BE',
        name: 'Belgium ',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'BO',
        name: 'Bolivia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'BR',
        name: 'Brazil',
        region: 'americas',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'BG',
        name: 'Bulgaria',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'CA',
        name: 'Canada',
        region: 'americas',
        basic: true,
        extended: true,
        advanced: true,
    },
    {
        id: 'CL',
        name: 'Chile',
        region: 'americas',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'CN',
        name: 'China ',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'CO',
        name: 'Colombia',
        region: 'americas',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'CR',
        name: 'Costa Rica',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'HR',
        name: 'Croatia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'CZ',
        name: 'Czech',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'DK',
        name: 'Denmark',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'EC',
        name: 'Ecuador',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'EG',
        name: 'Egypt',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'EE',
        name: 'Estonia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'FI',
        name: 'Finland',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'FR',
        name: 'France',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'DE',
        name: 'Germany',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'GR',
        name: 'Greece',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'GT',
        name: 'Guatemala',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'HK',
        name: 'Hong Kong',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'HU',
        name: 'Hungary',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'IN',
        name: 'India',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'ID',
        name: 'Indonesia',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'IE',
        name: 'Ireland',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'IL',
        name: 'Israel',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: false,
    },
    {
        id: 'IT',
        name: 'Italy',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'JP',
        name: 'Japan',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'KZ',
        name: 'Kazakhstan',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'KE',
        name: 'Kenya',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'LV',
        name: 'Latvia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'LT',
        name: 'Lithuania',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'LU',
        name: 'Luxembourg',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'MY',
        name: 'Malaysia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'MX',
        name: 'Mexico',
        region: 'americas',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'MA',
        name: 'Morocco',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'NL',
        name: 'Netherlands',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'NZ',
        name: 'New Zealand ',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'NG',
        name: 'Nigeria',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'NO',
        name: 'Norway',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PK',
        name: 'Pakistan',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PY',
        name: 'Paraguay',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PE',
        name: 'Peru',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PH',
        name: 'Philippines',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PL',
        name: 'Poland',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PT',
        name: 'Portugal',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'PR',
        name: 'Puerto Rico',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'RO',
        name: 'Romania',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'RU',
        name: 'Russia',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'RS',
        name: 'Serbia',
        region: 'other',
        etwork: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'SG',
        name: 'Singapore',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'SK',
        name: 'Slovakia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'SI',
        name: 'Slovenia',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'ZA',
        name: 'South Africa',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'KR',
        name: 'South Korea',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'ES',
        name: 'Spain',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'SE',
        name: 'Sweden',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'CH',
        name: 'Switzerland',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'TW',
        name: 'Taiwan',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'TH',
        name: 'Thailand',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'TR',
        name: 'Turkey',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'UK',
        name: 'United Kingdom',
        region: 'europe',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'UA',
        name: 'Ukraine',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'AE',
        name: 'United Arab Emirates',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'UY',
        name: 'Uruguay',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'US',
        name: 'United States',
        region: 'americas',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'VE',
        name: 'Venezuela',
        region: 'other',
        basic: false,
        extended: false,
        advanced: true,
    },
    {
        id: 'VN',
        name: 'Vietnam',
        region: 'asiaOceania',
        basic: false,
        extended: false,
        advanced: true,
    }];

const countryOptions = {
    regions,
    countries,
};

export default countryOptions;
