import 'ng-file-upload/dist/angular-file-upload-all';
import '../common-components/sv-clipboard';
import '../common-components/sv-filters';
import '../question-types/question-type-groups';
import '../survey-dashboard/survey-dashboard-service';
import '../survey/api-survey-service';
import surveyConstants from '../survey/survey-constants';
import '../survey/survey-statuses';

// Annie Fire!
angular.module('SurveyAdminModule', [
    'angularFileUpload',
    'svClipboard',
    'svFilters',
    'apiSurveyService',
    'questionTypeGroups.service',
    'surveyToStringService',
])
    .controller('surveyAdminCtrl', surveyAdminCtrl);
surveyAdminCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$notify',
    'questionTypeGroupsService',
    'surveyService',
    '$dashboardService',
    'surveyStatusesService',
];

/**
 * Survey admin ctrl
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} $timeout
 * @param {object} $notify
 * @param {object} QTGS
 * @param {object} surveyService
 * @param {object} $dashboardService
 * @param {object} surveyStatusesService
 */
function surveyAdminCtrl($scope, $rootScope, $timeout, $notify, QTGS, surveyService, $dashboardService, surveyStatusesService) {
    $scope.ux = {
        surveyView: 'quote',
        editSampleSize: false,
        editingTotalPanelCosts: false,
        panelCostCents: 0,
        overrideAmount: false,
        invite: null,
        surveyText: '',
        warningDismissed: false,
        newMessage: '',
        cancellationReason: '',
        cancellationComment: '',
        showSurveyOverrides: false,
        adminBarExpanded: false,
        urlTypes: [{
            id: 'rawUrl',
            label: 'Raw results URL',
            description: 'Raw results URL',
        }],
    };
    $scope.appData = {
        currentUser: null,
    };
    $scope.questionTypes = QTGS.types;
    $scope.questionTypeName = QTGS.questionTypeName;
    $scope.questionTypeGroups = QTGS.groupList;
    $scope.takeParams = {
        panelist: '[ID]',
    };
    $scope.take = {
        storedParams: '',
        allowMultiple: false,
        panelConfigs: [],
    };
    $scope.currentPanelConfig = undefined;
    $scope.saveSampleSize = saveSampleSize;
    $scope.overrideTotal = overrideTotal;
    $scope.setWholesaleCpi = setWholesaleCpi;
    $scope.changeSurveyName = changeSurveyName;
    $scope.cancelSurvey = cancelSurvey;
    $scope.canRunAgain = canRunAgain;
    $scope.runAgain = runAgain;
    $scope.isBasicSurvey = isBasicSurvey;
    $scope.isExtendedSurvey = isExtendedSurvey;
    $scope.isAdvancedSurvey = isAdvancedSurvey;
    $scope.toggleAdminPanel = toggleAdminPanel;
    init();

    function init() {
        surveyService.listPeriods($rootScope.survey.uuid).then(function(periods) {
            $scope.$evalAsync(() => {
                $scope.fullCollectionPeriods = periods;
            });
        });

        if ($rootScope.survey.clientDeadline) {
            $rootScope.survey.clientDeadline = new Date($rootScope.survey.clientDeadline);
        }

        if ($rootScope.survey.surveyTakeConfiguration) {
            $scope.take.storedParams = $rootScope.survey.surveyTakeConfiguration.rememberParameters;
            $scope.take.allowMultiple = $rootScope.survey.surveyTakeConfiguration.allowMultiple;
        }
        $rootScope.survey.status = $rootScope.survey.status || surveyStatusesService.getStatus($rootScope.survey);
    }

    /**
     * Save updated sample size
     *
     * @param {number} newSampleSize
     */
    function saveSampleSize(newSampleSize) {
        surveyService.updateLatestPeriod($rootScope.survey.uuid, {
            sampleSize: newSampleSize,
        }).then(function(result) {
            $rootScope.survey.latestCollectionPeriod = result;
        });
    }

    /**
     * Override total price for study
     *
     * @param {number} totalOverride
     */
    function overrideTotal(totalOverride) {
        surveyService.updateLatestPeriod($rootScope.survey.uuid, {
            totalOverrideCents: totalOverride * 100,
        }).then(function(result) {
            $rootScope.survey.latestCollectionPeriod = result;
        });
    }

    /**
     * Set CPI for panel link
     *
     * @param {number} wholesaleCpi
     */
    function setWholesaleCpi(wholesaleCpi) {
        surveyService.updateSurvey($rootScope.survey.uuid, {
            wholesaleCpiCents: wholesaleCpi * 100,
        }).then(function(result) {
            $rootScope.survey.wholesaleCpiCents = result.wholesaleCpiCents;
        });
    }

    /**
     * Change name of survey
     *
     * @param {string} newName
     */
    function changeSurveyName(newName) {
        surveyService.updateSurvey($rootScope.survey.uuid, {
            name: newName,
        }).then(function(survey) {
            $timeout(function() {
                $rootScope.survey.name = survey.name;
            }, 1);
        });
    }

    // Dashboard

    function cancelSurvey() {
        var params = {
            cancel: 'true',
            cancellationReason: $scope.ux.cancellationReason,
        };
        if ($scope.ux.cancellationComment) {
            params.cancellationComment = $scope.ux.cancellationComment;
        }

        surveyService.updateSurvey($rootScope.survey.uuid, params).then(function() {
            $rootScope.survey.canceled = true;
            $('#survey-cancel-modal').modal('hide');
        });
    }

    /**
     * Can this study be re-run?
     *
     * @returns {boolean}
     */
    function canRunAgain() {
        return $rootScope.survey.completed || $rootScope.survey.latestCollectionPeriod.completed;
    }

    /**
     * Run this again by creating a period
     *
     */
    function runAgain() {
        var sampleSize = $rootScope.survey.latestCollectionPeriod.sampleSize;
        surveyService.createPeriod($rootScope.survey.uuid, {
            sampleSize: sampleSize,
            autoPromptPayment: true,
        }).then(function(result) {
            $rootScope.survey.latestCollectionPeriod = result;
            $notify.success('New collection period was created!');
        }, function() {
            $notify.error('Could not create collection period. Is previous one still running?');
        });
    }

    /**
     * Is this a basic survey
     *
     * @param {object} survey
     * @returns {boolean}
     */
    function isBasicSurvey(survey) {
        return survey.surveyPackage === surveyConstants.PACKAGES.BASIC;
    }

    /**
     * Is this an extended survey
     *
     * @param {object} survey
     * @returns {boolean}
     */
    function isExtendedSurvey(survey) {
        return survey.surveyPackage === surveyConstants.PACKAGES.EXTENDED;
    }

    /**
     * Is this an advanced survey?
     *
     * @param {object} survey
     * @returns {boolean}
     */
    function isAdvancedSurvey(survey) {
        return survey.surveyPackage === surveyConstants.PACKAGES.ADVANCED;
    }

    /**
     * Toggle admin panel
     */
    function toggleAdminPanel() {
        $rootScope.ux.adminBarExpanded = !$scope.ux.adminBarExpanded;
    }
}
