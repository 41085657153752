import '../survey/api-survey-service';
angular.module('surveyThankYou.ctrl', ['apiSurveyService'])
    .directive('surveyThankYou', surveyThankYou)
    .controller('SurveyThankYouCtrl', SurveyThankYouCtrl);

/**
 *
 */
function surveyThankYou() {
    return {
        restrict: 'A',
        templateUrl: 'base-templates/thank-you.html',
        controller: 'SurveyThankYouCtrl',
        controllerAs: 'ThankYouVM',
    };
}
SurveyThankYouCtrl.$inject = [
    '$rootScope',
    '$timeout',
    'surveyService',
];

/**
 * @param $rootScope
 * @param $timeout
 * @param surveyService
 */
function SurveyThankYouCtrl($rootScope, $timeout, surveyService) {
    var ThankYouVM = this;
    ThankYouVM.ux = {
        saveDescriptionStatus: 'none',
        ready: false,
    };

    ThankYouVM.survey = $rootScope.survey;
    ThankYouVM.auth = $rootScope.auth;
    ThankYouVM.ux.surveyDescription = $rootScope.survey.name || '';
    ThankYouVM.f = {
        referrer: '',
        experienceRating: '',
        features: '',
        process: '',
    };

    $rootScope.rootux.saveSurveyStatus = 'unsaved';

    ThankYouVM.saveDescription = saveDescription;

    function saveDescription() {
        if (ThankYouVM.ux.saveDescriptionStatus === 'none') {
            ThankYouVM.ux.saveDescriptionStatus = 'saving';

            surveyService.updateSurvey($rootScope.survey.uuid, {
                name: ThankYouVM.ux.surveyDescription,
            }).then(function() {
                $rootScope.survey.name = ThankYouVM.ux.surveyDescription;
                ThankYouVM.ux.saveDescriptionStatus = 'saved';
                $timeout(function() {
                    ThankYouVM.ux.saveDescriptionStatus = 'none';
                }, 2000);
            });
        }
    }

    // Log the conversion in Google Analytics
    // U.fireGooglePixel("nSYSCJaiwQYQgs2H3wM", ($rootScope.survey.price && $rootScope.survey.price.total) || 0.0);

    ThankYouVM.ux.ready = true;
}
