import '../common-components/pub-sub';
angular.module('collectionPeriods.panel', ['pubSub'])
    .directive('collectionPeriodPanel', collectionPeriodPanel)
    .controller('CollectionPeriodPanelCtrl', CollectionPeriodPanelCtrl);

function collectionPeriodPanel() {
    return {
        restrict: 'AE',
        controller: 'CollectionPeriodPanelCtrl',
        controllerAs: 'CollectionPeriodPanelVM',
        scope: {
            save: '&',
            collectionPeriodModel: '=',
            disable: '=',
        },
        templateUrl: 'dashboard-templates/results/filter-and-compare/collection-period-panel.html',
    };
}
CollectionPeriodPanelCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    '$filter',
    'pubSubService',
];

function CollectionPeriodPanelCtrl($rootScope, $scope, $timeout, $filter, pubSubService) {
    var CollectionPeriodPanelVM = this,
        previousModel,
        cleanModel;
    CollectionPeriodPanelVM.ux = {
        disableCollectionPeriodFilter: true,
        sliderOptions: {
            floor: 0,
            ceil: $rootScope.survey.collectionPeriods.length - 1,
            showTicks: true,
            hideLimitLabels: true,
            ticksTooltip: function(idx) {
                return 'Period ' + idx + ': ' + getPeriodDescription(idx);
            },
            onChange: function(id, low, high) {
                if (low === high) {
                    CollectionPeriodPanelVM.filterModel.consolidate = true;
                }
                checkPeriodModelHasChanged();
            },
            translate: function(value) {
                return ++value;
            },
        },
    };
    CollectionPeriodPanelVM.filterModel = {};
    CollectionPeriodPanelVM.checkPeriodModelHasChanged = checkPeriodModelHasChanged;
    CollectionPeriodPanelVM.resetCollectionPeriodFilters = resetCollectionPeriodFilters;
    CollectionPeriodPanelVM.applyCollectionPeriodFilters = applyCollectionPeriodFilters;

    init();

    function init() {
        pubSubService.subscribe('toggle-datasource', $scope.$id, refreshState);
        cleanModel = angular.copy($scope.collectionPeriodModel);
        refreshState();
        $scope.$on('$destroy', function() {
            pubSubService.destroy('toggle-datasource', $scope.$id);
        });
    }

    function refreshState() {
        previousModel = {
            start: CollectionPeriodPanelVM.filterModel.start,
            end: CollectionPeriodPanelVM.filterModel.end,
            consolidate: CollectionPeriodPanelVM.filterModel.consolidate,
        };
        CollectionPeriodPanelVM.filterModel.start = cleanModel.start;
        CollectionPeriodPanelVM.filterModel.end = cleanModel.end;
        CollectionPeriodPanelVM.filterModel.consolidate = cleanModel.consolidate;
        CollectionPeriodPanelVM.ux.sliderOptions.ceil = CollectionPeriodPanelVM.filterModel.end;
    }

    function resetCollectionPeriodFilters() {
        CollectionPeriodPanelVM.filterModel.start = cleanModel.start;
        CollectionPeriodPanelVM.filterModel.end = cleanModel.end;
        CollectionPeriodPanelVM.filterModel.consolidate = cleanModel.consolidate;
        applyCollectionPeriodFilters();
    }

    function applyCollectionPeriodFilters() {
        // Needed to refresh the slider after the model has been updated programmatically
        $timeout(function() {
            $scope.$broadcast('rzSliderForceRender');
            $scope.$broadcast('reCalcViewDimensions');
        }, 250);
        $scope.save()(CollectionPeriodPanelVM.filterModel, 'periods');
        checkPeriodModelHasChanged();
    }

    function getPeriodDescription(periodIdx) {
        let period = $rootScope.survey.collectionPeriods[periodIdx];
        if (!period) {
            return;
        }
        return $filter('date')(period.started, 'shortDate') + ' to ' + (period.completed ? $filter('date')(period.completed, 'shortDate') : 'running');
    }

    function checkPeriodModelHasChanged() {
        CollectionPeriodPanelVM.ux.disableCollectionPeriodFilter = previousModel.start === CollectionPeriodPanelVM.filterModel.start && previousModel.end === CollectionPeriodPanelVM.filterModel.end && previousModel.consolidate === CollectionPeriodPanelVM.filterModel.consolidate;
    }
}
