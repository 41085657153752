import '../../../../common/js/angular-util';
import '../../../../common/js/inc/sv-notify';
import U from '../../../../common/js/util';
import '../survey/api-survey-service';

adminService.$inject = [
    '$q',
    'httpDeferred',
    '$notify',
    'surveyService',
];
/**
 * Defines the AdminService
 *
 * @param {object} $q
 * @param {object} httpDeferred
 * @param {object} $notify
 * @param {object} surveyService
 * @returns {*}
 */
function adminService($q, httpDeferred, $notify, surveyService) {
    var emailTypes = [{
        id: 'analyst-reviewed',
        description: 'Analyst Reviewed',
    },
    {
        id: 'survey-submitted',
        description: 'Survey Submitted',
    },
    {
        id: 'analyst-comment',
        description: 'Analyst Comment',
    },
    {
        id: 'results-delivered',
        description: 'Results Delivered',
    }];

    var surveyInfoFields = [{
        name: 'notes',
        input: 'textarea',
        description: 'Survey notes',
        editable: true,
        maxLength: 1024,
    },
    {
        name: 'cleaningInstructions',
        input: 'textarea',
        description: 'Cleaning Instructions',
        editable: true,
        maxLength: 512,
    },
    {
        name: 'autoClean',
        input: 'checkbox',
        description: 'Auto Clean?',
        editable: true,
    },
    {
        name: 'pixelUrl',
        input: 'text',
        description: 'Pixel Url',
        editable: true,
    }];
    /**
     * Gets survey info fields.
     *
     * @returns {*}
     */
    function getSurveyInfoFields() {
        return surveyInfoFields;
    }

    /**
     * Gets email types.
     *
     * @returns {*}
     */
    function getEmailTypes() {
        return emailTypes;
    }

    /**
     * Sends client invite.
     *
     * @param {number} surveyId
     * @param {string} userEmail
     * @returns {*}
     */
    function sendClientInvite(surveyId, userEmail) {
        return httpDeferred.post(`${httpDeferred.dashboardHost}/rest/invite/create`, {
            surveyUuid: surveyId,
            email: userEmail,
            admin: true,
        });
    }

    /**
     * Tests if type is collaborator.
     *
     * @param {*} type
     * @returns {*}
     */
    function hasCollaboratorOption(type) {
        if (type === 'analyst-reviewed' || type === 'analyst-comment') {
            return true;
        }
        return false;
    }

    /**
     * Extracts targeting.
     *
     * @param {*} targetingReqs
     * @param {*} desiredReq
     * @returns {*}
     */
    function extractTargeting(targetingReqs, desiredReq) {
        var result = null;
        angular.forEach(targetingReqs, req => {
            if (desiredReq === req.attribute) {
                result = req.details;
            }
        });
        return result;
    }

    /**
     * Saves panel details.
     *
     * @param {*} currentPanelConfig
     * @param {object} survey
     * @returns {*}
     */
    function savePanelDetails(currentPanelConfig, survey) {
        if (!currentPanelConfig.panelPartner) {
            return;
        }
        return surveyService.updatePanelDetails(survey.uuid, currentPanelConfig).then(() => {
            $notify.success('Panel details saved!', {
                clear: true,
                timeout: 1500,
            });
        }, function() {
            $notify.error('Could not save panel details. Check if one with that panel partner and group already exists.', {
                clear: true,
                timeout: 1500,
            });
            return $q.reject();
        });
    }

    /**
     * Refreshes panel details.
     *
     * @param {object} survey
     * @returns {*}
     */
    function refreshPanelDetails(survey) {
        return surveyService.listPanelDetails(survey.uuid);
    }

    /**
     * Builds take param string.
     *
     * @param {*} currentPanelConfig
     * @param {*} takeParams
     * @returns {*}
     */
    function buildTakeParamString(currentPanelConfig, takeParams) {
        var params = {};

        if (currentPanelConfig.panelPartner) {
            // eslint-disable-next-line camelcase
            params.panel_id = currentPanelConfig.panelPartner;
        }

        var panelistStr = takeParams.panelist ? 'panelist_id=' + takeParams.panelist : null;
        var respondentGroupStr = currentPanelConfig.respondentGroup ? 'respondent_group=' + currentPanelConfig.respondentGroup : null;
        var paramStr = U.buildParamString(params);
        if (panelistStr && panelistStr !== 'panelist_id=[ID]') {
            if (respondentGroupStr) {
                paramStr = paramStr ? paramStr + '&' + respondentGroupStr : '?' + respondentGroupStr;
            }
            paramStr = paramStr ? paramStr + '&' + panelistStr : '?' + panelistStr;
        }

        return paramStr;
    }

    return {
        getSurveyInfoFields: getSurveyInfoFields,
        getEmailTypes: getEmailTypes,
        sendClientInvite: sendClientInvite,
        hasCollaboratorOption: hasCollaboratorOption,
        extractTargeting: extractTargeting,
        refreshPanelDetails: refreshPanelDetails,
        savePanelDetails: savePanelDetails,
        buildTakeParamString: buildTakeParamString,
    };
}
export default angular.module('surveyAdminService', [
    'angularUtil',
    'svNotify',
    'apiSurveyService',
]).service('adminService', adminService);
