const questionAnswerCutsConstants = {
    tableHeaders: [{
        name: '',
        selectAll: true,
    },
    {
        name: '&nbsp;',
        columnType: 'text',
        value: '_label',
        columnClass: 'text-bold',
    },
    {
        name: 'Question',
        tooltip: true,
        columnType: 'text',
        value: '_displayName',
    },
    {
        columnType: 'action',
        actionName: '<i class="far fa-edit"></i> Edit',
        eventName: 'update-cut-category',
    },
    {
        name: '',
        expandable: true,
        simple: true,
    }],
    tableOptions: {
        noSearch: true,
        nestedRows: [{
            nestedRowField: 'options',
            type: 'table',
            rowClass: 'overflow-visible',
            columns: [{
                iterable: true,
                columnType: 'input',
                inputType: 'checkbox',
                cellClass: 'padding-right-sm',
                model: '_selected',
            }],
        }],
    },
};

export default questionAnswerCutsConstants;
