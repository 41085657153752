import '../../../../common/js/inc/sv-notify';
import '../survey/api-survey-service';

angular.module('adminUpdateUser', [
    'svNotify', 'apiSurveyService',
])

    .controller('AdminUpdateUserCtrl', AdminUpdateUserCtrl);
AdminUpdateUserCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$notify',
    '$uibModalInstance',
    'surveyService',
];

/**
 * Admin update user controller
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $notify
 * @param {object} $uibModalInstance
 * @param {object} surveyService
 */
function AdminUpdateUserCtrl($rootScope, $scope, $notify, $uibModalInstance, surveyService) {
    var UpdateUserVM = this;
    UpdateUserVM.close = $uibModalInstance.close;
    UpdateUserVM.ux = {
        updateUserStatus: null,
        updatedUserEmail: null,
    };

    UpdateUserVM.updateSurveyOwner = updateSurveyOwner;

    function surveyUpdateUserError() {
        UpdateUserVM.ux.updateUserStatus = null;
        UpdateUserVM.ux.updatedUserEmail = '';
        $notify.error('Error updating user id, please try again');
    }

    function updateSurveyOwner() {
        if (UpdateUserVM.ux.updatedUserEmail) {
            UpdateUserVM.ux.updateUserStatus = 'updating';
            const params = {
                uuid: $scope.survey.uuid,
                userEmail: UpdateUserVM.ux.updatedUserEmail,
            };

            surveyService.updateSurvey($scope.survey.uuid, params).then(response => {
                $scope.$evalAsync(() => {
                    $rootScope.survey = response.survey;
                    $scope.survey = response.survey;
                    UpdateUserVM.close();
                });

                UpdateUserVM.ux.updateUserStatus = null;
                $notify.success('User ID updated. Please refresh to see changes', {
                    clear: true,
                    timeout: 2000,
                });
            }, surveyUpdateUserError);
        }
    }
}
