import moment from 'moment';
import '../analysis-plan.service';
import '../analysis-config-manager';
import analysisConfigDataService from '../analysis-config-data-service';

angular
    .module('analysisPlanReview.view', [
        'analysisPlan.service', 'analysisConfig.manager',
    ])
    .directive('analysisPlanReview', analysisPlanReview)
    .controller('AnalysisPlanReviewCtrl', AnalysisPlanReviewCtrl);

function analysisPlanReview() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/overview/analysis-plan-review.html',
        controller: 'AnalysisPlanReviewCtrl',
        controllerAs: 'AnalysisPlanReviewVM',
    };
}

AnalysisPlanReviewCtrl.$inject = ['$scope',
    '$rootScope',
    'analysisPlanService',
    'analysisConfigManager'];

function AnalysisPlanReviewCtrl($scope, $rootScope, analysisPlanService, analysisConfigManager) {
    var AnalysisPlanReviewVM = this;
    const analysisConfig = analysisConfigManager.get() || {};

    // VM exposed data
    AnalysisPlanReviewVM.ux = {
        ready: false,
        downloadTypes: analysisPlanService.liftDownloadTypes,
    };

    // VM exposed functions
    AnalysisPlanReviewVM.showResultsDownload = showResultsDownload;
    AnalysisPlanReviewVM.showAdminDownload = showAdminDownload;
    AnalysisPlanReviewVM.goToAnswerCoding = goToAnswerCoding;
    AnalysisPlanReviewVM.showAnswerCodingButton = showAnswerCodingButton;
    AnalysisPlanReviewVM.getLiftState = getLiftState;
    AnalysisPlanReviewVM.openDownloadLink = openDownloadLink;
    AnalysisPlanReviewVM.utcToFormattedLocalTime = utcToFormattedLocalTime;

    init();

    function init() {
        analysisConfigDataService.getLiftResults(analysisConfig.version).then(setResults, setResults);
    }

    function setResults(results) {
        results = results && !results.error ? results : {
            analysisPlanReady: {},
            lastSuccessfulAnalysisRun: {},
        };
        $scope.$evalAsync(() => {
            AnalysisPlanReviewVM.results = results;
            AnalysisPlanReviewVM.ux.ready = true;
        });
    }

    function openDownloadLink(type) {
        if (!type) {
            return;
        }
        const url = AnalysisPlanReviewVM.results && AnalysisPlanReviewVM.results.lastSuccessfulAnalysisRun[type];
        if (url) {
            window.open(url);
        }
    }

    function showResultsDownload() {
        return !!AnalysisPlanReviewVM.results.lastSuccessfulAnalysisRun.clientDownloadUrl;
    }

    function showAdminDownload() {
        return !!(AnalysisPlanReviewVM.results.lastSuccessfulAnalysisRun.adminDownloadUrl && $rootScope.auth.user.isAdmin);
    }

    function showAnswerCodingButton() {
        return !(AnalysisPlanReviewVM.results.analysisPlanReady.analysisCuts && AnalysisPlanReviewVM.results.analysisPlanReady.factorLevels);
    }

    function getLiftState() {
        return analysisPlanService.getLiftState(AnalysisPlanReviewVM.results);
    }

    function goToAnswerCoding() {
        //$state.go('analysisPlan.answer-coding');
    }

    function utcToFormattedLocalTime(date) {
        return moment(moment.utc(date)).local().format('MMMM Do YYYY, h:mma');
    }
}
