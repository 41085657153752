angular.module('svFilters', []).filter('multiFilter', function() {
    return function(data, filters, property) {
        if (!data || !data.length) {
            return [];
        }
        if (!filters || !filters.length) {
            return data;
        }

        var getDeepValue = function(parentObj, propertyString) {
            var value = parentObj,
                propertyArray = propertyString.split('.');

            angular.forEach(propertyArray, function(property) {
                value = value[property];
            });
            return value;
        };

        return data.filter(function(item) {
            for (var i = 0; i < filters.length; i++) {
                var f = filters[i];
                if (f.expression) {
                    var value = getDeepValue(item, f.field),
                        type = f.type,
                        expression = f.expression;

                    // Leaving this line in case debugging is needed in the future...
                    //console.log( "type: " + f.type + " field: " + f.field + " expression: " + f.expression + ' value: ' + value );

                    if (f.type === 'numeric') {
                        if (!/</.test(expression) && !/>/.test(expression)) {
                            expression = expression.replace(/=/g, '==');
                        }
                        if (typeof value === 'string') {
                            value = parseFloat(value.replace(/\$/g, ''));
                        }
                        expression = String(value) + expression;
                        if (!eval(expression)) {
                            return false;
                        }
                    }
                    else if (type === 'alpha' && (!value || value.toLowerCase().indexOf(expression.toLowerCase()) === -1)) {
                        return false;
                    }
                }
            }
            return true; // Data item cleared all filters so return true
        });
    };
});
