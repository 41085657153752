import _ from 'lodash';
import '../common-components/pub-sub';
import filterItemService from '../filter-and-compare/filter-item.service';
import {
    setModelById, getModelById,
} from '../filter-and-compare/default-filter-service';

angular.module('filterLogic.group', [
    'pubSub',
])
    .directive('filterLogicGroup', filterLogicGroup)
    .controller('FilterLogicGroupCtrl', FilterLogicGroupCtrl);
filterLogicGroup.$inject = [
    '$timeout', 'pubSubService',
];

/**
 * @param $timeout
 * @param pubSubService
 */
function filterLogicGroup($timeout, pubSubService) {
    return {
        restrict: 'AE',
        controller: 'FilterLogicGroupCtrl',
        controllerAs: 'FilterLogicGroupVM',
        templateUrl: '/shared-templates/filter-logic-group.html',
        scope: {
            parsedCriteria: '=',
            filterLogicModel: '=',
            level: '<',
            index: '<',
            parentLogic: '<?',
            onChange: '<?',
        },
        link: (scope, elem, attr, FilterLogicGroupVM) => {
            FilterLogicGroupVM.refreshDimensions = refreshDimensions;
            init();

            function init() {
                pubSubService.subscribe('refresh-dimensions-' + scope.level, scope.$id, refreshDimensions);
                if (FilterLogicGroupVM.level) {
                    FilterLogicGroupVM.style = {
                        padding: '0 0 0 ' + (10 + (5 * FilterLogicGroupVM.level)) + 'px',
                    };
                    _.defer(() => {
                        $timeout(refreshDimensions);
                    });
                }
            }

            /**
             * @param margin
             */
            function refreshDimensions(margin) {
                const height = elem.height();
                FilterLogicGroupVM.bracketStyle.height = height - 48 - (margin || 0);
                if (FilterLogicGroupVM.level > 1) {
                    pubSubService.notify(`refresh-dimensions-${FilterLogicGroupVM.level - 1}`, [FilterLogicGroupVM.bracketStyle.height / 2]);
                }
            }
        },
    };
}
FilterLogicGroupCtrl.$inject = [
    '$scope', 'pubSubService',
];

/**
 * @param $scope
 * @param pubSubService
 */
function FilterLogicGroupCtrl($scope, pubSubService) {
    const FilterLogicGroupVM = this;
    const MAX_FILTER_ALLOWED = 8,
        MAX_FILTER_ALLOWED_ERROR_MSG = `You have exceeded the number of filter items allowed (${MAX_FILTER_ALLOWED})`;
    FilterLogicGroupVM.ux = {};
    FilterLogicGroupVM.selectedLogic = [];
    FilterLogicGroupVM.logicTypes = {
        and: 'And',
        or: 'Or',
    };
    FilterLogicGroupVM.bracketStyle = {
        height: 0,
    };
    FilterLogicGroupVM.level = parseInt($scope.level);
    FilterLogicGroupVM.groupedLogic = Array.isArray($scope.parsedCriteria);
    FilterLogicGroupVM.filterModel = _.cloneDeep($scope.filterLogicModel);
    FilterLogicGroupVM.filterModels = [];
    FilterLogicGroupVM.cleanFilterModel = _.cloneDeep($scope.filterLogicModel);

    // VM exposed functions
    FilterLogicGroupVM.refreshLogicSelection = refreshLogicSelection;
    FilterLogicGroupVM.logicIsIncomplete = logicIsIncomplete;
    FilterLogicGroupVM.addCriteria = addCriteria;
    FilterLogicGroupVM.deleteCriteria = deleteCriteria;

    init();

    function init() {
        refreshView();
        pubSubService.subscribe('refresh-view-' + $scope.level, $scope.$id, refreshView);
        pubSubService.subscribe('add-children-' + $scope.level, $scope.$id, addChildren);
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'refresh-view-' + $scope.level, 'refresh-dimensions-' + $scope.level,
            ], $scope.$id);
        });
    }

    function onChange() {
        if ($scope.onChange) {
            $scope.onChange($scope.parsedCriteria);
        }
    }

    /**
     * @param index
     * @param toRemove
     * @param children
     */
    function addChildren(index, toRemove, children) {
        $scope.parsedCriteria.splice.apply($scope.parsedCriteria, [index, toRemove].concat(children));
        refreshSelectedLogic();
    }

    function refreshView() {
        refreshSelectedLogic();
        refreshFilterModel();
    }

    function refreshSelectedLogic() {
        _.forEach($scope.parsedCriteria, (parsedCriteria, idx) => {
            if (Array.isArray(parsedCriteria) && parsedCriteria.length < 2) {
                $scope.parsedCriteria[idx] = parsedCriteria[0];
                parsedCriteria.parentLogic = _.last(FilterLogicGroupVM.selectedLogic) || '$and';
            }
            if (!Array.isArray(parsedCriteria) && idx + 1 < $scope.parsedCriteria.length) {
                parsedCriteria.parentLogic = parsedCriteria.parentLogic || '$and';
                FilterLogicGroupVM.selectedLogic.push(parsedCriteria.parentLogic);
            }
        });
        onChange();
    }

    function refreshFilterModel() {
        _.forEach($scope.parsedCriteria, (parsedCriteria, idx) => {
            if (!Array.isArray(parsedCriteria)) {
                setModelById(FilterLogicGroupVM.filterModel, parsedCriteria);
                FilterLogicGroupVM.filterModels[idx] = _.cloneDeep($scope.filterLogicModel);
                setModelById(FilterLogicGroupVM.filterModels[idx], parsedCriteria);
            }
        });
    }

    /**
     * @param margin
     */
    function refreshDimensions(margin) {
        $scope.$evalAsync(() => {
            pubSubService.notify('refresh-dimensions-' + FilterLogicGroupVM.level, [margin]);
        });
    }

    function addCriteria() {
        if ($scope.parsedCriteria.length >= MAX_FILTER_ALLOWED) {
            pubSubService.notify('filter-logic-group-error', MAX_FILTER_ALLOWED_ERROR_MSG);
            return;
        }
        $scope.parsedCriteria[0].parentLogic = $scope.parsedCriteria[0].parentLogic || '$and';
        FilterLogicGroupVM.selectedLogic.push($scope.parsedCriteria[0].parentLogic);
        $scope.parsedCriteria.push({
            parentLogic: $scope.parsedCriteria[0].parentLogic,
        });
        const prevFilterModel = _.last(FilterLogicGroupVM.filterModels);
        const newFilterModel = _.cloneDeep($scope.filterLogicModel);
        _.each(prevFilterModel, (model, modelId) => {
            newFilterModel[modelId].isAvailable = model.isAvailable;
        });
        FilterLogicGroupVM.filterModels.push(newFilterModel);
        if (FilterLogicGroupVM.level) {
            refreshDimensions();
        }
    }

    /**
     * @param criteria
     */
    function logicIsIncomplete(criteria) {
        return !criteria.logic || !criteria.criteria.length || !criteria.id;
    }

    /**
     * @param criteria
     * @param idx
     */
    function deleteCriteria(criteria, idx) {
        if (criteria.id) {
            const model = getModelById(FilterLogicGroupVM.filterModel, criteria.id);
            filterItemService.resetModel(model);
        }
        $scope.parsedCriteria.splice(idx, 1);
        if (FilterLogicGroupVM.level) {
            pubSubService.notify('refresh-view-' + (FilterLogicGroupVM.level - 1), []);
        }
        setTimeout(() => {
            pubSubService.notify('refresh-filter-item');
        });
    }

    /**
     * Updates the parsedCriteria array to change the logic type at a specified index
     *
     * @param {string} logic - The new logic to be assigned (i.e. '$or', '$and')
     * @param {integer} index - The index in the array of parsedCriteria where we want to set the logic
     */
    function setLogicAtIndex(logic, index) {
        FilterLogicGroupVM.selectedLogic[index] = logic;
        $scope.parsedCriteria[index].parentLogic = logic;
        $scope.parsedCriteria[index + 1].parentLogic = logic;
    }

    /**
     * Update the logic type at a specified index in the parsed criteria
     * Note: Always affects the node at index, and the following node.
     * Depending on the 'shape' of parsedCriteria and the index at which we're changing things, it
     * may result in adding a additional nesting level, or merging/un-nesting into the level above
     *
     * @param {string} newLogic - The new logic to be assigned (i.e. '$or', '$and')
     * @param {integer} index - The index in the array of parsedCriteria where we want to set the logic
     */
    function refreshLogicSelection(newLogic, index) {
        // Straightforward update - no nesting, and only two items
        if (!$scope.level && $scope.parsedCriteria.length < 3) {
            setLogicAtIndex(newLogic, index);
        }
        // Merge nested logic into parent logic (remove a nesting level)
        else if ($scope.parentLogic === newLogic && !index) {
            const toMerge = $scope.parsedCriteria.length < 3 ? $scope.parsedCriteria : $scope.parsedCriteria.splice(index, 1);
            FilterLogicGroupVM.selectedLogic[index] = newLogic;
            _.forEach(toMerge, criteria => {
                criteria.parentLogic = newLogic;
            });
            if ($scope.parsedCriteria.length < 3) {
                delete FilterLogicGroupVM.groupedLogic;
                FilterLogicGroupVM.style.padding = 0;
            }
            else {
                FilterLogicGroupVM.selectedLogic.splice(index, 1);
            }
            setTimeout(() => {
                pubSubService.notify(`add-children-${FilterLogicGroupVM.level - 1}`, [
                    $scope.index,
                    $scope.parsedCriteria.length < 3 ? 1 : 0,
                    toMerge,
                ]);
            }, 250);
        }
        // Create nesting level
        else if ($scope.parsedCriteria.length > 2) {
            const toNest = $scope.parsedCriteria.splice(index, 2);
            _.forEach(toNest, nested => {
                nested.parentLogic = newLogic;
            });
            $scope.parsedCriteria.splice(index, 0, toNest);
            FilterLogicGroupVM.selectedLogic.splice(index, 1);
            setTimeout(() => {
                pubSubService.notify('refresh-filter-item');
            });
        }
        // Update logic inside nested logic, but maintain nesting
        else {
            setLogicAtIndex(newLogic, index);
        }
    }
}
