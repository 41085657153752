import '@uirouter/angularjs';
import '../common-components/pub-sub';
import exportFactory from '../export/export-factory';

angular.module('chartsWidget.text', ['ui.router', 'pubSub'])
    .controller('TextChartCtrl', TextChartCtrl)
    .directive('textChart', textChart);

textChart.$inject = ['$state'];

function textChart($state) {
    var CUSTOM_REPORT = 'customReports';
    return {
        restrict: 'E',
        templateUrl: function() {
            return 'dashboard-templates/' + ($state.current.name === CUSTOM_REPORT ? 'custom-reports' : 'results') + '/text-chart-widget.html';
        },
        replace: true,
        scope: {
            question: '=',
            chartDisplayNum: '@',
            chartNum: '@',
            visualMetadata: '=',
            params: '=',
        },
        controller: 'TextChartCtrl',
        controllerAs: 'TextChartVM',
        link: function(scope, elem, attr, TextChartVM) {
            TextChartVM.exportAsPng = exportAs('png');
            TextChartVM.exportAsSvg = exportAs('svg');

            function exportAs(type) {
                return function() {
                    exportFactory.exportChart(type, elem, null, 2).then(function(response) {
                        exportFactory.download(type, response.object, TextChartVM.question.details.statement.text);
                    });
                };
            }
        },
    };
}

TextChartCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'pubSubService',
];

function TextChartCtrl($rootScope, $scope, $state, pubSubService) {
    var TextChartVM = this;

    TextChartVM.question = $scope.question;
    TextChartVM.questionDetails = TextChartVM.question.details;
    TextChartVM.title = TextChartVM.question.details.statement.text;
    TextChartVM.isCustomReportView = $state.current.name === 'customReports';
    TextChartVM.visualMetadata = $scope.visualMetadata || {};
    TextChartVM.ux = {
        fullWidth: TextChartVM.visualMetadata.fullWidth,
        chartDisplayNum: $scope.chartDisplayNum,
        widgetOptions: {
            question: $scope.question,
            chartNum: TextChartVM.chartNum,
            surveyUuid: $rootScope.survey.uuid,
            params: null,
        },
    };

    TextChartVM.toggleFullWidthDisplay = toggleFullWidthDisplay;

    init();

    function init() {
        pubSubService.subscribe('render-chart-' + $scope.chartNum, $scope.$id, loadData);
        pubSubService.flushTopic('render-chart-' + $scope.chartNum);
        $scope.$on('$destroy', function() {
            pubSubService.destroy('render-chart-' + $scope.chartDisplayNum, $scope.$id);
        });
    }

    function setChartDetails(data, details) {
        details = details || {};

        function getChartDetails(detail) {
            if (detail.minMax) {
                U.renameObjProperty(detail.minMax, 'minimum_value', 'minimumValue');
                U.renameObjProperty(detail.minMax, 'maximum_value', 'maximumValue');
            }
            return {
                averageValue: _.find(detail.metricTally, function() {
                    return true;
                }),
                minMax: detail.minMax || null,
                comparisonLabel: detail.label,
            };
        }
        // For clickmap questions, don't display plottable chart if there are no zones
        if (details.isComparison) {
            var comparisonData = _.map(data, getChartDetails);
            return {
                comparisonData: comparisonData,
            };
        }

        return getChartDetails(data);
    }

    function toggleFullWidthDisplay($event) {
        $event.preventDefault(); // Stops browser from jumping to top
        $scope.$evalAsync(function() {
            TextChartVM.ux.loading = true;
            TextChartVM.ux.fullWidth = !TextChartVM.ux.fullWidth;
            TextChartVM.visualMetadata.fullWidth = TextChartVM.ux.fullWidth;
            pubSubService.notify('toggle-full-width-' + $scope.chartNum, [TextChartVM.ux.fullWidth]);
        });
    }

    function loadData(chartData, tallyParams) {
        if (!chartData || TextChartVM.question.type !== 'numeric') {
            return;
        }
        TextChartVM.ux.widgetOptions.params = tallyParams;
        chartData.then(function(response) {
            var question = TextChartVM.question,
                chartDetails = {
                    questionType: question.type,
                    questionDetails: question.details,
                    isComparison: !!tallyParams.comparisonData,
                };
            response = response.response || response;
            TextChartVM.ux.chartDetails = setChartDetails(response, chartDetails);
        });
    }
}
