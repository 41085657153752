import '@uirouter/angularjs';
import '../common-components/pub-sub';
import '../survey/survey-validation-service';
import '../survey/survey-statuses';
import '../question-types/question-type-groups';
import '../survey-creation/survey-creation-service';

angular.module('surveyDesign.ctrl', [
    'pubSub',
    'surveyValidation.service',
    'surveyCreation.service',
    'surveyStatusesService',
    'questionTypeGroups.service',
    'surveyCreation.service',
])
    .controller('SurveyDesignCtrl', SurveyDesignCtrl);
SurveyDesignCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$state',
    'pubSubService',
    'surveyValidationService',
    'questionTypeGroupsService',
    'surveyService',
    'surveyCreationService',
    'surveyStatusesService',
];

/**
 * @param $scope
 * @param $rootScope
 * @param $timeout
 * @param $state
 * @param pubSubService
 * @param SVS
 * @param QTGS
 * @param surveyService
 * @param surveyCreationService
 * @param surveyStatusesService
 */
function SurveyDesignCtrl($scope, $rootScope, $timeout, $state, pubSubService, SVS, QTGS, surveyService, surveyCreationService, surveyStatusesService) {
    var SurveyDesignVM = this;
    SurveyDesignVM.ux = {
        active: null,
        activeJson: {},
        ready: false,
        designType: '',
        numQuestions: '',
        numQuestionOptions: [
            'Under 5',
            'Under 15',
            'Under 30',
            'Under 50',
            'Under 100',
            '100+',
            'I&rsquo;m not sure yet',
        ],
        backUrl: getUrl(),
        nextUrl: getUrl(true),
        saveInProgress: false,
        showSavingMessage: false,
        accordian: {
            closeOthers: true,
            build: false,
            attach: false,
            unsure: false,
        },
        reorderMode: false,
        imageError: null,
        itemSet: '',
    };
    SurveyDesignVM.dashboardView = $rootScope.dashboardView;
    SurveyDesignVM.openSaveModal = surveyCreationService.openSaveModal;
    SurveyDesignVM.showSurveyPreview = showSurveyPreview;
    SurveyDesignVM.errors = errors;
    SurveyDesignVM.surveyErrors = surveyErrors;
    SurveyDesignVM.next = next;
    SurveyDesignVM.isAdvancedFlow = isAdvancedFlow;
    init();

    function init() {
        var trackDesignPageTraffic = _.once(U.fireSurveyDesignTraffic);
        $scope.$on('$viewContentLoaded', function() {
            trackDesignPageTraffic();
        });

        pubSubService.subscribe('update-save-in-progress', SurveyDesignVM.$id, updateSaveInProgress);
        pubSubService.subscribe('select-active-json', SurveyDesignVM.$id, selectActiveJson);
        SurveyDesignVM.auth = $rootScope.auth;
        SurveyDesignVM.survey = $rootScope.survey;
        SurveyDesignVM.survey._submitted = SurveyDesignVM.survey.draftCompleted || SurveyDesignVM.survey.quoteRequested;
        SurveyDesignVM.ux.creativeSetNames = $rootScope.creativeSetNames;

        surveyService.listQuestions(SurveyDesignVM.survey.uuid, SurveyDesignVM.ux.creativeSetNames).then(function(questions) {
            SurveyDesignVM.survey.questions = questions;
            $scope.$evalAsync(() => {
                SurveyDesignVM.ux.ready = true;
            });

            // If in quote flow, determine which accordian tab to show
            if (!SurveyDesignVM.dashboardView && isAdvancedFlow()) {
                SurveyDesignVM.ux.numQuestions = (SurveyDesignVM.survey.surveyQuoteRequest && SurveyDesignVM.survey.surveyQuoteRequest.approximateQuestionLength) || '';

                if (SurveyDesignVM.survey.questions && SurveyDesignVM.survey.questions.length) {
                    SurveyDesignVM.ux.designType = 'builder';
                    SurveyDesignVM.ux.accordian.build = true;
                    SurveyDesignVM.ux.accordian.attach = false;
                    SurveyDesignVM.ux.accordian.unsure = false;
                }
                else if (SurveyDesignVM.survey.surveyQuoteRequest && SurveyDesignVM.survey.surveyQuoteRequest.approximateQuestionLength) {
                    SurveyDesignVM.ux.designType = 'unsure';
                    SurveyDesignVM.ux.accordian.unsure = true;
                    SurveyDesignVM.ux.accordian.attach = false;
                    SurveyDesignVM.ux.accordian.build = false;
                }
                else {
                    surveyService.getAttachments(SurveyDesignVM.survey.uuid).then(function(attachments) {
                        $rootScope.surveyAttachments = attachments;
                        SurveyDesignVM.attachments = attachments;
                        if ($rootScope.surveyAttachments.length) {
                            SurveyDesignVM.ux.designType = 'attach';
                            SurveyDesignVM.ux.accordian.attach = true;
                            SurveyDesignVM.ux.accordian.build = false;
                            SurveyDesignVM.ux.accordian.unsure = false;
                        }
                    });
                }
            }
            QTGS.updateQuestionCount($rootScope.survey.questions, $rootScope.funnel);
        });
        $scope.$on('$destroy', function() {
            pubSubService.destroy('update-save-in-progress', SurveyDesignVM.$id);
            pubSubService.destroy('select-active-json', SurveyDesignVM.$id);
        });
    }

    /**
     *
     */
    function isAdvancedFlow() {
        return $rootScope.survey.surveyPackage === 'advanced';
    }

    /**
     * @param question
     */
    function selectActiveJson(question) {
        if (angular.isDefined) {
            SurveyDesignVM.ux.active = null;
            SurveyDesignVM.ux.activeJson = question;
        }
        else {
            SurveyDesignVM.ux.activeJson = null;
        }
    }

    /**
     * @param val
     */
    function updateSaveInProgress(val) {
        if (val) {
            SurveyDesignVM.ux.showSavingMessage = true;
        }
        else {
            $timeout(() => {
                delete SurveyDesignVM.ux.showSavingMessage;
            }, 500);
        }
    }

    // Errors in a question
    /**
     * @param q
     */
    function errors(q) {
        return SVS.questionErrors(q);
    }
    // Errors in a survey
    /**
     *
     */
    function surveyErrors() {
        return surveyStatusesService.isQuoteFlow() ? [] : SVS.surveyErrors($rootScope.survey);
    }

    function setUnsure() {
        if (SurveyDesignVM.ux.designType === 'unsure') {
            SurveyDesignVM.ux.numQuestions = SurveyDesignVM.ux.numQuestions || 'I&rsquo;m not sure yet';
            surveyService.updateQuoteRequest(SurveyDesignVM.survey.uuid, {
                approximateQuestionLength: SurveyDesignVM.ux.numQuestions,
            }).then(function(response) {
                SurveyDesignVM.survey.surveyQuoteRequest = response;
            });
        }
    }

    /**
     * @param forward
     */
    function getUrl(forward) {
        return $state.href(forward ? 'sample' : 'target', surveyStatusesService.getRouteParams($rootScope.survey));
    }

    // Navigate to the next page
    function next() {
        SurveyDesignVM.ux.checkSurveyErrors = true;
        if (surveyErrors().length === 0) {
            if (isAdvancedFlow()) {
                if (SurveyDesignVM.ux.designType === 'builder' && !SurveyDesignVM.survey.questions.length) {
                    // If user clicks next without adding any questions, switch to 'unsure' option
                    SurveyDesignVM.ux.designType = 'unsure';
                }
                else if (SurveyDesignVM.ux.designType === 'attach' || (!SurveyDesignVM.ux.designType && !SurveyDesignVM.survey.questions.length)) {
                    // If user clicks next without adding any attachments, switch to 'unsure' option
                    surveyService.getAttachments(SurveyDesignVM.survey.uuid).then(function(attachments) {
                        if (!attachments || !attachments.length) {
                            SurveyDesignVM.ux.designType = 'unsure';
                        }
                        else {
                            $rootScope.survey.attachments = attachments;
                        }
                    });
                }

                if (SurveyDesignVM.ux.designType === 'unsure') {
                    setUnsure();
                }
            }
            U.fireDrafterPixels();
        }
    }

    // Show survey preview
    function showSurveyPreview() {
        U.showSurveyWall({
            preview: $rootScope.survey.uuid,
            allowClose: true,
            disallowRedirect: true,
        });
    }
}
