import _ from 'lodash';
var list = [{
        id: 'cookie',
        name: 'Cookie',
        icon: 'fas fa-code',
    }, {
        id: 'deviceId',
        name: 'Device Id (IDFA/AAID)',
        icon: 'fas fa-mobile-alt',
    }],
    map = {};
_.forEach(list, function(e) {
    map[e.id] = e;
});

const trackingMethodOptions = {
    list,
    map,
};

export default trackingMethodOptions;
