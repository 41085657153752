import _ from 'lodash';
import './../survey/api-survey-service';

angular.module('filterAndCompare.service', [
    'apiSurveyService',
])
    .service('filterAndCompareService', filterAndCompareService);
filterAndCompareService.$inject = [
];

/**
 * Main service function
 *
 * @returns {object}
 */
function filterAndCompareService() {
    /**
     * Is deprecated
     *
     * @param {object} filterModel
     * @returns {boolean}
     */
    function isDeprecatedFilterModel(filterModel) {
        // All versions of filter models are guaranteed to have a question field
        return filterModel.questionAnswer || filterModel.Question$Choice || filterModel.beaconPools || filterModel.ages;
    }

    /**
     * Get CP model
     *
     * @param {object} survey
     * @param {object} defaultFilter
     * @returns {object}
     */
    function getCollectionPeriodModel(survey, defaultFilter) {
        defaultFilter = defaultFilter || {};
        let periods = _.last(survey.collectionPeriods).currentResponses ? survey.collectionPeriods : survey.collectionPeriods.slice(0, -1),
            defaultCollectionPeriods = defaultFilter.periods || defaultFilter.Demographic$Period || defaultFilter.collectionPeriods || {},
            start,
            end,
            eligibleCollectionPeriods;
        if (periods.length < 2) {
            return;
        }
        start = defaultCollectionPeriods.start || 0;
        end = defaultCollectionPeriods.end || periods.length - 1;
        eligibleCollectionPeriods = _.map(periods, (cp, idx) => {
            return {
                id: idx + 1,
                name: 'CP ' + (idx + 1),
                startDate: new Date(cp.started),
                endDate: cp.completed ? new Date(cp.completed) : null,
                set: start <= idx && idx <= end,
            };
        });
        return {
            start: start,
            end: end,
            consolidate: !!defaultFilter.comparisonData || defaultCollectionPeriods.consolidate,
            dates: eligibleCollectionPeriods,
        };
    }

    return {
        isDeprecatedFilterModel,
        getCollectionPeriodModel,
    };
}
