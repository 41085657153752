angular.module('PipingModule', [])
    .directive('pipingView', pipingView)
    .controller('PipingCtrl', PipingCtrl);

function pipingView() {
    return {
        restrict: 'E',
        templateUrl: '/survey-templates/common-components/piping-template.html',
        controller: 'PipingCtrl',
        controllerAs: 'PipingVM',
        scope: {
            question: '=',
        },
    };
}

PipingCtrl.$inject = ['$scope'];

function PipingCtrl($scope) {
    const BASIC_QUESTION_TYPES = [
        'singleSelect',
        'multiSelect',
        'numeric',
        'longFreeResponse',
    ];
    const ADVANCED_QUESTION_TYPES = ['singleSelect', 'multiSelect'];
    const ADVANCED = 'advanced';
    const BASIC = 'basic';
    var PipingVM = this;
    PipingVM.pipingLogic = {
        show: false,
        type: 'none',
        name: null,
        json: null,
    };

    init();
    PipingVM.validJson = U.isValidJsonStr;
    PipingVM.allowBasic = allowBasic;
    PipingVM.allowAdvanced = allowAdvanced;
    PipingVM.savePiping = savePiping;

    function init() {
        // Initialize piping on question load
        if ($scope.question.details.pipingLogic) {
            var keys = _.keys($scope.question.details.pipingLogic);
            PipingVM.pipingLogic.json = JSON.stringify($scope.question.details.pipingLogic);

            if (keys.length === 1 && U.isEmptyArray($scope.question.details.pipingLogic[keys[0]])) {
                PipingVM.pipingLogic.type = BASIC;
                PipingVM.pipingLogic.name = keys[0];
            }
            else {
                PipingVM.pipingLogic.type = ADVANCED;
            }
        }
    }

    // Save piping logic to question details
    function savePiping() {
        if (PipingVM.pipingLogic.type === BASIC && PipingVM.pipingLogic.name) {
            $scope.question.details.pipingLogic = {};
            $scope.question.details.pipingLogic[PipingVM.pipingLogic.name] = [];
        }
        else if (PipingVM.pipingLogic.type === ADVANCED) {
            $scope.question.details.pipingLogic = JSON.parse(PipingVM.pipingLogic.json);
        }
        else {
            $scope.question.details.pipingLogic = undefined;
        }
    }

    // Allow basic piping
    function allowBasic() {
        return U.arrayContains(BASIC_QUESTION_TYPES, $scope.question.type);
    }

    // Allow advanced piping
    function allowAdvanced() {
        return U.arrayContains(ADVANCED_QUESTION_TYPES, $scope.question.type);
    }

    // Watch piping logic scope vars
    $scope.$watch('pipingLogic.show', savePiping);
}
