/* eslint compat/compat: "off" */
const imageExportService = {
    export: _export,
    exportClickmap: exportClickmap,
};

function _export(canvas, image, x, y, isClickmap) {
    return new Promise(function(resolve) {
        let chartImg = new Image(),
            ctx = canvas.getContext('2d');
        ctx.globalAlpha = 1;
        chartImg.onload = () => {
            if (isClickmap) {
                ctx.drawImage(chartImg, 0, 0, chartImg.width / image.scale, image.height / image.scale, x, y, image.width, image.height);
            }
            else {
                ctx.drawImage(chartImg, x, y, image.width, image.height);
            }
            resolve(canvas);
        };

        chartImg.onerror = () => {
            resolve(canvas);
        };
        chartImg.crossOrigin = 'Anonymous';
        chartImg.src = image.src;
        chartImg.width = image.width;
        chartImg.height = image.height;
    });
}

function exportClickmap(canvas, clickmap, x, y) {
    return new Promise(function(resolve) {
        // Draw background
        _export(canvas, clickmap.background, x, y).then(() => {
            // Draw heatmap
            _export(canvas, clickmap.canvas, x, y, true).then(() => {
                const ctx = canvas.getContext('2d');
                ctx.font = clickmap.fontHeight + 'px Arial';
                ctx.globalAlpha = 0.4;
                // Draw zones
                _.forEach(clickmap.zones, zone => {
                    const textWidth = ctx.measureText(zone.text).width,
                        textXOffset = textWidth > zone.zoneArea.width ? 0 : (zone.zoneArea.width - textWidth) / 2;
                    // Draw zone border
                    ctx.beginPath();
                    ctx.strokeStyle = 'black';
                    ctx.lineWidth = '2';
                    ctx.rect(x + zone.zoneArea.x, y + zone.zoneArea.y, zone.zoneArea.width, zone.zoneArea.height);
                    ctx.stroke();
                    // Draw zone backdrop
                    ctx.beginPath();
                    ctx.fillStyle = 'rgb(20, 20, 20)';
                    ctx.rect(x + zone.zoneArea.x, y + zone.zoneArea.y, zone.zoneArea.width, zone.zoneArea.height);
                    ctx.fill();
                    // Draw zone name
                    ctx.fillStyle = 'white';
                    ctx.fillText(zone.text, x + zone.zoneArea.x + textXOffset, y + zone.zoneArea.y + clickmap.fontHeight);
                });
                resolve(canvas);
            });
        });
    });
}

export default imageExportService;
