angular.module('grid.editor', [])
    .directive('gridEditor', gridEditor)
    .controller('GridEditorCtrl', GridEditorCtrl);

function gridEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/grid-editor.html',
        scope: {
            question: '=',
        },
        controller: 'GridEditorCtrl',
        controllerAs: 'GridEditorVM',
    };
}
GridEditorCtrl.$inject = [
    '$rootScope',
    '$scope',
    'pubSubService',
];

function GridEditorCtrl($rootScope, $scope, pubSubService) {
    var GridEditorVM = this,
        config = $scope.question._config && $scope.question._config[$rootScope.funnel.id],
        maxChoices = (config && config.choices) || 6,
        initialRowStatements = (config && config.rows) || 6;
    GridEditorVM.auth = $rootScope.auth;
    GridEditorVM.maxRowStatements = initialRowStatements || 40;
    GridEditorVM.maxAnswerChars = $rootScope.auth.user.isAdmin ? 256 : config.maxAnswerChars;
    GridEditorVM.maxRowStatementChars = $rootScope.auth.user.isAdmin ? 256 : config.maxRowStatementChars;
    GridEditorVM.showCharCount = $rootScope.showCharCount;
    GridEditorVM.charCountColor = $rootScope.charCountColor;
    GridEditorVM.bulkEditor = {
        rowText: '',
        rowAltText: '',
        columnText: '',
    };
    GridEditorVM.skipLogic = {
        row: 0,
    };
    GridEditorVM.altStatements = {};
    /* Configure the "choices" sortable */
    GridEditorVM.sortableOptions = {
        axis: 'y',
        tolerance: 'pointer',
        containment: 'parent',
        handle: '.sort-handle',
        scroll: true,
        distance: 10,
        start: function(e, ui) {
            var choiceCell = ui.item.find('.choice-cell');
            choiceCell.css('width', choiceCell.data('choice-cell-width'));
            $('.tooltip').hide();
        },
        stop: function(e, ui) {
            ui.item.find('.choice-cell').css('width', 'auto');
        },
    };
    GridEditorVM.prepareAltStatements = prepareAltStatements;
    GridEditorVM.openBulkEditor = openBulkEditor;
    GridEditorVM.submitBulkEdits = submitBulkEdits;
    GridEditorVM.editSkipLogic = editSkipLogic;
    GridEditorVM.saveSkipLogic = saveSkipLogic;
    GridEditorVM.hasColumns = hasColumns;

    init();

    function init() {
        $scope.question.details.choices = $scope.question.details.choices || [];
        $scope.question.details.rowStatementsAlt = $scope.question.details.rowStatementsAlt || [];
        initializeWithEmptyText($scope.question.details.choices, maxChoices);
        initializeWithEmptyText($scope.question.details.rowStatements, initialRowStatements);

        /* Watch for changes and run update function */
        pubSubService.subscribe('question-pre-save', $scope.$id, function() {
            if (GridEditorVM.bulkEditor.showRow) {
                submitBulkEdits('row');
            }
            if (GridEditorVM.bulkEditor.showColumn) {
                submitBulkEdits('column');
            }
            if (GridEditorVM.skipLogic.show) {
                saveSkipLogic();
            }
            if (!_.some($scope.question.details.rowStatementsAlt, function(rowStatementsAlt) {
                return rowStatementsAlt.text.length;
            })) {
                delete $scope.question.details.rowStatementsAlt;
            }
            _.remove($scope.question.details.rowStatements, function(rowStatement) {
                return !(rowStatement.text && rowStatement.text.length);
            });
            $scope.question.details.minValue = $scope.question.details.minValue === null ? undefined : $scope.question.details.minValue;
            $scope.question.details.maxValue = $scope.question.details.maxValue === null ? undefined : $scope.question.details.maxValue;
        });

        $scope.$on('$destroy', function() {
            pubSubService.destroy('question-pre-save', $scope.$id);
            $(document).off('mouseover');
            $(document).off('keydown');
        });

        /* Observe element width before dragging removes it from the document flow */
        $(document).on('mouseover', '.sort-handle', function(e) {
            var choiceCell = $(e.target).parent().next('.choice-cell');
            choiceCell.data('choice-cell-width', choiceCell.css('width'));
        });

        /* Navigate through response choices via key presses */
        $(document).on('keydown', '.faux-btn input', function(e) {
            e.stopPropagation();

            if (e.keyCode === 13 || e.keyCode === 40) {
                $(this).parent().parent().parent().next().find('input').focus();
            }
            else if (e.keyCode === 38) {
                $(this).parent().parent().parent().prev().find('input').focus();
            }
        });
    }

    function initializeWithEmptyText(row, num) {
        for (var i = row.length; i < num; i++) {
            row.push({
                text: '',
            });
        }
    }

    function prepareAltStatements() {
        let numChoices = _.countBy($scope.question.details.choices, function(choice) {
            return !!(choice.text || '').length;
        }).true || 1;
        GridEditorVM.altStatements.show = true;
        initializeWithEmptyText($scope.question.details.rowStatementsAlt, numChoices);
    }

    function openBulkEditor(which, evt) {
        evt.preventDefault();
        switch (which) {
            case 'row':
                GridEditorVM.bulkEditor.rowText = '';
                _.forEach($scope.question.details.rowStatements, function(statement) {
                    GridEditorVM.bulkEditor.rowText += statement.text + '\n';
                });
                GridEditorVM.bulkEditor.showRow = true;
                break;
            case 'rowAlt':
                GridEditorVM.bulkEditor.rowAltText = '';
                _.forEach($scope.question.details.rowStatementsAlt, function(statement) {
                    GridEditorVM.bulkEditor.rowAltText += statement.text + '\n';
                });
                GridEditorVM.bulkEditor.showRowAlt = true;
                break;
            case 'column':
                GridEditorVM.bulkEditor.columnText = '';
                _.forEach($scope.question.details.choices, function(choice) {
                    GridEditorVM.bulkEditor.columnText += choice.text + '\n';
                });
                GridEditorVM.bulkEditor.showColumn = true;
                break;
            default:
                break;
        }
    }

    // Submit response choices via text area
    function submitBulkEdits(which) {
        let choices = null,
            i;
        switch (which) {
            case 'row':
                choices = GridEditorVM.bulkEditor.rowText.split('\n');
                i = $scope.question.details.rowStatements.length;

                _.forEach($scope.question.details.rowStatements, function(row, idx) {
                    row.text = choices[idx];
                });
                while (i < choices.length) {
                    $scope.question.details.rowStatements.push({
                        text: choices[i++],
                    });
                }
                GridEditorVM.bulkEditor.showRow = false;
                break;
            case 'rowAlt':
                choices = GridEditorVM.bulkEditor.rowAltText.split('\n');
                i = $scope.question.details.rowStatementsAlt.length;
                _.forEach($scope.question.details.rowStatementsAlt, function(row, idx) {
                    row.text = choices[idx];
                });
                while (i < choices.length) {
                    $scope.question.details.rowStatementsAlt.push({
                        text: choices[i++],
                    });
                }
                GridEditorVM.bulkEditor.showRowAlt = false;
                break;
            case 'column':
                choices = GridEditorVM.bulkEditor.columnText.split('\n');
                i = $scope.question.details.choices.length;
                _.forEach($scope.question.details.choices, function(choice, idx) {
                    choice.text = choices[idx];
                });
                while (i < choices.length) {
                    $scope.question.details.choices.push({
                        text: choices[i++],
                    });
                }
                GridEditorVM.bulkEditor.showColumn = false;
                break;
            default:
                break;
        }
    }

    function editSkipLogic() {
        _.forEach($scope.question.details.rowStatements, function(rowStatement) {
            rowStatement.skipLogic = rowStatement.skipLogic || {};
        });
        GridEditorVM.skipLogic.show = true;
    }

    function saveSkipLogic() {
        _.forEach($scope.question.details.rowStatements, function(rowStatement) {
            _.forEach(rowStatement.skipLogic, function(key) {
                rowStatement.skipLogic[key] = rowStatement.skipLogic[key] || void 0;
            });
            if ($.isEmptyObject(rowStatement.skipLogic)) {
                delete rowStatement.skipLogic;
            }
        });
        GridEditorVM.skipLogic.show = false;
    }

    function hasColumns() {
        return !($scope.question.details.gridType === 'numeric' || $scope.question.details.gridType === 'rating');
    }
}
