import $ from 'jquery';
import Config from './config';
import U from '../../../common/js/util';
import surveyConstants from './survey/survey-constants';

/**
 * Performs the main run.
 *
 * @param {*} $rootScope
 * @param {*} $timeout
 * @param {*} $window
 * @param {*} $urlService
 * @param {*} $transitions
 * @param {*} $state
 * @param {*} $stateParams
 * @param {*} $q
 * @param {*} accountService
 * @param {*} svOfflineService
 * @param {*} surveyStatusesService
 * @param {*} surveyService
 */
function run($rootScope, $timeout, $window, $urlService, $transitions, $state, $stateParams, $q, accountService, svOfflineService, surveyStatusesService, surveyService) {
    var authDeferred = $q.defer(),
        readyDeferred = $q.defer();

    $rootScope.hideSteps = false;
    $rootScope.funnelType = 'basic'; // JMH DEVNOTE: Eventually eliminate, but keep to be safe
    $rootScope.funnel = Config.funnelTypes.BASIC;
    $rootScope.authReady = authDeferred.promise;
    $rootScope.ready = readyDeferred.promise;
    $rootScope.isReady = false;
    $rootScope.auth = $rootScope.auth || {};
    $rootScope.isViewportSizeSmall = isViewportSizeSmall;

    // Add config information to the root scope
    $rootScope.contactEmail = window.config.contactEmail;

    init();

    function init() {
        const nextStateNeedsAuthentication = {
                to: state => !isPublicView(state),
            },
            navigatingFromDashboardToSurveyCreationThankYou = {
                from: 'dashboard.**',
                to: 'thank_you',
            },
            nextStateIsSurveyCreation = {
                to: state => isSurveyCreation(state), // Going to surveyCreation
                from: state => !isSurveyCreation(state), // AND not coming from surveyCreation
            };

        svOfflineService.init();

        // For routes not specified in the config, start a new basic survey
        $urlService.rules.otherwise((_, route) => {
            if ($rootScope.auth.user) {
                goToDefaultView(route);
            }
            else {
                $rootScope.authReady.then(() => {
                    goToDefaultView(route);
                });
            }
        });

        accountService.currentUser().then(user => {
            $rootScope.auth.user = user;

            authDeferred.resolve({
                user,
            });
            $rootScope.isAuthReady = true;
            setReady();
        }, () => {
            // User offline or server down
            $state.go('signIn');
            return false;
        });

        $transitions.onSuccess({
            from: state => state.name !== '',
            entering: 'dashboard.**',
        }, function(transition, _state) {
            let stateParams = transition.params(),
                surveyUuid = stateParams.surveyId;

            // For invalid survey uuid, redirect to account page
            if (surveyUuid && !U.isValidUuid(surveyUuid)) {
                return transition.router.stateService.target('account.info');
            }

            return true;
        });

        $transitions.onBefore(navigatingFromDashboardToSurveyCreationThankYou, transition => {
            // Stop back button 🤔 -- What should be the behavior here❓
            return transition.router.stateService.target('thank_you', surveyStatusesService.getRouteParams($rootScope.survey));
        });

        $transitions.onBefore(nextStateNeedsAuthentication, transition => {
            return $rootScope.authReady.then(async () => {
                if (!isUserAuthenticated()) { // Anonymous users are redirected to the Login screen
                    if (transition.to().name === 'signIn') {
                        $rootScope.isReady = true;
                        return true; // Go forth
                    }

                    // Capture where you want to go
                    $rootScope.desiredState = {
                        state: transition.to().name || 'account.info',
                        params: {
                            ...transition.params(),
                        },
                    };

                    // Redirect you to login
                    return transition.router.stateService.target('signIn');
                }

                return true; // Go forth
            });
        });

        $transitions.onBefore(nextStateIsSurveyCreation, transition => {
            $rootScope.authReady.then(() => {
                goToSurveyCreationState(transition);
            });
        });

        $transitions.onSuccess({}, transition => {
            update(transition);
            trackPageView(transition);
            $(window).scrollTop(0);
        });

        $rootScope.authReady.then(() => {
            $rootScope.isAuthReady = true;
        });

        $rootScope.$on('signInSuccess', () => {
            if ($rootScope.auth.user) {
                U.identifyUser($rootScope.auth.user);
            }

            // Go to desired state, as long as it's not the login screen
            if ($rootScope.desiredState && $rootScope.desiredState.state !== 'signIn') {
                Config.steps.CONTACT_INFO.hide = true; // QUESTION: why is this here
                $state.go($rootScope.desiredState.state, $rootScope.desiredState.params);

                // Clear desired state
                $rootScope.desiredState = {};
                setReady();
            }
            else {
                goToDefaultView();
            }
        });
    }

    function goToDefaultView() {
        if (isUserAuthenticated()) {
            $state.go('account.info');
        }
        else {
            // Anonymous users are redirected to the Login screen
            $state.go('signIn');
        }
    }

    function setReady() {
        $timeout(() => {
            $rootScope.isReady = true;
        }, 0);
    }

    /*
    The magic numbers refer to layout.less max-width and max-height of small screens

    FUTURE -- move into config values matching css framework breakpoints (e.g. BootStrap 3) allowing for
    js to line up with styles preventing abuse of one to do the other's job
    */
    /**
     * Is viewport size small.
     *
     * @param {*} data
     * @returns {boolean}
     */
    function isViewportSizeSmall(data) {
        return data.width <= 770 || data.height <= 605;
    }

    /**
     * Indicates if the current user is authenticated
     *
     * @returns {boolean} - true if user is authenticated, false otherwise
     */
    function isUserAuthenticated() {
        return $rootScope.auth.user && $rootScope.auth.user.anonymous === false;
    }

    /**
     * Update.
     *
     * @param {*} transition
     */
    function update(transition) {
        var nextState = transition.$to();

        $rootScope.dashboardView = !isSurveyCreation(nextState);
        $rootScope.previousState = transition.$from();

        if (goingBackFromDashboard(transition)) {
            $window.history.go(-2);
            return;
        }
        if (goingBackFromThankYou(transition)) {
            $state.go('thank_you', surveyStatusesService.getRouteParams($rootScope.survey));
        }
    }

    /**
     * Is survey creation.
     *
     * @param {*} state
     * @returns {boolean}
     */
    function isSurveyCreation(state) {
        return state.name === 'surveyCreation' || (state.data || {}).parent === 'surveyCreation';
    }

    /**
     * Is public view.
     *
     * @param {*} state
     * @returns {boolean}
     */
    function isPublicView(state) {
        return [
            'resetPassword',
            'resetPasswordUrl',
            'inviteLanding',
            'signIn',
            'signUp',
        ].indexOf(state.name) > -1 || isSurveyCreation(state);
    }

    /**
     * Going back from dashboard.
     *
     * @param {*} transition
     * @returns {boolean}
     */
    function goingBackFromDashboard(transition) {
        var next = transition.$to(),
            previous = transition.$from().data || {};

        return next.name === 'dashboard' && (previous.data || {}).parent === 'dashboard';
    }

    /**
     * Going back from thank you.
     *
     * @param {*} transition
     * @returns {boolean|*|boolean}
     */
    function goingBackFromThankYou(transition) {
        var nextState = transition.$to(),
            surveyCompleted = $rootScope.survey && $rootScope.survey.draftCompleted && !$rootScope.auth.user.isAdmin;

        return nextState.name !== 'thank_you' && surveyCompleted && nextState.parent.name === 'surveyCreation';
    }

    /**
     * Track page view.
     *
     * @param {*} transition
     */
    function trackPageView(transition) {
        let nextState = transition.to();

        // Track Google Analytics page views.
        if ($window._gaq) {
            $window._gaq.push(['_trackPageview', nextState]);
        }
    }

    /**
     * Get audience.
     *
     * @param {*} survey
     * @returns {Promise<*>}
     */
    function getAudience(survey) {
        return surveyService.getAudience(survey.uuid).then(function(audience) {
            surveyService.setAudienceInSurvey($rootScope.survey, audience);
            return surveyService.listScreeningQuestions(audience.uuid).then(function(screeners) {
                $rootScope.survey.screeningQuestions = screeners || [];
                readyDeferred.resolve();
                $rootScope.isReady = true;
                return survey;
            });
        }, function() {
            return createAudience(survey);
        });
    }

    /**
     * Create audience.
     *
     * @param {*} survey
     * @returns {Promise<*>}
     */
    function createAudience(survey) {
        return surveyService.createAudience(survey.uuid).then(function(audience) {
            surveyService.setAudienceInSurvey($rootScope.survey, audience);
            $rootScope.survey.screeningQuestions = [];
            readyDeferred.resolve();
            $rootScope.isReady = true;
            return $rootScope.survey;
        });
    }

    /**
     * Creates a new survey if surveyUuid is false, otherwise retrieves existing.
     * Creates/retrieves audience of survey.
     *
     * @name primeSurvey
     * @param {string} surveyUuid - The surveyUuid of a survey
     * @param {*} funnelType
     * @returns {Promise} Resolves the survey
     */
    function primeSurvey(surveyUuid, funnelType) {
        var getSurveyPromise,
            isNew;

        if ($rootScope.forceNewSurvey || !surveyUuid) {
            var params = {
                surveyPackage: funnelType || surveyConstants.PACKAGES.BASIC,
            };
            if (!funnelType) {
                if (surveyStatusesService.isAdvancedFlow()) {
                    params.surveyPackage = surveyConstants.PACKAGES.ADVANCED;
                }
                else if (surveyStatusesService.isExtendedFlow()) {
                    params.surveyPackage = surveyConstants.PACKAGES.EXTENDED;
                }
            }
            getSurveyPromise = surveyService.createSurvey(params);
            isNew = true;
        }
        else {
            getSurveyPromise = surveyService.getSurvey(surveyUuid);
        }

        setReady();

        return getSurveyPromise.then(function(survey) {
            $rootScope.survey = survey;
            selectFunnel(survey.surveyPackage);
            $rootScope.inSurveyFunnel = true;
            if (isNew) {
                return createAudience(survey);
            }
            return getAudience(survey);
        }, function() {
            $state.go('signIn');
        });
    }

    /**
     * Sets the funnel and funnelType on the $rootScope object
     *
     * @name selectFunnel
     * @param {string} funnel - string represetation of the surveyPackage
     */
    function selectFunnel(funnel) {
        if (funnel) {
            funnel = funnel === 'quote' ? 'advanced' : funnel;
            funnel = funnel.toUpperCase();
        }
        else if ($rootScope.survey) {
            funnel = ($rootScope.survey.surveyPackage || 'basic').toUpperCase();
        }
        else {
            funnel = 'BASIC';
        }

        $rootScope.funnel = Config.funnelTypes[funnel] || Config.funnelTypes.BASIC;
        $rootScope.funnelType = $rootScope.funnel.id;
    }
    /**
     * Prepares a survey for survey creation steps, then navigates to proper steps.
     * If no surveyId is defined, but there is a 'lastSurvey' object for the user,
     * sets the last survey as main survey.
     *
     * @name goToSurveyCreationState
     * @param {object} transition - The transition object from the transition hook
     */
    function goToSurveyCreationState(transition) {
        var stateParams = transition.params(),
            surveyId = stateParams.surveyId;

        var deferred = $q.defer(),
            lastSurvey = surveyStatusesService.isQuoteFlow() ? $rootScope.auth.user.lastQuote : $rootScope.auth.user.lastSurvey;

        selectFunnel(stateParams.funnelType);

        if (!$rootScope.forceNewSurvey && lastSurvey && lastSurvey.surveyPackage === ($rootScope.funnelType || 'basic')) {
            $rootScope.survey = lastSurvey;
            getAudience($rootScope.survey).then(function(survey) {
                deferred.resolve(survey);
            });
        }
        else {
            primeSurvey(surveyId, $rootScope.funnel.id).then(function(survey) {
                deferred.resolve(survey);
            });
        }

        deferred.promise.then(function(survey) {
            $rootScope.survey = survey;
            $state.go($rootScope.survey.numQuestions ? 'design' : 'target', {
                funnelType: $rootScope.survey.surveyPackage === 'basic' ? null : $rootScope.survey.surveyPackage,
                surveyId: $rootScope.survey.uuid,
            });
        });
    }
}

export default run;
