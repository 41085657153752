import _ from 'lodash';
import '../filter-and-compare/compare-service';
import './stats-module';

angular.module('surveyResults.service', [
    'StatsModule', 'compareService',
])
    .service('surveyResultsService', surveyResultsService);
surveyResultsService.$inject = [
    '$rootScope',
    '$statsService',
    'compareService',
];

function surveyResultsService($rootScope, $statsService, compareService) {
    const MAX_ALLOWED_GRID = 8;
    function getDataForQuestion(question, params) {
        const getDataFunction = params.comparisonData ? compareService.getComparisonQuestionTally : $statsService.getQuestionTally;
        return getDataFunction($rootScope.survey, question, params || {}).then(response => {
            if (question.type === 'freeResponse' || question.type === 'longFreeResponse') {
                _.forEach(Array.isArray(response) ? response : [response], periodData => {
                    periodData.countTally = extractWords(periodData.countTally, {});
                });
            }
            return response;
        });
    }

    function extractWords(countTally, wordsSoFar) {
        var wordMap = {};
        _.forEach(countTally, (count, wordList) => {
            var word = getCommonWords(wordsSoFar, wordList.slice(1, -1).split(','));
            wordMap[word] = count;
        });
        return wordMap;
    }

    function getCommonWords(wordsSoFar, words) {
        var firstWord = words[0].trim().slice(1, -1),
            common = firstWord;
        _.forEach(words, word => {
            word = word.trim().slice(1, -1);
            if (wordsSoFar[word]) {
                common = wordsSoFar[word];
            }
            else if ((word || '').length > 0) {
                wordsSoFar[word] = firstWord;
            }
        });
        return common;
    }

    // Get tally of survey responses
    function getSurveyResultsTallySummary(survey, params, periods) {
        return $statsService.getTallySummary(survey, {
            filter_by: params,
        }).then(response => {
            response.summary = createResponseSummary(survey, response, periods);
            return response;
        },
        status => {
            return status ? status.errorMsg : '';
        });
    }

    function createResponseSummary(survey, response, periods) {
        var summary = {
            numRespondentsUnfiltered: response.unfiltered.numRespondents,
            numRespondentsInLatestCollectionPeriod: survey.latestCollectionPeriod.currentResponses,
        };
        if (periods) {
            summary.numPeriods = survey.collectionPeriods.length;
            summary.numVisiblePeriods = Math.abs(periods.start - periods.end) + 1 || 0;
            summary.numRespondentsInVisiblePeriods = response.filtered.numRespondents;
        }
        else {
            summary.numRespondentsInVisiblePeriods = response.filtered.numRespondents;
        }
        return summary;
    }

    function getChartData(question, params) {
        const promises = [];
        if (question.details.type === 'grid' && (question.details.rowStatements.length > MAX_ALLOWED_GRID || params.comparisonData)) {
            let deferred,
                previousPromise = new Promise(resolve => {
                    deferred = resolve;
                });
            _.forEach(question.details.rowStatements, (_, idx) => {
                const promise = new Promise(resolve => {
                    const rowQuestion = angular.copy(question);
                    rowQuestion.line = idx + 1;
                    rowQuestion.gridIndex = idx + 1;
                    previousPromise.then(() => {
                        getDataForQuestion(rowQuestion, params).then(response => {
                            resolve(response);
                            deferred(response);
                            return response;
                        });
                    });
                    if (!idx) {
                        deferred();
                    }
                });
                promises.push(promise);
            });
        }
        else if (question.details.type === 'grid') {
            const deferred = [];
            _.forEach(question.details.rowStatements, () => {
                promises.push(new Promise(resolve => {
                    deferred.push(resolve);
                }));
            });
            getDataForQuestion(question, params).then(response => {
                _.forEach(response, (row, idx) => {
                    deferred[idx](row);
                });
            });
        }
        else {
            promises.push(new Promise(resolve => {
                getDataForQuestion(question, params).then(response => {
                    resolve(response);
                    return response;
                });
            }));
        }
        return promises;
    }

    return {
        getDataForQuestion,
        getSurveyResultsTallySummary,
        getChartData,
    };
}
