import _ from 'lodash';
import {
    getDefaultDateRangePickerOptions,
} from '../common-components/datepicker-service';
import '../common-components/sv-datepicker';
import './api-publisher-service';
import './publisher-date-service';

/**
 * @param stats
 */
function payoutAmount(stats) {
    if (stats.payoutCents) {
        return stats.payoutCents / 100;
    }
    return stats.clientCreditedInterviews * 0.20;
}

angular.module('publisherAdmin', [
    'svDatePicker',
    'apiPublisherService',
    'publisherDate.service',
])
    .controller('publisherAdminListCtrl', publisherAdminListCtrl)
    .controller('publisherAdminDemographicListCtrl', publisherAdminDemographicListCtrl)
    .controller('publisherAdminCtrl', publisherAdminCtrl);
publisherAdminListCtrl.$inject = [
    '$scope',
    '$rootScope',
    'publisherService',
    'publisherDateService',
];

/**
 * @param $scope
 * @param $rootScope
 * @param publisherService
 * @param publisherDateService
 */
function publisherAdminListCtrl($scope, $rootScope, publisherService, publisherDateService) {
    $scope.ux = {
        ready: false,
        summaries: null,
    };
    $scope.date = {
        start: publisherDateService.getStartDate(),
        end: publisherDateService.getEndDate(),
    };
    $scope.datePickerOptions = getDefaultDateRangePickerOptions($scope.date, refreshPublishers);
    $scope.payoutAmount = payoutAmount;
    $scope.refreshPublishers = refreshPublishers;
    $scope.refreshPublishers();

    function refreshPublishers() {
        $scope.ux.ready = false;
        $rootScope.startDate = $scope.date.start;
        $rootScope.endDate = $scope.date.end;
        publisherService.listAllPublisherSummaries($scope.date.start, $scope.date.end).then(function(publisherSummaries) {
            const orderedPublisherSummaries = _.orderBy(publisherSummaries, ['clientCreditedInterviews'], ['desc']);
            $scope.ux.summaries = orderedPublisherSummaries;
            $scope.ux.ready = true;
        });
    }
}

publisherAdminDemographicListCtrl.$inject = [
    '$scope',
    '$rootScope',
    'publisherService',
    'publisherDateService',
];

/**
 * @param $scope
 * @param $rootScope
 * @param publisherService
 * @param publisherDateService
 */
function publisherAdminDemographicListCtrl($scope, $rootScope, publisherService, publisherDateService) {
    $scope.ux = {
        ready: false,
        summaries: null,
    };

    $scope.date = {
        start: publisherDateService.getStartDate(),
        end: publisherDateService.getEndDate(),
    };
    $scope.datePickerOptions = getDefaultDateRangePickerOptions($scope.date, refreshDemographicPublishers);
    $scope.refreshDemographicPublishers = refreshDemographicPublishers;

    $scope.refreshDemographicPublishers();

    function refreshDemographicPublishers() {
        $scope.ux.ready = false;
        $rootScope.startDate = $scope.date.start;
        $rootScope.endDate = $scope.date.end;
        publisherService.listAllPublisherDemographicSummaries($scope.date.start, $scope.date.end).then(function(publisherSummaries) {
            $scope.ux.summaries = publisherSummaries;
            $scope.ux.ready = true;
        });
    }
}

publisherAdminCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$stateParams',
    'publisherService',
    'publisherDateService',
    '$notify',
    '$filter',
];

/**
 * @param $scope
 * @param $rootScope
 * @param $stateParams
 * @param publisherService
 * @param publisherDateService
 * @param $notify
 * @param $filter
 */
function publisherAdminCtrl($scope, $rootScope, $stateParams, publisherService, publisherDateService, $notify, $filter) {
    $scope.ux = {
        ready: false,
        publisherId: $stateParams.publisherId,
        details: null,
        publisher: null,
        editingDetails: false,
    };
    $scope.date = {
        start: $rootScope.startDate,
        end: $rootScope.endDate,
    };
    if (!$scope.date.start) {
        $scope.date.start = publisherDateService.getStartDate();
        $scope.date.end = publisherDateService.getEndDate();
    }

    $scope.edits = {
        placeholdersDesired: '',
        capNumber: '',
        capPeriodInDays: '',
        integrationNotes: '',
        validPayoutCents: '',
        startPayoutCents: '',
    };

    $scope.datePickerOptions = getDefaultDateRangePickerOptions($scope.date, refreshDetails, 'margin-top-md');

    $scope.payoutAmount = payoutAmount;
    $scope.formatDate = formatDate;
    $scope.refreshDetails = refreshDetails;
    $scope.readyEdits = readyEdits;
    $scope.savePublisherDetails = savePublisherDetails;

    if (!$scope.date.start) {
        $scope.date.start = publisherDateService.getStartDate();
        $scope.date.end = publisherDateService.getEndDate();
    }

    $scope.refreshDetails();

    /**
     * @param date
     */
    function formatDate(date) {
        return $filter('date')(U.toUTCDate(date), 'shortDate');
    }

    function refreshDetails() {
        $scope.ux.ready = false;
        publisherService.getDayStats($scope.ux.publisherId, $scope.date.start, $scope.date.end).then(function(stats) {
            $scope.ux.publisher = stats[0].publisher;
            $scope.ux.details = stats;
            $scope.ux.ready = true;
        });
    }

    function readyEdits() {
        $scope.edits.placeholdersDesired = $scope.ux.publisher.placeholdersDesired;
        $scope.edits.capNumber = $scope.ux.publisher.capNumber;
        $scope.edits.capPeriodInDays = $scope.ux.publisher.capPeriodInDays;
        $scope.edits.integrationNotes = $scope.ux.publisher.integrationNotes;
        $scope.edits.validPayoutCents = $scope.ux.publisher.validPayoutCents;
        $scope.edits.startPayoutCents = $scope.ux.publisher.startPayoutCents;
    }

    function savePublisherDetails() {
        publisherService.updatePublisher($scope.ux.publisherId, $scope.edits).then(function(publisher) {
            $scope.ux.publisher = publisher;
            $notify.success('Publisher updated.');
        });
    }
}
