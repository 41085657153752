import './chart-data-parser';
import './charts-widget-service';
import './charts-widget-wrapper';
import './charts-widget-text';
import './charts-widget-clickmap';
import './charts-widget-chart';
import './charts-widget-grid';
import './sv-wordcloud';

angular.module('chartsWidgetModule', [
    'chart.dataParser',
    'chartsWidget.service',
    'chartsWidget.wrapper',
    'chartsWidget.gridCharts',
    'chartsWidget.charts',
    'chartsWidget.text',
    'chartsWidget.clickMap',
    'svWordcloud',
]);
