import {
    adServers,
} from './tag-creation.ad-server-constants';
import {
    tagCreationMacros,
} from './tag-creation.macros-constants';

const ImpressionParameters = {
    adId: {
        name: 'Ad ID',
        value: 'adId',
    },
    campaignId: {
        name: 'Campaign ID',
        value: 'campaignId',
    },
    creativeId: {
        name: 'Creative ID',
        value: 'creativeId',
    },
    placementId: {
        name: 'Placement ID',
        value: 'placementId',
    },
    siteId: {
        name: 'Site ID',
        value: 'siteId',
    },
    orderId: {
        name: 'Order ID',
        value: 'orderId',
    },
    viewabilityLevel: {
        name: 'Viewability',
        value: 'viewabilityLevel',
    },
};

const tagCreationConstant = new Map([
    ['REDIRECT_DIVIDER_INDEX', 6],
    ['REDIRECTS', [
        {
            name: 'No Preference',
            value: '<img width="0" height="0" src="https://TO_REPLACE.surveywall-api.survata.com/r?eid=',
        },
        {
            name: 'Oracle/BluKai',
            value: '<img width="0" height="0" src="https://TO_REPLACE.surveywall-api.survata.com/o?eid=',
        },
        {
            name: 'Salesforce (Krux)',
            value: '<img width="0" height="0" src="https://TO_REPLACE.surveywall-api.survata.com/k?eid=',
        },
        {
            name: 'Liveramp',
            value: '<img width="0" height="0" src="https://TO_REPLACE.surveywall-api.survata.com/l?eid=',
        },
    ]],
    ['REDIRECT_NONE', {
        name: 'None',
        value: '<img width="0" height="0" src="https://pv.surveywall-api.survata.com/s?eid=',
    }],
    ['AD_SERVER', adServers],
    ['MACROS', tagCreationMacros],
    ['MACROS_URL_PARAM', '&cid='],
    ['BEACON_TYPES', [
        {
            name: 'Cookie',
            value: 'cookie',
        },
        {
            name: 'Mobile',
            value: 'mobile',
        },
        {
            name: 'Mobile & Cookie',
            value: 'cookieAndMobile',
        },
        {
            name: 'IP Address',
            value: 'ipaddress',
        },
    ]],
    ['IMPRESSION_PARAMETERS', ImpressionParameters],
    ['DEFAULT_IMPRESSION_PARAMETERS', [
        ImpressionParameters.creativeId,
        ImpressionParameters.placementId,
        ImpressionParameters.siteId,
    ]],
]);

export default tagCreationConstant;
