const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
const FULL_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const NUMERIC_MONTHS = '0?[1-9]|1[0-2]';
const YEAR_INPUT = '(?:[1-2]\\d)?\\d\\d';
const YEAR_IN_MONTHS = 12;
const MONTH_IN_DAYS = 30;
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const DAY_INPUT = '0?[1-9]|1\\d|2\\d|3[0-1]';
const MONTH_INPUT = MONTHS.concat(FULL_MONTHS).concat(NUMERIC_MONTHS).join('|');
const SUFFIX = '\\s*\\(\\d+\\s+\\w+\\)';
const SEPARATOR = '\\s*[^\\d]\\s*';
const NON_CAPTURING = '?:';
const OPTIONAL_SEPARATOR = group(SEPARATOR, NON_CAPTURING, true);
const DATE_SEPARATOR = new RegExp('^' + group(group(MONTH_INPUT, NON_CAPTURING) + SEPARATOR + group(DAY_INPUT, NON_CAPTURING) + OPTIONAL_SEPARATOR + group(YEAR_INPUT, NON_CAPTURING, true)) +
    '\\s*[\\-~]\\s*' + group(group(MONTH_INPUT, NON_CAPTURING, true) + OPTIONAL_SEPARATOR + group(DAY_INPUT, NON_CAPTURING) + OPTIONAL_SEPARATOR + group(YEAR_INPUT, NON_CAPTURING, true)) +
    group(SUFFIX, NON_CAPTURING, true) + '$');
const DATE_PARSER = /[\s\-/]/;
/**
 * @name exportDomElement
 * @param {Date{}} dates - The params containing start date and end dates
 * @param {Function} ngChange - The function that is triggered when the date changes
 * @param {string} wrapperClass - the class to be applied to the sv-date-range-picker directive.
 *
 * @description
 * Returns default options for date range picker
 */
function getDefaultDateRangePickerOptions(dates, ngChange, wrapperClass) {
    return {
        class: {
            wrapper: wrapperClass || '',
            input: '',
        },
        dateOptions: {
            startDate: {
                initDate: dates.start || new Date(),
            },
            endDate: {
                initDate: dates.end || new Date(),
            },
        },
        ngChange: ngChange,
        maxDate: new Date(),
    };
}

function group(str, lookaround, optional) {
    return '(' + (lookaround || '') + str + ')' + (optional ? '?' : '');
}

function parseYear(date) {
    let thisYear = (new Date()).getUTCFullYear(),
        year = _.parseInt(date);
    if (_.isInteger(year)) {
        if (year + 2000 <= thisYear) {
            year += 2000;
        }
        else if (year + 1900 < thisYear) {
            year += 1900;
        }
    }
    return year;
}

function parseHumanInput(str, datePickerOptions) {
    if (!str.match(DATE_SEPARATOR)) {
        return {
            error: 'Invalid input: please input two dates separated by a \'-\' or a \'~\'',
        };
    }
    let parsed = str.match(DATE_SEPARATOR),
        parsedStartDate = parsed[1].trim().split(DATE_PARSER),
        parsedEndDate = parsed[2].trim().split(DATE_PARSER),
        startDate = new Date(),
        endDate = new Date(),
        info = '',
        startMonth,
        endMonth,
        startDay,
        endDay,
        startYear,
        endYear;
    if (parsedStartDate.length < 2) {
        return {
            error: 'Invalid input: please input two dates separated by a \'-\' or a \'~\'',
        };
    }
    //Set month
    startMonth = getMonthFromString(parsedStartDate[0]);
    if (startMonth < 0) {
        return {
            error: 'Invalid input for start date month: ' + parsedStartDate[0],
        };
    }
    startDate.setMonth(startMonth);
    if (parsedEndDate.length > 1) {
        endMonth = getMonthFromString(parsedEndDate[0]);
        if (endMonth < 0) {
            return {
                error: 'Invalid input for end date month: ' + parsedEndDate[0],
            };
        }
    }
    else {
        endMonth = startMonth;
    }
    endDate.setMonth(endMonth);
    //Set date
    startDay = _.parseInt(parsedStartDate[1]);
    if (invalidInput(startDay)) {
        return {
            error: 'Invalid input for start date day: ' + parsedStartDate[1],
        };
    }
    startDate.setDate(startDay);
    endDay = _.parseInt(parsedEndDate[parsedEndDate.length > 1 ? 1 : 0]);
    if (invalidInput(endDay)) {
        return {
            error: 'Invalid input for end date day: ' + parsedEndDate[parsedEndDate.length > 1 ? 1 : 0],
        };
    }
    endDate.setDate(endDay);
    //Set year
    if (parsedStartDate.length > 2) {
        startYear = parseYear(parsedStartDate[2]);
        if (invalidInput(startYear)) {
            return {
                error: 'Invalid input for start date year: ' + parsedStartDate[2],
            };
        }
    }
    if (parsedEndDate.length > 2) {
        endYear = parseYear(parsedEndDate[2]);
        if (invalidInput(endYear)) {
            return {
                error: 'Invalid input for start date year: ' + parsedEndDate[2],
            };
        }
    }
    if (!startYear) {
        startYear = endYear || startDate.getUTCFullYear();
    }
    if (!endYear) {
        endYear = startYear;
    }
    startDate.setFullYear(startYear);
    endDate.setFullYear(endYear);
    if (compareDate(datePickerOptions.minDate, startDate) > -1) {
        info += 'Start date must be older than the minimum date: ' + parseDateForApi(datePickerOptions.minDate, true) + '. ';
    }
    if (compareDate(datePickerOptions.maxDate, endDate) < 0) {
        info += 'End date must be before than the maximum date: ' + parseDateForApi(datePickerOptions.maxDate, true) + '. ';
    }
    if (startDate > endDate) {
        return {
            error: 'Start date must be before end date.',
        };
    }
    return {
        startDate: startDate,
        endDate: endDate,
        info: info,
    };
}

function stringToDate(startDate, endDate) {
    let startUTCMonth = startDate.getUTCMonth(),
        endUTCMonth = endDate.getUTCMonth(),
        startMonth = MONTHS[startUTCMonth],
        endMonth = MONTHS[endUTCMonth],
        startDay = startDate.getUTCDate(),
        endDay = endDate.getUTCDate(),
        startYear = startDate.getUTCFullYear(),
        endYear = endDate.getUTCFullYear(),
        str = '';

    if (startMonth === endMonth) {
        str += startMonth + ' ' + startDay;
        if (startDay !== endDate) {
            str += ' - ' + endDay;
        }
    }
    else if (startYear === endYear) {
        str += startMonth + ' ' + startDay + ' - ' + endMonth + ' ' + endDay;
    }
    else {
        str += startMonth + ' ' + startDay + ', ' + startYear + ' - ' + endMonth + ' ' + endDay + ', ' + endYear;
    }
    return str;
}

function dateDifferenceToString(startDate, endDate) {
    const diffInDays = Math.round((endDate - startDate) / DAY_IN_MILLISECONDS) + 1;
    let diffInMonths = Math.floor(diffInDays / MONTH_IN_DAYS),
        diffInYears = Math.floor(diffInMonths / YEAR_IN_MONTHS);

    //Round months
    if (diffInDays / MONTH_IN_DAYS - diffInMonths > 0.75) {
        diffInMonths++;
    }
    if (diffInMonths / YEAR_IN_MONTHS - diffInYears > 0.75) {
        diffInYears++;
    }
    return diffInYears ? pluralize(diffInYears, 'Year') : diffInMonths ? pluralize(diffInMonths, 'Month') : pluralize(diffInDays, 'Day');
}

function parseDateForApi(date, format) {
    let month = date.getUTCMonth() + 1,
        day = date.getUTCDate();
    if (format) {
        return (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + '-' + date.getUTCFullYear();
    }
    return date.getUTCFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

function invalidInput(input) {
    return isNaN(input) || input < 0;
}

function getMonthFromString(str) {
    let month = _.indexOf(MONTHS, str);
    if (month < 0) {
        month = _.indexOf(FULL_MONTHS, str);
    }
    if (month < 0) {
        month = _.parseInt(str);
        //Javascript dates are 0-indexed
        month--;
    }
    if (invalidInput(month)) {
        return -1;
    }
    return month;
}

function pluralize(amount, type) {
    return amount + ' ' + type + (amount > 1 ? 's' : '');
}

function compareDate(date1, date2) {
    //Same day
    if (Math.abs(date1 - date2) <= DAY_IN_MILLISECONDS) {
        return 0;
    }
    // Date1 > date2
    if (date1 - date2 >= DAY_IN_MILLISECONDS) {
        return 1;
    }
    return -1;
}
export {
    getDefaultDateRangePickerOptions,
    parseHumanInput,
    stringToDate,
    dateDifferenceToString,
    parseDateForApi,
    compareDate,
    DAY_IN_MILLISECONDS,
    MONTHS,
};
