angular.module('beaconPool.summaryView', [])
    .directive('beaconPoolSummary', beaconPoolSummary)
    .controller('BeaconPoolSummaryCtrl', BeaconPoolSummaryCtrl);

function beaconPoolSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/beacon-pool-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'BeaconPoolSummaryCtrl',
        controllerAs: 'BeaconPoolSummaryVM',
    };
}
BeaconPoolSummaryCtrl.$inject = ['$scope'];

function BeaconPoolSummaryCtrl($scope) {
    var BeaconPoolSummaryVM = this;
    BeaconPoolSummaryVM.windowDays = windowDays;
    BeaconPoolSummaryVM.selectedBeacons = selectedBeacons;

    function windowDays() {
        return $scope.requirement && $scope.requirement.details && $scope.requirement.details.windowDays;
    }

    function selectedBeacons() {
        return $scope.requirement && $scope.requirement.details && $scope.requirement.details.beaconPools;
    }
}
