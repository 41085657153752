import '../../common-components/paginator';
import adMeasurementConstants from '../ad-measurement.constants';
import '../ad-measurement.api-service';
import adMeasurementService from '../ad-measurement.service';

angular.module('audienceConfiguration.selectControl', [
    'common.paginator', 'adMeasurement.apiService',
])
    .controller('SelectControlCtrl', SelectControlCtrl)
    .directive('selectControl', selectControl);

function selectControl() {
    return {
        restrict: 'E',
        templateUrl: '/dashboard-templates/ad-measurement/audience-configuration/select-control.html',
        scope: {
            audienceConfiguration: '=',
            beaconControlConfigurations: '=',
        },
        controller: 'SelectControlCtrl',
        controllerAs: 'SelectControlVM',
    };
}
SelectControlCtrl.$inject = [
    '$rootScope',
    '$scope',
    'Paginator',
    'adMeasurementApiService',
];

function SelectControlCtrl($rootScope, $scope, Paginator, adMeasurementApiService) {
    const CURRENT_STEP_IDX = 1,
        SelectControlVM = this,
        stepManager = adMeasurementService.getStepManager();
    SelectControlVM.audienceConfiguration = $scope.audienceConfiguration;
    SelectControlVM.ux = {};
    SelectControlVM.refreshControls = refreshControls;
    SelectControlVM.setOrderBy = setOrderBy;
    SelectControlVM.onControlConfigurationSelected = onControlConfigurationSelected;
    SelectControlVM.getErrorMessage = getErrorMessage;
    SelectControlVM.deleteBeaconControlConfiguration = deleteBeaconControlConfiguration;
    SelectControlVM.back = back;
    SelectControlVM.next = next;

    init();

    function init() {
        if (!SelectControlVM.audienceConfiguration.uuid) {
            if ($scope.beaconControlConfigurations && $scope.beaconControlConfigurations.length) {
                SelectControlVM.audienceConfiguration.beaconControlConfigurationUuid = $scope.beaconControlConfigurations[0].uuid;
            }
            else {
                SelectControlVM.ux.beaconControlConfiguration = adMeasurementConstants.NO_CONTROL;
            }
        }
        SelectControlVM.beaconControls = new Paginator({
            list: $scope.beaconControlConfigurations,
            itemsPerPage: adMeasurementConstants.DEFAULT_LIST_ITEMS_PER_PAGE,
        });
        refreshControls();
    }

    function refreshControls() {
        SelectControlVM.beaconControls.refreshList(filterControls);
        SelectControlVM.beaconControls.getPageData();
    }

    function filterControls(item) {
        if (SelectControlVM.ux.filterText) {
            return (new RegExp(SelectControlVM.ux.filterText, 'i')).test(item.name);
        }
        return true;
    }

    function setOrderBy(criteria) {
        if (SelectControlVM.ux.orderBy === criteria) {
            SelectControlVM.ux.reverse = !SelectControlVM.ux.reverse;
        }
        else {
            SelectControlVM.ux.orderBy = criteria;
        }
        SelectControlVM.beaconControls.sortPageData(SelectControlVM.ux.orderBy, SelectControlVM.ux.reverse);
        SelectControlVM.beaconControls.getPageData();
    }

    function onControlConfigurationSelected(control) {
        control = control || {};
        SelectControlVM.audienceConfiguration.name = SelectControlVM.audienceConfiguration.name || adMeasurementService.getAudienceConfigurationName(SelectControlVM.audienceConfiguration, $rootScope.survey, control.name);
        SelectControlVM.audienceConfiguration.beaconControlConfigurationName = control.name;
        SelectControlVM.audienceConfiguration.beaconControlConfigurationUuid = control.uuid;
        delete SelectControlVM.ux.errorMsg;
    }

    function back(e) {
        stepManager.setCurrentStep(e, CURRENT_STEP_IDX - 1);
    }

    function next(e) {
        if (SelectControlVM.ux.beaconControlConfiguration === adMeasurementConstants.NEW_CONTROL) {
            stepManager.setCurrentStep(e, CURRENT_STEP_IDX, 0);
            delete SelectControlVM.ux.beaconControlConfiguration;
            return;
        }
        SelectControlVM.audienceConfiguration.name = SelectControlVM.audienceConfiguration.name || adMeasurementService.getAudienceConfigurationName(SelectControlVM.audienceConfiguration, $rootScope.survey);
        stepManager.setCurrentStep(e, CURRENT_STEP_IDX + 1);
        SelectControlVM.ux.errorMsg = getErrorMessage();
        if (SelectControlVM.ux.errorMsg) {
            return;
        }
        delete SelectControlVM.ux.filterText;
    }

    function getErrorMessage() {
        if (!SelectControlVM.audienceConfiguration) {
            return 'Please select a control';
        }
    }

    function deleteBeaconControlConfiguration(control) {
        adMeasurementApiService.deleteBeaconControlConfigurationByUuid(control.uuid).then(function() {
            control.deleted = true;
        });
    }
}
