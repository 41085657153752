import './screening-question';

angular.module('targetingAttributeGroup.screener', [
    'screeningQuestion',
])
    .service('screener', technography);
technography.$inject = ['targetingAttributeScreeningQuestion'];

function technography(targetingAttributeScreeningQuestion) {
    return {
        id: 'screeners',
        name: 'Screening Question',
        basic: true,
        extended: true,
        advanced: false,
        attributeList: [targetingAttributeScreeningQuestion],
    };
}
