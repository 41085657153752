import _ from 'lodash';
import U from '../../../../common/js/util';
import {
    Plots,
} from 'plottable';

const Plottable = {
    Plots,
};

const PLOTTABLE_PLOTS = {
        BAR: function(_orientation) {
            return new Plottable.Plots.Bar(_orientation || 'vertical');
        },
        CLUSTEREDBAR: function(_orientation) {
            return new Plottable.Plots.ClusteredBar(_orientation || 'vertical');
        },
        SEGMENT: function() {
            return new Plottable.Plots.Segment();
        },
        SCATTER: function() {
            return new Plottable.Plots.Scatter();
        },
        LINE: function() {
            return new Plottable.Plots.Line();
        },
    },
    IMAGE_TEXT_PLACEHOLDER = getStringOfInvisibleWhitespaceCharsOfNlength(8, '_') + '\n' +
            getStringOfInvisibleWhitespaceCharsOfNlength(8, '_') + '\n' +
            getStringOfInvisibleWhitespaceCharsOfNlength(8, '_');

function isImage(text) {
    return (/^<img([\w\W]+?)>$/i).test(text) || (/^https?:\/\/[\w\W]*$/i).test(text);
}

/* Sidessteps possible xss hole, read more here -- https://stackoverflow.com/questions/1147359/how-to-decode-html-entities-using-jquery/1395954#1395954 */
function decodeEntities(encodedString) {
    var textArea = document.createElement('textarea'),
        returnValue;
    textArea.innerHTML = encodedString;
    returnValue = textArea.value;
    textArea.remove();
    return returnValue;
}

/* Necessary to get svg text writer/measurer to calculate non-visible whitespace giving text elements about 1.625pixels per character */
function getStringOfInvisibleWhitespaceCharsOfNlength(length, spacerChar) {
    var result = _.pad(String.fromCharCode(0x2007), length);

    spacerChar = spacerChar || ' '; // Defaults to space if nothing else provided

    // Return the array as a string
    return result.split('').join(spacerChar);
}

function getTokenizedTransformValue(attributeText) {
    var attrTextGroups = attributeText.match(/([A-Za-z]*.*?[^A-Za-z]\))/g),
        parsedAttrObj = {};

    _.forEach(attrTextGroups, function(attrGroup, _index, _list) {
        var attrGrpTransformType = attrGroup.match(/\w+/, 1)[0], // Get text value since split returns an array
            attrGrpTransfromValues = attrGroup.match(/\d+\.?\d*/g); // Gets all values that are numbers

        parsedAttrObj[attrGrpTransformType] = attrGrpTransfromValues;
    });
    return parsedAttrObj;
}

function categoryFormatter(text) {
    var result;
    if (_.isUndefined(text)) {
        return '';
    }
    text += '';

    // Removing excess whitespace from text
    result = String(text.trim());

    // Decode any html entities present
    result = decodeEntities(result);
    if (isImage(result)) {
        // Remove text content from tick label when we have an image
        return IMAGE_TEXT_PLACEHOLDER;
    }

    // If text contains a version of '<br>' then replace with a space.
    // if text contains html elements, remove the tags and leave the text.
    return result.replace(/(<br\b[^>]*>)/g, ' ').replace(/(<[^<]*>)/g, '');
}

function trackerTimeAxisDateFormatter(d) {
    var format = {
            month: 'MMM',
            day: 'DD',
            delimiters: [' '],
        },
        dates = d.split(' to '),
        startDate = new Date(dates[0]),
        endDate = dates[1] ? new Date(dates[1]) : '';
    if (!endDate || startDate.getMonth() === endDate.getMonth()) {
        delete format.day;
        return U.dateFormatter(startDate, format) + '\n' + startDate.getDate() + (endDate ? '–' + endDate.getDate() : '');
    }
    return U.dateFormatter(startDate, format) + '\n–\n' + U.dateFormatter(endDate, format);
}

function getRawArrayData(data) {
    if (_.isArray(data)) {
        return data;
    }

    // Get values from object which are arrays
    return _.values(data);
}

function dataHasImages(data) {
    var rawData = getRawArrayData(data);

    // If we have some images in data list return true
    return _.some(_.flatten(rawData), function(datum) {
        return isImage(datum.x) || isImage(datum.y);
    });
}

function getNumericLabelText(type) {
    switch (type) {
        case 'count':
            return 'Respondents';
        case 'ranking':
            return 'Average ranking';
        default:
            // Comparison + customVariable show percentage
            return 'Percentage (%)';
    }
}

const myChartUtils = {
    isImage,
    dataHasImages,
    getStringOfInvisibleWhitespaceCharsOfNlength,
    decodeEntities,
    getTokenizedTransformValue,
    getNumericLabelText,
    categoryFormatter,
    trackerTimeAxisDateFormatter,
    IMAGE_TEXT_PLACEHOLDER,
    PLOTTABLE_PLOTS,
};

export default myChartUtils;
