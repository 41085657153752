
import 'angular-animate';
angular.module('svNotify', ['ngAnimate'])
    .directive('lcTimedRemove', ['$timeout', function($timeout) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var mouseOver = false,
                    mouseLeavePromise = null,
                    timedOut = false,
                    timeout = attrs.lcTimedRemove || 10000,
                    position = attrs.position || null,
                    index = attrs.index || null;

                function removeMe() {
                    if (timedOut && !mouseOver) {
                        scope.onTimeout(position, index);
                    }
                }

                element.on('mouseenter', function() {
                    mouseOver = true;
                    if (mouseLeavePromise) {
                        $timeout.cancel(mouseLeavePromise);
                        mouseLeavePromise = null;
                    }
                });
                element.on('mouseleave', function() {
                    mouseLeavePromise = $timeout(function() {
                        mouseOver = false;
                        removeMe();
                    }, 2000);
                });
                $timeout(function() {
                    timedOut = true;
                    removeMe();
                }, timeout);
            },
        };
    }])
    .directive('svNotify', [
        '$compile',
        '$timeout',
        '$state',
        '$rootScope',
        '$notify',
        function($compile, $timeout, $state, $rootScope, $notify) {
            return {
                restrict: 'AC',
                link: function(scope, element, attrs) {
                    // Vars
                    var id = 0,
                        template = '' +
                            '<ul class="simple-notify simple-notify--{{position}}" ng-class="getSpecialPositioningClasses()" ng-attr-id="{{\'simple-notify-container-\' + position}}" ng-repeat="(position, id) in notifications">' +
                            '</ul>',
                        elm = $compile(template)(scope);

                    // Scoped Vars
                    scope.notifications = {
                        'top-left': {},
                        'top-right': {},
                        'bottom-left': {},
                        'bottom-right': {},
                        center: {},
                    };
                    scope.domNotifications = {};

                    // Scoped Functions
                    scope.dismiss = dismiss;
                    scope.onTimeout = onTimeout;
                    scope.buttonPressed = buttonPressed;
                    scope.getSpecialPositioningClasses = getSpecialPositioningClasses;

                    // And ... Begin
                    $('body').append(elm);
                    $notify.initPushNotify(scope, element, id);
                    $notify.initPushClearList(scope, id);
                    // Rootscope breaks on www currently, so this helps get around it. hopefully temporarily
                    $rootScope.ux = $rootScope.ux || {};

                    function dismiss(position, id) {
                        var positionList = scope.notifications[position];
                        if (positionList === null && scope.domNotifications[id]) {
                            scope.domNotifications[id].onTimeout = null;

                            if (scope.domNotifications[id].onDismiss) {
                                scope.domNotifications[id].onDismiss();
                            }
                        }
                        else if (positionList && positionList[id]) {
                            positionList[id].onTimeout = null;

                            if (positionList[id].onDismiss) {
                                positionList[id].onDismiss();
                            }
                            positionList[id].element.remove();
                            delete positionList[id];
                        }
                    }

                    function onTimeout(position, id) {
                        if (position === null && scope.domNotifications[id]) {
                            if (scope.domNotifications[id].onTimeout) {
                                scope.domNotifications[id].onTimeout();
                            }
                            scope.domNotifications[id].element.remove();
                            delete scope.domNotifications[id];
                        }
                        else if (position && scope.notifications[position][id]) {
                            if (scope.notifications[position][id].onTimeout) {
                                scope.notifications[position][id].onTimeout();
                            }
                            scope.notifications[position][id].element.remove();
                            delete scope.notifications[position][id];
                        }
                    }

                    function buttonPressed(position, id) {
                        var positionList = scope.notifications[position];

                        if (positionList === null && scope.domNotifications[id]) {
                            scope.domNotifications[id].onTimeout = null;

                            if (scope.domNotifications[id].button.callback) {
                                scope.domNotifications[id].button.callback();
                            }
                            scope.domNotifications[id].element.remove();
                            delete scope.domNotifications[id];
                        }
                        else if (positionList && positionList[id]) {
                            positionList[id].onTimeout = null;

                            if (positionList[id].button.callback) {
                                positionList[id].button.callback();
                            }
                            positionList[id].element.remove();
                            delete positionList[id];
                        }
                    }

                    function getSpecialPositioningClasses() {
                        var ngClass = '',
                            stateExists = !_.isEmpty($state.current.data);

                        // If expandeds are true, we don't need the other class
                        if ($rootScope.ux.sideNavExpanded) {
                            ngClass += ' simple-notify--avoid-side-nav-expanded';
                        }
                        else if (stateExists && $state.current.data.parent === 'dashboard' || 'account') {
                            ngClass += ' simple-notify--avoid-side-nav';
                        }

                        if ($rootScope.ux.adminBarExpanded) {
                            ngClass += ' simple-notify--avoid-admin-panel';
                        }
                        else if (stateExists && $state.current.data.parent === 'dashboard') {
                            ngClass += ' simple-notify--avoid-admin-bar';
                        }

                        return ngClass;
                    }
                },
            };
        },
    ])
    // The main function for creating a notify popup
    .service('$notify', ['$timeout',
        '$compile',
        function($timeout, $compile) {
            var pushNotify,
                pushClearList;

            function getIconByStatus(status) {
                switch (status) {
                    case 'success':
                        return 'fas fa-check-circle';
                    case 'warning':
                        return 'fas fa-exclamation-triangle';
                    case 'danger':
                        return 'fas fa-bolt';
                    case 'primary':
                    default:
                        return 'fas fa-bell';
                }
            }

            function notificationTemplateBuilder(position, id, options) {
                return '' +
                    '<li class="simple-notify__item simple-notify__item--' + position + ' simple-notify__item--' + options.type +
                    ' simple-notify__item--has-header-' + !!options.heading + '" lc-timed-remove="' + options.timeout +
                    '" position="' + position + '" index= "' + id + '">' +
                    '<i class="simple-notify__icon ' + getIconByStatus(options.type) + '"></i>' +
                    '<div class="simple-notify__item-inner">' +
                    '<button class="close simple-notify__close" type="button" ng-click="dismiss(\'' + position + '\', ' + id + ');">' +
                    '<i class="fas fa-times"></i>' +
                    '</button>' +
                    (options.heading ? '<div class="simple-notify__header">' + options.heading + '</div>' : '') +
                    '<div class="simple-notify__body">' +
                    options.text +
                    '</div>' +
                    (options.button ? '<div class="simple-notify__footer">' +
                        '<button class="btn btn-sv btn-sv--sm btn-sv--block" ng-click="buttonPressed(\'' + position + '\', ' + id + ');">' +
                        options.button.text +
                        '</button>' +
                        '</div>' : '') +
                    '</div>' +
                    '</li>';
            }

            // Gets called by directive
            function initPushClearList(scope) {
                pushClearList = function pushClearList(position) {
                    position = position || 'bottom-left';
                    if (scope.notifications) {
                        angular.forEach(scope.notifications[position], function(elem, id) {
                            scope.dismiss(position, id);
                        });
                    }
                };
            }

            // Gets called by directive
            function initPushNotify(scope, element, id) {
                pushNotify = function pushNotify(data) {
                    $timeout(function() {
                        var options = data.options || {},
                            position = options.position || 'bottom-left',
                            icon = options.icon || '',
                            heading = options.heading || '',
                            timeout = options.timeout || 4000,
                            onTimeout = options.onTimeout || null,
                            onDismiss = options.onDismiss || null,
                            button = options.button || null,
                            clear = options.clear || false,
                            element = position instanceof jQuery ? position : null,
                            simpleNotifyContainers = {};

                        // Note from Nate - I'm not convinced we use element at all
                        if (element) {
                            var btn = button ? '<button class="btn btn-xs btn-default pull-right" type="button" ng-click="buttonPressed(null,' + id + ')">' + button.text + '</button>' : '';
                            var ico = icon ? '<i class="' + icon + ' padding-right-sm"></i>' : '';
                            var alertTemplate = '' +
                                '<div class="alert alert-' + data.type + ' alert-dismissable simple-notify-dom" lc-timed-remove=' + timeout + ' index=' + id + '>' +
                                '<button class="close" type="button" ng-click="dismiss(null, ' + id + ')" data-dismiss="alert" aria-hidden="true">&times;</button>' +
                                ico + data.text + btn +
                                '</div>';

                            var newAlert = $compile(alertTemplate)(scope);

                            if (clear) {
                                angular.forEach(scope.domNotifications, function(x) {
                                    x.element.remove();
                                });
                                scope.domNotifications = {};
                            }
                            element.append(newAlert);
                            scope.domNotifications[id] = {
                                element: newAlert,
                                button: button,
                                onTimeout: onTimeout,
                                onDismiss: onDismiss,
                            };
                        }
                        else {
                            var compiledNotification,
                                notificationOptions;

                            if (clear) {
                                clearList(position);
                            }
                            notificationOptions = scope.notifications[position][id] = {
                                text: data.text,
                                type: data.type,
                                heading: heading,
                                button: button,
                                timeout: timeout,
                                onTimeout: onTimeout,
                                onDismiss: onDismiss,
                            };
                            if (!simpleNotifyContainers[position]) {
                                simpleNotifyContainers[position] = document.getElementById('simple-notify-container-' + position);
                            }
                            compiledNotification = $compile(notificationTemplateBuilder(position, id, notificationOptions))(scope);
                            simpleNotifyContainers[position].appendChild(compiledNotification[0]);
                            scope.notifications[position][id].element = compiledNotification;
                        }
                        id++;
                    }, 4);
                };
            }

            // Clear all existing notifications at the given position
            function clearList(position) {
                pushClearList(position);
                return this; // Allow chaining
            }

            function notify(text, type, options) {
                pushNotify({
                    text: text,
                    type: type,
                    options: options,
                });
            }

            // Notification types
            function primary(text, options) {
                notify(text, 'primary', options);
            }
            function success(text, options) {
                notify(text, 'success', options);
            }
            function warning(text, options) {
                notify(text, 'warning', options);
            }
            function error(text, options) {
                notify(text, 'danger', options);
            }

            return {
                notify: notify,
                primary: primary,
                success: success,
                warning: warning,
                error: error,
                clearList: clearList,
                initPushNotify: initPushNotify,
                initPushClearList: initPushClearList,
            };
        }]);

