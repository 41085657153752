import '@uirouter/angularjs';
import Config from '../config';
import '../survey/survey-statuses';
import surveyConstants from '../survey/survey-constants';
import U from '../../../../common/js/util';

angular.module('surveyCreation.ctrl', [
    'ui.router', 'surveyStatusesService',
])
    .controller('surveyCreationCtrl', surveyCreationCtrl);
surveyCreationCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    '$state',
    '$stateParams',
    '$transitions',
    'surveyStatusesService',
    'surveyService',
];

/**
 * Main survey creation controller
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $timeout
 * @param {object} $state
 * @param {object} $stateParams
 * @param {object} $transitions
 * @param {object} surveyStatusesService
 * @param {object} surveyService
 */
function surveyCreationCtrl($rootScope, $scope, $timeout, $state, $stateParams, $transitions, surveyStatusesService, surveyService) {
    const SurveyCreationVM = this;

    SurveyCreationVM.steps = {
        list: [],
        current: void 0,
        max: void 0,
    };
    SurveyCreationVM.ux = {
        showSteps: true,
    };
    SurveyCreationVM.linkTo = linkTo;

    init();

    function init() {
        let lastSurvey;
        $scope.checkoutUser = $rootScope.auth.user;
        // If we already have the instance of the survey
        if ($rootScope.survey && $rootScope.survey.uuid === $stateParams.surveyId) {
            initSurveys($rootScope.survey);
            return;
        }
        lastSurvey = /^(quote|advanced)$/.test($stateParams.funnelType) ? $rootScope.auth.user.lastQuote : $rootScope.auth.user.lastSurvey;
        // If we need to retrieve the survey corresponding to the survey id
        if ($stateParams.surveyId && U.isValidUuid($stateParams.surveyId)) {
            getSurvey($stateParams.surveyId);
        }
        // If a user wants to edit their previous survey
        else if (lastSurvey && !$rootScope.forceNewSurvey) {
            getSurvey(lastSurvey.uuid);
        }
        // Otherwise, create a survey
        else {
            surveyService.createSurvey({
                surveyPackage: $stateParams.funnelType === 'quote' ? 'advanced' : $stateParams.funnelType || $stateParams.surveyId || surveyConstants.PACKAGES.BASIC,
            }).then(survey => {
                $rootScope.survey = survey;
                createAudience(survey).then(() => {
                    initSurveys(survey);
                });
            });
        }

        $transitions.onBefore({
            to: 'dashboard',
        }, transition => {
            if (!$rootScope.auth.user.isAdmin) {
                transition.abort();
                $state.go('dashboard', {
                    surveyId: $stateParams.surveyId,
                });
            }
        });
        $transitions.onSuccess({
            to: '*',
        }, transition => {
            $rootScope.desiredState = {
                state: angular.copy(transition.$to().name),
                params: angular.copy($stateParams),
            };
            if ($rootScope.survey) {
                updateCurrentSteps((transition.$to().data || {}).step);
            }
        });
    }

    /**
     * Get survey from ID
     *
     * @param {number} surveyId
     * @returns {object}
     */
    function getSurvey(surveyId) {
        return new Promise((resolve, reject) => {
            // Get survey
            surveyService.getSurvey(surveyId).then(survey => {
                $rootScope.survey = survey;
                // Get/create audience
                surveyService.getAudience(survey.uuid).then(audience => {
                    surveyService.setAudienceInSurvey($rootScope.survey, audience);
                    $rootScope.survey.screeningQuestions = audience.screeningQuestions;
                    resolve(survey);
                }, () => {
                    createAudience(survey).then(() => {
                        resolve(survey);
                    }, reject);
                });
            });
        }).then(survey => {
            // Initialize survey if survey and audience are successfully retrieved
            initSurveys(survey);
        }).catch(() => {
            // Redirect to login if user can't get survey
            if ($rootScope.auth.user.anonymous) {
                $rootScope.desiredState = {
                    state: angular.copy($state.current.name),
                    params: angular.copy($stateParams),
                };
                $state.go('signIn');
            }
            else {
                $state.go('account.info');
            }
        });
    }

    /**
     * Check if this survey is in the wrong funnel
     *
     * @param {object} survey
     * @returns {boolean}
     */
    function inWrongFunnelType(survey) {
        return ($stateParams.funnelType !== 'extended' && survey.surveyPackage === 'extended') ||
            ($stateParams.funnelType !== 'quote' && survey.surveyPackage === 'advanced');
    }

    /**
     * Initialize survey creation flow
     *
     * @param {object} survey
     */
    function initSurveys(survey) {
        // If we're somehow in basic flow but survey type is extended, redirect
        if (inWrongFunnelType(survey)) {
            $state.go($state.current.name, surveyStatusesService.getRouteParams($rootScope.survey));
            return;
        }
        SurveyCreationVM.survey = survey;
        $rootScope.funnel = Config.funnelTypes[survey.surveyPackage.toUpperCase()];
        $rootScope.funnelType = $rootScope.funnel.id;

        SurveyCreationVM.funnelType = $rootScope.funnelType.toLowerCase();
        updateSteps();
        if (!$stateParams.surveyId) {
            $state.go('target', surveyStatusesService.getRouteParams($rootScope.survey));
        }
        updateCurrentSteps($stateParams.surveyId ? $state.current.data.step : 'TARGET');

        $timeout(() => {
            SurveyCreationVM.ux.ready = true;
        });
        delete $rootScope.forceNewSurvey;
    }

    /**
     * Link to the given step of the flow
     *
     * @param {object} step
     * @returns {object}
     */
    function linkTo(step) {
        if (!$rootScope.survey.draftCompleted && step.index <= SurveyCreationVM.steps.max.index) {
            return $state.href(step.step.toLowerCase(), surveyStatusesService.getRouteParams($rootScope.survey));
        }
    }

    function updateSteps() {
        const funnel = Config.funnelTypes[$rootScope.survey.surveyPackage.toUpperCase()];
        SurveyCreationVM.steps.list = [];
        _.forEach(Config.steps, function(e, step) {
            if (!e.hide && !funnel.exclude[step]) {
                SurveyCreationVM.steps.list.push({
                    index: e.index,
                    step: step,
                    route: e.route || '',
                    title: e.title || '',
                    description: e.description || '',
                    explainer: e.explainer || '',
                });
            }
        });
    }

    /**
     * Create audience for the survey
     *
     * @param {object} survey
     * @returns {object}
     */
    function createAudience(survey) {
        return surveyService.createAudience(survey.uuid).then(function(audience) {
            surveyService.setAudienceInSurvey($rootScope.survey, audience);
            $rootScope.survey.screeningQuestions = audience.screeningQuestions;
        });
    }

    /**
     * Update current step of flow
     *
     * @param {object} step
     */
    function updateCurrentSteps(step) {
        const funnel = Config.funnelTypes[$rootScope.survey.surveyPackage.toUpperCase()];
        SurveyCreationVM.steps.current = Config.steps[step] || Config.steps.TARGET;
        SurveyCreationVM.ux.showSteps = showSteps();
        if (!SurveyCreationVM.steps.current || funnel.exclude[step]) {
            return;
        }

        if (!SurveyCreationVM.steps.max || SurveyCreationVM.steps.current.index > SurveyCreationVM.steps.max.index) {
            SurveyCreationVM.steps.max = SurveyCreationVM.steps.current;
        }
    }

    /**
     * Should show steps
     *
     * @returns {boolean}
     */
    function showSteps() {
        if (!$rootScope.auth.user || $rootScope.dashboardView || (SurveyCreationVM.survey && SurveyCreationVM.survey.draftCompleted)) {
            console.info('you’re not from this part of town', !$rootScope.auth.user, $rootScope.dashboardView, SurveyCreationVM.survey && SurveyCreationVM.survey.draftCompleted);
            return false;
        }
        return !SurveyCreationVM.steps.current.hideSteps;
    }
}
