import _ from 'lodash';
import httpDeferred from '../../../../common/js/http-deferred';
import '../results-components/stats-module';
import filterLogicConstants from '../filter-logic/filter-logic-constants';
import {
    getEqValueFromSelectedId,
    getStatsParams,
} from './filter-service';
import '../results-components/stats-config-service';

angular.module('compareService', [
    'StatsModule', 'statsConfig.service',
])
    .service('compareService', compareService);
compareService.$inject = [
    '$rootScope',
    '$statsService',
    'statsConfigService',
];

/**
 * Defines the compare service.
 *
 * @param {*} $rootScope
 * @param {*} $statsService
 * @param {*} statsConfigService
 * @returns {*}
 */
function compareService($rootScope, $statsService, statsConfigService) {
    /**
     * Gets comparison stats param.
     *
     * @name getComparisonStatsParam
     * @param {object} params - Params that are needed in getStatsParams, including comparison data
     * @description We can't use the crosstab endpoint for clickmaps and free response questions -
     * this function returns the query params necessary to fetch clickmap/free response comparisons
     * @returns {*}
     */
    function getComparisonStatsParam(params) {
        const filterBy = [],
            baseFilterBy = getStatsParams(params)[0];

        _.forEach(params.comparisonData, comparison => {
            const copy = _.cloneDeep(baseFilterBy);
            _.forEach(comparison, data => {
                copy.$and.push(data.length < 2 ? {
                    $eq: getEqValueFromSelectedId(data[0].id),
                } : {
                    $or: _.map(data, val => {
                        return {
                            $eq: getEqValueFromSelectedId(val.id),
                        };
                    }),
                });
            });
            filterBy.push(copy);
        });
        return filterBy;
    }
    /**
     * Get comparison params.
     * params contains:
     * {object} question - The question to get data for
     * {string} type - Demographic$Age | Demographic$Gender | Beacon$Pool
     * {object} tallyParams - The filter and comparison to be applied to data.
     *
     * @name getComparisonParam
     * @param {*} params
     * @returns {object} params that contains significance_level, row_keys, and banner_keys.
     */
    function getComparisonParams(params) {
        let type = _.keys(params.comparisonData[0])[0],
            comparisonParams = {
                // eslint-disable-next-line camelcase
                banner_keys: type === 'Demographic$Gender' ? [{
                    type: 'Demographic',
                    field: 'Gender',
                }] : [{
                    type: 'Synthetic',
                    statement: type,
                    definition: getComparisonDefinitions(type, params.comparisonData),
                }],
                // eslint-disable-next-line camelcase
                row_keys: [],
                // eslint-disable-next-line camelcase
                significance_level: 0.95,
            },
            filterBy = getStatsParams(params)[0];

        if (filterBy.$and && !filterBy.$and.length) {
            return comparisonParams;
        }
        if (!filterBy.$and) {
            filterBy = {
                $and: [filterBy],
            };
        }
        // eslint-disable-next-line camelcase
        comparisonParams.filter_by = filterBy;
        return comparisonParams;
    }

    /**
     * Get comparison definitions.
     *
     * @param {*} type
     * @param {*} comparisonData
     * @returns {{}}
     */
    function getComparisonDefinitions(type, comparisonData) {
        const names = ['', ''],
            definitionArray = [{
                $or: [],
            }, {
                $or: [],
            }],
            definitions = {};
        _.forEach(comparisonData, (comparison, idx) => {
            var length = comparison[type].length - 1;
            _.forEach(comparison[type], (data, i) => {
                names[idx] += removeControlPiping(data.name) + (i === length ? '' : ', ');
                definitionArray[idx].$or.push({
                    $eq: type === filterLogicConstants.criteriaTypes.BEACON_POOL ? [data.id, 1] : [type, data.code || data.id || 1],
                });
            });
        });
        definitions[names[0]] = definitionArray[0];
        definitions[names[1]] = definitionArray[1];
        return definitions;
    }

    /**
     * Removes control piping.
     *
     * @param {string} name
     * @returns {string|*}
     */
    function removeControlPiping(name) {
        return name === '|Survata Control|' || name === '|Control|' ? 'Control' : name;
    }

    /**
     * Gets comparison question tally.
     *
     * @param {*} survey
     * @param {*} question
     * @param {*} params
     * @returns {Promise<*>}
     */
    function getComparisonQuestionTally(survey, question, params) {
        //Remove when stats server supports ranking, rating, etc
        if (question.details.type === 'longFreeResponse' || question.details.type === 'clickmap') {
            return getComparisonQuestionTallyDeprecated(survey, question, params);
        }
        const type = _.keys(params.comparisonData[0])[0],
            tallyParams = _.cloneDeep(params.tallyParams),
            labels = type === filterLogicConstants.criteriaTypes.GENDER ? ['female', 'male'] : _.keys(tallyParams.banner_keys[0].definition);
        let paramString;
        tallyParams.row_keys.push({
            type: question.screener ? 'Screener' : 'Question',
            number: question.index,
        });
        if (!_.isUndefined(question.gridIndex)) {
            tallyParams.row_keys[0].line = question.gridIndex;
        }
        paramString = escape(JSON.stringify(tallyParams));

        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/crosstab?useStagingStatsServer=true&crosstabRequest=' + paramString).then(response => {
            return _.map(labels, label => {
                return parseComparisonResponse(response.result, label, question);
            });
        }, status => {
            return status && status.msg ? status.msg : status || 'Somthing dun fucked up';
        });
    }

    /**
     * Parse comparison response.
     *
     * @param {*} response
     * @param {*} label
     * @param {*} question
     * @returns {*}
     */
    function parseComparisonResponse(response, label, question) {
        if (!response) {
            return statsConfigService.generateEmptyComparisonResponse(label, question);
        }
        var comparison = {
                caveats: response.countStatistics.caveats,
                pValues: response.countStatistics.pValues,
                significance: response.countStatistics.sortedCellwiseSignificance,
                respondentsShownChoice: response.responsesByQuestion.respondentsByRow,
                numRespondents: response.crosstabWithMarginalTotals.All.All,
                countTally: {},
                countProportions: {},
                confidenceIntervalsOverProportions: {},
            },
            type = question.type;
        if (type !== 'clickmap') {
            comparison.label = label;
        }
        _.forEach(response.crosstabWithMarginalTotals, function(counts, choice) {
            // '+' is replaced by ' ' in the response returned by backend
            //Ex: '65+ yrs' ==> '65  yrs' in response
            var replaced = label.replace('+', ' ');
            if (choice === 'All') {
                return;
            }
            comparison.countTally[choice] = counts[replaced];
            comparison.countProportions[choice] = counts[replaced] / response.crosstabWithMarginalTotals.All[replaced];
            comparison.confidenceIntervalsOverProportions[replaced] = {
                'Lower Bound': 0,
                'Upper Bound': 0,
            };
        });
        if (type === 'rating') {
            comparison.metricTally = {
                label: response.numericCrosstab.rating[label],
            };
            comparison.pValue = response.numericStatistics.tablePValue;
        }
        else if (type === 'ranking') {
            comparison.metricTally = {};
            _.forEach(response.numericCrosstab, (values, key) => {
                comparison.metricTally[key] = values[label];
            });
        }
        else if (type === 'numeric') {
            var surveyQuestion = _.find($rootScope.survey.questions, {
                uuid: question.uuid,
            });
            comparison.minMax = {
                minimumValue: surveyQuestion.details.minValue,
                maximumValue: surveyQuestion.details.maxValue,
            };
            comparison.metricTally = {
                label: response.numericCrosstab.numeric[label],
            };
        }
        return comparison;
    }

    /**
     * Gets comparison question tall deprecated.
     *
     * @param {*} survey
     * @param {*} question
     * @param {*} params
     * @returns {Promise<*>}
     */
    function getComparisonQuestionTallyDeprecated(survey, question, params) {
        return $statsService.getQuestionTally(survey, question, {
            tallyParams: getComparisonStatsParam(params),
            comparisonData: params.comparisonData,
            collectionPeriods: params.collectionPeriods,
        }).then(comparisons => {
            _.forEach(comparisons, (comparison, comparisonIdx) => {
                let comparisonLabel = getComparisonGroupTitle(params.comparisonData[comparisonIdx]);
                comparison.label = comparisonLabel;
                if (question.details.type === 'grid') {
                    _.forEach(comparison, gridRow => {
                        gridRow.label = comparisonLabel;
                    });
                }
            });
            return comparisons;
        }, status => {
            // Handle error
            return status && status.msg ? status.msg : status || 'Somthing dun fucked up';
        });
    }

    /**
     * Remove when stats server supports ranking, rating, etc
     *
     * @param {*} compData
     * @returns {string}
     */
    function getComparisonGroupTitle(compData) {
        var comparisonNames = [];
        _.forEach(compData, function(comp) {
            let pluck = comp[0].code ? 'code' : comp[0].id ? 'id' : comp[0].key ? 'key' : void 0,
                plucked = _.map(comp, pluck) || comp;
            comparisonNames = comparisonNames.concat(plucked);
        });
        return comparisonNames.join(', ');
    }

    return {
        getComparisonQuestionTally,
        getComparisonParams,
        getComparisonStatsParam,
    };
}
