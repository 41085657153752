import '@uirouter/angularjs';
import httpDeferred from '../../../../common/js/http-deferred';
import U from '../../../../common/js/util';
import './stats-config-service';

angular.module('StatsModule', [
    'ui.router', 'statsConfig.service',
])
    .service('$statsService', statsService);
statsService.$inject = [
    '$state', 'statsConfigService',
];

/**
 * Defines the stats service.
 *
 * @param {*} $state
 * @param {*} statsConfigService
 * @returns {*}
 */
function statsService($state, statsConfigService) {
    var _statsConfig = {
        filters: {},
    };

    /**
     * Fetch the stats schema from stats server
     *
     * @param {string} surveyUuid
     * @returns {Promise<*>}
     */
    function getStatsSchema(surveyUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/stats-schema');
    }

    // /**
    //  * Gets Survey's impression parameter groups.
    //  *
    //  * @param {string} surveyUuid
    //  * @returns {Promise<*>}
    //  */
    // function listImpressionParametersGroups(surveyUuid) {
    //     return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/impression-parameter-group/');
    // }

    /**
     * Get question tally.
     *
     * @param {*} survey
     * @param {*} question
     * @param {*} params
     * @returns {Promise<*>}
     */
    function getQuestionTally(survey, question, params) {
        var dates = params.collectionPeriods && params.collectionPeriods.dates,
            calls = [],
            questionParam;
        return new Promise((resolve, reject) => {
            if (question.details.type === 'introStatement') {
                resolve({});
                return;
            }
            questionParam = {
                type: question.screener ? 'screener' : 'question',
                number: question.index,
            };
            if (!_.isUndefined(question.line)) {
                questionParam.line = parseInt(question.line);
            }

            _.forEach(params.tallyParams, param => {
                let filter = angular.copy(U.trimObject(param.filter_by ? param : {
                    // eslint-disable-next-line camelcase
                    filter_by: param,
                }));
                filter.question = questionParam;
                calls.push(httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/question-tally', {
                    tallyRequest: filter,
                }));
            });
            Promise.all(calls).then(periodData => {
                resolve(statsConfigService.processQuestionTallyResponse(question, params, periodData, dates));
            }, status => {
                reject(status.error || status);
            });
        });
    }

    /**
     * Get tally summary.
     *
     * @param {*} survey
     * @param {*} params
     * @returns {Promise<*>}
     */
    function getTallySummary(survey, params) {
        params = params || {};
        params = U.trimObject(params);
        return new Promise(resolve => {
            httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/tally-summary?useStagingStatsServer=true', {
                tallyRequest: params,
            }).then(summary => {
                let unfiltered;
                summary.result = summary.result || statsConfigService.generateEmptySummary(survey);
                unfiltered = summary.result.unfiltered || summary.result;
                resolve({
                    unfiltered: unfiltered,
                    filtered: (summary.result.filtered && summary.result.filtered) || unfiltered,
                });
            }, () => {
                let emptyResponse = statsConfigService.generateEmptySummary(survey);
                resolve({
                    unfiltered: emptyResponse,
                    filtered: emptyResponse,
                });
            });
        });
    }

    /**
     * Get respondent data.
     *
     * @param {*} survey
     * @param {*} tallyParams
     * @param {*} periods
     * @returns {Promise<*>}
     */
    function getRespondentData(survey, tallyParams, periods) {
        const calls = [];
        _.forEach(tallyParams, param => {
            calls.push(getTallySummary(survey, param.filter_by ? param : {
                // eslint-disable-next-line camelcase
                filter_by: param,
            }));
        });
        return Promise.all(calls).then(periodSummaries => {
            return statsConfigService.processRespondentData(survey, periods, periodSummaries);
        });
    }

    /**
     * Initialize stats config.
     *
     * @param {*} survey
     * @returns {Promise<*>}
     */
    function initializeStatsConfig(survey) {
        return new Promise((resolve, reject) => {
            getStatsSchema(survey.uuid).then(schema => {
                schema.biographic = schema.biographic || {};
                let mappedHeaders = _.map(schema.biographic.headers, (val, idx) => {
                    return {
                        name: val,
                        code: 'H' + idx,
                        type: 'header',
                    };
                });
                if (schema.beacons) {
                    _statsConfig.impressionCountColumns = {};
                    _.assignIn(_statsConfig.impressionCountColumns, schema.beacons.impressionParameterGroups, {
                        ___beacons___: schema.beacons.beacons.columnPositions,
                    });
                }
                _statsConfig.headers = statsConfigService.filterStatsHeaders(survey, mappedHeaders);
                _statsConfig.questions = statsConfigService.processStatsQuestion(schema.questions);
                _statsConfig.impressionParameterGroups = [];
                survey._isTracker = survey.collectionPeriods.length > 1;
                if (survey._isTracker) {
                    let periodBanner = _.find(mappedHeaders, item => {
                        return item.name === 'Period';
                    });
                    _statsConfig.periodKey = periodBanner && periodBanner.code;
                }
                // Initialize filters
                statsConfigService.initializeFilters(survey, _statsConfig, schema).then(response => {
                    _statsConfig.filters = response;
                    survey._statsConfig = _statsConfig;
                    resolve(_statsConfig);
                }, () => {
                    // This should never happen, but just in case
                    reject(new Error('Your survey results are currently being processed. Please check back soon. If this problem persists please contact us.'));
                });
            }, () => {
                reject(new Error('Your survey results are currently being processed. Please check back soon. If this problem persists please contact us.'));
            });
        });
    }

    /**
     * Get variable tally.
     *
     * @param {*} survey
     * @param {*} cv
     * @returns {Promise<*>}
     */
    function getVariableTally(survey, cv) {
        const promises = [];
        _.forEach(cv.options, option => {
            promises.push(new Promise(resolve => {
                getTallySummary(survey, {
                    // eslint-disable-next-line camelcase
                    filter_by: option.filterJson,
                }).then(response => {
                    resolve(response);
                }, () => {
                    resolve({
                        filtered: {
                            numRespondents: 0,
                        },
                    });
                });
            }));
        });

        return Promise.all(promises).then(response => {
            const parsed = {};
            let total = 0;
            _.forEach(response, (tallies, index) => {
                parsed[cv.options[index].name] = tallies.filtered.numRespondents;
                total += tallies.filtered.numRespondents;
            });
            return {
                countTally: parsed,
                numRespondents: total,
            };
        });
    }

    return {
        getStatsSchema,
        getQuestionTally,
        getTallySummary,
        getVariableTally,
        getRespondentData,
        initializeStatsConfig,
    };
}
