import '@uirouter/angularjs';
import '../../../../common/js/inc/sv-notify';
import '../app-components/api-account-service';
import authService from './auth-service';

angular.module('signIn.ctrl', [
    'ui.router',
    'svNotify',
    'apiAccountService',
])
    .controller('SignInController', SignInController)
    .directive('signIn', signIn);
SignInController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$notify',
    '$state',
    'accountService',
];

function SignInController($scope, $rootScope, $timeout, $notify, $state, accountService) {
    $scope.email = authService.get('email') || '';
    $scope.password = authService.get('password') || '';
    $scope.status = '';
    $scope.submitted = false;
    $state.viewingDashboard = $state.current.name.indexOf('dashboard') !== -1;

    $scope.$watch('email', function() {
        authService.sync('email', $scope.email);
    });
    $scope.$watch('password', function() {
        authService.sync('password', $scope.password);
    });

    $scope.signIn = signIn;
    $scope.signUp = signUp;

    function signIn(ngFormCtrl) {
        if ($scope.status === 'during') {
            return;
        }

        if (ngFormCtrl.$invalid) {
            $scope.status = 'during';
            $timeout(function() {
                $scope.submitted = true;
                ngFormCtrl.$setPristine();
                $scope.status = '';
            }, 125);
            return;
        }

        $scope.status = 'during';
        accountService.signIn($scope.email, $scope.password).then(function(user) {
            $rootScope.auth.user = user;
            $scope.status = 'success';
            $notify.primary('You have successfully signed in.', {
                position: 'bottom-right',
                timeout: 3000,
            });

            $timeout(function() {
                $scope.ux.accountView = null;
                authService.reset();
            }, 250);
        },
        function() {
            $scope.status = 'error';
            ngFormCtrl.$setPristine();
        });
    }

    function signUp() {
        $scope.ux.accountView = 'signUp';
        $state.go('signUp');
    }
}

function signIn() {
    return {
        restrict: 'E',
        templateUrl: '/account-templates/sign-in.html',
        controller: 'signIn',
    };
}
