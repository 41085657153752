import '@uirouter/angularjs';
import pricingInformation from '../../../../common/js/survey-packages';
import '../../../../common/js/moe-service';
import '../survey-creation/survey-creation-service';
import '../survey/api-survey-service';
import '../survey/survey-statuses';

angular.module('surveySample.ctrl', ['ui.router', 'surveyCreation.service'])
    .controller('SurveySampleCtrl', SurveySampleCtrl);
SurveySampleCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'surveyService',
    '$moeService',
    'surveyCreationService',
    'surveyStatusesService',
];

/**
 * Constructor function creates an instance of the Survey Sample controller
 *
 * @param {object} $rootScope -- global binding
 * @param {object} $scope -- local binding
 * @param {object} $state
 * @param {object} $stateParams -- all params, including any in child states
 * @param {object} surveyService -- primary surveys service
 * @param {object} $moeService -- service for margin of error
 * @param {object} surveyCreationService -- service for creating surveys
 * @param {object} surveyStatusesService -- service for managing survey statuses
 */
function SurveySampleCtrl($rootScope, $scope, $state, $stateParams, surveyService, $moeService, surveyCreationService, surveyStatusesService) {
    var SurveySampleVM = this;

    const NEXT_BUTTON_TEXT = {
        ACCOUNT: 'Enter Account Info',
        QUOTE: 'Get a Quote',
    };
    const ADVANCED = 'advanced';
    const CUSTOM = 'custom';
    SurveySampleVM.minSampleSize = $rootScope.funnelType === ADVANCED ? 50 : $rootScope.auth.user.minimumNetworkRespondents || 200;
    SurveySampleVM.ux = {
        nextUrl: getUrl(true),
        backUrl: getUrl(),
        showErrors: false,
        sampleUnsure: false,
        selectedPackageId: $rootScope.funnelType === ADVANCED ? CUSTOM : 'standardRead',
        accordian: {
            closeOthers: true,
            specify: false,
            unsure: false,
        },
    };
    SurveySampleVM.openSaveModal = surveyCreationService.openSaveModal;
    SurveySampleVM.customPackage = {};
    SurveySampleVM.selectedPackage = {};
    SurveySampleVM.packages = pricingInformation.samplePackages;
    SurveySampleVM.extras = pricingInformation.extras;
    SurveySampleVM.isInteger = isInteger;
    SurveySampleVM.isValidSampleSize = isValidSampleSize;
    SurveySampleVM.marginOfError = marginOfError;
    SurveySampleVM.selectPackage = selectPackage;
    SurveySampleVM.getUrl = getUrl;
    SurveySampleVM.next = next;
    SurveySampleVM.nextButtonText = nextButtonText;
    SurveySampleVM.openSaveModal = surveyCreationService.openSaveModal;

    SurveySampleVM.survey = $rootScope.survey;
    SurveySampleVM.rootux = $rootScope.rootux;
    SurveySampleVM.isBasicFlow = surveyStatusesService.isBasicFlow();
    SurveySampleVM.isExtendedFlow = surveyStatusesService.isExtendedFlow();
    SurveySampleVM.isAdvancedFlow = surveyStatusesService.isAdvancedFlow();
    SurveySampleVM.datePickerOptions = {
        class: {
            input: 'text-right',
        },
        minDate: new Date(),
        dateOptions: {
            endDate: {
                initDate: new Date(),
            },
        },
        ngChange: angular.noop,
    };
    if (!$rootScope.auth.user.isAdmin) {
        $state.go('dashboard', {
            surveyId: $stateParams.surveyId,
        });
    }

    if ($rootScope.survey.latestCollectionPeriod) {
        init();
    }
    else {
        setSurvey();
    }

    /**
     * Sets the terms of a new survey
     *
     * @returns {Promise}
     */
    function setSurvey() {
        return new Promise((resolve, reject) => {
            // Get survey
            surveyService.getSurveyVerbose($rootScope.survey.uuid).then(survey => {
                // This is a safeguard since rootScope.survey was previously missing
                // multiple properties
                $scope.$evalAsync(() => {
                    $rootScope.survey = survey;
                    resolve(init(survey));
                });
            }, reject);
        });
    }

    /**
     * Registers the package that the user selects
     *
     */
    function init() {
        var defaultPackage;
        _.forEach(SurveySampleVM.packages, function(e) {
            if (e.sampleSize === ($rootScope.survey.latestCollectionPeriod || {}).sampleSize && !e.custom && $rootScope.funnelType !== ADVANCED) {
                SurveySampleVM.selectedPackage = e;
            }
            else if (e.custom) {
                SurveySampleVM.customPackage = e;
                if ($rootScope.funnelType === ADVANCED) {
                    defaultPackage = e;
                }
            }
            else if (e.id === SurveySampleVM.ux.selectedPackageId) {
                defaultPackage = e;
            }
        });

        // If a standard package not selected
        if (!SurveySampleVM.selectedPackage.id) {
            if ($rootScope.survey.latestCollectionPeriod && $rootScope.survey.latestCollectionPeriod.sampleSize) {
                SurveySampleVM.customPackage.sampleSize = $rootScope.survey.latestCollectionPeriod.sampleSize;
                SurveySampleVM.selectedPackage = SurveySampleVM.customPackage;
            }
            else {
                SurveySampleVM.selectedPackage = defaultPackage;
            }
        }

        // Set the selected package id for the radio button (which is just for the UI)
        SurveySampleVM.ux.selectedPackageId = SurveySampleVM.selectedPackage.id;
        if (!SurveySampleVM.selectedPackage.custom) {
            // Clear custom package size if other package selected
            SurveySampleVM.customPackage.sampleSize = null;
        }

        if ($rootScope.survey.surveyQuoteRequest && $rootScope.survey.surveyQuoteRequest.respondentsUnsure) {
            SurveySampleVM.ux.accordian.unsure = true;
            SurveySampleVM.ux.accordian.specify = false;
            SurveySampleVM.ux.sampleUnsure = true;
        }
        else if (SurveySampleVM.customPackage.sampleSize) {
            SurveySampleVM.ux.accordian.specify = true;
            SurveySampleVM.ux.accordian.unsure = false;
            SurveySampleVM.ux.sampleUnsure = false;
        }
    }

    /**
     * Checks to make sure that the sample size is a number
     *
     * @param {number} s
     * @returns {boolean}
     */
    function isInteger(s) {
        return _.isInteger(parseInt(s, 10));
    }

    /**
     * Checks to make sure that the sample size is a number and above the minimum sample size
     *
     * @param {number} x
     * @returns {boolean}
     */
    function isValidSampleSize(x) {
        return SurveySampleVM.isInteger(x) && (x >= SurveySampleVM.minSampleSize);
    }

    /**
     * Checks if the margin of error (MOE) is at a 95% confidence
     *
     * @param {number} sampleSize
     * @returns {boolean}
     */
    function marginOfError(sampleSize) {
        return $moeService.calculateMoe(sampleSize);
    }

    /**
     * Registers the package that the user selects
     *
     * @param {number} selectedPackage
     */
    function selectPackage(selectedPackage) {
        SurveySampleVM.selectedPackage = selectedPackage;
        SurveySampleVM.ux.selectedPackageId = SurveySampleVM.selectedPackage.id;
    }

    /**
     * Checks the kind of account that the user has and returns the appropriate url accordingly
     *
     * @param {boolean} forward
     * @returns {object}
     */
    function getUrl(forward) {
        const loggedIn = !!($rootScope.auth && $rootScope.auth.user && !$rootScope.auth.user.anonymous),
            params = surveyStatusesService.getRouteParams($rootScope.survey);
        if (forward) {
            if (loggedIn) {
                return $state.href('confirm', params);
            }
            return $state.href('contact_info', params);
        }
        return $state.href('design', params);
    }

    /**
     * Runs checks to verify user's selections
     *
     */
    function next() {
        if (!SurveySampleVM.selectedPackage.id) {
            SurveySampleVM.ux.showErrors = true;
            return;
        }
        $rootScope.survey.sampleSize = $rootScope.funnelType === ADVANCED ? SurveySampleVM.customPackage.sampleSize : SurveySampleVM.selectedPackage.sampleSize;

        // If user is on "specify sample size tab" but doesn't input anything, switch to sample unsure
        if ($rootScope.funnelType === ADVANCED && !$rootScope.survey.sampleSize && $rootScope.survey.sampleSize !== 0) {
            SurveySampleVM.ux.sampleUnsure = true;
        }

        if (SurveySampleVM.ux.sampleUnsure || SurveySampleVM.isValidSampleSize($rootScope.survey.sampleSize)) {
            surveyService.updateLatestPeriod($rootScope.survey.uuid, {
                sampleSize: $rootScope.survey.sampleSize || 0,
            }).then(function(periodResponse) {
                $rootScope.survey.latestCollectionPeriod = periodResponse;

                if (surveyStatusesService.isAdvancedFlow()) {
                    surveyService.updateQuoteRequest($rootScope.survey.uuid, {
                        respondentsUnsure: SurveySampleVM.ux.sampleUnsure,
                    }).then(function(response) {
                        $rootScope.survey.surveyQuoteRequest = response;
                    });
                }
            });
        }
        else {
            SurveySampleVM.ux.showErrors = true;
        }
    }

    /**
     * Decides what text the button in survey setup should display based on the user's choices
     *
     * @returns {string}
     */
    function nextButtonText() {
        if ($rootScope.auth.user.anonymous) {
            return NEXT_BUTTON_TEXT.ACCOUNT;
        }
        return NEXT_BUTTON_TEXT.QUOTE;
    }
}
