import _ from 'lodash';
import '../../../../common/js/angular-util';
import '../common-components/deferred';
import '../common-components/pub-sub';
import './campaign-manager-api-service';
import './api-beacon-service';
import tagCreationConstant from './tag-creation-constant';
import {
    partnerTypeSortFunction,
} from './tag-creation.ad-server-constants';
import '../../../../common/js/inc/sv-notify';

angular.module('tagCreation.modal', [
    'common.deferred',
    'pubSub',
    'campaignManagerApi',
    'svNotify',
    'apiBeaconService',
])
    .controller('TagCreationModalCtrl', TagCreationModalCtrl);
TagCreationModalCtrl.$inject = [
    '$scope',
    '$q',
    '$uibModalInstance',
    'Deferred',
    'beaconService',
    'tag',
    'generatingCode',
    'userId',
    'deferred',
    '$notify',
    'mediaPartnerService',
];

/**
 * Controller for tag creation modal.
 *
 * @param {object} $scope - The scope for this controller
 * @param {object} $q - Angular deferred service
 * @param {object} $uibModalInstance - Instance of the containing modal
 * @param {object} Deferred - Promise service
 * @param {object} beaconService - Service for beacon CRUD
 * @param {object} [tag] - Tag to edit
 * @param {boolean} generatingCode - Whether we want to generate code
 * @param {number} userId - Id of the user we're creating tags for
 * @param {Promise} deferred - Promise passed from beacons controller
 * @param {object} $notify - Notification service
 * @param {object} mediaPartnerService - Media partners service
 */
function TagCreationModalCtrl($scope, $q, $uibModalInstance, Deferred, beaconService, tag, generatingCode, userId, deferred, $notify, mediaPartnerService) {
    var TagCreationVM = this,
        DEFAULT_OPENING = '<img width="0" height="0" src="https://ev.surveywall-api.survata.com/r?eid=',
        PLACEHOLDER = 'PLACEHOLDER',
        tagParts = {
            opening: DEFAULT_OPENING,
            uuid: PLACEHOLDER,
            params: '',
            location: '',
            closing: '">',
        },
        macroCsvData = '',
        adServerCsvData = '';

    const DEVICE_ID = 'deviceId',
        MOBILE_TAG = 'mobile',
        COOKIE_AND_MOBILE_TAG = 'cookieAndMobile',
        DEFAULT_IMPRESSION_PARAMETERS = 'DEFAULT_IMPRESSION_PARAMETERS';

    // VM exposed variables
    TagCreationVM.ux = {
        editImpressionParameters: false,
        disableDMPRedirect: false,
    };
    TagCreationVM.beacon = {
        params: [],
        tags: [],
        adServer: {
            value: '',
        },
        dmp: {},
        newParamOrder: '',
    };
    TagCreationVM.userDefinedEventId = null;
    TagCreationVM.types = angular.copy(tagCreationConstant.get('BEACON_TYPES'));
    TagCreationVM.redirects = tagCreationConstant.get('REDIRECTS');

    // Convert the tagCreationConstant.IMPRESSION_PARAMETERS into a list for the UI
    TagCreationVM.impressionParameters = Object.values(tagCreationConstant.get('IMPRESSION_PARAMETERS'));

    // Initialize the list of adServers with an empty list
    TagCreationVM.adServer = [];
    TagCreationVM.macrosUrlParam = tagCreationConstant.get('MACROS_URL_PARAM');
    TagCreationVM.dividerIndex = tagCreationConstant.get('REDIRECT_DIVIDER_INDEX');

    // VM exposed functions
    TagCreationVM.setTagLocation = setTagLocation;
    TagCreationVM.closeModal = $uibModalInstance.close;
    TagCreationVM.disableSave = disableSave;
    TagCreationVM.selectType = selectType;
    TagCreationVM.selectAdServer = selectAdServer;
    TagCreationVM.selectRedirects = selectRedirects;
    TagCreationVM.saveTag = saveTag;
    TagCreationVM.exportAsCsv = exportAsCsv;
    TagCreationVM.generateTagParams = generateTagParams;
    TagCreationVM.copyToClipboard = copyToClipboard;
    TagCreationVM.fetchPreview = fetchPreview;
    TagCreationVM.allowableChannelsForSelectedAdServer = allowableChannelsForSelectedAdServer;
    TagCreationVM.changedOrdering = changedOrdering;
    TagCreationVM.toggleViewabilityData = toggleViewabilityData;
    TagCreationVM.toggleEditImpressionParameters = toggleEditImpressionParameters;
    TagCreationVM.generateAdServerTag = generateAdServerTag;
    TagCreationVM.tagReadyForSave = tagReadyForSave;
    TagCreationVM.showViewabilityToggle = showViewabilityToggle;
    TagCreationVM.showCopyToClipboard = showCopyToClipboard;
    TagCreationVM.disableDMPRedirectDropdown = disableDMPRedirectDropdown;
    TagCreationVM.onDisableDMPRedirectChanged = onDisableDMPRedirectChanged;
    TagCreationVM.disableSaveButton = disableSaveButton;

    init();

    /**
     * Initialize dropdowns
     *
     * @param {object} [fullBeacon]
     * @returns {Promise}
     */
    function initializeDropdowns(fullBeacon) {
        let deferred = new Deferred();

        // Execute the http request to fetch the media partners
        deferred(mediaPartnerService.listMediaPartners()).then(mediaPartners => {
            TagCreationVM.adServer = mediaPartners;
            TagCreationVM.adServer.sort(partnerTypeSortFunction);

            if (fullBeacon) {
                // Initialize the ad server dropdown
                const currentAdServer = mediaPartners.find(item => {
                    return fullBeacon.mediaPartnerId === item.id;
                });
                if (currentAdServer) {
                    TagCreationVM.beacon.adServer = currentAdServer;
                }

                // Initialize the tag type dropdown
                const currentTagType = TagCreationVM.types.find(item => {
                    return fullBeacon.beaconType === item.value;
                });
                if (currentTagType) {
                    TagCreationVM.beacon.beaconType = currentTagType;
                }

                initializeTagPreview();
            }
        });

        return deferred.promise();
    }

    /**
     * Initialize the preview of the tag
     */
    function initializeTagPreview() {
        generateTagParams();
        generateAdTagParams();
        fetchPreview();
        preventCommaInName();
    }

    function init() {
        if (!tag) {
            TagCreationVM.creatingNew = true;
            TagCreationVM.beacon.params = tagCreationConstant.get(DEFAULT_IMPRESSION_PARAMETERS);
            TagCreationVM.beacon.hasImpressionParameters = true;

            initializeDropdowns();
            return;
        }

        let deferred = new Deferred();
        TagCreationVM.generatingCode = generatingCode;

        deferred(beaconService.getFullTag(tag)).then(function(fullBeacon) {
            TagCreationVM.beacon = fullBeacon;
            // Set the dmp to it's default if the server returned null
            if (!TagCreationVM.beacon.dmp) {
                TagCreationVM.beacon.dmp = {};
            }

            deferred(initializeDropdowns(fullBeacon)).then(() => {
                const impressionParamArr = TagCreationVM.beacon.paramString.split(',');
                TagCreationVM.beacon.hasImpressionParameters = impressionParamArr.length > 0;
                TagCreationVM.ux.hasViewabilityData = _.indexOf(impressionParamArr, 'viewabilityLevel') > -1;
                tagParts.params = TagCreationVM.beacon.paramString;
                tagParts.opening = getTagOpeningForType(TagCreationVM.beacon.beaconType);
                tagParts.uuid = tag.uuid;
            });
        });

        deferred($uibModalInstance.rendered);
        deferred.promise().then(
            initializeTagPreview,
            initializeTagPreview
        );
    }

    /**
     * Determine whether to disable the DMP redirect dropdown, based on a bunch of
     * other UX status.
     *
     * @returns {boolean} Whether to disabled the DMP redirect dropdown
     */
    function disableDMPRedirectDropdown() {
        return TagCreationVM.ux.disableDMPRedirect || TagCreationVM.ux.editImpressionParameters ||
            !(TagCreationVM.beacon.beaconType && TagCreationVM.beacon.beaconType.value) ||
            (TagCreationVM.beacon.adServer && TagCreationVM.beacon.adServer.paramName === 'private');
    }

    /**
     * Callback for when DMP redirect disabled status is changed.
     */
    function onDisableDMPRedirectChanged() {
        if (TagCreationVM.ux.disableDMPRedirect) {
            TagCreationVM.beacon.dmp = tagCreationConstant.get('REDIRECT_NONE');
            TagCreationVM.selectRedirects(TagCreationVM.beacon.dmp);
        }
        else {
            TagCreationVM.beacon.dmp = {};
        }
    }

    /**
     * Depending on the currently selected ad-server, filter by the channels that are available.
     *
     * For example: The ad server TTD has boolean properties set to 'true' for cookie, cookieAndMobile, ipaddress, and mobile.
     * so the channel dropdown should display all of those as options.
     *
     * @param {object} channel - Tag channel type
     * @returns {boolean} Whether to filter out an channel type
     */
    function allowableChannelsForSelectedAdServer(channel) {
        return !!(TagCreationVM.beacon.adServer && TagCreationVM.beacon.adServer[channel.value]);
    }

    /**
     * Function that prevents inserting commas into a beacon name
     */
    function preventCommaInName() {
        if (TagCreationVM.creatingNew) {
            return;
        }

        var keypressBoundElem = angular.element('#beacon-name');
        keypressBoundElem.keypress(function(e) {
            return e.which !== 44;
        });

        $scope.$on('$destroy', function() {
            keypressBoundElem.off('keypress');
        });
    }

    /**
     * Fetches a newly generated tag (or ad server tag) to be shown in the tag preview section of the UI
     */
    function fetchPreview() {
        if (TagCreationVM.creatingNew && !TagCreationVM.savedNew) {
            TagCreationVM.beacon.name = TagCreationVM.beacon.name || '';
            var beacons = TagCreationVM.beacon.name.split(','),
                length = beacons.length - 1,
                tag = '',
                adServerTag = '';

            _.forEach(beacons, function(beacon, idx) {
                beacon = beacon.trim();
                if (beacon.length) {
                    tag += generateTag({
                        exportName: beacon,
                        uuid: PLACEHOLDER,
                    }, length === idx ? '' : ',\n');
                }
            });

            tag = generateTag({
                exportName: '',
                uuid: PLACEHOLDER,
            });
            adServerTag = generateAdServerTag({
                exportName: '',
                uuid: PLACEHOLDER,
            });

            TagCreationVM.tag = tag;
            TagCreationVM.adServerTag = adServerTag;
        }
        else {
            TagCreationVM.tag = generateTag({
                exportName: TagCreationVM.beacon.name,
                uuid: TagCreationVM.beacon.uuid,
            });

            TagCreationVM.adServerTag = generateAdServerTag({
                exportName: TagCreationVM.beacon.name,
                uuid: TagCreationVM.beacon.uuid,
            });
        }
    }

    /**
     * Accepts a tag type as a parameter and updates TagCreationVM.beacon.tagType when a new selection is made in the UI
     *
     * @param {object} type - Tag type (cookie, mobile, cookieAndMobile, ipaddress)
     */
    function selectType(type) {
        TagCreationVM.beacon.tagType = type.value;
        TagCreationVM.beacon.dmp = {};
        tagParts.opening = getTagOpeningForType(type.value);
        tagParts.params = '';
        tagParts.location = '';
        TagCreationVM.showOriginalOrdering = true;

        generateAdTagParams();
        fetchPreview();
    }

    /**
     * Accepts a tag type as a parameter and returns the tag's opening (html image opening)
     *
     * @param {string} type - Tag type (cookie, mobile, cookieAndMobile, ipaddress)
     * @returns {string} Tag opening based on type
     */
    function getTagOpeningForType(type) {
        const opening = type === 'private' ? 'pv' : 'ir';

        if (/ipaddress|private/.test(type)) {
            return (TagCreationVM.beacon.dmp.value || DEFAULT_OPENING).replace(/https:\/\/(TO_REPLACE|ir|ev|pv)\./, `https://${opening}.`);
        }

        return (TagCreationVM.beacon.dmp && TagCreationVM.beacon.dmp.value && tagParts.opening) || DEFAULT_OPENING;
    }

    /**
     * Accepts a adServer option as a parameter and updates TagCreationVM.adServerTag when a new selection is made in the UI
     * NOTE: ad server is not saved to the database
     *
     * @param {object} option - Ad server option
     */
    function selectAdServer(option) {
        TagCreationVM.adServerTag = '';
        TagCreationVM.beacon.changedOrder = false;
        TagCreationVM.beacon.adServer = option;
        TagCreationVM.showOriginalOrdering = true;
        TagCreationVM.beacon.beaconType = {};

        if (option.paramName === 'private') {
            TagCreationVM.beacon.dmp = {};
            tagParts.opening = '<img width="0" height="0" src="https://pv.surveywall-api.survata.com/s?eid=';
        }

        // When switching media partners, get the default order
        TagCreationVM.beacon.params = getDefaultParameters(TagCreationVM.beacon.adServer);

        generateAdTagParams();
        fetchPreview();
    }

    /**
     * Accepts a tag redirect as a parameter and updates the redirects of a tag when a new selection is made in the UI
     *
     * @param {object} redirects - Tag redirect
     */
    function selectRedirects(redirects) {
        tagParts.opening = redirects.value.replace('TO_REPLACE', TagCreationVM.beacon.beaconType.value === 'ipaddress' ? 'ir' : 'ev');

        fetchPreview();
    }

    /**
     * Disables the save button under specific conditions (all fields of the form filled out)
     * or if it has no impression macros then check if the beacon has a name and type
     *
     * @returns {boolean} Whether to disable save button
     */
    function disableSave() {
        TagCreationVM.beacon.beaconType = TagCreationVM.beacon.beaconType || {};

        if (!TagCreationVM.beacon.hasImpressionParameters) {
            return !TagCreationVM.beacon.name || !TagCreationVM.beacon.beaconType;
        }

        return !(TagCreationVM.beacon.name &&
            TagCreationVM.beacon.params.length &&
            (TagCreationVM.beacon.uuid || (!_.isEmpty(TagCreationVM.beacon.dmp) && TagCreationVM.beacon.adServer)));
    }

    /**
     * Accepts a DMP object as a parameter and returns the dmp name in the format that the DB expects
     * if it's Oracle/BluKai, we switch the name to oracle
     *
     * @param {object} dmpObj
     * @returns {string} DMP name
     */
    function getDmpName(dmpObj) {
        const dmp = dmpObj.name.toLowerCase();

        if (dmp === 'oracle/blukai') {
            return 'oracle';
        }

        return dmp;
    }

    /**
     * Returns the parameters needed to make a create or update beacon tag call to the API
     *
     * @returns {object} - Tag parameters object
     */
    function getTagParams() {
        const params = {
            beaconType: TagCreationVM.beacon.beaconType.value,
            exportName: TagCreationVM.beacon.name,
            userId: userId,
            dataPartner: getDmpName(TagCreationVM.beacon.dmp),
            mediaPartnerId: TagCreationVM.beacon.adServer.id,
        };

        if (TagCreationVM.ux.replaceEventId && TagCreationVM.userDefinedEventId) {
            params.eventId = TagCreationVM.userDefinedEventId;
        }

        return params;
    }

    /**
     * Determines whether to disable the save button.
     *
     * @returns {boolean} - Disable save button?
     */
    function disableSaveButton() {
        if (TagCreationVM.ux.editImpressionParameters) {
            return true;
        }
        return !tagReadyForSave();
    }

    /**
     * Checks if ready to submit form, gathers the parameters and shoots a create or update tag request to the API
     */
    function saveTag() {
        let beacons = TagCreationVM.beacon.name.split(',');
        TagCreationVM.ux.creationError = false;

        if (TagCreationVM.creatingNew && tagReadyForSave()) {
            let params = getTagParams();

            // Create a new beacon and then update the beacon to set the new uuid
            beaconService.createTag(params)
                .then(newTag => {
                    $scope.$evalAsync(() => {
                        TagCreationVM.beacon.uuid = newTag.uuid;
                        TagCreationVM.savedNew = true;
                    });

                    beaconService.updateTag(newTag.uuid, newTag).then(updatedBeacon => {
                        fetchPreview();
                        updateImpressionParameters(updatedBeacon);

                        $scope.$evalAsync(() => {
                            TagCreationVM.ux.created = true;
                        });
                    });
                }).catch(e => {
                    $notify.error(`There was an error when trying to create this tag: ${e.msg} -- Please make sure the tag has a unique name`, {
                        clear: true,
                        timeout: 5000,
                    });
                });
        }
        else {
            TagCreationVM.beacon.exportName = beacons[0];
            // The select type drop down component stores the types in an object, but when we update the tag with updateTag() the api expects beaconType to be a string.
            // here we check if beaconType has a property value, if it does, we set beaconType to be that string.
            if (tagReadyForSave()) {
                let params = getTagParams();

                beaconService.updateTag(TagCreationVM.beacon.uuid, params).then(updatedBeacon => {
                    TagCreationVM.saved = true;
                    TagCreationVM.beacon.uuid = updatedBeacon.uuid;

                    fetchPreview();
                    updateImpressionParameters(updatedBeacon);
                    copyToClipboard();

                    $scope.$evalAsync(() => {
                        TagCreationVM.ux.created = true;
                    });
                }).catch(e => {
                    $notify.error(`There was an error when trying to update this tag: ${e.msg}`, {
                        clear: true,
                        timeout: 5000,
                    });
                });
            }
        }
    }

    /**
     * Returns true or false based on whether or not all fields have been selected - if true, we can save a tag
     *
     * @returns {boolean} Is tag ready to save?
     */
    function tagReadyForSave() {
        return TagCreationVM.beacon.beaconType && TagCreationVM.beacon.beaconType.value && TagCreationVM.beacon.dmp && TagCreationVM.beacon.adServer;
    }

    /**
     * Accepts an array of beacons as a parameter and generates a tag for the csv export (download button)
     *
     * @param {Array} beaconArray - Array of beacons
     */
    function generateTagForCSV(beaconArray) {
        if (!TagCreationVM.beacon.paramString || TagCreationVM.saved) {
            TagCreationVM.beacon.paramString = '';
            TagCreationVM.beacon.paramString = TagCreationVM.beacon.params.map(param => param.value).join(',');
        }

        generateTagParams();
        macroCsvData = 'Upwave macro order for: ';
        adServerCsvData = 'Ad Server macro order for: ';

        // eslint-disable-next-line no-unused-vars
        _.forEach(beaconArray, function(beacon, idx) {
            let tagName = beacon.exportName || beacon.name;
            // eslint-disable-next-line no-useless-concat
            let tagString = tagName + ',' + tagParts.opening + tagParts.uuid + (TagCreationVM.beacon.hasImpressionParameters ? `&${TagCreationVM.beacon.paramString}` : '') + tagParts.location + '">' + ',\n';

            macroCsvData += tagString;
        });

        adServerCsvData += generateAdServerTag({
            exportName: TagCreationVM.beacon.name,
            uuid: TagCreationVM.beacon.uuid,
        });
    }

    /**
     * Accepts a beacon as a parameter and updates the tag's impression parameters and saves them to the DB
     *
     * @param {object} beacon - the beacon we want to create impression parameter rows for
     */
    function updateImpressionParameters(beacon) {
        const impressionParameters = TagCreationVM.beacon.params.map(param => param.value).join(',');

        if (impressionParameters !== TagCreationVM.beacon.paramString && TagCreationVM.beacon.hasImpressionParameters) {
            beaconService.setBeaconImpressionParameters(beacon.uuid, impressionParameters).then(function() {}, function(response) {
                fetchPreview();

                $notify.error(`There was an error when trying to update impression parameters: ${response.msg} -- Unable to save impression parameters`, {
                    clear: true,
                    timeout: 5000,
                });
            });
        }
    }

    /**
     * Updates the tag's location piece of the tag's resulting image tag
     */
    function setTagLocation() {
        tagParts.location = TagCreationVM.beacon.location ? '&l=' + encodeURIComponent(TagCreationVM.beacon.location) : '';

        fetchPreview();
    }

    /**
     * Accepts a beacon and a trailing comma as parameters and returns a generated tag (html image tag)
     *
     * @param {object} beacon - the beacon to generate a tag (HTML Image tag) for
     * @param {string} trailingComma - a string that denotes a trailing comma
     * @returns {string} The tag string
     */
    function generateTag(beacon, trailingComma) {
        tagParts.uuid = beacon.uuid;
        trailingComma = trailingComma || '';
        const beaconName = beacon.exportName || TagCreationVM.beacon.name;

        if (TagCreationVM.userDefinedEventId) {
            tagParts.uuid = TagCreationVM.userDefinedEventId;
        }

        // Handles generating the tag in it's final/saved form
        if (TagCreationVM.savedNew || TagCreationVM.saved) {
            tagParts.params = '';

            TagCreationVM.beacon.params.forEach((param, idx) => {
                tagParts.params += idx === TagCreationVM.beacon.params.length - 1 ? param.value : param.value + ',';
            });

            TagCreationVM.paramOrder = tagParts.params;

            return beaconName + ',' + tagParts.opening + TagCreationVM.beacon.uuid + (TagCreationVM.beacon.hasImpressionParameters ? `&${tagParts.params}` : '') + tagParts.location + tagParts.closing + trailingComma;
        }

        // Handles generating a preview of the tag when the macro order is changed
        if (TagCreationVM.beacon.newParamOrder) {
            return beaconName + ',' + tagParts.opening + (tagParts.uuid ? tagParts.uuid : 'PLACEHOLDER') + `&${TagCreationVM.beacon.newParamOrder}` + tagParts.location + tagParts.closing + trailingComma;
        }

        // Handles displaying a preview of a tag when a new ad server is selected
        return beaconName + ',' + tagParts.opening + tagParts.uuid + (TagCreationVM.beacon.hasImpressionParameters ? `&${tagParts.params}` : '') + tagParts.location + tagParts.closing + trailingComma;
    }

    /**
     * Accepts a beacon/tag as a parameter and returns a generated ad server tag (html image tag) that includes that ad servers macro mapping
     *
     * @param {object} beacon - The beacon to make an ad server tag for
     * @returns {string} The ad server tag string
     */
    function generateAdServerTag(beacon) {
        const beaconName = beacon.exportName || TagCreationVM.beacon.name;
        generateAdTagParams();

        if (TagCreationVM.beacon.adServerParams === 'undefined') {
            TagCreationVM.beacon.adServerParams = '';
        }
        tagParts.uuid = beacon.uuid;

        if (TagCreationVM.userDefinedEventId) {
            tagParts.uuid = TagCreationVM.userDefinedEventId;
        }

        return beaconName + ',' + tagParts.opening + tagParts.uuid + `${TagCreationVM.beacon.adServerParams}` + tagParts.location + tagParts.closing;
    }

    /**
     * Returns whether this is a valid macro param.
     *
     * @param {object} param - Object parameter
     * @param {string} param.type - The macro type (siteId, placementId, deviceId...etc)
     * @param {string} param.value - The macro value
     * @returns {boolean} - Is a valid param?
     */
    function isValidMacroParam({
        value, type,
    }) {
        // If this is a device id macro, it is only valid if the tag type is mobile or mobileAndCookie
        if (type === DEVICE_ID) {
            return TagCreationVM.beacon.tagType === MOBILE_TAG || TagCreationVM.beacon.tagType === COOKIE_AND_MOBILE_TAG;
        }
        // Value must be present to be valid
        return !!value;
    }

    /**
     * Get default impression params.
     *
     * @param {object} adServer
     * @returns {object[]} list of impression params
     */
    function getDefaultParameters(adServer) {
        if (Array.isArray(adServer.impressionParameters) && adServer.impressionParameters.length) {
            return adServer.impressionParameters.map(ip => tagCreationConstant.get('IMPRESSION_PARAMETERS')[ip]);
        }
        return tagCreationConstant.get(DEFAULT_IMPRESSION_PARAMETERS);
    }

    /**
     * Generates the parameters for the ad tag and orders them based on the user's chosen order via the drag and drop
     */
    function generateAdTagParams() {
        // We dont save ad server name to DB so on first load we cannot display the adserver macro order
        // this block handles generating params when the tag is saved or when the user switches the macro order
        if (TagCreationVM.beacon.adServer && (TagCreationVM.savedNew || TagCreationVM.saved || TagCreationVM.beacon.changedOrder)) {
            let macros = TagCreationVM.beacon.adServer.macros,
                numMacros = macros ? _.size(macros) : 0,
                adServerParams = [],
                macroOrderArr = TagCreationVM.beacon.changedOrder ? TagCreationVM.beacon.newParamOrder.split(',') : tagParts.params.split(',');

            if (numMacros) {
                for (let i = 0; i < macroOrderArr.length; i++) {
                    const param = macroOrderArr[i];
                    let mappedParam = macros[param];

                    if (!mappedParam) {
                        mappedParam = 'noValue';
                    }

                    adServerParams.push(mappedParam);
                }

                const dId = macros.deviceId;

                if (isValidMacroParam({
                    value: dId,
                    type: DEVICE_ID,
                })) {
                    adServerParams.push(dId);
                }
            }
            TagCreationVM.beacon.adServerParams = TagCreationVM.macrosUrlParam + adServerParams.join(',');
        }
        // Generate params when an ad server is selected
        else if (TagCreationVM.beacon.adServer) {
            let macros = TagCreationVM.beacon.adServer.macros || {},
                params = [];

            TagCreationVM.beacon.params.forEach(param => {
                params.push(macros[param.value] || 'noValue');
            });

            TagCreationVM.beacon.adServerParams = TagCreationVM.macrosUrlParam + params.join(',');
        }
        else {
            // Generate params on load
            TagCreationVM.beacon.adServerParams = '';
        }
    }

    /**
     * Generates tag params for the human readable tag we display in the UI while the user is creating/updating the tag
     */
    function generateTagParams() {
        tagParts.params = TagCreationVM.macrosUrlParam + TagCreationVM.beacon.paramString;
    }

    /**
     * Saves a csv formatted document to the User's local storage containing the Beacon's associated Image Tag
     */
    function exportAsCsv() {
        generateTagForCSV([TagCreationVM.beacon]);

        var blob = new Blob([macroCsvData, adServerCsvData], {
            type: 'data:text/csv;charset=utf-8',
        });

        // eslint-disable-next-line no-undef
        saveAs(blob, 'beacon_tag-' + TagCreationVM.beacon.name + '.csv');
    }

    /**
     * Copies the ad server tag (found in the html element 'copy-to-clipboard') to the user's clipboard
     */
    function copyToClipboard() {
        var inp = document.getElementById('copy-to-clipboard');
        TagCreationVM.ux.created = true;

        if (inp && inp.select) {
            inp.select();

            try {
                document.execCommand('copy');
                inp.blur();
            }
            catch (err) {
                TagCreationVM.copyError = 'Unable to copy text, please press Ctrl/Cmd + C to copy.';
            }
        }
    }

    /**
     * Accepts a string containing the updated impression param order that results from the user changing the param order in the drag and drop
     * and updates the tag's param order in the controller's scope.
     *
     * @param {string} updatedImpressionParamOrder - The new order to be changed to
     */
    function changedOrdering(updatedImpressionParamOrder) {
        TagCreationVM.beacon.newParamOrder = updatedImpressionParamOrder;
        TagCreationVM.beacon.changedOrder = true;

        TagCreationVM.tag = generateTag({
            exportName: TagCreationVM.beacon.name,
            uuid: TagCreationVM.beacon.uuid,
        });

        TagCreationVM.adServerTag = generateAdServerTag({
            exportName: TagCreationVM.beacon.name,
            uuid: TagCreationVM.beacon.uuid,
        });

        TagCreationVM.beacon.newParamOrder = null;
        TagCreationVM.beacon.changedOrder = false;
    }

    /**
     * Toggles the inclusion of viewabilityData (a 6th beacon impression macro) in the drag and drop for the current Tag
     */
    function toggleViewabilityData() {
        TagCreationVM.ux.hasViewabilityData = !TagCreationVM.ux.hasViewabilityData;
    }

    /**
     * Toggles the styling associated to the modal's reorder impression parameter mode
     */
    function toggleEditImpressionParameters() {
        if (readyToReorder()) {
            TagCreationVM.ux.editImpressionParameters = !TagCreationVM.ux.editImpressionParameters;
        }
    }

    /**
     * Returns true if user has filled out the modal form sufficiently to begin param reordering
     *
     * @returns {boolean} - Whether params can be reordered
     */
    function readyToReorder() {
        return TagCreationVM.beacon.beaconType && TagCreationVM.beacon.dmp && TagCreationVM.beacon.hasImpressionParameters;
    }

    /**
     * Returns true if the modal should show the viewabilityToggle button, otherwise false
     * NOTE: in the future, there's a good chance that we will want to toggle viewability for all tags.
     * Right now, viewability is automatically included as a parameter in mobile and cookieAndMobile tags
     * and this is an artifact of how the code was when I picked it up
     *
     * @returns {boolean} - Whether to show viewability toggle
     */
    function showViewabilityToggle() {
        if (TagCreationVM.beacon.params.length &&
            TagCreationVM.beacon.hasImpressionParameters &&
            TagCreationVM.creatingNew && TagCreationVM.beacon.beaconType && TagCreationVM.beacon.beaconType.value === 'cookie' ||
            TagCreationVM.beacon.beaconType && TagCreationVM.beacon.beaconType.value === 'ipaddress') {
            return true;
        }
        return false;
    }

    /**
     * Returns true if we should copy the new tag to the clipboard, otherwise false
     *
     * @returns {boolean} - Whether to show copy to clipboard
     */
    function showCopyToClipboard() {
        return (TagCreationVM.ux.created && !TagCreationVM.generatingCode) || (TagCreationVM.generatingCode && !TagCreationVM.ux.created);
    }
}
