import './click-map/editor-view';
import './click-map/preview-view';
import './grid/editor-view';
import './grid/preview-view';
import './intro-statement/editor-view';
import './intro-statement/preview-view';
import './long-free-response/editor-view';
import './long-free-response/preview-view';
import './numeric/editor-view';
import './numeric/preview-view';
import './ranking/editor-view';
import './ranking/preview-view';
import './rating/editor-view';
import './rating/preview-view';
import './rating/rating-ctrl';
import './select/editor-view';
import './select/preview-view';

angular.module('questionEditor.view', [
    'clickMap.editor',
    'clickMap.preview',
    'grid.editor',
    'grid.preview',
    'introStatement.editor',
    'introStatement.preview',
    'longFreeResponse.editor',
    'longFreeResponse.preview',
    'numeric.editor',
    'numeric.preview',
    'ranking.editor',
    'ranking.preview',
    'rating.preview',
    'rating.editor',
    'rating.ctrl',
    'select.editor',
    'select.preview',
])
    .directive('questionEditor', questionEditor)
    .controller('QuestionEditorCtrl', QuestionEditorCtrl);

function questionEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/question-editor.html',
        scope: {
            q: '=',
            checkQuestionErrors: '=',
            isAdmin: '=',
        },
        controller: 'QuestionEditorCtrl',
    };
}
QuestionEditorCtrl.$inject = ['$scope'];

function QuestionEditorCtrl($scope) {
    var first = true;
    $scope.autoSkip = {
        enabled: false,
        toSkip: null,
    };
    update();
    $scope.$watch('q', update);
    $scope.$watch('autoSkip', update, true);

    function update() {
        $scope.question = $scope.q || {};

        // On first time, set autoSkip scope variable (for the UI)
        if (first && $scope.question.details.skipLogic && $scope.question.details.skipLogic.true) {
            $scope.autoSkip = {
                enabled: true,
                toSkip: $scope.question.details.skipLogic.true,
            };
        }
        // On future times, update skipLogic param on question
        if (!first) {
            $scope.question.details.skipLogic = $scope.autoSkip.enabled && $scope.autoSkip.toSkip ? {
                true: $scope.autoSkip.toSkip,
            } : void 0;
        }
        first = false;
    }
}
