import {
    getDefaultDateRangePickerOptions,
} from '../common-components/datepicker-service';
import '../common-components/sv-filters';
import '../publisher/publisher-date-service';
import '../publisher/api-publisher-service';
import '../survey/api-survey-service';

angular.module('NetworkAdminModule', [
    'apiSurveyService',
    'apiPublisherService',
    'publisherDate.service',
    'svFilters',
])
    .controller('surveyScreenedCtrl', surveyScreenedCtrl)
    .controller('networkAdminCtrl', networkAdminCtrl);

surveyScreenedCtrl.$inject = [
    '$scope',
    '$rootScope',
    'publisherService',
    'publisherDateService',
];

/**
 * @param $scope
 * @param $rootScope
 * @param publisherService
 * @param publisherDateService
 */
function surveyScreenedCtrl($scope, $rootScope, publisherService, publisherDateService) {
    $scope.ux = {
        ready: false,
        summaries: null,
    };
    $scope.date = {
        start: publisherDateService.getStartDate(),
        end: publisherDateService.getEndDate(),
    };
    $scope.datePickerOptions = getDefaultDateRangePickerOptions($scope.date, refreshScreenedSummary);

    $scope.refreshScreenedSummary = refreshScreenedSummary;

    function refreshScreenedSummary() {
        $scope.ux.ready = false;
        $rootScope.startDate = $scope.date.start;
        $rootScope.endDate = $scope.date.end;
        publisherService.getScreenedSummary($scope.date.start, $scope.date.end).then(function(screenedSummaries) {
            $scope.ux.summaries = screenedSummaries;
            $scope.ux.ready = true;
        });
    }

    $scope.refreshScreenedSummary();
}
networkAdminCtrl.$inject = [
    '$scope',
    '$rootScope',
    'surveyService',
    '$timeout',
];

/**
 * @param $scope
 * @param $rootScope
 * @param surveyService
 * @param $timeout
 */
function networkAdminCtrl($scope, $rootScope, surveyService, $timeout) {
    $scope.sort = {
        criteria: null,
        reverse: true,
    };
    $rootScope.appData = {
        surveys: [],
        currentSurvey: undefined,
        surveyPriority: undefined,
    };
    $scope.ux = {
        ready: false,
        listRefreshing: false,
    };
    $scope.refreshSurveyList = refreshSurveyList;
    $scope.refreshSurveys = refreshSurveys;
    $scope.sortBy = sortBy;
    $scope.setEditing = setEditing;
    $scope.editNetworkSurvey = editNetworkSurvey;

    function refreshSurveyList() {
        if ($scope.ux.listRefreshing) {
            $timeout(function() {
                refreshSurveyList();
            }, 100);
        }
        else {
            refreshSurveys();
        }
    }

    function refreshSurveys() {
        var params = {
            showStarted: true,
            hideCompleted: true,
            hideCanceled: true,
            showNetwork: true,
        };
        $scope.ux.listRefreshing = true;

        surveyService.listAllSurveys(params).then(function(surveys) {
            $scope.appData.surveys = surveys;

            _.forEach(surveys, function(survey) {
                surveyService.getPeriodResultsSummary(survey.latestCollectionPeriod.uuid).then(function(summary) {
                    $scope.$evalAsync(() => {
                        survey._numPendingResponses = summary.pending;
                        survey._numCurrentResponses = summary.responses;
                        survey._responsesNeeded = summary.responsesNeeded;
                        survey._totalLength = summary.totalLength;
                        survey._finishDate = summary.finishDate;
                        survey._totalAsked = summary.totalAsked;
                        survey._screenerFailures = summary.screenerFailures;
                    });
                });
            });
            $scope.$evalAsync(() => {
                $scope.ux.ready = true;
                $scope.ux.listRefreshing = false;
            });
        }, function(_error) {
            $scope.ux.ready = true;
            $scope.ux.listRefreshing = false;
        });
    }

    /**
     * @param criteria
     */
    function sortBy(criteria) {
        if ($scope.sort.criteria === criteria) {
            $scope.sort.reverse = !$scope.sort.reverse;
        }
        else {
            $scope.sort.criteria = criteria;
        }
    }

    /**
     * @param survey
     */
    function setEditing(survey) {
        $scope.appData.currentSurvey = survey;
        $scope.appData.surveyPriority = survey.priority;
    }

    function editNetworkSurvey() {
        var params = {
            priority: $scope.appData.surveyPriority,
        };
        surveyService.updateSurvey($scope.appData.currentSurvey.uuid, params).then(function(_response) {
            $scope.appData.currentSurvey = undefined;
            $scope.appData.surveyPriority = undefined;
            refreshSurveyList();
        });
    }

    // Load the survey list
    refreshSurveyList();
}
