import targetingAttributeAge from './age';
import './gender';

angular.module('targetingAttributeGroup.demography', [
    'gender',
])
    .service('demography', demography);
demography.$inject = ['targetingAttributeGender'];

function demography(targetingAttributeGender) {
    return {
        id: 'demography',
        name: 'Demography',
        basic: true,
        extended: true,
        advanced: true,
        attributeList: [targetingAttributeAge, targetingAttributeGender],
    };
}
