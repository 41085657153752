const surveyConstants = {
    STATUSES: {
        DRAFT: 'Draft',
        QUOTE_REQUESTED: 'Quote Requested',
        QUOTE_IN_PROGRESS: 'Quote in progress',
        QUOTE_PROVIDED: 'Quote Provided',
        QUOTE_ACCEPTED: 'Quote Accepted',
        PROGRAMMING: 'Programming',
        PROGRAMMED: 'Programmed',
        ANALYST_REVIEW: 'Under analyst review',
        ANALYST_EDITING: 'Analyst editing',
        AWAITING_APPROVAL: 'Awaiting client approval',
        APPROVED: 'Approved',
        RUNNING: 'Running',
        PAUSED: 'Paused',
        COMPLETE: 'Complete',
        INACTIVE: 'Inactive',
        CANCELED: 'Canceled',
    },
    SURVEY_ACTIONS: {
        START: 'start',
        PAUSE: 'pause',
        PANEL: 'panel',
        UNPANEL: 'unpanel',
        NETWORK: 'network',
        UNNETWORK: 'unnetwork',
        ADVANCED: 'advanced',
        BASIC: 'basic',
        EXTENDED: 'extended',
        ABANDONED: 'abandoned',
        CANCEL: 'cancel',
        DRAFT_COMPLETE: 'completeDraft',
        START_REVIEW: 'startedReview',
        COMPLETE_REVIEW: 'reviewed',
        APPROVE: 'approve',
        UNAPPROVE: 'unapprove',
        UNREVIEW: 'unreview',
        START_QUOTE_PROCESS: 'startQuoteProcess',
        ACCEPT_QUOTE: 'acceptQuote',
        REQUEST_QUOTE: 'requestQuote',
        REQUEST_PROGRAMMING: 'requestProgramming',
        COMPLETE_PROGRAMMING: 'programmingComplete',
        RESTART: 'restart',
    },
    PACKAGES: {
        BASIC: 'basic',
        EXTENDED: 'extended',
        ADVANCED: 'advanced',
    },
};

export default surveyConstants;
