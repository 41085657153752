import trackingMethodOptions from './options';

angular.module('trackingMethod.summaryView', [])
    .directive('trackingMethodSummary', trackingMethodSummary)
    .controller('TrackingMethodSummaryCtrl', TrackingMethodSummaryCtrl);

function trackingMethodSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/tracking-method-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'TrackingMethodSummaryCtrl',
        controllerAs: 'TrackingMethodSummaryVM',
    };
}
TrackingMethodSummaryCtrl.$inject = ['$scope'];

function TrackingMethodSummaryCtrl($scope) {
    var TrackingMethodSummaryVM = this;
    TrackingMethodSummaryVM.options = trackingMethodOptions.list;
    TrackingMethodSummaryVM.selected = selected;

    function selected(option) {
        return $scope.requirement && $scope.requirement.details && ($scope.requirement.details.indexOf(option.id) !== -1);
    }
}
