import '../../../../common/js/angular-util';
import U from '../../../../common/js/util';

export default angular.module('apiPublisherService', ['angularUtil'])
    .service('publisherService', [
        '$q',
        '$filter',
        'httpDeferred',
        function($q, $filter, httpDeferred) {
            var listPublisherSummaries = function listPublisherSummaries(startDate, endDate, userId) {
                var deferred = $q.defer();

                var params = {
                    startDate: $filter('date')(startDate, 'MM/dd/yyyy'),
                    endDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                };
                if (userId) {
                    params.userId = userId;
                }
                httpDeferred.get(httpDeferred.dashboardHost + '/rest/publisher-day/user-summary' + U.buildParamString(params)).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to retrieve publishers');
                });
                return deferred.promise;
            };

            var listAllPublisherSummaries = function listAllPublisherSummaries(startDate, endDate) {
                var deferred = $q.defer();

                var params = {
                    startDate: $filter('date')(startDate, 'MM/dd/yyyy'),
                    endDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                };
                httpDeferred.get(httpDeferred.dashboardHost + '/rest/publisher-day/all-summary' + U.buildParamString(params)).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to retrieve ALL publishers');
                });
                return deferred.promise;
            };

            var getDayStats = function getDayStats(publisherId, startDate, endDate) {
                var deferred = $q.defer();

                var params = {
                    startDate: $filter('date')(startDate, 'MM/dd/yyyy'),
                    endDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                    uuid: publisherId,
                };
                httpDeferred.get(httpDeferred.dashboardHost + '/rest/publisher-day/list' + U.buildParamString(params)).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to retrieve stats');
                });
                return deferred.promise;
            };

            var listAllPublisherDemographicSummaries = function listAllPublisherDemographicSummaries(startDate, endDate) {
                var deferred = $q.defer();

                var params = {
                    startDate: $filter('date')(startDate, 'MM/dd/yyyy'),
                    endDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                };
                httpDeferred.get(httpDeferred.dashboardHost + '/rest/publisher-demographic-day/all-summary' + U.buildParamString(params)).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to retrieve ALL publishers');
                });
                return deferred.promise;
            };

            var getScreenedSummary = function getScreenedSummary(startDate, endDate) {
                var deferred = $q.defer();

                var params = {
                    startDate: $filter('date')(startDate, 'MM/dd/yyyy'),
                    endDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                };
                httpDeferred.get(httpDeferred.dashboardHost + '/rest/screened-summary/all-summary' + U.buildParamString(params)).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to retrieve screened summaries');
                });
                return deferred.promise;
            };

            var getDayDemographicStats = function getDayDemographicStats(publisherId, startDate, endDate) {
                var deferred = $q.defer();

                var params = {
                    startDate: $filter('date')(startDate, 'MM/dd/yyyy'),
                    endDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                    uuid: publisherId,
                };
                httpDeferred.get(httpDeferred.dashboardHost + '/rest/publisher-demographic-day/list' + U.buildParamString(params)).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to retrieve stats');
                });
                return deferred.promise;
            };

            var updatePublisher = function updatePublisher(uuid, updates) {
                var deferred = $q.defer();

                updates.uuid = uuid;
                httpDeferred.put(httpDeferred.dashboardHost + '/rest/publisher/update', updates).then(function(response) {
                    deferred.resolve(response);
                }, function() {
                    deferred.reject('Error trying to update publisher');
                });
                return deferred.promise;
            };

            return {
                listPublisherSummaries: listPublisherSummaries,
                listAllPublisherSummaries: listAllPublisherSummaries,
                getDayStats: getDayStats,
                getDayDemographicStats: getDayDemographicStats,
                listAllPublisherDemographicSummaries: listAllPublisherDemographicSummaries,
                updatePublisher: updatePublisher,
                getScreenedSummary: getScreenedSummary,
            };
        }]);
