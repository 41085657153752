import adMeasurementConstants from './ad-measurement.constants';
import '../common-components/nav-tabs';

angular
    .module('adMeasurement.view', [
        'ui.router', 'navTabs',
    ])
    .directive('adMeasurement', adMeasurement)
    .controller('AdMeasurementCtrl', AdMeasurementCtrl);

/**
 *
 */
function adMeasurement() {
    return {
        restrict: 'AE',
        template: '<div nav-tabs data-tabs="AdMeasurementVM.pages" class="ad-measurement-tab"></div>',
        controller: 'AdMeasurementCtrl',
        controllerAs: 'AdMeasurementVM',
    };
}

AdMeasurementCtrl.$inject = [
    '$state', '$urlService',
];
/**
 * @param $state
 * @param $urlService
 */
function AdMeasurementCtrl($state, $urlService) {
    const AdMeasurementVM = this;
    init();

    function init() {
        AdMeasurementVM.pages = angular.copy(adMeasurementConstants.pages);
        if ($state.current.name === 'adMeasurement') {
            const nextStateName = _.camelCase($urlService.parts().hash);
            if (nextStateName.length && $state.get(nextStateName)) {
                $state.go(nextStateName);
            }
            else {
                $state.go('audienceConfigurationList');
            }
        }
    }
}
