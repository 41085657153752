import countryOptions from './options';

angular.module('country.summaryView', [])
    .directive('countrySummary', countrySummary)
    .controller('CountrySummaryCtrl', CountrySummaryCtrl);

function countrySummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/country-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'CountrySummaryCtrl',
        controllerAs: 'CountrySummaryVM',
    };
}
CountrySummaryCtrl.$inject = ['$scope'];

function CountrySummaryCtrl($scope) {
    var CountrySummaryVM = this;

    CountrySummaryVM.summary = summary;

    function summary() {
        var x = [];

        _.forEach($scope.requirement.details || [], function(e) {
            x.push(countryOptions.map[e].name);
        });

        return x.join(', ');
    }
}
