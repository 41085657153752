import '../../common-components/pub-sub';
import '../ad-measurement.api-service';
import adMeasurementService from '../ad-measurement.service';

angular.module('audienceConfiguration.review', [
    'pubSub', 'adMeasurement.apiService',
])
    .controller('ReviewAudienceConfigurationCtrl', ReviewAudienceConfigurationCtrl)
    .directive('reviewAudienceConfiguration', reviewAudienceConfiguration);

function reviewAudienceConfiguration() {
    return {
        restrict: 'E',
        templateUrl: '/dashboard-templates/ad-measurement/audience-configuration/review.html',
        scope: {
            audienceConfiguration: '=',
            audienceConfigurationNames: '=',
        },
        controller: 'ReviewAudienceConfigurationCtrl',
        controllerAs: 'ReviewConfigurationVM',
    };
}
ReviewAudienceConfigurationCtrl.$inject = [
    '$rootScope',
    '$scope',
    'pubSubService',
    'adMeasurementApiService',
];

function ReviewAudienceConfigurationCtrl($rootScope, $scope, pubSubService, adMeasurementApiService) {
    const CURRENT_STEP_IDX = 2;
    var ReviewConfigurationVM = this,
        stepManager = adMeasurementService.getStepManager();
    ReviewConfigurationVM.ux = {};
    ReviewConfigurationVM.audienceConfiguration = $scope.audienceConfiguration;
    ReviewConfigurationVM.onAudienceConfigurationNameChanged = onAudienceConfigurationNameChanged;
    ReviewConfigurationVM.save = save;
    ReviewConfigurationVM.back = back;

    init();

    function init() {
        ReviewConfigurationVM.audienceConfiguration.name = ReviewConfigurationVM.audienceConfiguration.name ||
            adMeasurementService.getAudienceConfigurationName(ReviewConfigurationVM.audienceConfiguration, $rootScope.survey, ReviewConfigurationVM.audienceConfiguration.beaconControlConfigurationName);
    }

    function save() {
        adMeasurementApiService.updateAudienceConfiguration(ReviewConfigurationVM.audienceConfiguration, $rootScope.survey._audienceId).then(function(response) {
            pubSubService.notify('close-audience-configuration-modal', [response]);
        }, function(error) {
            console.log('error is: ' + JSON.stringify(error));
        });
    }

    function back(e) {
        stepManager.setCurrentStep(e, CURRENT_STEP_IDX - 1);
    }

    function onAudienceConfigurationNameChanged() {
        const uuid = $scope.audienceConfigurationNames[ReviewConfigurationVM.audienceConfiguration.name];
        if (!ReviewConfigurationVM.audienceConfiguration.name) {
            ReviewConfigurationVM.ux.errorMsg = 'Please give your configuration a name';
        }
        else if (uuid && uuid !== ReviewConfigurationVM.audienceConfiguration.uuid) {
            ReviewConfigurationVM.ux.errorMsg = 'A configuration with this name already exists.';
        }
    }
}
