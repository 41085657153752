angular.module('questionTypes.introStatement', [])
    .service('questionTypes.introStatement.service', introStatementService);

function introStatementService() {
    function toString(q) {
        return (q.statement.text || '') + '\n' +
            (q.statement.image ? '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n' : '') +
            '[free response]\n' +
            (q.details && q.details.text ? 'placeholder: ' + q.details.text : '') + '\n';
    }

    function errors(_q) {
        return [];
    }

    return {
        basic: true,
        extended: true,
        advanced: true,
        screener: {
            adminOnly: true,
        },
        id: 'introStatement',
        name: 'Introductory Statement',
        description: 'Read instructions before taking survey',
        icon: 'fas fa-microphone',
        toString: toString,
        errors: errors,
    };
}
