angular.module('longFreeResponse.preview', [])
    .directive('longFreeResponsePreview', longFreeResponsePreview);

function longFreeResponsePreview() {
    return {
        restrict: 'E',
        template: '<div class="faux-textarea placeholder">{{question.details.placeholder}}</div>',
        scope: {
            question: '=',
        },
    };
}
