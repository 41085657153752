import './dashboard-results-grid-chart';
import './dashboard-results-chart';
import './dashboard-results-ctrl';
import './respondent-data-view';
import './survey-results-view';
import './survey-results-service';
import './respondent-data-service';
import './results-overview-view';
import './download-survey-results';

angular.module('SurveyResultsModule', [
    'dashboardResults.gridChart',
    'dashboardResults.chart',
    'dashboardResults.ctrl',
    'respondentData.service',
    'respondentData.view',
    'surveyResults.view',
    'surveyResults.service',
    'surveyResults.overview',
    'downloadSurveyResults',
])
    .config([
        '$stateProvider', function($stateProvider) {
            const surveyResultsStates = {
                charts: {
                    url: '#charts',
                    templateUrl: 'dashboard-templates/results/charts.html',
                    parent: 'results',
                    data: {
                        default: 'surveyResultsCharts',
                    },
                },
                surveyResultsCharts: {
                    template: '<survey-results></survey-results>',
                    parent: 'charts',
                },
                respondentCharts: {
                    template: '<respondent-data></respondent-data>',
                    parent: 'charts',
                },
                variableCharts: {
                    template: '<custom-variables-charts></custom-variables-charts>',
                    parent: 'charts',
                },
                download: {
                    url: '#download',
                    templateUrl: 'dashboard-templates/results/download.html',
                    parent: 'results',
                    controller: 'DownloadSurveyResultsCtrl',
                    controllerAs: 'DownloadSurveyResultsVM',
                },
            };

            _.forEach(surveyResultsStates, (options, name) => {
                options.data = options.data || {};
                options.data.dashboardParent = 'results';
                $stateProvider.state(name, options);
            });
        }]);
