import './sv-dialog';

angular.module('modalService', ['common.dialog'])
    .service('modalService', modalService);
modalService.$inject = ['dialog'];

function modalService(dialog) {
    function optionCustomVariableEditor(cv, $event) {
        return dialog.popup({
            templateUrl: '/dashboard-templates/custom-variables/custom-variables-editor.html',
            controller: 'VariableEditorCtrl',
            controllerAs: 'VariableEditorVM',
            size: 'lg',
            windowClass: 'modal-sv modal-sv--full-height modal-sv--md-width modal-sv--gray-content-bg custom-variables__modal',
            resolve: {
                activeCV: function() {
                    return cv;
                },
                event: function() {
                    return $event;
                },
            },
        });
    }

    function openConfirmDeleteModal(resolve) {
        return dialog.popup({
            templateUrl: '/modal-templates/confirm-delete-modal.html',
            controller: 'ConfirmDeleteCtrl',
            controllerAs: 'ConfirmDeleteVM',
            resolve: resolve,
        });
    }

    return {
        optionCustomVariableEditor,
        openConfirmDeleteModal,
    };
}
