import U from '../../../../../../common/js/util';
import '../../../common-components/loading-button';
import analysisConfigDataService from '../analysis-config-data-service';
import './factor-form';

export default angular
    .module('topTwoBoxEditor', [
        'loadingButton', 'factorForm',
    ])
    .controller('TopTwoBoxEditorCtrl', TopTwoBoxEditorCtrl);

TopTwoBoxEditorCtrl.$inject = [
    '$uibModalInstance',
    'factor',
    'combined',
];

/**
 * Controller for top two box editor modal
 *
 * @param {object} $uibModalInstance - Handles modal actions
 * @param {object} factor - Parent factor
 * @param {object} combined - New combined factor level
 */
function TopTwoBoxEditorCtrl($uibModalInstance, factor, combined) {
    const TopTwoBoxEditorVM = this,
        MAX_LEVEL_NAME_LENGTH = 80;
    TopTwoBoxEditorVM.sharedUx = {};
    TopTwoBoxEditorVM.combined = {
        ...combined,
    };
    TopTwoBoxEditorVM.factor = factor;
    TopTwoBoxEditorVM.close = $uibModalInstance.close;
    TopTwoBoxEditorVM.save = save;
    TopTwoBoxEditorVM.disableButtons = disableButtons;
    TopTwoBoxEditorVM.deleteFactorlevel = deleteFactorlevel;

    /**
     * Disable the buttons if not fully filled out
     *
     * @returns boolean - Whether buttons should be disabled
     */
    function disableButtons() {
        return !TopTwoBoxEditorVM.combined.name || !TopTwoBoxEditorVM.combined.parsedCriteria[0].criteria.length;
    }

    /**
     * Saves the factor level with the data from the top-two-box editor
     */
    function save() {
        TopTwoBoxEditorVM.sharedUx.loading = true;
        TopTwoBoxEditorVM.combined.factorType = TopTwoBoxEditorVM.factor.factorType;

        analysisConfigDataService.updateFactorLevel(TopTwoBoxEditorVM.factor.uuid, TopTwoBoxEditorVM.combined).then(updated => {
            TopTwoBoxEditorVM.combined.uuid = updated.uuid;
            TopTwoBoxEditorVM.combined._displayNameFull = updated.name;
            // For display name, remove question index prefix, then limit the string length
            TopTwoBoxEditorVM.combined._displayName = U.limitStrLength(updated.name.replace(/^\d+\.(\d+\.)? /, ''), MAX_LEVEL_NAME_LENGTH);
            TopTwoBoxEditorVM.close(TopTwoBoxEditorVM.combined);
        });
    }

    function deleteFactorlevel() {
        analysisConfigDataService.deleteFactorLevel(TopTwoBoxEditorVM.combined.uuid).then(() => {
            TopTwoBoxEditorVM.combined.deleted = true;
            TopTwoBoxEditorVM.close(TopTwoBoxEditorVM.combined);
        });
    }
}
