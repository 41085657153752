angular.module('loadingButton', [])
    .directive('loadingButton', loadingButton)
    .controller('LoadingButtonCtrl', LoadingButtonCtrl);

function loadingButton() {
    return {
        restrict: 'AE',
        replace: true,
        templateUrl: '/shared-templates/loading-button.html',
        scope: {
            ux: '=',
            text: '@',
            onClick: '<',
            disabled: '<?',
            buttonClass: '@?',
        },
        controller: 'LoadingButtonCtrl',
        controllerAs: 'LoadingButtonVM',
    };
}
LoadingButtonCtrl.$inject = ['$scope'];

function LoadingButtonCtrl($scope) {
    var LoadingButtonVM = this;
    LoadingButtonVM.onClick = $scope.onClick;
    LoadingButtonVM.disabled = $scope.disabled || angular.noop;
    LoadingButtonVM.ux = $scope.ux;
    LoadingButtonVM.buttonClass = $scope.buttonClass || 'btn-sv--default';
}
