import '../common-components/pub-sub';
import '../results-components/pane-state-manager';
import './filter-and-compare-model-manager';
import './filter-and-compare-service';

angular.module('filterAndCompare.view', [
    'pubSub',
    'PaneStateManager',
    'filterAndCompare.modelManager',
    'filterAndCompare.service',
])
    .directive('filterAndComparePanel', filterAndComparePanel)
    .controller('filterAndCompareCtrl', filterAndCompareCtrl);

/**
 * Main panel function
 *
 * @returns {object}
 */
function filterAndComparePanel() {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/filter-and-compare.html',
        controller: 'filterAndCompareCtrl',
        controllerAs: 'FilterCompareVM',
    };
}
filterAndCompareCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    'pubSubService',
    'paneStateManager',
    'filterAndCompareModelManager',
];

/**
 * Main controller function
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $timeout
 * @param {object} pubSubService
 * @param {object} paneStateManager
 * @param {object} filterAndCompareModelManager
 */
function filterAndCompareCtrl($rootScope, $scope, $timeout, pubSubService, paneStateManager, filterAndCompareModelManager) {
    var FilterCompareVM = this,
        collectionPeriods = $rootScope.survey.collectionPeriods;
    FilterCompareVM.ux = {};
    FilterCompareVM.params = {};
    FilterCompareVM.hasCollectionPeriodFilter = collectionPeriods.length > 1;
    FilterCompareVM.survey = $rootScope.survey;
    FilterCompareVM.applyFilterCompareSettings = applyFilterCompareSettings;
    FilterCompareVM.togglePanes = togglePanes;

    init();

    function init() {
        pubSubService.subscribe('toggle-datasource', $scope.$id, refreshState);
        pubSubService.subscribe('update-language', $scope.$id, setLanguageForRespondents);
        pubSubService.subscribe('get-tally-complete', $scope.$id, onGetTallyComplete);
        pubSubService.flushTopic('update-language');
        refreshState();

        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'toggle-datasource',
                'update-language',
                'get-tally-complete',
            ], $scope.$id);
        });
    }

    function onGetTallyComplete() {
        FilterCompareVM.ux.filterRunning = false;
    }

    function refreshState() {
        FilterCompareVM.params.filterModel = filterAndCompareModelManager.filterModel();
        FilterCompareVM.params.comparisonData = filterAndCompareModelManager.comparisonData();
        FilterCompareVM.params.collectionPeriods = filterAndCompareModelManager.collectionPeriodModel();
        FilterCompareVM.params.parsedCriteria = filterAndCompareModelManager.parsedCriteria();
        FilterCompareVM.ux.allowCompareResults = allowCompareResults(FilterCompareVM.params.collectionPeriods);
        // Nav panes list all potential options for now, and if that pane doesn't exist for a certain tab then the variable is just benignly hanging around
        FilterCompareVM.ux.optionsNavPane = {
            collapsed: paneStateManager.initializeCollapsedState(),
            // Settings is unused for now; intended to indicate settings with an icon
            hasSettings: paneStateManager.initializeSettings(),
        };
        pubSubService.notify('refresh-filter-and-compare-model', [FilterCompareVM.params]);
    }

    /**
     * Allow comparison
     *
     * @returns {boolean}
     */
    function allowCompareResults() {
        let periods = FilterCompareVM.params.collectionPeriods || {
            start: -1,
        };
        return collectionPeriods.length === 1 || periods.start === periods.end || periods.consolidate;
    }

    /**
     * Apply settings
     *
     * @name applyFilterCompareSettings
     * @param {object} newParams - new filter/compare to be applioed
     * @param {string} type - periods | comparison | filter
     *
     * @description
     * Initializes filter/compare model with new filter/compare info. Resets comparison if a non-consolidated period filter is applied.
     */
    function applyFilterCompareSettings(newParams, type) {
        switch (type) {
            case 'periods':
                FilterCompareVM.params.collectionPeriods.start = newParams.start;
                FilterCompareVM.params.collectionPeriods.end = newParams.end;
                FilterCompareVM.params.collectionPeriods.consolidate = newParams.consolidate;
                filterAndCompareModelManager.collectionPeriodModel(FilterCompareVM.params.collectionPeriods);
                FilterCompareVM.ux.allowCompareResults = allowCompareResults(FilterCompareVM.params.collectionPeriods);
                if (!FilterCompareVM.ux.allowCompareResults) {
                    FilterCompareVM.params.comparisonData = filterAndCompareModelManager.comparisonData(null);
                }
                break;
            case 'comparisons':
                if (_.isEmpty(newParams.comparisonData)) {
                    newParams.comparisonData = null;
                }
                FilterCompareVM.params.comparisonData = filterAndCompareModelManager.comparisonData(newParams.comparisonData);
                FilterCompareVM.ux.optionsNavPane.collapsed.compare = true;
                break;
            case 'filter':
            default:
                FilterCompareVM.params.filterModel = filterAndCompareModelManager.filterModel(newParams.filterModel);
                FilterCompareVM.params.parsedCriteria = filterAndCompareModelManager.parsedCriteria(newParams.parsedCriteria);
                FilterCompareVM.ux.allowCompareResults = allowCompareResults(FilterCompareVM.params.collectionPeriods);
                FilterCompareVM.ux.optionsNavPane.collapsed.filter = true;
                break;
        }
        // Fetch new data with the current filters
        getTally();
    }

    function getTally() {
        pubSubService.notify('get-tally-started');
        FilterCompareVM.ux.filterRunning = true;
        pubSubService.notify('update-chart-data', [FilterCompareVM.params]);
    }

    /**
     * Toggle panes
     *
     * @param {object} type
     */
    function togglePanes(type) {
        FilterCompareVM.ux.optionsNavPane = paneStateManager.togglePanes(type, FilterCompareVM.ux.optionsNavPane);
        $timeout(function() {
            $scope.$broadcast('rzSliderForceRender');
            $scope.$broadcast('reCalcViewDimensions');
        }, 250);
    }

    /**
     * Set language
     *
     * @param {object} newSummary
     */
    function setLanguageForRespondents(newSummary) {
        if (!newSummary) {
            return;
        }
        let currentCount = newSummary.numRespondentsInVisiblePeriods,
            numRespondentsUnfiltered = newSummary.numRespondentsUnfiltered;
        if (currentCount === numRespondentsUnfiltered) {
            FilterCompareVM.ux.languageForRespondents = 'All ' + numRespondentsUnfiltered;
        }
        else {
            FilterCompareVM.ux.languageForRespondents = currentCount + '/' + numRespondentsUnfiltered + ' (' + Math.round((currentCount / numRespondentsUnfiltered) * 100) + '%)';
        }
        FilterCompareVM.ux.numPeriods = newSummary.numPeriods;
        FilterCompareVM.ux.numVisiblePeriods = newSummary.numVisiblePeriods;
        if (newSummary.numPeriods && newSummary.numPeriods !== newSummary.numVisiblePeriods) {
            FilterCompareVM.ux.languageForPeriods = newSummary.numVisiblePeriods + '/' + newSummary.numPeriods;
        }
        else {
            FilterCompareVM.ux.languageForPeriods = void 0;
        }
    }
}
