function DownloadSurveyResultsCtrl($rootScope) {
    const DownloadSurveyResultsVM = this;
    DownloadSurveyResultsVM.ux = {};
    DownloadSurveyResultsVM.survey = $rootScope.survey;

    init();

    function init() {
        if ($rootScope.survey.started) {
            var summary = $rootScope.survey.latestCollectionPeriod._summary;
            DownloadSurveyResultsVM.survey = $rootScope.survey;
            DownloadSurveyResultsVM.ux.showEstimatedCompleteDate = summary.finishDate && (summary.responses / summary.responsesNeeded) > 0.10;
            DownloadSurveyResultsVM.surveyResult = $rootScope.survey.surveyResult ? $rootScope.survey : $rootScope.survey.latestCollectionPeriod;
            DownloadSurveyResultsVM.surveyResultItem = DownloadSurveyResultsVM.surveyResult.surveyResult;
            DownloadSurveyResultsVM.ux.surveyCompleted = DownloadSurveyResultsVM.surveyResultItem && DownloadSurveyResultsVM.surveyResult.completed;
            DownloadSurveyResultsVM.ux.collectingResults = !DownloadSurveyResultsVM.ux.surveyCompleted && !$rootScope.survey.latestCollectionPeriod.surveyResult;
            DownloadSurveyResultsVM.surveyQuestions = $rootScope.survey._statsConfig.questions;
        }
    }
}

angular.module('downloadSurveyResults', [])
    .controller('DownloadSurveyResultsCtrl', DownloadSurveyResultsCtrl);
