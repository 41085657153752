import '../analysis-plan.service';

angular
    .module('analysisCutCategory', [
        'pubSub', 'analysisPlan.service',
    ])
    .controller('AnalysisCutCategoryCtrl', AnalysisCutCategoryCtrl);

AnalysisCutCategoryCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'pubSubService',
    'analysisPlanService',
    'category',
    'categoryMap',
];

/**
 * Controller for analysis cut category modal.
 *
 * @param {object} $scope - The scope for this controller
 * @param {object} $uibModalInstance - Instance of parent modal
 * @param {object} pubSubService - Pub/sub service
 * @param {object} analysisPlanService - Analysis plan service
 * @param {object} category - The category we're currently editing
 * @param {object} categoryMap - Map of cut categories, keyed by category name
 */
function AnalysisCutCategoryCtrl($scope, $uibModalInstance, pubSubService, analysisPlanService, category, categoryMap) {
    var AnalysisCutCategoryVM = this;
    const MAX_CATEGORY_NAME_LENGTH = 255;

    // VM exposed variables
    AnalysisCutCategoryVM.category = angular.copy(category);
    AnalysisCutCategoryVM.ux = {
        warningMsg: 'Clicking the save button will overwrite the category name.  Any changes to the name will not update the existing cut category, but result in a new cut category.',
        showCutNameCharsRemaining: false,
        errorMsg: '',
    };

    // VM Exposed functions
    AnalysisCutCategoryVM.save = save;
    AnalysisCutCategoryVM.enableSave = enableSave;
    AnalysisCutCategoryVM.close = close;
    AnalysisCutCategoryVM.onCategoryNameChanged = onCategoryNameChanged;

    /**
     * Called when category name is changed.
     */
    function onCategoryNameChanged() {
        if (categoryNameAlreadyExists(AnalysisCutCategoryVM.category.name)) {
            AnalysisCutCategoryVM.ux.errorMsg = 'Category name already exists';
        }
        else {
            AnalysisCutCategoryVM.ux.errorMsg = '';
            AnalysisCutCategoryVM.ux.categoryNameCharsRemaining = MAX_CATEGORY_NAME_LENGTH - AnalysisCutCategoryVM.category.name.length;
            AnalysisCutCategoryVM.ux.showCategoryNameCharsRemaining = AnalysisCutCategoryVM.ux.categoryNameCharsRemaining < 10;
        }
    }

    /**
     * Determine whether category name exists?
     *
     * @param {string} name - Category name to check
     * @returns {boolean} Does category name already exist?
     */
    function categoryNameAlreadyExists(name) {
        return !!categoryMap[name];
    }

    /**
     * Return whether to enable the save button.
     *
     * @returns {boolean} Whether to enable save button
     */
    function enableSave() {
        return !AnalysisCutCategoryVM.ux.errorMsg;
    }

    /**
     * Save the cut category, then close the modal.
     */
    function save() {
        analysisPlanService.updateCategory(AnalysisCutCategoryVM.category).then(response => {
            AnalysisCutCategoryVM.category = response;
            close(AnalysisCutCategoryVM.category);
        }, () => {
            AnalysisCutCategoryVM.ux.errorMsg = 'There was an error updating the cut category.';
        });
    }

    /**
     * Close the modal.
     *
     * @param {object} [updatedCut] - The updated cut
     */
    function close(updatedCut) {
        $uibModalInstance.close(updatedCut);
    }
}
