angular.module('svAutosizeTextarea', []).directive('svAutosizeTextarea', function() {
    return {
        restrict: 'CA',
        link: function(scope, elem, attrs) {
            var element = $(elem);
            if (typeof attrs.animated === 'undefined') {
                element.autosize();
            }
            else {
                element.autosize({
                    append: '\n',
                });
                element.addClass('form-control');
                element.css('-webkit-transition', 'height 0.2s');
                element.css('-moz-transition', 'height 0.2s');
                element.css('transition', 'height 0.2s');
            }
            element.triggerHandler('autosize.resizeIncludeStyle');

            if (attrs.ngModel) {
                scope.$watch(attrs.ngModel, function() {
                    setTimeout(function() {
                        element.triggerHandler('autosize.resizeIncludeStyle');
                    }, 1);
                });
            }
        },
    };
});
