/** Only used in subdomains/surveys **/
angular.module('svClipboard', [])
    .directive('svClipboard', function() {
        return {
            restrict: 'CAE',
            link: function(scope, element, attrs) {
                var hiddenContent = $('<span class="display-inline-block" id="copyContent"></span>'),
                    previousCaretPosition = -1,
                    elementSelected = false;

                $('body').append(hiddenContent);
                hiddenContent.css({
                    position: 'absolute',
                    left: '-9999em',
                });

                // Check if this directive is being moused over
                element.on('mouseenter', function() {
                    elementSelected = true;
                }).on('mouseleave', function() {
                    elementSelected = false;
                });

                // If user clicks control key, prime the text to be copied
                $(document).keydown(function(e) {
                    var val;

                    if (!(elementSelected && (e.ctrlKey || e.metaKey))) {
                        return;
                    }
                    if (!window.getSelection().toString()) {
                        if (typeof attrs.formatted !== 'undefined') {
                            val = typeof attrs.svClipboardValue === 'undefined' ? element.html() : attrs.svClipboardValue || '';
                            hiddenContent.html(val);
                        }
                        else {
                            val = typeof attrs.svClipboardValue === 'undefined' ? element.text() : attrs.svClipboardValue || '';
                            hiddenContent.text(val);
                        }
                        if (window.getSelection().rangeCount > 0) {
                            previousCaretPosition = document.activeElement.selectionStart;
                        }
                        var range = document.createRange();
                        range.selectNodeContents(hiddenContent[0]);

                        if (window.getSelection().rangeCount > 0) {
                            window.getSelection().removeAllRanges();
                        }
                        window.getSelection().addRange(range);
                    }
                });
                // Unprime the hidden content element
                $(document).keyup(function(e) {
                    if (elementSelected) {
                        if (hiddenContent.text()) {
                            hiddenContent.text('');
                            hiddenContent.blur();
                            window.getSelection().removeAllRanges();
                        }
                        if (previousCaretPosition != -1) {
                            document.activeElement.selectionStart = previousCaretPosition;
                        }
                    }
                });
            },
        };
    });
