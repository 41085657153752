import _ from 'angular';
import 'lodash';
    export default angular.module('moeService', [])

        .service('$moeService', [function() {
            var zvalues = {
                '0.70': 1.04,
                0.75: 1.15,
                '0.80': 1.28,
                0.85: 1.44,
                '0.90': 1.645,
                0.92: 1.75,
                0.95: 1.96,
                0.96: 2.05,
                0.98: 2.33,
                0.99: 2.58,
            };

            // Finite population correction factor
            var fpcf = function fpcf(populationSize, sampleSize) {
                return Math.sqrt((populationSize - sampleSize) / (populationSize - 1));
            };

            var largePopMoe = function largePopMoe(proportion, sampleSize, zScore) {
                return zScore * Math.sqrt((proportion * (1 - proportion)) / sampleSize);
            };

            var confidenceIntervals = function confidenceIntervals() {
                return Object.keys(zvalues);
            };

            var calculateMoe = function calculateMoe(sampleSize, confidenceInterval, populationSize, proportion) {
                var zscore = zvalues[confidenceInterval || '0.95'];
                var moe = largePopMoe(proportion || 0.5, sampleSize, zscore);
                if (populationSize) {
                    return moe * fpcf(populationSize, sampleSize);
                }
                return moe;
            };

            return {
                fpcf: fpcf,
                largePopMoe: largePopMoe,
                confidenceIntervals: confidenceIntervals,
                calculateMoe: calculateMoe,
            };
        }]);

