import _ from 'lodash';
import '../common-components/pub-sub';
import filterLogicConstants from '../filter-logic/filter-logic-constants';

angular.module('comparisonPanel', [
    'pubSub',
])
    .controller('ComparisonPanelCtrl', ComparisonPanelCtrl)
    .directive('comparisonPanel', comparisonPanel);

function comparisonPanel() {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/filter-and-compare/comparison-panel.html',
        scope: {
            save: '<',
            enabledOptions: '=',
            filterModel: '=',
            comparisonData: '=',
        },
        controller: 'ComparisonPanelCtrl',
        controllerAs: 'ComparisonPanelVM',
    };
}

ComparisonPanelCtrl.$inject = [
    '$scope', 'pubSubService',
];

function ComparisonPanelCtrl($scope, pubSubService) {
    var ComparisonPanelVM = this,
        COMPARISONS = 'comparisons',
        cleanChoices = {},
        cleanModel = angular.copy($scope.filterModel),
        cleanChoicesSet,
        _comparisonModel,
        _selectedComparisons;
    ComparisonPanelVM.ux = {
        comparisonsSelected: [0, 0],
    };
    ComparisonPanelVM.collapsedSubtitle = '';
    ComparisonPanelVM.selectedDimension = '';
    ComparisonPanelVM.descriptiveSubtitle = []; // NOTE — unused; save for future use
    ComparisonPanelVM.comparisonChoices = [];
    ComparisonPanelVM.description = 'Get more out of your data by applying a comparison. A comparison allows you to understand how one group of respondents answered your survey compared to a second group.';
    ComparisonPanelVM.setDimension = setDimension;
    ComparisonPanelVM.applyComparisonSelections = applyComparisonSelections;
    ComparisonPanelVM.resetComparisonSelections = resetComparisonSelections;
    ComparisonPanelVM.onComparisonSelect = onComparisonSelect;
    ComparisonPanelVM.onComparisonRemove = onComparisonRemove;

    init();

    function init() {
        setComparisonModel({
            comparisonData: $scope.comparisonData,
            filterModel: $scope.filterModel,
        });
        pubSubService.subscribe('refresh-filter-and-compare-model', $scope.$id, setComparisonModel);
        $scope.$on('$destroy', () => {
            pubSubService.destroy('refresh-filter-and-compare-model', $scope.$id);
        });
    }

    function setComparisonModel(params) {
        let defaultComparison = params.comparisonData;
        _comparisonModel = _comparisonModel || params.filterModel;
        ComparisonPanelVM.filterDimensions = ComparisonPanelVM.filterDimensions || processCompareTypes();
        if (defaultComparison) {
            setDimension(_.keys(defaultComparison[0])[0]);
            ComparisonPanelVM.compareByList = [defaultComparison[0][ComparisonPanelVM.selectedDimension], defaultComparison[1][ComparisonPanelVM.selectedDimension]];
            ComparisonPanelVM.ux.comparisonsSelected = [ComparisonPanelVM.compareByList[0].length, ComparisonPanelVM.compareByList[1].length];
            _selectedComparisons = defaultComparison;
        }
        else {
            ComparisonPanelVM.compareByList = [{}, {}];
            ComparisonPanelVM.selectedDimension = void 0;
            _selectedComparisons = [{}, {}];
        }
    }

    function processCompareTypes() {
        let processedCompareTypes = [];
        _.forEach($scope.enabledOptions, function(compareType) {
            let isDisabled = true;
            if (compareType === filterLogicConstants.criteriaTypes.BEACON_POOL) {
                if (!_comparisonModel.Beacon$Pool || _comparisonModel.Beacon$Pool.options.length < 2) {
                    return;
                }
                isDisabled = false;
            }
            else if (compareType === filterLogicConstants.criteriaTypes.GENDER && _comparisonModel.Demographic$Gender) {
                isDisabled = false;
            }
            //If more than 1 age filter options are available.
            else if (compareType === filterLogicConstants.criteriaTypes.AGE && _.filter(_comparisonModel.Demographic$Age.options, {
                disabled: false,
            }).length > 1) {
                isDisabled = false;
            }
            if (!cleanChoicesSet && !isDisabled) {
                cleanChoices[compareType] = _comparisonModel[compareType].options;
                _.forEach(cleanChoices[compareType], function(comparisonChoices, index) {
                    comparisonChoices.index = index;
                });
            }
            if (!isDisabled) {
                processedCompareTypes.push({
                    name: compareType,
                    disabled: isDisabled,
                    title: _comparisonModel[compareType].name,
                });
            }
        });
        cleanChoicesSet = true;
        return processedCompareTypes;
    }

    function setDimension(dimension) {
        ComparisonPanelVM.selectedDimension = dimension;
        ComparisonPanelVM.compareByList = [{}, {}];
        ComparisonPanelVM.comparisonChoices = angular.copy(cleanChoices[dimension]);
        if (dimension === filterLogicConstants.criteriaTypes.GENDER) {
            ComparisonPanelVM.ux.comparisonsSelected = [1, 1];
            ComparisonPanelVM.compareByList = [];
            _.forEach(ComparisonPanelVM.comparisonChoices, function(gender) {
                if (gender.isAvailable) {
                    ComparisonPanelVM.compareByList.push([gender]);
                }
            });
        }
        else {
            ComparisonPanelVM.compareByList = [{}, {}];
            ComparisonPanelVM.ux.comparisonsSelected = [0, 0];
        }
    }

    function applyComparisonSelections() {
        var dimension = ComparisonPanelVM.selectedDimension;
        _selectedComparisons = [{}, {}];
        _selectedComparisons[0][dimension] = ComparisonPanelVM.compareByList[0];
        _selectedComparisons[1][dimension] = ComparisonPanelVM.compareByList[1];
        $scope.comparisonData = _selectedComparisons;
        // Call save method with 'comparisons' parameter and data
        $scope.save({
            comparisonData: _selectedComparisons,
        }, COMPARISONS);
    }

    function resetComparisonSelections() {
        // Call save method with no data to reset
        $scope.comparisonData = void 0;
        $scope.save({
            comparisonData: [],
        }, COMPARISONS);

        // Get clean form data again
        setComparisonModel(cleanModel);
        // Clear comparison dimension
        ComparisonPanelVM.selectedDimension = '';
        // Clear comparisons
        _selectedComparisons = [];
        ComparisonPanelVM.comparisonChoices = [];
        ComparisonPanelVM.compareByList = [{}, {}];
    }

    function onComparisonSelect(selected, groupIndex) {
        selected.disabled = true;
        selected.set = true;
        ComparisonPanelVM.ux.comparisonsSelected[groupIndex]++;
    }

    function onComparisonRemove(deselected, groupIndex) {
        deselected.disabled = false;
        deselected.set = false;
        ComparisonPanelVM.ux.comparisonsSelected[groupIndex]--;
    }
}
