const roundedDaysSinceLastExposureCuts = {
    category: 'Rounded days since last exposure',
    categoryType: 'timeBased',
    type: {
        value: 'all',
        name: 'Overall',
    },
    controlType: 'Equivalent',
    options: [{
        name: 'Less than or equal to 2 days',
        value: 'DaysSinceLastExposure$<=2',
        exposed: {
            $le: ['Rounded Days Since Last Exposure', 2],
        },
    },
    {
        name: 'Less than or equal to 5 days',
        value: 'DaysSinceLastExposure$<=5',
        exposed: {
            $le: ['Rounded Days Since Last Exposure', 5],
        },
    },
    {
        name: 'Less than or equal to 7 days',
        value: 'DaysSinceLastExposure$<=7',
        exposed: {
            $le: ['Rounded Days Since Last Exposure', 7],
        },
    },
    {
        name: 'Less than or equal to 10 days',
        value: 'DaysSinceLastExposure$<=10',
        exposed: {
            $le: ['Rounded Days Since Last Exposure', 10],
        },
    },
    {
        name: 'Less than or equal to 14 days',
        value: 'DaysSinceLastExposure$<=14',
        exposed: {
            $le: ['Rounded Days Since Last Exposure', 14],
        },
    },
    {
        name: 'Less than or equal to 20 days',
        value: 'DaysSinceLastExposure$<=20',
        exposed: {
            $le: ['Rounded Days Since Last Exposure', 20],
        },
    }],
};

export default roundedDaysSinceLastExposureCuts;
