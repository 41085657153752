import '@uirouter/angularjs';
import '../../common-components/sv-dialog';
import '../../app-components/api-account-service';
import '../../survey/survey-statuses';
import '../../survey/api-survey-service';

angular.module('userAdmin.details', [
    'common.dialog',
    'apiAccountService',
    'apiSurveyService',
    'surveyStatusesService',
])
    .directive('adminUserDetails', adminUserDetails)
    .controller('UserDetailsCtrl', UserDetailsCtrl);

/**
 * Admin user details controller
 *
 * @returns {object}
 */
function adminUserDetails() {
    return {
        restrict: 'AE',
        templateUrl: '/admin-templates/user-details.html',
        controller: 'UserDetailsCtrl',
        controllerAs: 'UserDetailsVM',
    };
}
UserDetailsCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    'dialog',
    'accountService',
    'surveyService',
    'surveyStatusesService',
];

/**
 * Main user details controller
 *
 * @param {object} $scope
 * @param {object} $stateParams
 * @param {object} $state
 * @param {object} dialog
 * @param {object} accountService
 * @param {object} surveyService
 * @param {object} surveyStatusesService
 */
function UserDetailsCtrl($scope, $stateParams, $state, dialog, accountService, surveyService, surveyStatusesService) {
    var UserDetailsVM = this;
    UserDetailsVM.ux = {};
    UserDetailsVM.deleteSurvey = deleteSurvey;
    UserDetailsVM.duplicateSurvey = duplicateSurvey;
    UserDetailsVM.createSurveyForUser = createSurveyForUser;

    init();

    function init() {
        accountService.getUser($stateParams.userId).then(function(user) {
            UserDetailsVM.ux.ready = true;
            UserDetailsVM.currentUser = user;
            UserDetailsVM.appData = {
                currentUser: UserDetailsVM.currentUser,
            };
            $scope.appData = {
                currentUser: UserDetailsVM.currentUser,
            };
            accountService.listContactRequests($stateParams.userId).then(function(contacts) {
                UserDetailsVM.currentUser._contactRequests = contacts;
            });
            refreshSurveys();
        });
    }

    function refreshSurveys() {
        surveyService.listSurveys($stateParams.userId).then(function(surveys) {
            setSurveyState(surveys, '_surveys');
        }, function() {
            UserDetailsVM.currentUser._surveys = [];
        });
        surveyService.listSharedSurveys($stateParams.userId).then(function(sharedSurveys) {
            setSurveyState(sharedSurveys, '_sharedSurveys');
        });
    }

    /**
     * Set the state of the survey
     *
     * @param {object} surveys
     * @param {object} type
     */
    function setSurveyState(surveys, type) {
        _.forEach(surveys, function(survey) {
            survey.status = surveyStatusesService.getStatus(survey);
        });
        $scope.$evalAsync(() => {
            UserDetailsVM.currentUser[type] = surveys;
        });
    }

    /**
     * Delete the survey
     *
     * @param {string} surveyUuid
     */
    function deleteSurvey(surveyUuid) {
        surveyService.deleteSurvey(surveyUuid).then(function() {
            refreshSurveys();
        });
    }

    /**
     * Duplicate the given survey
     *
     * @param {string} surveyUuid
     */
    function duplicateSurvey(surveyUuid) {
        surveyService.duplicateSurvey(surveyUuid).then(function() {
            refreshSurveys();
        });
    }

    function createSurveyForUser() {
        surveyService.createSurvey({
            userId: UserDetailsVM.currentUser.id,
        }).then(function(survey) {
            surveyService.createAudience(survey.uuid, null).then(function() {
                $state.go('dashboard', {
                    surveyId: survey.uuid,
                });
            });
        });
    }
}
