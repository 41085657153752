import analysisConfigConstants from './analysis-config-constants';
import analysisConfigDataService from './analysis-config-data-service';
import './analysis-configuration';

angular.module('analysisConfig.manager', [
    'analysisConfiguration',
])
    .service('analysisConfigManager', analysisConfigManager);
analysisConfigManager.$inject = [
    '$rootScope', 'AnalysisConfiguration',
];

/**
 * Analysis configuration manager service.
 *
 * @param {object} $rootScope - The root scope of the app
 * @param {object} AnalysisConfiguration - Analysis configuration object
 * @returns {object} the exposed functions for this service
 */
function analysisConfigManager($rootScope, AnalysisConfiguration) {
    var analysisConfig;

    /**
     * Set the analysis configuration.
     *
     * @param {object} configuration - Object to set the congifiguration to
     */
    function set(configuration) {
        analysisConfig = new AnalysisConfiguration(configuration);
        if (_.size(analysisConfig.humanCutter.cuts)) {
            nextStep(0);
        }
    }

    /**
     * Get analysis config data.
     *
     * @param {string} [flag] - A param of analysis config object
     * @returns {object} - Analysis configuration data
     */
    function get(flag) {
        if (!arguments.length) {
            return analysisConfig;
        }
        if (analysisConfig[flag]) {
            return analysisConfig[flag];
        }
        console.error(flag + ' is not a valid parameter');
    }

    /**
     * Proceed to next step.
     *
     * @param {number} previousStepIdx - Index of previous step
     */
    function nextStep(previousStepIdx) {
        let steps = analysisConfigConstants.analysisConfigSteps;
        delete steps[previousStepIdx].isOpen;
        steps[previousStepIdx + 1].isOpen = true;
    }

    /**
     * Save analysis configuration.
     *
     * @returns {Promise} - Revolved upon analysis configuration API call completed
     */
    function saveAnalysisConfiguration() {
        return analysisConfigDataService.saveAnalysisConfiguration($rootScope.survey.uuid, analysisConfig);
    }

    return {
        nextStep: nextStep,
        get: get,
        set: set,
        saveAnalysisConfiguration: saveAnalysisConfiguration,
    };
}
