import targetingAttributeCountry from './country';
import targetingAttributeRegion from './region';
import targetingAttributeMetro from './metro';
import './postal-code';

angular.module('targetingAttributeGroup.geography', [
    'postalCode',
])
    .service('geography', geography);
geography.$inject = [
    'targetingAttributePostalCode',
];

function geography(targetingAttributePostalCode) {
    return {
        id: 'geography',
        name: 'Geography',
        basic: true,
        extended: true,
        advanced: true,
        attributeList: [
            targetingAttributeCountry,
            targetingAttributeRegion,
            targetingAttributeMetro,
            targetingAttributePostalCode,
        ],
    };
}
