angular.module('introStatement.preview', [])
    .directive('introStatementPreview', introStatementPreview);

function introStatementPreview() {
    return {
        restrict: 'E',
        template: '<p class="text-center" ng-bind-html="question.details.text"></p>',
        scope: {
            question: '=',
        },
    };
}
