angular.module('statementPreview.view', [])
    .directive('statementPreview', statementPreview)
    .controller('statementPreviewCtrl', statementPreviewCtrl);

function statementPreview() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/statement-preview.html',
        scope: {
            q: '=',
        },
        controller: 'statementPreviewCtrl',
    };
}
statementPreviewCtrl.$inject = ['$scope'];

function statementPreviewCtrl($scope) {
    function update() {
        $scope.question = $scope.q || {};
    }
    $scope.$watch('q', update);
    update();
}
