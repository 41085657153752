import {
    convertParsedCriteria,
} from '../filter-and-compare/filter-service';
import {
    convertFilterBy,
} from '../filter-and-compare/default-filter-service';
import filterLogicService from './filter-logic-service';

angular.module('filterLogicEditor', [])
    .directive('filterLogicEditor', filterLogicEditor)
    .controller('FilterLogicEditorCtrl', FilterLogicEditorCtrl);

function filterLogicEditor() {
    return {
        templateUrl: '/shared-templates/filter-logic-editor.html',
        controller: 'FilterLogicEditorCtrl',
        controllerAs: 'FilterLogicEditorVM',
        link: (scope, elem, attr, FilterLogicEditorVM) => {
            FilterLogicEditorVM.copyToClipboard = copyToClipboard;

            function copyToClipboard() {
                var inp = elem.find('copy-to-clipboard');
                if (inp && inp.select) {
                    inp.select();
                    try {
                        document.execCommand('copy');
                        inp.blur();
                    }
                    catch (err) {
                        copyToClipboard.ux.copyError = 'Unable to copy text, please press Ctrl/Cmd + C to copy.';
                    }
                }
            }
        },
        scope: {
            parsedCriteria: '=',
            filterModel: '=',
            customVariableUuid: '@?',
            onChange: '<?',
        },
    };
}
FilterLogicEditorCtrl.$inject = [
    '$rootScope', '$scope',
];

function FilterLogicEditorCtrl($rootScope, $scope) {
    var FilterLogicEditorVM = this;
    FilterLogicEditorVM.ux = {};
    FilterLogicEditorVM.availableToCopy = availableToCopy;
    FilterLogicEditorVM.customVariableUuid = $scope.customVariableUuid;
    FilterLogicEditorVM.state = $scope.state;
    FilterLogicEditorVM.parsedCriteria = _.cloneDeep($scope.parsedCriteria);
    FilterLogicEditorVM.customVariables = ($rootScope.survey._statsConfig && $rootScope.survey._statsConfig.filters.customVariables || {}).options || [];
    FilterLogicEditorVM.prepareJsonEditing = prepareJsonEditing;
    FilterLogicEditorVM.onChange = onChange;
    FilterLogicEditorVM.selectVariable = selectVariable;
    FilterLogicEditorVM.selectSubvariable = selectSubvariable;

    init();

    function init() {
        _.forEach(FilterLogicEditorVM.customVariables, cv => {
            if (cv.uuid !== FilterLogicEditorVM.customVariableUuid) {
                cv.availableToCopy = true;
            }
        });
    }

    function availableToCopy(cv) {
        return cv.availableToCopy;
    }

    function prepareJsonEditing(editing, $event) {
        if (editing) {
            FilterLogicEditorVM.jsonString = JSON.stringify(convertParsedCriteria(FilterLogicEditorVM.parsedCriteria, $scope.filterModel));
        }
        else {
            let newCriteria = convertFilterBy(JSON.parse(FilterLogicEditorVM.jsonString), []);
            if (!newCriteria || !filterLogicService.validSubVariable(newCriteria)) {
                FilterLogicEditorVM.ux.saveError = 'Invalid JSON. Please ensure that your json is in the correct format.';
                $event.stopPropagation();
                $event.preventDefault();
                return;
            }
            FilterLogicEditorVM.ux.saveError = void 0;
            FilterLogicEditorVM.parsedCriteria = newCriteria;
            onChange();
        }
        FilterLogicEditorVM.ux.editing = editing;
    }

    function onChange() {
        if ($scope.onChange) {
            $scope.onChange(FilterLogicEditorVM.parsedCriteria);
        }
    }

    function selectVariable() {
        FilterLogicEditorVM.copyError = void 0;
        FilterLogicEditorVM.ux.svJSON = void 0;
    }

    function selectSubvariable(selected) {
        FilterLogicEditorVM.ux.svJSON = JSON.stringify(selected.filterJson);
        FilterLogicEditorVM.copyError = void 0;
    }
}
