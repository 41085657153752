import _ from 'lodash';

const analysisPlanConstants = {
    SIGNIFICANCE_LEVELS: 'lift_significance_levels',
    TEST_LEVELS: 'audience_profile_significance_levels',

    timeBinOptions: _.range(1, 5),

    defaultSignificanceLevels: [95,
        85,
        80],

    numSignificanceLevels: 3,

    questionAnswerTableConfig: {
        headers: [{
            name: '&nbsp;',
            sortable: true,
            columnType: 'text',
            sortField: '_index',
            value: '_label',
        },
        {
            name: 'Question name',
            sortable: true,
            columnType: 'text',
            sortField: 'name',
            value: '_displayName',
            eventName: 'update-factor',
        },
        {
            name: '',
            expandable: true,
            simple: true,
        }],
        options: {
            nestedRows: [{
                nestedRowField: 'levels',
                type: 'table',
                rowClass: 'overflow-visible',
                columns: [{
                    value: '_label',
                    columnType: 'text',
                    columnClass: 'text-bold text-right',
                    simple: true,
                    inputType: 'radio',
                }],
            }],
        },
    },
};

export default analysisPlanConstants;
