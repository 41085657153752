import '@uirouter/angularjs';
import '../../../../common/js/angular-util';

angular.module('apiResultsService', ['ui.router', 'angularUtil'])
    .service('$resultsService', resultsService);
resultsService.$inject = [
    '$state',
    '$filter',
    'httpDeferred',
];

/**
 * Fetches and filters results
 *
 * @param {object} $state
 * @param {Function} $filter - Converts data to UI-friendly format
 * @param {object} httpDeferred - Wrapper for Angular $http, with additional functionality
 * @returns {object} - Object that summarizes results that is populated throughout UI
 */
function resultsService($state, $filter, httpDeferred) {
    /**
     * Gets summary of survey results
     *
     * @param {object} survey -- details about the survey
     * @returns {object} -- Results summary returns specific data about survey
     */
    function getResultsSummary(survey) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/results-summary').then(function(resultsSummary) {
            survey._resultsSummary = angular.copy(resultsSummary);
            return resultsSummary;
        });
    }

    return {
        getResultsSummary: getResultsSummary,
    };
}

