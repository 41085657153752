import _ from 'lodash';

import '../../../../common/js/util';
import FilterLogicModel from '../filter-logic/filter-logic-model';
import {
    convertParsedCriteria,
} from '../filter-and-compare/filter-service';
import {
    convertFilterBy,
} from '../filter-and-compare/default-filter-service';
import filterLogicService from '../filter-logic/filter-logic-service';
import customVariablesService from './custom-variables.api-service';

angular.module('customVariables.editor', [
])
    .controller('VariableEditorCtrl', VariableEditorCtrl);
VariableEditorCtrl.$inject = [
    '$rootScope',
    '$uibModalInstance',
    'activeCV',
];

/**
 * @param $rootScope
 * @param $uibModalInstance
 * @param activeCV
 */
function VariableEditorCtrl($rootScope, $uibModalInstance, activeCV) {
    var CVEditorVM = this,
        previouslyOpen;

    // VM exposed variables
    CVEditorVM.ux = {};
    CVEditorVM.auth = $rootScope.auth;
    CVEditorVM.showHeaderNameForm = true;

    // VM exposed functions
    CVEditorVM.deleteSubVariable = deleteSubVariable;
    CVEditorVM.saveVariable = saveVariable;
    CVEditorVM.addSubVariable = addSubVariable;
    CVEditorVM.updateSubvariableName = updateSubvariableName;
    CVEditorVM.collapse = collapse;
    CVEditorVM.editCVName = editCVName;
    CVEditorVM.closeModal = closeModal;

    init();

    // Parse the details JSON string and initialize the UI
    function init() {
        let idx = 0;
        CVEditorVM.activeCV = angular.copy(activeCV);
        CVEditorVM.subVariables = [];
        // Iterate through the details json and populate the sub variables array
        _.forEach(CVEditorVM.activeCV.options || {}, subVariable => {
            CVEditorVM.subVariables.push({
                collapsed: true,
                label: subVariable.id,
                previousLabel: subVariable.id,
                filterModel: new FilterLogicModel($rootScope.survey, true),
                idx: idx++,
                parsedCriteria: convertFilterBy(subVariable.filterJson, []),
            });
        });
        CVEditorVM.showHeaderNameForm = !CVEditorVM.activeCV.name;
    }

    /**
     * @param subVar
     */
    function collapse(subVar) {
        subVar.collapsed = !subVar.collapsed;
        subVar.initialized = true;
        if (previouslyOpen && previouslyOpen.idx !== subVar.idx) {
            previouslyOpen.collapsed = true;
        }
        previouslyOpen = subVar;
    }

    function editCVName() {
        CVEditorVM.showHeaderNameForm = !CVEditorVM.showHeaderNameForm;
    }

    // Save active cv by creating or updating
    /**
     *
     */
    function saveVariable() {
        const newDetailsJson = {};
        let toSave;
        if (!CVEditorVM.activeCV.name) {
            CVEditorVM.ux.saveError = 'Please name your variable.';
            return;
        }
        _.forEach(CVEditorVM.subVariables, subVar => {
            if (subVar.deleted) {
                return;
            }
            if (!subVar.label || !subVar.label.length) {
                CVEditorVM.ux.saveError = 'A subvariable name is required.';
                return false;
            }
            if (newDetailsJson[subVar.label]) {
                CVEditorVM.ux.saveError = 'Subvariable ' + subVar.label + ' is already used in this variable. Please change its name.';
                return false;
            }
            if (!filterLogicService.validSubVariable(subVar.parsedCriteria)) {
                CVEditorVM.ux.saveError = 'Subvariable ' + subVar.label + ' is incomplete';
                return false;
            }
            subVar.filterJson = convertParsedCriteria(subVar.parsedCriteria, subVar.filterModel);
            newDetailsJson[subVar.label] = subVar.filterJson;
        });
        if (CVEditorVM.ux.saveError) {
            return;
        }
        CVEditorVM.activeCV.detailsJson = newDetailsJson;
        toSave = angular.copy(CVEditorVM.activeCV);

        if (CVEditorVM.activeCV.id || CVEditorVM.activeCV.uuid) {
            //Iterate through all widgets to update ones that are for the updated custom variable.
            return customVariablesService.update($rootScope.survey, toSave).then(cv => {
                let promises = [];
                Promise.all(promises).then(() => {
                    closeModal(cv);
                });
            });
        }
        return customVariablesService.create($rootScope.survey, toSave).then(closeModal);
    }

    function addSubVariable() {
        let key = 'New sub-variable';

        CVEditorVM.subVariables.push({
            collapsed: true,
            label: key,
            previousLabel: key,
            filterModel: new FilterLogicModel($rootScope.survey, true),
            idx: CVEditorVM.subVariables.length,
            parsedCriteria: [{
                parentLogic: '$and',
                criteria: [],
            }],
        });
    }

    /**
     * @param subVar
     */
    function updateSubvariableName(subVar) {
        subVar.previousLabel = subVar.label;
    }

    /**
     * @param idx
     */
    function deleteSubVariable(idx) {
        CVEditorVM.subVariables.splice(idx, 1);
    }

    /**
     * @param args
     */
    function closeModal(args) {
        $uibModalInstance.close(args);
    }
}
