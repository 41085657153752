const AND = {
        name: 'is',
        value: '$and',
    },
    CONTAINS = {
        name: 'includes',
        value: '$or',
    },
    CONTAINS_GE = {
        name: 'includes',
        value: '$ge',
    },
    IS_NOT = {
        name: 'is not',
        value: '$not',
    },
    IS_EXACTLY = {
        name: 'is exactly',
        value: 'EXACTLY', // This will later be changed to $and
    },
    IS = {
        name: 'is',
        value: '$eq',
    },
    LESS_THAN = {
        name: '<',
        value: '$lt',
    },
    LESS_THAN_EQUAL = {
        name: '<=',
        value: '$le',
    },
    EQUALS = {
        name: 'is',
        value: '$eq',
    },
    GREATER_THAN = {
        name: '>',
        value: '$gt',
    },
    GREATER_THAN_EQUAL = {
        name: '>=',
        value: '$ge',
    },
    // eslint-disable-next-line no-unused-vars
    IN = {
        name: 'in',
        value: '$in',
    },
    ANY = {
        name: 'any',
        value: '$any',
    },
    FUZZY_CONTAINS = {
        name: 'contains',
        value: '$fuzzy_contains',
    },
    FUZZY_EQ = {
        name: 'matches',
        value: '$fuzzy_matches',
    };
/* eslint complexity: off */
/**
 * Get the logic phrase by the id of the logic model
 *
 * @param {string} id
 * @returns {object[]} the array of the logic phrase
 */
function getLogicPhraseById(id) {
    const baseEquivalence = [
        LESS_THAN,
        LESS_THAN_EQUAL,
        EQUALS,
        GREATER_THAN_EQUAL,
        GREATER_THAN,
    ];
    switch (id) {
        case 'Demographic$Age':
        case 'Beacon$Pool':
        case 'ControlFor$':
        case 'ControlBeacon$':
        case 'ControlBeaconPool$':
        case 'Beacon$':
        case 'Demographic$State':
        case 'Demographic$Region':
        case 'Operating$System':
        case 'Urbanicity':
        case 'MedianIncomeRangeOfPostalCode':
            return [
                CONTAINS, IS_NOT,
            ];
        case 'Demographic$Gender':
            return [
                IS,
            ];
        case 'Question$Choice':
            return [
                CONTAINS, IS_NOT,
            ];
        case 'Question$ChoiceExtended':
            return [
                CONTAINS,
                IS_NOT,
                IS_EXACTLY,
            ];
        case 'Question$ChoiceFuzzy':
            return [FUZZY_CONTAINS, FUZZY_EQ];
        case 'Custom$Variable':
            return [
                CONTAINS,
            ];
        case 'ImpressionParameterGroups':
            return [
                CONTAINS_GE,
                ANY,
                IS_NOT,
            ];
        case 'BeaconFrequency$':
            return baseEquivalence;
        case 'Date (UTC)':
        case 'Frequency':
        case 'Rounded Days Since Last Exposure':
            return baseEquivalence;
        case 'Frequency_':
            return [
                AND, IS_NOT,
            ];
        default:
            return [];
    }
}

export default getLogicPhraseById;
