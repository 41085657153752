import '../../../../common/js/inc/sv-notify';
import '../common-components/sv-dialog';
import '../common-components/pub-sub';
import '../app-components/api-account-service';
import '../survey/api-survey-service';
import '../survey/survey-to-string-service';
import '../survey/survey-statuses';
import '../survey-dashboard/survey-dashboard-service';

angular.module('adminBar.view', [
    'svNotify',
    'common.dialog',
    'pubSub',
    'apiAccountService',
    'surveyToStringService',
    'apiSurveyService',
    'surveyStatusesService',
    'surveyDashboardService',
])
    .directive('adminBar', adminBar)
    .controller('AdminBarCtrl', AdminBarCtrl);

/**
 * Top level admin-bar
 *
 * @returns {*}
 */
function adminBar() {
    return {
        restrict: 'E',
        templateUrl: 'admin-templates/admin-bar.html',
        controller: 'AdminBarCtrl',
        controllerAs: 'AdminBarVM',
    };
}
AdminBarCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$notify',
    'dialog',
    'pubSubService',
    'accountService',
    '$dashboardService',
    'surveyService',
    'surveyStatusesService',
];

/**
 * Admin bar controller
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $notify
 * @param {object} dialog
 * @param {object} pubSubService
 * @param {object} accountService
 * @param {object} $dashboardService
 * @param {object} surveyService
 * @param {object} surveyStatusesService
 */
function AdminBarCtrl($rootScope, $scope, $notify, dialog, pubSubService, accountService, $dashboardService, surveyService, surveyStatusesService) {
    var AdminBarVM = this;
    AdminBarVM.ux = {
        showAdminBackground: new Date().getDay() === 5, // Trolling Friday!
        sampleSizeDraft: 0,
    };
    AdminBarVM.survey = $rootScope.survey;
    AdminBarVM.toggleAdminPanel = toggleAdminPanel;
    AdminBarVM.surveyStatus = surveyStatus;
    AdminBarVM.showPreview = showPreview;
    AdminBarVM.changeSurveyStatus = changeSurveyStatus;
    AdminBarVM.moveToAnalystReview = moveToAnalystReview;
    AdminBarVM.sendReadyQuoteNotif = sendReadyQuoteNotif;
    AdminBarVM.deliverSurveyResults = deliverSurveyResults;
    AdminBarVM.deliverCollectionPeriodResults = deliverCollectionPeriodResults;
    AdminBarVM.saveClientEditable = saveClientEditable;
    AdminBarVM.cancelClientDeadline = cancelClientDeadline;
    AdminBarVM.saveDeadline = saveDeadline;
    AdminBarVM.editClientDeadline = editClientDeadline;
    AdminBarVM.editPriority = editPriority;
    AdminBarVM.copyDefaultResultsFilter = copyDefaultResultsFilter;
    AdminBarVM.setDefaultResultsFilter = setDefaultResultsFilter;
    AdminBarVM.openUpdateUserModal = openUpdateUserModal;

    // Scope issues will be taken care of in a future PR
    $scope.cancelSurvey = cancelSurvey;

    init();

    function init() {
        AdminBarVM.ux.canDeliverSurvey = surveyStatus() === 'Running' || surveyStatus() === 'Paused';
        if ($rootScope.survey.collectionPeriods.length > 1) {
            // Assume all previous collection periods have been completed
            let cpStatus = getLatestCollectionPeriodStatus();
            AdminBarVM.ux.canDeliverCollectionPeriod = cpStatus === 'Running' || cpStatus === 'Paused';
        }
        setSurveyState();
    }

    function setSurveyState() {
        const cp = $rootScope.survey.latestCollectionPeriod || {};
        $scope.$evalAsync(() => {
            AdminBarVM.ux.showStartResume = $rootScope.survey.paused || (!cp.started && $rootScope.survey.draftCompleted && !cp.canceled);
            AdminBarVM.ux.canPause = cp.started && !$rootScope.survey.paused && !cp.completed;
            AdminBarVM.ux.canCancel = ($rootScope.survey.draftCompleted || $rootScope.survey.quoteRequested) && !$rootScope.survey.canceled;
            AdminBarVM.ux.canRestart = $rootScope.survey.completed || cp.completed;
        });
    }

    function cancelSurvey() {
        var params = {
            cancel: 'true',
            cancellationReason: $scope.ux.cancellationReason,
        };
        if ($scope.ux.cancellationComment) {
            params.cancellationComment = $scope.ux.cancellationComment;
        }

        surveyService.updateSurvey($rootScope.survey.uuid, params).then(function() {
            $scope.$evalAsync(() => {
                $rootScope.survey.canceled = true;
            });
            $('#survey-cancel-modal').modal('hide');
        });
    }

    function toggleAdminPanel() {
        AdminBarVM.ux.adminBarExpanded = !AdminBarVM.ux.adminBarExpanded;
        $rootScope.ux.adminBarExpanded = AdminBarVM.ux.adminBarExpanded;
    }

    /**
     * Get survey status
     *
     * @returns {object}
     */
    function surveyStatus() {
        return surveyStatusesService.getStatus($rootScope.survey);
    }

    /**
     * Get latest CP status
     *
     * @returns {object}
     */
    function getLatestCollectionPeriodStatus() {
        return surveyStatusesService.getStatus($rootScope.survey.latestCollectionPeriod);
    }

    function showPreview() {
        $dashboardService.showPreview($rootScope.survey);
    }

    /**
     * Change survey status
     *
     * @param {object} status
     */
    function changeSurveyStatus(status) {
        surveyStatusesService.changeSurveyStatus($rootScope.survey, status).then(survey => {
            AdminBarVM.survey = survey;
            $rootScope.survey = survey;
            setSurveyState();
        });
    }

    function moveToAnalystReview() {
        if ($rootScope.survey.paymentPrompted) {
            surveyStatusesService.changeSurveyStatus($rootScope.survey, 'completeDraft');
        }
        else {
            dialog.popup({
                templateUrl: '/admin-templates/move-to-analyst-review-modal.html',
                controller: 'MoveToAnalystReviewCtrl',
                controllerAs: 'MoveToAnalystReviewVM',
            });
        }
    }

    function sendReadyQuoteNotif() {
        accountService.sendEmail('quote-ready-for-price-notification', {
            surveyUuid: $rootScope.survey.uuid,
        }).then(function() {
            $notify.success('Email sent.');
        }, function() {
            $notify.error('Error sending notification email');
        });
    }

    function deliverCollectionPeriodResults() {
        surveyService.finishPeriod($rootScope.survey.latestCollectionPeriod.uuid).then(function(completedPeriod) {
            AdminBarVM.ux.canDeliverCollectionPeriod = false;
            $rootScope.survey.latestCollectionPeriod = completedPeriod;
            $notify.success('Period completed.', {
                clear: true,
                timeout: 1500,
            });
        });
    }

    function deliverSurveyResults() {
        // Complete the collection period first, if necessary
        if (AdminBarVM.ux.canDeliverCollectionPeriod) {
            deliverCollectionPeriodResults();
        }
        surveyService.finishSurvey($rootScope.survey.uuid).then(function(completedSurvey) {
            AdminBarVM.ux.canDeliverSurvey = false;
            $rootScope.survey = completedSurvey;
            $notify.success('Survey completed.', {
                clear: true,
                timeout: 1500,
            });
        });
    }

    function saveClientEditable() {
        surveyService.updateSurvey($rootScope.survey.uuid, {
            clientEditable: $rootScope.survey.clientEditable,
        }).then(function(response) {
            $rootScope.survey.clientEditable = response.clientEditable;
        });
    }

    function cancelClientDeadline() {
        AdminBarVM.ux.deadlineDraft = null;
    }

    function saveDeadline() {
        $rootScope.survey.clientDeadline = AdminBarVM.ux.deadlineDraft;
        saveSurveyInfo({
            name: 'clientDeadline',
        });
    }

    /**
     * Save survey
     *
     * @param {object} field
     */
    function saveSurveyInfo(field) {
        var params = {};
        params[field.name] = $rootScope.survey[field.name];
        surveyService.updateSurvey($rootScope.survey.uuid, params).then(function() {
            $notify.success('Survey info saved!', {
                clear: true,
                timeout: 1500,
            });
            AdminBarVM.ux.deadlineDraft = null;
        });
    }

    function editClientDeadline() {
        AdminBarVM.ux.deadlineDraft = $rootScope.survey.clientDeadline;
    }

    /**
     * Edit survey priority
     *
     * @param {number} newPriorityVal
     */
    function editPriority(newPriorityVal) {
        surveyService.updateSurvey($rootScope.survey.uuid, {
            priority: newPriorityVal,
        }).then(function(response) {
            $rootScope.survey.priority = response.priority;
        });
    }

    function copyDefaultResultsFilter() {
        pubSubService.notify('copy-default-filter');
    }

    function setDefaultResultsFilter() {
        pubSubService.notify('default-filter');
        $('#results-default-modal').modal('hide');
    }

    function openUpdateUserModal() {
        dialog.popup({
            templateUrl: '/admin-templates/admin-update-user.html',
            controller: 'AdminUpdateUserCtrl',
            controllerAs: 'UpdateUserVM',
        });
    }
}
