import exportConstants from '../export/export-constants';
import exportFactory from '../export/export-factory';
import '../common-components/pub-sub';
import '../common-components/viewport-pub-sub';
import '../charts/charts-service';
import filterLogicConstants from '../filter-logic/filter-logic-constants';
import './chart-data-parser';
import './charts-widget-service';

angular.module('chartsWidget.charts', [
    'pubSub',
    'chart.dataParser',
    'charts.service',
    'chartsWidget.service',
])
    .controller('ChartWidgetCtrl', ChartWidgetCtrl)
    .directive('chartWidget', chartWidget);
ChartWidgetCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'pubSubService',
    'chartDataParser',
    'myChartService',
    'chartWidgetService',
];

/**
 * Chart Widget Controller
 *
 * Controller of the chartsWidget.charts
 * This controller is responsible for rendering the chart and manipulation of the chart's various visual aspects.
 * The chart is initialized when the render-chart-{{chartId}} is called.
 *
 * @property {object} question:object A misnomer since the chart could be representing something other than a question.
 * @property {number} gridIndex:number If defined, then indicates that the chart is a single line in a grid.
 * @property {string} chartNum:string The id of the chart.
 * @property {string} chartDisplayNum:string If it is a line in a grid chart, the chartDisplayNum will defer from the chartNum. -- to revisit.
 * @property {object} visualMetadata:object Stores the following keys: fullWidth, errorbars, orientation, and showingBarChart.
 * @property {object} params:object Contains the params that were applied to the chart data.
 * @param $rootScope
 * @param $scope
 * @param $state
 * @param pubSubService
 * @param chartDataParser
 * @param myChartService
 * @param chartWidgetService
 */
function ChartWidgetCtrl($rootScope, $scope, $state, pubSubService, chartDataParser, myChartService, chartWidgetService) {
    var ChartWidgetVM = this,
        gridIndex = angular.isDefined($scope.gridIndex) ? parseInt($scope.gridIndex) : void 0,
        chartNum = $scope.chartNum,
        ERRORBARS = 'errorbars';
    //Static
    ChartWidgetVM.question = $scope.question;
    ChartWidgetVM.gridIndex = gridIndex;
    ChartWidgetVM.isGridChart = _.isNumber(gridIndex);
    ChartWidgetVM.questionDetails = ChartWidgetVM.question.details;
    ChartWidgetVM.isCustomReportView = $state.current.name === 'customReports';
    ChartWidgetVM.isFreeResponse = chartDataParser.isFreeResponse(ChartWidgetVM.questionDetails.type);
    ChartWidgetVM.chartNum = chartNum + (ChartWidgetVM.isGridChart ? '-' + gridIndex : '');
    ChartWidgetVM.chartId = '#gen-chart-' + ChartWidgetVM.chartNum;
    ChartWidgetVM.hasImageHeader = ChartWidgetVM.questionDetails.imageUrl || ChartWidgetVM.questionDetails.statement.image;
    ChartWidgetVM.canShowFullWidthDisplay = !ChartWidgetVM.isGridChart;
    ChartWidgetVM.auth = $rootScope.auth;
    ChartWidgetVM.params = {};
    ChartWidgetVM.messages = {
        errorBarPopup: '<span class="fs-7"><strong>Error bars</strong> show the inherent uncertainty with extrapolating insights from the surveyed population onto the greater whole</span>',
    };

    //Changing state
    ChartWidgetVM.ux = {
        loading: true,
        chartDetails: {},
        widgetOptions: {
            question: ChartWidgetVM.question,
            chartNum: ChartWidgetVM.chartNum,
            gridIndex: gridIndex,
            surveyUuid: $rootScope.survey.uuid,
        },
        notSupported: U.isSafari() || U.isIe(),
    };

    //Functions
    ChartWidgetVM.toggleFullWidthDisplay = toggleFullWidthDisplay;
    ChartWidgetVM.toggleFreeResponseDisplay = toggleFreeResponseDisplay;
    ChartWidgetVM.toggleErrorBarVisibility = toggleErrorBarVisibility;
    ChartWidgetVM.flipChartAxis = flipChartAxis;
    ChartWidgetVM.refreshChart = refreshChart;
    ChartWidgetVM.resetChartDisplay = resetChartDisplay;
    ChartWidgetVM.reloadData = reloadData;
    ChartWidgetVM.showErrorbars = showErrorbars;

    init();

    function init() {
        var updateErrorTopic = 'update-error-' + chartNum,
            renderChartTopic = 'render-chart-' + ChartWidgetVM.chartNum,
            toggleFullWidthDisplayTopic = 'toggle-full-width-' + chartNum;
        if (ChartWidgetVM.isGridChart) {
            $scope.visualMetadata[$scope.gridIndex] = $scope.visualMetadata[$scope.gridIndex] || {};
            ChartWidgetVM.visualMetadata = $scope.visualMetadata[$scope.gridIndex];
            ChartWidgetVM.chartDisplayNum = $scope.question.index + '.' + (gridIndex + 1);
        }
        else {
            ChartWidgetVM.visualMetadata = $scope.visualMetadata || {};
            ChartWidgetVM.chartDisplayNum = $scope.question.index;
        }
        if (ChartWidgetVM.question.type === 'rating') {
            ChartWidgetVM.lowLabel = ChartWidgetVM.questionDetails.lowLabel ? '1 ' + (ChartWidgetVM.questionDetails.displayType === 'slider' ? '' : ChartWidgetVM.questionDetails.glyph) + ' = ' + ChartWidgetVM.questionDetails.lowLabel : '';
            ChartWidgetVM.highLabel = ChartWidgetVM.questionDetails.highLabel ? ChartWidgetVM.questionDetails.length + ' ' + (ChartWidgetVM.questionDetails.displayType === 'slider' ? '' : ChartWidgetVM.questionDetails.glyph + 's') + ' = ' + ChartWidgetVM.questionDetails.highLabel : '';
        }
        ChartWidgetVM.ux.fullWidth = ChartWidgetVM.visualMetadata.fullWidth;
        ChartWidgetVM.ux.errorbarsShowing = ChartWidgetVM.visualMetadata.errorbars;
        pubSubService.subscribe(updateErrorTopic, $scope.$id, updateError);
        pubSubService.subscribe(renderChartTopic, $scope.$id, onLoadChart);
        pubSubService.subscribe(toggleFullWidthDisplayTopic, $scope.$id, toggleFullWidthDisplay);
        pubSubService.flushTopic(renderChartTopic);
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                renderChartTopic,
                updateErrorTopic,
                toggleFullWidthDisplayTopic,
            ], $scope.$id);
            if (ChartWidgetVM.ux.chart) {
                myChartService.destroyOldInteractions(ChartWidgetVM.ux.chart);
            }
        });
    }

    function startLoading() {
        ChartWidgetVM.ux.loading = true;
        ChartWidgetVM.ux.dataError = false;
        ChartWidgetVM.ux.readableError = '';
    }

    /**
     * Update the error message.
     *
     * @param error - Error message to be displayed
     */
    function updateError(error) {
        ChartWidgetVM.ux.dataError = true;
        ChartWidgetVM.ux.readableError = error;
    }

    /**
     * Flips the axis of the chart.
     *
     * @param {object} $event - The click event
     */
    function flipChartAxis($event) {
        $event.preventDefault(); // Stops browser from jumping to top
        $event.stopPropagation(); // Keeps dropdown from closing on click
        ChartWidgetVM.ux.chart.swapAxis();
    }

    /**
     * Whether to switch the view type of free response question
     *
     * @returns {boolean} Whether to switch the view
     */
    function switchFreeResponseView() {
        return ChartWidgetVM.isFreeResponse && !ChartWidgetVM.ux.chartDetails.isLineChart && !ChartWidgetVM.ux.chartDetails.comparisonData;
    }

    /**
     *
     */
    function showPlottableChart() {
        return !ChartWidgetVM.isFreeResponse || !ChartWidgetVM.ux.showingWordCloud;
    }

    /*
     * Whether to display the flip chart button
     *
     * @returns {boolean} - Whether to display the flip chart button
     */
    /**
     *
     */
    function showFlipChartButton() {
        return ChartWidgetVM.ux.showingPlottableChart && !ChartWidgetVM.ux.chartDetails.isLineChart;
    }

    /**
     * Toggle the display of free response question.
     *
     * @param {object} $event - Click event
     */
    function toggleFreeResponseDisplay($event) {
        $event.preventDefault(); // Stops browser from jumping to top
        $event.stopPropagation(); // Keeps dropdown from closing on click
        ChartWidgetVM.visualMetadata.showingBarChart = !ChartWidgetVM.visualMetadata.showingBarChart;

        // NOTE: errorbar toggle UI hidden when showing wordclouds on a chart supporting errrobars
        ChartWidgetVM.ux.showingWordCloud = !ChartWidgetVM.ux.showingWordCloud;
        ChartWidgetVM.ux.showToggleFullWidthDisplay = !ChartWidgetVM.ux.showingWordCloud;
        ChartWidgetVM.ux.showingPlottableChart = !ChartWidgetVM.ux.showingWordCloud;
        ChartWidgetVM.ux.showingFlipChartButton = !ChartWidgetVM.ux.showingWordCloud;
        ChartWidgetVM.setSVGClass(ChartWidgetVM.ux.chart);

        if (!ChartWidgetVM.ux.showingWordCloud) {
            ChartWidgetVM.ux.chart.redraw();
            toggleFullWidthDisplay($event);
        }
        if (ChartWidgetVM.ux.showingWordCloud && ChartWidgetVM.ux.fullWidth) {
            toggleFullWidthDisplay($event);
        }
    }

    /**
     * Whether to display errrorbar toggle.
     *
     * @param {object} chart - The chart
     * @param {object} question - The question
     * @returns {boolean} - Whether to display toggle
     */
    function showErrorbarToggleInUI(chart, question) {
        /* Because there isn't a case where one chart of a grid can have a errorbars and not any other chart
        for grids we can look only at the first chart to see if we can show the errorbarToggle in the ui */
        var showPlottableChart = !chartDataParser.hidePlottableChart(question);
        return chart.hasPlugin(ERRORBARS) && showPlottableChart && !/freeResponse/i.test(question.type) && !question.respondentDataType;
    }

    /**
     * Display error bars.
     *
     * @param {object} chart - The chart
     */
    function showErrorbars(chart) {
        return chart.hasPlugin(ERRORBARS) && chart.hasPlugin(ERRORBARS).visible();
    }

    /**
     * @name toggleFullWidthDisplay
     * @param {Event} $event - The click event
     *
     * @description
     * When toggling width for grid charts, the event is caught on the parent level, and thus $event is undefined.
     * Because $event.stopPropagation is called, the fullWidth flag in visualMetadata must still be set even with inputs.
     */
    function toggleFullWidthDisplay($event) {
        /* NOTE function is not available to template when wordcloud is displayed -- will be updated later */
        if ($event) {
            $event.preventDefault(); // Stops browser from jumping to top
            $event.stopPropagation(); // Keeps dropdown from closing on click
        }
        ChartWidgetVM.visualMetadata.fullWidth = !ChartWidgetVM.visualMetadata.fullWidth;
        ChartWidgetVM.ux.loading = true;
        ChartWidgetVM.ux.fullWidth = !ChartWidgetVM.ux.fullWidth;
        setTimeout(() => {
            ChartWidgetVM.ux.chart.redraw().then(() => {
                ChartWidgetVM.ux.errorbarsShowing = showErrorbars(ChartWidgetVM.ux.chart);
                ChartWidgetVM.setSVGClass(ChartWidgetVM.ux.chart);
                delete ChartWidgetVM.ux.loading;
            });
        }, 20);
    }

    /**
     * @param $event
     */
    function toggleErrorBarVisibility($event) {
        $event.preventDefault(); // Stop browser from jumping to top or navigating away
        $event.stopPropagation(); // Keeps dropdown from closing on click
        ChartWidgetVM.visualMetadata.errorbars = !ChartWidgetVM.visualMetadata.errorbars;

        // Guard against rare times when chart is undefined
        if (ChartWidgetVM.ux.chart) {
            let errorbars = ChartWidgetVM.ux.chart.hasPlugin(ERRORBARS);
            errorbars.visible(!errorbars.visible());

            // Toggle the errorbar visibility in the chart and UI -- we always have a ref to an errorbar
            ChartWidgetVM.ux.errorbarsShowing = errorbars.visible();
            ChartWidgetVM.setSVGClass(ChartWidgetVM.ux.chart);
        }
    }

    function reloadData() {
        startLoading();
        pubSubService.notify('reload-data-' + $scope.chartNum, [ChartWidgetVM.params]);
    }

    /**
     * @name onLoadChart
     * @param {Promise} chartDataPromise - Promise that resolves chart data.
     * @param params
     * @param {object} newTallyParams - Params that were applied to chart data.
     */
    function onLoadChart(chartDataPromise, params) {
        if (!chartDataPromise) {
            return;
        }
        startLoading();
        ChartWidgetVM.params = params;
        $scope.params = params;
        ChartWidgetVM.ux.isComparison = !!params.comparisonData;
        ChartWidgetVM.ux.isTracker = chartDataParser.isTracker(params.collectionPeriods);
        ChartWidgetVM.ux.showingWordCloud = ChartWidgetVM.visualMetadata.showingBarChart ? !ChartWidgetVM.visualMetadata.showingBarChart : ChartWidgetVM.isFreeResponse && !ChartWidgetVM.ux.isTracker;
        ChartWidgetVM.ux.showingPlottableChart = showPlottableChart();
        ChartWidgetVM.ux.showingBPNote = $scope.chartNum === filterLogicConstants.criteriaTypes.BEACON_POOL && hasMoreThanNBeaconPools(10);
        ChartWidgetVM.ux.shouldShowDivider = ChartWidgetVM.ux.canSwitchFreeResponseView || ChartWidgetVM.ux.errorbarToggleAvailableInUI || ChartWidgetVM.ux.showingFlipChartButton;
        chartDataPromise.then(function(newData) {
            var error = newData.error || (newData.errorMsg && newData.errorMsg.error);
            if (error) {
                pubSubService.notify('update-error-' + ChartWidgetVM.chartNum, [error]);
            }
            ChartWidgetVM.ux.chartDetails = chartWidgetService.getChartDetails(ChartWidgetVM.question, params, newData);
            ChartWidgetVM.ux.canSwitchFreeResponseView = switchFreeResponseView();
            ChartWidgetVM.ux.showingFlipChartButton = showFlipChartButton();
            ChartWidgetVM.ux.chart = chartWidgetService.getChart(newData, ChartWidgetVM.question, params, ChartWidgetVM.ux.chart || ChartWidgetVM.chartId, {
                horizontal: ChartWidgetVM.visualMetadata.horizontal,
            });
            if (ChartWidgetVM.ux.chart.enablePanZoom()) {
                ChartWidgetVM.ux.chart._hasZoomAvailable = true;
                ChartWidgetVM.ux.chart.addEventListener(ChartWidgetVM.ux.chart.EVENTS.PLOT_INTERACTIONS_TOGGLED, plotInteractionsToggledHandler, 'plot-interactions-toggled');
                ChartWidgetVM.ux.chart.addEventListener(ChartWidgetVM.ux.chart.EVENTS.PLOT_CENTERING_UPDATE, plotCenteringHandler, 'plot-centering-handler');
            }
            ChartWidgetVM.title = ChartWidgetVM.question.details.statement.displayNameFull || ChartWidgetVM.question.details.statement.text;
            ChartWidgetVM.title = ChartWidgetVM.title.replace(/^\d+\.\s*/, '');
            ChartWidgetVM.ux.errorbarToggleAvailableInUI = !params.comparisonData && showErrorbarToggleInUI(ChartWidgetVM.ux.chart, ChartWidgetVM.question);
            var errorbars = ChartWidgetVM.ux.chart.hasPlugin(ERRORBARS);
            if (errorbars && ChartWidgetVM.ux.errorbarsShowing && ChartWidgetVM.visualMetadata.errorbars) {
                errorbars.visible(true);
            }

            // If chart is a clickmap, but no zones were defined, hide the chart
            if (ChartWidgetVM.question.type === 'clickmap' && !ChartWidgetVM.questionDetails.zones.length) {
                ChartWidgetVM.ux.hideChart = true;
                ChartWidgetVM.ux.loading = false;
            }
            else {
                ChartWidgetVM.renderChart('render', 'loading');
            }
        }, function(error) {
            ChartWidgetVM.ux.loading = false;
            pubSubService.notify('update-error-' + ChartWidgetVM.chartNum, [error]);
        });
    }

    /**
     * @param data
     */
    function plotCenteringHandler(data) {
        ChartWidgetVM.ux.chart._isOffCenter = !data.centered;
        $scope.$digest();
    }

    /**
     * @param data
     */
    function plotInteractionsToggledHandler(data) {
        ChartWidgetVM.ux.chart._interactionsEnabled = data.interactionsEnabled;
        $scope.$digest();
    }

    /**
     * @name hasMoreThanNBeaconPools
     * @param {number} count - Count of 'N' beacon pools
     *
     * @description
     * Returns true if there are some beacon pools hidden from view.
     */
    function hasMoreThanNBeaconPools(count) {
        var beaconPools = ChartWidgetVM.params.beaconPools || [],
            hasMoreThanNBP = beaconPools.length > count,
            comparisonType = ChartWidgetVM.params.comparisonData ? _.keys(ChartWidgetVM.params.comparisonData[0])[0] : '',
            hasMoreThanNComparisonBP = comparisonType === filterLogicConstants.criteriaTypes.BEACON_POOL && ChartWidgetVM.params.comparisonData[0].Beacon$Pool.concat(ChartWidgetVM.params.comparisonData[1].Beacon$Pool).length > count;
        return hasMoreThanNBP || hasMoreThanNComparisonBP;
    }

    function refreshChart() {
        ChartWidgetVM.ux.chart.table().redraw();
    }

    function resetChartDisplay() {
        ChartWidgetVM.ux.chart.resetChartDisplay();
        // Clear ui chart reset status
        ChartWidgetVM.ux.chart._isOffCenter = false;
    }
}

chartWidget.$inject = [
    '$notify',
    '$state',
    'pubSubService',
    'myChartService',
];

/**
 * Chart Widget
 *
 * @param {object} $notify - Notifcation service
 * @param {object} $state - State object
 * @param {object} pubSubService - Pub-sub service
 * @param {object} myChartService - Service for building charts
 */
function chartWidget($notify, $state, pubSubService, myChartService) {
    var CUSTOM_REPORT = 'customReports';
    return {
        restrict: 'E',
        scope: {
            question: '=',
            chartDisplayNum: '@',
            chartNum: '@',
            gridIndex: '@?',
            visualMetadata: '=',
            params: '=',
        },
        controller: 'ChartWidgetCtrl',
        controllerAs: 'ChartWidgetVM',
        templateUrl: () => {
            return $state.current.name === CUSTOM_REPORT ? '/dashboard-templates/custom-reports/chart-widget.html' : '/dashboard-templates/results/chart-widget.html';
        },
        link: function(scope, elem, attr, ChartWidgetVM) {
            var $titleEl,
                EXPORT_ERROR_MSG_BROWSER = 'At this time chart exports are not supported on this browser. Please try another browser or contact Upwave for further assistance.',
                dblClickBound;
            ChartWidgetVM.ux.chartDimensions = {
                height: 0,
                width: 0,
            };
            ChartWidgetVM.setSVGClass = setSVGClass;
            ChartWidgetVM.renderChart = renderChart;
            ChartWidgetVM.exportAsPng = exportAs('png');
            ChartWidgetVM.exportAsSvg = exportAs('svg');

            init();

            function init() {
                // DOM-y methods
                scope.$evalAsync(() => {
                    getScopeSvgWidth();
                    setScopeSvgHeight();
                });
                pubSubService.subscribe('sv-window-resized', scope.$id, () => {
                    scope.$evalAsync(() => {
                        setScopeSvgHeight();
                        if (ChartWidgetVM.ux.chart && ChartWidgetVM.ux.chart.table) {
                            renderChart('redraw', 'resizing');
                        }
                    });
                });
                scope.$on('$destroy', () => {
                    elem.find('.plottable').off('dblclick');
                    pubSubService.destroy('sv-window-resized', scope.$id);
                });
            }

            /**
             * Render Chart
             *
             * Once redraw/render promise is resolved, notify that the chart has been rendered.
             * To handle issue with bars not appearing in the proper places, redraw the
             * element again once it is in viewport.
             *
             * @param action
             * @param flag
             */
            function renderChart(action, flag) {
                elem[0].id = scope.chartNum + (_.isUndefined(scope.gridIndex) ? '' : '-' + scope.gridIndex);
                myChartService.chartInView.subscribe(elem[0], () => {
                    ChartWidgetVM.ux.chart[action]().then(() => {
                        myChartService.chartInView.loadNext(elem[0].id);
                        ChartWidgetVM.ux.errorbarsShowing = ChartWidgetVM.showErrorbars(ChartWidgetVM.ux.chart);
                        setSVGClass(ChartWidgetVM.ux.chart);
                        scope.$evalAsync(() => {
                            delete ChartWidgetVM.ux[flag];
                        });
                    });
                }, void 0, [], scope.question.index < 3);
            }

            /**
             * @param chart
             */
            function setSVGClass(chart) {
                var ngClass = '';
                if (!chart) {
                    return ngClass;
                }
                if (ChartWidgetVM.ux.showingWordCloud && ChartWidgetVM.isFreeResponse) {
                    ngClass += 'chart__hide ';
                }
                if (chart._hasZoomAvailable) {
                    ngClass += 'plot--has-interactions ';
                }
                if (chart._interactionsEnabled) {
                    ngClass += 'plot--has-interactions-enabled ';
                }
                if (ChartWidgetVM.ux.errorbarsShowing) {
                    ngClass += 'error-bars--are-showing';
                }

                elem.find('.plottable')[0].setAttribute('class', 'chart plottable ' + ngClass.trim());
                if (!dblClickBound) {
                    elem.find('.plottable').on('dblclick', ChartWidgetVM.refreshChart);
                    dblClickBound = true;
                }
            }

            /**
             * @param type
             */
            function exportAs(type) {
                return () => {
                    let scale = Math.max(exportConstants.chartDimensions.width / elem[0].clientWidth, exportConstants.chartDimensions.height / elem[0].clientHeight);
                    exportFactory.exportChart(type, {
                        elem: elem[0],
                        row: ChartWidgetVM.gridIndex,
                        scale: scale,
                    }).then(function(response) {
                        let questionName = scope.question.details.statement.text;
                        if (type === 'png') {
                            exportFactory.appendLogo(response, scale, true).then(() => {
                                exportFactory.download(type, response, questionName);
                            });
                        }
                        else {
                            exportFactory.download(type, response, questionName);
                        }
                    }, function(errorMsg) {
                        console.error('export error: ' + errorMsg);
                        $notify.error(EXPORT_ERROR_MSG_BROWSER);
                    });
                };
            }

            /**
             *
             */
            function getAvailableHeight() {
                if (!$titleEl) {
                    $titleEl = $(elem).find('.chart__title');
                    return $titleEl.outerHeight();
                }
                return $titleEl.outerHeight();
            }

            /**
             * @param question
             */
            function getSVGMaxHeightByQuestionAnswerOptions(question) {
                if (!/[fF]reeResponse$/.test(question.type)) {
                    var isTrackerOrGrid = question.details.type === 'grid' || ChartWidgetVM.ux.isTracker;
                    if (isTrackerOrGrid || (question.details.choices || question.details.zones || question.details).length < 14) {
                        return 450;
                    }
                    return 530;
                }
                return 450;
            }

            function setScopeSvgHeight() {
                var maxHeight = getSVGMaxHeightByQuestionAnswerOptions(ChartWidgetVM.question);
                ChartWidgetVM.ux.chartDimensions.height = maxHeight - getAvailableHeight();
            }

            function getScopeSvgWidth() {
                // Gets the y-axis label to share their left edge with the chart question statement - being beautiful isn't easy
                // TODO move this to css @samuelmburu
                if (ChartWidgetVM.question.type === 'grid') {
                    ChartWidgetVM.ux.chartDimensions.width = '92%';
                }
                else {
                    ChartWidgetVM.ux.chartDimensions.width = scope.chartNum < 10 ? '93.45%' : '91.6%';
                }
            }
        },
    };
}
