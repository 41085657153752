import _ from 'lodash';
import U from '../../../../../common/js/util';
import '../../common-components/deferred';
import filterLogicService from '../../filter-logic/filter-logic-service';
import './analysis-config-service';
import './analysis-config-manager';
import AnalysisConfigModel from './analysis-config-model';
import urbanicityCuts from './cuts/types/urbanicity-cuts';
import standardCuts from './cuts/types/standard-cuts';
import frequencyCuts from './cuts/types/frequency-cuts';
import metroCuts from './cuts/types/metro-cuts';
import roundedDaysSinceLastExposureCuts from './cuts/types/rounded-days-since-last-exposure';
import medianIncomeCuts from './cuts/types/median-income-cuts';
import analysisConfigConstants from './analysis-config-constants';
import analysisConfigDataService from './analysis-config-data-service';
import liftResultsStatesConstants from './lift-results-states-constants';
import Config from '../../config';
import regionCuts from './cuts/types/region-cuts';

angular
    .module('analysisPlan.service', [
        'common.deferred', 'analysisConfig.manager',
    ])
    .service('analysisPlanService', analysisPlanService);

analysisPlanService.$inject = [
    '$rootScope', 'analysisConfigManager',
];

/**
 * Analysis plan service.
 *
 * @param {object} $rootScope - The root scope of the application
 * @param {object} analysisConfigManager - The analysis configuration manager
 * @returns {object} The exposed functions for the service
 */
function analysisPlanService($rootScope, analysisConfigManager) {
    const CATEGORY_NAME_MAX_LENGTH = 255;
    const analysisConfig = analysisConfigManager.get() || {
            humanCutter: {},
        },
        allCuts = analysisConfig.humanCutter.cuts,
        filterModel = new AnalysisConfigModel($rootScope.survey),
        categorySelectionTypes = {
            NONE: 0,
            SOME: 1,
            ALL: 2,
        },
        liftDownloadTypes = {
            CLIENT: 'clientDownloadUrl',
            ADMIN: 'adminDownloadUrl',
        },
        cutNameToCutTypeMap = {};

    /**
     * Util function comparing two arrays.
     *
     * @param {Array} array1 - First array to compare
     * @param {Array} array2 - Second array to compare
     * @returns {boolean} Whether the arrays contain same values
     */
    function arraysContainSameValues(array1, array2) {
        if (!array1 || !array2 || array1.length !== array2.length) {
            return false;
        }
        return !_.difference(array1, array2).length;
    }

    /**
     * Util function converting an array of decimal values to percent.
     *
     * @param {Array} arr - Array of decimal values
     * @returns {Array} Array of percentage values
     */
    function arrayFractionToPercent(arr) {
        let newArr = angular.copy(arr);
        for (let i = 0; i < newArr.length; i++) {
            newArr[i] *= 100;
        }
        return newArr;
    }

    /**
     * Util function converting an array of percent values to decimal.
     *
     * @param {Array} arr - Array of percentage values
     * @returns {Array} Array of decimal values
     */
    function arrayPercentToFraction(arr) {
        let newArr = angular.copy(arr);
        for (let i = 0; i < newArr.length; i++) {
            newArr[i] /= 100;
        }
        return newArr;
    }

    /**
     * Util function returning a cleaned array with no null/undefined values
     *
     * @param {Array} original - Array which may contain null values
     * @returns {Array} Array with any null values stripped
     */
    function getCleanedArray(original) {
        const toSave = [];
        _.forEach(original, item => {
            if (item) {
                toSave.push(item);
            }
        });
        return toSave;
    }

    /**
     * Util function ensuring an array has 'n' number of items.
     *
     * @param {Array} arr - Array to pad
     * @param {number} arrSize - Size that array should be
     */
    function padArray(arr, arrSize) {
        for (let i = arr.length; i < arrSize; i++) {
            arr.push(undefined);
        }
    }

    /**
     * Given an analysis config settings object, pad the significance arrays.
     *
     * @param {object} settings - Analysis configuration settings object
     * @param {number} numSlots - Number of slots to pad
     */
    function padSignificanceLevels(settings, numSlots) {
        padArray(settings.output_params.lift_significance_levels, numSlots);
        padArray(settings.output_params.audience_profile_significance_levels, numSlots);
    }

    /**
     * Determine whether lift signifiance is equal to audience profile significance.
     *
     * @param {object} settings - Analysis configuration settings object
     * @returns {boolean} - Do significance arrays contain same values?
     */
    function areTestLevelsEqualToSignificanceLevels(settings) {
        return arraysContainSameValues(settings.output_params.lift_significance_levels, settings.output_params.audience_profile_significance_levels);
    }

    /**
     * Given a cut category, get the cut list for it.
     *
     * @param {object} category - The category
     * @returns {Array} - The list of cuts for the category
     */
    function getCutListForCategory(category) {
        const selectedCuts = [];
        let numSelected = 0;

        _.forEach(category.options, cut => {
            if (cut._selected) {
                selectedCuts.push(cut._displayName || cut.name);
                numSelected++;
            }
        });
        category._selected = numSelected === category.options.length ? 2 : numSelected === 0 ? 0 : 1;
        return selectedCuts.join(', ');
    }

    /**
     * Given an array of categories, get the selection type for all of them.
     *
     * @param {Array} categories - Array of categories
     * @returns {number} - Numeric representation of selection type (ALL, NONE, SOME)
     */
    function getSelectionTypeForMultipleCategories(categories) {
        let atLeastOneSelected = false,
            atLeastOneUnselected = false;

        _.forEach(categories, category => {
            if (category._selected === categorySelectionTypes.NONE) {
                atLeastOneUnselected = true;
            }
            else {
                atLeastOneSelected = true;
            }
        });
        if (atLeastOneSelected) {
            return atLeastOneUnselected ? categorySelectionTypes.SOME : categorySelectionTypes.ALL;
        }
        return categorySelectionTypes.NONE;
    }

    /**
     * Given a cut that was just updated, get the selection status for the category.
     *
     * @param {object} cut - Analysis cut
     * @param {object} category - The parent category associated of the cut
     * @returns {number} - Numeric representation of selection type (ALL, NONE, SOME)
     */
    function getCategorySelectionType(cut, category) {
        if (cut._selected) {
            if (_.every(category.options, option => {
                return option._selected;
            })) {
                return categorySelectionTypes.ALL;
            }
            return categorySelectionTypes.SOME;
        }
        if (!cut._selected) {
            if (_.every(category.options, option => {
                return !option._selected;
            })) {
                return categorySelectionTypes.NONE;
            }
            return categorySelectionTypes.SOME;
        }
    }

    /**
     * Set all cuts in given array to selected or de-selected
     *
     * @param {object} cuts - An array of cuts
     * @param {boolean} setSelected - Selection status for cuts to be set to
     * @returns {number} - Numeric representation of selection type (ALL, NONE, SOME)
     */
    function setAllCuts(cuts, setSelected) {
        _.forEach(cuts, category => {
            setAllCutsForCategory(category, setSelected);
        });
        return setSelected ? categorySelectionTypes.ALL : categorySelectionTypes.NONE;
    }

    /**
     * Set all cuts in a category to selected or de-selected.
     *
     * @param {object} category - A cut category (i.e. Gender)
     * @param {boolean} setSelected - Selection status for cuts to be set to
     */
    function setAllCutsForCategory(category, setSelected) {
        category._selected = setSelected ? categorySelectionTypes.ALL : categorySelectionTypes.NONE;
        _.forEach(category.options, option => {
            option._selected = setSelected;
        });
    }

    /**
     * Initializes a cuts category.
     *
     * @param {object} category - A cut category (i.e. Gender)
     * @param {object} config - Analysis configuration object
     */
    function initalizeCutsCategory(category, config) {
        let atLeastOneSelectedInCategory = false,
            atLeastOneUnselectedInCategory = false;

        category.options.forEach(cut => {
            const selectedCut = allCuts[cut.value] || allCuts[cut._legacyValue];
            cut.type = cut.type || category.type;
            cut._displayName = cut._displayName || cut.name;

            if (selectedCut) {
                cut.uuid = selectedCut.uuid;
                cut.value = allCuts[cut._legacyValue] ? cut._legacyValue : cut.value;
                if (config && config.paramsToCopyFromExistingCut) {
                    _.forEach(config.paramsToCopyFromExistingCut, param => {
                        cut[param] = selectedCut[param];
                    });
                }
                cut._selected = true;
                // We have historically mapped the full list of "potential cut categories" to the ones
                // that actually exist in the DB based on the category name. This is fragile and breaks
                // if the user overrides the category name. However, we can also map the category by it's child cuts
                // which we're doing here, by setting the category's analysisCutCategory info (uuid and name) based on the JSON
                // of it's child cut
                category.analysisCutCategory = selectedCut.analysisCutCategory;
                category.uuid = selectedCut.analysisCutCategory.uuid;
                atLeastOneSelectedInCategory = true;
            }
            else {
                atLeastOneUnselectedInCategory = true;
            }
        });

        // Determine selected type for category (ALL, SOME, NONE)
        if (atLeastOneSelectedInCategory) {
            category._selected = atLeastOneUnselectedInCategory ? categorySelectionTypes.SOME : categorySelectionTypes.ALL;
        }
        else {
            category._selected = categorySelectionTypes.NONE;
        }
        category._expanded = !(config && config.categoriesCollapsedOnLoad);
    }

    /**
     * Returns all the standard cuts that should be visible for US region
     *
     * @returns {Promise} - a list of standard cuts to display on the UI or an error
     */
    function getUsCuts() {
        return new Promise(resolve => {
            let cuts = standardCuts.concat(regionCuts, medianIncomeCuts, urbanicityCuts);
            buildMetroCuts()
                .then(metroCutsOptions => {
                    if (metroCutsOptions) {
                        metroCuts.options = metroCutsOptions;

                        cuts = cuts.concat(metroCuts);
                        cuts.forEach(category => {
                            initalizeCutsCategory(category, {
                                categoriesCollapsedOnLoad: true,
                            });
                        });
                    }
                    resolve(cuts);
                })
                .catch(e => {
                    console.error(e);
                    resolve(cuts);
                });
        });
    }

    /**
     * Returns all the standard cuts that should be visible for non-US regions
     *
     * @returns {Promise} - a list of standard cuts to display on the UI
     */
    function getNonUsCuts() {
        return new Promise(resolve => {
            let cuts = standardCuts;
            cuts.forEach(category => {
                initalizeCutsCategory(category, {
                    categoriesCollapsedOnLoad: true,
                });
            });
            resolve(cuts);
        });
    }

    /**
     * Gets a list of standard cuts to display on the UI and sets a selected flag (to display as selected on the UI)
     * on each one that exists in the database for the respective survey
     *
     * @returns {Promise} - a list of standard cuts to display on the UI or an error
     */
    function getStandardCuts() {
        return Config.isUsRegion ? getUsCuts() : getNonUsCuts();
    }

    /**
     * Builds the metroCuts based on a list of metro areas by mapping over that list and attaching meta info to each cut
     *
     * @returns {Promise} - a list of metro cuts or an error
     */
    function buildMetroCuts() {
        return new Promise((resolve, reject) => {
            analysisConfigDataService.getMetroAreas()
                .then(metroAreas => {
                    if (metroAreas) {
                        // Sort metro areas alphabetically before displaying on the UI
                        metroAreas.sort(function(a, b) {
                            var textA = a.displayName;
                            var textB = b.displayName;
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                        });
                        resolve(metroAreas.map(metroArea => {
                            return {
                                exposed: {
                                    $eq: ['Demographic$Metro_Area', metroArea.displayName],
                                },
                                name: metroArea.displayName,
                                type: {
                                    value: 'equivalent',
                                    name: 'Equivalent',
                                },
                                value: `Metro Area: ${metroArea.displayName}`,
                                _displayName: metroArea.displayName,
                                abbreviation: metroArea.abbreviation ? metroArea.abbreviation : null,
                            };
                        }));
                    }
                    else {
                        reject(new Error('Encountered an error when attempting to build metro area cuts'));
                    }
                })
                .catch(e => console.error(e));
        });
    }

    /**
     * Get the list of time based cuts.
     *
     * @returns {Array} - Array of time-based cuts
     */
    function getTimeBasedCuts() {
        const timeBasedCuts = [];
        _.forEach([frequencyCuts, roundedDaysSinceLastExposureCuts], timeCut => {
            initalizeCutsCategory(timeCut);
            timeBasedCuts.push(timeCut);
        });
        return timeBasedCuts;
    }

    /**
     * Get the list of question-answer cuts.
     *
     * @returns {Array} - Array of question-answer cuts
     */
    function getQuestionAnswerCuts() {
        const questionAnswerCuts = [];
        const sortedQuestionCuts = _.sortBy(analysisConfig.questionFactors, '_index');

        sortedQuestionCuts.forEach(question => {
            const questionCategory = {
                name: question.name,
                category: question.name,
                categoryType: 'question',
                analysisCutCategoryType: 'question',
                _label: question._label,
                _displayName: question._displayName.replace(/^\d+\.(\d+\.)? /, ''),
                _displayNameFull: question._displayNameFull.replace(/^\d+\. /, ''),
                options: question._disabled ? [] : _.map(question._question.levels, level => {
                    cutNameToCutTypeMap[level.name] = 'Question Choice';
                    return {
                        name: level.name,
                        value: level.name,
                        _displayName: U.limitStrLength(level.name.replace(/^\d+\.(\d+\.)? /, ''), 80),
                        type: {
                            value: 'equivalent',
                        },
                        exposed: {
                            $or: [{
                                $eq: [level.id, 1],
                            }],
                        },
                    };
                }),
                _disabled: question._disabled,
            };
            initalizeCutsCategory(questionCategory, {
                paramsToCopyFromExistingCut: ['control'],
            });

            // If this category maps to one in the DB
            if (questionCategory.analysisCutCategory) {
                const cutCategoryName = questionCategory.analysisCutCategory.name;

                // If the cut category name has been manually changed, then populate display name
                if (cutCategoryName !== questionCategory.name) {
                    questionCategory._displayNameFull = cutCategoryName;
                    questionCategory._displayName = U.limitStrLength(cutCategoryName, 80);
                }
            }
            questionAnswerCuts.push(questionCategory);
        });

        return questionAnswerCuts;
    }

    /**
     * Get the list of impression parameter cuts.
     *
     * @returns {Array} - Array of impression parameter cuts
     */
    function getImpressionParameterCuts() {
        const impressionParameterCuts = _.cloneDeep(filterModel.ImpressionParameterGroups);

        impressionParameterCuts.options.forEach(impressionParameterGroup => {
            impressionParameterGroup.category = impressionParameterGroup.id;
            impressionParameterGroup.categoryType = 'impressionGroup';

            impressionParameterGroup.options = _.map(impressionParameterGroup.options, option => {
                const categoryAndGroup = option.name.split(': '),
                    name = `ImpressionParameterGroups$${option.name}`;

                return {
                    name,
                    value: name,
                    type: {
                        value: 'all',
                    },
                    exposed: {
                        $and: [{
                            $any: [option.id],
                        }, {
                            $eq: ['is_control', 0],
                        }],
                    },
                    control: {
                        $eq: ['is_control', 1],
                    },

                    // Params used for FE logic, but not saved to DB
                    _displayName: option.name,
                    _legacyValue: `ImpressionParameterGroups$${categoryAndGroup[1]}`,
                };
            });
            initalizeCutsCategory(impressionParameterGroup);
        });

        return impressionParameterCuts;
    }

    /**
     * Save or delete cut, depending on selection status.
     *
     * @param {object} cut - The cut to save or delete
     * @returns {Promise} - To be resolved when cut is saved or deleted
     */
    function saveOrDeleteCut(cut) {
        if (cut._selected && !cut.uuid) {
            // TODO: (JMH 2019/7/22) After testing of backfilled surveys, use ONLY updateAnalysisCutCategory
            if (cut.analysisCutCategory) {
                return analysisConfigDataService.updateAnalysisCutByCategory(cut.analysisCutCategory.uuid, cut.value, cut).then(updated => {
                    cut.uuid = updated.uuid;
                    analysisConfigManager.get(analysisConfigConstants.analysisConfigFields.HUMAN_CUTTER).cuts[cut.value] = cut;
                });
            }
            return analysisConfigDataService.updateAnalysisCut(analysisConfigManager.get('version'), cut.value, cut).then(updated => {
                cut.uuid = updated.uuid;
                analysisConfigManager.get(analysisConfigConstants.analysisConfigFields.HUMAN_CUTTER).cuts[cut.value] = cut;
            });
        }
        if (!cut._selected && cut.uuid) {
            return analysisConfigDataService.deleteAnalysisCutByUuid(cut.uuid).then(() => {
                delete cut.uuid;
            });
        }
    }

    /**
     * Initialize the cut category. If the category doesn't already exist, and if the category had items selected,
     * create a new cut category.
     *
     * @param {object} category - Category to initialize
     * @returns {Promise} - To be resolved when category is initialized
     */
    function initializeAnalysisCutCategory(category) {
        return new Promise((resolve, reject) => {
            if (!category.uuid && category._selected) {
                const categoryToCreate = {
                    name: U.limitStrLength(category.category, CATEGORY_NAME_MAX_LENGTH),
                    analysisCutCategoryType: category.categoryType || 'custom',
                };

                analysisConfigDataService.createAnalysisCutCategory(analysisConfigManager.get('version'), categoryToCreate).then(newCutCategory => {
                    category.name = newCutCategory.name;
                    category.analysisCutCategoryType = newCutCategory.analysisCutCategoryType;
                    category.uuid = newCutCategory.uuid;
                    resolve(category);
                }, reject);
            }
            else {
                resolve(category);
            }
        });
    }

    /**
     * Given a category, save all the cuts for it, based on selection status.
     *
     * @param {object} category - The category to save cuts for
     * @returns {Promise} - To be resolved when all cuts are saved
     */
    function saveCutsForCategory(category) {
        return new Promise((resolve, reject) => {
            const promises = [];
            _.forEach(category.options, cut => {
                cut.analysisCutCategory = cut.analysisCutCategory || {
                    name: category.name,
                    uuid: category.uuid,
                    analysisCutCategoryType: category.analysisCutCategoryType,
                };
                cut._cutCategoryUuid = category.uuid;
                promises.push(saveOrDeleteCut(cut));
            });
            category.cutList = getCutListForCategory(category);
            Promise.all(promises).then(resolve, reject);
        });
    }

    /**
     * Update a cut category.
     *
     * @param {object} category - Cut category to be updated.
     * @returns {Promise} Promise to be resolved when API call finishes
     */
    function updateCategory(category) {
        const analysisConfigUuid = analysisConfigManager.get('version');
        // Send only the essential params to API call
        const updatedCategory = {
            uuid: category.uuid,
            name: category.name,
        };

        return analysisConfigDataService.updateAnalysisCutCategory(analysisConfigUuid, updatedCategory);
    }

    /**
     * Make sure the category exists, then save and/or delete cuts within a category.
     * i.e. male, female within gender or question choices for a question
     *
     * @param {object} category - The category we want to save/initialize
     * @returns {Promise} A promise to be resolved when the category and cuts are saved
     */
    function saveCategoryAndCuts(category) {
        return new Promise((resolve, reject) => {
            initializeAnalysisCutCategory(category).then(response => {
                saveCutsForCategory(response).then(resolve, reject);
            }, reject);
        });
    }

    /**
     * Save multiple cuts catergories within a single category type
     * i.e. Age, Gender...etc within 'demographic' or questions within 'question'
     *
     * @param {Array} cutsCategories - Array of cut categories
     * @returns {Promise} A promise to be resolved when all categories are saved
     */
    function saveMultipleCutsCategories(cutsCategories) {
        return new Promise((resolve, reject) => {
            const promises = [];
            _.forEach(cutsCategories, cutCategory => {
                if (!cutCategory._disabled) {
                    promises.push(saveCategoryAndCuts(cutCategory));
                }
            });
            Promise.all(promises).then(resolve, reject);
        });
    }

    /**
     * Create or update a custom cut, under a cut category
     *
     * @param {object} cut - The cut to be saved
     * @param {string} newCategoryName - (Optional) If a new category is to be created, a new category name should be provided
     *
     * @returns {Promise} A promise to be resolved when the call to update the cut returns (or rejected, if call fails)
     */
    function saveCustomCut(cut, newCategoryName) {
        return new Promise((resolve, reject) => {
            if (newCategoryName) {
                const newCategory = {
                    category: newCategoryName,
                    categoryType: 'custom',
                    _selected: true,
                };
                initializeAnalysisCutCategory(newCategory).then(response => {
                    const parentCategoryUuid = (cut._parentAnalysisCutCategory || response).uuid;
                    cut.analysisCutCategory = response;
                    analysisConfigDataService.updateAnalysisCutByCategory(parentCategoryUuid, cut.name, cut).then(resolve, () => {
                        reject('There was an error saving the custom cut');
                    });
                }, () => {
                    reject('There was an error initializing the cut category');
                });
            }
            else if (cut.analysisCutCategory && cut.analysisCutCategory.uuid) {
                const parentCategoryUuid = (cut._parentAnalysisCutCategory || cut.analysisCutCategory).uuid;
                analysisConfigDataService.updateAnalysisCutByCategory(parentCategoryUuid, cut.name, cut).then(resolve, () => {
                    reject('There was an error saving the custom cut');
                });
            }
            else {
                reject('Invalid category was provided');
            }
        });
    }

    /**
     * Handles some of the UI initialization for cuts. Determine what the selected type is for the checkbox
     * i.e. "All", "None", or "Some". Also, map the category uuid to the cut, if it exists
     *
     * @param {Array} cuts - List of cuts
     * @param {object} categoryMap - Cut category map
     * @returns {number} Int representation of selection status ('all', 'none', 'some')
     */
    function initializeViewForCuts(cuts, categoryMap = {}) {
        let someSelected = false,
            allSelected = true;

        _.forEach(cuts, category => {
            // If the category exists in the DB, get the category uuid
            if (categoryMap[category.category]) {
                category.uuid = categoryMap[category.category].uuid;
            }
            category.cutList = getCutListForCategory(category);
            allSelected = allSelected && category._selected;
            someSelected = someSelected || category._selected;
        });

        return allSelected ? 2 : someSelected ? 1 : 0;
    }

    /**
     * Given a cut, set the "plain english" text labels for exposed and control.
     *
     * @param {object} cut - The cut that we want to set labels for
     */
    function setPlainEnglishLabelsForCut(cut) {
        cut._exposedPlainEnglish = filterLogicService.filterJsonToPlainEnglish(cut.exposed);
        cut._controlPlainEnglish = cut.type.value === 'custom' ? filterLogicService.filterJsonToPlainEnglish(cut.control) : cut.type.name;
        cut._categoryName = cut.analysisCutCategory.name;
    }

    /**
     * Extract the list of custom cuts from the list of all cuts.
     *
     * @returns {Array} Array of custom cuts
     */
    function getCustomCuts() {
        const customCuts = [];
        _.forEach(allCuts, cut => {
            if (cut.analysisCutCategory && cut.analysisCutCategory.analysisCutCategoryType === analysisConfigConstants.cutCategoryTypes.CUSTOM) {
                setPlainEnglishLabelsForCut(cut);
                customCuts.push(cut);
            }
        });
        return customCuts;
    }

    /**
     * Get a map of cut categories, keyed by the category name.
     *
     * @returns {Promise} A promise to be resolved when the cut category map has been fetched
     */
    function getCutCategoryMap() {
        return new Promise(resolve => {
            analysisConfigDataService.listAnalysisCutCategories(analysisConfigManager.get('version')).then(cutCategories => {
                const cutCategoryMap = _.reduce(cutCategories, (obj, param) => {
                    obj[param.name] = param;
                    return obj;
                }, {});
                resolve(cutCategoryMap);
            }, () => {
                resolve({});
            });
        });
    }

    /**
     * Get a map of cut category types.
     *
     * @returns {Promise} A promise to be resolved when the cut category type map has been fetched
     */
    function getCutCategoryTypeMap() {
        return new Promise(resolve => {
            analysisConfigDataService.listAnalysisCutCategories(analysisConfigManager.get('version')).then(cutCategories => {
                const cutCategoryMap = _.groupBy(cutCategories, 'analysisCutCategoryType');
                resolve(cutCategoryMap);
            }, () => {
                resolve({});
            });
        });
    }

    /**
     * Get all categories and cuts.
     *
     * @returns {Promise} A promise to be resolved when all categories and cuts have been fetched
     */
    function getAllCategoriesAndCuts() {
        return analysisConfigDataService.listAnalysisCutCategoriesAndCuts(analysisConfigManager.get('version'));
    }

    /**
     * Save all the mass cuts.
     *
     * @param {object} massCuts - Object of mass cuts from mass cut creator
     * @returns {Promise} A promise to be resolved when the mass cuts have been saved
     */
    function saveMassCuts(massCuts) {
        return new Promise((resolve, reject) => {
            const categoryToCreate = {
                name: massCuts.categoryName,
                analysisCutCategoryType: 'custom',
            };

            analysisConfigDataService.createAnalysisCutCategory(analysisConfigManager.get('version'), categoryToCreate).then(category => {
                const promises = [];

                massCuts.cutsToCreate.forEach(cut => {
                    if (cut._selected) {
                        promises.push(analysisConfigDataService.updateAnalysisCutByCategory(category.uuid, cut.name, cut));
                    }
                });

                Promise.all(promises).then(resolve, reject);
            }, reject);
        });
    }

    /**
     * Get the total number of cuts.
     *
     * @returns {number} - The size of the cuts array
     */
    function getNumTotalCuts() {
        return _.size(allCuts);
    }

    /**
     * Get analysis plan default settings.
     *
     * @returns {object} The default settings object
     */
    function getAnalysisPlanSettings() {
        return analysisConfigConstants.defaultAnalysisPlanSettings;
    }

    /**
     * Get the lift state.
     *
     * @param {object} results - The results status object
     * @returns {string} The state of the lift resullts (i.e READY, NOT_READY, HAS_RESULTS_AND_CHANGES...etc)
     */
    function getLiftState(results) {
        if (results.analysisPlanReady.analysisCuts && results.analysisPlanReady.factorLevels) {
            if (results.lastSuccessfulAnalysisRun && results.lastSuccessfulAnalysisRun.date) {
                if (results.previousRunFailed) {
                    return liftResultsStatesConstants.HAS_RESULTS_AND_ERROR;
                }
                return results.runScheduled ? liftResultsStatesConstants.HAS_RESULTS_AND_CHANGES : liftResultsStatesConstants.HAS_RESULTS_AND_NO_CHANGES;
            }
            return results.previousRunFailed ? liftResultsStatesConstants.FIRST_FAILED : liftResultsStatesConstants.READY;
        }
        return liftResultsStatesConstants.NOT_READY;
    }

    return {
        // Get initial cuts by type
        getStandardCuts: getStandardCuts,
        getQuestionAnswerCuts: getQuestionAnswerCuts,
        getTimeBasedCuts: getTimeBasedCuts,
        getImpressionParameterCuts: getImpressionParameterCuts,
        getCustomCuts: getCustomCuts,

        // Save or delete a cut based on _selected and uuid
        saveOrDeleteCut: saveOrDeleteCut,

        // Cut and category utils
        getCategorySelectionType: getCategorySelectionType,
        getSelectionTypeForMultipleCategories: getSelectionTypeForMultipleCategories,
        setAllCuts: setAllCuts,
        setAllCutsForCategory: setAllCutsForCategory,
        categorySelectionTypes: categorySelectionTypes,
        getCutListForCategory: getCutListForCategory,
        getNumTotalCuts: getNumTotalCuts,
        getAnalysisPlanSettings: getAnalysisPlanSettings,
        getLiftState: getLiftState,
        liftDownloadTypes: liftDownloadTypes,

        // Value and array utils
        arraysContainSameValues: arraysContainSameValues,
        arrayFractionToPercent: arrayFractionToPercent,
        arrayPercentToFraction: arrayPercentToFraction,
        padArray: padArray,
        padSignificanceLevels: padSignificanceLevels,
        areTestLevelsEqualToSignificanceLevels: areTestLevelsEqualToSignificanceLevels,
        getCleanedArray: getCleanedArray,
        setPlainEnglishLabelsForCut: setPlainEnglishLabelsForCut,

        getCutCategoryMap,
        getCutCategoryTypeMap,
        updateCategory,
        saveCategoryAndCuts,
        saveCutsForCategory,
        saveMultipleCutsCategories,

        initializeViewForCuts,
        saveCustomCut,

        getAllCategoriesAndCuts,
        saveMassCuts,
    };
}
