angular.module('screeningQuestion.editorSummaryView', [])
    .directive('screeningQuestionSummary', screeningQuestionSummary)
    .directive('screeningQuestionEditor', screeningQuestionEditor)
    .controller('ScreeningQuestionEditorCtrl', ScreeningQuestionEditorCtrl);

function screeningQuestionSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/screening-question-summary.html',
        scope: {
            requirement: '=',
        },
    };
}
screeningQuestionEditor.$inject = ['$rootScope'];

function screeningQuestionEditor($rootScope) {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/screening-question-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, ScreeningQuestionEditorVM) {
            var required = 2,
                i,
                last;
            $(document).on('mouseover', '.sort-handle', function(e) {
                // Observe element width before dragging removes
                // it from the document flow.
                var choiceCell = $(e.target).parent().next('.choice-cell');
                choiceCell.data('choice-cell-width', choiceCell.css('width'));
            });

            /* Add functionality to navigate through response choices via key presses */
            $(document).on('keydown', '.faux-btn input', function(e) {
                e.stopPropagation(); // Stop propagation - don't want to navigate through questions

                if (e.keyCode === 13 || e.keyCode === 40) {
                    $(this).parent().parent().parent().next().find('input').focus();
                }
                else if (e.keyCode === 38) {
                    $(this).parent().parent().parent().prev().find('input').focus();
                }
            });

            syncOuterToLocal();

            scope.$watch('requirement.details.question.details.choices', syncOuterToLocal, true);
            scope.$watch('ScreeningQuestionEditorVM.choices', syncOuterToLocal, true);
            scope.$watch('ScreeningQuestionEditorVM.choices', update, true);
            scope.$watch('ScreeningQuestionEditorVM.choices', update);
            scope.$on('$destroy', function() {
                $(document).off('mouseover');
                $(document).off('keydown');
            });

            function initializeDetails() {
                scope.requirement.details = scope.requirement.details || {};
                scope.requirement.details.question = scope.requirement.details.question || {};
                scope.requirement.details.question.statement = scope.requirement.details.question.statement || '';
                scope.requirement.details.question.details = scope.requirement.details.question.details || {};
                scope.requirement.details.question.details.choices = scope.requirement.details.question.details.choices || [];
            }

            function syncOuterToLocal() {
                initializeDetails();
                if (!angular.equals(last, scope.requirement.details.question.details.choices)) {
                    ScreeningQuestionEditorVM.choices = [];
                    for (i = 0; i < scope.requirement.details.question.details.choices.length; i++) {
                        ScreeningQuestionEditorVM.choices.push(scope.requirement.details.question.details.choices[i]);
                    }
                }
                ScreeningQuestionEditorVM.choices = ScreeningQuestionEditorVM.choices || [];

                /* If user is admin, continue to increase the max choices */
                if ($rootScope.auth.user && $rootScope.auth.user.isAdmin) {
                    ScreeningQuestionEditorVM.maxChoices = Math.max(6, scope.requirement.details.question.details.choices.length + 1);
                }

                for (i = ScreeningQuestionEditorVM.choices.length; i < ScreeningQuestionEditorVM.maxChoices; i++) {
                    ScreeningQuestionEditorVM.choices.push({
                        html: '',
                        disqualifier: false,
                    });
                }
            }

            function update() {
                ScreeningQuestionEditorVM.missing = required;
                var x = [];
                _.forEach(ScreeningQuestionEditorVM.choices, function(e) {
                    if (e.html) {
                        ScreeningQuestionEditorVM.missing = ScreeningQuestionEditorVM.missing > 0 ? ScreeningQuestionEditorVM.missing - 1 : ScreeningQuestionEditorVM.missing;
                        x.push({
                            html: e.html,
                            disqualifier: !!e.disqualifier,
                        });
                    }
                });
                initializeDetails();
                scope.requirement.details.question.details.choices = x;
                last = angular.copy(x);
            }
        },
        controller: 'ScreeningQuestionEditorCtrl',
        controllerAs: 'ScreeningQuestionEditorVM',
    };
}
ScreeningQuestionEditorCtrl.$inject = ['$rootScope', '$scope'];

function ScreeningQuestionEditorCtrl($rootScope) {
    var ScreeningQuestionEditorVM = this,
        required = 2;
    ScreeningQuestionEditorVM.maxChoices = 6;
    ScreeningQuestionEditorVM.missing = required;
    // Response choice character count
    ScreeningQuestionEditorVM.maxChars = $rootScope.auth.user && $rootScope.auth.user.isAdmin ? 256 : 60;
    // Configure the "choices" sortable.
    // Use start, stop, and mouseover to maintain element
    // width during sorting.
    ScreeningQuestionEditorVM.sortableOptions = {
        axis: 'y',
        tolerance: 'pointer',
        containment: 'parent',
        handle: '.sort-handle',
        scroll: true,
        distance: 10,
        start: function(e, ui) {
            var choiceCell = ui.item.find('.choice-cell');
            choiceCell.css('width', choiceCell.data('choice-cell-width'));
            $('.tooltip').hide();
        },
        stop: function(e, ui) {
            ui.item.find('.choice-cell').css('width', 'auto');
        },
    };
    ScreeningQuestionEditorVM.showCharCount = showCharCount;
    ScreeningQuestionEditorVM.charCountColor = charCountColor;

    function showCharCount(chars) {
        return (2 * chars) >= ScreeningQuestionEditorVM.maxChars;
    }

    function charCountColor(chars) {
        var red = Math.min(255, Math.round(255 * chars / ScreeningQuestionEditorVM.maxChars, 0)).toString(16);
        red = red.length === 1 ? '0' + red : red;
        return '#' + red + '3333';
    }
}
