import _ from 'lodash';

angular.module('common.paginator', [])
    .directive('paginator', paginator)
    .directive('buttonPager', buttonPager)
    .controller('PaginatorCtrl', PaginatorCtrl)
    .factory('Paginator', Paginator);

function paginator() {
    return {
        restrict: 'E',
        templateUrl: '/shared-templates/paginator.html',
        scope: {
            paginator: '=',
            onChange: '<?',
        },
        controller: 'PaginatorCtrl',
        controllerAs: 'PaginatorVM',
    };
}

function buttonPager() {
    return {
        restrict: 'E',
        templateUrl: '/shared-templates/button-pager.html',
        scope: {
            paginator: '=',
            onChange: '<?',
        },
        controller: 'PaginatorCtrl',
        controllerAs: 'PaginatorVM',
    };
}

function PaginatorCtrl($scope) {
    const MAX_PAGE = 4;
    var PaginatorVM = this,
        onChange = $scope.onChange;
    PaginatorVM.refreshPageData = refreshPageData;
    PaginatorVM.getMaxSize = getMaxSize;

    function refreshPageData() {
        $scope.paginator.getPageData();
        if (onChange) {
            onChange();
        }
    }

    function getMaxSize() {
        return $scope.paginator.numPages > MAX_PAGE ? MAX_PAGE : null;
    }
}
Paginator.$inject = ['$filter'];

function Paginator($filter) {
    const DEFAULT_ITEMS_PER_PAGE = 10;

    function Paginator(params) {
        //Uib-pagination is 1-indexed
        this.currentPageIdx = 1;
        this.list = params.list;
        this.numPages = 0;
        this.itemsPerPage = params.itemsPerPage || DEFAULT_ITEMS_PER_PAGE;
    }

    Paginator.prototype.getPageData = getPageData;
    Paginator.prototype.sortPageData = sortPageData;
    Paginator.prototype.refreshList = refreshList;

    function refreshList(filter) {
        if (filter) {
            let self = this,
                filtered = [];
            _.forEach(this.list, function(item) {
                if (filter.apply(self, [item])) {
                    filtered.push(item);
                }
            });
            this.filtered = filtered;
        }
        else {
            this.filtered = this.list;
        }
        this.numItems = this.filtered.length;
        this.numPages = Math.ceil(this.numItems / this.itemsPerPage);
        if (this.numPages < 2) {
            this.data = this.filtered;
        }
    }

    function getPageData(pageIdx) {
        pageIdx = pageIdx || this.currentPageIdx - 1;
        this.data = this.filtered.slice(pageIdx * this.itemsPerPage, (pageIdx + 1) * this.itemsPerPage);
        return this.data;
    }

    function sortPageData(orderBy, reverse) {
        if (this.filtered.length) {
            this.filtered = $filter('orderBy')(this.filtered, orderBy, reverse);
        }
        else {
            this.list = $filter('orderBy')(this.list, orderBy, reverse);
        }
    }

    return Paginator;
}
