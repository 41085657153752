import './axis-label-images';
import './errorbars';
import './chart-tooltips';
import './chart-factory';
import './charts-service';
import './charts-angled-axis-labels';
angular.module('ChartsModule', [
    'chart.axisLabelImages',
    'chart.errorbars',
    'chart.tooltips',
    'chart',
    'charts.service',
    'charts.angledAxisLabels',
]);
