import _ from 'lodash';
import '../analysis-config-manager';
import '../analysis-plan.service';
import '../../../common-components/sv-dialog';
import './global-filter.view';
import filterLogicService from '../../../filter-logic/filter-logic-service';
import analysisConfigDataService from '../analysis-config-data-service';
import analysisPlanConstants from '../analysis-plan.constants';

angular
    .module('analysisPlanSettings.view', [
        'common.dialog',
        'analysisConfig.manager',
        'analysisPlan.service',
        'globalFilter.view',
    ])
    .directive('analysisPlanSettings', analysisPlanSettings)
    .controller('AnalysisPlanSettingsCtrl', AnalysisPlanSettingsCtrl);

/**
 * Analysis plan settings directive.
 *
 * @returns {object} directive definition
 */
function analysisPlanSettings() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/overview/analysis-plan-settings.html',
        controller: 'AnalysisPlanSettingsCtrl',
        controllerAs: 'AnalysisPlanSettingsVM',
    };
}

AnalysisPlanSettingsCtrl.$inject = [
    '$scope',
    '$rootScope',
    'dialog',
    'analysisConfigManager',
    'analysisPlanService',
];

/**
 * Analysis plan settings controller.
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} dialog
 * @param {object} analysisConfigManager
 * @param {object} analysisPlanService
 */
function AnalysisPlanSettingsCtrl($scope, $rootScope, dialog, analysisConfigManager, analysisPlanService) {
    var AnalysisPlanSettingsVM = this;
    const analysisConfig = analysisConfigManager.get();

    // VM exposed variables
    AnalysisPlanSettingsVM.ux = {
        timeBinOptions: analysisPlanConstants.timeBinOptions,
        errors: {
            significanceLevels: null,
            testLevels: null,
        },
        showWeeksInTimeBin: $rootScope.auth.user.isAdmin,
    };

    // VM exposed functions
    AnalysisPlanSettingsVM.onSettingsChanged = onSettingsChanged;
    AnalysisPlanSettingsVM.updateSignificanceLevels = updateSignificanceLevels;
    AnalysisPlanSettingsVM.updateTestLevels = updateTestLevels;
    AnalysisPlanSettingsVM.onTestLevelsEqualToSignificanceLevelsChanged = onTestLevelsEqualToSignificanceLevelsChanged;
    AnalysisPlanSettingsVM.openGlobalFilterEditor = openGlobalFilterEditor;
    AnalysisPlanSettingsVM.deleteGlobalFilter = deleteGlobalFilter;

    init();

    /**
     * Controller initialization
     */
    function init() {
        analysisConfigDataService.getAnalysisConfigSettings(analysisConfig.version).then(response => {
            $rootScope.$evalAsync(() => {
                AnalysisPlanSettingsVM.settings = response;
                setGlobalFilterText();
                analysisPlanService.padSignificanceLevels(AnalysisPlanSettingsVM.settings, analysisPlanConstants.numSignificanceLevels);
                AnalysisPlanSettingsVM.testLevelsEqualToSignificanceLevels = analysisPlanService.areTestLevelsEqualToSignificanceLevels(AnalysisPlanSettingsVM.settings);
            });
        });
    }

    /**
     * Update Levels
     *
     * @param {string} type - 'significanceLevels' or 'testLevels'
     */
    function updateLevels(type) {
        AnalysisPlanSettingsVM.settings.output_params[type] = analysisPlanService.getCleanedArray(AnalysisPlanSettingsVM.settings.output_params[type]);

        if (AnalysisPlanSettingsVM.settings.output_params[type].length) {
            AnalysisPlanSettingsVM.ux.errors[type] = '';

            // Keep test levels in sync, if applicable
            if (AnalysisPlanSettingsVM.testLevelsEqualToSignificanceLevels && type === analysisPlanConstants.SIGNIFICANCE_LEVELS) {
                AnalysisPlanSettingsVM.settings.output_params.audience_profile_significance_levels = _.cloneDeep(AnalysisPlanSettingsVM.settings.output_params.lift_significance_levels);
            }
            onSettingsChanged();
        }
        else {
            AnalysisPlanSettingsVM.ux.errors[type] = 'Please specify at least one significance level';
        }
        analysisPlanService.padSignificanceLevels(AnalysisPlanSettingsVM.settings, analysisPlanConstants.numSignificanceLevels);
    }

    /**
     * Update significance levels.
     */
    function updateSignificanceLevels() {
        updateLevels(analysisPlanConstants.SIGNIFICANCE_LEVELS);
    }

    /**
     * Update test levels.
     */
    function updateTestLevels() {
        // If we modified test levels and they are now no longer equal to significance levels, toggle setting off
        AnalysisPlanSettingsVM.testLevelsEqualToSignificanceLevels = analysisPlanService.areTestLevelsEqualToSignificanceLevels(AnalysisPlanSettingsVM.settings);
        updateLevels(analysisPlanConstants.TEST_LEVELS);
    }

    /**
     * Handle when test levels equal to significance levels.
     */
    function onTestLevelsEqualToSignificanceLevelsChanged() {
        if (AnalysisPlanSettingsVM.testLevelsEqualToSignificanceLevels && !analysisPlanService.areTestLevelsEqualToSignificanceLevels(AnalysisPlanSettingsVM.settings)) {
            AnalysisPlanSettingsVM.settings.output_params.audience_profile_significance_levels = _.cloneDeep(AnalysisPlanSettingsVM.settings.output_params.lift_significance_levels);
            updateLevels(analysisPlanConstants.TEST_LEVELS);
        }
    }

    /**
     * On settings changed.
     *
     * @returns {Promise} resolves when save completes
     */
    function onSettingsChanged() {
        return analysisConfigDataService.saveAnalysisConfigSettings(analysisConfig.version, AnalysisPlanSettingsVM.settings);
    }

    /**
     * Set global filter text.
     */
    function setGlobalFilterText() {
        $scope.$evalAsync(() => {
            AnalysisPlanSettingsVM.ux.globalFilterText = filterLogicService.filterJsonToPlainEnglish(AnalysisPlanSettingsVM.settings.survey_params.global_filter);
        });
    }

    /**
     * Open global filter modal.
     */
    function openGlobalFilterEditor() {
        const editor = dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/overview/global-filter-editor.html',
            controller: 'GlobalFilterCtrl',
            controllerAs: 'GlobalFilterVM',
            windowClass: 'analysis-config modal-sv--full-height modal-sv--full-width',
            resolve: {
                filter: () => {
                    return AnalysisPlanSettingsVM.settings.survey_params.global_filter || {};
                },
            },
        });
        editor.result.then(updatedFilter => {
            if (updatedFilter) {
                AnalysisPlanSettingsVM.settings.survey_params.global_filter = updatedFilter;
                onSettingsChanged().then(setGlobalFilterText);
            }
        });
    }

    /**
     * Delete global filter.
     */
    function deleteGlobalFilter() {
        AnalysisPlanSettingsVM.settings.survey_params.global_filter = null;
        onSettingsChanged().then(setGlobalFilterText);
    }
}
