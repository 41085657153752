import analysisConfigConstants from '../analysis-config-constants';

const defaultLevelDropdownOptions = [{
    name: 'Default',
    value: 'isTargetOption',
    eventName: 'update-factor',
},
{
    name: 'Own Brand',
    value: 'isBrand',
    eventName: 'update-factor',
},
{
    name: 'None',
    value: null,
    eventName: 'update-factor',
}];
const questionCodingConstants = {
    // Table headers for question coding table
    tableHeaders: [{
        name: '&nbsp;',
        columnType: 'notification',
        value: '_notification',
    },
    {
        name: '&nbsp;',
        sortable: true,
        columnType: 'text',
        sortField: '_index',
        value: '_label',
    },
    {
        name: 'Question name',
        sortable: true,
        tooltip: true,
        columnType: 'text',
        sortField: 'name',
        value: '_displayName',
        eventName: 'update-factor',
    },
    {
        name: 'KPI type',
        simple: true,
        columnType: 'dropdown',
        columnClass: 'overflow-visible text-right',
        dropdownOptions: [],
        disabled: row => {
            return row._factorType !== analysisConfigConstants.KPI;
        },
        model: 'kpiType',
        eventName: 'update-factor',
    },
    {
        name: 'Confounder type',
        simple: true,
        columnType: 'dropdown',
        columnClass: 'overflow-visible text-right',
        dropdownOptions: [],
        disabled: row => {
            return row._factorType !== analysisConfigConstants.CONFOUNDER;
        },
        model: 'confounderType',
        eventName: 'update-factor',
    },
    {
        name: 'Objectives',
        simple: true,
        columnType: 'dropdown',
        columnClass: 'overflow-visible text-right',
        dropdownOptions: [],
        disabled: row => {
            return row._factorType !== analysisConfigConstants.KPI;
        },
        model: 'objectivePriority',
        eventName: 'update-factor',
    },
    {
        name: 'Answer default type',
        simple: true,
        iterable: true,
        columnClass: 'text-right',
        cellClass: 'padding-right-sm',
        options: analysisConfigConstants.questionFactorTypes.filter(type => {
            return type.value !== undefined;
        }),
        columnType: 'input',
        inputType: 'radio',
        model: '_factorType',
        eventName: 'update-factor-default-type',
    },
    {
        name: '',
        expandable: true,
        simple: true,
    }],

    // Table options for question coding table
    tableOptions: {
        noSearch: true,
        nestedRows: [{
            nestedRowField: 'levels',
            type: 'table',
            rowClass: 'overflow-visible',
            columns: [{
                value: '_label',
                columnType: 'text',
                columnClass: 'text-bold text-right',
                simple: true,
            },
            {
                name: 'Choice',
                value: '_displayName',
                columnType: 'text',
                columnClass: 'text-left',
                simple: true,
            },
            {
                name: 'Factor Type',
                iterable: true,
                columnType: 'input',
                columnClass: 'text-right',
                inputType: 'radio',
                cellClass: 'padding-right-sm',
                options: analysisConfigConstants.factorLevelFactorTypes,
                simple: true,
                disable: function(row) {
                    return row.questionType === 'singleSelect';
                },
                model: 'factorType',
                eventName: 'update-factor',
            },
            {
                name: 'Stats Dir.',
                simple: true,
                columnClass: 'overflow-visible',
                columnType: 'dropdown',
                dropdownOptions: analysisConfigConstants.testTypes.map(type => {
                    type.eventName = 'update-factor';
                    return type;
                }),
                model: 'testType',
            },
            {
                columnType: 'link',
                eventName: 'add-top-two',
                value: '<i class="fal fa-edit"></i>',
                show: {},
            },
            {
                name: 'Level Default',
                simple: true,
                columnClass: 'overflow-visible',
                columnType: 'dropdown',
                dropdownOptions: defaultLevelDropdownOptions,
                disabled: row =>
                    row.factorType !== analysisConfigConstants.KPI,
                model: 'isDefault',
                eventName: 'update-factor',
            }],
        },
        {
            type: 'link',
            eventName: 'add-top-two',
            name: '<i class="fal fa-plus"></i> Custom Grouping',
            hide: row => row.type === 'longFreeResponse',
        },
        {
            type: 'link',
            eventName: 'add-top-two',
            name: '<i class="fal fa-plus"></i> Fuzzy recoding',
            hide: row => row.type !== 'longFreeResponse',
        }],
    },
    kpiTypePosition: 3, // Which column in the headers is for kpi types
    confounderTypePosition: 4, // Which column in the headers is for confounder types
    objectivePriorityPosition: 5, // Which column in the headers is for objective types
    defaultLevelDropdownOptions: defaultLevelDropdownOptions,
    // Location of each item in the set default dropdown
    defaultLevelChoiceIndices: {
        isTargetOption: 0,
        isBrand: 1,
        none: 2,
    },
};

export default questionCodingConstants;
