import {
    convertParsedCriteria,
} from '../../../filter-and-compare/filter-service';
import {
    prepareLogic,
} from '../../../filter-and-compare/default-filter-service';
import AnalysisConfigModel from '../analysis-config-model';

angular
    .module('globalFilter.view', [
        'pubSub',
        'modalService',
        'analysisPlan.service',
    ])
    .controller('GlobalFilterCtrl', GlobalFilterCtrl);

GlobalFilterCtrl.$inject = [
    '$rootScope',
    '$uibModalInstance',
    'filter',
];

function GlobalFilterCtrl($rootScope, $uibModalInstance, filter) {
    var GlobalFilterVM = this;

    // VM exposed functions
    GlobalFilterVM.close = $uibModalInstance.close;
    GlobalFilterVM.save = save;
    GlobalFilterVM.onChange = onChange;
    GlobalFilterVM.disableSave = disableSave;

    init();

    function init() {
        GlobalFilterVM.filterLogic = {
            filterLogicModel: new AnalysisConfigModel($rootScope.survey),
            parsedCriteria: prepareLogic(filter),
        };
    }

    function onChange(updatedParsedCriteria) {
        GlobalFilterVM.filterLogic.parsedCriteria = updatedParsedCriteria;
    }

    function save() {
        const globalFilter = convertParsedCriteria(GlobalFilterVM.filterLogic.parsedCriteria, GlobalFilterVM.filterLogic.filterLogicModel);
        // Do the save here before closing!
        GlobalFilterVM.close(globalFilter);
    }

    function disableSave() {
        return !GlobalFilterVM.filterLogic.parsedCriteria.length || !GlobalFilterVM.filterLogic.parsedCriteria[0].criteria.length;
    }
}
