angular.module('svInsetModal', [])
    .directive('svInsetModal', [function() {
        return {
            restrict: 'EAC',
            link: function(scope, element, attr) {
                var win = $(window);

                var updateInsetModalSize = function() {
                    var insetHeader = $('sv-inset-modal-header'),
                        insetBody = $('sv-inset-modal-body'),
                        insetFooter = $('sv-inset-modal-footer');

                    if (insetBody.length) {
                        var winHeight = win.outerHeight(),
                            winWidth = win.outerWidth(),
                            svInsetModalMaxHeight = parseInt(element.css('maxHeight'), 10),
                            insetHeaderHeight = insetHeader.outerHeight(),
                            insetFooterHeight = insetFooter.outerHeight(),
                            effectiveWinHeight = svInsetModalMaxHeight ? Math.min(winHeight, svInsetModalMaxHeight) : winHeight,
                            maxHeight = Math.min(Math.max(effectiveWinHeight - insetHeaderHeight - insetFooterHeight - 10, 200), 700);

                        element.css('maxWidth', Math.max(100, winWidth - 20) + 'px');
                        insetBody.css('maxHeight', maxHeight.toString() + 'px');
                    }
                };
                win.on('resize', updateInsetModalSize);
                updateInsetModalSize();
            },
        };
    }]);
