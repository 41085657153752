angular.module('longFreeResponse.editor', [])
    .directive('longFreeResponseEditor', longFreeResponseEditor)
    .controller('LongFreeResponseEditorCtrl', LongFreeResponseEditorCtrl);

function longFreeResponseEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/long-free-response-editor.html',
        scope: {
            question: '=',
        },
        controller: 'LongFreeResponseEditorCtrl',
        controllerAs: 'LongFreeResponseEditorVM',
    };
}
LongFreeResponseEditorCtrl.$inject = ['$rootScope', '$scope'];

function LongFreeResponseEditorCtrl($rootScope, $scope) {
    var LongFreeResponseEditorVM = this;
    LongFreeResponseEditorVM.auth = $rootScope.auth;
    $scope.question.details.placeholder = $scope.question.details.placeholder || '';
}
