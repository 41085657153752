import impressionParameterConstants from './impression-parameter.constants';
import '../../../../../common/js/inc/sv-notify';
import '../../beacons/api-beacon-service';
import '../../common-components/display-table';
import '../../common-components/pub-sub';
import '../../results-components/stats-module';
import '../ad-measurement.api-service';

angular
    .module('impressionParameterValues.view', [
        'pubSub',
        'svNotify',
        'StatsModule',
        'adMeasurement.apiService',
    ])
    .controller('ImpressionParamValueCtrl', impressionParamValueCtrl);

impressionParamValueCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'pubSubService',
    'adMeasurementApiService',
    'impressionParameterData',
];

function impressionParamValueCtrl($rootScope, $scope, $uibModalInstance, pubSubService, adMeasurementApiService, impressionParameterData) {
    const ImpressionParamValueVM = this;
    let selectedBeaconDefinition;

    // VM exposed variables
    ImpressionParamValueVM.values = impressionParameterData.data;
    ImpressionParamValueVM.tableHeaders = impressionParameterConstants.beaconDefinitionTableHeaders;
    ImpressionParamValueVM.csvErrorsTableHeaders = impressionParameterConstants.csvErrorsTableHeaders;
    ImpressionParamValueVM.statusTabs = {
        options: impressionParameterConstants.placementReportUploadStatusTabs,
        active: 'warnings',
    };
    ImpressionParamValueVM.ux = {
        numCsvErrors: _.size(impressionParameterData.errors || {}),
        loading: false,
        paginationOptions: {
            itemsPerPage: 12,
        },
    };

    // VM exposed functions
    ImpressionParamValueVM.closeModal = $uibModalInstance.close;
    ImpressionParamValueVM.saveValues = saveValues;
    ImpressionParamValueVM.disableSave = disableSave;
    ImpressionParamValueVM.hideFooter = hideFooter;
    ImpressionParamValueVM.hasUploadStatus = hasUploadStatus;

    init();

    function init() {
        if (ImpressionParamValueVM.ux.numCsvErrors) {
            ImpressionParamValueVM.csvErrors = _.map(impressionParameterData.errors, (msg, row) => {
                return {
                    row: row,
                    warningMessage: msg,
                };
            });
        }
        else {
            ImpressionParamValueVM.ux.status = 'Parsed ' + ImpressionParamValueVM.values.length + ' rows with no errors.';
        }
        adMeasurementApiService.getTagsForSurvey($rootScope.survey).then(response => {
            const groups = _.groupBy(response, 'segment');
            $scope.$evalAsync(() => {
                ImpressionParamValueVM.tags = groups.null;
            });
        });
        pubSubService.subscribe('select-beacon-definition', $scope.$id, selectBeaconDefinition);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(['select-beacon-definition'], $scope.$id);
        });
    }

    function selectBeaconDefinition(row) {
        if (selectedBeaconDefinition) {
            delete selectedBeaconDefinition._selected;
        }
        selectedBeaconDefinition = row;
        row._selected = true;
        ImpressionParamValueVM.ux.error = null;
    }

    function disableSave() {
        return ImpressionParamValueVM.ux.loading;
    }

    function hasUploadStatus() {
        return !!(ImpressionParamValueVM.ux.serverError || ImpressionParamValueVM.uploadStatus);
    }

    function hideFooter() {
        return hasUploadStatus() || ImpressionParamValueVM.ux.numCsvErrors;
    }

    function saveValues() {
        if (!selectedBeaconDefinition) {
            ImpressionParamValueVM.ux.error = 'Please select a tag';
            return;
        }
        ImpressionParamValueVM.ux.loading = true;
        _.forEach(ImpressionParamValueVM.values, value => {
            value.beaconDefinition = selectedBeaconDefinition.uuid;
        });

        adMeasurementApiService.saveImpressionParameterValuesBySurveyUuid($rootScope.survey.uuid, ImpressionParamValueVM.values).then(response => {
            ImpressionParamValueVM.uploadStatus = response;
            ImpressionParamValueVM.ux.loading = false;
        }, errorMsg => {
            ImpressionParamValueVM.ux.error = errorMsg;
            ImpressionParamValueVM.ux.loading = false;
        }).catch(status => {
            ImpressionParamValueVM.ux.status = status;
        });
    }
}
