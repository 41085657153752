import U from '../../../../common/js/util';
import './../app-components/api-account-service';
import './survey-to-string-service';
import surveyConstants from './survey-constants';
import './api-survey-service';

angular.module('surveyStatusesService', [
    'ui.router',
    'apiAccountService',
    'surveyToStringService',
    'apiSurveyService',
])
    .service('surveyStatusesService', surveyStatusesService);
surveyStatusesService.$inject = [
    '$rootScope',
    '$stateParams',
    'accountService',
    'STSS',
    'surveyService',
];

/**
 * Defines the Survey Statuses service.
 *
 * @param {*} $rootScope
 * @param {*} $stateParams
 * @param {*} accountService
 * @param {*} STSS
 * @param {*} surveyService
 * @returns {{getRouteParams: (function(*): {surveyId: *, funnelType: null|string|*}), isAdvancedFlow: (function()), isLatestRefunded: (function(*)), getStatus: ((function(*=): (string))|*), isExtendedFlow: (function()), isQuoteFlow: (function(): boolean), isEditable: ((function(*=): (boolean))|*), changeSurveyStatus: (function(*=, *=): Promise<*>), isBasicFlow: (function())}}
 */
function surveyStatusesService($rootScope, $stateParams, accountService, STSS, surveyService) {
    const STATUSES = surveyConstants.STATUSES;

    /**
     * Get status.
     *
     * @param {*} survey
     * @returns {string|string}
     */
    function getStatus(survey) {
        if (survey.completed) {
            return STATUSES.COMPLETE;
        }
        else if (survey.canceled && !survey.started) {
            return STATUSES.INACTIVE;
        }
        else if (survey.canceled) {
            return STATUSES.CANCELED;
        }
        else if (survey.started) {
            return survey.paused ? STATUSES.PAUSED : STATUSES.RUNNING;
        }
        else if (survey.approved) {
            return STATUSES.APPROVED;
        }
        else if (survey.reviewed) {
            return STATUSES.AWAITING_APPROVAL;
        }
        else if (survey.reviewStarted) {
            return STATUSES.ANALYST_EDITING;
        }
        else if (survey.draftCompleted) {
            return STATUSES.ANALYST_REVIEW;
        }
        else if (survey.programmingCompleted) {
            return STATUSES.PROGRAMMED;
        }
        else if (survey.programmingRequested) {
            return STATUSES.PROGRAMMING;
        }
        else if (survey.quoteAccepted) {
            return STATUSES.QUOTE_ACCEPTED;
        }
        else if (survey.quoteProvided) {
            return STATUSES.QUOTE_PROVIDED;
        }
        else if (survey.quoteProcessStarted) {
            return STATUSES.QUOTE_IN_PROGRESS;
        }
        else if (survey.quoteRequested) {
            return STATUSES.QUOTE_REQUESTED;
        }

        return STATUSES.DRAFT;
    }

    /**
     * Is basic flow.
     *
     * @returns {boolean}
     */
    function isBasicFlow() {
        return !$stateParams.funnelType || ($rootScope.survey && $rootScope.survey.surveyPackage === 'basic');
    }

    /**
     * Is advanced flow.
     *
     * @returns {boolean}
     */
    function isAdvancedFlow() {
        return isQuoteFlow() || $stateParams.funnelType === 'advanced' || ($rootScope.survey && $rootScope.survey.surveyPackage === 'advanced');
    }

    /**
     * Is extended flow.
     *
     * @returns {boolean}
     */
    function isExtendedFlow() {
        return $stateParams.funnelType === 'extended' || ($rootScope.survey && $rootScope.survey.surveyPackage === 'extended');
    }

    // Helper function to determine whether this is a quote flow
    /**
     * Is quote flow.
     *
     * @returns {boolean}
     */
    function isQuoteFlow() {
        return $stateParams.funnelType === 'quote';
    }

    /**
     * Is editable.
     *
     * @param {*} survey
     * @returns {boolean}
     */
    function isEditable(survey) {
        if (survey.complete || getStatus(survey) === STATUSES.RUNNING) {
            return false;
        }
        else if (survey.reviewStarted && !survey.clientEditable && !$rootScope.auth.user.isAdmin) {
            return false;
        }
        return true;
    }

    /**
     * Helper Functions
     */

    /**
     * Change survey status.
     *
     * @param {*} survey
     * @param {*} action
     * @returns {Promise<*>}
     */
    function changeSurveyStatus(survey, action) {
        var surveyActions = surveyConstants.SURVEY_ACTIONS;

        return surveyService.updateSurveyStatus(action, survey).then(function(response) {
            U.extend(survey, response);

            if (action === surveyActions.START) {
                surveyService.updateCollectionPeriod(survey.latestCollectionPeriod.uuid, {
                    start: 'true',
                }).then(function(cp) {
                    survey.latestCollectionPeriod = cp;
                });
            }
            else if (action === surveyActions.REQUEST_QUOTE) {
                accountService.sendEmail('quote-requested', {
                    surveyUuid: survey.uuid,
                    quoteInfoText: 'TARGETING: ' + STSS.targetingReqsToString(survey),
                });
            }
            return survey;
        });
    }

    /**
     * Get route params.
     *
     * @param {*} survey
     * @returns {{surveyId, funnelType: (null|string|*)}}
     */
    function getRouteParams(survey) {
        return {
            surveyId: survey.uuid,
            funnelType: survey.surveyPackage === 'basic' ? null : survey.surveyPackage === 'advanced' ? 'quote' : survey.surveyPackage,
        };
    }

    return {
        getStatus: getStatus,
        isExtendedFlow: isExtendedFlow,
        isAdvancedFlow: isAdvancedFlow,
        isBasicFlow: isBasicFlow,
        isQuoteFlow: isQuoteFlow,
        isEditable: isEditable,
        changeSurveyStatus: changeSurveyStatus,
        getRouteParams: getRouteParams,
    };
}
