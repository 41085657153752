
import './analysis-plan-cuts.view';
import './target-audience-cuts.view';
import './standard-cuts.view';
import './question-answer-cuts.view';
import './impression-parameter-cuts.view';
import './time-based-cuts.view';
import './custom-cuts.view';

angular.module('analysisPlan.cuts', [
    'analysisPlanCuts.view',
    'targetAudienceCuts.view',
    'standardCuts.view',
    'questionAnswerCuts.view',
    'impressionParameterCuts.view',
    'timeBasedCuts.view',
    'customCuts.view',
]);
