import trackingMethodOptions from './tracking-method/options';

/**
 * @param req
 */
function toString(req) {
    // Req details is a list of IDs
    return _.map(req.details, function(id) {
        return trackingMethodOptions.map[id].name;
    }).join(', ');
}

/**
 * @param req
 */
function errors(req) {
    var errs = [],
        l = (req.details && req.details.length) || 0;

    // Require at least one group to be selected.
    if (l === 0) {
        errs.push('No tracking methods are selected. Select at least one.');
    }

    return errs;
}

const targetingAttributeTrackingMethod = {
    id: 'trackingMethod',
    name: 'Tracking method',
    basic: false,
    advanced: false,
    description: 'Filter by the respondent&rsquo;s tracking method (app or cookie)',
    options: trackingMethodOptions.list,
    toString: toString,
    errors: errors,
};

export default targetingAttributeTrackingMethod;
