import 'angular-cookies';
import '@uirouter/angularjs';
import '../../../../common/js/inc/sv-notify';
import '../common-components/deferred';
import '../common-components/sv-dialog';
import '../common-components/modal-service';
import '../common-components/pub-sub';
import '../question-types/question-type-groups';
import '../survey/api-survey-service';
import '../survey/survey-to-string-service';
import surveyConstants from '../survey/survey-constants';
import '../survey/survey-statuses';
import '../app-components/api-account-service';
import customVariablesService from '../custom-variables/custom-variables.api-service';
import '../results-components/api-results-service';
import '../results-components/stats-module';
import '../filter-and-compare/filter-and-compare-model-manager';
import './survey-dashboard-service';
import DashboardConfig from './survey-dashboard-config';
import U from '../../../../common/js/util';

var DashboardViewTypes = DashboardConfig.viewTypes;

angular.module('surveyDashboard.ctrl', [
    'ui.router',
    'svNotify',
    'common.deferred',
    'common.dialog',
    'modalService',
    'pubSub',
    'questionTypeGroups.service',
    'apiSurveyService',
    'surveyToStringService',
    'apiAccountService',
    'StatsModule',
    'apiResultsService',
    'filterAndCompare.modelManager',
    'surveyDashboardService',
])
    .controller('SurveyDashboardCtrl', SurveyDashboardCtrl);
SurveyDashboardCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$cookies',
    '$timeout',
    '$state',
    '$transitions',
    '$stateParams',
    '$notify',
    'Deferred',
    'dialog',
    'modalService',
    'pubSubService',
    'questionTypeGroupsService',
    'surveyService',
    'accountService',
    '$dashboardService',
    'STSS',
    '$statsService',
    '$resultsService',
    'filterAndCompareModelManager',
];

/**
 * Controller for the dashboard page
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $q
 * @param {object} $cookies
 * @param {object} $timeout
 * @param {object} $state
 * @param {object} $transitions
 * @param {object} $stateParams
 * @param {object} $notify
 * @param {object} Deferred
 * @param {object} dialog
 * @param {object} modalService
 * @param {object} pubSubService
 * @param {object} QTGS
 * @param {object} surveyService
 * @param {object} accountService
 * @param {object} $dashboardService
 * @param {object} STSS
 * @param {object} $statsService
 * @param {object} $resultsService
 * @param {object} filterAndCompareModelManager
 */
function SurveyDashboardCtrl($rootScope, $scope, $q, $cookies, $timeout, $state, $transitions, $stateParams, $notify, Deferred, dialog, modalService, pubSubService, QTGS, surveyService, accountService,
    $dashboardService, STSS, $statsService, $resultsService, filterAndCompareModelManager) {
    $scope.ux = {
        view: $state.current.name.split('.')[1],
        ready: false,
        showExportOverlay: false,
        editDescription: false,
        editSampleSize: false,
        sampleSizeDraft: 0,
        editPreviewUrl: false,
        approvingSurvey: false,
        hasCharts: false,
        showEstimatedCompleteDate: false, // Should be in surveyResultsModule
        chartsReady: false,
        results: {
            resultItem: null,
            surveyCompleted: false,
            count: {},
        },
        sideNavExpanded: false,
        newCustomReportWidget: !!$cookies.unseenReportWidget,
    };
    var readyDeferred = $q.defer();
    $rootScope.ready = readyDeferred.promise;
    $scope.questionTypes = QTGS.types;
    $scope.currentReportUuid = $stateParams.reportId;

    $scope.questionTypeName = QTGS.questionTypeName; // REMOVE: used by survey-design, question-editor, targeting-builder, and survey-builder.
    $scope.questionTypeGroups = QTGS.groupList;
    $scope.dashboard = {
        currentStep: 0,
        steps: DashboardConfig.surveySteps,
    };
    $scope.isProd = U.isProd();

    $scope.revertQuestion = revertQuestion;
    $scope.goToInsightsAnchor = goToInsightsAnchor;
    $scope.previewAvailable = previewAvailable;
    $scope.showPreview = showPreview;
    $scope.approveSurvey = approveSurvey;
    $scope.changeSurveyName = changeSurveyName;
    $scope.showResults = showResults;
    $scope.canRunAgain = canRunAgain;
    $scope.newCollectionPeriod = newCollectionPeriod;
    $scope.duplicateSurvey = duplicateSurvey;
    $scope.resultsBadgeText = resultsBadgeText;
    $scope.hideQuestionEdits = hideQuestionEdits;
    $scope.hideEditButtons = hideEditButtons;
    $scope.range = range;
    $scope.toggleSideNavWidth = toggleSideNavWidth;
    $scope.openEditor = openEditor;
    $scope.openSurveyTextModal = openSurveyTextModal;

    init();

    function init() {
        pubSubService.subscribe('hide-report-selection-dropdown', $scope.$id, hideReportSelectionDropdown);
        pubSubService.subscribe('sv-window-resized', $scope.$id, verifyViewportBreakpoints);
        $transitions.onSuccess({
            to: state => {
                return state.parent.name === 'dashboard' || (state.data || {}).dashboardParent;
            },
        }, function(transition) {
            const next = transition.$to();
            $scope.ux.view = next.data.dashboardParent || (next.parent.name === 'dashboard' ? next.name : next.parent.name);
            $scope.viewDisplayName = getViewDisplayName($scope.ux.view);
        });
        $scope.$on('$destroy', function() {
            pubSubService.destroy([
                'hide-report-selection-dropdown',
                'update-custom-report-badge',
                'sv-window-resized',
            ], $scope.$id);
        });
        // Listens for 'targeting-reqs-updated' event
        $scope.$on('targeting-reqs-updated', generateTargetingReqsString);

        // NEEDED TO RESIZE COLLECTION PERIODS WHEN IT MOVES FROM STATIC TO DROPDOWN STATES
        $timeout(function() {
            // Listen for the BS Affix to resize rzslider width
            $('body').on('affixed.bs.affix affixed-top.bs.affix', function() {
                $scope.$broadcast('rzSliderForceRender');
                $scope.$broadcast('reCalcViewDimensions');
            });
        });

        $dashboardService.getFullSurvey($stateParams.surveyId).then(function(survey) {
            let deferred = new Deferred(),
                statsConfigPromise = $q.defer(),
                customReportPromises = {
                    surveyReport: $q.defer(),
                };
            $rootScope.survey = $rootScope.survey && $rootScope.survey.id === survey.id ? U.extend($rootScope.survey, survey) : survey;
            $rootScope.survey._surveyDisplayConfig = {};
            $scope.ux.view = ($state.current.data || {}).dashboardParent || ($state.current.parent === 'dashboard' ? $state.current.name : $state.current.parent);
            $scope.viewDisplayName = getViewDisplayName($scope.ux.view);
            $scope.survey = $rootScope.survey;
            $scope.checkoutUser = survey.user;
            $scope.dashboard.currentStep = $dashboardService.getCurrentStep($scope.survey);
            $scope.ux.showSurveyStatus = !(survey.canceled || ((survey.surveyPackage === surveyConstants.PACKAGES.ADVANCED) && !survey.draftCompleted) || ($scope.dashboard.currentStep === 3));

            $q.all(_.map(customReportPromises, function(deferred) {
                return deferred.promise;
            })).then(function() {
                // eslint-disable-next-line no-undef
                customReportDataService.dataLoading('finished', []);
            });
            // Fetch collection period info
            deferred(statsConfigPromise.promise);
            $resultsService.getResultsSummary($rootScope.survey).then(function() {
                $scope.collectionPeriods = $rootScope.survey.collectionPeriods;
                // If latest period has responses or if we have previous collection periods
                // eslint-disable-next-line no-warning-comments
                // TODO: Investigate whether hasResults is needed, as it duplicates ux.hasCharts
                $scope.ux.hasCharts = $scope.survey.latestCollectionPeriod._summary.responses || $scope.collectionPeriods.length > 1;
                $scope.hasResults = $scope.survey.latestCollectionPeriod._summary.responses;
                $statsService.initializeStatsConfig($rootScope.survey).then(() => {
                    statsConfigPromise.resolve();
                }, () => {
                    $rootScope.survey._statsConfig = {
                        filters: {
                            questions: {},
                        },
                    };
                    statsConfigPromise.resolve();
                });
                if ($state.current.name === 'dashboard') {
                    $state.go($dashboardService.selectView($rootScope.survey, $scope.ux.hasCharts, DashboardViewTypes), {
                        surveyId: $rootScope.survey.uuid,
                    });
                }
            });
            deferred.promise().then(function() {
                filterAndCompareModelManager.initialize(($rootScope.survey._surveyDisplayConfig || {}).defaultFilterJson);
                $scope.ux.ready = true;
            });

            $scope.latestCollectionPeriod = angular.copy($rootScope.survey.latestCollectionPeriod);

            readyDeferred.resolve();
        }, function(_error) {
            if ($state.current.name !== 'signIn') {
                $rootScope.desiredState = {
                    state: angular.copy($state.current.name),
                    params: angular.copy($stateParams),
                };
            }
            $state.go('signIn');
        });
    }

    /**
     * Opens the editor
     *
     * @param {object} $event
     */
    function openEditor($event) {
        let cv = {
            name: '',
            description: '',
            detailsJson: {},
        };
        modalService.optionCustomVariableEditor(cv, $event).result.then(function(updatedCv) {
            if (!updatedCv) {
                return;
            }
            if (!$rootScope.survey._statsConfig.filters.customVariables) {
                $rootScope.survey._statsConfig.filters.customVariables = customVariablesService.emptyCustomVarFilter();
            }
            $rootScope.survey._statsConfig.filters.customVariables.options.push(customVariablesService.getConvertedStatsConfigObj(updatedCv, 0));
            pubSubService.notify('custom-variable-filters-updated', []);
        });
    }

    /**
     * Hides the report selection dropdown
     *
     * @param {object} hide
     */
    function hideReportSelectionDropdown(hide) {
        $scope.ux.hideDropdown = hide;
    }

    // Used to keep the targeting string updated when the user modifies targeting details
    function generateTargetingReqsString() {
        $rootScope.survey._targetingString = $dashboardService.createTargetingReqsString($scope.survey);
    }

    // ADMIN
    //Belongs to survey-design-ctrl.
    /**
     * Revert question changes
     *
     * @param {object} question
     */
    function revertQuestion(question) {
        surveyService.revertQuestion(question.uuid).then(function() {
            refreshQuestions();
        });
    }

    // ADMIN
    function refreshQuestions() {
        surveyService.listQuestions($scope.survey.uuid).then(function(questions) {
            $scope.survey.questions = questions;
        });
    }

    // ADMIN

    /**
     * Go to the anchor section
     *
     * @param {object} type
     */
    function goToInsightsAnchor(type) {
        $scope.setAddonType(type);
        $timeout(function() {
            var container = document.querySelectorAll('.c-content-container.c-content-container__inner')[0];
            var scrollTo = document.getElementById('insights-anchor');
            container.scrollTop = scrollTo.offsetTop;
        }, 0);
    }

    //Belongs to survey-design-ctrl. We should really re-think how we use survey-app-common.
    /**
     * Checks if the preview of the survey is available
     *
     * @returns {boolean}
     */
    function previewAvailable() {
        return $rootScope.auth.user.isAdmin || !isLegacySurvey($rootScope.survey);
    }

    /**
     * Checks if the survey is a legacy
     *
     * @param {object} survey
     * @returns {boolean}
     */
    function isLegacySurvey(survey) {
        return new Date(survey.created).getTime() < new Date('05/24/2014').getTime();
    }

    /**
     * Show the survey preview
     */
    function showPreview() {
        $dashboardService.showPreview($scope.survey);
    }

    /**
     * Called when approve survey button is clicked in modal
     */
    function approveSurvey() {
        $scope.ux.approvingSurvey = true;
        surveyService.approveSurvey($scope.survey.uuid).then(function() {
            $scope.survey.approved = true;
            $('#approve-survey-modal').modal('hide');
            $scope.ux.approvingSurvey = false;

            $notify.success('Your survey will begin shortly', {
                heading: 'Survey approved!',
                position: 'bottom-right',
            });

            $scope.dashboard.currentStep = $dashboardService.getCurrentStep($scope.survey);
        },
        function() {
            $notify.error('Error approving survey, it may not be set up correctly. Please call or email Upwave for assistance.');
            $scope.ux.approvingSurvey = false;
        });
    }

    /**
     * Updates the survey name
     *
     * @param {string} newName
     */
    function changeSurveyName(newName) {
        surveyService.updateSurvey($scope.survey.uuid, {
            name: newName,
        }).then(function() {
            $scope.$evalAsync(() => {
                $scope.survey.name = newName;
            });
        });
        $scope.ux.editSurveyName = false;
    }

    /**
     * Checks whether the results page should be shown
     *
     * @returns {boolean}
     */
    function showResults() {
        if ($scope.survey.latestCollectionPeriod.completed) {
            return true;
        }

        if ($scope.survey.completed) {
            return true;
        }

        if ($scope.hasResults) {
            return true;
        }

        return !!($scope.collectionPeriods && $scope.collectionPeriods[0].surveyResult);
    }

    /**
     * Checks the survey can run again
     *
     * @returns {boolean}
     */
    function canRunAgain() {
        return ($rootScope.auth.user.isAdmin || $scope.survey.surveyPackage !== surveyConstants.PACKAGES.ADVANCED) && ($scope.survey.completed || $scope.survey.latestCollectionPeriod.completed);
    }

    function newCollectionPeriod() {
        var sampleSize = $scope.survey.latestCollectionPeriod.sampleSize;
        surveyService.createPeriod($scope.survey.uuid, {
            sampleSize: sampleSize,
        })
            .then(function(result) {
                $scope.survey.latestCollectionPeriod = result;
                $notify.success('New collection period was created.');
            },
            function() {
                $notify.error('Error creating new create collection period. Please call or email Upwave for assistance.');
            });
    }

    function duplicateSurvey() {
        surveyService.duplicateSurvey($scope.survey.uuid).then(function(result) {
            $rootScope.dashboardView = false;
            $rootScope.survey = result;
            $state.go('target', {
                funnelType: $rootScope.funnelType.toLowerCase(),
            });
        });
    }
    //Belongs to survey-design-ctrl and insights.html

    function resultsBadgeText() {
        $dashboardService.resultsBadgeText($scope.survey);
    }

    /**
     * Checks whether to hide the question edit section
     *
     * @returns {boolean}
     */
    function hideQuestionEdits() {
        return isLegacySurvey($scope.survey) && $scope.hasResults;
    }

    /**
     * Checks whether we should show the edit button
     *
     * @returns {boolean}
     */
    function hideEditButtons() {
        return $dashboardService.hideEditButtons($rootScope.survey, $rootScope.auth.user);
    }

    /**
     * Returns the range values
     *
     * @param {object} start
     * @param {object} end
     * @returns {object}
     */
    function range(start, end) {
        var result = [];
        while (start < end) {
            result.push(start++);
        }
        return result;
    }

    /**
     * Get the display name based on the provided view name
     *
     * @param {string} viewName
     * @returns {string}
     */
    function getViewDisplayName(viewName) {
        return DashboardConfig.displayName[viewName];
    }

    function toggleSideNavWidth() {
        $rootScope.ux.sideNavExpanded = !$scope.ux.sideNavExpanded;
        $scope.ux.sideNavExpanded = !$scope.ux.sideNavExpanded;
    }

    /**
     * Checks to see if any of the given breakpoints have been met in order to update the
     * UI-userInitiated, a resize action that is user initiated from within the viewport
     *
     * @param {object} data
     */
    function verifyViewportBreakpoints(data) {
        if (!data.userInitiated && $rootScope.isViewportSizeSmall(data)) {
            $rootScope.ux.sideNavExpanded = false;
            $scope.ux.sideNavExpanded = false;
        }
    }

    function openSurveyTextModal() {
        dialog.popup({
            templateUrl: '/dashboard-templates/design/survey_text_modal.html',
            controllerAs: 'SurveyTextVM',
            controller: 'SurveyTextCtrl',
        });
    }
}
