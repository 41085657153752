import _ from 'lodash';
const list = [{
        id: 'desktop',
        name: 'Desk / laptop',
        icon: 'fas fa-laptop',
    },
    {
        id: 'mobile_phone',
        name: 'Mobile phone',
        icon: 'fas fa-mobile-alt',
    },
    {
        id: 'tablet',
        name: 'Tablet',
        icon: 'far fa-tablet',
    }],
    map = {};
_.forEach(list, function(e) {
    map[e.id] = e;
});

const deviceOptions = {
    list,
    map,
};

export default deviceOptions;
