import _ from 'lodash';
import {
    QUESTION_REGEX,
    ALLOWED_FIRST_LEVEL_LOGIC,
    getModelById,
} from './default-filter-service';

import {
    DEFAULT_FUZZY_THRESHOLD,
} from './default-filter-service';

/**
 * @name getEqValueFromSelectedId
 * @param {string} selectedId
 * @description Given a selected criteria id, return the value of the criteria object.
 * @example getEqValueFromSelectedId('female') returns ['Demographic$Gender', 'female']
 *
 * @returns {Array} An array of length two that is the value of {$eq: [VALUE]}
 */
function getEqValueFromSelectedId(selectedId) {
    if (/^(fe)?male$/i.test(selectedId)) {
        return ['Demographic$Gender', selectedId];
    }
    if (/^[1-6][35] (and over|to [1-6][47])$/.test(selectedId)) {
        return ['Demographic$Age', selectedId];
    }
    if (/^BeaconPool\$(\d+|Control)$/.test(selectedId)) {
        return [selectedId, 1];
    }
}

/**
 * @name getEqValueFromId
 * @param {string} modelId - Id of the model. View switch cases
 * @param {string} selectedId - Id of the selected criteria
 * @param params
 * @param {number} binary -  1, 0, or undefined
 * @returns {Array} An array representing the column export portion of survata query language
 * Examples of valid formats:
 * { '$eq': ['Demographic$Gender', 'Male'] }
 * { '$eq': ['Question$1|Choice$1', 1] }
 * { '$le': ['Rounded Days Since Last Exposure', 14] }
 * { '$any': ['Question$1|Choice$1'] }
 * { '$any': ['ImpressionParameterGroups$Creative Concept: Bubby Bear'] }
 * { '$any': ['ImpressionParameterGroups$Creative Concept: Bubby Bear', 'ImpressionParameterGroups$Creative Concept: Teen Wolf'] }
 *
 * Note that logic of type $any should be an array of criterias ONLY, and should not have a value
 */
function getEqValueFromId(params) {
    const {
        model, selectedId, binary, logicType,
    } = params;
    const value = _.isNumber(binary) ? binary : 1;

    if (model.isImpressionParameterGroup) {
        if (logicType === '$any') {
            return [selectedId];
        }
        return [selectedId, value];
    }
    const id = QUESTION_REGEX.test(model.id) ? 'Question$Choice' : model.id;
    switch (id) {
        case 'Beacon$Pool':
        case 'ControlBeacon$':
        case 'ControlBeaconPool$':
        case 'ControlFor$':
        case 'BeaconFrequency$':
        case 'Beacon$':
        case 'Question$Choice':
            return [selectedId, value];
        case 'Demographic$Gender':
        case 'Demographic$Age':
        case 'Demographic$State':
        case 'Demographic$Region':
        case 'Operating$System':
        case 'Demographic$Operating_System':
        case 'Collection$Period':
        default:
            return [id, selectedId];
    }
}

/**
 * @name getStatsParams
 * @param {object} params
 * @param {object[]} params.parsedCriteria - Array of objects containing an id, an array of selected criteria, a logic, and optionally, a parent logic
 * @param {FilterLogicModel} params.filterModel - A FilterLogicModel
 * @param {object[]} params.collectionPeriods - Array of collection period information
 *
 * @returns {Array} An array of tally params. The array will be greater than one if there are unconsolidated periods.
 */
function getStatsParams(params) {
    const filterBy = [],
        baseFilterBy = convertParsedCriteria(params.parsedCriteria, params.filterModel);
    if (!baseFilterBy.$and) {
        const logic = _.keys(baseFilterBy)[0],
            selected = baseFilterBy[logic];
        delete baseFilterBy[logic];
        baseFilterBy.$and = [{
            [logic]: selected,
        }];
    }
    if (params.collectionPeriods) {
        const periods = [];
        for (let i = params.collectionPeriods.start; i <= params.collectionPeriods.end; i++) {
            if (params.collectionPeriods.consolidate) {
                periods.push({
                    $eq: ['Demographic$Period', i + 1],
                });
            }
            else {
                const copy = angular.copy(baseFilterBy);
                copy.$and.push({
                    $eq: ['Demographic$Period', i + 1],
                });
                filterBy.push(copy);
            }
        }
        if (params.collectionPeriods.consolidate) {
            baseFilterBy.$and.push({
                $or: periods,
            });
            filterBy.push(baseFilterBy);
        }
    }
    else {
        filterBy.push(baseFilterBy);
    }
    return filterBy;
}

/**
 * Given custom variable criteria,  build the survata query language filter object
 *
 * @param {object} criteria
 * @param {string} criteria.id - Question id, custom variable id, or model id
 * @param {string[]} criteria.criteria - Array of ids of selected criteria
 * @param {string} criteria.logic - $and, $or, $eq, $not, or EXACTLY
 * @param {FilterLogicModel} filterModel
 * @returns A filter object using Survata Query Language
 */
function getFilterFromCustomVariableCriteria(criteria, filterModel) {
    const cv = _.find(filterModel.Custom$Variable.options, {
            id: criteria.id,
        }),
        filter = [],
        nameRegexp = new RegExp('^(' + _.reduce(criteria.criteria, (acc, str) => {
            if (acc.length) {
                acc += '|';
            }
            acc += str.replace(/[+^$\(\)|\[\]]/g, w => {
                return `\\${w}`;
            });
            return acc;
        }, '') + ')$');
    // Use regex to match the selected name to a subvariable
    cv.options.forEach(sv => {
        if (nameRegexp.test(sv.id)) {
            filter.push(sv.filterJson);
        }
    });
    return filter.length > 1 ? {
        $or: filter,
    } : filter[0];
}

/**
 * Given a criteria object, build the survata query language filter object
 *
 * @param {object} criteria
 * @param {string} criteria.id - Question id, custom variable id, or model id
 * @param {string[]} criteria.criteria - Array of ids of selected criteria
 * @param {string} criteria.logic - $and, $or, $eq, $not, or EXACTLY
 * @param {FilterLogicModel} filterModel
 * @returns A filter object using Survata Query Language
 */
function getFilterFromCriteria(criteria, filterModel) {
    // Custom variable
    if (/^CV\d+$/.test(criteria.id)) {
        return getFilterFromCustomVariableCriteria(criteria, filterModel);
    }
    if (/^\$fuzzy_(contains|or)$/.test(criteria.logic)) {
        return {
            [criteria.logic]: [criteria.id].concat(criteria.criteria, DEFAULT_FUZZY_THRESHOLD),
        };
    }
    const isExactly = criteria.logic === 'EXACTLY',
        model = getModelById(filterModel, criteria.id),
        // When the logic is exactly, we need to set the second argument to 0/1 depending on its selection state defined in the filter model
        selectedCriteria = isExactly && model.type !== 'longFreeResponse' ? model.options : criteria.criteria;
    let logic = isExactly ? '$and' : criteria.logic === '$not' ? '$or' : criteria.logic,
        filter;
    const isAllowedFirstLevelLogic = ALLOWED_FIRST_LEVEL_LOGIC.test(logic);

    if (selectedCriteria.length < 2 && criteria.logic !== '$not') {
        logic = isAllowedFirstLevelLogic ? logic : '$eq';

        return {
            [logic]: getEqValueFromId({
                model: model,
                logicType: logic,
                selectedId: selectedCriteria[0],
            }),
        };
    }
    const parentLogic = isAllowedFirstLevelLogic ? '$or' : logic;
    filter = {
        [parentLogic]: _.map(selectedCriteria, selected => {
            const defaultLogic = /ImpressionParameterGroups\$/.test(selected) ? '$ge' : '$eq';
            const isSet = isExactly ? _.includes(criteria.criteria, selected.id) ? 1 : 0 : 1;
            const filterJson = {};
            filterJson[isAllowedFirstLevelLogic ? logic : defaultLogic] = getEqValueFromId({
                model: model,
                selectedId: selected.id || selected,
                binary: isSet,
            });
            return filterJson;
        }),
    };

    return criteria.logic === '$not' ? {
        $not: filter,
    } : filter;
}

/**
 * Convert the array of parsed criteria into Survata Query Language JSON
 *
 * @param {Array} parsedCriteria - An array of objects or arrays. If it is nested logic, then the item will be an array of objects containing the id, selected criteria, and logic
 * @param {FilterLogicModel} filterModel
 *
 * @returns {object} The filter_by object
 */
function convertParsedCriteria(parsedCriteria, filterModel) {
    if (!parsedCriteria.length) {
        return {
            $and: [],
        };
    }
    let logic;
    const criteria = [];

    // If first item in the array is another array, then we have nested and logic
    if (Array.isArray(parsedCriteria[0])) {
        logic = '$and';
    }
    // Otherwise, get the parent logic/logic from first parsed criteria object
    else {
        logic = parsedCriteria[0].parentLogic || parsedCriteria[0].logic;
    }

    parsedCriteria.forEach(data => {
        if (Array.isArray(data)) {
            criteria.push(convertParsedCriteria(data, filterModel));
        }
        else if (data.criteria.length) {
            criteria.push(getFilterFromCriteria(data, filterModel));
        }
    });
    if (criteria.length < 2) {
        return criteria[0] || {
            $and: [],
        };
    }
    return {
        [logic]: criteria,
    };
}

export {
    getEqValueFromSelectedId,
    getStatsParams,
    convertParsedCriteria,
};
