angular.module('numeric.preview', [])
    .directive('numericPreview', numericPreview);

function numericPreview() {
    return {
        restrict: 'E',
        template: '<div class="text-center"><input type="number" placeholder="{{question.details.placeholder}}"></div>',
        scope: {
            question: '=',
        },
    };
}
