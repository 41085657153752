const selectConstants = new Map([
    ['displayTypes', [{
        id: 'normal',
        name: 'Standard',
    },
    {
        id: 'slider',
        name: 'Slider',
    },
    {
        id: 'dropdown',
        name: 'Dropdown',
    },
    {
        id: 'combobox',
        name: 'Combo box',
    }]],
    ['displayTypeConfig', {
        normal: {
            otherEnabled: true,
            noneOfTheAboveEnabled: true,
            basic: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 60,
            },
            extended: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 60,
            },
            advanced: {
                minChoices: 6,
                maxChoices: 24,
                maxAnswerChars: 256,
            },
        },
        slider: {
            otherEnabled: false,
            noneOfTheAboveEnabled: false,
            basic: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 60,
            },
            extended: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 60,
            },
            advanced: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 128,
            },
        },
        dropdown: {
            otherEnabled: true,
            noneOfTheAboveEnabled: true,
            basic: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 60,
            },
            extended: {
                minChoices: 6,
                maxChoices: 8,
                maxAnswerChars: 60,
            },
            advanced: {
                minChoices: 12,
                maxChoices: 36,
                maxAnswerChars: 128,
            },
        },
        combobox: {
            otherEnabled: true,
            noneOfTheAboveEnabled: true,
            basic: {
                minChoices: 8,
                maxChoices: 24,
                maxAnswerChars: 60,
            },
            extended: {
                minChoices: 8,
                maxChoices: 24,
                maxAnswerChars: 60,
            },
            advanced: {
                minChoices: 12,
                maxChoices: 36,
                maxAnswerChars: 128,
            },
        },
    }],
    ['orderTypes', [{
        value: 'fixed',
        name: 'Fixed order',
        icon: 'fa-lock',
    },
    {
        value: 'shuffle',
        name: 'Randomly shuffle',
        icon: 'fa-random',
    },
    {
        value: 'random-v',
        name: 'Randomly reverse order',
        icon: 'fa-exchange fa-rotate-90',
    }]],
]);
export default selectConstants;
