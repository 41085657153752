import './intro-statement';
import './select';
import './rating';
import './ranking';
import './long-free-response';
import './click-map';
import './grid';
import './numeric';
import './common-components/piping-ctrl';
import './statement-preview-view';
import './question-editor-view';
import './question-types-service';
import './question-type-groups';

angular.module('QuestionTypesModule', [
    'questionTypes.introStatement',
    'questionTypes.select',
    'questionTypes.rating',
    'questionTypes.ranking',
    'questionTypes.longFreeResponse',
    'questionTypes.clickMap',
    'questionTypes.grid',
    'questionTypes.numeric',
    'PipingModule',
    'statementPreview.view',
    'questionEditor.view',
    'questionTypes.service',
    'questionTypeGroups.service',
]);
