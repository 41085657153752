import '../common-components/pub-sub';
import '../common-components/sv-dialog';
import '../charts/charts-service';
import '../charts-widget/chart-data-parser';
import '../filter-and-compare/filter-and-compare-model-manager';
import '../filter-and-compare/filter-and-compare-service';
import '../filter-and-compare/compare-service';
import {
    getStatsParams,
} from '../filter-and-compare/filter-service';
import './survey-results-service';
import './stats-module';
import Config from '../config';

angular.module('surveyResults.view', [
    'pubSub',
    'StatsModule',
    'charts.service',
    'common.dialog',
    'filterAndCompare.modelManager',
    'filterAndCompare.service',
    'charts.service',
    'compareService',
    'surveyResults.service',
])
    .directive('surveyResults', surveyResults)
    .controller('SurveyResultsCtrl', SurveyResultsCtrl);
surveyResults.$inject = [
    '$rootScope',
    'dialog',
    'myChartService',
];

/**
 * Main survey results func
 *
 * @param {object} $rootScope
 * @param {object} dialog
 * @param {object} myChartService
 * @returns {object}
 */
function surveyResults($rootScope, dialog, myChartService) {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/survey-results.html',
        scope: {
            filterAndCompareModel: '=',
            chartsRefreshing: '=',
            isPublic: '=',
        },
        controller: 'SurveyResultsCtrl',
        controllerAs: 'SurveyResultsVM',
        link: (scope, elem, attr, SurveyResultsVM) => {
            SurveyResultsVM.openExportOptionsModal = openExportOptionsModal;

            function openExportOptionsModal() {
                const widgets = _.map(SurveyResultsVM.screeningQuestions.concat(SurveyResultsVM.surveyQuestions), question => {
                    const chart = elem.find(`#full-snapshot-${question.uuid}`);
                    if (question.details.type === 'grid') {
                        return chart.find('dashboard-results-grid-chart')[0];
                    }
                    return chart.find('dashboard-results-chart')[0];
                });
                SurveyResultsVM.ux.preparingDownload = true;
                _.forEach(SurveyResultsVM.dataPromises, deferred => {
                    deferred.resolve();
                });
                Promise.all(SurveyResultsVM.dataRetrieval).then(() => {
                    Promise.all(myChartService.chartInView.flushAll()).then(() => {
                        SurveyResultsVM.ux.preparingDownload = false;
                        dialog.popup({
                            templateUrl: '/modal-templates/report-export-options-modal.html',
                            controller: 'ReportExportOptionsCtrl',
                            controllerAs: 'ReportExportOptionsVM',
                            resolve: {
                                widgets: () => {
                                    return widgets;
                                },
                                fileName: () => {
                                    return $rootScope.survey.name;
                                },
                                crosstabInfo: () => {
                                    return [];
                                },
                            },
                        });
                    });
                });
            }
        },
    };
}
SurveyResultsCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    'pubSubService',
    'filterAndCompareModelManager',
    'filterAndCompareService',
    'compareService',
    'surveyResultsService',
];

/**
 * Main ctrl function
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $q
 * @param {object} pubSubService
 * @param {object} filterAndCompareModelManager
 * @param {object} filterAndCompareService
 * @param {object} compareService
 * @param {object} surveyResultsService
 */
function SurveyResultsCtrl($rootScope, $scope, $q, pubSubService, filterAndCompareModelManager, filterAndCompareService, compareService, surveyResultsService) {
    const SurveyResultsVM = this,
        NUM_INITIALLY_LOADED = 6;
    SurveyResultsVM.auth = $rootScope.auth;
    SurveyResultsVM.survey = $rootScope.survey;
    SurveyResultsVM.ux = {
        pluralized: $rootScope.survey.screeningQuestions && $rootScope.survey.screeningQuestions.length > 1 ? 's' : '',
        loading: true,
    };
    SurveyResultsVM.params = {};

    init();

    function init() {
        const screeningQuestions = [],
            surveyQuestions = [];
        _.forEach($rootScope.survey._statsConfig.questions, question => {
            if (question.screener) {
                screeningQuestions.push(question);
            }
            else {
                surveyQuestions.push(question);
            }
        });
        SurveyResultsVM.surveyQuestions = surveyQuestions;
        SurveyResultsVM.screeningQuestions = screeningQuestions;
        filterAndCompareModelManager.initialize(($rootScope.survey._surveyDisplayConfig || {}).defaultFilterJson);
        if (Config.isUsRegion) {
            getChartData(); // Should not be visible outside the US
        }
        pubSubService.subscribe('update-chart-data', $scope.$id, getChartData);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(['update-chart-data'], $scope.$id);
        });
    }

    function getChartData() {
        let previousPromise;
        SurveyResultsVM.dataRetrieval = [];
        SurveyResultsVM.dataPromises = {};
        getTally();
        _.forEach($rootScope.survey._statsConfig.questions, (question, index) => {
            SurveyResultsVM.dataPromises[question.uuid] = $q.defer();
            previousPromise = previousPromise || SurveyResultsVM.dataPromises[question.uuid].promise;
            previousPromise.then(() => {
                let promises = surveyResultsService.getChartData(question, SurveyResultsVM.params);
                _.forEach(promises, (promise, idx) => {
                    pubSubService.notify(`render-chart-${question.uuid}${question.details.type === 'grid' ? '-' + idx : ''}`, [promise, SurveyResultsVM.params]);
                });
                Promise.all(promises).then(() => {
                    SurveyResultsVM.dataPromises[question.uuid].resolve();
                });
            });
            if (index < NUM_INITIALLY_LOADED) {
                SurveyResultsVM.dataPromises[question.uuid].resolve();
            }
            previousPromise = SurveyResultsVM.dataPromises[question.uuid].promise;
            SurveyResultsVM.dataRetrieval.push(SurveyResultsVM.dataPromises[question.uuid].promise);
        });
    }

    function getTally() {
        let params,
            tallyParams;
        SurveyResultsVM.params.filterModel = filterAndCompareModelManager.filterModel();
        SurveyResultsVM.params.collectionPeriods = filterAndCompareModelManager.collectionPeriodModel();
        SurveyResultsVM.params.comparisonData = filterAndCompareModelManager.comparisonData();
        SurveyResultsVM.params.parsedCriteria = filterAndCompareModelManager.parsedCriteria();
        pubSubService.notify('get-tally-started');
        // Get params to get summary
        if (SurveyResultsVM.params.collectionPeriods && !SurveyResultsVM.params.collectionPeriods.consolidate) {
            params = angular.copy(SurveyResultsVM.params);
            params.collectionPeriods.consolidate = true;
        }
        else {
            params = SurveyResultsVM.params;
        }
        tallyParams = getStatsParams(params);
        // Set tally params for question data
        if (SurveyResultsVM.params.comparisonData) {
            SurveyResultsVM.params.tallyParams = compareService.getComparisonParams(SurveyResultsVM.params);
        }
        else if (SurveyResultsVM.params.collectionPeriods && !SurveyResultsVM.params.collectionPeriods.consolidate) {
            SurveyResultsVM.params.tallyParams = getStatsParams(SurveyResultsVM.params);
        }
        else {
            SurveyResultsVM.params.tallyParams = tallyParams;
        }
        // Get survey summary
        surveyResultsService.getSurveyResultsTallySummary($rootScope.survey, tallyParams[0], SurveyResultsVM.params.collectionPeriods).then(response => {
            pubSubService.notify('get-tally-complete');
            if (response) {
                // Set up count language for filter summary sentence
                pubSubService.notify('update-language', [response.summary]);
            }
        }, response => {
            pubSubService.notify('update-results-status-msg', response);
        });
    }
}
