import _ from 'lodash';
import '../../../../../../common/js/inc/sv-notify';
import '../../../common-components/display-table';
import '../../../common-components/sv-dialog';
import '../../../common-components/pub-sub';
import '../analysis-config-manager';
import '../analysis-factor-service';
import analysisConfigConstants from '../analysis-config-constants';
import questionCodingConstants from './question-coding-constants';
import analysisConfigServerConstantsService from '../analysis-config-server-constants';
import {
    convertFilterBy,
} from '../../../filter-and-compare/default-filter-service';
import './top-two-box-editor';

angular.module('questionCoding.view', [
    'pubSub',
    'svNotify',
    'common.dialog',
    'analysisConfig.manager',
    'analysisFactorService',
    'topTwoBoxEditor',
])
    .directive('questionCoding', questionCoding)
    .controller('QuestionCodingCtrl', QuestionCodingCtrl);

/**
 * The Question Coding directive.
 *
 * @returns {object} - The directive JSON
 */
function questionCoding() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/answer-coding/question-coding.html',
        controller: 'QuestionCodingCtrl',
        controllerAs: 'QuestionCodingVM',
    };
}

QuestionCodingCtrl.$inject = [
    '$scope',
    '$notify',
    'pubSubService',
    'dialog',
    'analysisConfigManager',
    'analysisFactorService',
];

/**
 * The question coding controller.
 *
 * @param {object} $scope - The scope for this component
 * @param {object} $notify - Notification service
 * @param {object} pubSubService - Pub/sub service
 * @param {object} dialog - Modal handler service
 * @param {object} analysisConfigManager - Data model for analysis configuration
 * @param {object} analysisFactorService - Service handling factor CRUD
 */
function QuestionCodingCtrl($scope, $notify, pubSubService, dialog, analysisConfigManager, analysisFactorService) {
    var QuestionCodingVM = this,
        analysisConfig = analysisConfigManager.get();

    // VM exposed variables
    QuestionCodingVM.tableReady = false;
    QuestionCodingVM.tableHeaders = questionCodingConstants.tableHeaders;
    QuestionCodingVM.tableOptions = questionCodingConstants.tableOptions;

    init();

    function init() {
        QuestionCodingVM.tableBody = _.sortBy(analysisConfig.questionFactors, '_index');
        let hasNotificationColumn = false;

        const kpiTypesPromise = analysisConfigServerConstantsService.getKpiTypes();
        const confounderTypesPromise = analysisConfigServerConstantsService.getConfounderTypes();
        const objectivesPromise = analysisConfigServerConstantsService.getObjectivePriorities();

        Promise.all([kpiTypesPromise,
            confounderTypesPromise,
            objectivesPromise]).then(values => {
            const kpiTypes = values[0];
            const confounderTypes = values[1];
            const objectivePriorities = values[2];
            QuestionCodingVM.kpiTypes = _.map(kpiTypes, kpiType => {
                return {
                    name: kpiType.displayName,
                    value: kpiType.code,
                    eventName: 'update-factor',
                };
            });

            // Populate the dropdown options with the KPI types
            QuestionCodingVM.tableHeaders[questionCodingConstants.kpiTypePosition].dropdownOptions = QuestionCodingVM.kpiTypes;

            QuestionCodingVM.confounderTypes = _.map(confounderTypes, confounderType => {
                return {
                    name: confounderType.displayName,
                    value: confounderType.code,
                    eventName: 'update-factor',
                };
            });
            // Populate the dropdown options with the KPI types
            QuestionCodingVM.tableHeaders[questionCodingConstants.confounderTypePosition].dropdownOptions = QuestionCodingVM.confounderTypes;

            QuestionCodingVM.objectivePriorities = _.map(objectivePriorities, objectivePriority => {
                return {
                    name: objectivePriority.displayName,
                    value: objectivePriority.code,
                    eventName: 'update-factor',
                };
            });
            QuestionCodingVM.objectivePriorities.push({
                name: 'None',
                value: null,
                eventName: 'update-factor',
            });
            QuestionCodingVM.tableHeaders[questionCodingConstants.objectivePriorityPosition].dropdownOptions = QuestionCodingVM.objectivePriorities;

            // Now that the KPI and confounder types dropdown setup is done, the table is ready to display
            $scope.$evalAsync(() => {
                QuestionCodingVM.tableReady = true;
            });

            // Hide top two box link if factor already has one
            QuestionCodingVM.tableBody.forEach(factor => {
                // Populate ID field, which is used by display table to notify when factor levels are updated
                factor.id = factor._label;

                if (factor._initializationFailed) {
                    factor._notification = {
                        type: 'danger',
                        tooltip: 'Factor initialization failed',
                    };
                    hasNotificationColumn = true;
                }

                // Factor.kpiType is a string, but we actually need the full kpiType object
                factor.kpiType = _.find(QuestionCodingVM.kpiTypes, type => {
                    return type.value === factor.kpiType;
                });

                // Factor.confounderType is a string, but we actually need the full confounderType object
                factor.confounderType = _.find(QuestionCodingVM.confounderTypes, type => {
                    return type.value === factor.confounderType;
                });
                // Factor.objectivePriority is a string, but we actually need the full objectivePriority object
                factor.objectivePriority = _.find(QuestionCodingVM.objectivePriorities, objective => {
                    return objective.value === factor.objectivePriority;
                });
                if (!factor.objectivePriority) {
                    factor.objectivePriority = {
                        name: 'None',
                        value: null,
                        eventName: 'update-factor',
                    };
                }

                factor.levels.forEach(level => {
                    level.questionType = factor._question.factorType && factor._question.factorType.type;
                    if (level.isSynthetic) {
                        showEditButtonForFactorLevel(level);
                        level._levelsToCombine = (level.levelsToCombine || []).join(', ');
                    }
                    // Levels have boolean value for isTargetOption and brandUuid which indicates whether it is own brand
                    // but we need to convert it to the objects in the dropdowns
                    if (level.brandUuid) {
                        level.isDefault = questionCodingConstants.defaultLevelDropdownOptions[questionCodingConstants.defaultLevelChoiceIndices.isBrand];
                        // If a level has a brandUuid, then set it to QuestionCodingVM too
                        // so that for other levels we can set it directly later without having make backend find the uuid
                        if (!QuestionCodingVM.brandUuid) {
                            QuestionCodingVM.brandUuid = level.brandUuid;
                        }
                    }
                    else if (level.isTargetOption) {
                        level.isDefault = questionCodingConstants.defaultLevelDropdownOptions[questionCodingConstants.defaultLevelChoiceIndices.isTargetOption];
                    }
                    else {
                        level.isDefault = questionCodingConstants.defaultLevelDropdownOptions[questionCodingConstants.defaultLevelChoiceIndices.none];
                    }
                });

                // Check factor for any errors
                validateFactor(factor);
            });

            // If there are no notifications to show, remove column from header
            if (!hasNotificationColumn) {
                _.pullAt(QuestionCodingVM.tableHeaders, [0]);
            }
        });

        pubSubService.subscribe('update-factor-default-type', $scope.$id, factor => {
            _.forEach(factor.levels, level => {
                level.factorType = factor._factorType;
            });
            updateFactor(factor);
        });
        pubSubService.subscribe('update-factor-list', $scope.$id, (factor, old) => {
            analysisConfig.updateFactorList(factor, old);
            updateFactor(factor);
        });
        pubSubService.subscribe('update-factor', $scope.$id, updateFactor);
        pubSubService.subscribe('add-top-two', $scope.$id, addCombinedLevels);
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'update-factor-list',
                'update-factor',
                'add-top-two',
            ], $scope.$id);
        });
    }

    /**
     * Sets whether to show edit button for factor level.
     *
     * @param {object} level - The factor level
     */
    function showEditButtonForFactorLevel(level) {
        QuestionCodingVM.tableOptions.nestedRows[0].columns[4].show[level.uuid] = true;
    }

    /**
     * Check the given factor for errors or warnings.
     *
     * @param {object} factor - The factor to validate
     */
    function validateFactor(factor) {
        // First, clear any previous notifications
        factor._notification = undefined;

        // Set and validate the default/target level if factor type is KPI
        if (factor._factorType === analysisConfigConstants.KPI) {
            setDefaultFactorLevelAndValidate(factor);
        }

        // If factor-type is 'KPI', a KPI type must also be specified
        if (factor._factorType === analysisConfigConstants.KPI && !factor.kpiType) {
            factor._notification = {
                type: 'error',
                target: 'kpiType',
                tooltip: 'KPI type must be specified',
            };
            pubSubService.notify(`refresh-table-${factor._label}`);
        }
        // If factor-type is 'confounder', a Confounder type must also be specified
        if (factor._factorType === analysisConfigConstants.CONFOUNDER && !factor.confounderType) {
            factor._notification = {
                type: 'error',
                target: 'confounderType',
                tooltip: 'Confounder type must be specified',
            };
            pubSubService.notify(`refresh-table-${factor._label}`);
        }
    }

    /**
     * Check the levels of a factor whether there is more than one ownBrand to the factor
     * Set the ownBrand level with brandUuid if available
     * Set the isTargetOption and isBrand so that it can be directly consumed when calling API
     *
     * @param {object} factor - The factor to validate
     *
     */
    function setDefaultFactorLevelAndValidate(factor) {
        let hasOwnBrandLevel = false;
        const defaultLevelChoices = questionCodingConstants.defaultLevelDropdownOptions;
        factor.levels.forEach(level => {
            level._notification = undefined;
            level.isBrand = level.isDefault.value === defaultLevelChoices[questionCodingConstants.defaultLevelChoiceIndices.isBrand].value;
            if (level.isBrand) {
                if (hasOwnBrandLevel) {
                    level._notification = {
                        type: 'error',
                        target: 'isDefault',
                        tooltip: 'Each factor can only have one level assigned as own brand',
                    };
                    pubSubService.notify(`refresh-table-${factor._label}`);
                }
                if (QuestionCodingVM.brandUuid) {
                    level.brandUuid = QuestionCodingVM.brandUuid;
                }
                level.isTargetOption = true;
                hasOwnBrandLevel = true;
            }
            else {
                level.isTargetOption = level.isDefault.value === defaultLevelChoices[questionCodingConstants.defaultLevelChoiceIndices.isTargetOption].value;
                level.brandUuid = null;
            }
        });
    }

    /**
     * Update an analysis factor.
     *
     * @param {object} factor - The factor to update.
     */
    function updateFactor(factor) {
        analysisConfig.cleanFactor(factor);
        validateFactor(factor);

        analysisFactorService.saveAnalysisFactor(analysisConfig, factor).then(() => {
        }, () => {
            $notify.error(`There was an error saving ${factor._label}`);
        });
    }

    /**
     * Combines two factor levels - usually for top two box - to create a custom grouping.
     * Sets the initial state for the custom factor level, then opens a modal to allow further editing
     *
     * @param {object} factor - The parent factor for the factor levels to be combined
     * @param {object} levelToUpdate - Existing level/custom grouping, if we're editing rather than creating a new one
     */
    function addCombinedLevels(factor, levelToUpdate) {
        if (factor.type === 'longFreeResponse') {
            // Handle preexisting longFreeResponse fuzzyCoding
            if (levelToUpdate.definitionJson) {
                analysisFactorService.buildParsedCriteriaForFuzzyFactor(levelToUpdate, factor);
            }
            else {
                // Handle creating a new longFreeResponse fuzzyCoding
                levelToUpdate = analysisFactorService.getEmptyFactorLevel(`Unaided Awareness (${factor.name.replace(/^\d+\.(\d+\.)? /, '')})`, factor.levels.length);

                levelToUpdate.parsedCriteria = [{
                    id: factor._question.id,
                    criteria: [],
                    logic: '$fuzzy_contains',
                }];

                levelToUpdate.questionType = 'longFreeResponse';
                levelToUpdate.isSynthetic = true;
            }
        }
        else if (levelToUpdate.definitionJson) {
            // Get parsed criteria for existing level/custom grouping that does not a descendant of
            // a longFreeResponse question
            levelToUpdate.parsedCriteria = convertFilterBy(levelToUpdate.definitionJson, []);
        }
        else {
            // Handle creating new level/custom grouping for non longFreeResponse
            // Get first two choices to be the top two
            const levels = _.values(factor._question.levels).slice(0, 2),
                questionId = levels[0].id.replace(/\|Choice\$\d+$/, ''),
                levelIds = _.map(levels, level => {
                    return level.id;
                });

            // Prefix the factor level name with the question index. But add 1 because human end users generally prefer 1 based indexing.
            levelToUpdate = analysisFactorService.getEmptyFactorLevel(`${factor._index + 1}. T2B - (${factor.name.replace(/^\d+\.(\d+\.)? /, '')})`, factor.levels.length);

            levelToUpdate.parsedCriteria = [{
                id: questionId,
                criteria: levelIds,
                logic: '$or',
            }];

            levelToUpdate.levelsToCombine = _.map(levels, level => {
                return level.name;
            });

            levelToUpdate.isSynthetic = true;
        }

        dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/answer-coding/top-two-box-editor.html',
            controller: 'TopTwoBoxEditorCtrl',
            controllerAs: 'TopTwoBoxEditorVM',
            windowClass: 'analysis-config modal-sv--full-height modal-sv--lg-width',
            resolve: {
                factor: () => {
                    return factor;
                },
                combined: () => {
                    return levelToUpdate;
                },
            },
        }).result.then(updated => {
            if (updated) {
                const idx = _.findIndex(factor.levels, {
                    uuid: updated.uuid,
                });
                if (idx === -1) {
                    factor.levels.push(updated);
                    showEditButtonForFactorLevel(updated);
                }
                else if (updated.deleted) {
                    factor.levels.splice(idx, 1);
                }
                else {
                    factor.levels[idx] = updated;
                }
                pubSubService.notify(`refresh-table-${factor._label}`);
            }
        });
    }
}
