const surveyListConstants = {
    statusFilters: [{
        id: 'hideApproved',
        description: 'Hide Approved',
        value: false,
    },
    {
        id: 'hideDrafts',
        description: 'Hide Drafts',
        value: true,
    },
    {
        id: 'hideCompleted',
        description: 'Hide Completed',
        value: true,
    },
    {
        id: 'hideRunning',
        description: 'Hide Running',
        value: false,
    },
    {
        id: 'hideCanceled',
        description: 'Hide Canceled',
        value: true,
    },
    {
        id: 'showStarted',
        description: 'Show Started',
        value: false,
    }],
    tableFilters: [{
        field: 'id',
        type: 'numeric',
        expression: '',
    },
    {
        field: 'customerSurveyId',
        type: 'alpha',
        expression: '',
    },
    {
        field: 'name',
        type: 'alpha',
        expression: '',
    },
    {
        field: 'user.name',
        type: 'alpha',
        expression: '',
    }],
};

export default surveyListConstants;
