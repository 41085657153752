import deviceOptions from './device/options';

/**
 * @param req
 */
function toString(req) {
    return _.map(req.details, function(id) {
        return deviceOptions.map[id].name;
    }).join(', ');
}

/**
 * @param req
 */
function errors(req) {
    var errs = [],
        l = (req.details && req.details.length) || 0;

    // Require at least one group to be selected.
    if (l === 0) {
        errs.push('No device type is selected. Select at least one.');
    }

    // Require at least one group to be un-selected.
    if (deviceOptions.list.length === l) {
        errs.push('All device types are selected. Unselect at least one.');
    }

    return errs;
}
const targetingAttributeDevice = {
    id: 'device',
    name: 'Device type',
    basic: true,
    extended: true,
    advanced: false,
    description: 'Filter by the respondent&rsquo;s device type',
    options: deviceOptions.list,
    toString: toString,
    errors: errors,
};

export default targetingAttributeDevice;
