import _ from 'lodash';
import analysisConfigDataService from './analysis-config-data-service';
import analysisConfigConstants from './analysis-config-constants';
import defaultCuts from './cuts/types/default-cuts';
import './analysis-config-service';

angular.module('analysisConfiguration', [
    'analysisConfig.service',
])
    .factory('AnalysisConfiguration', AnalysisConfiguration);
AnalysisConfiguration.$inject = [
    'analysisConfigService',
];

/**
 * Analysis configuration.
 *
 * @param {object} analysisConfigService - Analysis configuration service
 * @returns {object} self
 */
function AnalysisConfiguration(analysisConfigService) {
    /**
     * AnalysisConfiguration contructor.
     *
     * @param {object} configuration - Configuration to use for initialization
     * @returns {object} analysis configuration object
     */
    function AnalysisConfiguration(configuration) {
        var self = this,
            defaultCut = angular.copy(defaultCuts);
        self.audienceProfileParams = analysisConfigService.getDefaultAudienceProfileParams();
        self.inputParams = analysisConfigService.getDefaultInputParams();
        self.outputParams = analysisConfigService.getDefaultOutputParams();
        self.surveyParams = analysisConfigService.getDefaultSurveyParams();
        self.weightingParams = analysisConfigService.getDefaultWeightingParams();
        self.humanCutter = analysisConfigService.getDefaultHumanCutter();
        self.impressionCountColumns = analysisConfigService.getImpressionCountColumns();
        self.confounders = {
            behavioral: [],
            other: [],
            temporal: [],
        };
        self.traitFactors = [];
        self.targetAudience = null;
        self.defaultCuts = angular.copy(defaultCut);
        init();

        function init() {
            let controlTypeMap = {};
            if (!analysisConfigService.filterModel.Demographic$Gender) {
                delete defaultCut['Gender: Male'];
                delete defaultCut['Gender: Female'];
            }
            self.customFactors = configuration.customFactors;
            self.questionFactors = configuration.questionFactors;
            self.version = configuration.uuid;
            self.kpiFactors = configuration.kpi;
            self.traitFactors = configuration.traitFactors;
            self.marketResearchFactors = configuration.marketResearch;
            self.targetAudience = configuration.targetAudience;
            self.confounders.behavioral = configuration.confounder.behavioral;
            self.humanCutter.cuts = configuration.globalCuts;
            self.cutFactors = configuration.cutFactors;
            self.specificFactors = configuration.specificFactors;
            self.specificCuts = configuration.specificCuts;
            if (!self.kpiFactors.length && !self.marketResearchFactors.length && !self.confounders.behavioral.length) {
                self.confounders.behavioral = _.map(self.questionFactors, function(questionFactor) {
                    return questionFactor;
                });
            }
            _.forEach(analysisConfigConstants.cutControlTypes, function(controlType) {
                controlTypeMap[controlType.value] = controlType;
            });
            _.forEach(self.defaultCuts, function(cut, cutName) {
                if (self.humanCutter.cuts[cutName]) {
                    delete self.defaultCuts[cutName];
                }
                else {
                    cut.set = true;
                    cut.type = controlTypeMap[cut.type] || analysisConfigConstants.cutControlTypes[0];
                }
            });
        }

        return self;
    }

    AnalysisConfiguration.prototype.getFactorListFromFactorType = getFactorListFromFactorType;
    AnalysisConfiguration.prototype.updateFactorList = updateFactorList;
    AnalysisConfiguration.prototype.cleanFactor = cleanFactor;

    /**
     * Clean the factor object
     *
     * @param {object} factor - Factor to be updated
     */
    function cleanFactor(factor) {
        if (factor._factorType !== analysisConfigConstants.KPI) {
            delete factor.kpiType;
        }
        if (factor._factorType !== analysisConfigConstants.CONFOUNDER) {
            delete factor.confounderType;
        }
    }

    /**
     * Update analysis factor list.
     *
     * @param {object} factor - Factor to be updated
     * @param {object} old - The old factor type
     */
    function updateFactorList(factor, old) {
        if (factor._factorType !== old) {
            if (factor._factorType) {
                this.getFactorListFromFactorType(factor._factorType).push(factor);
            }
            else if (factor.uuid) {
                if (factor._factorType) {
                    analysisConfigDataService.updateAnalysisFactor(this.version, factor);
                }
                else {
                    analysisConfigDataService.deleteAnalysisFactor(factor);
                }
            }
            cleanFactor(factor);
        }
    }

    /**
     * Get factor list, given factor type.
     *
     * @param {string} factorType - Type of analysis factor
     * @returns {object[]} - List of factors based on type
     */
    function getFactorListFromFactorType(factorType) {
        let self = this;
        if (factorType === analysisConfigConstants.CONFOUNDER) {
            return self.confounders.behavioral;
        }
        if (factorType === analysisConfigConstants.KPI) {
            return self.kpiFactors;
        }
        return self.marketResearchFactors;
    }
    return AnalysisConfiguration;
}
