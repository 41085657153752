import './tag-creation-modal';
import './beacon-pool-editor-modal';
import './beacon-definition-code-modal';
import './beacons-ctrl';
import './segment-creation-modal';

angular.module('beaconModule', [
    'beaconDefinitionCodeModal',
    'beaconCtrl',
    'tagCreation.modal',
    'beaconPoolEditor.modal',
    'segmentCreation.modal',
]);
