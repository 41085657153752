import filterLogicConstants from '../../../filter-logic/filter-logic-constants';
import getLogicPhraseById from '../../../filter-logic/filter-logic-phrases';

/**
 * Get the target audience filter logic model,
 * If there are factors passed in, add the logic model from factors
 * otherwise return the default six
 *
 * @param {object} survey
 * @param {object[]} [factors=null]
 * @returns {object} the filter logic model
 */
export function getTargetAudienceFilterLogicModel(survey, factors = null) {
    const filterLogic = {};

    filterLogic.Demographic$Age = {
        id: filterLogicConstants.criteriaTypes.AGE,
        name: 'Age',
        logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.AGE),
        isAvailable: true,
        options: filterLogicConstants.Demographic$AgeOptions,
    };
    filterLogic.Demographic$Gender = {
        id: filterLogicConstants.criteriaTypes.GENDER,
        name: 'Gender',
        logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.GENDER),
        isAvailable: true,
        options: filterLogicConstants.Demographic$GenderOptions,
    };
    filterLogic.Demographic$Region = {
        id: filterLogicConstants.criteriaTypes.REGION,
        name: 'Region',
        logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.REGION),
        isAvailable: true,
        options: filterLogicConstants.Demographic$RegionOptions,
    };
    filterLogic.Operating$System = {
        id: filterLogicConstants.criteriaTypes.OPERATING_SYSTEM,
        name: 'Operating System',
        logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.OPERATING_SYSTEM),
        isAvailable: true,
        options: filterLogicConstants.Operating$SystemOptions,
    };
    filterLogic.Urbanicity = {
        id: 'Urbanicity',
        name: 'Urbanicity (based on Postal Code)',
        logicPhrase: getLogicPhraseById('Urbanicity'),
        isAvailable: true,
        options: filterLogicConstants.urbanicityOptions,
    };

    filterLogic.MedianIncomeRangeOfPostalCode = {
        id: filterLogicConstants.criteriaTypes.MEDIAN_INCOME_RANGE_OF_POSTAL_CODE,
        name: 'Median income range of postal code',
        logicPhrase: getLogicPhraseById('MedianIncomeRangeOfPostalCode'),
        isAvailable: true,
        options: filterLogicConstants.MedianIncomeRangeOfPostalCodeOptions,
    };

    if (factors) {
        const statsConfig = survey._statsConfig;
        const questions = statsConfig.filters.questions.options;
        const factorNames = factors.map(factor => factor.name);
        const filteredQuestions = questions.filter(question => factorNames.includes(question.name));
        filterLogic.Question$Choice = {
            id: 'Question$Choice',
            name: 'Question choice',
            isAvailable: true,
            hasSubmenu: true,
            options: filteredQuestions.map(question => {
                let questionLogicPhraseType = 'Question$Choice';
                if (question.type === 'multiSelect') {
                    questionLogicPhraseType = 'Question$ChoiceExtended';
                }
                question.logicPhrase = getLogicPhraseById(questionLogicPhraseType);
                return {
                    ...question,
                };
            }),
        };
    }
    return filterLogic;
}
