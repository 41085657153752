
angular.module('survataLogos', [])
    .directive('survataLogo', [function() {
        return {
            restrict: 'EA',
            replace: false,
            template: '' +
            '<span class="sr-only">Upwave</span>' +
            '<i class="im im-survata-logo-people"></i>' +
            '<i class="im im-survata-logo-glass"></i>' +
            '<i class="im im-survata-logo-name"></i>',
        };
    }])
    .directive('survataLogoMark', [function() {
        return {
            restrict: 'EA',
            replace: false,
            template: '' +
            '<span class="sr-only">Upwave</span>' +
            '<i class="im im-survata-logo-people"></i>' +
            '<i class="im im-survata-logo-glass"></i>',
        };
    }])
    .directive('survataLogoName', [function() {
        return {
            restrict: 'EA',
            replace: false,
            template: '' +
            '<span class="sr-only">Upwave</span>' +
            '<img src="/assets/images/branding/brand-logo-title.svg">',
        };
    }]);

