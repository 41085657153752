import _ from 'lodash';
import adMeasurementConstants from '../ad-measurement.constants';

export default class AudienceConfigurationStepManager {
    constructor(completed) {
        this.steps = [{
            name: 'Exposure',
            isCurrentStep: true,
            enabled: true,
        },
        {
            name: 'Select Control',
            enabled: true,
            nestedSteps: [{
                name: 'Create Control',
                title: 'Configure New Control',
                description: 'Some sentence about what this is all about',
                completed: false,
                enabled: false,
                parentIdx: 1,
            }, {
                name: 'Review Control',
                title: 'Review & Name New Control',
                description: 'Some sentence about what this is all about',
                completed: false,
                enabled: false,
                parentIdx: 1,
            }],
        },
        {
            name: 'Review',
            title: 'Review your configuration',
            description: 'Some sentence about review',
            enabled: true,
        }];
        this.currentStepIndex = 0;
        this.currentStep = {
            name: this.steps[this.currentStepIndex].name,
        };

        // Set step objects up for manipulation
        _.forEach(this.steps, step => {
            step.completed = completed;
        });
    }
    setCurrentStep(e, idx, subStep) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (_.isNumber(this.currentNestedStepIndex)) {
            this.steps[this.currentStepIndex].nestedSteps[this.currentNestedStepIndex].completed = true;
            delete this.steps[this.currentStepIndex].nestedSteps[this.currentNestedStepIndex].isCurrentStep;
        }
        else {
            this.steps[this.currentStepIndex].completed = true;
            delete this.steps[this.currentStepIndex].isCurrentStep;
        }
        this.currentStepIndex = idx;
        if (_.isNumber(subStep)) {
            let step = this.steps[this.currentStepIndex].nestedSteps[subStep];
            step.isCurrentStep = true;
            this.currentStep.name = adMeasurementConstants.audienceConfigurationStepTypes.NEW_CONTROL;
            this.currentSubStep = this.currentSubStep || {};
            this.currentSubStep.name = step.name;
            this.currentSubStep.title = step.title;
            this.currentSubStep.description = step.description;
            this.currentNestedStepIndex = subStep;
        }
        else {
            this.steps[this.currentStepIndex].isCurrentStep = true;
            this.currentStep.name = this.steps[this.currentStepIndex].name;
            delete this.currentSubStep;
            delete this.currentNestedStepIndex;
        }
    }

    getCurrentStep() {
        return this.currentStep;
    }
}
