
export default {
    surveyPackages: {
        basic: {
            cpi: {
                us: 2,
                international: 3,
            },
        },
        extended: {
            cpi: {
                us: 3,
                international: 5,
            },
        },
    },
    samplePackages: [
        {
            id: 'deepRead',
            name: 'Deep Read',
            sampleSize: 4000,
            description: 'Perfect to investigate trends by demographic subgroup',
        },
        {
            id: 'standardRead',
            name: 'Standard Read',
            sampleSize: 1000,
            description: 'Perfect to measure consumer preference & behavior',
            popular: true,
        },
        {
            id: 'quickRead',
            name: 'Quick Read',
            sampleSize: 500,
            description: 'Perfect to test a hunch or preview a study before a Standard Read',
        },
        {
            id: 'custom',
            name: 'Custom',
            description: 'Perfect to meet your specific needs',
            custom: true,
        },
    ],
    extras: [
        {
            cutoff: 1,
            description: 'Research analyst to edit questions',
            icon: 'fas fa-pencil',
            tooltip: '',
        },
        {
            cutoff: 1,
            description: 'Online tools to analyze results',
            icon: 'fas fa-cogs',
            tooltip: '',
        },
        {
            cutoff: 500,
            description: 'Census representative results',
            icon: 'im im-statistics',
            tooltip: '',
        },
        {
            cutoff: 2000,
            description: 'Post-survey call to review results',
            icon: 'fas fa-phone',
            tooltip: '',
        },
    ],
};

