import countryOptions from './country/options';

/**
 * @param req
 */
function toString(req) {
    return req.details.type.toUpperCase() === 'US' ? 'US' : 'International: ' + req.details.countries.join(', ');
}

/**
 * @param req
 */
function errors(req) {
    var errs = [];

    // If user selected international but did not add any countries, return an error
    if (req.details.type && req.details.type === 'international' && (!req.details.countries || !req.details.countries.length)) {
        errs.push('No countries selected. Select at least one.');
    }
    return errs;
}

const targetingAttributeCountry = {
    id: 'country',
    name: 'Country',
    hide: true,
    description: 'Filter by the respondent\'s country of residence',
    options: countryOptions.list,
    initialDetails: {
        type: 'US',
        countries: [],
    },
    toString: toString,
    errors: errors,
};

export default targetingAttributeCountry;
