import './filter-and-compare-model-manager';
import './filter-and-compare-service';
import './filter-and-compare-view';
import './comparison-panel';
import './compare-service';
import './filter-panel';
import './collection-period-panel';
import './filter-item.view';

angular.module('FilterAndCompareModule', [
    'filterAndCompare.modelManager',
    'filterAndCompare.service',
    'filterAndCompare.view',
    'comparisonPanel',
    'compareService',
    'filterPanel.view',
    'collectionPeriods.panel',
    'filterItem.view',
]);
