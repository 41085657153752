import '../../common-components/sv-dialog';
import '../../survey-creation/survey-creation-service';
import '../../survey/api-survey-service';
import countryOptions from './options';

angular.module('country.editorView', [
    'apiSurveyService',
    'common.dialog',
    'surveyCreation.service',
])
    .directive('countryEditor', countryEditor)
    .controller('InternationalModalCtrl', InternationalModalCtrl)
    .controller('CountryEditorCtrl', CountryEditorCtrl);

/**
 *
 */
function countryEditor() {
    return {
        restrict: 'E',
        scope: {
            requirement: '=?',
        },
        templateUrl: 'survey-creation-templates/target/country-editor.html',
        controller: 'CountryEditorCtrl',
        controllerAs: 'CountryEditorVM',
    };
}
CountryEditorCtrl.$inject = [
    '$rootScope',
    '$scope',
    'dialog',
    'surveyService',
    'surveyCreationService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param dialog
 * @param surveyService
 * @param surveyCreationService
 */
function CountryEditorCtrl($rootScope, $scope, dialog, surveyService, surveyCreationService) {
    const CountryEditorVM = this;
    let surveyTargetingReqs;
    CountryEditorVM.survey = $rootScope.survey;
    CountryEditorVM.auth = $rootScope.auth;
    CountryEditorVM.dashboardView = $rootScope.dashboardView;
    // Have to use 'searchBar.countries' instead of just 'searchBar' because ng-model would suddenly not work with the angular ui-select...
    CountryEditorVM.updateDetailsType = updateDetailsType;

    init();

    function init() {
        $rootScope.survey.targetingReqs = $rootScope.survey.targetingReqs || [];
        refreshTargetingReqs();
    }

    /**
     * @param type
     */
    function updateDetailsType(type) {
        if (type === 'international') {
            openCountriesModal();
        }
        else {
            $scope.requirement.details.countries = [];
            surveyService.updateAudience($rootScope.survey._audienceId, {
                targetingReqs: [$scope.requirement],
            }).then(() => {
                refreshTargetingReqs();
            });
        }
    }

    function openCountriesModal() {
        dialog.popup({
            templateUrl: '/modal-templates/international-modal.html',
            controller: InternationalModalCtrl,
            controllerAs: 'InternationalModalVM',
            windowClass: 'countries-modal',
            resolve: {
                currentCountries: () => {
                    return CountryEditorVM.currentCountries;
                },
            },
        }).result.then(currentCountries => {
            if (currentCountries) {
                if (currentCountries.length < 1) {
                    $scope.requirement.details.type = 'US';
                }
                $scope.requirement.details.countries = _.map(currentCountries, 'id');
                surveyService.updateAudience($rootScope.survey._audienceId, {
                    targetingReqs: [$scope.requirement],
                }).then(() => {
                    CountryEditorVM.currentCountries = currentCountries;
                    refreshTargetingReqs();
                });
            }
        });
    }

    function refreshTargetingReqs() {
        surveyCreationService.refreshTargetingReqs();
        if (!$rootScope.survey.targetingReqs.length || surveyTargetingReqs === JSON.stringify($rootScope.survey.targetingReqs)) {
            return;
        }
        let t = _.find($rootScope.survey.targetingReqs, {
            attribute: 'country',
        });
        if (!t && $rootScope.survey.targetingReqs) {
            t = {
                attribute: 'country',
                details: {
                    type: 'US',
                    countries: [],
                },
            };
            $rootScope.survey.targetingReqs.push(t);
        }
        $scope.requirement = t;
        CountryEditorVM.currentCountries = _.filter(countryOptions.countries, country => {
            return _.includes($scope.requirement.details.countries, country.id);
        });
        surveyTargetingReqs = JSON.stringify($rootScope.survey.targetingReqs);
    }
}
InternationalModalCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'currentCountries',
];
/**
 * @param $rootScope
 * @param $scope
 * @param $uibModalInstance
 * @param currentCountries
 */
function InternationalModalCtrl($rootScope, $scope, $uibModalInstance, currentCountries) {
    var InternationalModalVM = this;
    InternationalModalVM.searchBar = {
        countries: [],
    };
    InternationalModalVM.auth = $rootScope.auth;
    InternationalModalVM.funnelType = $scope.funnelType;
    InternationalModalVM.countries = angular.copy(countryOptions.countries);
    InternationalModalVM.closeCountriesModal = closeCountriesModal;
    InternationalModalVM.countriesFilter = countriesFilter;
    InternationalModalVM.updateCurrentCountries = updateCurrentCountries;
    InternationalModalVM.updateCheckbox = updateCheckbox;
    InternationalModalVM.updateBySearchCriteria = updateBySearchCriteria;

    init();

    function init() {
        _.forEach(currentCountries, country => {
            _.find(InternationalModalVM.countries, {
                id: country.id,
            }).selected = true;
            InternationalModalVM.searchBar.countries.push(country);
        });
    }

    /**
     * @param toRemove
     * @param item
     */
    function updateBySearchCriteria(toRemove, item) {
        if (toRemove) {
            delete item.selected;
        }
        else {
            item.selected = true;
        }
    }

    /**
     * @param search
     */
    function countriesFilter(search) {
        const filter = {
            name: search,
        };
        if (!$rootScope.auth.user.isAdmin) {
            filter[$rootScope.funnelType] = true;
        }
        return filter;
    }

    /**
     * @param country
     */
    function updateCheckbox(country) {
        if (country.selected) {
            InternationalModalVM.searchBar.countries.push(country);
        }
        else {
            const idx = _.findIndex(InternationalModalVM.searchBar.countries, {
                id: country.id,
            });
            if (idx > -1) {
                InternationalModalVM.searchBar.countries.splice(idx, 1);
            }
        }
    }

    /**
     * @param arg
     */
    function closeCountriesModal(arg) {
        $uibModalInstance.close(arg);
    }

    function updateCurrentCountries() {
        if (InternationalModalVM.searchBar.countries < 1) {
            InternationalModalVM.countryError = true;
            return;
        }
        closeCountriesModal(InternationalModalVM.searchBar.countries);
    }
}
