import '../../../../common/js/inc/sv-notify';
import '../survey/survey-statuses';
import surveyConstants from '../survey/survey-constants';
import '../survey-creation/survey-creation-service';
import surveyCreationConstant from '../survey-creation/survey-creation-constant';
import '../question-types/question-type-groups';
import '../common-components/pub-sub';
import Config from '../config';

angular.module('surveyBuilder.view', [
    'ui.router',
    'svNotify',
    'pubSub',
    'apiSurveyService',
    'questionTypeGroups.service',
    'surveyCreation.service',
    'surveyStatusesService',
])
    .controller('SurveyBuilderCtrl', SurveyBuilderCtrl)
    .directive('surveyBuilder', surveyBuilder);
surveyBuilder.$inject = [
    '$rootScope',
    '$timeout',
    'surveyService',
];

/**
 * @param $rootScope
 * @param $timeout
 * @param surveyService
 */
function surveyBuilder($rootScope, $timeout, surveyService) {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/survey-builder.html',
        controller: 'SurveyBuilderCtrl',
        controllerAs: 'SurveyBuilderVM',
        scope: {
            activeQuestion: '=',
        },
        link: function(scope, _elem, _attr, SurveyBuilderVM) {
            // Whether to display the "Add question" menu as a drop-up or drop-down.
            SurveyBuilderVM.dropUp = false;
            SurveyBuilderVM.updateDropUp = updateDropUp;

            function updateDropUp() {
                // Display as a "drop-up" if the drop-up menu fits in the window.
                var $dropButton = $('.q-dropdown-btn'),
                    $dropMenu = $('.q-dropdown-menu');
                SurveyBuilderVM.dropUp = ($dropMenu.height() + 50) < $dropButton.offset().top;

                // Set an explicit width on the dropdown menu group names
                // so that group/attribute names align.
                var $groupNames = $('.dropdown-group-name'),
                    width = 0;
                _.forEach($groupNames, function(e) {
                    var w = $(e).width();
                    width = w > width ? w : width;
                });
                $groupNames.css('min-width', width.toFixed() + 'px');
            }

            SurveyBuilderVM.sortableOptions = {
                tolerance: 'pointer',
                cancel: '.not-sortable',
                start: function(e, _ui) {
                    $timeout(function() {
                        $(e.target).data('ui-sortable').floating = true;
                        SurveyBuilderVM.ux.isDragging = true;
                    });
                },
                stop: function(event, ui) {
                    var idx = ui.item.index();
                    var question = $rootScope.survey.questions[idx];

                    $timeout(function() {
                        SurveyBuilderVM.ux.isDragging = false;
                    }, 250);

                    // No-op of only one question in list
                    if ($rootScope.survey.questions.length === 1) {
                        return;
                    }

                    if (idx === 0) {
                        SurveyBuilderVM.ux.saveInProgress = true;
                        surveyService.moveQuestionBefore(question.uuid, $rootScope.survey.questions[1].uuid).then(function() {
                            SurveyBuilderVM.ux.saveInProgress = false;
                        }, function() {
                            SurveyBuilderVM.ux.saveInProgress = false;
                        });
                    }
                    else {
                        var referenceQuestion = $rootScope.survey.questions[idx - 1];
                        SurveyBuilderVM.ux.saveInProgress = true;
                        surveyService.moveQuestionAfter(question.uuid, referenceQuestion.uuid).then(function() {
                            SurveyBuilderVM.ux.saveInProgress = false;
                        }, function() {
                            SurveyBuilderVM.ux.saveInProgress = false;
                        });
                    }
                },
            };
        },
    };
}
SurveyBuilderCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$notify',
    'pubSubService',
    'questionTypeGroupsService',
    'surveyService',
    'surveyCreationService',
    'surveyStatusesService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $state
 * @param $notify
 * @param pubSubService
 * @param QTGS
 * @param surveyService
 * @param surveyCreationService
 * @param surveyStatusesService
 */
function SurveyBuilderCtrl($rootScope, $scope, $state, $notify, pubSubService, QTGS, surveyService, surveyCreationService, surveyStatusesService) {
    var SurveyBuilderVM = this,
        ERROR_MESSAGES = surveyCreationConstant.get('ERROR_MESSAGES'),
        EDIT_MSG = surveyCreationConstant.get('EDIT_MSG'),
        REORDER_MSG = surveyCreationConstant.get('REORDER_MSG');
    SurveyBuilderVM.ux = {
        reorderButtonMsg: surveyCreationConstant.get('REORDER_MSG'),
        questionListStyle: $rootScope.dashboardView ? 'ib-col-md-12 ib-col-xs-12' : 'ib-col-md-4 ib-col-xs-6',
        programmingMetadata: {},
    };

    SurveyBuilderVM.tagMap = surveyCreationConstant.get('TAG_MAP');
    SurveyBuilderVM.questionTypeName = QTGS.questionTypeName;
    SurveyBuilderVM.questionTypes = QTGS.types;
    SurveyBuilderVM.questionTypeGroups = QTGS.groupList;

    SurveyBuilderVM.select = select;
    SurveyBuilderVM.copy = copy;
    SurveyBuilderVM.remove = remove;
    SurveyBuilderVM.toggleReorder = toggleReorder;
    SurveyBuilderVM.toggleMetadata = toggleMetadata;
    SurveyBuilderVM.toggleRandomize = toggleRandomize;
    SurveyBuilderVM.changeType = changeType;
    SurveyBuilderVM.editJson = editJson;
    SurveyBuilderVM.convertToPackage = convertToPackage;
    SurveyBuilderVM.clickToEdit = clickToEdit;
    SurveyBuilderVM.getLogicTags = getLogicTags;

    SurveyBuilderVM.updateQuestionProgrammingMetadata = updateQuestionProgrammingMetadata;
    SurveyBuilderVM.resetQuestionProgrammingMetadata = resetQuestionProgrammingMetadata;

    SurveyBuilderVM.showReorderButton = showReorderButton;
    SurveyBuilderVM.showNoteSection = showNoteSection;
    SurveyBuilderVM.canCopyQuestion = canCopyQuestion;
    SurveyBuilderVM.canAddQuestion = surveyCreationService.canAddQuestion;
    SurveyBuilderVM.canEditSurveyBuilder = canEditSurveyBuilder;
    SurveyBuilderVM.isBasicSurvey = isBasicSurvey;
    SurveyBuilderVM.isExtendedSurvey = isExtendedSurvey;
    SurveyBuilderVM.surveyUnderAnalystReview = surveyUnderAnalystReview;

    init();

    function init() {
        SurveyBuilderVM.survey = $rootScope.survey;
        SurveyBuilderVM.dashboardView = $rootScope.dashboardView;
        SurveyBuilderVM.auth = $rootScope.auth;
        SurveyBuilderVM.funnelType = $rootScope.funnelType;
        _.forEach($rootScope.survey.questions, function(question) {
            resetQuestionProgrammingMetadata(question);
        });
        pubSubService.subscribe('update-is-dragging', $scope.$id, updateIsDragging);
        pubSubService.subscribe('update-save-in-progress', $scope.$id, updateSaveInProgress);
        pubSubService.subscribe('reset-programming-metadata', $scope.$id, resetQuestionProgrammingMetadata);
        pubSubService.subscribe('add-new-question', $scope.$id, function(question) {
            $scope.activeQuestion = question;
        });
        $scope.$on('$destroy', function() {
            pubSubService.destroy('update-save-in-progress', $scope.$id);
            pubSubService.destroy('update-is-dragging', $scope.$id);
            pubSubService.destroy('reset-programming-metadata', $scope.$id);
            pubSubService.destroy('add-new-question', $scope.$id);
        });
    }

    /**
     * @param bool
     */
    function updateSaveInProgress(bool) {
        SurveyBuilderVM.ux.saveInProgress = bool;
    }

    /**
     * @param bool
     */
    function updateIsDragging(bool) {
        SurveyBuilderVM.ux.isDragging = bool;
    }

    /**
     *
     */
    function showReorderButton() {
        // Var isAdmin = $rootScope.auth && $rootScope.auth.user && $rootScope.auth.user.isAdmin;
        return ($rootScope.auth.user.isAdmin || $rootScope.survey.clientEditable || !$rootScope.survey.reviewStarted) &&
            ($rootScope.survey.questions || []).length > 1;
    }

    /**
     * @param q
     * @param editing
     */
    function showNoteSection(q, editing) {
        return SurveyBuilderVM.ux.programmingMetadata[q.id].edit === editing && !SurveyBuilderVM.ux.isDragging && !SurveyBuilderVM.ux.reorderMode;
    }

    /**
     * @param q
     */
    function canCopyQuestion(q) {
        return (!$scope.activeQuestion || !$scope.activeQuestion.uuid) || surveyCreationService.canCopyQuestion(q);
    }

    /**
     *
     */
    function canEditSurveyBuilder() {
        return surveyStatusesService.isEditable($rootScope.survey);
    }

    /**
     *
     */
    function isBasicSurvey() {
        return surveyStatusesService.isBasicFlow() || ($rootScope.survey.surveyPackage === surveyConstants.PACKAGES.BASIC);
    }

    /**
     *
     */
    function isExtendedSurvey() {
        return surveyStatusesService.isExtendedFlow() || ($rootScope.survey.surveyPackage === surveyConstants.PACKAGES.EXTENDED);
    }

    /**
     *
     */
    function surveyUnderAnalystReview() {
        return !surveyStatusesService.isEditable($rootScope.survey) && $rootScope.survey.reviewStarted && !$rootScope.survey.approved;
    }

    // Select a question for editing
    /**
     * @param q
     */
    function select(q) {
        if (!$rootScope.survey.completed && !SurveyBuilderVM.ux.isDragging && !SurveyBuilderVM.ux.reorderMode &&
            (!$scope.activeQuestion || (q.uuid !== $scope.activeQuestion.uuid)) && canEditSurveyBuilder()) {
            $scope.activeQuestion = angular.copy(q);
            $scope.activeQuestion._config = SurveyBuilderVM.questionTypes[q.type];
            pubSubService.notify('select-active-json', [null]);
            pubSubService.notify('update-check-question-errors', [false]);
        }
    }
    // Copy: copy a question.
    /**
     * @param q
     */
    function copy(q) {
        if ($scope.activeQuestion) {
            return;
        }

        // Clear notifications to prevent undo of question delete on copy
        $notify.clearList();
        SurveyBuilderVM.ux.saveInProgress = true;
        surveyService.createQuestion($rootScope.survey, q, $rootScope.creativeSetNames).then(ques => {
            $scope.$evalAsync(() => {
                surveyService.moveQuestionAfter(ques.uuid, q.uuid).then(() => {
                    $scope.$evalAsync(() => {
                        var idx = $rootScope.survey.questions.indexOf(q);
                        $rootScope.survey.questions.splice(idx + 1, 0, ques);
                        SurveyBuilderVM.ux.saveInProgress = false;
                        updateQuestionCount();
                        resetQuestionProgrammingMetadata(ques);
                    });
                },
                function() {
                    // If move question server call fails, just add to end
                    $rootScope.survey.questions.push(ques);
                    SurveyBuilderVM.ux.saveInProgress = false;
                });
            });
        },
        function() {
            SurveyBuilderVM.ux.serverError = ERROR_MESSAGES.COPY_QUESTION_ERROR;
            SurveyBuilderVM.ux.saveInProgress = false;
        });
    }

    /**
     * @param uuid
     */
    function remove(uuid) {
        surveyCreationService.removeQuestion(uuid);
        if ($scope.activeQuestion && ($scope.activeQuestion.uuid === uuid)) {
            $scope.activeQuestion = null;
        }
    }

    function toggleReorder() {
        if (SurveyBuilderVM.ux.reorderMode) {
            SurveyBuilderVM.ux.reorderMode = false;
            SurveyBuilderVM.ux.reorderButtonMsg = REORDER_MSG;
        }
        else {
            SurveyBuilderVM.ux.reorderMode = true;
            SurveyBuilderVM.ux.reorderButtonMsg = EDIT_MSG;
        }
    }

    /**
     * @param idx
     */
    function toggleMetadata(idx) {
        SurveyBuilderVM.ux.programmingMetadata[idx].edit = !SurveyBuilderVM.ux.programmingMetadata[idx].edit;
    }

    // Toggle question randomization and update the question
    /**
     * @param q
     */
    function toggleRandomize(q) {
        q.randomize = !q.randomize;

        surveyService.updateQuestion(q.uuid, q, $rootScope.creativeSetNames).then(function() {}, function() {
            q.randomize = !q.randomize; // Since the change didn't save, revert back
            $notify.error('Error setting question randomization.');
        });
    }

    // Edit a question's raw json
    /**
     * @param q
     */
    function editJson(q) {
        var json = angular.copy(q);
        json.statement = JSON.stringify(json.statement);
        json.details = JSON.stringify(json.details);
        pubSubService.notify('select-active-json', json);
    }

    // Change the question type
    /**
     * @param question
     * @param newType
     */
    function changeType(question, newType) {
        question.type = newType.id;
        question.details = angular.copy(newType.initialDetails || {});
        select(question);
    }

    // Converting between basic, extended, and advanced surveys
    /**
     * @param sPackage
     */
    function convertToPackage(sPackage) {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        surveyService.updateSurvey($rootScope.survey.uuid, {
            advanced: sPackage === 'advanced',
            surveyPackage: sPackage,
        }).then(function(survey) {
            $rootScope.survey = U.extend($rootScope.survey, survey);
            $rootScope.survey.surveyPackage = sPackage;
            $rootScope.funnel = Config.funnelTypes[sPackage.toUpperCase()];
            $rootScope.funnelType = $rootScope.funnel.id;
            $state.go('design', surveyStatusesService.getRouteParams($rootScope.survey));
        });
    }

    /**
     * @param question
     */
    function clickToEdit(question) {
        SurveyBuilderVM.ux.programmingMetadata[question.id].edit = $rootScope.auth.user.isAdmin;
    }

    //Programming Meta Data functions
    /**
     * @param question
     */
    function resetQuestionProgrammingMetadata(question) {
        SurveyBuilderVM.ux.programmingMetadata[question.id] = {
            edit: false,
            data: question.programmingMetadata,
        };
    }

    /**
     * @param question
     * @param data
     */
    function updateQuestionProgrammingMetadata(question, data) {
        question.programmingMetadata = data;
        surveyService.updateQuestion(question.uuid, question, $rootScope.creativeSetNames).then(function() {
            SurveyBuilderVM.ux.programmingMetadata[question.id].edit = false;
        }, function(error) {
            $notify.warning('Note not saved' + error, {
                clear: true,
                timeout: 3000,
            });
        });
    }

    function updateQuestionCount() {
        QTGS.updateQuestionCount($rootScope.survey.questions, $rootScope.funnel);
    }

    // Determine what logic exists on this question
    /**
     * @param question
     */
    function getLogicTags(question) {
        var tags = [],
            tagTypes = {
                SKIP: 'skip',
                TRAP: 'trap',
                SCREENER: 'screener',
                SUPPRESSION: 'suppress',
                RELEASE: 'release',
                PIPING: 'piping',
                RANDOMIZED: 'randomized',
            },
            skipTagAdded = false,
            suppressionTagAdded = false;

        if (question.details.skipLogic && !skipTagAdded) {
            tags.push(tagTypes.SKIP);
            skipTagAdded = true;
        }
        if (question.screener) {
            tags.push(tagTypes.SCREENER);
        }
        else if (question.trap) {
            tags.push(tagTypes.TRAP);
        }
        if (question.details.suppressionLogic) {
            tags.push(tagTypes.SUPPRESSION);
            suppressionTagAdded = true;
        }
        if (question.details.releaseLogic) {
            tags.push(tagTypes.RELEASE);
        }
        if (question.details.pipingLogic) {
            tags.push(tagTypes.PIPING);
        }
        if (question.randomize) {
            tags.push(tagTypes.RANDOMIZED);
        }
        if (QTGS.isSingleSelect(question) && !(skipTagAdded && suppressionTagAdded)) {
            for (var i = 0; i < question.details.choices.length; i++) {
                if (question.details.choices[i].skip && !skipTagAdded) {
                    tags.push(tagTypes.SKIP);
                    skipTagAdded = true;
                    if (suppressionTagAdded) {
                        break;
                    }
                }
                if (question.details.choices[i].toSuppress && !suppressionTagAdded) {
                    tags.push(tagTypes.SUPPRESSION);
                    suppressionTagAdded = true;
                    if (skipTagAdded) {
                        break;
                    }
                }
            }
        }
        return tags;
    }
}
