import * as d3 from 'd3-selection';
import myChartUtils from './charts-utils';
import svgConstants from '../common-components/svg-constants';

angular.module('chart.axisLabelImages', [])
    .factory('AxisLabelImages', AxisLabelImages);
function AxisLabelImages() {
    const PX = 'px';
    const WIDTH = 'width';
    const HEIGHT = 'height';
    // NOTE: 5 spaces hacky, but works -- giving the user more mouse area to trigger hover effects on images
    var IMAGE_TEXT_HOVER_BUFFER_PLACEHOLDER = myChartUtils.getStringOfInvisibleWhitespaceCharsOfNlength(5),
        IMAGE_LOADING_GIF2 = svgConstants.imageLoadingGif;

    function AxisLabelImages() {
        var _chart,
            _plot,
            _group,
            _axisLabelsContainer,
            _axisLabels, // TODO: update with axis property
            _id = '_axisLabelImages__' + (Math.random() * 1e3); // Needed to generate random-enough id values;

        axisLabelImages.init = init;
        axisLabelImages.plot = plot;
        axisLabelImages.group = group;
        axisLabelImages.chart = chart;
        axisLabelImages.destroy = destroy;

        function init() {
            _chart.addEventListener(_chart.EVENTS.ANCHOR_COMPLETE, handleChartAnchorComplete, _id, true);
            _chart.addEventListener(_chart.EVENTS.RESIZE_COMPLETE, handleChartResizeComplete, _id);
            _chart.addEventListener(_chart.EVENTS.FLIP_AXIS_COMPLETE, handleChartFlipAxisComplete, _id);

            return axisLabelImages;
        }

        function destroy() {
            _chart.removeEventListener(_chart.EVENTS.Y_AXIS_ANCHOR_COMPLETE, _id);
            _chart.removeEventListener(_chart.EVENTS.X_AXIS_ANCHOR_COMPLETE, _id);
            _chart.removeEventListener(_chart.EVENTS.RESIZE_COMPLETE, _id);

            // Remove things from the DOM
            _axisLabels.each(function() {
                let label = d3.select(this);
                if (myChartUtils.isImage(label.data())) {
                    removeMouseEventListeners(label.select('image'));
                }
            });
            _group.destroy();
        }

        function plot(val) {
            if (!arguments.length) {
                return _plot;
            }
            _plot = val;

            return axisLabelImages;
        }

        function group(val) {
            if (!arguments.length) {
                return _group;
            }
            _group = val;

            return axisLabelImages;
        }

        function chart(val) {
            if (!arguments.length) {
                return _chart;
            }
            _chart = val;

            return axisLabelImages;
        }

        function handleChartAnchorComplete() {
            axisLabelImages();
        }

        function handleChartResizeComplete() {
            axisLabelImages();
        }

        function handleChartFlipAxisComplete() {
            axisLabelImages();
        }

        function getPlotCategoricAxisSelection() {
            return _group.parent().element().select('.category-axis');
        }

        function getPlotCategoricScale() {
            var categoricAxisKey = _plot._isVertical ? 'x' : 'y';
            return _plot[categoricAxisKey]().scale;
        }

        function imageMouseOver() {
            let el = _getTickLabelTarget(d3.event.target);
            if (el) {
                let targetLabel = d3.select(el);
                // NOTE: DOM sorting of tick labels since SVG 1.2 doens't have a notion of z-index
                _axisLabels.sort(function(a, _b) {
                    // We need to use .data() due to categoryFormatter
                    return a === targetLabel.data() ? 1 : -1;
                });
                targetLabel.attr('transform', _getTransform(targetLabel, d3.select(d3.event.target), -1));
            }
        }

        function imageMouseOut() {
            let el = _getTickLabelTarget(d3.event.target);
            if (el) {
                let targetLabel = d3.select(el);
                targetLabel.attr('transform', _getTransform(targetLabel, d3.select(d3.event.target), 1));
            }
        }

        // "maxMin" is +/- 1.
        function _getTransform(targetLabel, image, maxMin) {
            let translate = targetLabel.attr('transform').match(/translate\(([\-0-9\.]+),([\-0-9\.]+)\)/),
                scale = maxMin < 0 ? ' scale(2)' : '';
            if (translate) {
                let width = maxMin * (parseFloat(image.attr('x')) + parseFloat(image.attr(WIDTH)) / 2),
                    height = maxMin * (parseFloat(image.attr('y')) + parseFloat(image.attr(HEIGHT)) / 2),
                    x = parseFloat(translate[1]),
                    y = parseFloat(translate[2]);
                return 'translate(' + (x + width) + ',' + (y + height) + ')' + scale;
            }
            return targetLabel.attr('transform') + scale;
        }

        function _getTickLabelTarget(eventTarget) {
            if (eventTarget.tagName.toLowerCase() === 'image') {
                return eventTarget.parentElement;
            }
            else if (eventTarget.className.baseVal.indexOf('text-line') > -1) {
                // Not pretty, but we built the markup so it's slightly better
                return eventTarget.parentNode.parentNode.parentNode;
            }
        }

        function _attachMouseEventListeners(axisLabels, axisLabelsContainer) {
            if (axisLabelsContainer.attr('data-event-listeners-added') || window.mozPaintCount) { // NOTE: disabling svg transform origin in firefox is top|left of the page not it's svg parent done by -- window.mozPaintCount
                return; // NOTE: only add event listeners once
            }

            if (!axisLabels.empty() && axisLabelsContainer) {
                // IDEA: maybe switch to click instead of mouseover/mouseout to side step the hover and triggering issues -- behavior would match that of the Bootstrap POPOVER data-trigger="focus"
                axisLabelsContainer
                    .on('mouseover', imageMouseOver)
                    .on('mouseout', imageMouseOut);

                axisLabelsContainer.attr('data-event-listeners-added', true);
            }
        }

        function removeMouseEventListeners(axisLabelsContainer) {
            if (axisLabelsContainer.attr('data-event-listeners-added')) {
                // .off() is not supported for d3 v2.9.1, alternative is .on(event, null)
                axisLabelsContainer
                    .on('mouseover', null)
                    .on('mouseout', null);
            }
        }

        function _isCategoryAxesOnXAxis() {
            return _plot._isVertical;
        }

        function _getImageSourceURL(imgData) {
            // When an image return src attribute OTHERWISE return URL -- NOTE: added benefit of preloading the image by doing it this way
            return imgData.trim()[0] === '<' ? $(imgData).attr('src') : imgData;
        }

        function axisLabelImages() {
            // Caching selector
            // QUESTION: Should we store this as a property?
            var axisSelection = getPlotCategoricAxisSelection();

            // This assumes we only have one categoric axis... which should always be true
            // not true for heatmaps though
            _axisLabelsContainer = axisSelection.node();
            _axisLabels = axisSelection.selectAll('.tick-label');

            function getImageDimensions(widthHeightRatio) {
                var categoryScale = getPlotCategoricScale(),
                    categoryAxisElement = getPlotCategoricAxisSelection().node(),
                    categoryScaleOnXAxis = _isCategoryAxesOnXAxis(),
                    // Sizing image
                    dimensionsObj = {
                        height: 0,
                        width: 0,
                    },
                    widthHeight;

                // W/h == 1
                // find Math.min(availableHeight, availableWidth) and set both height and width to that value
                // QUESTION are we as large as we could be???
                if (widthHeightRatio === 1) {
                    if (categoryScaleOnXAxis) { // Category and thus the images on x-axis -- rangeBand refers to width available for image
                        if (categoryScale.rangeBand() < parseFloat(categoryAxisElement.clientHeight)) { // Must parseFloat on the height since the function is an alias to a DOM property which is stored as a string
                            // width is the limiting factor
                            widthHeight = categoryScale.rangeBand();
                        }
                        else {
                            // Height is limiting factor
                            widthHeight = parseFloat(categoryAxisElement.clientHeight);
                        }
                    }
                    // Category and thus images on y-axis -- rangeBand refers to height available for image
                    else if (categoryScale.rangeBand() < parseFloat(categoryAxisElement.clientWidth)) { // Must parseFloat on the width since the function is an alias to a DOM property which is stored as a string
                        // height is the limiting factor
                        widthHeight = categoryScale.rangeBand();
                    }
                    dimensionsObj.width = widthHeight;
                    dimensionsObj.height = widthHeight;
                }
                // W/h < 1 --- height is the limiting dimension
                // set height to maximum value available and calculate width
                // if calculatedWidth > maxWidth set width to maximum and recalcuate width otherwise set width to calculcatedWidth
                else if (widthHeightRatio < 1) {
                    if (categoryScaleOnXAxis) { // If category and thus the images on x-axis --> axis height (same height as bounding box for axis) gives available image height
                        dimensionsObj.height = parseFloat(categoryAxisElement.clientHeight);
                        dimensionsObj.width = dimensionsObj.height * widthHeightRatio;

                        // Ensure we have enough room for the calculated width
                        if (dimensionsObj.width > categoryScale.rangeBand()) {
                            dimensionsObj.width = categoryScale.rangeBand();
                            dimensionsObj.height = dimensionsObj.width / widthHeightRatio;
                        }
                    }
                    else { // Category and thus images on y-axis --> scale rangeBand refers to height available for image
                        dimensionsObj.height = categoryScale.rangeBand();
                        dimensionsObj.width = dimensionsObj.height * widthHeightRatio;

                        // Ensure we have enough room for the calculated width
                        if (dimensionsObj.width > categoryAxisElement.clientWidth) {
                            dimensionsObj.width = categoryAxisElement.clientWidth;
                            dimensionsObj.height = dimensionsObj.width / widthHeightRatio;
                        }
                    }
                }
                // W/h > 1 --- width is the limiting dimension
                // set width to maximum value available and calculate height
                // if calculatedHeight > maxHeight set width to maximum and recalcuate width otherwise set width to calculcatedHeight
                else if (categoryScaleOnXAxis) { // Category and thus the images on x-axis --> scale rangeBand refers to width available for image
                    dimensionsObj.width = categoryScale.rangeBand();
                    dimensionsObj.height = dimensionsObj.width / widthHeightRatio;

                    // Ensure we have enough room for the calculated height
                    if (dimensionsObj.height > parseFloat(categoryAxisElement.clientHeight)) {
                        dimensionsObj.height = parseFloat(categoryAxisElement.clientHeight);
                        dimensionsObj.width = dimensionsObj.height * widthHeightRatio;
                    }
                }
                else { // Category and thus images on y-axis --> axis width (same width as bounding box for axis) gives available image width
                    dimensionsObj.width = parseFloat(categoryAxisElement.clientWidth);
                    dimensionsObj.height = dimensionsObj.width / widthHeightRatio;

                    // Ensure we have enough room for the calculated height
                    if (dimensionsObj.height > categoryScale.rangeBand()) {
                        dimensionsObj.height = categoryScale.rangeBand();
                        dimensionsObj.width = dimensionsObj.height * widthHeightRatio;
                    }
                }

                return dimensionsObj;
            }

            _axisLabels.each(function() {
                var label = d3.select(this),
                    imgEl,
                    image,
                    maxHeight,
                    maxWidth,
                    categoryScaleOnXAxis = _isCategoryAxesOnXAxis(); // Needs to be a function because this could have changed between calls to the axisLabels
                if (categoryScaleOnXAxis) {
                    maxHeight = _axisLabelsContainer.clientHeight; // Need to reference the chart each iteration because category properties could have changed
                    maxWidth = _plot.x().scale.stepWidth();
                }
                else {
                    maxHeight = _plot.y().scale.stepWidth();
                    maxWidth = _axisLabelsContainer.clientWidth;
                }

                if (myChartUtils.isImage(label.data())) {
                    image = label.select('image');

                    if (image.empty()) {
                        // Adding class allowing us to hide the <text> used to make space for the image
                        label.classed('tick-label--has-image', true);

                        image = label.append('svg:image')
                            .attr('preserveAspectRatio', 'xMidYMid meet')
                            .attr('xlink:href', IMAGE_LOADING_GIF2)
                            .attr(WIDTH, function() {
                                return categoryScaleOnXAxis ? maxWidth + PX : '35px';
                            })
                            .attr(HEIGHT, function() {
                                return categoryScaleOnXAxis ? '20px' : maxHeight + PX;
                            })
                            .attr('x', function() {
                                if (!categoryScaleOnXAxis) {
                                    return (_axisLabelsContainer.clientWidth - maxWidth) + PX; // Push loading image closer to y-axis
                                }
                            });

                        // NOTE: using html image element to add load callbacks
                        imgEl = document.createElement('img');
                        imgEl.onload = function() {
                            var loadedImage = this,
                                widthHeightRatio = loadedImage.naturalWidth / loadedImage.naturalHeight,
                                imageDimensions;

                            image.attr('preserveAspectRatio', 'xMidYMin meet') // Centers image horizontally
                                .attr('xlink:href', loadedImage.src)
                                .classed('image--is-loading', false)
                                // .attr('style', 'opacity: 0') // NOTE: inline style for better renering control
                                .attr('widthHeightRatio', widthHeightRatio); // Store image width ratio IDEA would be nice to use this later to scale up the image

                            if (!categoryScaleOnXAxis) {
                                // NOTE: makes the hover area for the zoom easier to trigger
                                label.select('text')
                                    .html(IMAGE_TEXT_HOVER_BUFFER_PLACEHOLDER)
                                    .attr('text-anchor', 'start');
                            }

                            // Get image dimensions + size image
                            imageDimensions = getImageDimensions(widthHeightRatio);

                            image /*.attr('style', 'opacity: 0') */ // NOTE: for better display of images on window resize --- TODO: move this to a css class that is no frills opacity so that brower calculations aren't so taxing
                                .attr(WIDTH, imageDimensions.width + PX)
                                .attr(HEIGHT, imageDimensions.height + PX);

                            // Position + place image
                            if (categoryScaleOnXAxis) {
                                let xPos = (getPlotCategoricScale().stepWidth() - imageDimensions.width) / 2,
                                    yPos = 0;
                                image.attr('x', xPos + PX)
                                    .attr('y', yPos)
                                    .attr('preserveAspectRatio', 'xMidYMin meet')
                                    .style('transform-origin', xPos + PX + ' ' + yPos + PX);
                            }
                            else {
                                // Y-axis images are right aligned next to axis marker (baseline)
                                // using the y-axis baseline as the rightmost postion to start calculating the value of this image's x1 which we subtract:
                                //  - it's stroke width -- parseInt removes the unit PX
                                //  - calculated width of the image
                                //  - 2 x the categoryAxis's inner tick length (2x to give image enough space away from baseline)
                                let xPos = parseFloat(_axisLabelsContainer.clientWidth) - 20 - imageDimensions.width /* - categoryAxis.innerTickLength() * 2;*/,
                                    yPos = (getPlotCategoricScale().stepWidth() - imageDimensions.height) / 2;

                                image.attr('y', yPos + PX)
                                    .attr('x', xPos + PX)
                                    .attr('preserveAspectRatio', 'xMinYMid meet')
                                    .attr('transform-origin', xPos + PX + ' ' + yPos + PX);
                            }

                            // Image.attr('style', null); // IDEA: maybe handle case of window resize to keep images sizes in proportion to the chart

                            // NOTE: only add event listeners once -- currently borked 😞
                            _attachMouseEventListeners(_axisLabels, image);
                        };
                        imgEl.src = _getImageSourceURL(label.data()[0]); // Load the image

                        // trigger the load event for images that have been cached by the browser
                        if (imgEl.complete) {
                            imgEl.onload();
                        }

                        // Add property to axis to add event handler once
                        _axisLabelsContainer.setAttribute('data-has-images', true);
                    }
                }
            });
        } // End of axisLabelImages

        return axisLabelImages;
    }
    return AxisLabelImages;
}
