import regionOptions from './region/options';

/**
 * @param req
 */
function toString(req) {
    var names = '',
        countries = {};

    // Req details is a list of IDs
    _.forEach(req.details, function(id) {
        var country = regionOptions.countryMap[id];

        countries[country] = countries[country] || [];
        countries[country].push(regionOptions.map[id].name);
    });
    _.forEach(countries, function(country, c) {
        names += c + ':[' + country.sort().join(', ') + '] ';
    });
    return names;
}

/**
 * @param req
 */
function errors(req) {
    var errs = [],
        l = (req.details && req.details.length) || 0,
        maxOptions = 0;

    _.forEach(regionOptions.list, function(listItem) {
        maxOptions += listItem.length;
    });

    // Require at least one group to be selected.
    if (l === 0) {
        errs.push('No regions selected. Select at least one.');
    }

    // Require at least one group to be un-selected.
    if (maxOptions === l) {
        errs.push('All regions are selected. Unselect at least one.');
    }

    return errs;
}
const targetingAttributeRegion = {
    id: 'region',
    name: 'State',
    description: 'Filter by the respondent\'s US state',
    options: regionOptions.list,
    basic: true,
    extended: true,
    advanced: true,
    toString: toString,
    errors: errors,
};

export default targetingAttributeRegion;
