import U from '../../../../../../common/js/util';
import {
    convertParsedCriteria,
} from '../../../filter-and-compare/filter-service';
import '../../../filter-logic/filter-logic-editor';
import AnalysisConfigModel from '../analysis-config-model';
import analysisConfigConstants from '../analysis-config-constants';
import {
    convertFilterBy,
} from '../../../filter-and-compare/default-filter-service';

angular.module('factorForm', [
    'filterLogicEditor',
])
    .directive('factorForm', factorForm)
    .controller('FactorFormCtrl', FactorFormCtrl);

function factorForm() {
    return {
        restrict: 'AE',
        templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/factor-form.html',
        scope: {
            level: '=',
        },
        controller: 'FactorFormCtrl',
        controllerAs: 'FactorFormVM',
    };
}
FactorFormCtrl.$inject = [
    '$scope', '$rootScope',
];

function FactorFormCtrl($scope, $rootScope) {
    var FactorFormVM = this;
    const MAX_FACTOR_LEVEL_NAME_LENGTH = 255;
    FactorFormVM.ux = {
        factorLevelNameCharsRemaining: MAX_FACTOR_LEVEL_NAME_LENGTH,
        showFactorLevelNameCharsRemaining: false,
        factorLevelNameMaxLength: MAX_FACTOR_LEVEL_NAME_LENGTH,
    };
    FactorFormVM.factorLevel = $scope.level;
    FactorFormVM.filterModel = new AnalysisConfigModel($rootScope.survey);
    FactorFormVM.setDefinitionJson = setDefinitionJson;
    FactorFormVM.factorLevelTypes = analysisConfigConstants.factorLevelTypes;
    FactorFormVM.testTypes = analysisConfigConstants.testTypes;
    FactorFormVM.onFactorLevelNameChanged = onFactorLevelNameChanged;

    init();

    function init() {
        FactorFormVM.isTemporalFactor = FactorFormVM.factorLevel.name && FactorFormVM.factorLevel.name.match(/time_bin__\d+/);
        FactorFormVM.factorLevel.name = U.limitStrLength(FactorFormVM.factorLevel.name, MAX_FACTOR_LEVEL_NAME_LENGTH);
        onFactorLevelNameChanged();
        setDefinitionJson();
    }

    function onFactorLevelNameChanged() {
        FactorFormVM.ux.factorLevelNameCharsRemaining = MAX_FACTOR_LEVEL_NAME_LENGTH - FactorFormVM.factorLevel.name.length;
        FactorFormVM.ux.showFactorLevelNameCharsRemaining = FactorFormVM.ux.factorLevelNameCharsRemaining < 10;
    }

    function setDefinitionJson(parsedCriteria, definitionJson) {
        if (definitionJson) {
            const tryParse = convertFilterBy(definitionJson, []);
            if (tryParse) {
                FactorFormVM.factorLevel.parsedCriteria = tryParse;
                FactorFormVM.factorLevel.definitionJson = definitionJson;
            }
        }
        else {
            FactorFormVM.factorLevel.parsedCriteria = parsedCriteria || FactorFormVM.factorLevel.parsedCriteria;
            FactorFormVM.factorLevel.definitionJson = convertParsedCriteria(FactorFormVM.factorLevel.parsedCriteria, FactorFormVM.filterModel);
        }
    }
}
