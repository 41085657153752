import '../../common-components/nav-tabs';
import './analysis-config-manager';
import './analysis-config-service';
import analysisConfigDataService from './analysis-config-data-service';
import adMeasurementConstants from '../ad-measurement.constants';

angular.module('analysisPlan.view', [
    'ui.router',
    'analysisConfig.manager',
    'analysisConfig.service',
])
    .directive('analysisPlan', analysisPlan)
    .controller('AnalysisPlanCtrl', AnalysisPlanCtrl);

function analysisPlan() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan.html',
        controller: 'AnalysisPlanCtrl',
        controllerAs: 'AnalysisPlanVM',
    };
}

AnalysisPlanCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'analysisConfigManager',
    'analysisConfigService',
];

function AnalysisPlanCtrl($scope, $state, $stateParams, analysisConfigManager, analysisConfigService) {
    var AnalysisPlanVM = this;

    // VM exposed variables
    AnalysisPlanVM.views = _.values(adMeasurementConstants.analysisPlanViews);
    AnalysisPlanVM.tabOptions = {
        orientation: 'vertical',
        affixWidthContstraintSelector: '.c-content-container.c-content-container__inner .tab-pane.panel.active .row .col-sm-2',
    };
    AnalysisPlanVM.ux = {
        ready: false,
    };

    // VM exposed functions
    AnalysisPlanVM.createAnalysisConfiguration = createAnalysisConfiguration;

    init();

    function init() {
        const promise = new Promise(resolve => {
            analysisConfigDataService.listAnalysisConfigurations($stateParams.surveyId).then(configurations => {
                const config = configurations[0];
                if (config) {
                    analysisConfigService.getFullAnalysisConfiguration(config.uuid).then(response => {
                        setAnalysisConfig(response);
                        resolve();
                    }, resolve);
                }
                else {
                    resolve();
                }
            });
        });
        promise.then(() => {
            if ($state.current.name === 'analysisPlan') {
                $state.go('analysisPlanOverview');
            }
            $scope.$evalAsync(() => {
                AnalysisPlanVM.ux.ready = true;
            });
        });
    }

    function createAnalysisConfiguration() {
        analysisConfigDataService.createAnalysisConfiguration($stateParams.surveyId).then(init);
    }

    function setAnalysisConfig(config) {
        AnalysisPlanVM.analysisConfiguration = config;
        analysisConfigManager.set(config);
    }
}
