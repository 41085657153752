import Papa from 'papaparse';
import _ from 'lodash';
import filterLogicConstants from '../filter-logic/filter-logic-constants';
import getLogicPhraseById from '../filter-logic/filter-logic-phrases';
import AudienceConfigurationStepManager from './audience-configuration/audience-configuration-step-manager';
import adMeasurementConstants from './ad-measurement.constants';

let stepManager;
const adMeasurementService = {
    getStepManager,
    checkBeaconControlConfigurationForErrors,
    getDemographicsStrategyDescription,
    getBeaconControlConfigurationName,
    getAudienceConfigurationName,
    convertCsvFileToJson,
    getBeaconPoolTargetingReq,
    configNameExistsInList,
    setImpressionParameterGroups,
};

/**
 * Create the step manager
 *
 * @param {boolean} completed
 *
 * @returns {AudienceConfigurationStepManager} the step manager
 */
function getStepManager(completed) {
    if (arguments.length) {
        stepManager = new AudienceConfigurationStepManager(completed);
    }
    return stepManager;
}

/**
 * Check if config name exists in the list
 *
 * @param {object} config
 * @param {object[]} configList
 *
 * @returns {boolean} name is in list
 */
function configNameExistsInList(config, configList) {
    for (let i = 0; i < configList.length; i++) {
        if (configList[i].name === config.name && configList[i].uuid !== config.uuid) {
            return true;
        }
    }
}

/**
 * Check the creation step for errors
 *
 * @param {object} beaconControlConfiguration
 *
 * @returns {string | undefined} error message, if there was an error
 */
function checkErrorsForCreationStep(beaconControlConfiguration) {
    /**
     * Inner validator
     *
     * @returns {string | undefined} error message, if any
     */
    function validateControlPixel() {
        if (!beaconControlConfiguration.beaconPoolName) {
            return 'Please select a control pixel.';
        }
    }

    /**
     * Inner validator
     *
     * @returns {string | undefined} error message, if any
     */
    function validateDemographicControlType() {
        if (beaconControlConfiguration.demographicsStrategy === adMeasurementConstants.MATCHING) {
            for (let i = 0; i < adMeasurementConstants.demographicsStrategies.matching.options.length; i++) {
                let matchingItem = adMeasurementConstants.demographicsStrategies.matching.options[i];
                if (beaconControlConfiguration[matchingItem.type]) {
                    return;
                }
            }
            return 'Please select at least one demographic criteria to match';
        }
        else if (beaconControlConfiguration.demographicsStrategy === adMeasurementConstants.QUOTAS) {
            for (let i = 0; i < adMeasurementConstants.demographicsStrategies.quotas.options.length; i++) {
                let quotaType = adMeasurementConstants.demographicsStrategies.quotas.options[i];

                for (let j = 0; j < quotaType.options.length; j++) {
                    if (beaconControlConfiguration[quotaType.options[j].type]) {
                        return;
                    }
                }
            }
            return 'Please set a quota for at least one demographic criteria.';
        }
        else if (beaconControlConfiguration.controlType === adMeasurementConstants.DEMOGRAPHIC_ONLY) {
            return 'Please select a demographics strategy.';
        }
    }

    let errorMsg = null;
    if (beaconControlConfiguration.controlType === adMeasurementConstants.CONTROL_PIXEL) {
        errorMsg = validateControlPixel();
        if (errorMsg) {
            return errorMsg;
        }
    }
    errorMsg = validateDemographicControlType();
    if (errorMsg) {
        return errorMsg;
    }
    if (!beaconControlConfiguration.controlType) {
        return 'Please select a control type.';
    }
}

/**
 * Check review step for errors
 *
 * @param {object} beaconControlConfiguration
 * @param {object[]} beaconControlConfigurations
 *
 * @returns {string | undefined} error message, if there are errors
 */
function checkErrorsForReviewStep(beaconControlConfiguration, beaconControlConfigurations) {
    if (beaconControlConfiguration.name) {
        if (configNameExistsInList(beaconControlConfiguration, beaconControlConfigurations)) {
            return 'A configuration with this name already exists.';
        }
        return;
    }
    return 'Please give this configuration a name.';
}

/**
 * Check if the configuration has errors
 *
 * @param {string} currentStep
 * @param {object} beaconControlConfiguration
 * @param {object[]} beaconControlConfigurations
 *
 * @returns {string} error message
 */
function checkBeaconControlConfigurationForErrors(currentStep, beaconControlConfiguration, beaconControlConfigurations) {
    switch (currentStep) {
        case adMeasurementConstants.beaconControlConfigurationStepTypes.CREATE_CONTROL:
            return checkErrorsForCreationStep(beaconControlConfiguration);
        case adMeasurementConstants.beaconControlConfigurationStepTypes.REVIEW_CONTROL:
            return checkErrorsForReviewStep(beaconControlConfiguration, beaconControlConfigurations);
        default:
            break;
    }
}

/**
 * Get description of demographic strategies
 *
 * @param {object} beaconControlConfiguration
 *
 * @returns {string | undefined} description
 */
function getDemographicsStrategyDescription(beaconControlConfiguration) {
    if (!beaconControlConfiguration) {
        return;
    }
    if (beaconControlConfiguration.demographicsStrategy === adMeasurementConstants.MATCHING) {
        let matching = [];
        _.forEach(adMeasurementConstants.demographicsStrategies.matching.options, matchOption => {
            if (beaconControlConfiguration[matchOption.type]) {
                matching.push(matchOption.name);
            }
        });
        return 'Matching: ' + matching.join(', ');
    }
    else if (beaconControlConfiguration.demographicsStrategy === adMeasurementConstants.QUOTAS) {
        let quotas = [];
        _.forEach(adMeasurementConstants.demographicsStrategies.quotas.options, quotaCategory => {
            _.forEach(quotaCategory.options, quotaOption => {
                if (beaconControlConfiguration[quotaOption.type]) {
                    quotas.push(quotaOption.name + ' = ' + beaconControlConfiguration[quotaOption.type] + '%');
                }
            });
        });
        return 'Matching: ' + quotas.join(', ');
    }
    return 'None';
}

/**
 * Gets AudienceConfigurationName
 *
 * @param {object} audienceConfiguration
 * @param {object} survey
 * @param {string} controlName
 *
 * @returns {string} audience configuration name
 */
function getAudienceConfigurationName(audienceConfiguration, survey, controlName) {
    controlName = !controlName || controlName === adMeasurementConstants.NO_CONTROL ? 'No control selected' : controlName;
    if (audienceConfiguration.uuid) {
        return audienceConfiguration.name;
    }
    let name = survey.name + '-';
    if (audienceConfiguration.beaconDefinitions.length > 1) {
        name += audienceConfiguration.beaconDefinitions.length + ' exposure pixels';
    }
    else {
        name += audienceConfiguration.beaconDefinitions[0].exportName;
    }
    name += '-' + controlName + ' control';
    return name;
}

/**
 * Get beacon control config name
 *
 * @param {object} beaconControlConfiguration
 * @param {object} survey
 * @param {object} selectedPixel
 *
 * @returns {string} name of beacon control config
 */
function getBeaconControlConfigurationName(beaconControlConfiguration, survey, selectedPixel) {
    if (beaconControlConfiguration.uuid) {
        return beaconControlConfiguration.name;
    }
    let name = survey.name;
    if (selectedPixel) {
        name += '-' + selectedPixel.exportName + ' control';
    }
    if (beaconControlConfiguration.demographicsStrategy) {
        name += '-demographic ' + beaconControlConfiguration.demographicsStrategy;
    }
    return name;
}

/**
 * Given the contents of a CSV file, convert it into an array of JSON objects
 * with representing each row
 *
 * @param {string} file - CSV string with contents of file
 *
 * @returns {Promise} result promise
 */
function convertCsvFileToJson(file) {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject();
        }

        const onComplete = parsed => {
            let result = [],
                errors = {};

            if (!parsed || parsed.errors.length) {
                console.log('errors: ' + JSON.stringify(parsed.errors));
                reject(parsed.errors);
            }

            let keys = parsed.data[0];

            for (let i = 1; i < parsed.data.length; i++) {
                let currentRow = parsed.data[i],
                    item = {};

                // If the last row has 0 or 1 items, then it's an empty line so just ignore it
                if (i === parsed.data.length - 1 && currentRow.length < 2) {
                    break;
                }
                if (currentRow.length !== keys.length) {
                    errors[i] = 'Malformed on row ' + i + ' of ' + parsed.data.length + '. Should contain ' + keys.length + ' items but found ' + currentRow.length;
                }
                for (let j = 0; j < keys.length; j++) {
                    if (currentRow[j]) {
                        item[keys[j]] = currentRow[j];
                    }
                    for (let j = 0; j < keys.length; j++) {
                        if (currentRow[j]) {
                            item[keys[j]] = currentRow[j];
                        }
                    }
                }
                result.push(item);
            }
            resolve({
                data: result,
                errors: errors,
            });
        };

        const config = {
            delimiter: ',',
            complete: onComplete,
        };

        Papa.parse(file, config);
    });
}

/**
 * Find all the targeting recs that are beacon pool based.
 *
 * @param {object[]} targetingReqs
 *
 * @returns {object[]} array of beacon pool targeting recs
 */
function getBeaconPoolTargetingReq(targetingReqs) {
    return _.find(targetingReqs, req => {
        return req.attribute === adMeasurementConstants.BEACON_POOL_REQ;
    });
}

/**
 * Set the impression parameter groups
 *
 * @param {object} impressionParameterGroups
 *
 * @returns {object[]} impressionParameterGroupArray
 */
function setImpressionParameterGroups(impressionParameterGroups) {
    let impressionParameterGroupArray = [];

    _.forEach(impressionParameterGroups, (options, key) => {
        impressionParameterGroupArray.push({
            id: key,
            name: key,
            displayName: key.replace('ImpressionParameterGroups$', ''),
            isAvailable: true,
            options: _.map(options, option => {
                return {
                    name: option.name,
                    displayName: option.name.split(': ')[1],
                    id: `ImpressionParameterGroups$${option.name}`,
                };
            }),
            isImpressionParameterGroup: true,
            logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.IMPRESSION_PARAMETER_GROUPS),
        });
    });
    return impressionParameterGroupArray;
}

export default adMeasurementService;
