import webBrowserOptions from './options';

angular.module('webBrowser.editorView', [])
    .directive('webBrowserEditor', webBrowserEditor)
    .controller('WebBrowserEditorCtrl', WebBrowserEditorCtrl);

function webBrowserEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/web-browser-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, WebBrowserEditorVM) {
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('WebBrowserEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);
            updateOptionsObjectFromReqDetailsArray();

            function updateOptionsObjectFromReqDetailsArray() {
                _.forEach(WebBrowserEditorVM.options, function(e) {
                    e.selected = !!scope.requirement.details && scope.requirement.details.indexOf(e.id) !== -1;
                });
            }

            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];
                _.forEach(WebBrowserEditorVM.options, function(e) {
                    if (e.selected) {
                        x.push(e.id);
                    }
                });
                scope.requirement.details = x;
            }
        },
        controller: 'WebBrowserEditorCtrl',
        controllerAs: 'WebBrowserEditorVM',
    };
}
WebBrowserEditorCtrl.$inject = [
    '$scope',
];

function WebBrowserEditorCtrl($scope) {
    var WebBrowserEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    WebBrowserEditorVM.setAll = setAll;
    WebBrowserEditorVM.options = angular.copy(webBrowserOptions.list);

    function setAll(val) {
        angular.forEach(WebBrowserEditorVM.options, function(e) {
            e.selected = val;
        });
    }
}
