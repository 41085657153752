
    
    angular.module('svViewlet', [])
        .directive('svViewlet', ['$compile',
            '$controller',
            '$animate',
            function($compile, $controller, $animate) {
                return {
                    restrict: 'E',
                    transclude: 'element',
                    compile: function(element, attr, linker) {
                        return function(scope, $element, attr) {
                            var currentScope, currentElement;

                            update();
                            if (attr.updateTrigger) {
                                scope.$watchCollection('[' + attr.updateTrigger + ',' + attr.view + ']', function() {
                                    update(scope.$eval(attr.view));
                                });
                            }
                            else {
                                scope.$watch(attr.view, update);
                            }

                            function cleanupLast() {
                                if (currentScope) {
                                    currentScope.$destroy();
                                    currentScope = null;
                                }
                                if (currentElement) {
                                    $animate.leave(currentElement);
                                    currentElement = null;
                                }
                            }

                            function update(view) {
                                cleanupLast();
                                if (!view) {
                                    return;
                                }

                                var newScope = scope.$new();
                                linker(newScope, function(clone) {
                                    cleanupLast();

                                    clone.html(view.template);
                                    $animate.enter(clone, null, $element);

                                    currentScope = newScope;
                                    currentElement = clone;

                                    if (view.controller) {
                                        controller = $controller(view.controller, {
                                            $scope: currentScope,
                                        });
                                        clone.data('$ngControllerController', view.controller);
                                        clone.children().data('$ngControllerController', view.controller);
                                    }

                                    $compile(clone.contents())(currentScope);
                                });
                            }
                        };
                    },
                };
            }]);

