import targetingAttributeDevice from './device';
import './operating-system';
import targetingAttributeWebBrowser from './web-browser';
import './beacon-pool';
import targetingAttributeTrackingMethod from './tracking-method';

angular.module('targetingAttributeGroup.technography', [
    'operatingSystem', 'beaconPool',
])
    .service('technography', technography);
technography.$inject = [
    'targetingAttributeOperatingSystem', 'targetingAttributeBeaconPool',
];

function technography(targetingAttributeOperatingSystem, targetingAttributeBeaconPool) {
    return {
        id: 'technography',
        name: 'Technography',
        basic: true,
        extended: true,
        advanced: false,
        attributeList: [
            targetingAttributeDevice,
            targetingAttributeOperatingSystem,
            targetingAttributeWebBrowser,
            targetingAttributeBeaconPool,
            targetingAttributeTrackingMethod,
        ],
    };
}
