import './reset-password';
import './reset-password-ctrl';
import './sign-in';
import './sign-up';
import './sign-up-compact-view';
angular.module('AuthModule', [
    'signIn.ctrl',
    'signUp.ctrl',
    'signUp.compact',
    'resetPassword.controller',
    'resetPassword.ctrl',
]);
