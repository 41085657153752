import responseService from '../app-components/api-response-service';
import '../survey/api-survey-service';
import '../common-components/sv-filters';
import '../common-components/sv-loading-spinner';
import '../common-components/sv-clipboard';
angular.module('ResponseAdminModule', [
    'apiSurveyService',
    'svFilters',
    'svLoadingSpinner',
    'svClipboard',
])
    .controller('responseListCtrl', responseListCtrl);
responseListCtrl.$inject = [
    '$scope',
    'surveyService',
    '$timeout',
    '$notify',
];
function responseListCtrl($scope, surveyService, $timeout, $notify) {
    const ITEMS_PER_PAGE = 200;
    $scope.ResponseStatuses = responseService.listResponseStatuses();
    $scope.RejectionReasons = responseService.listRejectionReasons();
    $scope.ExportTypes = responseService.listExportTypes();
    $scope.surveys = [];
    $scope.responses = [];
    $scope.collectionPeriods = [];
    $scope.statuses = [$scope.ResponseStatuses.PENDING,
        $scope.ResponseStatuses.ACCEPTED,
        $scope.ResponseStatuses.REJECTED];
    $scope.sort = {
        criteria: null,
        reverse: true,
    };
    $scope.ux = {
        ready: false,
        currentSurvey: null,
        responseStatus: $scope.ResponseStatuses.PENDING,
        loadingResponses: false,
        updatingResponse: false,
        approvingRemaining: false,
        massIdList: '',
        massIdAction: null,
        massUpdating: 0,
        generatedFile: {
            type: null,
            url: null,
        },
        exportsDisabled: false,
        exportRequest: {
            exportType: $scope.ExportTypes.EXCEL,
        },
    };
    $scope.pages = {
        current: 0,
    };

    $scope.changePage = function(page) {
        if (page < 0) {
            return;
        }
        $scope.pages.current = page;
        $scope.refreshResponseList();
    };

    $scope.refreshResponseList = function refreshResponseList() {
        if ($scope.ux.loadingResponses) {
            $timeout(function() {
                $scope.refreshResponseList();
            }, 100);
        }
        else {
            $scope.refreshResponses();
        }
    };

    $scope.refreshResponses = function() {
        $scope.ux.loadingResponses = true;
        $scope.responses = [];

        var params = {
            max: ITEMS_PER_PAGE,
            offset: $scope.pages.current * ITEMS_PER_PAGE,
            status: $scope.ux.responseStatus,
        };

        responseService.listResponses($scope.ux.currentSurvey.uuid, params).then(function(responses) {
            surveyService.listPeriods($scope.ux.currentSurvey.uuid).then(function(periods) {
                $scope.collectionPeriods = periods;
            });
            $scope.responses = responses;
            var lastScreener = -1;
            if (responses[0] && responses[0].answers) {
                for (var i = 0; i < responses[0].answers.length; i++) {
                    var answer = responses[0].answers[i];
                    if (answer.question.screener) {
                        lastScreener = i;
                    }
                    else {
                        break;
                    }
                }
            }
            $scope.lastScreenerIdx = lastScreener;
            loadingResponses();
        }, loadingResponses);
    };

    function loadingResponses() {
        $scope.$evalAsync(() => {
            $scope.ux.loadingResponses = false;
        });
    }

    $scope.getAnswerColumnLabel = function(index) {
        if (index > $scope.lastScreenerIdx) {
            return 'Answer ' + (index - $scope.lastScreenerIdx);
        }
        return 'Screener Answer ' + (index + 1);
    };

    $scope.isAccepted = function(response) {
        if (response.responseStatus == $scope.ResponseStatuses.ACCEPTED) {
            return true;
        }
        return false;
    };

    $scope.isRejected = function(response) {
        if (response.responseStatus == $scope.ResponseStatuses.REJECTED) {
            return true;
        }
        return false;
    };

    $scope.isFreeResponse = function(question) {
        if (question.type == 'longFreeResponse' || question.type == 'freeResponse' || question.type == 'numeric') {
            return true;
        }
        return false;
    };

    var isGrid = function(question) {
        return question.type === 'grid';
    };

    $scope.isStraightLining = function(answer) {
        if (!isGrid(answer.question)) {
            return false;
        }
        var selections = answer.response.selected;
        if (selections.length > 1) {
            var selected = selections[0];
            for (var i = 1; i < selections.length; i++) {
                if (Array.isArray(selections[i])) {
                    if (selected.length != selections[i].length || selected.length != U.arrayIntersection(selected, selections[i]).length) {
                        return false;
                    }
                }
                else if (selections[i] != selected) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };

    $scope.isOpenEnded = function(answer) {
        return answer.response.otherSpecify || $scope.isFreeResponse(answer.question);
    };

    $scope.updateStatusByUuid = function(surveyResponseUuid, status, rejectionReason) {
        $scope.ux.updatingResponse = true;
        responseService.setStatus(surveyResponseUuid, status, rejectionReason).then(function(response) {
            $scope.$evalAsync(() => {
                $scope.ux.updatingResponse = false;
                $scope.ux.massUpdating--;
            });
        },
        function(error) {
            $scope.$evalAsync(() => {
                $scope.ux.updatingResponse = false;
                $scope.ux.massUpdating--;
            });
        });
    };

    $scope.updateStatus = function(surveyResponse, status, rejectionReason) {
        surveyResponse._locked = true;
        $scope.ux.updatingResponse = true;
        responseService.setStatus(surveyResponse.uuid, status, rejectionReason).then(function(response) {
            $scope.$evalAsync(() => {
                surveyResponse.responseStatus = response.responseStatus;
                surveyResponse.rejectionReason = response.rejectionReason;
                $scope.ux.updatingResponse = false;
                surveyResponse._locked = false;
            });
        },
        function(error) {
            $scope.$evalAsync(() => {
                surveyResponse._locked = false;
                $scope.ux.updatingResponse = false;
            });
        });
    };

    $scope.blockingUpdate = function(surveyResponseUuid, status, rejectionReason) {
        if ($scope.ux.updatingResponse) {
            $timeout(function() {
                $scope.blockingUpdate(surveyResponseUuid, status, rejectionReason);
            }, 100);
        }
        else {
            $scope.updateStatusByUuid(surveyResponseUuid, status, rejectionReason);
        }
    };

    $scope.reject = function(surveyResponse) {
        $scope.updateStatus(surveyResponse, $scope.ResponseStatuses.REJECTED, $scope.RejectionReasons.MANUAL);
    };

    $scope.accept = function(surveyResponse) {
        $scope.updateStatus(surveyResponse, $scope.ResponseStatuses.ACCEPTED);
    };

    $scope.massUpdate = function(massIdList, massIdAction) {
        $scope.ux.massUpdating = 0;
        var ids = massIdList.split('\n');
        var reason = massIdAction == $scope.ResponseStatuses.REJECTED ? $scope.RejectionReasons.MANUAL : null;
        for (var i = 0; i < ids.length; i++) {
            $scope.ux.massUpdating++;
            $scope.blockingUpdate(ids[i], massIdAction, reason);
        }
        $scope.ux.massIdAction = null;
    };

    $scope.massDone = function() {
        $scope.ux.massIdList = '';
        $scope.ux.massIdAction = null;
        $scope.refreshResponses();
    };

    $scope.blockingAccept = function(surveyResponse, callback) {
        if ($scope.ux.updatingResponse) {
            $timeout(function() {
                $scope.blockingAccept(surveyResponse, callback || null);
            }, 100);
        }
        else {
            $scope.accept(surveyResponse);
            if (callback) {
                callback();
            }
        }
    };

    $scope.acceptAllPending = function(responses) {
        var acceptAllPendingRun = function() {
            $timeout(function() {
                $scope.ux.approvingRemaining = true;
            }, 1);
            angular.forEach(responses, function(surveyResponse, idx) {
                if (surveyResponse.responseStatus == $scope.ResponseStatuses.PENDING) {
                    if (idx < responses.length - 1) {
                        $scope.blockingAccept(surveyResponse);
                    }
                    else {
                        $scope.blockingAccept(surveyResponse, function() {
                            $timeout(function() {
                                $scope.ux.approvingRemaining = false;
                            }, 1);
                        });
                    }
                }
            });
        };

        $notify.success('Accepting will start in 3 seconds...', {
            button: {
                text: 'Cancel',
                callback: function() {

                },
            },
            timeout: 3000,
            onTimeout: acceptAllPendingRun,
            onDismiss: acceptAllPendingRun,
        });
    };

    $scope.answerText = function(answer) {
        if (answer.skipped) {
            return 'SKIPPED';
        }
        else if (answer.question.type == 'grid') {
            var result = '<table>';
            angular.forEach(answer.question.details.rowStatements, function(statement, index) {
                result = result + '<tr><td>' + statement.text + '</td><td>';
                var selected = answer.response.selected[index];
                if (answer.question.details.gridType == 'single') {
                    for (var i = 0; i < answer.response.choices.length; i++) {
                        if (answer.response.choices[i].value == selected) {
                            var val = answer.response.choices[i].text;
                            if (!val) {
                                val = answer.response.choices[i].image;
                            }
                            result = result + val + '</td></tr> ';
                        }
                    }
                }
                else if (answer.question.details.gridType == 'multi') {
                    for (var i = 0; i < answer.response.choices.length; i++) {
                        if (selected.indexOf(answer.response.choices[i].value) > -1) {
                            var val = answer.response.choices[i].text;
                            if (!val) {
                                val = answer.response.choices[i].image;
                            }
                            result = result + val + ', ';
                        }
                    }
                    result += '</td></tr> ';
                }
                else if (answer.question.details.gridType == 'numeric') {
                    result = result + selected + '</td></tr> ';
                }
            });
            return result + '</table>';
        }
        else if (answer.question.type == 'singleSelect') {
            if (answer.response.otherSpecify) {
                return 'Other: ' + answer.response.otherSpecify;
            }
            var selected = answer.response.selected;
            for (var i = 0; i < answer.response.choices.length; i++) {
                if (answer.response.choices[i].value == selected) {
                    var val = answer.response.choices[i].text;
                    if (!val) {
                        val = answer.response.choices[i].image;
                    }
                    return val;
                }
            }
        }
        else if (answer.question.type == 'multiSelect') {
            var results = [];
            var selected = answer.response.selected || [];
            for (var i = 0; i < answer.response.choices.length; i++) {
                if (selected.indexOf(answer.response.choices[i].value) > -1) {
                    var val = answer.response.choices[i].text;
                    if (!val) {
                        val = answer.response.choices[i].image;
                    }
                    results.push(val);
                }
            }
            if (answer.response.otherSpecify) {
                results.push('Other: ' + answer.response.otherSpecify);
            }
            return '[' + results.join(', ') + ']';
        }
        else if (answer.question.type == 'rating') {
            return answer.response.rating + '/' + answer.response.possible;
        }
        else if (answer.question.type == 'longFreeResponse' || answer.question.type == 'freeResponse') {
            return answer.response.text;
        }
        else if (answer.question.type == 'numeric') {
            return '' + answer.response.value;
        }
        else if (answer.question.type == 'ranking') {
            var results = [];
            var selected = answer.response.ranked;
            for (var i = 0; i < selected.length; i++) {
                for (var j = 0; j < answer.response.choices.length; j++) {
                    if (answer.response.choices[j].value == selected[i]) {
                        results.push(answer.response.choices[j].text || answer.response.choices[j].image);
                        break;
                    }
                }
            }
            return '[' + results.join(', ') + ']';
        }
        else if (answer.question.type == 'clickmap') {
            var results = [];
            var clicks = answer.response.clicks;
            var hasZones = answer.question.details.zones && answer.question.details.zones.length;
            for (var i = 0; i < clicks.length; i++) {
                results.push(hasZones ? 'zone: ' + clicks[i].zone : 'x: ' + Math.floor(clicks[i].x) + ' y: ' + Math.floor(clicks[i].y));
            }
            return '[' + results.join(';<br>') + ']';
        }
        else {
            return 'NO DISPLAY';
        }
    };

    $scope.getBeaconInfo = function(response) {
        if (response.beaconEvent) {
            return response.beaconEvent.beaconDefinition.exportName;
        }
        else if (response.beaconPoolEvents) {
            var poolEvents = [];
            for (var i = 0; i < response.beaconPoolEvents.length; i++) {
                poolEvents.push(response.beaconPoolEvents[i].beaconPoolName);
            }
            return poolEvents.join(' | ');
        }
        return '';
    };

    var showFileModal = function() {
        $('#generated-file-modal').modal('show');
    };

    $scope.getTermReport = function() {
        responseService.createTermReport($scope.ux.currentSurvey.uuid).then(function(response) {
            $scope.$evalAsync(() => {
                $scope.ux.generatedFile.type = 'Term report';
                $scope.ux.generatedFile.url = response.url;
                showFileModal();
            });
        },
        function(error) {
            $notify.error('Error generating term report.');
        });
    };

    var requestExport = function(params) {
        responseService.requestExport($scope.ux.currentSurvey.uuid, params).then(function(response) {
            $scope.$evalAsync(() => {
                $notify.success('Request processed. You will receive an email with the export when it is completed.');
                $scope.ux.exportsDisabled = false;
            });
        },
        function() {
            $scope.$evalAsync(() => {
                $notify.error('Error requesting ' + params.exportType + ' export.');
                $scope.ux.exportsDisabled = false;
            });
        });
    };

    $scope.requestExport = function() {
        $scope.ux.exportsDisabled = true;
        requestExport($scope.ux.exportRequest);
    };

    $scope.requestPendingsExport = function() {
        $scope.ux.exportsDisabled = true;
        var params = {
            requestExport: $scope.ExportTypes.EXCEL,
            exportPending: true,
        };
        requestExport(params);
    };

    $scope.sortBy = function(criteria) {
        if ($scope.sort.criteria === criteria) {
            $scope.sort.reverse = !$scope.sort.reverse;
        }
        else {
            $scope.sort.criteria = criteria;
        }
    };

    // If question.position doesn't exist, this is a screener, so just sort it by its existing order
    // otherwise, order by position (+100, as a buffer between screeners)
    $scope.answerOrderBy = function() {
        var screenerIdx = 0;

        return function(answer) {
            return answer.question.position != undefined ? answer.question.position + 100 : screenerIdx++;
        };
    };

    $scope.setCurrentSurvey = function() {
        var url = U.Url();
        var surveyUuid = url.param('surveyUuid');
        if (surveyUuid) {
            surveyService.getSurvey(surveyUuid, false).then(function(response) {
                surveyService.getAudience(response.uuid).then(function(audience) {
                    angular.forEach(audience.targetingReqs, function(req) {
                        if (req.attribute === 'beacon' || req.attribute === 'beaconPool') {
                            response._hasBeacon = true;
                        }
                    });
                    $scope.ux.currentSurvey = response;
                    $scope.refreshResponses();
                    $scope.ux.ready = true;
                });
            });
        }
    };

    $scope.setCurrentSurvey();
}
