angular.module('rating.preview', [])
    .directive('ratingPreview', ratingPreview);

function ratingPreview() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/rating-preview.html',
        scope: {
            question: '=',
        },
        controller: 'RatingCtrl',
        controllerAs: 'RatingVM',
    };
}
