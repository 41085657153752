import '../app-components/api-account-service';
import authService from './auth-service';

angular.module('resetPassword.controller', ['apiAccountService'])
    .controller('ResetPasswordController', ResetPasswordController);
ResetPasswordController.$inject = [
    '$scope',
    '$timeout',
    'accountService',
];

function ResetPasswordController($scope, $timeout, accountService) {
    $scope.localux = {
        email: authService.get('email'),
        status: '',
    };

    $scope.sendPasswordResetEmail = sendPasswordResetEmail;
    $scope.disableSubmitButton = disableSubmitButton;
    $scope.syncEmail = syncEmail;

    function sendPasswordResetEmail() {
        $scope.localux.status = 'submitting';
        accountService.sendPasswordResetEmail($scope.localux.email);
        $timeout(function() {
            $scope.localux.status = 'success';
        }, 1000);
    }

    // Disable submit button if in progress or if form does not have a valid email
    function disableSubmitButton() {
        return $scope.localux.status || !$scope.localux.email || !U.validEmail($scope.localux.email);
    }

    // Sync the local email address with the other sign in/sign up
    function syncEmail() {
        authService.sync('email', $scope.localux.email);
    }
}
