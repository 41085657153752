import '../../beacons/api-beacon-service';
import '../ad-measurement.api-service';
import adMeasurementConstants from '../ad-measurement.constants';

angular.module('impressionReportingService', [
    'apiBeaconService', 'adMeasurement.apiService',
])
    .service('impressionReportingService', impressionReportingService);
impressionReportingService.$inject = [
    '$q',
    'beaconService',
    'adMeasurementApiService',
];

function impressionReportingService($q, beaconService, adMeasurementApiService) {
    const MAX_DISPLAY_NAME_LENGTH = 40;
    const IMPRESSION_PARAMETER_MAP = getImpressionParameterMap();

    function getImpressionParameterMap() {
        let map = {};
        _.forEach(adMeasurementConstants.impressionCountTypes, ip => {
            if (ip.subViews) {
                _.forEach(ip.subViews, subIp => {
                    map[subIp.view] = subIp;
                });
            }
            else {
                map[ip.view] = ip;
            }
        });
        return map;
    }

    function getBeaconImpressionCounts(beaconPoolBeacons, dateRange) {
        _.forEach(beaconPoolBeacons, bpb => {
            bpb.params = [];

            adMeasurementApiService.getBeaconImpressionCount(bpb.uuid, dateRange).then(response => {
                bpb.impressionCount = response.count;
            });
            beaconService.getBeaconImpressionParameters(bpb.uuid).then(response => {
                let impressionParameterGroups = {};
                _.forEach(response, impressionParam => {
                    if (!impressionParam || !impressionParam.impressionParameter) {
                        return;
                    }
                    const ip = IMPRESSION_PARAMETER_MAP[impressionParam.impressionParameter.name];
                    impressionParameterGroups[ip.value || ip.name] = ip;
                    ip.displayable = true;
                });
                bpb.paramString = ''; //Set to empty, used in html
                bpb.params = _.values(impressionParameterGroups);
            });
        });
    }

    function getImpressionGroupImpressionCounts(group, dateRange) {
        return new Promise((resolve, reject) => {
            const promises = [];
            group.impressionCount = 0;
            _.forEach(group.parameters, ipg => {
                const promise = adMeasurementApiService.getImpressionGroupCount(ipg.uuid, dateRange).then(response => {
                    ipg.impressionCount = response.count;
                    group.impressionCount += response.count;
                });
                promises.push(promise);
            });
            Promise.all(promises).then(resolve, reject);
        });
    }

    function getImpressionGroupBeacons(impressionGroups) {
        const MAX_UNRESOLVED = 15;
        let promises = [],
            idx = 0,
            numResolved = 0;
        _.forEach(impressionGroups, group => {
            let beaconDefinitions = {};
            group.impressionCount = 0;
            group.paramString = '';
            group.tag = group.tag || [];
            _.forEach(group.parameters, ipg => {
                if (!ipg.beaconDataFetched) {
                    let promiseIdx = Math.floor(idx / MAX_UNRESOLVED);
                    ipg.beaconDataFetched = true;
                    if (!(idx % MAX_UNRESOLVED)) {
                        promises.push($q.defer());
                    }
                    if (idx + 1 === MAX_UNRESOLVED) {
                        promises[promiseIdx].resolve();
                    }
                    promises[promiseIdx].promise.then(() => {
                        adMeasurementApiService.listImpressionParameterValueGroup(ipg.uuid).then(response => {
                            numResolved++;
                            _.forEach(response, ipvg => {
                                let beaconDefinition = ipvg.impressionParameterValue.beaconDefinition;
                                if (beaconDefinition && !beaconDefinitions[beaconDefinition.uuid]) {
                                    beaconDefinitions[beaconDefinition.uuid] = true;
                                    setDisplayableValues(beaconDefinition, group.paramString);
                                    group.paramString += beaconDefinition.displayName + ' ';
                                    group.tag.push(beaconDefinition);
                                }
                            });
                            if (!(numResolved % MAX_UNRESOLVED) && promiseIdx + 1 < promises.length) {
                                promises[promiseIdx + 1].resolve();
                            }
                        });
                    });
                }
                idx++;
            });
        });
    }

    function setDisplayableValues(object, stringSoFar) {
        let name = object.exportName || object.name;
        object.name = name;
        object.displayName = name.slice(0, MAX_DISPLAY_NAME_LENGTH) + (name.length > MAX_DISPLAY_NAME_LENGTH ? '...' : '');
        object.displayable = (stringSoFar + ' ' + name).length < 80;
    }

    function getParameterImpressionCounts(surveyUuid, dateRange, values) {
        return adMeasurementApiService.getImpressionDaySummary(surveyUuid, dateRange).then(response => {
            return _.map(response, summary => {
                let impressionParameterValue = values.get(summary.impressionParameterValue.value);
                if (!impressionParameterValue) {
                    values.set(summary.impressionParameterValue.value, summary.impressionParameterValue);
                    impressionParameterValue = summary.impressionParameterValue;
                }
                impressionParameterValue.impressionCount = summary.impressionCount;
                return impressionParameterValue;
            });
        });
    }

    function getImpressionGroupsByImpressionValues(surveyUuid, impressions, values) {
        _.forEach(impressions, impressionParameterValue => {
            const value = values.get(impressionParameterValue.value);
            if (value) {
                impressionParameterValue.params = value.params;
                impressionParameterValue.paramString = value.paramString;
            }
            else {
                values.set(impressionParameterValue.value, impressionParameterValue);
                adMeasurementApiService.getImpressionGroupByImpressionValue(surveyUuid, impressionParameterValue.value).then(response => {
                    let paramString = '';
                    impressionParameterValue.params = _.orderBy(response, [ipg => {
                        setDisplayableValues(ipg, paramString);
                        paramString += ipg.displayName + ' ';
                        return (ipg.name || '').length;
                    }], ['asc']);
                    impressionParameterValue.paramString = paramString;
                });
            }
        });
    }

    return {
        IMPRESSION_PARAMETER_MAP: IMPRESSION_PARAMETER_MAP,
        getBeaconImpressionCounts: getBeaconImpressionCounts,
        getParameterImpressionCounts: getParameterImpressionCounts,
        getImpressionGroupImpressionCounts: getImpressionGroupImpressionCounts,
        getImpressionGroupsByImpressionValues: getImpressionGroupsByImpressionValues,
        getImpressionGroupBeacons: getImpressionGroupBeacons,
    };
}
