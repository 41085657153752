import _ from 'lodash';
import '../common-components/pub-sub';
import FilterLogicModel from '../filter-logic/filter-logic-model';
import {
    getModelById,
} from './default-filter-service';
angular.module('filterPanel.view', ['pubSub'])
    .controller('FilterPanelCtrl', FilterPanelCtrl)
    .directive('filterPanel', filterPanelDirective);

function filterPanelDirective() {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/filter-and-compare/filter-panel.html',
        scope: {
            save: '<',
            filterModel: '=',
            parsedCriteria: '=',
            description: '@?',
        },
        controllerAs: 'FilterPanelVM',
        controller: 'FilterPanelCtrl',
    };
}

FilterPanelCtrl.$inject = [
    '$rootScope',
    '$scope',
    'pubSubService',
];

function FilterPanelCtrl($rootScope, $scope, pubSubService) {
    const FilterPanelVM = this,
        filterModelCopy = new FilterLogicModel($rootScope.survey);
    let appliedFilterSettings;
    FilterPanelVM.ux = {
        totalPossibleFilterByGroups: 0,
        disableApplyFilters: true,
    };
    FilterPanelVM.description = $scope.description || 'Apply a filter to your survey results to gain a better understanding of how a subset of respondents answered your survey.';
    FilterPanelVM.addNewFilterByGroup = addNewFilterByGroup;
    FilterPanelVM.trashThisFilterByGroup = trashThisFilterByGroup;
    FilterPanelVM.resetFilterSettings = resetFilterSettings;
    FilterPanelVM.applyFilterSettings = applyFilterSettings;
    FilterPanelVM.filterModelChanged = filterModelChanged;
    init();

    function init() {
        setFilterModel({
            parsedCriteria: $scope.parsedCriteria,
            filterModel: $scope.filterModel,
        });
        pubSubService.subscribe('refresh-filter-and-compare-model', $scope.$id, params => {
            setFilterModel(params);
            setTimeout(() => {
                pubSubService.notify('refresh-filter-item');
            }, 0);
        });
        $scope.$on('$destroy', () => {
            pubSubService.destroy('refresh-filter-and-compare-model', $scope.$id);
        });
    }

    function setFilterModel(params) {
        FilterPanelVM.selectedCriteria = angular.copy(params.parsedCriteria);
        appliedFilterSettings = JSON.stringify(FilterPanelVM.selectedCriteria);
        if (_.isEmpty(FilterPanelVM.selectedCriteria)) {
            addNewFilterByGroup();
        }
        _.forEach(params.filterModel, model => {
            if (model.hasSubmenu) {
                FilterPanelVM.ux.totalPossibleFilterByGroups += model.options.length;
            }
            else {
                FilterPanelVM.ux.totalPossibleFilterByGroups++;
            }
        });
    }

    function addNewFilterByGroup() {
        FilterPanelVM.selectedCriteria.push({
            criteria: [],
            parentLogic: '$and',
        });
        filterModelChanged();
    }

    function filterModelChanged() {
        FilterPanelVM.ux.disableApplyFilters = appliedFilterSettings === JSON.stringify(FilterPanelVM.selectedCriteria) && _.each(FilterPanelVM.selectedCriteria, criteria => {
            return criteria.id && criteria.criteria.length;
        });
    }

    function trashThisFilterByGroup(index) {
        const criteria = FilterPanelVM.selectedCriteria.splice(index, 1)[0];
        if (criteria.id) {
            const model = getModelById($scope.filterModel, criteria.id);
            _.forEach(model.options, option => {
                option.set = criteria.id === 'Demographic$Age' ? !option.disabled : criteria.id === 'Beacon$Pool';
            });
            model.isAvailable = true;
        }

        FilterPanelVM.ux.disableResetFilters = !FilterPanelVM.selectedCriteria.length || _.some(FilterPanelVM.selectedCriteria, criteria => {
            return !criteria.criteria.length;
        });
        if (FilterPanelVM.selectedCriteria.length) {
            filterModelChanged();
            setTimeout(() => {
                pubSubService.notify('refresh-filter-item');
            });
        }
        else {
            addNewFilterByGroup();
        }
    }

    function resetFilterSettings() {
        // Clear filterform & actions
        $scope.filterModel = angular.copy(filterModelCopy);
        $scope.parsedCriteria = [{
            criteria: [],
            parentLogic: '$and',
        }];
        FilterPanelVM.selectedCriteria = angular.copy($scope.parsedCriteria);
        FilterPanelVM.ux.disableApplyFilters = '';
        // Update variables
        FilterPanelVM.ux.disableApplyFilters = true;
        FilterPanelVM.ux.disableResetFilters = true;
        // Reset from datasource with unset datasource vars (re-initialize will wait for datasource change)
        $scope.save({
            filterModel: $scope.filterModel,
            parsedCriteria: FilterPanelVM.selectedCriteria,
        });
        setTimeout(() => {
            pubSubService.notify('refresh-filter-item');
        }, 0);
    }

    function applyFilterSettings() {
        // Update variables
        FilterPanelVM.ux.disableApplyFilters = true;
        FilterPanelVM.ux.disableResetFilters = false;
        $scope.parsedCriteria = angular.copy(FilterPanelVM.selectedCriteria);
        appliedFilterSettings = JSON.stringify(FilterPanelVM.selectedCriteria);
        // Finish the job by calling back to the dashboard controller
        $scope.save({
            filterModel: $scope.filterModel,
            parsedCriteria: FilterPanelVM.selectedCriteria,
        });
    }
}
