/*global U*/
import 'ng-file-upload/dist/angular-file-upload-all';
import '../../../../common/js/angular-util';

angular.module('apiAccountService', ['angularFileUpload', 'angularUtil'])
    /* Service providing functionality for creating and maintaining user accounts on Upwave API server */
    .service('accountService', accountService);
accountService.$inject = [
    '$rootScope',
    '$q',
    '$upload',
    'httpDeferred',
];
/**
 * Account Service
 *
 * @param {object} $rootScope
 * @param {object} $q
 * @param {object} $upload
 * @param {object} httpDeferred
 * @returns {object} the service instance
 */
function accountService($rootScope, $q, $upload, httpDeferred) {
    /**
     * Sign up
     *
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phone
     * @returns {Promise} the REST call response
     */
    function signUp(email, password, firstName, lastName, phone) {
        const newUser = {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
            },
            deferred = $q.defer();

        if (newUser.email && newUser.password) {
            httpDeferred.post(httpDeferred.dashboardHost + '/rest/auth/email-register', newUser).then(function(response) {
                if (response.error) {
                    deferred.reject('API sign up error');
                }
                else {
                    deferred.resolve(response);
                    signIn(email, password).then(function(user) {
                        $rootScope.auth.user = user;
                    });
                }
            }, function() {
                deferred.reject('API sign up error');
            });
        }
        else {
            deferred.reject('Sign up failed. Some inputs missing');
        }
        return deferred.promise;
    }

    /**
     * Sign in
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise} the REST call response
     */
    function signIn(email, password) {
        const deferred = $q.defer();

        httpDeferred.post(httpDeferred.dashboardHost + '/rest/auth/email-login', {
            email: email,
            password: password,
        }).then(function(response) {
            if (response.error) {
                deferred.reject('User login failed: ' + response.msg);
            }
            else {
                const user = response;
                user.anonymous = !user || user.anonymous !== false; // Examples: [true, undefined, null]
                $rootScope.auth.user = user;
                deferred.resolve(user);
                $rootScope.$emit('signInSuccess');
            }
        }, function() {
            deferred.reject('User login failed!');
        });
        return deferred.promise;
    }

    /**
     * Sign out
     *
     * @returns {Promise} the REST call response
     */
    function signOut() {
        const deferred = $q.defer();
        httpDeferred.get(httpDeferred.dashboardHost + '/rest/auth/logout').then(function(response) {
            if (response.error) {
                deferred.reject('Error logging out: ' + response.msg);
            }
            else {
                $rootScope.auth.user = undefined;
                $rootScope.signedOut = true;
                deferred.resolve();
            }
        }, function() {
            deferred.reject('User sign out failed!');
        });
        return deferred.promise;
    }

    /**
     * Get current user
     *
     * @returns {Promise} the REST call response
     */
    function currentUser() {
        const deferred = $q.defer(),
            params = {};
        if (U.cookie('utm_log')) {
            params.utmLog = U.cookie('utm_log');
        }
        if (U.cookie('ref_log')) {
            params.refererLog = U.cookie('ref_log');
        }

        httpDeferred.get(httpDeferred.dashboardHost + '/rest/auth/current-user' + U.buildParamString(params)).then(function(response) {
            if (response.error) {
                deferred.reject('Error getting current user: ' + response.msg);
            }
            else {
                const user = response;
                user.anonymous = !user || user.anonymous !== false; // Examples: [true, undefined, null]
                deferred.resolve(user);
            }
        }, function() {
            deferred.reject('Get current user failed!');
        });
        return deferred.promise;
    }

    /**
     * Create the user
     *
     * @param {string} email
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} phone
     * @param {string} company
     * @returns {Promise} the REST call response
     */
    function createUser(email, firstName, lastName, phone, company) {
        const newUser = {
                email: email,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                company: company,
            },
            deferred = $q.defer();

        if (newUser.email) {
            httpDeferred.post(httpDeferred.dashboardHost + '/rest/user/create', newUser).then(function(response) {
                if (response.error) {
                    deferred.reject('API create user error');
                }
                else {
                    deferred.resolve(response);
                }
            }, function() {
                deferred.reject('API create user error');
            });
        }
        else {
            deferred.reject('Create user failed. Some inputs missing');
        }
        return deferred.promise;
    }

    /**
     * Get user info
     *
     * @param {number} userId
     * @returns {Promise} the REST call response
     */
    function getUser(userId) {
        const deferred = $q.defer();

        httpDeferred.get(httpDeferred.dashboardHost + '/rest/user/show?userId=' + userId).then(function(response) {
            if (response.error) {
                deferred.reject('Error getting current user: ' + response.msg);
            }
            else {
                deferred.resolve(response);
            }
        }, function() {
            deferred.reject('Get users failed!');
        });
        return deferred.promise;
    }

    /**
     * List users
     *
     * @param {object} params
     * @returns {Promise} the REST call response
     */
    function listUsers(params) {
        const deferred = $q.defer();

        httpDeferred.get(httpDeferred.dashboardHost + '/rest/user/list' + U.buildParamString(params)).then(function(response) {
            if (response.error) {
                deferred.reject('Error getting current user: ' + response.msg);
            }
            else {
                deferred.resolve(response);
            }
        }, function() {
            deferred.reject('Get all users failed!');
        });
        return deferred.promise;
    }

    /**
     * Update user info
     *
     * @param {number} userId
     * @param {string[]} fieldsToUpdate
     * @returns {Promise} the REST call response
     */
    function updateUser(userId, fieldsToUpdate) {
        const deferred = $q.defer();

        httpDeferred.put(httpDeferred.dashboardHost + '/rest/user/update?userId=' + userId, fieldsToUpdate).then(function(response) {
            if (response.error) {
                deferred.reject('Error updating user: ' + response.msg);
            }
            else {
                deferred.resolve(response);
            }
        }, function() {
            deferred.reject('Update user failed');
        });
        return deferred.promise;
    }

    /**
     * Change user password
     *
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise} the REST call response
     */
    function changePassword(oldPassword, newPassword) {
        const deferred = $q.defer();

        httpDeferred.post(httpDeferred.dashboardHost + '/rest/auth/change-password', {
            oldPassword: oldPassword,
            newPassword: newPassword,
        }).then(function(response) {
            if (response.error) {
                deferred.reject('Error changing password: ' + response.msg);
            }
            else {
                deferred.resolve('API password changed');
            }
        }, function() {
            deferred.reject('Change password failed!');
        });
        return deferred.promise;
    }

    /**
     * Initiates a password reset flow
     *
     * @param {string} email
     * @returns {Promise} the REST call response
     */
    function sendPasswordResetEmail(email) {
        const deferred = $q.defer();

        httpDeferred.post(httpDeferred.dashboardHost + '/rest/auth/forgot-password', {
            email: email,
        }).then(function(response) {
            if (response.error) {
                deferred.reject('Error sending password reset email: ' + response.msg);
            }
            else {
                deferred.resolve();
            }
        }, function() {
            deferred.reject('Send password reset failed!');
        });
        return deferred.promise;
    }

    /**
     * Change current password
     *
     * @param {string} token
     * @param {string} newPassword
     * @param {string} userName
     * @param {string} phone
     * @returns {Promise} the REST call response
     */
    function resetPassword(token, newPassword, userName, phone) {
        const deferred = $q.defer();

        httpDeferred.post(httpDeferred.dashboardHost + '/rest/auth/reset-password', {
            token: token,
            password: newPassword,
            name: userName,
            phone: phone,
        }).then(function(response) {
            if (response.error) {
                deferred.reject({
                    error: 'Error resetting password: ' + response.msg,
                });
            }
            else {
                deferred.resolve(response);
            }
        }, function() {
            deferred.reject('Resetting password failed.');
        });
        return deferred.promise;
    }

    /**
     * List user contacts
     *
     * @param {number} userId
     * @returns {Promise} the REST call response
     */
    function listContactRequests(userId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/rest/contact-request/list?userId=' + userId);
    }

    /**
     * Send email
     *
     * @param {string} emailType
     * @param {object} params
     * @param {boolean} simulateError
     * @returns {Promise} the REST call response
     */
    function sendEmail(emailType, params, simulateError) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/rest/email/' + emailType, params, simulateError);
    }

    return {
        signIn: signIn,
        signOut: signOut,
        signUp: signUp,
        currentUser: currentUser,
        createUser: createUser,
        listUsers: listUsers,
        getUser: getUser,
        updateUser: updateUser,
        changePassword: changePassword,
        sendPasswordResetEmail: sendPasswordResetEmail,
        resetPassword: resetPassword,
        sendEmail: sendEmail,

        listContactRequests: listContactRequests,
    };
}
