import './gender/options';

angular.module('gender', ['gender.options'])
    .service('targetingAttributeGender', targetingAttributeGender);
targetingAttributeGender.$inject = ['genderOptions'];

function targetingAttributeGender(genderOptions) {
    function toString(req) {
        return _.map(req.details, function(id) {
            return genderOptions.map[id].name;
        }).join(', ');
    }

    function errors(req) {
        var errs = [],
            l = (req.details && req.details.length) || 0;

        // Require at least one group to be selected.
        if (l === 0) {
            errs.push('No gender is selected. Select either &ldquo;male&rdquo; or &ldquo;female.&rdquo;');
        }

        return errs;
    }

    return {
        id: 'gender',
        name: 'Gender',
        description: 'Filter by respondent gender',
        options: genderOptions.list,
        basic: true,
        extended: true,
        advanced: true,
        toString: toString,
        errors: errors,
    };
}
