angular.module('gender.editorView', ['gender.options'])
    .directive('genderEditor', genderEditor)
    .controller('GenderEditorCtrl', GenderEditorCtrl);

function genderEditor() {
    return {
        restrict: 'E',
        templateUrl: '/survey-creation-templates/target/gender-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, GenderEditorVM) {
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('GenderEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);
            updateOptionsObjectFromReqDetailsArray();

            function updateOptionsObjectFromReqDetailsArray() {
                _.forEach(GenderEditorVM.options, function(e) {
                    e.selected = !!scope.requirement.details && (scope.requirement.details.indexOf(e.id) !== -1);
                });
            }

            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];
                _.forEach(GenderEditorVM.options, function(e) {
                    if (e.selected) {
                        x.push(e.id);
                    }
                });
                scope.requirement.details = x;
            }
        },
        controller: 'GenderEditorCtrl',
        controllerAs: 'GenderEditorVM',
    };
}
GenderEditorCtrl.$inject = [
    '$scope', 'genderOptions',
];

function GenderEditorCtrl($scope, genderOptions) {
    var GenderEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    GenderEditorVM.options = angular.copy(genderOptions.list);
    GenderEditorVM.deselectOthers = deselectOthers;

    function deselectOthers(option) {
        _.forEach(GenderEditorVM.options, function(op) {
            if (option.name !== op.name) {
                op.selected = false;
            }
        });
    }
}
