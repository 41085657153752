import 'angular-ui-bootstrap';

angular.module('svDatePicker', ['ui.bootstrap'])
    .directive('svDateRangePicker', svDateRangePicker)
    .directive('svDatePicker', svDatePicker)
    .controller('svDatePickerCtrl', svDatePickerCtrl);
angular.module('ui.bootstrap.datepicker')
    .config(['$provide', templateOverride]);
/**
 * @name templateOverride
 *
 * @description
 * Overrides the ui-bootstrap datepicker's template with survata's svDatePicker
 * Todo: when upgrading, just replace the icons.
 */
function templateOverride($provide) {
    $provide.decorator('uibDaypickerDirective', function($delegate) {
        var directive = $delegate[0];
        directive.templateUrl = 'shared-templates/sv-daypicker.html';
        return $delegate;
    });
    $provide.decorator('uibMonthpickerDirective', function($delegate) {
        var directive = $delegate[0];
        directive.templateUrl = 'shared-templates/sv-monthpicker.html';
        return $delegate;
    });
    $provide.decorator('uibYearpickerDirective', function($delegate) {
        var directive = $delegate[0];
        directive.templateUrl = 'shared-templates/sv-yearpicker.html';
        return $delegate;
    });
}

function inputTemplate(date) {
    return '' +
        '<input id="' + date + '" type="text" ' +
        'class="form-control form-control--width-auto {{options.class.input}}" ' +
        'uib-datepicker-popup="{{options.format || svDatePickerVM.defaultOptions.format}}" ' +
        'datepicker-options="options.dateOptions.' + date + '" ' +
        'is-open="svDatePickerVM.' + date + '.opened" ' +
        'min-date="options.minDate" ' +
        'max-date="options.maxDate"' +
        'alt-input-formats="{{options.altInputFormats || svDatePickerVM.defaultOptions.altInputFormats}}" ' +
        'ng-model="ngModel.' + date + '" ' +
        'ng-required="true" ' +
        'ng-change="options.ngChange()" ' +
        'on-open-focus="false"' +
        'ng-click="svDatePickerVM.toggleDatepicker(\'' + date + '\')"/>';
}

function svDatePicker() {
    var date = 'endDate';
    return {
        restrict: 'E',
        template: '' +
            '<div class="input-group sv-datepicker pull-left {{options.class.wrapper}}">' +
            '<span class="input-group-btn va-top">' +
            '<button type="button" class="btn btn-default" ng-click="svDatePickerVM.toggleDatepicker(\'' + date + '\')">' +
            '<i class="far fa-calendar-alt"></i>' +
            '</button>' +
            '</span>' +
            inputTemplate(date) +
            '</p>',
        scope: {
            options: '=',
            ngModel: '=',
        },
        controller: 'svDatePickerCtrl',
        controllerAs: 'svDatePickerVM',
    };
}

function svDatePickerCtrl() {
    var svDatePickerVM = this;
    svDatePickerVM.defaultOptions = {
        format: 'MM/dd/yyyy',
        altInputFormats: ['M!/d!/yyyy'],
    };
    svDatePickerVM.toggleDatepicker = toggleDatepicker;

    function toggleDatepicker(date) {
        if (!svDatePickerVM[date]) {
            svDatePickerVM[date] = {};
        }
        svDatePickerVM[date].opened = true;
    }
}

function svDateRangePicker() {
    return {
        restrict: 'E',
        template: '' +
            '<form class="form-inline form-inline-sv sv-datepicker pull-left {{options.class.wrapper}}">' +
            '<div class="form-group">' +
            '<label for="start">Start:</label>' +
            inputTemplate('start') +
            '</div>' +
            '<div class="form-group">' +
            '<label for="end">End:</label>' +
            inputTemplate('end') +
            '</div>' +
            '</form>',
        scope: {
            options: '=',
            ngModel: '=',
        },
        controller: 'svDatePickerCtrl',
        controllerAs: 'svDatePickerVM',
    };
}
