angular.module('questionTypes.clickMap', [])
    .service('questionTypes.clickMap.service', clickMapService);

function clickMapService() {
    function toString(q) {
        return (q.statement.text || '') + '\n' +
            '[click map]\n' +
            '[image url: ' + q.details.imageUrl + ']\n' +
            '[clicks: ' + q.details.numClicks + ']\n';
    }

    function errors(q) {
        var errs = [];

        if (!q.details.imageUrl) {
            errs.push('Please select an image');
        }
        return errs;
    }
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'clickmap',
        name: 'Click Map',
        description: 'Click on a presented image',
        icon: 'fal fa-bullseye',
        toString: toString,
        errors: errors,
        initialDetails: {
            numClicks: 1,
            zones: [],
        },
    };
}
