import '../../common-components/paginator';
import '../../beacons/api-beacon-service';
import adMeasurementService from '../ad-measurement.service';
import '../ad-measurement.api-service';
import adMeasurementConstants from '../ad-measurement.constants';

angular.module('audienceConfiguration.configureExposure', [
    'common.paginator',
    'apiBeaconService',
    'adMeasurement.apiService',
])
    .controller('ConfigureExposureCtrl', ConfigureExposureCtrl)
    .directive('configureExposure', configureExposure);

/**
 *
 */
function configureExposure() {
    return {
        restrict: 'E',
        templateUrl: '/dashboard-templates/ad-measurement/audience-configuration/configure-exposure.html',
        scope: {
            audienceConfiguration: '=',
        },
        controller: 'ConfigureExposureCtrl',
        controllerAs: 'ConfigureExposureVM',
    };
}
ConfigureExposureCtrl.$inject = [
    '$rootScope',
    '$scope',
    'Paginator',
    'beaconService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param Paginator
 * @param beaconService
 */
function ConfigureExposureCtrl($rootScope, $scope, Paginator, beaconService) {
    const NEXT_STEP_IDX = 1,
        ConfigureExposureVM = this,
        stepManager = adMeasurementService.getStepManager(),
        selectedPixelMap = {};
    ConfigureExposureVM.audienceConfiguration = $scope.audienceConfiguration;
    ConfigureExposureVM.ux = {
        beaconParams: {},
    };
    ConfigureExposureVM.exposurePixels = [];

    ConfigureExposureVM.populateBeaconDefinitionList = populateBeaconDefinitionList;
    ConfigureExposureVM.addPixel = addPixel;
    ConfigureExposureVM.removePixel = removePixel;
    ConfigureExposureVM.updateCountForFrequency = updateCountForFrequency;
    ConfigureExposureVM.getErrorMessage = getErrorMessage;
    ConfigureExposureVM.next = next;

    init();

    function init() {
        _.forEach(ConfigureExposureVM.audienceConfiguration.beaconDefinitions, function(beaconDefinition) {
            selectedPixelMap[beaconDefinition.uuid] = true;
        });
        populateBeaconDefinitionList();
    }

    function populateBeaconDefinitionList() {
        ConfigureExposureVM.ux.beaconParams.userId = $rootScope.survey.user.id;
        if (ConfigureExposureVM.ux.filterText) {
            ConfigureExposureVM.ux.beaconParams.exportName = ConfigureExposureVM.ux.filterText;
            if ($rootScope.auth.user.isAdmin) {
                delete ConfigureExposureVM.ux.beaconParams.userId;
            }
        }
        else {
            delete ConfigureExposureVM.ux.beaconParams.exportName;
        }

        beaconService.listTags(ConfigureExposureVM.ux.beaconParams).then(function(exposurePixels) {
            _.forEach(exposurePixels, function(pixel) {
                pixel.selected = selectedPixelMap[pixel.uuid];
            });
            ConfigureExposureVM.paginatedTags = new Paginator({
                list: exposurePixels,
                itemsPerPage: adMeasurementConstants.DEFAULT_LIST_ITEMS_PER_PAGE,
            });
            refreshBeacons();
        });
    }

    function refreshBeacons() {
        ConfigureExposureVM.paginatedTags.refreshList(filterBeacons);
        $scope.$evalAsync(() => {
            ConfigureExposureVM.paginatedTags.getPageData();
        });
    }

    /**
     * @param pixel
     */
    function filterBeacons(pixel) {
        return !pixel.selected;
    }

    /**
     * @param pixelToAdd
     */
    function addPixel(pixelToAdd) {
        pixelToAdd.selected = true;
        pixelToAdd.countForFrequency = true;
        ConfigureExposureVM.audienceConfiguration.beaconDefinitions.push(pixelToAdd);
        delete ConfigureExposureVM.ux.errorMsg;
        refreshBeacons();
    }

    /**
     * @param pixelToRemove
     * @param idx
     */
    function removePixel(pixelToRemove, idx) {
        pixelToRemove.selected = false;
        refreshBeacons();
        ConfigureExposureVM.audienceConfiguration.beaconDefinitions.splice(idx, 1);
        // If you have removed the last pixel, close the panel
        if (!ConfigureExposureVM.audienceConfiguration.beaconDefinitions.length) {
            ConfigureExposureVM.ux.selectedPixelsExpanded = false;
        }
    }

    /**
     * @param pixelSelectedForFrequency
     */
    function updateCountForFrequency(pixelSelectedForFrequency) {
        _.forEach(ConfigureExposureVM.selectedPixels, function(pixel) {
            if (pixel.uuid !== pixelSelectedForFrequency.uuid) {
                pixel.countsForFrequency = false;
            }
        });
    }

    /**
     * @param e
     */
    function next(e) {
        ConfigureExposureVM.ux.errorMsg = getErrorMessage();
        if (!ConfigureExposureVM.ux.errorMsg) {
            stepManager.setCurrentStep(e, NEXT_STEP_IDX);
        }
    }

    /**
     *
     */
    function getErrorMessage() {
        const beaconsArray = ConfigureExposureVM.audienceConfiguration.beaconDefinitions;
        //Checks if at least one exposure beacon is added
        if (!beaconsArray.length) {
            return 'Please add at least one exposure pixel';
        }
        //Checks if at least one of the beacons counts for frequency
        const frequencyCheck = beaconsArray.some(i => i.countForFrequency);
        if (!frequencyCheck) {
            return 'Please configure at least one count for frequency';
        }
    }
}
