import './admin-update-user';
import './survey-admin-ctrl';
import './survey-list-ctrl';
import './response-admin-ctrl';
import './network-admin-ctrl';
import './panel-admin-ctrl';
import './client-info-ctrl';
import './survey-take-ctrl';
import './url-builder-app';
import './survey-admin-service';
import './retargeting-admin-ctrl';
angular.module('SurvataAdminModule', [
    'adminUpdateUser',
    'SurveyAdminModule',
    'SurveyListModule',
    'ResponseAdminModule',
    'NetworkAdminModule',
    'PanelAdminModule',
    'ClientInfoModule',
    'SurveyTakeModule',
    'UrlBuilderApp',
    'surveyAdminService',
    'RetargetingAdminModule',
]);
