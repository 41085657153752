angular.module('questionTypeGroups.service', ['questionTypes.service'])
    .service('questionTypeGroupsService', questionTypeGroupsService);

questionTypeGroupsService.$inject = ['questionTypesService'];

function questionTypeGroupsService(questionTypesService) {
    var groupList = [questionTypesService],
        types = {},
        questionTypeCount = {},
        atOpenEndedLimit = false,
        QuestionTypes = {
            SINGLE_SELECT: 'singleSelect',
            MULTI_SELECT: 'multiSelect',
            FREE_RESPONSE: 'freeResponse',
            FREE_RESPONSE_ALT: 'longFreeResponse',
            RATING: 'rating',
            RANKING: 'ranking',
            INTRO_STATEMENT: 'introStatement',
            NUMERIC: 'numeric',
            GRID: 'grid',
        };

    _.forEach(groupList[0].typeList, function(type) {
        types[type.id] = type;
    });

    function isDropdown(q) {
        return q.type === QuestionTypes.SINGLE_SELECT && q.details.displayType === 'dropdown';
    }

    function isCombobox(q) {
        return (q.type === QuestionTypes.SINGLE_SELECT || q.type === QuestionTypes.MULTI_SELECT) && q.details.displayType === 'combobox';
    }

    function isSlider(q) {
        return (q.type === QuestionTypes.SINGLE_SELECT || q.type === QuestionTypes.MULTI_SELECT) && q.details.displayType === 'slider';
    }

    function isSingleSelect(q) {
        return q.type === QuestionTypes.SINGLE_SELECT;
    }

    function isMultiSelect(q) {
        return q.type === QuestionTypes.MULTI_SELECT || (q.type === 'multi' && q.details.type === 'grid');
    }

    function isRanking(q) {
        return q.type === QuestionTypes.RANKING;
    }

    function isRating(q) {
        return q.type === QuestionTypes.RATING;
    }

    function isGlyphRating(q) {
        return q.type === QuestionTypes.RATING && (!q.details.displayType || q.details.displayType === 'glyph');
    }

    function isSliderRating(q) {
        return q.type === QuestionTypes.RATING && q.details.displayType === 'slider';
    }

    function isFreeResponse(q) {
        return q.type === QuestionTypes.FREE_RESPONSE || q.type === QuestionTypes.FREE_RESPONSE_ALT;
    }

    function isNumeric(q) {
        return q.type === QuestionTypes.NUMERIC;
    }

    function isIntroStatement(q) {
        return q.type === QuestionTypes.INTRO_STATEMENT;
    }

    function isGrid(q) {
        return q.type === QuestionTypes.GRID;
    }

    function isOpenEnded(q) {
        return (q.type === QuestionTypes.FREE_RESPONSE) || q.type === QuestionTypes.FREE_RESPONSE_ALT || (q.type === QuestionTypes.NUMERIC);
    }

    function questionTypeName(q) {
        if (isCombobox(q)) {
            return 'Combobox';
        }
        else if (isDropdown(q)) {
            return 'Dropdown';
        }
        else if (isSlider(q)) {
            return 'Slider';
        }
        else if (isSingleSelect(q)) {
            return 'Single Select';
        }
        else if (isMultiSelect(q)) {
            return 'Multi Select';
        }
        else if (isGlyphRating(q)) {
            return 'Rating';
        }
        else if (isSliderRating(q)) {
            return 'Rating Slider';
        }
        else if (isGrid(q)) {
            return 'Grid';
        }
        return types[q.type].name;
    }

    function updateQuestionCount(questions, funnel) {
        var numOpenEnded = 0;
        questionTypeCount = {};

        _.forEach(questions, function(question) {
            numOpenEnded += isOpenEnded(question) ? 1 : 0;
            questionTypeCount[question.type] = questionTypeCount[question.type] || 0;
            questionTypeCount[question.type]++;
        });

        if (funnel && funnel.maxOpenEnded) {
            atOpenEndedLimit = numOpenEnded >= funnel.maxOpenEnded;
        }
    }

    function questionDisabled(questionType, funnel) {
        if (isOpenEnded({
            type: questionType.id,
        })) {
            return atOpenEndedLimit;
        }
        else if (questionType[funnel.id] && questionType[funnel.id].maxAllowed) {
            return (questionTypeCount[questionType.id] || 0) >= questionType[funnel.id].maxAllowed;
        }
        return false;
    }

    function questionTooltipText(questionType, funnel) {
        var text = 'To maintain a quality experience for respondents, we limit surveys to ';
        if (isOpenEnded({
            type: questionType.id,
        }) && atOpenEndedLimit) {
            return text + funnel.maxOpenEnded + ' open ended questions (free response or numeric input) on ' + funnel.id;
        }
        else if (questionType[funnel.id] && questionType[funnel.id].maxAllowed) {
            return text + questionType[funnel.id].maxAllowed + ' ' + questionType.name.toLowerCase() + ' questions on ' + funnel.id;
        }
        return '';
    }

    return {
        groupList: groupList,
        types: types,
        updateQuestionCount: updateQuestionCount,
        questionDisabled: questionDisabled,
        questionTooltipText: questionTooltipText,

        // Use these to avoid 'magic strings!'
        questionTypes: QuestionTypes,
        questionTypeName: questionTypeName,
        isSingleSelect: isSingleSelect,
        isDropdown: isDropdown,
        isCombobox: isCombobox,
        isSlider: isSlider,
        isMultiSelect: isMultiSelect,
        isRanking: isRanking,
        isRating: isRating,
        isGlyphRating: isGlyphRating,
        isSliderRating: isSliderRating,
        isFreeResponse: isFreeResponse,
        isIntroStatement: isIntroStatement,
        isNumeric: isNumeric,
        isGrid: isGrid,
        isOpenEnded: isOpenEnded,
    };
}
