import '../../../../common/js/inc/sv-notify';
import './api-beacon-service';

angular
    .module('segmentCreation.modal', [
        'svNotify', 'apiBeaconService',
    ])
    .controller('SegmentCreationModal', SegmentCreationModal);

SegmentCreationModal.$inject = [
    '$uibModalInstance',
    '$notify',
    'beaconService',
    'segment',
    'deferred',
];

/**
 * Controller for segment creation modal
 *
 * @param {object} $uibModalInstance - Modal service
 * @param {object} $notify - Notification service
 * @param {object} beaconService - Beacon CRUD service
 * @param {object} segment - Segment object
 * @param {Promise} deferred - Promise passed from parent
 */
function SegmentCreationModal($uibModalInstance, $notify, beaconService, segment, deferred) {
    var SegmentCreationVM = this;
    SegmentCreationVM.segment = segment;
    SegmentCreationVM.types = [{
        name: 'Cookie',
        value: 'cookie',
    },
    {
        name: 'Mobile',
        value: 'mobile',
    },
    {
        name: 'Mobile & Cookie',
        value: 'cookieAndMobile',
    },
    {
        name: 'IP Address',
        value: 'ipaddress',
    }];
    SegmentCreationVM.dataPartners = [{
        name: 'Liveramp',
        value: 'liveramp',
        prepend: 'l-',
    },
    {
        name: 'Krux',
        value: 'krux',
        prepend: 'k-',
    },
    {
        name: 'Lotame',
        value: 'lotame',
        prepend: 't-',
    },
    {
        name: 'Oracle',
        value: 'oracle',
        prepend: 'o-',
    },
    {
        name: 'Datalogix',
        value: 'datalogix',
        prepend: 'dlx-',
    }];
    SegmentCreationVM.disableSave = disableSave;
    SegmentCreationVM.closeModal = closeModal;
    SegmentCreationVM.createNewSegment = createNewSegment;

    init();

    function init() {
        if (SegmentCreationVM.segment.uuid) {
            SegmentCreationVM.segment.dataPartner = _.find(SegmentCreationVM.dataPartners, {
                value: SegmentCreationVM.segment.dataPartner,
            }) || {};
            SegmentCreationVM.segment.beaconType = _.find(SegmentCreationVM.types, {
                value: SegmentCreationVM.segment.beaconType,
            }) || {};
            SegmentCreationVM.segment.segmentId = SegmentCreationVM.segment.uuid;
            if (SegmentCreationVM.segment.dataPartner.prepend) {
                SegmentCreationVM.segment.segmentId = SegmentCreationVM.segment.segmentId.replace(new RegExp('^' + SegmentCreationVM.segment.dataPartner.prepend), '');
            }
        }
    }

    /**
     * Create or update the segment.
     */
    function createNewSegment() {
        var promise;
        if (SegmentCreationVM.segment.uuid) {
            SegmentCreationVM.segment.dataPartner = SegmentCreationVM.segment.dataPartner.value;
            SegmentCreationVM.segment.beaconType = SegmentCreationVM.segment.beaconType.value;
            promise = beaconService.updateTag(SegmentCreationVM.segment.uuid, SegmentCreationVM.segment);
        }
        else {
            promise = beaconService.createSegment(SegmentCreationVM.segment);
        }

        promise.then(response => {
            deferred.resolve(response);
            $uibModalInstance.close();
        }, error => {
            $notify.error(error.msg || error, {
                clear: true,
                timeout: 1500,
            });
            closeModal();
        });
    }

    /**
     * Close the modal.
     */
    function closeModal() {
        deferred.resolve();
        $uibModalInstance.close();
    }

    /**
     * Determine whether save button should be disabled.
     *
     * @returns {boolean} Whether to disable save button
     */
    function disableSave() {
        return !SegmentCreationVM.segment.segmentId || !SegmentCreationVM.segment.exportName || !SegmentCreationVM.segment.beaconType || !SegmentCreationVM.segment.dataPartner;
    }
}
