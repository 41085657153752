import Paginate from '../../common-components/paginate-service';
import '../../beacons/api-beacon-service';

angular.module('beaconPool.editorView', ['apiBeaconService'])
    .directive('beaconPoolEditor', beaconPoolEditor)
    .controller('BeaconPoolEditorCtrl', BeaconPoolEditorCtrl);

function beaconPoolEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/beacon-pool-editor.html',
        scope: {
            requirement: '=',
        },
        controller: 'BeaconPoolEditorCtrl',
        controllerAs: 'BeaconPoolEditorVM',
    };
}
BeaconPoolEditorCtrl.$inject = [
    '$rootScope',
    '$scope',
    'beaconService',
];

function BeaconPoolEditorCtrl($rootScope, $scope, beaconService) {
    var BeaconPoolEditorVM = this,
        beaconPools = new Paginate({
            func: beaconService.listBeaconPools,
        });
    $scope.auth = $rootScope.auth;
    $scope.requirement.details = $scope.requirement.details || {
        beaconPools: [],
        windowDays: null,
    };
    BeaconPoolEditorVM.auth = $rootScope.auth;
    BeaconPoolEditorVM.paginatedTags = {
        offset: 0,
        max: 10,
        page: 0,
        hasNext: false,
        hasPrev: false,
    };
    BeaconPoolEditorVM.localux = {
        beaconPoolNameSearch: '',
    };
    BeaconPoolEditorVM.addBeacon = addBeacon;
    BeaconPoolEditorVM.removeBeacon = removeBeacon;
    BeaconPoolEditorVM.togglePause = togglePause;
    BeaconPoolEditorVM.refreshBeacons = refreshBeacons;
    BeaconPoolEditorVM.nextPage = nextPage;
    BeaconPoolEditorVM.prevPage = prevPage;
    BeaconPoolEditorVM.beaconFilter = beaconFilter;

    init();

    function init() {
        // Load all the beaconPools available to the current user
        if ($rootScope.auth.user.isAdmin) {
            refreshBeacons();
        }
        else {
            beaconService.listBeaconPools().then(function(beaconPools) {
                BeaconPoolEditorVM.paginatedTags.data = beaconPools;
                BeaconPoolEditorVM.paginatedTags.hasNext = false;

                if ($scope.requirement.details.beaconPools.length) {
                    var uuids = _.map(BeaconPoolEditorVM.paginatedTags.data, function(beaconPool) {
                        return beaconPool.uuid;
                    });

                    var nonUserBeacons = $scope.requirement.details.beaconPools.filter(function(beaconPool) {
                        return uuids.indexOf(beaconPool.uuid) === -1;
                    });
                    BeaconPoolEditorVM.paginatedTags.data = BeaconPoolEditorVM.paginatedTags.data.concat(nonUserBeacons);
                }
            });
        }
    }

    function addBeacon(beaconPool) {
        $scope.requirement.details.beaconPools.push(beaconPool);
    }

    function removeBeacon(beaconPool) {
        var idx = $scope.requirement.details.beaconPools.indexOf(beaconPool);
        $scope.requirement.details.beaconPools.splice(idx, 1);
    }

    function togglePause(beaconPool) {
        beaconPool.paused = !beaconPool.paused;
    }

    function refreshBeacons() {
        var params;
        if (BeaconPoolEditorVM.localux.searchFilter) {
            params = {
                name: BeaconPoolEditorVM.localux.searchFilter,
            };
        }
        beaconPools.fetch(params).then(function(data) {
            BeaconPoolEditorVM.paginatedTags = data;
        });
    }

    function nextPage() {
        BeaconPoolEditorVM.paginatedTags = beaconPools.next();
    }

    function prevPage() {
        BeaconPoolEditorVM.paginatedTags = beaconPools.prev();
    }

    // Filter out beaconPools that are already selected
    function beaconFilter(item) {
        return _.map($scope.requirement.details.beaconPools, function(beaconPool) {
            return beaconPool.uuid;
        }).indexOf(item.uuid) === -1;
    }
}
