import '@uirouter/angularjs';
import '../../../common/js/load-survata';
import './app-components/api-account-service';
import './survey/api-survey-service';
import U from '../../../common/js/util';

/*global filepicker */

angular.module('SurveyAppCommon', [
    'apiAccountService', 'apiSurveyService',
])
    .run([
        '$rootScope',
        '$q',
        '$timeout',
        '$interval',
        '$http',
        '$state',
        '$stateParams',
        'accountService',
        'surveyService',
        function($rootScope, $q, $timeout, $interval, $http, $state, $stateParams, accountService, surveyService) {
            $rootScope.ux = $rootScope.ux || {};
            $rootScope.ux.accountView = '';
            $rootScope.rootux = $rootScope.rootux || {};
            $rootScope.rootux.saveSurveyStatus = 'unsaved';
            $rootScope.saveSurvey = saveSurvey;
            $rootScope.signOut = signOut;
            $rootScope.limitString = limitString;
            $rootScope.showCount = showCount;
            $rootScope.showCharCount = showCharCount;
            $rootScope.charCountColor = charCountColor;
            $rootScope.unAdminMe = unAdminMe;
            $rootScope.uploadImage = uploadImage;

            /**
             * Save the survey
             *
             * @param {string} surveyName
             * @param {string} surveyUuid
             * @returns {object}
             */
            function saveSurvey(surveyName, surveyUuid) {
                var d = $q.defer();
                if (!surveyUuid && !$rootScope.survey) {
                    d.resolve();
                    return d.promise;
                }
                $rootScope.rootux.saveSurveyStatus = 'saving';

                surveyService.updateSurvey(surveyUuid || $rootScope.survey.uuid, {
                    name: surveyName,
                }).then(function() {
                    $rootScope.rootux.saveSurveyStatus = 'saved';
                    d.resolve();
                });
                return d.promise;
            }

            // User sign out
            function signOut() {
                accountService.signOut();
                delete $rootScope.auth.user;
                $rootScope.desiredState = {
                    state: $state.current,
                    params: angular.copy($stateParams),
                };
                $state.go('signIn');
                $rootScope.$broadcast('signedOut');
            }

            /**
             * Limit string to a maximum number of chars with an optional tail
             *
             * @param {string} str
             * @param {number} max
             * @param {string} tail
             * @returns {string}
             */
            function limitString(str, max, tail) {
                tail = tail || '';
                return str.length > max ? str.substring(0, max) + tail : str;
            }

            /**
             * Response choice character or line count
             *
             * @param {number} current
             * @param {number} max
             * @returns {boolean}
             */
            function showCount(current, max) {
                return (2 * current) >= max;
            }

            /**
             * Response choice character count
             *
             * @param {string} chars
             * @param {number} maxChars
             * @returns {number}
             */
            function showCharCount(chars, maxChars) {
                return $rootScope.showCount(chars, maxChars);
            }

            /**
             * Char count color
             *
             * @param {string} chars
             * @param {number} maxChars
             * @returns {string}
             */
            function charCountColor(chars, maxChars) {
                var red = Math.min(255, Math.round(255 * chars / maxChars, 0)).toString(16);
                red = red.length === 1 ? '0' + red : red;
                return '#' + red + '3333';
            }

            // View the current page as a mere mortal would
            function unAdminMe() {
                $timeout(function() {
                    $rootScope.auth.user.isAdmin = false;
                });
            }

            /**
             * Choose an image to upload
             *
             * @returns {object}
             */
            function uploadImage() {
                var d = $q.defer(),
                    params = {
                        services: ['COMPUTER',
                            'URL',
                            'BOX',
                            'GOOGLE_DRIVE',
                        ],
                    };

                filepicker.pick(params, function(image) {
                    if (image) {
                        // Determine the image dimensions by create an image element
                        // and grabbing the height and width (but don't add it to the DOM)
                        var imgElem = document.createElement('img');
                        imgElem.src = image.url;

                        var getDimensions = $interval(function() {
                            if (imgElem.height && imgElem.width) {
                                $interval.cancel(getDimensions);
                                var ratio = imgElem.height / imgElem.width;
                                if ((ratio > 2.5 && imgElem.width > 400) || (ratio < 0.15 && imgElem.width > 500)) {
                                    d.reject('Image ratio not supported');
                                }
                                else {
                                    d.resolve({
                                        url: image.url,
                                        height: imgElem.height,
                                        width: imgElem.width,
                                    });
                                }
                            }
                        }, 250);
                    }
                    else {
                        d.reject();
                    }
                }, function() {
                    d.reject();
                });

                return d.promise;
            }
        },
    ])
    // Display a demo of the survey (don't capture responses)
    .controller('SurveyDemoCtrl', SurveyDemoCtrl);
SurveyDemoCtrl.$inject = ['$scope',
    '$rootScope',
    '$stateParams',
    '$timeout',
];

/**
 * Demo controller
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} $stateParams
 * @param {object} $timeout
 */
function SurveyDemoCtrl($scope, $rootScope, $stateParams, $timeout) {
    var resultCallback = function(result) {
        $timeout(function() {
            $scope.resultText = result;
        }, 1);
    };

    if ($stateParams.surveyId) {
        $timeout(function() {
            U.showSurveyWall({
                preview: $stateParams.surveyId,
                parent: '#survey-take',
                hideHeader: true,
                disallowClose: true,
                verticalLayout: true,
                hideFooter: true,
                resultCallback: resultCallback,
            });
        }, 500);
    }
}
