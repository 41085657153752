import '../../../../common/js/angular-util';
import '../common-components/pub-sub';
import '../common-components/nav-tabs';
import customVariablesService from '../custom-variables/custom-variables.api-service';
import dashboardConstants from './dashboard-constants';

angular.module('dashboardResults.ctrl', [
    'angularUtil', 'pubSub',
])
    .controller('DashboardResultsCtrl', DashboardResultsCtrl);

DashboardResultsCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    '$state',
    '$stateParams',
    '$urlService',
    'httpDeferred',
    'pubSubService',
    '$dashboardService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param $state
 * @param $stateParams
 * @param $urlService
 * @param httpDeferred
 * @param pubSubService
 * @param $dashboardService
 */
function DashboardResultsCtrl($rootScope, $scope, $timeout, $state, $stateParams, $urlService, httpDeferred, pubSubService, $dashboardService) {
    var DashboardResultsVM = this;
    DashboardResultsVM.ux = {
        ratingAnswer: 0,
        ratingDetails: '',
        ratingFormStatus: '',
        tallyParams: {},
        activeTab: 'charts',
        showFilterCompare: true,
        hasCharts: $rootScope.survey.latestCollectionPeriod._summary.responses || $rootScope.survey.collectionPeriods.length > 1,
    };

    DashboardResultsVM.auth = $rootScope.auth;
    DashboardResultsVM.views = dashboardConstants.get('resultsViews');
    DashboardResultsVM.latestCollectionPeriod = $scope.latestCollectionPeriod;
    DashboardResultsVM.customVariableEditorView = customVariablesService.modalView;
    DashboardResultsVM.togglePanes = togglePanes;

    init();

    function init() {
        pubSubService.subscribe('get-tally-complete', $scope.$id, onGetTallyComplete);
        pubSubService.subscribe('get-tally-started', $scope.$id, onGetTallyStarted);
        pubSubService.subscribe('update-results-status-msg', $scope.$id, updateResultsStatusMsg);

        // FIXME: sometimes survey.surveyResult other times survey.completed o_O, requesting Insights inconsistent with where to download from.
        DashboardResultsVM.ux.hasResultsDownload = !!$rootScope.survey.completed || _.some($rootScope.survey.collectionPeriods, function(period) {
            return !!period.completed;
        });
        if ($state.current.name === 'results') {
            const nextStateName = _.camelCase($urlService.parts().hash),
                nextState = $state.get(nextStateName);
            if (nextStateName.length && nextState) {
                $state.go((nextState.data && nextState.data.default) || nextStateName);
            }
            else {
                $state.go('surveyResultsCharts');
            }
        }
    }

    /**
     * @param message
     */
    function updateResultsStatusMsg(message) {
        DashboardResultsVM.ux.resultsStatusMsg = message;
    }

    function onGetTallyComplete() {
        DashboardResultsVM.ux.chartsRefreshing = false;
        DashboardResultsVM.filterRunning = false;
    }

    function onGetTallyStarted() {
        DashboardResultsVM.ux.chartsRefreshing = true;
        DashboardResultsVM.filterRunning = true;
    }

    /**
     * @param tab
     * @param e
     */
    function togglePanes(tab, e) {
        if (e && e.target.tagName === 'BUTTON') {
            e.preventDefault();
            return;
        }
        DashboardResultsVM.ux.activeTab = tab;
        if (DashboardResultsVM.ux.activeTab === 'charts') {
            $timeout(() => {
                $scope.$broadcast('rzSliderForceRender');
                $scope.$broadcast('reCalcViewDimensions');
            }, 250);
        }
    }
}
