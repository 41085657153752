/** Only used in subdomains/surveys **/
angular.module('svFocusMe', []).directive('svFocusMe', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            /*
                In order for this to work, you have to broadcast an event
                on the sv-focus-me attribute e.g.

                HTML:
                <input sv-focus-me="myEvent" type="text" />

                JS:
                $scope.$broadcast('myEvent');
            */
            scope.$on(attrs.svFocusMe, function(value) {
                $timeout(function() {
                    element[0].focus();
                });
            });
        },
    };
});
