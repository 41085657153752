import _ from 'lodash';

var list,
    map = {};

list = [{
    id: 'star',
    name: 'star',
    icon: 'far fa-star',
},
{
    id: 'square',
    name: 'square',
    icon: 'far fa-square',
},
{
    id: 'circle',
    name: 'circle',
    icon: 'far fa-circle',
},
{
    id: 'heart',
    name: 'heart',
    icon: 'far fa-heart',
}];

_.forEach(list, function(e) {
    map[e.id] = e;
});

angular.module('rating.options', [])
    .constant('ratingOptions', {
        list: list,
        map: map,
        minLength: 3,
        maxLength: 10,
    });
