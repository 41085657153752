import svgConstants from './svg-constants';
const companyLogo = svgConstants.pdfLogo;

/* Export HTML to PDF using jsPDF library given a selector string and an optional file name */
/* Notes from jsPDF author: All units are in the set measurement for the document */
/* This can be changed to "pt" (points), "mm" (Default), "cm", "in" */

/**
 * Given selector of an html element, export that element as PDF.
 *
 * @param {string}  selector - jQuery selector
 * @param {string}  fileName
 * @param {boolean} addLogo
 */
function htmlToPDFCustom(selector, fileName, addLogo) {
    var doc = new jsPDF();
    doc.setFontSize(10);
    doc.setFillColor(255, 0, 0);
    const LOGO_HEIGHT = 19;
    const LOGO_WIDTH = 44;

    /**
     * Build a faux table (jsPDFs tables are wonky)
     *
     * @param {Element} element
     * @param {number} x
     * @param {number} y
     * @param {number} width
     */
    function buildTable(element, x, y, width) {
        var $rows = element.find('.table-export').find('.table-export-row'),
            colWidths = element.find('.table-export').attr('columns'),
            lineHeight = 5,
            xOffset = 2,
            yOffset = 4,
            linesInTable = 0,
            totalPadding = 0,
            curYPos = y,
            colWidths = colWidths ? JSON.parse(colWidths) : [],
            temp;

        // Iterate through each row
        $rows.each(function(rowIdx) {
            var $curRow = $(this),
                $cells = $curRow.find('.table-export-cell'),
                avgColWidth = width / $cells.length,
                curXPos = angular.copy(x) + xOffset,
                linesInRow = 1,
                paddingTop = 0,
                paddingBottom = 0;

            // Iterate through each table cell
            $cells.each(function(colIdx) {
                var $curCell = $(this),
                    linesInCell = 0,
                    prevExtra = 0;

                if ($curCell.is('[multi-line]')) {
                    $curCell.find('.cell-line').each(function(lineIdx) {
                        var $curLine = $(this),
                            lines = doc.splitTextToSize($curLine.attr('value'), 145);

                        paddingTop += $curLine.attr('padding-top') ? parseInt($curLine.attr('padding-top'), 10) : 0;
                        paddingBottom += $curLine.attr('padding-bottom') ? parseInt($curLine.attr('padding-bottom'), 10) : 0;

                        // Increment lines in cell
                        linesInCell += 1;

                        // Set font-type if attribute set
                        if ($curLine.attr('font-type')) {
                            doc.setFontType($curLine.attr('font-type'));
                        }

                        // If a "line" needs to word wrap, increment lines again
                        for (var i = 1; i < lines.length; i++) {
                            linesInCell += 1;
                            lines[i] = '   ' + lines[i];
                        }

                        // Lines in row should be max of lines in row cells
                        linesInRow = Math.max(linesInRow, linesInCell);

                        // Draw the text at current position
                        doc.text(curXPos, curYPos + yOffset + (lineIdx + prevExtra) * lineHeight + paddingTop, lines);
                        doc.setFontType('normal');
                        prevExtra = prevExtra + lines.length - 1;
                    });
                }
                else {
                    if ($curCell.attr('font-type')) {
                        doc.setFontType($curCell.attr('font-type'));
                    }

                    doc.text(curXPos, curYPos + yOffset, $curCell.attr('value'));
                    doc.setFontType('normal');
                }
                curXPos = curXPos + ((colWidths && colWidths[colIdx]) || avgColWidth) + xOffset;
            });

            // Increment current Y position
            curYPos = curYPos + (linesInRow * lineHeight) + paddingTop + paddingBottom;
            linesInTable += linesInRow;
            totalPadding += paddingTop + paddingBottom;

            // Draw row line
            if (rowIdx < $rows.length - 1) {
                doc.line(x, curYPos, x + width, curYPos);
            }
        });

        // Draw table grid
        temp = x;
        for (var i = 0; i < colWidths.length - 1; i++) {
            temp += colWidths[i];
            doc.line(temp, y, temp, y + linesInTable * lineHeight + totalPadding);
        }
        doc.rect(x, y, width, linesInTable * lineHeight + totalPadding, 'D');
    }

    // Process each content block
    $(selector).find('.content-block').each(function() {
        var item = $(this),
            x = parseInt(item.attr('x'), 10),
            y = parseInt(item.attr('y'), 10),
            width = parseInt(item.attr('width'), 10);

        if (item.is('[has-table]')) {
            buildTable(item, x, y, width);
        }
        doc.fromHTML(item.get(0), x + 1, y, {
            width: width,
        });
    });

    // Add the survata logo
    if (addLogo) {
        doc.addImage(companyLogo, 'PNG', 151, 12, LOGO_WIDTH, LOGO_HEIGHT);
    }

    // Save the document for download
    doc.save((fileName || 'export') + '.pdf');
}

/**
 * Given selector of an html element, export that element as PDF.
 *
 * @param {string}  selector - jQuery selector
 * @param {string}  fileName
 * @param {boolean} addLogo
 */
function htmlToPDFBasic(selector, fileName, addLogo) {
    var doc = new jsPDF();
    if (addLogo) {
        doc.addImage(companyLogo, 'PNG', 160, 5, LOGO_WIDTH, LOGO_HEIGHT);
    }
    doc.fromHTML($(selector).get(0), 15, 5, {
        width: 170,
    });
    doc.save((fileName || 'export') + '.pdf');
}

const exportPDF = {
    htmlToPDFCustom: htmlToPDFCustom,
    htmlToPDFBasic: htmlToPDFBasic,
};

export default exportPDF;
