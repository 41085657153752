const urbanicityCuts = {
    category: 'Urbanicity',
    categoryType: 'demographic',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    controlType: 'Equivalent',
    options: [{
        name: 'Rural',
        value: 'Urbanicity (based on Postal Code): Rural',
        exposed: {
            $eq: ['Urbanicity (based on Postal Code)', 'Rural'],
        },

    },
    {
        name: 'Small Town',
        value: 'Urbanicity (based on Postal Code): Small Town',
        exposed: {
            $eq: ['Urbanicity (based on Postal Code)', 'Small_town'],
        },
    },
    {
        name: 'Large City',
        value: 'Urbanicity (based on Postal Code): Large City',
        exposed: {
            $eq: ['Urbanicity (based on Postal Code)', 'Large_city'],
        },
    },
    {
        name: 'No Data',
        value: 'Urbanicity (based on Postal Code): No Data',
        exposed: {
            $eq: ['Urbanicity (based on Postal Code)', 'Not Available'],
        },
    }],
};

export default urbanicityCuts;
