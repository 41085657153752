/* global U */
import '../../../../common/js/angular-util';
import '../survey/survey-statuses';
import '../survey/survey-to-string-service';
import '../survey/api-survey-service';
import surveyConstants from '../survey/survey-constants';
import '../app-components/api-account-service';

angular.module('surveyDashboardService', [
    'angularUtil',
    'surveyStatusesService',
    'surveyToStringService',
    'apiSurveyService',
    'apiAccountService',
])
    .service('$dashboardService', dashboardService);
dashboardService.$inject = [
    '$rootScope',
    '$q',
    '$window',
    'STSS',
    'surveyStatusesService',
    'surveyService',
    'accountService',
];

/**
 * Build and return the dashboardService
 *
 * @param {object} $rootScope
 * @param {object} $q
 * @param {object} $window
 * @param {object} STSS
 * @param {object} surveyStatusesService
 * @param {object} surveyService
 * @param {object} accountService
 *
 * @returns {object} - dashboardService
 */
function dashboardService($rootScope, $q, $window, STSS, surveyStatusesService, surveyService, accountService) {
    /**
     * Get the survey
     *
     * @param {string} uuid
     *
     * @returns {object} - dashboardService
     */
    function getFullSurvey(uuid) {
        var deferred = $q.defer();

        surveyService.getSurvey(uuid).then(function(survey) {
            $q.all([
                surveyService.getPeriodResultsSummary(survey.latestCollectionPeriod.uuid),
                surveyService.getSurveyQuote(uuid),
                surveyService.listQuestions(uuid),
                surveyService.getAudience(uuid),
                surveyService.listOriginalQuestions(uuid),
                accountService.getUser(survey.user.id),
                surveyService.listPeriods(uuid),
            ])
                .then(function(data) {
                    data[0].finishDate = U.makeDate(data[0].finishDate, 1);
                    survey.latestCollectionPeriod._summary = data[0];

                    if (survey.quoteProvided) {
                        survey._quote = data[1];
                        if (survey._quote.billToAddress) {
                            survey._quote.billToAddress = survey._quote.billToAddress.split('\n');
                        }
                    }

                    survey.questions = data[2];

                    surveyService.setAudienceInSurvey(survey, data[3]);
                    survey._audienceClassification = data[3].classification;
                    survey.sampleSize = data[3].sampleSize;
                    surveyService.listScreeningQuestions(data[3].uuid).then(function(screeners) {
                        survey.screeningQuestions = screeners || [];

                        // Puts stringified targeting reqs into $scope.survey._targetingString
                        survey._targetingString = createTargetingReqsString(survey);
                    });

                    survey.originalQuestions = data[4];

                    survey.collectionPeriods = data[6];
                    deferred.resolve(survey);
                }, function(error) {
                    console.error('Error trying to get details of survey', {
                        method: 'get, $q.all',
                    });
                    $q.reject('Error trying to get details of survey' + JSON.stringify(error));
                    deferred.reject('Error trying to get details of survey' + JSON.stringify(error));
                });
        }, function(error) {
            console.error('Error trying to get survey');
            deferred.reject('Error trying to get survey' + JSON.stringify(error));
        });
        return deferred.promise;
    }

    // Used to keep the targeting string updated when the user modifies targeting details
    /**
     * CreateTargetingReqsString
     *
     * @param {object} survey
     *
     * @returns {string} result
     */
    function createTargetingReqsString(survey) {
        if (!survey) {
            return;
        }
        var screeners = survey.screeningQuestions || [],
            targetingString = STSS.targetingReqsToString(survey);

        if (screeners.length) {
            targetingString += ' Screener(s):';

            for (var i = 0; i < screeners.length; i++) {
                targetingString += i ? ' ,' : '';
                targetingString += ' ' + screeners[i].statement.text;
            }
        }
        if (survey.targetingDetails) {
            targetingString += ' Additional details: ' + survey.targetingDetails;
        }

        return targetingString;
    }

    /**
     * GetCurrentStep
     *
     * @param {object} survey
     *
     * @returns {number}
     */
    function getCurrentStep(survey) {
        var surveyStep = {
                ANALYST_REVIEW: 0,
                REVIEW_APPROVE: 1,
                COLLECTING_RESULTS: 2,
                RESULTS_DELIVERED: 3,
            },
            step = surveyStep.ANALYST_REVIEW, // Default step
            lcpResultsDelivered = survey.latestCollectionPeriod.completed &&
            survey.latestCollectionPeriod.surveyResult &&
            survey.latestCollectionPeriod.surveyResult.created; // Necessary for trackers

        if (survey.resultsDelivered || survey.completed || lcpResultsDelivered) {
            step = surveyStep.RESULTS_DELIVERED;
        }
        else if (survey.approved && !survey.completed) {
            step = surveyStep.COLLECTING_RESULTS;
        }
        else if (!survey.approved && survey.reviewed) {
            step = surveyStep.REVIEW_APPROVE;
        }

        return step;
    }

    /**
     * HideEditButtons
     *
     * @param {object} survey
     * @param {object} user
     *
     * @returns {boolean}
     */
    function hideEditButtons(survey, user) {
        if (survey.completed) {
            return true;
        }
        else if (user.isAdmin) {
            return false;
        }

        const atMaxLength = survey.surveyPackage === surveyConstants.PACKAGES.ADVANCED ? false : (survey.questions || []).length >= 6;
        return atMaxLength || (survey.reviewStarted && !survey.clientEditable);
    }

    /**
     * Select view
     *
     * @param {object} survey
     * @param {boolean} hasCharts
     * @param {object} dashboardViewTypes
     *
     * @returns {string} - viewType
     */
    function selectView(survey, hasCharts, dashboardViewTypes) {
        if (survey.completed || hasCharts) {
            return dashboardViewTypes.RESULTS;
        }

        return dashboardViewTypes.DESIGN;
    }

    /**
     * Shows the survey preview
     *
     * @param {object} survey
     */
    function showPreview(survey) {
        if (survey.surveyPackage === surveyConstants.PACKAGES.ADVANCED) {
            $window.open('https://take.survata.com#/preview/' + survey.uuid);
        }
        else {
            U.showSurveyWall({
                preview: survey.uuid,
                allowClose: true,
                allowBack: true,
            });
        }
    }

    /**
     * Returns the results badge text
     *
     * @param {object} survey
     *
     * @returns {string}
     */
    function resultsBadgeText(survey) {
        if (survey.completed) {
            return 'complete';
        }
        else if (survey.latestCollectionPeriod && survey.latestCollectionPeriod._summary) {
            if (survey.latestCollectionPeriod._summary.responses >= survey.latestCollectionPeriod._summary.responsesNeeded) {
                return 'complete';
            }

            var percent = Math.round(survey.latestCollectionPeriod._summary.responses / survey.latestCollectionPeriod._summary.responsesNeeded * 100);
            return '' + survey.latestCollectionPeriod._summary.responses + '/' + survey.latestCollectionPeriod._summary.responsesNeeded + ' - ' + percent + '%';
        }

        return 'running';
    }

    /**
     * Setup the video
     *
     * @param {string} videoUrl
     *
     * @returns {object}
     */
    function setupVideo(videoUrl) {
        var video = {};
        if (!videoUrl) {
            return video;
        }
        var url = videoUrl;
        // eslint-disable-next-line no-useless-escape
        if (url.match('//(www.)?youtube|youtu\.be')) {
            var youtubeId;
            if (url.match('embed')) {
                youtubeId = url.split(/embed\//)[1].split('"')[0];
            }
            else {
                youtubeId = url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
            }
            video.embedType = 'youtube';
            video.embedUrl = '//www.youtube.com/embed/' + youtubeId + '?autoplay=1&playsinline=1';
        }
        else if (url.indexOf('vimeo.com') > -1) {
            // eslint-disable-next-line no-useless-escape
            var m = url.match(/^.+vimeo.com\/(.*\/)?([^#\?]*)/);
            var vimeoId = m[2] || m[1];
            video.embedType = 'vimeo';
            video.embedUrl = '//player.vimeo.com/video/' + vimeoId + '?autoplay=1';
        }
        else if (url.indexOf('.mp4', url.length - 4) > -1) {
            video.embedType = 'html5';
            video.embedUrl = url;
        }
        return video;
    }

    return {
        getFullSurvey: getFullSurvey,
        createTargetingReqsString: createTargetingReqsString,
        getCurrentStep: getCurrentStep,
        hideEditButtons: hideEditButtons,
        selectView: selectView,
        showPreview: showPreview,
        resultsBadgeText: resultsBadgeText,
        setupVideo: setupVideo,
    };
}
