import '../../../common-components/collapsible-panel';
import '../analysis-config-manager';

angular
    .module('analysisPlanCuts.view', ['collapsiblePanel', 'analysisConfig.manager'])
    .directive('analysisPlanCuts', analysisPlanCuts)
    .controller('AnalysisPlanCutsCtrl', AnalysisPlanCutsCtrl);

function analysisPlanCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/analysis-plan-cuts.html',
        controller: 'AnalysisPlanCutsCtrl',
        controllerAs: 'AnalysisPlanCutsVM',
    };
}

AnalysisPlanCutsCtrl.$inject = [
    'analysisConfigManager',
];

function AnalysisPlanCutsCtrl(analysisConfigManager) {
    var AnalysisPlanCutsVM = this,
        humanCutter = analysisConfigManager.get('humanCutter');

    // VM exposed variables
    AnalysisPlanCutsVM.cuts = humanCutter && humanCutter.cuts;
    AnalysisPlanCutsVM.ux = {
        showBasicCutsView: !_.size(AnalysisPlanCutsVM.cuts),
    };
}
