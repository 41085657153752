import regionOptions from './options';

angular.module('region.summaryView', [])
    .directive('regionSummary', regionSummary)
    .controller('RegionSummaryCtrl', RegionSummaryCtrl);

function regionSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/region-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'RegionSummaryCtrl',
        controllerAs: 'RegionSummaryVM',
    };
}
RegionSummaryCtrl.$inject = ['$scope'];

function RegionSummaryCtrl($scope) {
    var RegionSummaryVM = this;
    RegionSummaryVM.summary = summary;

    function summary() {
        var x = [];

        _.forEach($scope.requirement.details || [], function(e) {
            x.push(regionOptions.map[e].name);
        });

        return x.join(', ');
    }
}
