import '../../common-components/display-table';
import '../../common-components/pub-sub';
import './impression-reporting-service';

angular.module('impressionParameterList', ['pubSub', 'impressionReportingService'])
    .controller('ImpressionParameterListCtrl', ImpressionParameterCtrl);
ImpressionParameterCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'pubSubService',
    'impressionReportingService',
    'ipg',
    'dateRange',
];

function ImpressionParameterCtrl($scope, $uibModalInstance, pubSubService, impressionReportingService, ipg, dateRange) {
    const ImpressionParameterListVM = this;
    ImpressionParameterListVM.ux = {};
    ImpressionParameterListVM.close = $uibModalInstance.close;
    ImpressionParameterListVM.ipg = ipg;
    ImpressionParameterListVM.tableBody = ipg.parameters;
    ImpressionParameterListVM.tableHeaders = [{
        name: 'Name',
        value: 'name',
        default: 'unnamed',
        columnType: 'text',
        sortable: true,
    },
    {
        name: 'Count',
        value: 'impressionCount',
        default: '0',
        columnType: 'text',
        sortable: true,
        calendar: true,
    },
    {
        name: 'Created',
        value: 'created',
        columnType: 'date',
        sortable: true,
    }];
    ImpressionParameterListVM.tableFooter = [[{
        value: 'Total',
        columnType: 'text',
        columnClass: 'text-bold',
    }, {
        value: ImpressionParameterListVM.ipg.impressionCount,
        columnType: 'text',
        colspan: 2,
    }]];

    init();

    function init() {
        ImpressionParameterListVM.dateRange = dateRange;
        ImpressionParameterListVM.tableOptions = {
            dateRange: ImpressionParameterListVM.dateRange,
            noSearch: true,
        };
        pubSubService.subscribe('update-date-range', $scope.$id, updateDateRange);
        $scope.$on('$destroy', () => {
            pubSubService.notify('update-date-range', $scope.$id);
        });
    }

    function updateDateRange() {
        impressionReportingService.getImpressionGroupImpressionCounts(ImpressionParameterListVM.ipg, ImpressionParameterListVM.dateRange).then(() => {
            ImpressionParameterListVM.tableFooter[0][1].value = ImpressionParameterListVM.ipg.impressionCount;
        });
    }
}
