angular.module('operatingSystem.summaryView', ['operatingSystem.options'])
    .directive('operatingSystemSummary', operatingSystemSummary)
    .controller('OperatingSystemSummaryCtrl', OperatingSystemSummaryCtrl);

function operatingSystemSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/operating-system-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'OperatingSystemSummaryCtrl',
        controllerAs: 'OperatingSystemSummaryVM',
    };
}
OperatingSystemSummaryCtrl.$inject = ['$scope', 'operatingSystemOptions'];

function OperatingSystemSummaryCtrl($scope, operatingSystemOptions) {
    var OperatingSystemSummaryVM = this;
    OperatingSystemSummaryVM.options = operatingSystemOptions.list;
    OperatingSystemSummaryVM.selected = selected;

    function selected(option) {
        return $scope.requirement && $scope.requirement.details && ($scope.requirement.details.indexOf(option.id) !== -1);
    }
}
