const adMeasurementConstants = {
    CONTROL_PIXEL: 'controlPixel',
    DEMOGRAPHIC_ONLY: 'demographicOnly',
    NO_STRATEGY: 'none',
    MATCHING: 'matching',
    QUOTAS: 'quotas',
    NO_CONTROL: 'noControl',
    NEW_CONTROL: 'newControl',
    ALLOWED: 'allowed',
    SUPPRESSED: 'suppressed',
    USER_BEACONS: 'userBeacons',
    SURVEY_BEACONS: 'surveyBeacons',
    ALL_BEACONS: 'allBeacons',
    BEACON_POOL_REQ: 'beaconPool',
    DEFAULT_LIST_ITEMS_PER_PAGE: 10,
    pages: [{
        name: 'Audience configuration',
        id: 'audience-configuration',
        view: 'audienceConfigurationList',
    },
    {
        name: 'Impressions',
        id: 'impressions',
        view: 'impressionParameterReporting',
    },
    {
        name: 'Analysis plan',
        id: 'analysis-plan',
        view: 'analysisPlan',
    },
    {
        name: 'Campaign',
        id: 'campaign-form',
        view: 'campaignForm',
    }],
    impressionCountTypes: [{
        name: 'Tag',
        view: 'tag',
        displayType: 'tag',
    },
    {
        name: 'Impression Group',
        view: 'impressionGroups',
        displayType: 'impressionGroups',
    },
    {
        name: 'Parameters',
        nested: true,
        view: 'parameters',
        subViews: [{
            name: 'Ad ID',
            view: 'adId',
            displayType: 'parameterId',
            parent: 'parameters',
        },
        {
            name: 'Campaign ID',
            view: 'campaignId',
            displayType: 'parameterId',
            parent: 'parameters',
        },
        {
            name: 'Creative ID',
            view: 'creativeId',
            displayType: 'parameterId',
            parent: 'parameters',
        },
        {
            name: 'Placement ID',
            view: 'placementId',
            displayType: 'parameterId',
            parent: 'parameters',
        },
        {
            name: 'Site ID',
            view: 'siteId',
            displayType: 'parameterId',
            parent: 'parameters',
        },
        {
            name: 'Viewability',
            view: 'viewabilityLevel',
            displayType: 'parameterId',
            parent: 'parameters',
        }],
    }],
    impressionTableViewColumns: {
        tag: [{
            name: 'Name',
            value: 'name',
            columnType: 'text',
            sortable: true,
        },
        {
            name: 'Count',
            value: 'impressionCount',
            default: '0',
            columnType: 'text',
            sortable: true,
            calendar: true,
        },
        {
            name: 'Parameters',
            value: 'params',
            columnType: 'buttonLabel',
            iterable: true,
            eventName: 'select-impression-count-type',
        },
        {
            columnType: 'action',
            actionName: 'View Tag',
            eventName: 'action-button-callback',
        }],
        impressionGroups: [{
            name: 'Name',
            value: 'name',
            columnType: 'text',
            sortable: true,
        },
        {
            name: 'Tags',
            columnType: 'text',
            value: 'paramString',
        },
        {
            actionName: 'View All',
            columnType: 'action',
            eventName: 'action-button-callback',
        }],
        parameterId: [{
            value: 'value',
            columnType: 'text',
            sortable: true,
        },
        {
            name: 'Name',
            value: 'displayName',
            default: 'unnamed',
            columnType: 'text',
            sortable: true,
        },
        {
            name: 'Count',
            value: 'impressionCount',
            default: '0',
            columnType: 'text',
            sortable: true,
            calendar: true,
        }],
    },
    beaconDefinitionListCriteriaOptions: [{
        name: 'User',
        value: 'userBeacons',
    },
    {
        name: 'Survey',
        value: 'surveyBeacons',
    },
    {
        name: 'All',
        value: 'allBeacons',
    }],

    analysisPlanViews: {
        overview: {
            id: 'overview',
            name: 'Overview',
            view: 'analysisPlanOverview',
        },
        cuts: {
            id: 'cuts',
            name: 'Cuts',
            view: 'analysisPlanCuts',
        },
        answerCoding: {
            id: 'answerCoding',
            name: 'Answer Coding',
            view: 'answerCoding',
        },
        targetAudiences: {
            id: 'targetAudiences',
            name: 'Target Audiences',
            view: 'targetAudiences',
        },
    },

    audienceConfigurationStepTypes: {
        EXPOSURE: 'Exposure',
        SELECT_CONTROL: 'Select Control',
        NEW_CONTROL: 'New Control',
        REVIEW: 'Review',
    },

    controlTypes: [{
        name: 'Control Pixel',
        value: 'controlPixel',

    }, {
        name: 'Demographic Only',
        value: 'demographicOnly',
    }],

    beaconControlConfigurationStepTypes: {
        CREATE_CONTROL: 'Create Control',
        REVIEW_CONTROL: 'Review Control',
    },

    demographicsStrategies: {
        matching: {
            name: 'Matching',
            value: 'matching',
            options: [{
                type: 'matchAge',
                name: 'Age',
                defaultValue: true,
            },
            {
                type: 'matchGender',
                name: 'Gender',
                defaultValue: true,
            },
            {
                type: 'matchCountry',
                name: 'Country',
                defaultValue: true,
            },
            {
                type: 'matchUsRegion',
                name: 'Region',
                defaultValue: false,
            },
            {
                type: 'matchOs',
                name: 'OS',
                defaultValue: false,
            },
            {
                type: 'matchPublisher',
                name: 'Publisher',
                defaultValue: false,
            }],
        },
        quotas: {
            name: 'Quotas',
            value: 'quotas',
            options: [{
                type: 'age',
                name: 'Age',
                options: [{
                    type: 'ageRange13To17Percent',
                    name: '13–17',
                    defaultValue: 0.16,
                },
                {
                    type: 'ageRange18To24Percent',
                    name: '18–24',
                    defaultValue: 0.09,
                },
                {
                    type: 'ageRange25To34Percent',
                    name: '25–34',
                    defaultValue: 0.15,
                },
                {
                    type: 'ageRange35To44Percent',
                    name: '35–44',
                    defaultValue: 0.15,
                },
                {
                    type: 'ageRange45To54Percent',
                    name: '45–54',
                    defaultValue: 0.16,
                },
                {
                    type: 'ageRange55To64Percent',
                    name: '55–64',
                    defaultValue: 0.14,
                },
                {
                    type: 'ageRange65PlusPercent',
                    name: '65+',
                    defaultValue: 0.15,
                }],
            },
            {
                type: 'gender',
                name: 'Gender',
                options: [{
                    type: 'femalePercent',
                    name: 'Female',
                    defaultValue: 0.51,
                }],
            },
            {
                type: 'region',
                name: 'Region',
                options: [{
                    type: 'westPercent',
                    name: 'West',
                    defaultValue: 0.23,
                },
                {
                    type: 'southPercent',
                    name: 'South',
                    defaultValue: 0.37,
                },
                {
                    type: 'midwestPercent',
                    name: 'Midwest',
                    defaultValue: 0.22,
                },
                {
                    type: 'northeastPercent',
                    name: 'Northeast',
                    defaultValue: 0.18,
                }],
            }],
        },
    },
};

export default adMeasurementConstants;
