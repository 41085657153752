import '@uirouter/angularjs';
import '../../app-components/api-account-service';
angular.module('userAdmin.list', ['apiAccountService'])
    .controller('userListCtrl', userListCtrl);
userListCtrl.$inject = [
    '$scope',
    '$state',
    '$timeout',
    'accountService',
];

/**
 * @param $scope
 * @param $state
 * @param $timeout
 * @param accountService
 */
function userListCtrl($scope, $state, $timeout, accountService) {
    var tableFilters = {
            name: '',
            email: '',
        },
        statusFilters = [{
            id: 'hideAccount',
            description: 'Hide Account',
            value: false,
        }, {
            id: 'hideContact',
            description: 'Hide Contact Us',
            value: false,
        }];

    $scope.pagination = {
        max: 25,
        offset: 0,
        page: 0,
    };
    $scope.sort = {
        criteria: 'id',
        reverse: true,
        filters: tableFilters,
        statusFilters: statusFilters,
    };
    $scope.ux = {
        ready: false,
    };
    $scope.email = '';
    $scope.name = '';
    $scope.phone = '';
    $scope.company = '';
    $scope.newSubmitted = false;

    $scope.refreshPromise = null;
    $scope.refreshRunning = false;

    $scope.$watch('sort.filters', function() {
        if ($scope.refreshPromise) {
            $timeout.cancel($scope.refreshPromise);
        }
        $scope.refreshPromise = $timeout(function() {
            $scope.refreshUserList();
        }, 750);
    }, true);

    $scope.resultsPerPage = resultsPerPage;
    $scope.changePage = changePage;
    $scope.sortBy = sortBy;
    $scope.refreshUserList = refreshUserList;
    $scope.refreshUsers = refreshUsers;
    $scope.newUser = newUser;

    /**
     * @param perPage
     */
    function resultsPerPage(perPage) {
        var multiplier = $scope.pagination.max / perPage;
        $scope.pagination.max = perPage;
        $scope.pagination.page = Math.floor($scope.pagination.page * multiplier);
        $scope.refreshUserList();
    }

    /**
     * @param page
     */
    function changePage(page) {
        if (page < 0) {
            return;
        }
        $scope.pagination.page = page;
        $scope.pagination.offset = $scope.pagination.page * $scope.pagination.max;
        $scope.refreshUserList();
    }

    /**
     * @param criteria
     */
    function sortBy(criteria) {
        if ($scope.sort.criteria === criteria) {
            $scope.sort.reverse = !$scope.sort.reverse;
        }
        else {
            $scope.sort.criteria = criteria;
        }
    }

    function newUser() {
        var name = U.splitName($scope.name);
        accountService.createUser($scope.email, name.first, name.last, $scope.phone, $scope.company)
            .then(function(user) {
                $state.go('admin.userDetails', {
                    userId: user.id,
                });
            }, function() {
                $scope.newSubmitted = true;
            });
    }

    function refreshUserList() {
        if ($scope.refreshRunning) {
            $timeout(function() {
                $scope.refreshUserList();
            }, 100);
        }
        else {
            $scope.refreshUsers();
        }
    }

    function refreshUsers() {
        $scope.refreshRunning = true;
        var params = angular.copy($scope.pagination);
        params.filters = JSON.stringify($scope.sort.filters);

        accountService.listUsers(params).then(function(users) {
            $scope.appData = {
                users: users,
                userMap: {},
            };
            $scope.users = users;

            angular.forEach($scope.appData.users, function(user) {
                $scope.appData.userMap[user.id] = user;
            });
            $scope.ux.ready = true;
            $scope.refreshRunning = false;
        },
        function() {
            $scope.ux.ready = true;
            $scope.refreshRunning = false;
        });
    }

    $scope.refreshUserList();
}
