angular.module('svLoadingSpinner', [])
    .directive('svLoadingSpinner', [function() {
        return {
            restrict: 'ECA',
            replace: true,
            template: '<div class="loading-spinner">' +
                '<i class="fas fa-spinner fa-spin fa-3x loading-spinner__icon"></i>' +
                '<span class="loading-spinner__text">Loading&hellip;</span>' +
                '</div>',
        };
    }]);
