const defaultCutNames = {
    // New
    Overall: true,
    Male: true,
    Female: true,
    '13 to 17': true,
    '18 to 24': true,
    '25 to 34': true,
    '35 to 44': true,
    '45 to 54': true,
    '55 to 64': true,
    '65+': true,
    'Device type: Mobile': true,
    'Device type: Desktop': true,
    Mobile: true,
    Desktop: true,
    Windows: true,
    Android: true,
    MacOS: true,
    iOS: true,
    Other: true,
    Northeast: true,
    Midwest: true,
    South: true,
    West: true,
    1: true,
    2: true,
    3: true,
    4: true,
    '5+': true,
    '10+': true,
    '1-2': true,
    '3-4': true,
    '5-9': true,
    'Less than or equal to 2 days': true,
    'Less than or equal to 5 days': true,
    'Less than or equal to 7 days': true,
    'Less than or equal to 10 days': true,
    'Less than or equal to 14 days': true,
    'Less than or equal to 20 days': true,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
    'Not Monday': true,
    'Not Tuesday': true,
    'Not Wednesday': true,
    'Not Thursday': true,
    'Not Sunday': true,
    Weekdays: true,
    Weekends: true,
    'Early Morning (midnight - 6am)': true,
    'Morning (6am - noon)': true,
    'Afternoon (noon - 6pm)': true,
    'Evening (6pm - midnight)': true,
    Rural: true,
    'Small Town': true,
    'Large City': true,
    'No Data': true,
    '$1 - 25,000': true,
    '$25,001 - 50,000': true,
    '$50,001 - 75,000': true,
    '$75,001 - 100,000': true,
    '$100,000+': true,

    // Old
    'Gender: Male': true,
    'Gender: Female': true,
    'Age: 13 to 17': true,
    'Age: 18 to 24': true,
    'Age: 25 to 34': true,
    'Age: 35 to 44': true,
    'Age: 45 to 54': true,
    'Age: 55 to 64': true,
    'Age: 65+': true,
    'Operating System: Mobile': true,
    'Operating System: Desktop': true,
    'Operating System: Windows': true,
    'Operating System: Android': true,
    'Operating System: MacOS': true,
    'Operating System: iOS': true,
    'Operating System: Other': true,
    'Region: Northeast': true,
    'Region: Midwest': true,
    'Region: South': true,
    'Region: West': true,
    '1 Exposure': true,
    '2 Exposures': true,
    '3 Exposures': true,
    '4 Exposures': true,
    '5+ Exposures': true,
    '10+ Exposures': true,
    '1-2 Exposures': true,
    '3-4 Exposures': true,
    '5-9 Exposures': true,
    'Urbanicity: Rural': true,
    'Urbanicity: Small Town': true,
    'Urbanicity: Large City': true,
    'Urbanicity: No Data': true,
    NO_DATA_urbanicity: true,
    'Median Income: 1-25,000': true,
    'Median Income: 25,001-50,000': true,
    'Median Income: 50,001-75,000': true,
    'Median Income: 75,001-100,000': true,
    'Median Income: 100,000+': true,
    NO_DATA_median_income: true,
};

export default defaultCutNames;
