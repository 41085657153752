import '@uirouter/angularjs';
import '../survey/survey-to-string-service';
import '../survey/survey-statuses';

angular.module('surveyContactInfo.ctrl', ['surveyStatusesService'])
    .controller('SurveyContactInfoCtrl', SurveyContactInfoCtrl);
SurveyContactInfoCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    'STSS',
    'surveyStatusesService',
];

/**
 * Main survey contact info controller
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} $state
 * @param {object} $stateParams
 * @param {object} STSS
 * @param {object} surveyStatusesService
 */
function SurveyContactInfoCtrl($scope, $rootScope, $state, $stateParams, STSS, surveyStatusesService) {
    var ContactInfoVM = this;
    //I hate this, but it will be fixed in another PR
    $rootScope.surveyToString = surveyToString;
    $rootScope.surveyId = $stateParams.surveyId;
    ContactInfoVM.auth = $rootScope.auth;
    ContactInfoVM.formStatus = '';
    ContactInfoVM.ux = {
        backUrl: $rootScope.survey ? $state.href('sample', surveyStatusesService.getRouteParams($rootScope.survey)) : '',
        nextUrl: $rootScope.survey ? $state.href('confirm', surveyStatusesService.getRouteParams($rootScope.survey)) : '',
        accountView: $rootScope.survey && $rootScope.survey.client && $rootScope.survey.client.type === 'guest' ? 'guest' : 'signUp',
    };
    ContactInfoVM.signOut = $rootScope.signOut;

    init();

    function init() {
        if ($rootScope.survey && $rootScope.survey.client && $rootScope.survey.client.type === 'guest') {
            $state.go('signUp');
        }
        //I have a feeling another scope is modifiying this scope's accountView, so going to keep this here.
        $scope.$watch('ux.accountView', (newView, oldView) => {
            if (!newView && (oldView === 'resetPassword')) {
                $state.go('account.signIn');
                ContactInfoVM.formStatus = '';
            }
        });
    }

    /**
     * Convert current survey to string
     *
     * @returns {string}
     */
    function surveyToString() {
        return STSS.surveyToString($rootScope.survey);
    }
}
