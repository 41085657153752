const analysisConfigConstants = {
    METRO_CUT_PREFIX: 'Metro Area:',
    TEMPORAL: 'temporal',
    KPI: 'kpi',
    CONFOUNDER: 'confounder',
    MARKET_RESEARCH: 'marketResearch',
    TARGET_AUDIENCE: 'targetAudience',
    TARGET_AUDIENCE_NAME: 'Target Audience',
    DEFAULT_SAMPLE: 50,
    DEFAULT_WEEKS_IN_TIME_BIN: 2,
    DEFAULT_MAX_DAYS_SINCE_EXPOSURE: 30,
    DEFAULT_COMPLEXITY_LIMIT: 10,
    DEFAULT_NOTABILITY_CUTOFF: 0.05,
    DEFAULT_MAX_STEPS: 1000,
    DFAULT_MIN_COUNT: 10,
    DEFAULT_TRIM: 0.01,
    DEFAULT_TMAX: 2,
    DEFAULT_MAX_TAB_NAME_LENGTH: 31,
    keysToIgnore: {
        analysisUsage: true,
        uuid: true,
        definitionJson: true,
        kpiType: true,
        analysisFactorUuid: true,
        //Not saved to db
        set: true,
        parsedCriteria: true,
    },
    defaultAnalysisPlanSettings: {
        output_params: {
            audience_profile_significance_levels: [
                0.95,
                0.85,
                0.80,
            ],
            lift_significance_levels: [
                0.95,
                0.85,
                0.80,
            ],
        },
        weighting_params: {
            min_exposed_sample: 100,
            min_control_sample: 100,
        },
        survey_params: {
            max_days_since_exposure: 30,
            weeks_in_time_bin: 2,
        },
    },
    questionFactorTypes: [{
        value: 'kpi',
        name: 'KPI factor',
        _displayName: 'KPI',
        _displayNameFull: 'Key performance indicator',
    },
    {
        value: 'confounder',
        name: 'Weighting',
        _displayName: 'Conf.',
        _displayNameFull: 'Confounder',
    },
    {
        value: 'marketResearch',
        name: 'Market research factor',
        _displayName: 'MR',
        _displayNameFull: 'Market research factor',
    },
    {
        name: 'Exclude',
    }],
    factorLevelFactorTypes: [{
        value: 'kpi',
        name: 'KPI',
        _displayName: 'KPI',
        _displayNameFull: 'Key performance indicator',
    },
    {
        value: 'confounder',
        name: 'Conf',
        _displayName: 'Conf.',
        _displayNameFull: 'Confounder',
    },
    {
        value: 'marketResearch',
        name: 'MR',
        _displayName: 'MR',
        _displayNameFull: 'Market Research',
    }],
    analysisConfigFields: {
        AUDIENCE_PROFILE_PARAMS: 'audienceProfileParams',
        CONFOUNDERS: 'confounders',
        HUMAN_CUTTER: 'humanCutter',
        INPUT_PARAMS: 'inputParams',
        KPI_FACTORS: 'kpiFactors',
        MARKET_RESEARCH_FACTORS: 'marketResearchFactors',
        OUTPUT_PARAMS: 'outputParams',
        SURVEY_PARAMS: 'surveyParams',
        VERSION: 'version',
        WEIGHTING_PARAMS: 'weightingParams',
        IMPRESSION_COUNT_COLUMNS: 'impressionCountColumns',
    },
    inclusions: [{
        value: 'allowed',
        name: 'Allowed',
    }, {
        value: 'suppressed',
        name: 'Suppressed',
    }],
    cutControlTypes: [{
        value: 'equivalent',
        name: 'Equivalent control',
    },
    {
        value: 'all',
        name: 'Overall',
    },
    {
        value: 'custom',
        name: 'Custom control',
    }],
    cutCategoryTypes: {
        DEMOGRAPHIC: 'demographic',
        TIME_BASED: 'timeBased',
        QUESTION: 'question',
        IMPRESSION_GROUP: 'impressionGroup',
        CUSTOM: 'custom',
    },
    indicatorTypes: [{
        value: 'is_control',
        name: 'Is control',
    }, {
        value: 'is_exposed',
        name: 'Is exposed',
    }],
    analysisUsages: [{
        value: 'global',
        name: 'Global',
    }, {
        value: 'specific',
        name: 'Specific',
    }],
    controlTypes: [{
        value: 'all',
        name: 'Overall',
    },
    {
        value: 'Custom',
        name: 'Custom',
    },
    {
        value: 'Matched',
        name: 'Matched',
    }],
    tableFormats: [{
        value: 'SIMPLE',
        name: 'Simple',
    },
    {
        value: 'BASIC',
        name: 'Basic',
    },
    {
        value: 'FULL',
        name: 'Full',
    }],
    renormalizationMethods: [{
        value: 'MIN',
        name: 'min',
    },
    {
        value: 'MAX',
        name: 'max',
    },
    {
        value: 'EXPOSED',
        name: 'Exposed',
    },
    {
        value: 'CONTROL',
        name: 'Control',
    },
    {
        value: 'NONE',
        name: 'None',
    }],
    liftCalculationMethods: [{
        value: 'STRATIFIED',
        name: 'Stratified',
    },
    {
        value: 'MARKETSIGHT',
        name: 'Market sight',
    },
    {
        value: 'DIRECT',
        name: 'Direct',
    }],
    weightingSelectionMethods: [{
        value: 'FORWARD',
        name: 'Forward',
    }, {
        value: 'ORIGINAL_FLAVOR',
        name: 'Original flavor',
    }],
    treatmentEffectMethods: [{
        value: 'ATE',
        name: 'ATE',
    },
    {
        value: 'ATT',
        name: 'ATT',
    },
    {
        value: 'ATC',
        name: 'ATC',
    }],
    selectionCriterions: [{
        value: 'AIC',
        name: 'Akaike Information Criterion',
    },
    {
        value: 'BIC',
        name: 'Bayesian Information Criterion',
    },
    {
        value: 'LRT',
        name: 'Likelihood Information Criterion',

    }],
    factorTypes: [{
        value: 'kpi',
        field: 'kpiFactors',
        original: 'originalKpiFactors',
    },
    {
        value: 'confounder',
        original: 'originalConfounders',
    },
    {
        value: 'marketResearch',
        field: 'marketResearchFactors',
        original: 'originalMarketResearchFactors',
    }],
    analysisFactorTypes: [{
        value: 'SINGLE_SELECT',
        name: 'single select',
        type: 'singleSelect',
    },
    {
        value: 'MULTI_SELECT',
        name: 'multi select',
        type: 'multiSelect',
    },
    {
        value: 'DEMOGRAPHIC',
        name: 'Demographic',
    },
    {
        value: 'TEMPORAL',
        name: 'Temporal',
    }],
    factorLevelTypes: [{
        value: 'CATEGORICAL',
        name: 'Categorical',
    },
    {
        value: 'ORDINAL',
        name: 'Ordinal',
    },
    {
        value: 'NUMERIC',
        name: 'Numeric',
    }],
    testTypes: [{
        value: 'twoSided',
        name: 'Two sided',
    },
    {
        value: 'lower',
        name: 'Lower',
    },
    {
        value: 'higher',
        name: 'Higher',
    }],
    analysisConfigSteps: [{
        title: 'Select default cuts',
        view: 'selectDefaultCuts',
        isOpen: true,
    },
    {
        title: 'Cuts',
        view: 'humanCutter',
    },
    {
        title: 'Select weighting and factors',
        view: 'weightingAndFactorSelection',
    },
    {
        title: 'Review KPI factors',
        value: 'kpiFactors',
        factorType: 'kpi',
        view: 'questionFactors',
    },
    {
        title: 'Review market research factors',
        value: 'marketResearchFactors',
        factorType: 'marketResearch',
        view: 'questionFactors',
    },
    {
        title: 'Review confounders',
        value: 'confounders',
        factorType: 'confounder',
        view: 'confounders',
    },
    {
        title: 'Review custom factors',
        view: 'questionFactors',
    },
    /**
            //Commenting out until further development
            {
                title: 'Specific cuts',
                view: 'specificCuts',
            },
     **/
    {
        title: 'Input parameters',
        view: 'inputParams',
    },
    {
        title: 'Survey parameters',
        view: 'surveyParams',
    },
    {
        title: 'Audience profile parameters',
        view: 'audienceProfileParams',
    },
    {
        title: 'Weighting parameters',
        view: 'weightingParams',
    },
    {
        title: 'Output parameters',
        view: 'outputParams',
    },
    {
        title: 'Review and save',
        view: 'jsonEditor',
    },
    ],
};

export default analysisConfigConstants;
