angular.module('chart.dataParser', [])
    .service('chartDataParser', chartDataParser);

/**
 * @param $rootScope
 */
function chartDataParser($rootScope) {
    /**
     * @param periods
     */
    function isTracker(periods) {
        return periods && !(periods.consolidate || periods.start === periods.end);
    }

    /**
     * @param questionType
     */
    function isFreeResponse(questionType) {
        return /[fF]reeResponse$/.test(questionType);
    }

    /**
     * @name chartDataParser#isLineChart
     * @description Returns false if there are no periods or the period is consolidated
     * @param {object} periods
     * @param {boolean} periods.consolidate
     * @param {number} periods.start
     * @param {number} periods.end
     * @returns {boolean}
     */
    function isLineChart(periods) {
        var hasPeriods = $rootScope.survey && ($rootScope.survey._isTracker || $rootScope.survey.collectionPeriods.length > 1);
        return !!hasPeriods && !!periods && !periods.consolidate && (!periods.start || periods.start !== periods.end);
    }

    /**
     * @param question
     */
    function isPlottableChart(question) {
        return question.type !== 'numeric' && question.type !== 'introStatement' && !hidePlottableChart(question);
    }
    // For clickmap questions, don't display plottable chart if there are no zones
    /**
     * @param question
     */
    function hidePlottableChart(question) {
        return question.type === 'clickmap' ? !(question.details.zones && question.details.zones.length) : void 0;
    }

    /**
     * @name parseTallyCount
     * @param {object[]} tallyParams - The tally params
     * @param {object[]|object} count - The object containing the tally count, where the object key is the period or comparison key, and the value is the count.
     * @param {object[]|object} numRespondents - The object containing the total number of respondents per category
     * @param {string} tallyType - The tally type to be charted
     * @returns {boolean} true if the comparison type is the same as the chart type
     *
     * @description
     * If the tallies are for a tracker or comparison, then the count and the number of respondents are arrays.
     * Used to parse respondent data.
     */
    function parseTallyCount(tallyParams, count, numRespondents, tallyType) {
        let chartDataset = [],
            _selfCharting = selfCharting(tallyParams.comparisonData, tallyType),
            _isComparison = tallyParams.comparisonData && !_selfCharting,
            _isTracker = isTracker(tallyParams.periods),
            idx;
        if (_isTracker) {
            idx = tallyParams.periods ? tallyParams.periods.start : 0;
            _.forEach(count, function(tally, label) {
                let chartData = parseTallyData(tally, numRespondents[label] || numRespondents, tallyParams.periods.dates[idx]);
                chartData.label = label;
                chartDataset.push(chartData);
                idx++;
            });
            return chartDataset;
        }
        else if (_isComparison) {
            idx = 0;
            _.forEach(numRespondents, function(num, label) {
                let chartData = parseTallyData(count[idx++], num);
                chartData.label = label;
                chartDataset.push(chartData);
            });
            return chartDataset;
        }
        return parseTallyData(count, numRespondents);
    }

    /**
     * @name selfCharting
     * @param {object[]} comparisonData - An array of comparisons
     * @param {string} tallyType - The tally type to be charted
     * @returns {boolean} true if the comparison type is the same as the chart type
     *
     * @description
     * When charting comparisons for demographic data, ensure that if the chart type
     * is a beaconPool, age, or gender, the chart doesn't try to compare with itself.
     */
    function selfCharting(comparisonData, tallyType) {
        if (!comparisonData || tallyType === 'Demographic$Location') {
            return false;
        }
        return _.keys(comparisonData[0])[0] === tallyType;
    }

    /**
     * @param data
     * @param numRespondents
     * @param date
     */
    function parseTallyData(data, numRespondents, date) {
        var countProportions = {},
            chartData = {
                numRespondents: numRespondents,
                confidenceIntervalsOverProportions: {},
                respondentsShownChoice: {},
            };
        _.forEach(data, function(count, key) {
            countProportions[key] = count / chartData.numRespondents;
        });
        chartData.countTally = U.alphabetizeObjectKeys(data);
        chartData.countProportions = countProportions;
        chartData.dates = date || chartData.dates;
        return chartData;
    }

    /**
     * For specifying chart type, later on *
     *
     * @param questionType
     */
    function getChartType(questionType) {
        switch (questionType) {
            case 'ranking':
                return 'clusteredBar';
            case 'grid':
            default:
                return 'bar';
        }
    }

    return {
        isTracker: isTracker,
        isFreeResponse: isFreeResponse,
        isLineChart: isLineChart,
        isPlottableChart: isPlottableChart,
        hidePlottableChart: hidePlottableChart,

        parseTallyCount: parseTallyCount,
        parseTallyData: parseTallyData,
        selfCharting: selfCharting,
        getChartType: getChartType,
    };
}
