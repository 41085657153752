const clientInfoFields = [{
    name: 'email',
    input: 'text',
    description: 'Client email',
    editable: false,
},
{
    name: 'firstName',
    input: 'text',
    description: 'First name',
    editable: true,
},
{
    name: 'lastName',
    input: 'text',
    description: 'Last name',
    editable: true,
},
{
    name: 'phone',
    input: 'text',
    description: 'Phone',
    editable: true,
},
{
    name: 'jobTitle',
    input: 'text',
    description: 'Job Title',
    editable: true,
},
{
    name: 'notes',
    input: 'textarea',
    description: 'Admin Notes',
    editable: true,
}];

const userBasicsConstants = {
    clientInfoFields: clientInfoFields,
};

export default userBasicsConstants;
