import angular from 'angular';
import _ from 'lodash';
class PubSubService {
    constructor() {
        this.subscribers = {};
    }
    /**
     * @name notify
     * @param {string|string[]} topic - The topic to notify
     * @param {*} [args] - Arguments to be applied to callback
     */
    notify(topic, args) {
        if (!args) {
            args = [];
        }
        else if (!Array.isArray(args)) {
            args = [args];
        }
        if (!this.subscribers[topic]) {
            this.subscribers[topic] = {
                args: args || [],
                callbacks: {},
            };
            return;
        }
        else if (_.isEmpty(this.subscribers[topic].callbacks)) {
            this.subscribers[topic].args = args;
            return;
        }
        _.forEach(this.subscribers[topic].callbacks, function(callback) {
            callback.apply(this, args);
        });
    }

    subscribe(topics, id, callback) {
        const self = this;
        if (Array.isArray(topics)) {
            _.forEach(topics, function(topic) {
                self.subscribe(topic, id, callback);
            });
            return;
        }
        if (!this.subscribers[topics]) {
            this.subscribers[topics] = {
                callbacks: {},
            };
        }
        this.subscribers[topics].callbacks['_' + id] = callback;
    }

    destroy(topics, id) {
        const self = this;
        if (Array.isArray(topics)) {
            _.forEach(topics, function(topic) {
                self.destroy(topic, id);
            });
            return;
        }
        if (this.subscribers[topics]) {
            delete this.subscribers[topics].callbacks['_' + id];
            if (_.isEmpty(this.subscribers[topics].callbacks)) {
                delete this.subscribers[topics];
            }
        }
    }

    /**
     * @name flushTopic
     * @param {string} topic - Topic to be flushed
     *
     * @description
     * Some controllers try to notify other controllers before they are initialized.
     * By calling flushTopic in the controllers that are initialized later on, ensure
     * that newer controllers are up-to-date with the calls.
     */
    flushTopic(topic) {
        if (!this.subscribers[topic]) {
            return;
        }
        const args = this.subscribers[topic].args || [];
        delete this.subscribers[topic].args;
        _.forEach(this.subscribers[topic].callbacks, function(callback) {
            callback.apply(this, args);
        });
    }
}

export default angular.module('pubSub', [])
    .service('pubSubService', PubSubService);
