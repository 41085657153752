import 'jquery';
import '../../../../../common/js/lib/jquery.imgareaselect.min';

angular.module('clickMap.editor', ['pubSub'])
    .directive('clickMapEditor', clickMapEditor)
    .controller('ClickMapEditorCtrl', ClickMapEditorCtrl);

function clickMapEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/click-map-editor.html',
        scope: {
            question: '=',
        },
        controller: 'ClickMapEditorCtrl',
        controllerAs: 'ClickMapEditorVM',
    };
}

ClickMapEditorCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    'pubSubService',
];

function ClickMapEditorCtrl($scope, $rootScope, $timeout, pubSubService) {
    var ClickMapEditorVM = this;
    ClickMapEditorVM.ux = {
        imageError: null,
        editingZone: false,
    };
    ClickMapEditorVM.editZone = editZone;
    ClickMapEditorVM.deleteZone = deleteZone;
    ClickMapEditorVM.addZone = addZone;
    ClickMapEditorVM.cancelEdits = cancelEdits;
    ClickMapEditorVM.disableEdits = disableEdits;
    ClickMapEditorVM.saveZone = saveZone;
    ClickMapEditorVM.chooseImage = chooseImage;

    init();

    function init() {
        /* Get notifications from parent scope that question editor has been closed without saving */
        pubSubService.subscribe('cancel-question-edits', $scope.$id, function() {
            doneEditingHelper(ClickMapEditorVM.currentZone);
        });
        $scope.$on('$destroy', function() {
            pubSubService.destroy('cancel-question-edits', $scope.$id);
        });
    }

    /* Helper function used by cancelEdits and saveZone functions */
    function doneEditingHelper(zone) {
        ClickMapEditorVM.editingIndex = null;
        ClickMapEditorVM.currentZone = null;
        ClickMapEditorVM.ux.editingZone = false;
        if (zone) {
            delete zone.editing;
        }
        $('.clickmap-image').imgAreaSelect({
            remove: true,
            hide: true,
        });
    }

    /* Track the coordinates of the crop tool */
    function track(img, selection) {
        ClickMapEditorVM.currentZone = {
            x: selection.x1,
            y: selection.y1,
            width: selection.width,
            height: selection.height,
        };
    }

    /* Edit an existing zone */
    function editZone(zone, index) {
        zone.editing = true;
        ClickMapEditorVM.ux.editingZone = true;
        ClickMapEditorVM.editingIndex = index;
        $('.clickmap-image').imgAreaSelect({
            x1: zone.x,
            y1: zone.y,
            x2: zone.x + zone.width,
            y2: zone.y + zone.height,
            handles: true,
            onSelectChange: track,
            onSelectEnd: track,
        });
    }

    /* Delete a zone */
    function deleteZone(index) {
        $scope.question.details.zones.splice(index, 1);
    }

    /* Pop open the area select tool */
    function addZone($event) {
        $event.preventDefault();
        $('.clickmap-image').imgAreaSelect({
            x1: 120,
            y1: 90,
            x2: 280,
            y2: 210,
            handles: true,
            onSelectChange: track,
            onSelectEnd: track,
            instance: true,
        });
        ClickMapEditorVM.currentZone = {
            x: 120,
            y: 90,
            width: 160,
            height: 120,
            name: 'Zone ' + ($scope.question.details.zones.length + 1),
            editing: true,
        };
        $scope.question.details.zones.push(ClickMapEditorVM.currentZone);
        ClickMapEditorVM.editingIndex = $scope.question.details.zones.length - 1;
        ClickMapEditorVM.ux.editingZone = true;
    }

    /* Cancel editing of a zone and get rid of area select tool */
    function cancelEdits(zone) {
        doneEditingHelper(zone);
    }

    /* Check whether to disable a set of edit/delete buttons */
    function disableEdits(index) {
        return (ClickMapEditorVM.editingIndex || ClickMapEditorVM.editingIndex === 0) && (ClickMapEditorVM.editingIndex !== index);
    }

    /* Save a new or edited zone */
    function saveZone(zone) {
        _.forEach(ClickMapEditorVM.currentZone, function(val, key) {
            zone[key] = val;
        });
        doneEditingHelper(zone);
    }

    /* Choose an image using the filepicker.io tool */
    function chooseImage($event) {
        $rootScope.uploadImage().then(function(image) {
            $scope.question.details.imageUrl = image.url;
            $scope.question.details.imageWidth = image.width;
            $scope.question.details.imageHeight = image.height;
        }, function(reason) {
            if (reason) {
                $timeout(function() {
                    ClickMapEditorVM.ux.imageError = reason;
                });
            }
        });
    }
}
