import {
    Animators, Components, Scales,
} from 'plottable/plottable';
import chartsConstant from './charts-constant';
import '../common-components/pub-sub';
import './axis-label-images';
import './chart-tooltips';
import './charts-angled-axis-labels';
import myChartUtils from './charts-utils';

const Plottable = {
    Animators: Animators,
    Components: Components,
    Scales: Scales,
};

angular.module('chart', [
    'pubSub',
    'chart.axisLabelImages',
    'chart.tooltips',
    'charts.angledAxisLabels',
])
    .factory('Chart', chart);
chart.$inject = [
    '$q',
    '$timeout',
    'pubSubService',
    'AxisLabelImages',
    'Tooltips',
    'angledAxisLabels',
];
/**
 * @param $q
 * @param $timeout
 * @param pubSubService
 * @param AxisLabelImages
 * @param Tooltips
 * @param angledAxisLabels
 */
function chart($q, $timeout, pubSubService, AxisLabelImages, Tooltips, angledAxisLabels) {
    const HORIZONTAL = 'horizontal';
    const VERTICAL = 'vertical';
    var CHART_EVENTS = {
        ANCHOR_COMPLETE: 'anchor_complete',
        Y_AXIS_ANCHOR_COMPLETE: 'y_axis_anchor_complete',
        X_AXIS_ANCHOR_COMPLETE: 'x_axis_anchor_complete',

        REDRAW_COMPLETE: 'redraw_complete',
        FLIP_AXIS_COMPLETE: 'flip_axis_complete',

        DATASET_UPDATE_COMPLETE: 'dataset_update_complete',
        DATA_LOAD_COMPLETE: 'data_load_complete',

        RESIZE_START: 'resize_start',
        RESIZE_COMPLETE: 'resize_complete',

        Y_AXIS_RESIZE_COMPLETE: 'axis_resize_complete',
        X_AXIS_RESIZE_COMPLETE: 'axis_resize_complete',

        PLOT_CENTERING_UPDATE: 'plot_centering_update',
        PLOT_INTERACTIONS_TOGGLED: 'plot_interactions_toggled',
    };

    /**
     * @param message
     */
    function chartLogger(message) {
        // Console.log(message);
    }

    /**
     *
     */
    function chart() {
        // Default settings -- assume vertical chart
        var _enableChartTooltip = false,
            _showChartTooltipTrackingLine = false,
            _enableLegend = false,
            _enablePanZoom = false,
            _plotType = [chartsConstant.PLOTTABLE_PLOT_TYPES.BAR],
            _isPublic = false,
            _isTracker = false,
            _plugins = null,
            _orientation = VERTICAL,
            clickListener,
            clickLegend,
            clickPlotgroup,
            _chartTooltips,
            _categoryAxis,

            dataLoadIntervalTimer,
            chartAnchorCompleteTimer,
            xAxisForegroundLoadTimer,
            yAxisForegroundLoadTimer,
            chartResizeCompleteTimer,
            chartRenderTimeout,
            chartRenderedPromise,
            _width,
            _height,
            _renderContainer = null,

            _labels = null,
            _legend = null,
            _svgReplacer,

            _colorScale = new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY),
            animator = new Plottable.Animators.Easing().stepDuration(200).maxTotalDuration(1000).stepDelay(350),

            _table = {},
            _destroySubscriptionAfterCall = {},
            _isFlippingAxis = false;

        /**
         * @param item
         */
        function _getTextNodeSize(item) {
            var textElements = item.selectAll('text');
            if (textElements.empty()) {
                return 0;
            }
            return item.selectAll('text').nodes().reduce(function(acc, textEl) {
                return acc + textEl.getComputedTextLength();
            }, 0);
        }

        // TODO: @samuelmburu keeping this here to use some of this logic for 0º
        // roatated labels this is taking longer than expected so stopping for now
        /**
         * @param _data
         */
        function _ensureAxisTextLabelsAreLegible(_data) {
            if (!_isVertical) {
                return;
            } // Only deal with vertical charts for now
            var data = _data || {
                    axis: 'xAxis',
                },
                // At the end of the day, workingAxis needs to be a Categorical Axis. this crazy ternary should ensure it.
                workingAxis = (data.axis === 'xAxis') === data.isCategorical ? _xAxis : _yAxis,
                tickLabels = workingAxis._tickLabelContainer.selectAll('.tick-label'),
                axisLabels = tickLabels.selectAll('.text-container'),
                angledTickLabelAngle = -90,
                angledTickLabelPadding = 7,
                angledLabelBreathingRoom = 10,
                numLabelsWithWrappingText = axisLabels.nodes().map(function(item) {
                    // Get width of all text items in
                    return _getTextNodeSize(d3.select(item));
                }).reduce(function(acc, textElWidth) {
                    return textElWidth > workingAxis.getScale().rangeBand() ? ++acc : acc;
                }, 0);
            // NumAxisLabelsTextEls = axisLabels.selectAll('.text-line').reduce(function(acc, item) { return acc+item.length; }, 0);

            /** * TODO remove all tickLabels that have images */

            // flipping based on if 1/2 or more of the axislabels have wrapping text
            if (numLabelsWithWrappingText * 2 >= axisLabels.nodes().length) {
                // Get Plottable to format the text as one line before final rotation
                if (workingAxis.tickLabelAngle() !== angledTickLabelAngle) {
                    workingAxis.addClass('chart-hidden'); // Hide labels while they are repositioned
                    workingAxis.tickLabelAngle(angledTickLabelAngle) // Flipping labels -90º; the provided function only allows [0, 90, -90] :doh:
                        .tickLabelPadding(angledTickLabelPadding); // Shrink padding to bring text closer to plot

                    chartLogger('we came here so we neeed to run this again');

                    // NOTE we need to call return here since setting the tickLabelAngle will cause the table to redraw it self
                    //  at which point the table redraw/resize event will be triggered causing the labels to redraw themselves bypassing this if block
                    return;
                }
                // If angle set to -90 already then rotate -45

                // set rotation on each tick-label
                // NOTE for consistent cross-browser svg rotations we need to provide a center point around which each of the
                // tick labels is rotated
                _.forEach(tickLabels.nodes(), function(selectedLabelEl) {
                    var currentTransform;

                    if (d3.select(selectedLabelEl).classed('tick-label--has-image')) {
                        return;
                    } // Do not rotate labels with images

                    currentTransform = myChartUtils.getTokenizedTransformValue(selectedLabelEl.getAttribute('transform'));
                    if (!currentTransform || _.isEmpty(currentTransform)) {
                        return;
                    }
                    var bbox = selectedLabelEl.getBBox(),
                        rotationXAdjustment = bbox.x + bbox.width / 2.0,
                        yTranslation = currentTransform.translate[1] || 0, // When no y translation ensure we a number value AKA zero
                        rotationYAdjustment = yTranslation + (Math.exp(Math.PI / 6) + Math.exp(-Math.PI / 6)) / 2 * angledLabelBreathingRoom,
                        updatedTransform = [];

                    for (var type in currentTransform) {
                        if (type === 'rotate') {
                            continue;
                        } // Skip rotate if it is present

                        updatedTransform.push(type + '(' + currentTransform[type].join(',') + ')');
                    }

                    // Update transform attribute
                    selectedLabelEl.setAttribute('transform', updatedTransform.join(' ') + ' rotate(60, ' + rotationXAdjustment + ', ' + rotationYAdjustment + ')');
                    selectedLabelEl.setAttribute('class', selectedLabelEl.getAttribute('class') + ' tick-label--is-rotated');
                });
                // Show axis-labels
                workingAxis.removeClass('chart-hidden');
            }
            // TODO else should remove the .tick-label--is-rotated if present and also any rotation transforms
        }

        function replaceSVG() {
            var replacer = myChart.svgReplacer();
            if (replacer.new && replacer.old) {
                replacer.old.parentNode.replaceChild(replacer.new, replacer.old);
                myChart.svgReplacer(null, null);
            }
        }

        /**** PUBLIC ****/
        function myChart() {
            // Update table with latest components
            _table = myChart.table();

            // Branding class and orientation
            _table.addClass('survata');

            // _applyPadProportionToQuantitativeScales();

            _table.onAnchor(function() {
                replaceSVG();

                chartLogger(_renderContainer, _table, 'anchor start');

                // To ensure that the chart in the DOM has the parts we need we set a timer to check
                // this is necessary because we are not guaranteed that the chart is fully rendered
                // in the DOM when the onAnchor is fired
                chartAnchorCompleteTimer = setInterval(function() {
                    if (myChart.table().content() && !myChart.table().content().empty()) {
                        clearInterval(chartAnchorCompleteTimer);
                        chartLogger('chart anchor fired for...' + _renderContainer);
                        initializeChartPlugins();
                        chartRenderedPromise && chartRenderedPromise.resolve(myChart);
                        myChart.triggerEvent(myChart.EVENTS.ANCHOR_COMPLETE);
                        chartLogger(_renderContainer, _table, 'anchor complete');
                    }
                }, 350);
                // If (_isTracker || (myChart.data().length > 1)) {
                //     // myChart.applyInteractions();
                // }
            });

            function cleanUpResizeArtifacts() {
                var chartElement = myChart.table().rootElement().node();
                clearInterval(chartResizeCompleteTimer);
                chartResizeCompleteTimer = undefined;

                // Clear dimensions from table dataset
                chartElement.removeAttribute('data-sv-width');
                chartElement.removeAttribute('data-sv-height');
                chartElement.removeAttribute('data-sv-matching-count');

                // If rendered promise resolve it
                chartRenderedPromise && chartRenderedPromise.resolve(myChart);
                chartLogger('resize completed fired for...' + _renderContainer);
            }

            /**
             * @param dimensions
             */
            function chartResizeCompleteTimerHandler(dimensions) {
                var chartElement = myChart.table().rootElement().node(),
                    dimensionsMatchingCount = 3,
                    tableAttr = {};
                // If data-sv-width is present then we know we have dimensions to extract
                // NOTE  -- this is done because IE11 does not support the dataset attribute on SVG elements 🙅
                if (chartElement.getAttribute('data-sv-width')) {
                    tableAttr = {
                        width: parseFloat(chartElement.getAttribute('data-sv-width')),
                        height: parseFloat(chartElement.getAttribute('data-sv-height')),
                        dimensionsMatchingCount: parseFloat(chartElement.getAttribute('data-sv-matching-count')),
                    };
                }

                if (tableAttr.width && tableAttr.height) {
                    if (tableAttr.width === dimensions.width && tableAttr.height === dimensions.height) {
                        // We must be done resizing since our values haven't changed since last time
                        // callTriggerFn();
                        if (tableAttr.dimensionsMatchingCount) {
                            // Increment dimensions matching count
                            chartElement.setAttribute('data-sv-matching-count', ++tableAttr.dimensionsMatchingCount);
                            if (tableAttr.dimensionsMatchingCount === dimensionsMatchingCount) {
                                // We have 3 consecutive matching dimensions triggerEvent
                                cleanUpResizeArtifacts();

                                initializeChartPlugins();

                                myChart.triggerEvent(myChart.EVENTS.RESIZE_COMPLETE);

                                chartLogger(_renderContainer, _table, 'resizing complete');
                            }
                        }
                        else {
                            // Set match count to 1
                            chartElement.setAttribute('data-sv-matching-count', 1);
                        }
                    }
                    else {
                        // Clear stable count dimensions
                        chartElement.setAttribute('data-sv-matching-count', '');
                    }
                }

                chartElement.setAttribute('data-sv-width', dimensions.width);
                chartElement.setAttribute('data-sv-height', dimensions.height);
            }
            _table.onResize(function() {
                chartLogger(_renderContainer, _table, 'resizing start');

                // NOTE: Because this gets triggered before the FLIP_AXIS_COMPLETE event
                // we need to check if we are in the middle of flipping axis so to make
                // sure that everything is ok
                if (_isFlippingAxis || !_table._isAnchored) {
                    return;
                }
                // To ensure that the chart is finished resizing in the DOM we set a timer to check
                // if the size has been stable for 2 ticks of the clock (this info is stored in the DOM)
                // this is necessary because we are not guaranteed that the chart has finished reszing
                // when the onAnchor is fired
                // kill existing timer and get a new one
                clearInterval(chartResizeCompleteTimer);
                chartResizeCompleteTimer = undefined;
                chartResizeCompleteTimer = setInterval(function() {
                    var chartElement = myChart.table().element().node(),
                        dimensionsObj = {
                            width: chartElement.clientWidth,
                            height: chartElement.clientHeight,
                        };

                    chartResizeCompleteTimerHandler(dimensionsObj);
                }, 350);
            });
        } // End of myChart

        /*
        Finds all individual plots that are not superimposed meaning that
        they have their own data and need their own add-on

        returns list of objects with 2 keys plot, group
         */
        /**
         *
         */
        function getAllIndividualPlotsAndCorrespondingGroup() {
            var items,
                result = [];

            /**
             * @param group
             */
            function getOnePlotFromGroup(group) {
                // Find and return first plot in group
                return _.find(group.components(), function(component) {
                    return component.entities;
                });
            }

            // When we have no rows return empty array
            if (_table._rows && _table._rows.length) {
                items = _.flatten(_table._rows[0][0]._rows);

                for (var i = 0; i < items.length; i++) {
                    // Check for plot group
                    // // NOTE: need to do something more resilients
                    if (items[i] && items[i].components) {
                        // Get group with plot and the plot itself
                        // getting plot so we can extract the data from it
                        // getting the group so we know where to add our new layer
                        result.push({
                            group: items[i],
                            plot: getOnePlotFromGroup(items[i]),
                        });
                        // NOTE: this blatantly assumes that only plots are in the group and that the last item is a plot
                    }
                    else if (items[i] && items[i].innerTickLength) {
                        _categoryAxis = items[i];
                    }
                }
            }

            return result;
        }

        /**
         * Inits chart plugins called when table is first anchored
         */
        function initializeChartPlugins() {
            var groupPlotMap = getAllIndividualPlotsAndCorrespondingGroup(),
                pluginTypeList = [
                    'plotlabels',
                    'errorbars',
                    'tooltips',
                    'liftarrows',
                ];
            groupPlotMap.forEach(function(item, index) {
                // Kind of ugly, but necessary
                if (!_plugins || !_plugins[index]) {
                    return;
                }
                pluginTypeList.forEach(pluginType => {
                    if (pluginType in _plugins[index]) {
                        _plugins[index][pluginType]
                            .plot(item.plot)
                            .group(item.group)
                            .chart(myChart)
                            .init();
                    }
                });

                if (item.plot.datasets()[0].metadata().hasImages) {
                    if ('axisImageLabels' in _plugins[index]) {
                        // Update refs to group
                        _plugins[index].axisImageLabels.group(item.group);
                    }
                    else {
                        _plugins[index].axisImageLabels = (new AxisLabelImages())
                            .plot(item.plot)
                            .group(item.group)
                            .chart(myChart)
                            .init();
                    }
                }
                else if ('angledAxisLabels' in _plugins[index]) {
                    // Update refs to group
                    _plugins[index].angledAxisLabels.group(item.group);
                }
                else {
                    _plugins[index].angledAxisLabels = angledAxisLabels()
                        .plot(item.plot)
                        .group(item.group)
                        .chart(myChart)
                        .init();
                }
            });
        }

        /**
         * Takes object of objects that define and describe the plugins for each plots
         * Used by two methods initializeChartPlugins AND when retrieving specific plot addon instances
         *
         * @param  {object} val - keyed by plot number value of key is an object keyed by addon type
         * @returns {myChart} making this chainable
         */
        function plugins(val) {
            if (!arguments.length) {
                return _plugins;
            }

            _plugins = val;

            return myChart;
        }

        /**
         * @param val
         */
        function table(val) {
            if (!arguments.length) {
                return _table;
            }

            _table = val;

            return myChart;
        }

        myChart.enablePanZoom = enablePanZoom;
        myChart.enableErrorbars = enableErrorbars;
        myChart.plugins = plugins;
        myChart.hasPlugin = hasPlugin;
        myChart.addEventListener = addEventListener;
        myChart.applyInteractions = applyInteractions;
        myChart.buildChart = buildChart;
        myChart.chartTooltips = chartTooltips;
        myChart.clearEventListenerTimers = clearEventListenerTimers;
        myChart.destroy = destroy;
        myChart.enableChartTooltip = enableChartTooltip;
        myChart.height = height;
        myChart.isPublic = isPublic;
        myChart.isTracker = isTracker;
        myChart.moveAxisLineAndTicksToTopOfAxisGroup = moveAxisLineAndTicksToTopOfAxisGroup;
        myChart.redraw = redraw;
        myChart.removeEventListener = removeEventListener;
        myChart.render = render;
        myChart.renderContainer = renderContainer;
        myChart.setHorizontal = setHorizontal;
        myChart.setNumericalScaleDomainMax = setNumericalScaleDomainMax;
        myChart.showChartTooltipTrackingLine = showChartTooltipTrackingLine;
        myChart.svgReplacer = svgReplacer;
        myChart.swapAxis = swapAxis;
        myChart.table = table;
        myChart.triggerEvent = triggerEvent;
        myChart.width = width;
        myChart.orientation = orientation;
        myChart.categoryAxis = categoryAxis;
        myChart.getAllIndividualPlotsAndCorrespondingGroup = getAllIndividualPlotsAndCorrespondingGroup;

        /**
         *
         */
        function enablePanZoom() {
            return false;
        }

        /**
         *
         */
        function enableErrorbars() {
            return false;
        }

        /**
         * @param bool
         */
        function isTracker(bool) {
            if (!arguments.length) {
                return _isTracker;
            }
            _isTracker = bool;
            return myChart;
        }

        /**
         * @param string
         */
        function orientation(string) {
            if (!arguments.length) {
                return _orientation;
            }
            if (/vertical|horizontal/.test(string)) {
                _orientation = string;
            }
            return myChart;
        }

        /**
         * @param category
         */
        function categoryAxis(category) {
            if (!arguments.length) {
                return _categoryAxis;
            }
            _categoryAxis = category;
            return myChart;
        }

        /**
         * @param fragment
         * @param svg
         */
        function svgReplacer(fragment, svg) {
            if (!arguments.length) {
                return _svgReplacer;
            }
            _svgReplacer = {
                new: fragment,
                old: svg,
            };
            return myChart;
        }

        /**
         *
         */
        function clearEventListenerTimers() {
            clearInterval(dataLoadIntervalTimer);
            clearInterval(chartAnchorCompleteTimer);
            clearInterval(xAxisForegroundLoadTimer);
            clearInterval(yAxisForegroundLoadTimer);
            clearInterval(chartResizeCompleteTimer);
            $timeout.cancel(chartRenderTimeout);
            return myChart;
        }

        /**
         * @param isPublic
         */
        function isPublic(isPublic) {
            if (!angular.isDefined(isPublic)) {
                return _isPublic;
            }
            _isPublic = isPublic;

            return myChart;
        }

        /* NOTE only works for vertical charts at present */
        /**
         *
         */
        function setNumericalScaleDomainMax() {
            var numericScaleDomainMax,
                numericAxisKey = myChart.getCategoricAxis().orientation() === 'bottom' ? 'y' : 'x',
                numericScale = myChart[numericAxisKey + 'Scale'](),
                numericAxis = myChart[numericAxisKey + 'Axis'](),
                orthogonalAxisKey = numericAxisKey === 'x' ? 'y' : 'x',
                isPercentValue = myChart[orthogonalAxisKey + 'AxisLabel']().text().match(/%|percent/i),
                allDataUpperBound;

            if (myChart.enableErrorbars()) {
                allDataUpperBound = myChart.data().map(function(_dataset) {
                    return _.map(_dataset.data(), isPercentValue ? 'cntProp' : 'upperBound');
                });

                numericScaleDomainMax = Math.max.apply(null, _.flatten(allDataUpperBound));

                // Only set domainMax if numericScaleDomainMax > 0
                if (numericScaleDomainMax > 0) {
                    numericScale.domainMax(numericScaleDomainMax * 1.18);
                }
            }
            else {
                // When errorbars are dissabled set padproportion on numeric scale
                numericScale.autoDomain().padProportion(_getProperAxisPadProportionValue(numericAxis));
            }

            numericScale.padProportion(_getProperAxisPadProportionValue(numericAxis)); // Make sure chart data has enough breathing room

            return myChart;
        }

        /**
         * @param axis
         */
        function _getProperAxisPadProportionValue(axis) {
            if (myChart.isVertical() && axis.orientation().match(/left|right/i)) {
                return _labels && (_labels.orientation() === VERTICAL) ? 0.55 : 0.31; // NOTE .31 causes an issue in non-grid charts adding more vertical space than necessary -- the fix would be to let the chart object to know that it is part of a multiChart is one of many in a grid
            }

            return 0.65;
        }

        /**
         * @param plot
         * @param legendText
         */
        function _adjustOpacity(plot, legendText) {
            plot.attr('opacity', function(d, i, ds) { // NOTE: move this to a style
                return ds.metadata().name === legendText ? 1 : 0.2;
            });
        }

        function applyInteractions() {
            if (!_enableChartTooltip && !_enableLegend && !_enablePanZoom) {
                return;
            }

            if (!clickListener) {
                clickListener = new Plottable.Interactions.Click();
            }
            clickListener.enabled(true)
                .attachTo(_table);

            if (_enableChartTooltip) {
                _chartTooltips = new Tooltips(myChart, _showChartTooltipTrackingLine);
            }

            if (_enableLegend) {
                if (!clickLegend) {
                    clickLegend = new Plottable.Interactions.Click();
                }
                clickLegend.enabled(true)
                    .attachTo(_legend)
                    .onClick(function(p) {
                        if (_legend.entitiesAt(p)[0] !== undefined) {
                            var selected = _legend.entitiesAt(p)[0].datum;

                            _.forEach(myChart.plot(), function(_plot) {
                                _adjustOpacity(_plot, selected);
                            });
                        }
                    });

                if (!clickPlotgroup) {
                    clickPlotgroup = new Plottable.Interactions.Click();
                }
                clickPlotgroup.enabled(true)
                    .attachTo(myChart.plotGroup())
                    .onClick(function() {
                        _.forEach(myChart.plot(), function(_plot) {
                            _plot.attr('opacity', 1);
                        });
                    });
            }
        }

        /**
         * @param value
         */
        function chartTooltips(value) {
            if (!arguments.length) {
                return _chartTooltips;
            }
            _chartTooltips = value;

            return myChart;
        }

        // NOTE: builds chart object, it MUST be used last when creating a chart
        /**
         *
         */
        function buildChart() {
            myChart(); // QUESTION: is there a way to make this more purrrdy?

            return myChart;
        }

        // Re/renders chart into DOM
        // when complete fires table.onResize which resolves the promise when resize
        // is complete -- TODO make the promises more contextual or highly generic
        /**
         *
         */
        function render() {
            // TODO clean up some of this it's a mess @samuelmburu
            chartRenderTimeout = $timeout(function() {
                chartRenderedPromise = $q.defer();

                if (myChart.table()._isAnchored && myChart.table()._isSetup) {
                    myChart.table().render(); // When table already exists and is anchored and setup in the DOM render
                }
                else {
                    let renderId = _renderContainer.slice(1, _renderContainer.length),
                        fragment = document.createDocumentFragment(),
                        svg = document.getElementById(renderId);
                    fragment.appendChild(svg.cloneNode(true));
                    myChart.svgReplacer(fragment, svg);
                    myChart.table().renderTo(fragment.childNodes[0]);
                }

                return chartRenderedPromise.promise;
            }, false); // We do not need to let angular know of anything so do not run inside of $apply
            return chartRenderTimeout;
        }

        /**
         *
         */
        function redraw() {
            chartRenderedPromise = $q.defer();

            if (_labels) {
                _labels.show(false); // QUESTION does it make sense to put this login in the plotLabel itself?
            }
            _table.redraw(); // When complete fires table.onResize which resolves the promise when resize is complete

            return chartRenderedPromise.promise;
        }

        // Configurable getters + setters
        /**
         * @param value
         */
        function width(value) {
            if (!arguments.length) {
                // When no value provided get it from the DOM
                return _width || myChart.table().element().node().clientWidth;
            }
            _width = value;

            return myChart;
        }

        /**
         * @param value
         */
        function height(value) {
            if (!arguments.length) {
                // When no value provided get it from the DOM
                return _height || myChart.table().element().node().clientHeight;
            }
            _height = value;

            // Make chainable
            return myChart;
        }

        /**
         *
         */
        function setHorizontal() {
            myChart.orientation(HORIZONTAL)
                .plot({
                    type: _plotType,
                    horizontal: true,
                })
                .xAxis(chartsConstant.PLOTTABLE_SCALES.LINEAR, 'bottom')
                .yAxis(chartsConstant.PLOTTABLE_SCALES.CATEGORY, 'left')
                .x(function(d, _i, _ds) {
                    return d.y;
                })
                .y(function(d, _i, _ds) {
                    return d.x;
                })
                .xAxisLabel('Respondents')
                .yAxisLabel('');

            return myChart;
        }

        /**
         * @param value
         */
        function enableChartTooltip(value) {
            if (!arguments.length) {
                return _enableChartTooltip;
            }
            _enableChartTooltip = value;

            return myChart;
        }

        /**
         * @param value
         */
        function showChartTooltipTrackingLine(value) {
            if (!arguments.length) {
                return _showChartTooltipTrackingLine;
            }
            _showChartTooltipTrackingLine = value;

            return myChart;
        }

        /* Helpers for swapAxis */

        /**
         * Searches the main table for an inner table whose contents need to
         * be flipped -- Presently this only works for _one_ nested table
         *
         * @returns {Plottable.Components.Table} Table whose contents need to be rotated
         */
        function getInnerTable() {
            // Logic needs to be a bit more robust 😄
            return _table._rows[0][0];
        }

        /**
         * Returns new axis label with the text from the current axis label
         * and angle orientation from the axis label on the opposite side of
         * the table
         *
         * @param       {object}  fromTable    - The table from which to copy axis label configuration from
         * @param       {object}  el           - current axis label
         * @param       {object}  destLocation - contains row and col info for destination location for new axis label
         * @returns      {object}               axis label with source axis label text and destination axis label orientation angle
         */
        function _getAxisLabel(fromTable, el, destLocation) {
            var angle = 0;

            // Get angle from axisLabel that this axis label will take its position
            if (fromTable.componentAt(destLocation.row, destLocation.col)) {
                angle = fromTable.componentAt(destLocation.row, destLocation.col).angle();
            }
            return new Plottable.Components.AxisLabel(el.text(), angle);
        }

        /**
         * Literally swaps orientation and x+y alignment for the opposing
         * axes that are switching places
         *
         * @param       {object} fromTable    - The table from which the axes are being swapped
         * @param       {object} el           - current axis
         * @param       {object} destLocation - object containing row and col info for destination location of current axis
         * @returns      {object}              object containing newly configured axes objects
         */
        function _getAxes(fromTable, el, destLocation) {
            // Because of the way that axes work we have to handle both of
            // them at the same time
            var elAtDestination = fromTable.componentAt(destLocation.row, destLocation.col),
                elAtDestinationAligment,
                elAtDestinationOrientation = elAtDestination.orientation(),
                currentElOrientation = el.orientation(),
                currentElAlignment,
                currentElTickAngle = el._tickLabelAngle || 0,
                elAtDestinationTickAngle = elAtDestination._tickLabelAngle || 0,
                destinationEl = elAtDestination,
                currentEl = el;

            // Configure orientation + tick label angle before detaching
            // otherwise we can't set it until it's added back to the DOM
            el.orientation(elAtDestinationOrientation);
            el._tickLabelAngle = elAtDestinationTickAngle;

            elAtDestination.orientation(currentElOrientation);
            elAtDestination._tickLabelAngle = currentElTickAngle;

            // Store both source (currentEl) and destination element
            // alignment configuration
            elAtDestinationAligment = {
                x: elAtDestination.xAlignment(),
                y: elAtDestination.yAlignment(),
            };
            currentElAlignment = {
                x: currentEl.xAlignment(),
                y: currentEl.yAlignment(),
            };

            // Configure alignment
            currentEl.xAlignment(elAtDestinationAligment.x).yAlignment(elAtDestinationAligment.y);
            destinationEl.xAlignment(currentElAlignment.x).yAlignment(currentElAlignment.y);

            // Detach axes for swapping later
            destinationEl = elAtDestination.detach();
            currentEl = el.detach();

            return {
                currentEl: currentEl,
                destinationEl: destinationEl,
            };
        }

        /**
         * Reconfigures existing plot object by swapping x and y scales and
         * accessors
         *
         * @param       {object} plot - The plot that is being reconfigured
         * @returns      {object}      The plot with a new configuration
         */
        function _getReconfiguredPlot(plot) {
            // Toggle horizontal/vertical orientatio
            plot._isVertical = !plot._isVertical;

            // Cache x + y scale and accessors so we don't loose refs to them
            // as we start setting them for the new orientation
            var futureXProps = {
                    scale: plot.y().scale,
                    accessor: plot.y().accessor,
                },
                futureYProps = {
                    scale: plot.x().scale,
                    accessor: plot.x().accessor,
                };

            // Update how the plot draws itsel
            plot.x(futureXProps.accessor, futureXProps.scale)
                .y(futureYProps.accessor, futureYProps.scale);

            // Technically we do not need to return it, but returning it felt
            // clearer as to what was happening
            return plot;
        }

        /**
         * Returns new group container with new gridlines and reconfigured
         * plot(s)
         *
         * @param       {object} fromTable    - The table containing the group element that is to be copied
         * @param       {object} el           - current group element from source table
         * @param       {object} destLocation - object containing row and col info for destination location of new group
         * @returns      {object}              new group container with new gridlines and reconfigured plots
         */
        function _getGroup(fromTable, el, destLocation) {
            var newGroup = new Plottable.Components.Group([]);

            // Create new gridlines and add them to non-DOM group element
            if (el.components()[1].orientation() === VERTICAL) {
                // When plot is vertical we need to add yScale as our x scale
                newGroup.append(new Plottable.Components.Gridlines(el.components()[1].y().scale, null));
            }
            else {
                newGroup.append(new Plottable.Components.Gridlines(null, el.components()[1].x().scale));
            }

            // Destroy existing gridlines -- they are the first item in the group
            el.components()[0].destroy();

            // Iterate over all components in group and configuring
            for (var i = 0; i < el.components().length; i++) {
                // Only copy over plots
                // QUESTION: will this work with errorbars??
                if (el.components()[i].entitiesAt) {
                    let plot = el.components()[i].detach();
                    // Add reconfigured plot to the group
                    newGroup.append(_getReconfiguredPlot(plot));
                }
            }

            return newGroup;
        }
        /* End Helpers for swapAxis */

        // Assumes square table or that the components being swapped are in a square
        // - legends aren't moved
        function swapAxis() {
            var tableToCopy = getInnerTable(),
                newTable = new Plottable.Components.Table(),
                rows = tableToCopy._rows,
                numCols = tableToCopy._nCols;

            // Set flag so that the resize event isn't triggered
            _isFlippingAxis = true;

            myChart.orientation(myChart.orientation() === HORIZONTAL ? VERTICAL : HORIZONTAL);

            // Iterate over columns and rows moving elements from their position
            // in DOM table to their opposite location in the non-DOM table
            for (var c = 0; c < numCols; c++) {
                for (var r = 0; r < rows.length; r++) {
                    if (tableToCopy.componentAt(r, c) !== null) {
                        // Get ref to element
                        let el = tableToCopy.componentAt(r, c),
                            newEl,
                            destLocation = {
                                col: rows.length - 1 - r,
                                row: numCols - 1 - c,
                            };

                        // Figure out what type of component we have
                        // then configure it
                        if (el.text && !el.endTickLength) {
                            // Axis label
                            newEl = _getAxisLabel(tableToCopy, el, destLocation);
                            newTable.add(newEl, destLocation.row, destLocation.col);
                        }
                        else if (el.endTickLength) {
                            // Axis
                            let newEls = _getAxes(tableToCopy, el, destLocation);
                            // Adding current axis el configured for destination location
                            newTable.add(newEls.currentEl, destLocation.row, destLocation.col);
                            // Adding opposite axis el configured for present location in new table
                            newTable.add(newEls.destinationEl, r, c);
                        }
                        else if (el.maxEntriesPerRow) {
                            // Legend -- stays in the same place
                            let legend = el.detach();
                            newTable.add(legend, r, c);
                        }
                        else if (el.append && el.components) {
                            // Group
                            let newEl = _getGroup(tableToCopy, el, destLocation);
                            newTable.add(newEl, destLocation.row, destLocation.col);
                        }
                        else if (el.append && el.entityNearest) {
                            // PlotGroup
                        }
                    }
                }
            }

            // Remove tableToCopy from the DOM
            tableToCopy.destroy();

            // Add new table to MainTable in the DOM
            _table.add(newTable, 0, 0);

            // Need to let all plugins know that the grouping element has been
            // updated so they know how to redraw themselves
            updatePluginsWithNewGroupingElement();

            // Set flag for flipping axis to false
            _isFlippingAxis = false;

            // Trigger chart anchored event so plugins can do their thing
            myChart.triggerEvent(myChart.EVENTS.FLIP_AXIS_COMPLETE);
        }

        function updatePluginsWithNewGroupingElement() {
            // Update all addon instances Grouping element property reference
            var items = getAllIndividualPlotsAndCorrespondingGroup();

            _.forEach(_plugins, function(pluginObj, groupIndex) {
                // Get ref to actual grouping element
                var groupEl = items[groupIndex].group,
                    listOfPluginsInThisGroup = _.values(pluginObj);

                // Iterate over all plugins in that group updating grouping element reference
                for (var i = 0; i < listOfPluginsInThisGroup.length; i++) {
                    listOfPluginsInThisGroup[i].group(groupEl);
                }
            });
        }

        function destroyPlugins() {
            // This is ugly, i know i wrote it, but in order to be suuper
            // flexible something has got to be ugly.. luckily this isn't run
            // super often
            _.forOwn(_plugins, groupEl => {
                _.forOwn(groupEl, addonInstance => {
                    if (addonInstance.chart()) {
                        addonInstance.destroy();
                        addonInstance = void 0;
                    }
                });
            });
        }

        function destroy() {
            destroyPlugins();
            clearEventListenerTimers();
            _table.destroy();
        }

        /**
         * @param value
         */
        function renderContainer(value) {
            if (!arguments.length) {
                return _renderContainer;
            }
            _renderContainer = value;

            // Make chainable
            return myChart;
        }

        // Getters only
        /**
         * @param axisString
         */
        function moveAxisLineAndTicksToTopOfAxisGroup(axisString) {
            var moveBaseLineTimer = setInterval(function() {
                var axisContents = d3.selectAll(myChart[axisString]().content().node().childNodes),
                    orderAndVerifyRegEx = /\btick-mark-container|\bbaseline\b/,
                    // Sort the data of the elements
                    dataSortedWithLineFirst = axisContents.nodes().sort(function(a, _) {
                        return orderAndVerifyRegEx.test(a.className.baseVal) ? -1 : 1;
                    }),
                    // Sort the DOM elements
                    sortedElementsWithLineFirst = d3.selectAll(dataSortedWithLineFirst).order(),
                    // Checking to see if the line+tick-mark-container are at top of axis group to stop the timer
                    allSorted = _.every(sortedElementsWithLineFirst.nodes().slice(0, 2), function(d) {
                        return orderAndVerifyRegEx.test(d.className.baseVal);
                    });

                // Stop timer if all elements are sorted appropriately
                if (allSorted) {
                    clearInterval(moveBaseLineTimer);
                }
            }, 20);
        }

        // Event handling awesomeness -- not sure about method naming 😬
        /**
         * @param evt
         * @param data
         */
        function triggerEvent(evt, data) {
            pubSubService.notify(_renderContainer + evt, [data]);
            if (_destroySubscriptionAfterCall[evt]) {
                _.forEach(_destroySubscriptionAfterCall[evt], function(id) {
                    removeEventListener(evt, id);
                });
                delete _destroySubscriptionAfterCall[evt];
            }
        }

        /**
         * @param evt
         * @param handler
         * @param id
         * @param destroyAfterCalled
         */
        function addEventListener(evt, handler, id, destroyAfterCalled) {
            id = id || _renderContainer;
            pubSubService.subscribe(_renderContainer + evt, id, handler);
            if (destroyAfterCalled) {
                _destroySubscriptionAfterCall[evt] = _destroySubscriptionAfterCall[evt] || [];
                _destroySubscriptionAfterCall[evt].push(id);
            }
        }

        /**
         * @param evt
         * @param id
         */
        function removeEventListener(evt, id) {
            pubSubService.destroy(_renderContainer + evt, id);
        }

        /**
         * @param pluginType
         */
        function hasPlugin(pluginType) {
            let plugin = _.find(_plugins, function(_plugin) {
                return _plugin[pluginType];
            });
            return plugin ? plugin[pluginType] : plugin;
        }

        // Expose static properties
        myChart.EVENTS = CHART_EVENTS;

        return myChart;
    }
    return chart;
}
