angular.module('numeric.editor', [])
    .directive('numericEditor', numericEditor)
    .controller('NumericEditorCtrl', NumericEditorCtrl);

function numericEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/numeric-editor.html',
        scope: {
            question: '=',
        },
        controller: 'NumericEditorCtrl',
        controllerAs: 'NumericEditorVM',
    };
}
NumericEditorCtrl.$inject = ['$rootScope'];

function NumericEditorCtrl($rootScope) {
    var NumericEditorVM = this;
    NumericEditorVM.auth = $rootScope.auth;
}
