import './chart-data-parser';

angular.module('chartsWidget.wrapper', [
    'chart.dataParser',
])
    .controller('ChartsWrapperCtrl', ChartsWrapperCtrl)
    .directive('chartsWrapper', chartsWrapper);

function chartsWrapper() {
    return {
        restrict: 'AE',
        scope: {
            question: '=',
            chartNum: '@',
            visualMetadata: '=?',
            params: '=?',
        },
        controller: 'ChartsWrapperCtrl',
        controllerAs: 'ChartWrapperVM',
        templateUrl: '/shared-templates/chart-widgets/charts-wrapper.html',
    };
}

ChartsWrapperCtrl.$inject = [
    '$scope', 'chartDataParser',
];

function ChartsWrapperCtrl($scope, chartDataParser) {
    var ChartWrapperVM = this;

    $scope.visualMetadata = $scope.visualMetadata || {};
    ChartWrapperVM.question = $scope.question;
    ChartWrapperVM.questionType = $scope.question.details.type;
    ChartWrapperVM.visualMetadata = $scope.visualMetadata || {};
    ChartWrapperVM.chartDisplayNum = $scope.chartNum.match(/^(screener-)?\d+$/) ? $scope.chartNum.replace('screener-', '') : '';
    ChartWrapperVM.isFreeResponse = chartDataParser.isFreeResponse(ChartWrapperVM.questionType);
    ChartWrapperVM.hidePlottableChart = chartDataParser.hidePlottableChart(ChartWrapperVM.question);
    ChartWrapperVM.isPlottableChart = chartDataParser.isPlottableChart(ChartWrapperVM.question);
}
