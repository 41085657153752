const medianIncomeCuts = {
    category: 'Median Income',
    categoryType: 'demographic',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    controlType: 'Equivalent',
    options: [{
        name: '$1 - 25,000',
        value: 'Median Income: 1-25,000',
        exposed: {
            $eq: ['Median Income Range of Postal Code', '$1-$25,000'],
        },

    },
    {
        name: '$25,001 - 50,000',
        value: 'Median Income: 25,001-50,000',
        exposed: {
            $eq: ['Median Income Range of Postal Code', '$25,001-$50,000'],
        },
    },
    {
        name: '$50,001 - 75,000',
        value: 'Median Income: 50,001-75,000',
        exposed: {
            $eq: ['Median Income Range of Postal Code', '$50,001-$75,000'],
        },
    },
    {
        name: '$75,001 - 100,000',
        value: 'Median Income: 75,001-100,000',
        exposed: {
            $eq: ['Median Income Range of Postal Code', '$75,001-$100,000'],
        },
    },
    {
        name: '$100,000+',
        value: 'Median Income: 100,000+',
        exposed: {
            $eq: ['Median Income Range of Postal Code', '$100,000+'],
        },
    },
    {
        name: 'No Data',
        value: 'NO_DATA_median_income',
        exposed: {
            $eq: ['Median Income Range of Postal Code', 'Not Available'],
        },
    }],
};

export default medianIncomeCuts;
