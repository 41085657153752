import * as h337 from 'heatmapjs';

angular.module('chartsWidget.clickMap', [])
    .directive('clickmap', clickmap);

function clickmap() {
    return {
        restrict: 'EA',
        scope: {
            questionDetails: '=',
            clicks: '=',
            zones: '=',
        },
        templateUrl: '/shared-templates/chart-widgets/clickmap.html',
        link: function($scope, $element) {
            var clickWeight = 0.7,
                browserWidth = $(window).width(),
                imgWidth = browserWidth > 600 ? 500 : 225,
                clickAdjust = 1.0,
                image = {
                    height: $scope.questionDetails.imageHeight,
                    width: $scope.questionDetails.imageWidth,
                    image: $scope.questionDetails.imageUrl,
                },
                heatmapConfig = {
                    maxOpacity: 0.65,
                    minOpacity: 0.0,
                    blur: 0.6,
                    gradient: {
                        '.25': 'rgb(0,0,255)',
                        '.65': 'rgb(0,255,0)',
                        '.75': 'yellow',
                        '.90': 'rgb(255,0,0)',
                    },
                };

            heatmapConfig.radius = browserWidth > 600 ? 22 : 10;

            $scope.zones = $scope.zones || [];
            $scope.zoneAdjust = browserWidth > 600 ? 1.67 : 0.75;

            $scope.ready = false;
            $scope.dimensions = {};
            $scope.dimensions.width = imgWidth;
            $scope.dimensions.height = image.height / image.width * $scope.dimensions.width;

            // Instantiate the heatmap
            heatmapConfig.container = $element.find('.clickmap')[0];

            var heatmapInstance = h337.create(heatmapConfig);

            // Load all data points into the heatmap
            $scope.$watch('clicks', function(clickData) {
                if (!clickData) {
                    return;
                }
                // Adjust click weight based on how many clicks there are
                if (clickData.length <= 50) {
                    clickWeight = 0.85;
                }
                else if (clickData.length <= 65) {
                    clickWeight = 0.75;
                }

                clickAdjust = clickData.adjust || 1.0;

                var dataPoints = [];
                _.forEach(clickData, function(clickNum) {
                    _.forEach(clickNum.clicks, function(click) {
                        dataPoints.push({
                            x: click[0] * clickAdjust,
                            y: click[1] * clickAdjust,
                            value: clickWeight,
                        });
                    });
                });
                heatmapInstance.setData({
                    min: 0,
                    max: 2,
                    data: dataPoints,
                });
                $scope.ready = true;
            });
        },
    };
}
