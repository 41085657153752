import U from '../../../../common/js/util';
import '../../../../common/js/inc/sv-notify';
import '../common-components/pub-sub';
import '../survey/survey-validation-service';
import '../question-types/question-type-groups';
import '../survey/api-survey-service';
import '../survey-dashboard/survey-dashboard-service';
import '../survey-creation/survey-creation-service';
import surveyCreationConstant from '../survey-creation/survey-creation-constant';
import '../survey/survey-statuses';

angular.module('questionsEditor.view', [
    'svNotify',
    'pubSub',
    'questionTypeGroups.service',
    'surveyValidation.service',
    'surveyStatusesService',
    'surveyDashboardService',
    'apiSurveyService',
    'surveyCreation.service',
])
    .controller('QuestionsEditorCtrl', QuestionsEditorCtrl)
    .directive('questionsEditor', questionsEditor);
questionsEditor.$inject = [
    '$rootScope', '$timeout',
];

/**
 * @param $rootScope
 * @param $timeout
 */
function questionsEditor($rootScope, $timeout) {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/questions-editor.html',
        scope: {
            activeQuestion: '=',
            activeJson: '=',
        },
        link: function(scope, _elem, _attr, QuestionsEditorVM) {
            QuestionsEditorVM.chooseFile = chooseFile;
            QuestionsEditorVM.ux = {
                showDropZones: false,
                isDragging: false,
                imageDnD: {},
            };

            /* Watch drag and drop position to update image position for active question */
            scope.$watch('QuestionsEditorVM.ux.imgDnD', function() {
                _.forEach(QuestionsEditorVM.ux.imgDnD, function(v, k) {
                    scope.activeQuestion.statement.image.position = v ? k : scope.activeQuestion.statement.image.position;
                });
                QuestionsEditorVM.ux.showDropZones = false;
            }, true);

            /* Trigger resize of text area when drop zones appear or disappear */
            scope.$watch('QuestionsEditorVM.ux.showDropZones', function() {
                $timeout(function() {
                    $('.question-statement').trigger('autosize.resizeIncludeStyle');
                }, 1);
            });
            /* Sync value of image position to the local drag and drop variable */
            scope.$watch('activeQuestion.statement.image.position', function() {
                QuestionsEditorVM.ux.imgDnD = {};

                if (scope.activeQuestion && scope.activeQuestion.statement && scope.activeQuestion.statement.image) {
                    QuestionsEditorVM.ux.imgDnD[scope.activeQuestion.statement.image.position] = true;
                }
            }, true);

            /* Pick a file using the filepicker */

            /****** Functions required for image upload and drag and drop ******/
            function chooseFile() {
                $rootScope.uploadImage().then(function(image) {
                    if (scope.activeQuestion && scope.activeQuestion.type && image) {
                        $timeout(function() {
                            scope.activeQuestion.statement.image = {
                                url: image.url,
                                position: 'bottom',
                            };
                            QuestionsEditorVM.ux.imgDnD = {
                                bottom: true,
                            };
                        });
                    }
                },
                function(reason) {
                    if (reason) {
                        QuestionsEditorVM.ux.imageError = reason;
                    }
                });
            }
        },
        controller: 'QuestionsEditorCtrl',
        controllerAs: 'QuestionsEditorVM',
    };
}
QuestionsEditorCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$notify',
    'pubSubService',
    'surveyValidationService',
    'questionTypeGroupsService',
    'surveyService',
    '$dashboardService',
    'surveyCreationService',
    'surveyStatusesService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $notify
 * @param pubSubService
 * @param SVS
 * @param QTGS
 * @param surveyService
 * @param $dashboardService
 * @param surveyCreationService
 * @param surveyStatusesService
 */
function QuestionsEditorCtrl($rootScope, $scope, $notify, pubSubService, SVS, QTGS, surveyService, $dashboardService, surveyCreationService, surveyStatusesService) {
    var QuestionsEditorVM = this,
        QuestionTypes = QTGS.questionTypes,
        ERROR_MESSAGES = surveyCreationConstant.get('ERROR_MESSAGES'),
        isIe = U.isIe(),
        isFirefox = U.isFirefox(),
        focusElement;
    QuestionsEditorVM.survey = $rootScope.survey;
    QuestionsEditorVM.auth = $rootScope.auth;
    QuestionsEditorVM.funnel = $rootScope.funnel;
    QuestionsEditorVM.ux = {};
    QuestionsEditorVM.questionTypeName = QTGS.questionTypeName;
    QuestionsEditorVM.canAddVideo = canAddVideo;
    QuestionsEditorVM.canAddImage = canAddImage;
    QuestionsEditorVM.isXChooseY = isXChooseY;
    QuestionsEditorVM.isAdvancedSurvey = surveyCreationService.isAdvancedSurvey;
    QuestionsEditorVM.showCharCount = $rootScope.showCharCount;
    QuestionsEditorVM.charCountColor = $rootScope.charCountColor;
    QuestionsEditorVM.getValidJson = getValidJson;
    QuestionsEditorVM.unselectJson = unselectJson;

    QuestionsEditorVM.saveJson = saveJson;
    QuestionsEditorVM.save = save;
    QuestionsEditorVM.remove = remove;
    QuestionsEditorVM.cancelEdits = cancelEdits;
    QuestionsEditorVM.insertText = insertText;
    QuestionsEditorVM.setupVideo = setupVideo;
    QuestionsEditorVM.chooseVideo = chooseVideo;

    QuestionsEditorVM.getMaxQuestionChars = getMaxQuestionChars;
    QuestionsEditorVM.getMaxUserQuestionChars = getMaxUserQuestionChars;
    QuestionsEditorVM.getStatementPlaceholder = getStatementPlaceholder;

    init();

    function init() {
        pubSubService.subscribe('update-save-in-progress', $scope.$id, updateSaveInProgress);
        pubSubService.subscribe('update-check-question-errors', $scope.$id, updateCheckQuestionErrors);
        $scope.$on('$destroy', function() {
            pubSubService.destroy('update-save-in-progress', $scope.$id);
            pubSubService.destroy('update-check-question-errors', $scope.$id);
        });
    }

    /**
     * @param bool
     */
    function updateSaveInProgressAndNotify(bool) {
        updateSaveInProgress(bool);
        pubSubService.notify('update-save-in-progress', bool);
    }

    /**
     * @param bool
     */
    function updateCheckQuestionErrors(bool) {
        QuestionsEditorVM.ux.checkQuestionErrors = !!bool;
    }

    /**
     * @param bool
     */
    function updateSaveInProgress(bool) {
        $scope.$evalAsync(() => {
            QuestionsEditorVM.ux.saveInProgress = !!bool;
        });
    }

    function updateQuestionCount() {
        QTGS.updateQuestionCount($rootScope.survey.questions, $rootScope.funnel);
    }

    /**
     *
     */
    function canAddVideo() {
        return $scope.activeQuestion.type && ($scope.activeQuestion.type === 'introStatement' || $rootScope.auth.user.isAdmin || surveyStatusesService.isAdvancedFlow()) &&
            !$scope.activeQuestion.statement.image && !$scope.activeQuestion.statement.video;
    }

    /**
     *
     */
    function canAddImage() {
        return $scope.activeQuestion.type && $scope.activeQuestion.type !== 'clickmap' &&
            !$scope.activeQuestion.statement.image && !$scope.activeQuestion.statement.video;
    }

    /**
     * @param question
     */
    function isXChooseY(question) {
        if (question.details.choicesToDisplay) {
            var max = question.details.choicesToDisplay + question.details.specifyOptionEnabled + question.details.hasNoneOfTheAbove;
            return question.details.choices.length > max;
        }

        return false;
    }

    // Determine whether this is a valid json string
    /**
     * @param js
     */
    function getValidJson(js) {
        var result = true;
        try {
            JSON.parse(js);
        }
        catch (err) {
            result = false;
        }
        return result;
    }

    function unselectJson() {
        $scope.activeJson = {};
    }

    // Save a question's raw json
    function saveJson() {
        var active = $scope.activeJson,
            old = surveyCreationService.getQuestion(active.uuid, active.screener);

        active.statement = JSON.parse(active.statement);
        active.details = JSON.parse(active.details);

        old.statement = active.statement;
        old.details = active.details;
        updateSaveInProgressAndNotify(true);
        surveyService.updateQuestion(active.uuid, active).then(function() {
            updateSaveInProgressAndNotify(false);
        }, function() {
            updateSaveInProgressAndNotify(false);
        });
        $scope.activeJson = null;
    }

    // Update: save the active question.
    function save() {
        let errors = SVS.questionErrors($scope.activeQuestion);
        // Check errors first.
        QuestionsEditorVM.ux.checkQuestionErrors = true;
        if (errors.length) {
            QuestionsEditorVM.errors = errors;
            return;
        }
        QuestionsEditorVM.errors = null;

        // Notify child scope that we're saving
        pubSubService.notify('question-pre-save', []);

        var active = $scope.activeQuestion,
            old = surveyCreationService.getQuestion(active.uuid, active.screener);

        // Clean response choices so only choices corresponding to selected response type remain
        if (active.type === QuestionTypes.SINGLE_SELECT || active.type === QuestionTypes.MULTI_SELECT || active.type === QuestionTypes.RANKING || (active.type === QuestionTypes.GRID && active.details.gridType !== 'numeric')) {
            let cleanedChoices = [],
                responseType = active.details.responseType || 'text';

            _.forEach(active.details.choices, function(c) {
                //Don't save falsy values
                _.forEach(c, function(value, key) {
                    if (!value) {
                        delete c[key];
                    }
                });
                if (responseType === 'text' && c.text) {
                    delete c.image;
                    cleanedChoices.push(c);
                }
                else if (responseType === 'image' && c.image) {
                    delete c.text;
                    cleanedChoices.push(c);
                }
                else if (c.specifyOptionEnabled || c.noneOfTheAbove) {
                    cleanedChoices.push(c);
                }
            });
            active.details.choices = angular.copy(cleanedChoices);
        }

        if (old) {
            _.forEach(active, function(e, i) {
                old[i] = angular.copy(e);
            });
            _.forEach(old, function(e, i) {
                if (!active[i]) {
                    delete old[i];
                }
            });

            if (active.statement.image === null) {
                delete active.statement.image;
            }
            if (active.statement.video === null) {
                delete active.statement.video;
            }

            updateSaveInProgressAndNotify(true);
            surveyService.updateQuestion(active.uuid, active).then(function() {
                updateSaveInProgressAndNotify(false);
                $scope.activeQuestion = null;
                updateQuestionCount();
            }, function() {
                QuestionsEditorVM.ux.serverError = ERROR_MESSAGES.SAVE_QUESTION_ERROR;
                updateSaveInProgressAndNotify(false);
            });
        }
        else {
            updateSaveInProgressAndNotify(true);
            surveyService.createQuestion($rootScope.survey, active).then(function(question) {
                if (active.screener) {
                    $rootScope.survey.screeningQuestions = $rootScope.survey.screeningQuestions || [];
                    $rootScope.survey.screeningQuestions.push(question);
                }
                else {
                    $rootScope.survey.questions = $rootScope.survey.questions || [];
                    $rootScope.survey.questions.push(question);
                }
                updateSaveInProgressAndNotify(false);
                $scope.activeQuestion = null;
                updateQuestionCount();
                pubSubService.notify('reset-programming-metadata', [question]);
            }, function() {
                QuestionsEditorVM.ux.serverError = ERROR_MESSAGES.SAVE_QUESTION_ERROR;
                updateSaveInProgressAndNotify(false);
            });
        }
    }

    /**
     * @param uuid
     */
    function remove(uuid) {
        surveyCreationService.removeQuestion(uuid);
        if ($scope.activeQuestion && $scope.activeQuestion.uuid === uuid) {
            $scope.activeQuestion = null;
        }
    }

    // Notify child scopes to cancel all question edits
    function cancelEdits() {
        $scope.activeQuestion = null;
        pubSubService.notify('cancel-question-edits', []);
    }

    /**
     * @param text
     */
    function insertText(text) {
        if (!focusElement) {
            $notify.error('Cursor location has not yet been placed.');
            return;
        }
        var input = focusElement,
            scrollPos = input.scrollTop,
            pos = 0,
            range,
            front,
            back;

        if (isIe) {
            input.focus();
            range = document.selection.createRange();
            range.moveStart('character', -input.value.length);
            pos = range.text.length;
        }
        else if (isFirefox) {
            pos = input.selectionStart;
        }
        front = input.value.substring(0, pos);
        back = input.value.substring(pos, input.value.length);
        input.value = front + text + back;
        pos += text.length;
        if (isIe) {
            input.focus();
            range = document.selection.createRange();
            range.moveStart('character', -input.value.length);
            range.moveStart('character', pos);
            range.moveEnd('character', 0);
            range.select();
        }
        else if (isFirefox) {
            input.selectionStart = pos;
            input.selectionEnd = pos;
            input.focus();
        }
        input.scrollTop = scrollPos;
        angular.element(input).trigger('input');
    }

    function setupVideo() {
        QuestionsEditorVM.ux.videoMessage = null;

        if ($scope.activeQuestion.statement.video.creative) {
            $scope.activeQuestion.statement.video.embedUrl = 'creative';
            $scope.activeQuestion.statement.video.embedType = 'creative';
            return;
        }
        var urlEmbed = $dashboardService.setupVideo($scope.activeQuestion.statement.video.url);
        if (!urlEmbed) {
            QuestionsEditorVM.ux.videoMessage = ERROR_MESSAGES.VIDEO_TYPE_UNSUPPORTED;
            return;
        }
        $scope.activeQuestion.statement.video = U.extend($scope.activeQuestion.statement.video, urlEmbed);
    }

    function chooseVideo() {
        $scope.activeQuestion.statement.video = {
            url: '',
            position: 'bottom',
            embedUrl: '',
            embedType: '',
        };
    }

    /**
     *
     */
    function getMaxQuestionChars() {
        return $rootScope.auth && $rootScope.auth.user && $rootScope.auth.user.isAdmin ? 1024 : getMaxUserQuestionChars();
    }

    /**
     *
     */
    function getMaxUserQuestionChars() {
        return $rootScope.funnelType === 'advanced' ? 1024 : 256;
    }

    // Return the appropriate question statement placeholder text
    /**
     *
     */
    function getStatementPlaceholder() {
        if (!$scope.activeQuestion) {
            return '';
        }
        return QTGS.isIntroStatement($scope.activeQuestion) ? 'Enter an intro heading (i.e ' + '“' + 'Introduction' + '”' + ')' : 'Enter your question here';
    }
}
