import '../../../common-components/pub-sub';
import '../../../common-components/modal-service';
import '../../../common-components/sv-dialog';
import '../analysis-config-manager';
import analysisConfigDataService from '../analysis-config-data-service';
import analysisConfigConstants from '../analysis-config-constants';
import customCutsConstants from './custom-cuts-constants';
import '../analysis-plan.service';
import './analysis-cut';
import './mass-cut-creator';

angular
    .module('customCuts.view', [
        'common.dialog',
        'pubSub',
        'analysisCut',
        'massCutCreator',
        'modalService',
        'analysisPlan.service',
        'analysisConfig.manager',
    ])
    .directive('customCuts', customCuts)
    .controller('CustomCutsCtrl', CustomCutsCtrl);

/**
 * Custom cuts view directive.
 *
 * @returns {object} - Custom cut directive definition object
 */
function customCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/custom-cuts.html',
        controller: 'CustomCutsCtrl',
        controllerAs: 'CustomCutsVM',
    };
}

CustomCutsCtrl.$inject = [
    '$scope',
    'dialog',
    'pubSubService',
    'modalService',
    'analysisPlanService',
];

/**
 * Controller for custom cuts view.
 *
 * @param {object} $scope - The scope for the custom cut view
 * @param {object} dialog - Service for handling custom modals
 * @param {object} pubSubService - Pub/sub service
 * @param {object} modalService - Service for popping confim modal
 * @param {object} analysisPlanService - Data handling for analysis plan
 */
function CustomCutsCtrl($scope, dialog, pubSubService, modalService, analysisPlanService) {
    var CustomCutsVM = this;
    let cutCategoryMap = [];
    let allCategoriesAndCuts = [];

    // VM exposed variables
    CustomCutsVM.tableHeaders = customCutsConstants.tableHeaders;
    CustomCutsVM.tableOptions = {};
    CustomCutsVM.customCuts = analysisPlanService.getCustomCuts();

    // VM exposed functions
    CustomCutsVM.openCutEditor = openCutEditor;
    CustomCutsVM.openMassCutCreator = openMassCutCreator;

    init();

    function init() {
        refreshCategoriesAndCuts();

        pubSubService.subscribe('open-cut-editor', $scope.$id, openCutEditor);
        pubSubService.subscribe('delete-cut', $scope.$id, deleteCut);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(['open-cut-editor', 'delete-cut'], $scope.$id);
        });
    }

    /**
     * Refresh list of categories and cuts.
     */
    function refreshCategoriesAndCuts() {
        analysisPlanService.getAllCategoriesAndCuts().then(response => {
            allCategoriesAndCuts = response;
            cutCategoryMap = _.groupBy(allCategoriesAndCuts, 'analysisCutCategoryType');
        });
    }

    /**
     * Open custom cut editor.
     *
     * @param {object} [cut] - Cut to edit
     */
    function openCutEditor(cut) {
        cut = cut || {};
        if (cut.name === 'Overall' || cut.editing) {
            return;
        }
        const cutName = cut.name,
            editor = dialog.popup({
                templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/cuts/analysis-cut.html',
                controller: 'AnalysisCutCtrl',
                controllerAs: 'AnalysisCutVM',
                windowClass: 'analysis-config modal-sv--full-height modal-sv--full-width',
                resolve: {
                    cut: () => {
                        return {
                            name: cutName,
                            analysisCutCategory: cut.analysisCutCategory,
                            type: cut.type || analysisConfigConstants.cutControlTypes[0],
                            exposed: cut.exposed,
                            control: cut.control,
                            uuid: cut.uuid,
                        };
                    },
                    customCutCategories: () => {
                        return cutCategoryMap.custom;
                    },
                },
            });

        editor.result.then(updatedCut => {
            if (updatedCut) {
                analysisPlanService.setPlainEnglishLabelsForCut(updatedCut);

                // User was updating an existing cut
                if (cutName) {
                    const idx = _.findIndex(CustomCutsVM.customCuts, {
                        uuid: updatedCut.uuid,
                    });
                    CustomCutsVM.customCuts[idx] = updatedCut;
                }
                // A new cut was created
                else {
                    CustomCutsVM.customCuts.push(updatedCut);
                    refreshCategoriesAndCuts();
                }

                pubSubService.notify('refresh-table');
            }
        });
    }

    /**
     * Open mass cut creator modal.
     */
    function openMassCutCreator() {
        const creator = dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/cuts/mass-cut-creator.html',
            controller: 'MassCutCreatorCtrl',
            controllerAs: 'MassCutCreatorVM',
            windowClass: 'analysis-config modal-sv--full-height modal-sv--full-width',
            resolve: {
                allCategoriesAndCuts: () => {
                    return allCategoriesAndCuts;
                },
            },
        });
        creator.result.then(newCuts => {
            if (newCuts) {
                console.log(newCuts);
            }
        });
    }

    /**
     * Delete a custom cut.
     *
     * @param {object} cut - The cut to delete
     */
    function deleteCut(cut) {
        const cutIndex = _.findIndex(CustomCutsVM.customCuts, {
            uuid: cut.uuid,
        });
        modalService.openConfirmDeleteModal({
            item: () => {
                return 'cut';
            },
            callback: () => {
                return () => {
                    analysisConfigDataService.deleteAnalysisCutByUuid(cut.uuid).then(() => {
                        CustomCutsVM.customCuts.splice(cutIndex, 1);
                        pubSubService.notify('refresh-table');
                    });
                };
            },
            arg: () => {
                return cut;
            },
            title: () => {
                return `Are you sure you want to delete cut "${cut.name}"?`;
            },
        });
    }
}
