export default {
    // Configuration information for the different funnel types
    funnelTypes: {
        EXTENDED: {
            id: 'extended',
            path: '/extended',
            exclude: {
                DATA_ANALYSIS: true,
                CONFIRM: true,
            },
            maxQuestions: 15,
            maxScreeners: 2,
            maxOpenEnded: 3,
            maxVideoLengthSecs: 30,
            cpi: {
                us: 3,
                international: 5,
            },
        },
        ADVANCED: {
            id: 'advanced',
            path: '/quote',
            exclude: {
                DATA_ANALYSIS: true,
            },
            maxQuestions: 90,
        },
        BASIC: {
            id: 'basic',
            path: '',
            exclude: {
                DATA_ANALYSIS: true,
                CONFIRM: true,
            },
            maxQuestions: 6,
            maxScreeners: 1,
            maxOpenEnded: 2,
            maxVideoLengthSecs: 15,
            cpi: {
                us: 2,
                international: 3,
            },
        },
    },

    // Routing information for each step in the funnel
    steps: {
        TARGET: {
            index: 0,
            route: '/target',
            title: 'Define target audience',
            description: 'Define demographic and other requirements for your survey&rsquo;s target audience.',
            details: {
                templateUrl: 'survey-creation-templates/target/target.html',
                controller: 'SurveyTargetCtrl',
                resolve: {
                    eventName: function() {
                        return 'visit-target';
                    },
                },
            },
        },
        DESIGN: {
            index: 1,
            route: '/design',
            title: 'Write survey questions',
            details: {
                templateUrl: 'survey-creation-templates/design/design.html',
                controller: 'SurveyDesignCtrl',
                controllerAs: 'SurveyDesignVM',
                resolve: {
                    eventName: function() {
                        return 'visit-design';
                    },
                },
            },
        },
        SAMPLE: {
            index: 2,
            route: '/sample',
            title: 'Choose sample size',
            description: 'Select the number of respondents to survey.',
            details: {
                templateUrl: 'base-templates/sample.html',
                controller: 'SurveySampleCtrl',
                controllerAs: 'SurveySampleVM',
                resolve: {
                    eventName: function() {
                        return 'visit-sample';
                    },
                },
            },
        },
        DATA_ANALYSIS: {
            index: 2.5,
            route: '/data',
            title: 'Data analysis options',
            details: {
                template: '<survey-data-analysis-options></survey-data-analysis-options>',
            },
        },
        CONTACT_INFO: {
            index: 3,
            route: '/contact-info',
            title: 'Enter account info',
            details: {
                resolve: {
                    eventName: function() {
                        return 'visit-contact-info';
                    },
                },
                views: {
                    '': {
                        templateUrl: 'base-templates/contact-info.html',
                        controller: 'SurveyContactInfoCtrl',
                        controllerAs: 'ContactInfoVM',
                    },
                    'signUp@contact_info': {
                        templateUrl: 'account-templates/sign-up.html',
                        controller: 'SignUpController',
                    },
                },
            },
        },
        CONFIRM: {
            index: 4,
            route: '/confirm',
            title: 'Confirm',
            hide: true,
            hideSteps: true,
            details: {
                templateUrl: 'base-templates/confirm.html',
                controller: 'SurveyQuoteCtrl',
                resolve: {
                    eventName: function() {
                        return 'visit-confirm';
                    },
                },
            },
        },
        THANK_YOU: {
            index: 5,
            route: '/thank-you',
            title: 'Thank you',
            hide: true,
            hideSteps: true,
            details: {
                template: '<div survey-thank-you></div>',
                resolve: {
                    eventName: function() {
                        return 'visit-thank-you';
                    },
                },
            },
        },
    },

    // Non-funnel route configuration
    adminRoutes: {
        admin: {
            url: '/admin',
            template: '<div ui-view></div>',
        },
        'admin.surveys': {
            url: '^/admin/surveys',
            template: '<survey-list></survey-list>',
        },
        'admin.screenedSummary': {
            url: '^/admin/screened-summary',
            templateUrl: 'admin-templates/screened-summary.html',
            controller: 'surveyScreenedCtrl',
        },
        'admin.users': {
            url: '^/admin/users',
            templateUrl: 'admin-templates/user-list.html',
            controller: 'userListCtrl',
        },
        'admin.userDetails': {
            url: '^/admin/users/:userId',
            views: {
                '': {
                    template: '<admin-user-details></admin-user-details>',
                },
                'beacons@admin.userDetails': {
                    templateUrl: 'shared-templates/beacons.html',
                    controller: 'beaconCtrl',
                },
            },
        },
        'admin.publishers': {
            url: '^/admin/publishers',
            templateUrl: 'admin-templates/publisher-list.html',
            controller: 'publisherAdminListCtrl',
        },
        'admin.publisherDemographics': {
            url: '^/admin/publisher-demographics',
            templateUrl: 'admin-templates/publisher-demographic-list.html',
            controller: 'publisherAdminDemographicListCtrl',
        },
        'admin.publisherDetails': {
            url: '^/admin/publishers/:publisherId',
            templateUrl: 'admin-templates/publisher-details.html',
            controller: 'publisherAdminCtrl',
        },
        'admin.responses': {
            url: '^/admin/responses/?surveyUuid',
            templateUrl: 'admin-templates/response-list.html',
            controller: 'responseListCtrl',
        },
        'admin.network': {
            url: '^/admin/network',
            templateUrl: '../admin-templates/network-dashboard.html',
            controller: 'networkAdminCtrl',
        },
        'admin.panel': {
            url: '^/admin/panel',
            templateUrl: 'admin-templates/panel-dashboard.html',
            controller: 'PanelAdminCtrl',
        },
        'admin.retargeting': {
            url: '^/admin/retargeting',
            templateUrl: 'admin-templates/retargeting-dashboard.html',
            controller: 'RetargetingAdminCtrl',
            controllerAs: 'RetargetingAdminVM',
        },
        'admin.urlBuilder': {
            url: '^/admin/url-builder',
            templateUrl: 'admin-templates/url-builder.html',
        },
        'admin.surveywallTest': {
            url: '^/admin/surveywall-test',
            templateUrl: 'admin-templates/surveywall-test.html',
        },
    },
    dashboardRoutes: {
        dashboard: {
            url: '/:surveyId/dashboard',
            templateUrl: 'base-templates/dashboard.html',
            controller: 'SurveyDashboardCtrl',
        },
        results: {
            url: '/results',
            templateUrl: 'dashboard-templates/dashboard-results.html',
            controller: 'DashboardResultsCtrl',
            controllerAs: 'DashboardResultsVM',
        },
        adMeasurement: {
            url: '/ad-measurement/',
            views: {
                '': {
                    abstract: true,
                    template: '<div ad-measurement></div>',
                },
            },
        },
        surveyDesign: {
            url: '/design',
            views: {
                '': {
                    templateUrl: 'dashboard-templates/survey-design.html',
                },
                'target@surveyDesign': {
                    templateUrl: 'survey-creation-templates/target/target.html',
                    controller: 'SurveyTargetCtrl',
                },
                'design@surveyDesign': {
                    templateUrl: 'survey-creation-templates/design/design.html',
                    controller: 'SurveyDesignCtrl',
                    controllerAs: 'SurveyDesignVM',
                },
            },
        },
        attachments: {
            url: '/attachments',
            templateUrl: 'dashboard-templates/survey-attachments.html',
            controller: 'SurveyAttachmentsCtrl',
        },
        sharing: {
            url: '/sharing',
            templateUrl: 'dashboard-templates/survey-sharing.html',
            controller: 'SurveyInviteCtrl',
        },
        clientInfo: {
            url: '/client-info',
            templateUrl: 'admin-templates/survey-client-info.html',
            controller: 'ClientInfoCtrl',
            controllerAs: 'ClientInfoVM',
        },
        take: {
            url: '/take',
            templateUrl: 'admin-templates/take-link.html',
            controller: 'surveyTakeCtrl',
        },
    },
    accountRoutes: {
        account: {
            url: '/account',
            abstract: true,
            templateUrl: 'base-templates/user-account.html',
            controller: 'UserAccountCtrl',
        },
        'account.info': {
            url: '/info',
            views: {
                '': {
                    templateUrl: 'account-templates/account-info.html',
                },
                'personal@account.info': {
                    templateUrl: 'account-templates/personal-info.html',
                },
            },
        },
        'account.beacons': {
            url: '/beacons',
            templateUrl: 'shared-templates/beacons.html',
            controller: 'beaconCtrl',
        },
    },
    authRoutes: {
        signIn: {
            templateUrl: 'account-templates/sign-in.html',
            controller: 'SignInController',
        },
        signUp: {
            templateUrl: 'account-templates/sign-up.html',
            controller: 'SignUpController',
        },
        resetPassword: {
            url: '/reset-password',
            templateUrl: 'account-templates/reset-password.html',
            controller: 'ResetPasswordController',
        },
    },
    routes: {
        resetPasswordUrl: {
            url: '/reset-password/:token?utm_campaign&utm_source&utm_medium',
            templateUrl: 'account-templates/password-reset-form.html',
            controller: 'resetPasswordCtrl',
        },
        inviteLanding: {
            url: '/invite-landing/:token',
            templateUrl: 'account-templates/invite-landing.html',
            controller: 'resetPasswordCtrl',
        },
        demo: {
            url: '/demo/:surveyId',
            templateUrl: 'base-templates/demo.html',
            controller: 'SurveyDemoCtrl',
        },
        publisher: {
            url: '/publisher',
            templateUrl: 'publisher-templates/summary.html',
            controller: 'publisherSummaryCtrl',
        },
        publisherDetail: {
            url: '/publisher/:publisherId',
            templateUrl: 'publisher-templates/details.html',
            controller: 'publisherDetailCtrl',
        },
    },
    isUsRegion: 'true' === 'true',
};
