angular.module('clickMap.preview', [])
    .directive('clickMapPreview', clickMapPreview)
    .controller('ClickMapPreviewCtrl', ClickMapPreviewCtrl);

function clickMapPreview() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/click-map-preview.html',
        scope: {
            question: '=',
        },
        controller: 'ClickMapPreviewCtrl',
        controllerAs: 'ClickMapPreviewVM',
    };
}

function ClickMapPreviewCtrl() {
    var ClickMapPreviewVM = this;
    ClickMapPreviewVM.getStyle = getStyle;

    function getStyle(zone) {
        var ratio = 1.5,
            top = zone.y / ratio,
            left = zone.x / ratio,
            width = zone.width / ratio,
            height = zone.height / ratio,
            style = 'top:' + top + 'px; left:' + left + 'px; width:' + width + 'px; height:' + height + 'px;';
        return style;
    }
}
