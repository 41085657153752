angular.module('svDropdown', [])
    .directive('svDropdown', svDropdown);

/**
 * The svDropdown directive
 *
 * @returns {object} The dropdown details JSON
 */
function svDropdown() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            model: '=',
            choices: '=',
            disabled: '=?',
            //Assume this value is always greater than 0 -- it doesn't make sense to have a divider index at the top of the list
            dividerIndex: '=?',
            onChange: '<?',
            dropdownButtonAction: '<?',
            filter: '<?',
            defaultText: '@?',
            itemType: '@?',
            tab: '=?',
            item: '=?',
        },
        templateUrl: 'shared-templates/sv-dropdown.html',
        controller: function($scope) {
            var onChange = $scope.onChange || angular.noop;
            $scope.selectOption = selectOption;
            $scope.filtered = $scope.filter || (() => {
                return true;
            });

            init();

            function init() {
                if ($scope.dividerIndex) {
                    $scope.choicesBeforeIndex = $scope.choices.slice(0, $scope.dividerIndex);
                    $scope.choicesAfterIndex = $scope.choices.slice($scope.dividerIndex, $scope.choices.length);
                }
                else {
                    $scope.choicesBeforeIndex = [];
                    $scope.choicesWithSubmenu = [];
                    _.forEach($scope.choices, function(choice) {
                        if (choice.hasSubmenu) {
                            $scope.choicesWithSubmenu.push(choice);
                        }
                        else {
                            $scope.choicesBeforeIndex.push(choice);
                        }
                    });
                }
            }

            /**
             * User has selected an option.
             *
             * @param {object} option - Selected option
             * @param {object} e - The mouse event
             */
            function selectOption(option, e) {
                const oldValue = $scope.model;
                $scope.model = option;
                if (onChange) {
                    onChange(option, oldValue, $scope.item, e);
                }
            }
        },
    };
}
