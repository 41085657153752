import _ from 'lodash';
const list = [{
        id: '13_to_17',
        code: '13 to 17',
        name: '13–17',
    },
    {
        id: '18_to_24',
        code: '18 to 24',
        name: '18–24',
    },
    {
        id: '25_to_34',
        code: '25 to 34',
        name: '25–34',
    },
    {
        id: '35_to_44',
        code: '35 to 44',
        name: '35–44',
    },
    {
        id: '45_to_54',
        code: '45 to 54',
        name: '45–54',
    },
    {
        id: '55_to_64',
        code: '55 to 64',
        name: '55–64',
    },
    {
        id: '65_and_over',
        code: '65 and over',
        name: '65+ yrs',
    }],
    map = {};
_.forEach(list, function(e) {
    map[e.id] = e;
});

const ageOptions = {
    list,
    map,
};

export default ageOptions;
