import * as d3 from 'd3-selection';
import {
    Components, Dataset,
} from 'plottable/plottable';

const Plottable = {
    Components: Components,
    Dataset: Dataset,
};
const IS_VISIBLE = '--is-visible',
    HEIGHT = 'height',
    WIDTH = 'width',
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
    CLASS_NAME = 'sv-plot-label',
    GROUPING_CLASS_NAME = 'sv-plot-labels';

function _getNumberOfItemsInPlottableDataset(datasets) {
    return _.reduce(datasets, (sum, ds) => {
        sum += ds.data().length;
        return sum;
    }, 0);
}

function _getDatasetIndex(data, datasets, i) {
    // When chart has more datasets than the plot label does make
    // return the number of datasets in the chart
    //
    // This ensures that the label will be positioned over the last bar
    var numItemsInChartData = _getNumberOfItemsInPlottableDataset(datasets);
    if (numItemsInChartData > data.length) {
        return datasets.length - 1;
    }
    return Math.floor(i / (datasets[0] && datasets[0].data().length));
}

function _insertLinebreaks(plot, el) {
    if (el.empty()) {
        return false;
    } // If no element element do nothing

    var words, wordLen, tspan;

    if (el.select('tspan').empty()) {
        words = (el.node().innerHTML || el.node().textContent).split('\n'); // InternetExplorer does not support innerHTML for SVG elements -- ideally should be removed, but after more testing
        wordLen = words.length;

        el.text('');
        for (var j = 0; j < wordLen; j++) {
            tspan = el.append('tspan').text(words[j]);

            if (j > 0) {
                tspan.attr('class', 'percent-label');
            }
        }
    }

    tspan = el.selectAll('tspan:last-child');
    tspan.attr('dy', () => {
        return plot._isVertical ? '1em' : null;
    }).attr('x', () => {
        return plot._isVertical ? '0' : null;
    }).attr('dx', () => {
        return plot._isVertical ? null : '0.4em';
    });
}

function checkForNewLabelOrientation(plot) {
    if (plot.size && plot.size().accessor) {
        return;
    }

    var attrProjector = plot._generateAttrToProjector && plot._generateAttrToProjector(plot),
        barBaseExpanse = attrProjector[plot._isVertical ? WIDTH : HEIGHT](),
        minAllowableWidth = 14, // Smallest width/height allowed for bar before turning label text vertical
        tempOrientation = barBaseExpanse < minAllowableWidth ? VERTICAL : HORIZONTAL;

    // Toggle plotlabel orientation *ONLY IF* when chart is vertical otherwise leave it horizontal
    // -- because vertically oriented labels on a horizontal chart is ._0 -- crazy
    return plot._isVertical ? tempOrientation : HORIZONTAL;
}

function adjustLabelFontSizeForBetterDisplay(plotLabelGroup, plot) {
    if (!plotLabelGroup || !plotLabelGroup._isAnchored || (plot.size && plot.size().accessor)) {
        return;
    }

    var attrProjector = plot._generateAttrToProjector(plot),
        barBaseExpanse = attrProjector[plot._isVertical ? WIDTH : HEIGHT](), // Gets width or height or bar based on chart orientation
        paddingOnScale = plot[plot._isVertical ? 'x' : 'y']().scale._outerPadding || 0,
        bodyFontSize = 14, // In pixels
        labelMaxFontSize = 0.875,
        calculatedFontSize = '';

    if (barBaseExpanse <= bodyFontSize) {
        // Adding 2px to the barBaseExpanse for stroke-width of 1px on both sides of the bar
        // and the extra padding on the outsides of the
        calculatedFontSize = (2 + barBaseExpanse + paddingOnScale) / bodyFontSize;
    }
    // To minimize reflows set font-size on plot label group
    // making sure to cap fontSize to 1em when chart is horizontal
    plotLabelGroup._element.node().style.fontSize = Math.min(calculatedFontSize || Math.Infinity, labelMaxFontSize) + 'em';
}

function getLabelPositionForBarPlot(plot, labelToBarSpace, d, i, dataset) {
    var attrProjector = plot._generateAttrToProjector && plot._generateAttrToProjector(plot),
        xPos,
        yPos;

    if (plot._isVertical) {
        xPos = attrProjector.x(d, i, dataset) + attrProjector.width(d, i, dataset) / 2 + (U.isIe() ? 3 : 0); // For ie adjust x -- Unfortunately this cannot be done via css :(
        yPos = attrProjector.y(d, i, dataset) - (3.45 * labelToBarSpace); // Subtracting because up is negative in svg coordinate system
    }
    else {
        xPos = attrProjector.width(d, i, dataset) + labelToBarSpace;
        yPos = attrProjector.y(d, i, dataset) + attrProjector.height(d, i, dataset) / 2 + (U.isIe() ? 3 : 0); // For ie adjust y -- Unfortunately this cannot be done via css :(
    }

    return {
        x: xPos,
        y: yPos,
    };
}

function getLabelPositionForScatterPlot(plot, d, _i, _ds) {
    var xPos = plot.x().scale.scale(plot.x().accessor(d)),
        yPos = plot.y().scale.scale(plot.y().accessor(d));

    return {
        x: xPos - 16,
        y: yPos + 27,
    };
}

function setPlotLabelPlotOrientationClass(plotLabelGroup, plot) {
    if ('_isVertical' in plot) {
        let orientationClassPrefix = GROUPING_CLASS_NAME + '--plot-is-';

        // Toggle is-vertical|horizontal class on plotlabel grouping element
        plotLabelGroup.addClass(orientationClassPrefix + (plot._isVertical ? VERTICAL : HORIZONTAL))
            .removeClass(orientationClassPrefix + (plot._isVertical ? HORIZONTAL : VERTICAL));
    }
}

function getPlotLabelGroupFromDOM(groupComponents) {
    return _.find(groupComponents, component => {
        if (component.hasClass) {
            return component.hasClass(GROUPING_CLASS_NAME);
        }
        return component._element.classed(GROUPING_CLASS_NAME);
    });
}

function getPlotLabelsGroup(group) {
    // Check to see if we have a grouping elemnt for labels in the DOM
    let plotLabelGroup = getPlotLabelGroupFromDOM(group.components());

    if (!plotLabelGroup || !plotLabelGroup._isAnchored) {
        // Get new plotlabelGroup
        plotLabelGroup = new Plottable.Components.Group([]).addClass(GROUPING_CLASS_NAME);

        // Put it in the DOM
        group.append(plotLabelGroup);
    }

    return plotLabelGroup;
}

function handleChartFlipAxisComplete() {
    // Rebuild grouping layer to build plot labels on
    this._group.append(getPlotLabelsGroup(this._group));
}

function handleChartAnchorResizeComplete() {
    // Ensure we have plot label group so our labels are contained
    this._plotLabelGroup = getPlotLabelsGroup(this._group);

    // Now that chart has finished resizing update the plot label positioning
    this.drawPlotlables();
}

export default class Plotlabels {
    constructor(options) {
        this.id = '_plotLabel__' + (Math.random() * 1e3);
        this._orientation = HORIZONTAL;
        this._labelToBarSpace = 5;
        this._data = [];
        this._plot = options.plot;
        this._group = options.group;
        this._formatter = options.formatter;
        this._visible = false;
        this._chart = null;
        this._labels = null;
        this._plotLabelGroup = null;
        this.data(options.data || []);
    }

    // For plotlabels to know when to render themselves
    chart(val) {
        if (!arguments.length) {
            return this._chart;
        }

        this._chart = val;
        return this;
    }

    // That will format values displayed in plotlabel
    formatter(val) {
        if (!arguments.length) {
            return this._formatter;
        }

        this._formatter = val;
        return this;
    }

    // Plot group that plotlabels will build themselves atop of
    group(val) {
        if (!val) {
            return this._group;
        }

        this._group = val;
        return this;
    }

    init() {
        // Set event listeners to the tooltip, (i care about this)
        this._chart.addEventListener(this._chart.EVENTS.ANCHOR_COMPLETE, () => {
            handleChartAnchorResizeComplete.call(this);
        }, this.id);
        this._chart.addEventListener(this._chart.EVENTS.RESIZE_COMPLETE, () => {
            handleChartAnchorResizeComplete.call(this);
        }, this.id);
        this._chart.addEventListener(this._chart.EVENTS.FLIP_AXIS_COMPLETE, () => {
            handleChartFlipAxisComplete.call(this);
        }, this.id);
        // Add layer for the the plot labels
        this._group.append(getPlotLabelsGroup(this._group));
        return this;
    }

    // Plot from which data is pulled -- not sure if we need this
    plot(val) {
        if (!val) {
            return this._plot;
        }
        this._plot = val;
        return this;
    }

    destroy() {
        this._chart.removeEventListener(this._chart.EVENTS.ANCHOR_COMPLETE, this.id);
        this._chart.removeEventListener(this._chart.EVENTS.RESIZE_COMPLETE, this.id);
        this._chart.removeEventListener(this._chart.EVENTS.FLIP_AXIS_COMPLETE, this.id);
        // Remove things from the DOM
        this._group.destroy();
    }

    drawPlotlables() {
        const self = this;
        let labelSelection,
            plotLabelGroupContent,
            plotLabelPositioningTimer;
        // Need to make sure that plot group is present
        if (!this._plotLabelGroup || !this._plotLabelGroup._element) {
            this._plotLabelGroup = getPlotLabelsGroup(this._group);
        }

        // When plot is not visible do nothing
        if (this._plot.height() === 0 || this._plot.width() === 0) {
            return;
        }

        // Since this is run often we need to know if the orientation of the chart has
        this.orientation(checkForNewLabelOrientation(this._plot));

        // For better display adjust the font-size when the bar width/height is smaller than font-size
        adjustLabelFontSizeForBetterDisplay(this._plotLabelGroup, this._plot);

        // Check plot orientation
        setPlotLabelPlotOrientationClass(this._plotLabelGroup, this._plot);

        plotLabelGroupContent = this._plotLabelGroup.content();
        // Make selection
        labelSelection = plotLabelGroupContent
            .selectAll('.' + CLASS_NAME)
            .data(this._data);

        // Remove items that are not bound to data anymore
        labelSelection.exit()
            .remove();

        // Add any elements necessary to accomodate the "new" data
        labelSelection.enter()
            .append('g') // Add plot label group wrapper
            .classed(CLASS_NAME, true)
            .append('text') // Add text element for theplot label text content
            .insert('rect', ':first-child') // Add rectangle as first child of "g" -- used for legibility of plotlabel content
            .classed('plot-label-bg', true);

        // Now get a reference to elements in our updated data-bound DOM
        this._labels = plotLabelGroupContent.selectAll('g');
        this._labels.classed(CLASS_NAME + IS_VISIBLE, false); // Hide labels while re/positioning
        this._labels.selectAll('text')
            .text((d, i, nodeList) => {
                return this._formatter(d, i, nodeList, self._plot);
            })
            .each(function() {
                // FIXME: @samuelmburu this needs to be updated to look like the text one, not doing it now because it might bork things
                _insertLinebreaks(self._plot, d3.select(this));
            });

        const isIE = U.isIe();
        // Update label positioning
        this._labels
            .classed(CLASS_NAME + '--is-vertical', () => {
                return this.orientation() === VERTICAL;
            })
            .classed(CLASS_NAME + IS_VISIBLE, true)
            .attr('transform', (d, i) => {
                var datasets = this._plot.datasets(),
                    datasetIndex = _getDatasetIndex(this._data, datasets, i),
                    dataset = datasets[datasetIndex],
                    errorValue = -99,
                    rotation = '',
                    position,
                    xPos,
                    yPos;

                // Decide which plot we have
                if (this._plot.size) {
                    // We have a scatter plot
                    position = getLabelPositionForScatterPlot(this._plot, d, i, dataset);
                }
                else {
                    position = getLabelPositionForBarPlot(this._plot, this._labelToBarSpace, d, i, dataset);
                }

                // For invalid numbers e.g. (NaN or Infinity) by switch to a "safe value" instead of throwing an error
                xPos = isFinite(position.x) ? position.x : errorValue;
                yPos = isFinite(position.y) ? position.y : errorValue;

                // For vertical labels, rotate 180 degrees and tweak the y offset
                // IE 11 + Edge do not support text-orientation so we rotate by -90
                if (this.orientation() === VERTICAL) {
                    let rotationAngle = isIE ? -90 : 180;

                    rotation = ' rotate(' + rotationAngle + ')';
                    yPos += 14;
                }
                return 'translate(' + xPos + ', ' + yPos + ')' + rotation;
            })
            .each(function() {
                // Adjust positioning of the background
                var label = d3.select(this),
                    bg = label.select('rect'),
                    textContainer = label.select('text').node(),
                    textContainerBBox = textContainer.getBBox(),
                    x = textContainerBBox.x,
                    y = textContainerBBox.y;

                bg.attr('transform', 'translate(' + x + ',' + y + ')')
                    .attr(WIDTH, textContainerBBox.width)
                    .attr(HEIGHT, textContainerBBox.height);
            })
            .classed(CLASS_NAME + IS_VISIBLE, true);

        return this;
    }

    className(value, add) {
        if (!arguments.length) {
            return CLASS_NAME;
        }

        // Ensure we have labels set
        this._labels = this._labels || this._plotLabelGroup.content().selectAll('.' + CLASS_NAME);

        if (add) {
            // Add new class name
            this._labels.classed(CLASS_NAME += ' ' + value, true);
        }
        else {
            // Remove old class name + add new one
            this._labels.classed(CLASS_NAME, false)
                .classed(value, true);
        }

        return this;
    }

    // Orientation of the plotLabels, different from the chart.orientation()
    orientation(value) {
        if (!arguments.length) {
            return this._orientation;
        }
        this._orientation = value;
        return this;
    }

    show(bool) {
        if (!arguments.length || !this._labels) {
            return this._visible;
        }
        this._visible = bool;
        this._labels.classed('visible', this._visible);
        return this;
    }

    data(value, filterFn) {
        if (!arguments.length) {
            return this._data;
        }

        if (value && value.length) {
            if (filterFn) {
                value = value.filter(filterFn);
            }

            // NOTE: testing for Plottable Dataset since d3 uses plain arrays
            if (value[0]._data) {
                for (var i = 0; i < value.length; i++) {
                    this._data.push(value[i].data());
                }
            }
            else {
                // Then add plain array to data
                this._data.push(value);
            }
        }

        this._data = _.flatten(this._data); // NOTE: necessary when there are multiple data sets need to flatten them to one big array for d3
        return this;
    }

    labelToBarSpace(value) {
        if (!arguments.length) {
            return this._labelToBarSpace;
        }
        this._labelToBarSpace = value;
        return this;
    }
}
