import _ from 'lodash';
import httpDeferred from '../../../../common/js/http-deferred';

/**
 * Gets Survey's custom variables.
 *
 * @param {*} survey
 * @returns {Promise<*>}
 */
function listVariables(survey) {
    return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/custom-variable');
}

/**
 * Creates Survey's custom variable.
 *
 * @param {*} survey
 * @param {*} cv
 * @returns {Promise<*>}
 */
function createVariable(survey, cv) {
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/custom-variable', cv);
}

/**
 * Updates Survey's custom variable.
 *
 * @param {*} survey
 * @param {*} cv
 * @returns {Promise<*>}
 */
function updateVariable(survey, cv) {
    return httpDeferred.put(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/custom-variable/' + cv.uuid, cv);
}

/**
 * Deletes Survey's custom variable.
 *
 * @param {*} survey
 * @param {*} cv
 * @returns {Promise<*>}
 */
function deleteVariable(survey, cv) {
    return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/custom-variable/' + cv.uuid);
}

/**
 * Gets converted stats config object.
 *
 * @param {*} customVar
 * @param {*} idx
 * @returns {{isAvailable: boolean, code: string, name, options: *[], label, uuid, enabled: boolean}}
 */
function getConvertedStatsConfigObj(customVar, idx) {
    let customVarOptions = [];

    _.forEach(customVar.detailsJson, (val, key) => {
        customVarOptions.push({
            name: key,
            filterJson: val,
        });
    });

    return {
        label: customVar.name,
        code: 'CV' + idx,
        uuid: customVar.uuid,
        name: customVar.name,
        options: customVarOptions,
        enabled: true,
        isAvailable: true,
    };
}

/**
 * Initialize the variables filter object, given list of variables
 *
 * @param {*} survey
 * @returns {Promise<{isAvailable: boolean, name: string, options: *[], id: string}>}
 */
function initializeVariablesFilter(survey) {
    const customVarFilter = emptyCustomVarFilter();

    return listVariables(survey).then(customVariables => {
        if (customVariables && customVariables.length) {
            _.forEach(customVariables, (customVar, idx) => {
                // Do this for now, until we fix up the JSON render server side
                if (customVar.deleted) {
                    return;
                }
                customVarFilter.options.push(getConvertedStatsConfigObj(customVar, idx));
            });
        }
        return customVarFilter;
    });
}

/**
 * Gets an empty custom var filter.
 *
 * @returns {{isAvailable: boolean, name: string, options: *[], id: string}}
 */
function emptyCustomVarFilter() {
    return {
        id: 'customVariables',
        name: 'Variables',
        isAvailable: true,
        options: [],
    };
}

const customVariablesService = {
    // Custom variable crud
    create: createVariable,
    update: updateVariable,
    delete: deleteVariable,
    list: listVariables,
    // Logic
    initializeVariablesFilter,
    getConvertedStatsConfigObj,
    emptyCustomVarFilter,
};

export default customVariablesService;
