import _ from 'lodash';

const MAX_FREE_RESPONSE = 8;

/**
 * Parse chart data
 *
 * @param {Array} data
 * @param {object} question
 * @returns {object} parsedData
 */
function parseChartData(data, question) {
    if (question.details.type === 'ranking') {
        return parseDataForRanking(data, question);
    }
    return parseData(data, question);
}

/**
 * Parse ranking question data
 *
 * @param {object}   data
 * @returns {object} parsedData
 */
function parseDataForRanking(data) {
    let parsedData;
    _.forEach(data.countTally, (counts, rank) => {
        let bounds;
        if (_.isObject(counts)) {
            parsedData = parsedData || {};
            parsedData[rank] = [];
            _.forEach(counts, (value, key) => {
                bounds = (data.confidenceIntervalsOverProportions || data.confidenceIntervalsOverMeans || {})[key] || {};
                parsedData[rank].push(getParsedData(value / data.numRespondents, value, bounds, key, data.dates));
            });
        }
        else {
            parsedData = parsedData || [];
            bounds = _.values(data.confidenceIntervalsOverProportions)[0];
            parsedData.push(getParsedData(data.countProportions[rank], counts, bounds, rank, data.dates));
        }
    });
    return parsedData;
}

/**
 * Get parsed data
 *
 * @param {number}  cntProp
 * @param {number}  value
 * @param {object}  bounds
 * @param {number}  x
 * @param {object}  period
 * @returns {object} parsed data object
 */
function getParsedData(cntProp, value, bounds, x, period) {
    // Make sure cntProp is an actual number
    return {
        cntProp: cntProp > 0 ? cntProp : 0,
        cntValue: value,
        text: value,
        upperBound: getUpperBound(bounds, value),
        lowerBound: getLowerBound(bounds, value),
        x: x.replace(/^\d+\.(\d+\.)? /, ''),
        y: value,
        period,
    };
}

/**
 * Parse data
 *
 * @param {Array}   data
 * @param {object}  question
 * @returns {Array} parsedData
 */
function parseData(data, question) {
    const parsedData = {},
        ordered = [];

    data = question.details.type === 'clickmap' ? data.clickData[1] : data;
    if (/[fF]reeResponse$/.test(question.details.type) && !data.isTracker) {
        data.countTally = getMostCommonResponse(data.countTally);
    }
    _.forEach(data.countTally, (value, key) => {
        const isOther = /^\d+\.(\d+\.)? Other( \[(.*)\])$/.test(key),
            x = isOther ? _.find(question.details.choices, 'specifyOptionEnabled').text : key,
            bounds = (data.confidenceIntervalsOverProportions || data.confidenceIntervalsOverMeans || {})[key] || {};
        let cntProp;
        data.countProportions = data.countProportions || {};
        cntProp = isOther ? value / data.numRespondents : data.countProportions[key] || value / data.numRespondents;
        parsedData[x] = getParsedData(cntProp, value, bounds, x, data.dates);
    });

    if ((question.details.choices || []).length) {
        _.forEach(question.details.choices, choice => {
            if (!parsedData[choice.text || choice.image]) {
                return;
            }
            ordered.push(parsedData[choice.text || choice.image]);
        });
    }

    // If ordered array has fewer items than parsedData,
    // then we're missing things, so just return parsedData values
    if (Object.values(parsedData).length > ordered.length) {
        return Object.values(parsedData);
    }
    return ordered;
}

/**
 * Get tracker data
 *
 * @param {Array}   data
 * @param {object}  question
 * @returns {object} grouped
 */
function getTrackerData(data, question) {
    let parsed = [],
        grouped;

    _.forEach(data, period => {
        period.isTracker = true;
        parsed = parsed.concat(parseChartData(period, question));
    });
    grouped = _.groupBy(parsed, 'x');
    if (/[fF]reeResponse$/.test(question.details.type)) {
        return getMostCommonResponsesForTracker(parsed);
    }
    return grouped;
}

/**
 * Get upper bound
 *
 * @param {object}   errorData
 * @param {number}   value
 * @returns {number} upperBound
 */
function getUpperBound(errorData, value) {
    if (!errorData) {
        return null;
    }
    return (1 + errorData['Upper Bound']) * value;
}

/**
 * Get lower bound
 *
 * @param {object} errorData
 * @param {number} value
 * @returns {number} lowerBound
 */
function getLowerBound(errorData, value) {
    if (!errorData) {
        return null;
    }
    return (1 - errorData['Lower Bound']) * value;
}

/**
 * Get most common response
 *
 * @param {Array}    responses
 * @returns {object} sorted
 */
function getMostCommonResponse(responses) {
    let sortable = _.toPairs(responses),
        length = sortable.length,
        sorted = {};

    sortable.sort((a, b) => {
        return a[1] - b[1];
    });

    _.forEach(sortable.slice(Math.min(length - MAX_FREE_RESPONSE, length)), pair => {
        sorted[pair[0]] = pair[1];
    });

    return sorted;
}

/**
 * Get most common responses for tracker
 *
 * @param {Array}    parsed
 * @returns {object} response
 */
function getMostCommonResponsesForTracker(parsed) {
    const xToResponses = {},
        xToCount = {},
        response = {};
    let sorted;

    _.forEach(parsed, data => {
        xToCount[data.x] = xToCount[data.x] || 0;
        xToResponses[data.x] = xToResponses[data.x] || [];
        xToCount[data.x] += data.cntValue;
        xToResponses[data.x].push(data);
    });
    sorted = getMostCommonResponse(xToCount);
    _.forEach(sorted, (_value, key) => {
        response[key] = xToResponses[key];
    });
    return response;
}

/**
 * Get comparison data
 *
 * @param {Array}    data
 * @param {object}   question
 * @returns {object} parsed data
 */
function getComparisonData(data, question) {
    const parsed = {};
    _.forEach(data, comparison => {
        parsed[comparison.label] = parseChartData(comparison, question);
    });
    return parsed;
}

/**
 * Get chart data
 *
 * @param {Array}    data
 * @param {object}   question
 * @param {object}   params
 * @returns {object} chart data
 */
function getChartData(data, question, params) {
    const dataResult = {};
    params = params || {};
    if (!data.countTally && (data.clickData && (!data.clickData[1] || !data.clickData[1].countTally)) && !_.isArray(data)) {
        return dataResult;
    }

    if (params.isComparison) {
        return getComparisonData(data, question);
    }
    if (params.isTracker) {
        return getTrackerData(data, question);
    }
    return parseChartData(data, question);
}

export {
    getChartData,
};
