import _ from 'lodash';
var list = [{
        id: 'chrome',
        name: 'Chrome',
        icon: 'im im-chrome',
    },
    {
        id: 'firefox',
        name: 'Firefox',
        icon: 'im im-firefox',
    },
    {
        id: 'ie',
        name: 'IE',
        icon: 'im im-IE',
    },
    {
        id: 'safari',
        name: 'Safari',
        icon: 'im im-safari',
    },
    {
        id: 'other',
        name: 'Other',
        icon: 'fas fa-question-circle',
    }],
    map = {};
_.forEach(list, function(e) {
    map[e.id] = e;
});
const webBrowserOptions = {
    list: list,
    map: map,
};

export default webBrowserOptions;
