const standardCuts = [{
    category: 'Overall',
    categoryType: 'demographic',
    controlType: 'Overall',
    type: {
        value: 'all',
        name: 'Overall',
    },
    options: [{
        name: 'Overall',
        value: 'Overall',
        exposed: {
            $bool: true,
        },
    }],
},
{
    // Gender
    category: 'Gender',
    categoryType: 'demographic',
    controlType: 'Equivalent',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    options: [{
        name: 'Male',
        value: 'Gender: Male',
        exposed: {
            $eq: ['Demographic$Gender', 'Male'],
        },
    }, {
        name: 'Female',
        value: 'Gender: Female',
        exposed: {
            $eq: ['Demographic$Gender', 'Female'],
        },
    }],
},
{
    // Age cut options
    category: 'Age',
    categoryType: 'demographic',
    controlType: 'Equivalent',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    options: [{
        name: '13 to 17',
        value: 'Age: 13 to 17',
        exposed: {
            $eq: ['Demographic$Age', '13 to 17'],
        },
    },
    {
        name: '18 to 24',
        value: 'Age: 18 to 24',
        exposed: {
            $eq: ['Demographic$Age', '18 to 24'],
        },
    },
    {
        name: '25 to 34',
        value: 'Age: 25 to 34',
        exposed: {
            $eq: ['Demographic$Age', '25 to 34'],
        },
    },
    {
        name: '35 to 44',
        value: 'Age: 35 to 44',
        exposed: {
            $eq: ['Demographic$Age', '35 to 44'],
        },
    },
    {
        name: '45 to 54',
        value: 'Age: 45 to 54',
        exposed: {
            $eq: ['Demographic$Age', '45 to 54'],
        },
    },
    {
        name: '55 to 64',
        value: 'Age: 55 to 64',
        exposed: {
            $eq: ['Demographic$Age', '55 to 64'],
        },
    },
    {
        name: '65+',
        value: 'Age: 65+',
        exposed: {
            $eq: ['Demographic$Age', '65 and over'],
        },
    }],
},
{
    // Device Type
    category: 'Device Type',
    categoryType: 'demographic',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    controlType: 'Equivalent',
    options: [{
        name: 'Mobile',
        value: 'Device Type: Mobile',
        exposed: {
            $or: [{
                $eq: ['Operating$System', 'ios'],
            }, {
                $eq: ['Operating$System', 'android'],
            }],
        },
    }, {
        name: 'Desktop',
        value: 'Device Type: Desktop',
        exposed: {
            $or: [{
                $eq: ['Operating$System', 'windows'],
            }, {
                $eq: ['Operating$System', 'macos'],
            }],
        },
    }],
},
{
    // Operating System cut options
    category: 'Operating System',
    categoryType: 'demographic',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    controlType: 'Equivalent',
    options: [{
        name: 'Windows',
        value: 'Operating System: Windows',
        exposed: {
            $eq: ['Operating$System', 'windows'],
        },

    },
    {
        name: 'Android',
        value: 'Operating System: Android',
        exposed: {
            $eq: ['Operating$System', 'android'],
        },
    },
    {
        name: 'MacOS',
        value: 'Operating System: MacOS',
        exposed: {
            $eq: ['Operating$System', 'macos'],
        },
    },
    {
        name: 'iOS',
        value: 'Operating System: iOS',
        exposed: {
            $eq: ['Operating$System', 'ios'],
        },
    },
    {
        name: 'Other',
        value: 'Operating System: Other',
        exposed: {
            $eq: ['Operating$System', 'other'],
        },
    }],
}];

export default standardCuts;
