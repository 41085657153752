import _ from 'lodash';
import U from '../../../../common/js/util';
import customVariablesService from '../custom-variables/custom-variables.api-service';
import filterAndCompareConstants from '../filter-and-compare/filter-and-compare-constants';
angular.module('statsConfig.service', [])
    .service('statsConfigService', statsConfigService);

/**
 * Stats config service.
 *
 * @returns {object} Exposed service functions
 */
function statsConfigService() {
    const QUESTION_TXT = 'Question',
        SCREENER_TXT = 'Screener',
        SINGLE_SELECT = 'singleSelect',
        MULTI_SELECT = 'multiSelect',
        GRID_LINE_FRAGEMENT = 'Line$',
        CHOICE_FRAGEMENT = 'Choice$',
        FILTER_TOO_NARROW_CODE = 10,
        SURVEY_NOT_COMPILED = 11,
        ages = [
            '13 to 17',
            '18 to 24',
            '25 to 34',
            '35 to 44',
            '45 to 54',
            '55 to 64',
            '65 and over',
        ];

    /**
     * Initialize the question filter object, given the list of questions.
     *
     * @param {object[]} questions - Array of survey questions
     * @returns {object} question filter object
     */
    function initializeQuestionFilter(questions) {
        var questionFilter = {
            id: 'questionAnswer',
            name: 'Questions',
            isAvailable: true,
            options: [],
        };

        _.forEach(questions, question => {
            questionFilter.options.push(buildQuestionChoiceFilter(question));
            // For each grid row, add another question filter option
            if (question.details.type === 'grid' && /single|multi/.test(question.details.gridType)) {
                _.forEach(question.details.rowStatements, (line, idx) => {
                    questionFilter.options.push(buildQuestionChoiceFilter(question, line, idx));
                });
            }
        });
        return questionFilter;
    }

    /**
     * Build question choice filter.
     *
     * @param {object} question - Survey question
     * @param {object} [row] - Grid row
     * @param {number} [rowIndex] - Grid row index
     * @returns {object} filter question content
     */
    function buildQuestionChoiceFilter(question, row, rowIndex) {
        let questionCode = question.id + (row ? '|' + GRID_LINE_FRAGEMENT + (rowIndex + 1) : ''),
            analysisPlanQuestionCode = question.analysisPlanId + (row ? '|' + GRID_LINE_FRAGEMENT + (rowIndex + 1) : ''),
            name = row ? question.details.rowStatements[rowIndex].displayNameFull : question.details.statement.text,
            strLength = row ? 45 : 50,
            enabled = row || question.details.type === SINGLE_SELECT || question.details.type === MULTI_SELECT,
            filterQuestion = {
                id: questionCode,
                analysisPlanId: analysisPlanQuestionCode,
                label: `${question.screener ? 'S' : 'Q'}${question.index}`,
                analysisPlanLabel: 'Q' + question.analysisPlanIndex,
                screener: question.screener,
                index: _.parseInt(question.code.slice(1)),
                type: row ? 'gridRow' : question.details.type,
                displayType: question.displayType,
                name: row ? question.details.rowStatements[rowIndex].text : name,
                displayNameFull: row ? U.stringRemoveHtml(name) : question.details.displayNameFull,
                displayName: row ? U.limitStrLength(name, strLength, true) : question.details.displayName,
                displayIconClass: getQuestionTypeIcon(question),
                enabled: !!enabled,
                options: [],
                disabled: !enabled,
            };

        if (row) {
            filterQuestion.rowIndex = row ? rowIndex + 1 : void 0;
            filterQuestion.gridType = question.details.gridType;
            filterQuestion.gridStatement = question.details.statement.text;
        }

        if (enabled) {
            _.forEach(row ? question.details.choices[rowIndex] : question.details.choices, (choice, idx) => {
                let choiceText = choice.text || choice.image,
                    displayNameFull = choiceText.replace(/^\d+\.(\d+\.)? /, '');
                displayNameFull = choiceText.replace(/^\d+\.(\d+\.)? /, '');
                filterQuestion.options.push({
                    name: choiceText,
                    displayNameFull,
                    displayName: U.limitStrLength(displayNameFull, 50),
                    value: idx,
                    disabled: question.details.type === SINGLE_SELECT || choice.noneOfTheAbove ? !!choice.disqualifier : false,
                    id: questionCode + '|' + CHOICE_FRAGEMENT + (idx + 1),
                    analysisPlanId: analysisPlanQuestionCode + '|' + CHOICE_FRAGEMENT + (idx + 1),
                    image: !choice.noneOfTheAbove && choice.image,
                    noneOfTheAbove: choice.noneOfTheAbove,
                });
            });
        }

        return filterQuestion;
    }

    /**
     * Get the icon for a question.
     *
     * @param {object} question - The question
     * @returns {string} The CSS class name of the icon
     */
    function getQuestionTypeIcon(question) {
        if (question.gridType) {
            return filterAndCompareConstants.iconMap[question.gridType === 'single' ? SINGLE_SELECT : MULTI_SELECT];
        }
        return filterAndCompareConstants.iconMap[question.details.type];
    }

    /**
     * Initialize beacon filters.
     *
     * @param {string[]} keys - Array of key strings
     * @param {string} field - The field in the schema we want to initialize
     * @param {object} [schema={}]
     * @returns {object} Filter options
     */
    function initializeBeaconFilters(keys, field, schema = {}) {
        const columnPositions = schema && schema.columnPositions || schema || {},
            options = columnPositions.choiceColumns,
            offset = columnPositions.firstColumnNum;

        if (options) {
            const filterOptions = {};
            const ordered = _.reduce(options, (result, position, name) => {
                result[position - offset - 1] = name;
                return result;
            }, []);

            _.forEach(keys, key => {
                filterOptions[key] = [];
                _.forEach(ordered, (val, idx) => {
                    const beaconPoolId = key + (idx + 1);
                    filterOptions[key].push({
                        name: val,
                        id: beaconPoolId,
                        key: beaconPoolId,
                    });
                });
            });
            if (field === 'beaconPools') {
                const last = _.last(filterOptions.BeaconPool$);
                last.isControl = true;
                last.id = 'is_control';
                last.key = 'is_control';
            }
            return filterOptions;
        }
    }

    /**
     * Initialize filters.
     *
     * @param {object} survey - The survey object
     * @param {object} statsConfig - The stats configuration
     * @param {object} schema - The stats schema
     * @returns {Promise} Promise to be fulfilled after making an API call
     */
    function initializeFilters(survey, statsConfig, schema) {
        const filterObj = {},
            beaconSchema = schema.beacons || {},
            beaconFields = ['BeaconFrequency$', 'Beacon$'],
            beacons = initializeBeaconFilters(beaconFields, 'beacons', beaconSchema.beacons),
            controlBeacons = initializeBeaconFilters(['ControlBeacon$'], 'controlBeacons', beaconSchema.controlBeacons),
            controlBeaconPools = initializeBeaconFilters(['ControlBeaconPool$'], 'controlBeaconPools', beaconSchema.controlBeaconPools),
            controlForExposedBeaconPools = initializeBeaconFilters(['ControlFor$'], 'controlFor', beaconSchema.controlForExposedBeaconPools),
            beaconPools = initializeBeaconFilters(['BeaconPool$'], 'beaconPools', beaconSchema.beaconPools);

        if (beacons) {
            _.forEach(beacons, (value, key) => {
                statsConfig[key] = value;
            });
        }
        if (controlBeacons) {
            statsConfig.ControlBeacon$ = controlBeacons.ControlBeacon$;
        }
        if (controlBeaconPools) {
            statsConfig.ControlBeaconPool$ = controlBeaconPools.ControlBeaconPool$;
        }
        if (controlForExposedBeaconPools) {
            statsConfig.ControlFor$ = controlForExposedBeaconPools.ControlFor$;
        }
        if (beaconPools) {
            filterObj.Beacon$Pool = {
                options: beaconPools.BeaconPool$,
            };
            statsConfig.hasBeaconPools = true;
        }
        if ((schema.beacons || {}).impressionParameterGroups) {
            const impressionParameterGroups = _.reduce(schema.beacons.impressionParameterGroups, (results, _value, key) => {
                const field = `ImpressionParameterGroups$${key}`;
                results[key] = initializeBeaconFilters([field], key, beaconSchema.impressionParameterGroups[key])[field];
                return results;
            }, {});
            statsConfig.impressionParameterGroups = impressionParameterGroups;
        }
        filterObj.questions = initializeQuestionFilter(statsConfig.questions);

        // Custom variables filter requires a server call
        return new Promise(resolve => {
            customVariablesService.initializeVariablesFilter(survey).then(customVariables => {
                filterObj.customVariables = customVariables;
                resolve(filterObj);
            }, () => {
                resolve(filterObj);
            });
        });
    }

    /**
     * Handle "filter too narrow" server response.
     *
     * @param {object} data - The JSON payload from server
     * @param {object} question - The question associated with the tally call
     * @returns {object} data object populated with an empty response
     */
    function handleFilterTooNarrow(data, question) {
        if (data.code === FILTER_TOO_NARROW_CODE || data.code === SURVEY_NOT_COMPILED) {
            data.result = {};
            if (question.details.type === 'grid') {
                data.result.lines = _.map(_.range(0, question.details.rowStatements.length), () => {
                    return generateEmptyReponse(question.details.choices);
                });
            }
            else if (question.details.type === 'clickmap') {
                data.result = {
                    // eslint-disable-next-line camelcase
                    click_data: {
                        1: generateEmptyReponse(question.details.zones),
                    },
                };
            }
            else if (question.details.type === 'rating') {
                data.result = _.range(1, question.details.length + 1);
                data.result.confidenceIntervalsOverMeans = {
                    [question.details.statement.text]: 0,
                };
                data.result.metricTally = data.result.confidenceIntervalsOverMeans;
                delete data.result.confidenceIntervalsOverProportions;
            }
            else if (/[fF]reeResponse$/.test(question.details.type)) {
                data.result = generateEmptyReponse({
                    text: '',
                });
            }
            else {
                data.result = generateEmptyReponse(question.details.choices);
            }
            if (question.details.type === 'ranking') {
                data.result.countTally = {};
                _.forEach(_.range(1, question.details.choices.length), idx => {
                    data.result.countTally[idx] = data.result.confidenceIntervalsOverProportions;
                });
                data.result.confidenceIntervalsOverMeans = data.result.confidenceIntervalsOverProportions;
                delete data.result.confidenceIntervalsOverProportions;
            }
        }
        return data;
    }

    /**
     * Get an empty response object.
     *
     * @param {object[]} choices - Array of question choices.
     * @returns {object} Empty response object
     */
    function generateEmptyReponse(choices) {
        var keys = [
                'countProportions',
                'respondentsShownChoice',
                'confidenceIntervalsOverProportions',
                'countTally',
            ],
            response = {
                numRespondents: 0,
                numRespondentsUnfiltered: 0,
                caveats: [],
                metricTally: null,
                minMax: null,
                clickData: void 0,
            },
            count = {},
            confidenceIntervalsOverProportions = {};

        _.forEach(choices, choice => {
            const key = _.isNumber(choice) ? choice : choice.text || choice.image || choice.name;
            count[key] = 0;
            confidenceIntervalsOverProportions[key] = {
                'Lower Bound': 0,
                'Upper Bound': 0,
            };
        });
        _.forEach(keys, key => {
            response[key] = key === 'confidenceIntervalsOverProportions' ? confidenceIntervalsOverProportions : count;
        });
        return response;
    }

    /**
     * Set text for other/specify option.
     *
     * @param {string} otherText - Other/specify text
     * @param {object[]} choices - Array of answer choices
     */
    function setOtherText(otherText, choices) {
        if (otherText) {
            for (var i = 0; i < choices.length; i++) {
                if (choices[i].specifyOptionEnabled) {
                    choices[i].text = otherText;
                    break;
                }
            }
        }
    }

    /**
     * Get statement by type.
     *
     * @param {object} question - Survey question
     * @param {string} type - Question type
     * @returns {string} question statement based on whether it's a grid type
     */
    function getStatementByType(question, type) {
        const statement = (question.lines ? question.lines[0] : question)[type];
        return statement && JSON.parse(statement);
    }

    /**
     * Process stats questions.
     *
     * @param  {object[]} questions - Array of survey questions
     * @returns {object[]} Array of questions, in format required for filters and analysis plan
     */
    function processStatsQuestion(questions) {
        const strLength = 50;
        let screenerCount = 0;
        return _.map(questions, (question, questionCount) => {
            let isScreener,
                questionIndex,
                questionStatement,
                code,
                choices,
                rowStatements,
                displayName;
            question.details = (question.lines ? question.lines[0].details : question.details) || {};
            isScreener = question.lines ? question.lines[0].isScreener : question.isScreener;
            questionIndex = (isScreener ? questionCount : questionCount - screenerCount) + 1;
            code = (isScreener ? SCREENER_TXT : QUESTION_TXT) + '$' + questionIndex;
            questionStatement = {
                text: question.statement,
                image: getStatementByType(question, 'statementImageJson'),
                video: getStatementByType(question, 'statementVideoJson'),
            };
            displayName = questionStatement.text.replace(/^\d+\. /, '');
            if (question.columnPositions) {
                const zonesOrChoices = question.details.choices || question.details.zones,
                    prefix = (_.keys(question.columnPositions.choiceColumns)[0] || '').split(/^(\d+\. )/)[1] || '';
                choices = [];
                _.forEach(zonesOrChoices, (questionChoice, choiceIdx) => {
                    const choiceText = prefix + (questionChoice.text || questionChoice.image);
                    choices.push({
                        id: code + '|Choice$' + (choiceIdx + 1),
                        [question.details.responseType === 'image' ? 'image' : 'text']: choiceText,
                        specifyOptionEnabled: questionChoice.specifyOptionEnabled,
                        noneOfTheAbove: questionChoice.noneOfTheAbove,
                    });
                });
            }
            else {
                choices = [];
                rowStatements = [];
                _.forEach(question.lines, (line, lineIdx) => {
                    const lineChoices = [],
                        prefix = (_.keys(line.columnPositions.choiceColumns)[0] || '').split(/^(\d+\.(?:\d+\.)? )/)[1] || '';

                    _.forEach(question.details.choices, (choice, choiceIdx) => {
                        const choiceText = prefix + (choice.text || choice.image);
                        lineChoices.push({
                            id: code + '|Line$' + (lineIdx + 1) + '|Choice$' + (choiceIdx + 1),
                            text: choiceText,
                            specifyOptionEnabled: choice.specifyOptionEnabled,
                            noneOfTheAbove: choice.noneOfTheAbove,
                        });
                    });
                    choices.push(lineChoices);
                    rowStatements.push({
                        text: line.statement,
                        displayNameFull: question.details.rowStatements[lineIdx].text,
                    });
                });
            }

            setOtherText(question.details.otherText, choices);
            screenerCount += isScreener ? 1 : 0;
            return {
                id: code,
                analysisPlanId: 'Question$' + (questionCount + 1),
                index: questionIndex,
                analysisPlanIndex: questionCount + 1,
                uuid: question.uuid,
                screener: isScreener,
                code: 'Q' + questionCount,
                type: question.questionType,
                details: {
                    type: question.questionType,
                    displayNameFull: name.length > 0 ? name : displayName,
                    displayName: U.limitStrLength(displayName, strLength, true),
                    displayIconClass: getQuestionTypeIcon(question),
                    displayType: question.details.displayType,
                    responseType: question.details.responseType,
                    order: question.details.order,
                    statement: questionStatement,
                    text: question.details.text,
                    choices: choices,
                    // Images
                    imageUrl: question.details.imageUrl,
                    imageHeight: question.details.imageHeight,
                    imageWidth: question.details.imageWidth,
                    // Grid
                    rowStatements: rowStatements,
                    gridType: question.details.gridType,
                    // Rating
                    glyph: question.details.glyph,
                    lowLabel: question.details.lowLabel,
                    highLabel: question.details.highLabel,
                    length: question.details.length,
                    // Ranking
                    itemsToRank: question.details.itemsToRank,
                    // Clickmap
                    zones: question.details.zones,
                },
            };
        });
    }

    /**
     * Filter stats headers.
     *
     * @param {object} survey - The survey object
     * @param {object[]} headers - Demographic headers from  stats schema
     * @returns {object[]} filtered set of headers
     */
    function filterStatsHeaders(survey, headers) {
        var eligibleHeaders = [
                'Age', 'Gender',
            ],
            geographic = null;

        for (var i = 0; i < (survey.targetingReqs || []).length; i++) {
            var req = survey.targetingReqs[i];
            if (geographic !== 'State' && geographic !== 'Metro' && req.attribute === 'country') {
                if (req.details.countries.length > 1) {
                    geographic = 'Country';
                }
                else {
                    geographic = req.details.type.toUpperCase() === 'US' ? 'Region' : undefined;
                }
            }
            else if (geographic !== 'Metro Area' && geographic !== 'State' && req.attribute === 'region') {
                geographic = req.details.length > 1 ? 'State' : 'Metro Area';
            }
            else if (req.attribute === 'metro') {
                geographic = req.details.length > 1 ? 'Metro Area' : 'City';
            }
            else if (req.attribute === 'zip') {
                geographic = 'Postal Code';
                break;
            }
        }
        if (geographic) {
            eligibleHeaders.push(geographic);
        }
        return _.filter(headers, h => {
            return eligibleHeaders.indexOf(h.name) !== -1;
        });
    }

    /**
     * Generate empty comparison response.
     *
     * @param {string} label - Label text
     * @param {object} question - Survey question
     * @returns {object} - Empty comparison response
     */
    function generateEmptyComparisonResponse(label, question) {
        let choices = question.details.choices,
            comparison;

        // This is ugly, but works because for grid we have an array of arrays
        // Will change once we move things into react land
        if (Array.isArray(choices[0])) {
            choices = choices[question.gridIndex - 1];
        }
        comparison = generateEmptyReponse(choices);
        if (question.type !== 'clickmap') {
            comparison.label = label;
        }
        if (question.type === 'rating') {
            comparison.metricTally = {
                label: 0,
            };
        }
        else if (question.type === 'numeric') {
            comparison.minMax = {
                minimumValue: 0,
                maximumValue: 0,
            };
            comparison.metricTally = {
                label: 0,
            };
        }
        return comparison;
    }

    /**
     * Generate empty summary.
     *
     * @param {object} survey - Survey object
     * @returns {object} Empty results summary object
     */
    function generateEmptySummary(survey) {
        const emptyResponse = {
            numRespondents: 0,
        };
        let hasAgeRequirement,
            disabledGender;

        _.forEach(survey.targetingReqs, req => {
            if (req.attribute === 'country' && req.details.type.toUpperCase() === 'US') {
                emptyResponse.regionTally = {
                    South: 0,
                    Midwest: 0,
                    West: 0,
                    Northeast: 0,
                };
            }
            else if (req.attribute === 'age') {
                emptyResponse.ageTally = {};
                _.forEach(req.details, id => {
                    emptyResponse.ageTally[id.replace(/_/g, ' ')] = 0;
                });
                hasAgeRequirement = true;
            }
            else if (req.attribute === 'gender') {
                disabledGender = true;
            }
        });
        if (!emptyResponse.ageTally && !hasAgeRequirement) {
            emptyResponse.ageTally = {};
            _.forEach(ages, age => {
                emptyResponse.ageTally[age] = 0;
            });
        }
        if (!disabledGender) {
            emptyResponse.genderTally = {
                female: 0,
                male: 0,
            };
        }
        return emptyResponse;
    }

    /**
     * Process respondent data.
     *
     * @param {object} survey - Survey object
     * @param {object} [periods] - Collection period start/end info
     * @param {object[]} periodSummaries - Array of collection period summaries
     * @returns {object} - Tally data
     */
    function processRespondentData(survey, periods, periodSummaries) {
        const summary = {},
            tallies = {
                genderTally: [],
                regionTally: [],
                ageTally: [],
            };
        let totalNumRespondents = 0;
        summary.numRespondentsUnfiltered = periodSummaries[0].unfiltered.numRespondents;
        summary.numVisiblePeriods = periods ? Math.abs(periods.start - periods.end) + 1 : void 0;
        summary.numPeriods = summary.numVisiblePeriods ? survey.collectionPeriods.length : void 0;

        if (periodSummaries.length > 1) {
            const numRespondentsByCollectionPeriod = [];
            _.forEach(periodSummaries, periodSummary => {
                _.forEach(tallies, (tally, key) => {
                    tally.push(periodSummary.filtered[key]);
                });
                numRespondentsByCollectionPeriod.push(periodSummary.filtered.numRespondents);
                totalNumRespondents += periodSummary.filtered.numRespondents;
            });
            summary.numRespondentsByCollectionPeriod = numRespondentsByCollectionPeriod;
            summary.numRespondentsInVisiblePeriods = totalNumRespondents;
            tallies.numRespondents = totalNumRespondents;
            if (!tallies.regionTally) {
                delete tallies.regionTally;
            }
            return {
                filtered: tallies,
                unfiltered: periodSummaries[0].unfiltered,
                numRespondents: totalNumRespondents,
                summary: summary,
            };
        }
        if (periodSummaries.length < 2) {
            periodSummaries = periodSummaries[0];
            if (!periodSummaries.filtered.regionTally) {
                delete periodSummaries.filtered.regionTally;
            }
            periodSummaries.numRespondents = periodSummaries.filtered.numRespondents;
            summary.numRespondentsInVisiblePeriods = periodSummaries.filtered.numRespondents;
            periodSummaries.summary = summary;
            return periodSummaries;
        }
    }

    /**
     * Process question tally response.
     *
     * @param {object} question - Survey question
     * @param {object} params - Question tally params
     * @param {object[]} periodData - Array of collection period info
     * @param {object[]} dates - Array of dates
     * @returns {object[]} - Question tally data
     */
    function processQuestionTallyResponse(question, params, periodData, dates) {
        if (params.collectionPeriods && !params.collectionPeriods.consolidate && !params.comparisonData) {
            let multiPeriod = [];
            if (question.details.type === 'grid' && _.isUndefined(question.line)) {
                _.forEach(periodData, (curPeriod, idx) => {
                    var rowIdx = 0,
                        periodIdx = params.collectionPeriods.start + idx;
                    if (curPeriod.code === FILTER_TOO_NARROW_CODE) {
                        curPeriod.result = {};
                        curPeriod.result.lines = _.map(_.range(0, question.details.rowStatements.length), () => {
                            return generateEmptyReponse(question.details.choices);
                        });
                    }
                    _.forEach(curPeriod.result.lines, rowData => {
                        multiPeriod[rowIdx] = multiPeriod[rowIdx] || [];
                        multiPeriod[rowIdx][periodIdx] = multiPeriod[rowIdx][periodIdx] || {};
                        multiPeriod[rowIdx][periodIdx] = handleOther(question, rowData);
                        multiPeriod[rowIdx][periodIdx].dates = dates[periodIdx];
                        rowIdx++;
                    });
                });
            }
            else {
                multiPeriod = _.map(periodData, (item, idx) => {
                    var result = item.result || generateEmptyReponse(question.details.choices);
                    result.dates = dates[params.collectionPeriods.start + idx];
                    return result;
                });
            }
            return multiPeriod;
        }
        // Handle single collection period
        if (periodData.length < 2) {
            periodData = periodData[0];
            handleFilterTooNarrow(periodData, question);
            periodData = periodData.result.lines ? _.values(periodData.result.lines) : periodData.result;
            if (question.details.type === 'singleSelect' || question.details.type === 'multiSelect') {
                handleOther(question, periodData);
            }
            return periodData;
        }
        const toResolve = [];
        _.forEach(periodData, data => {
            handleFilterTooNarrow(data, question);
            if (data.result.click_data) {
                toResolve.push({
                    clickData: data.result.click_data,
                });
            }
            else if (data.result.lines) {
                toResolve.push(_.values(data.result.lines));
            }
            else {
                toResolve.push(data.result);
            }
        });
        return toResolve;
    }

    /**
     * Handle user specified "other" option.
     *
     * @param {object} question - Survey question
     * @param {object} result - Result object to be populated
     * @returns {object} result object
     */
    function handleOther(question, result) {
        let otherOption = _.find(question.details.choices, item => {
            return item.specifyOptionEnabled;
        });
        if (otherOption) {
            _.forEach(result.countProportions, (_value, key) => {
                if (key.indexOf(otherOption.text || 'Other') > -1) {
                    result.countProportions[key] = result.countTally[key] / result.numRespondents;
                    return false;
                }
            });
            otherOption.text = otherOption.text || 'Other';
        }
        return result;
    }

    return {
        initializeFilters,
        filterStatsHeaders,
        generateEmptyComparisonResponse,
        generateEmptySummary,
        processStatsQuestion,
        processQuestionTallyResponse,
        processRespondentData,
    };
}
