import '../question-types/question-type-groups';
import '../targeting-attributes/targeting-attribute-groups';

angular.module('surveyValidation.service', ['questionTypeGroups.service', 'targetingAttributeGroups.service'])
    .service('surveyValidationService', surveyValidationService);
surveyValidationService.$inject = ['questionTypeGroupsService', 'targetingAttributeGroups'];

function surveyValidationService(QTGS, TAGS) {
    function questionErrors(q) {
        let errors = [];

        /* Standard question requirements. */
        // Require a question type.
        if (q.type) {
            let typePrototype = (q.type && QTGS.types[q.type]) || null;
            // Require a question statement.
            if (!q.statement || !q.statement.text) {
                errors.push('Enter a question statement.');
            }

            /* Type-specific requirements */
            if (typePrototype) {
                errors = errors.concat(typePrototype.errors(q));
            }
        }
        else {
            errors.push('Select a question type.');
        }
        return errors;
    }

    function targetingReqErrors(req) {
        let errors = [],
            /* Attribute-specific requirements */
            attrPrototype = (req.attribute && TAGS.attributes[req.attribute]) || null;
        if (attrPrototype) {
            errors = errors.concat(attrPrototype.errors(req));
        }

        return errors;
    }

    function surveyErrors(survey) {
        let errors = [];

        if (!survey.questions || (survey.questions.length === 0)) {
            errors.push('Add at least one question to your survey.');
        }

        return errors;
    }

    return {
        surveyErrors: surveyErrors,
        targetingReqErrors: targetingReqErrors,
        questionErrors: questionErrors,
    };
}
