import trackingMethodOptions from './options';

angular.module('trackingMethod.editorView', [])
    .directive('trackingMethodEditor', trackingMethodEditor)
    .controller('TrackingMethodEditorCtrl', TrackingMethodEditorCtrl);

function trackingMethodEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/tracking-method-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, TrackingMethodEditorVM) {
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('TrackingMethodEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);
            updateOptionsObjectFromReqDetailsArray();

            function updateOptionsObjectFromReqDetailsArray() {
                _.forEach(TrackingMethodEditorVM.options, function(e) {
                    e.selected = !!scope.requirement.details && (scope.requirement.details.indexOf(e.id) !== -1);
                });
            }

            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];
                _.forEach(TrackingMethodEditorVM.options, function(e) {
                    if (e.selected) {
                        x.push(e.id);
                    }
                });
                scope.requirement.details = x;
            }
        },
        controller: 'TrackingMethodEditorCtrl',
        controllerAs: 'TrackingMethodEditorVM',
    };
}
TrackingMethodEditorCtrl.$inject = [
    '$scope',
];

function TrackingMethodEditorCtrl($scope) {
    var TrackingMethodEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    TrackingMethodEditorVM.setAll = setAll;

    TrackingMethodEditorVM.options = angular.copy(trackingMethodOptions.list);

    function setAll(val) {
        _.forEach(TrackingMethodEditorVM.options, function(e) {
            e.selected = val;
        });
    }
}
