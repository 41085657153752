import webBrowserOptions from './web-browser/options';

/**
 * @param req
 */
function toString(req) {
    return _.map(req.details, function(id) {
        return webBrowserOptions.map[id].name;
    }).join(', ');
}

/**
 * @param req
 */
function errors(req) {
    const errs = [],
        l = (req.details && req.details.length) || 0;

    // Require at least one group to be selected.
    if (l === 0) {
        errs.push('No web browsers are selected. Select at least one.');
    }

    // Require at least one group to be un-selected.
    if (webBrowserOptions.list.length === l) {
        errs.push('All web browsers are selected. Unselect at least one.');
    }

    return errs;
}

const targetingAttributeWebBrowser = {
    id: 'webBrowser',
    name: 'Web browser',
    basic: true,
    advanced: false,
    description: 'Filter by the respondent&rsquo; web browser',
    options: webBrowserOptions.list,
    toString: toString,
    errors: errors,
};

export default targetingAttributeWebBrowser;
