import 'angular-dragdrop';
import './pub-sub';

angular.module('svDnd', ['ngDragDrop', 'pubSub'])
    .directive('svDndImage', svDndImage)
    .controller('SvDndImageCtrl', SvDndImageCtrl);

function svDndImage() {
    return {
        restrict: 'AE',
        scope: {
            image: '=',
            ux: '=',
            imageNames: '=',
        },
        templateUrl: '/shared-templates/sv-dnd.html',
        controller: 'SvDndImageCtrl',
        controllerAs: 'SvDndImageVM',
        link: function(scope, element, attrs) {
            var model = attrs.svDndImage;
            scope.model = model;
            element.find('.drop-zone')
                .on('dropover', function() {
                    $(this).addClass('over');
                })
                .on('dropout', function() {
                    $(this).removeClass('over');
                });
        },
    };
}
SvDndImageCtrl.$inject = ['$scope', 'pubSubService'];

function SvDndImageCtrl($scope, pubSubService) {
    var SvDndImageVM = this;
    SvDndImageVM.dropOpts = '{tolerance: \'touch\'}';
    SvDndImageVM.dragOpts1 = '{revert: \'invalid\', revertDuration: 300}';
    SvDndImageVM.dragOpts2 = '{onStop: \'SvDndImageVM.stopDrag\', onDrag: \'SvDndImageVM.startDrag\'}';
    SvDndImageVM.imageNames = $scope.imageNames;
    SvDndImageVM.deleteImage = deleteImage;
    SvDndImageVM.startDrag = startDrag;
    SvDndImageVM.stopDrag = stopDrag;

    function deleteImage() {
        $scope.image = null;
        $scope.ux.itemSet = null;
    }

    function startDrag() {
        $scope.ux.isDragging = true;
        pubSubService.notify('update-is-dragging', true);
    }

    function stopDrag() {
        $scope.ux.showDropZones = false;
        $scope.ux.isDragging = false;
        pubSubService.notify('update-is-dragging', false);
    }
}
