import _ from 'lodash';
import '../../beacons/api-beacon-service';
import adMeasurementConstants from '../ad-measurement.constants';
import adMeasurementService from '../ad-measurement.service';

angular.module('audienceConfiguration.beaconControlConfiguration', [
    'apiBeaconService', 'adMeasurement.apiService',
])
    .controller('BeaconControlConfigurationCtrl', BeaconControlConfigurationCtrl)
    .directive('beaconControlConfiguration', beaconControlConfiguration);

/**
 *
 */
function beaconControlConfiguration() {
    return {
        restrict: 'E',
        templateUrl: '/dashboard-templates/ad-measurement/audience-configuration/beacon-control-configuration.html',
        scope: {
            beaconControlConfigurations: '=',
            audienceConfiguration: '=',
        },
        controller: 'BeaconControlConfigurationCtrl',
        controllerAs: 'BeaconControlConfigurationVM',
    };
}

BeaconControlConfigurationCtrl.$inject = [
    '$rootScope',
    '$scope',
    'beaconService',
    'adMeasurementApiService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param beaconService
 * @param adMeasurementApiService
 */
function BeaconControlConfigurationCtrl($rootScope, $scope, beaconService, adMeasurementApiService) {
    const BeaconControlConfigurationVM = this,
        stepManager = adMeasurementService.getStepManager();
    BeaconControlConfigurationVM.configuration = {};
    BeaconControlConfigurationVM.controlTypes = adMeasurementConstants.controlTypes;
    BeaconControlConfigurationVM.demographicsStrategies = adMeasurementConstants.demographicsStrategies;

    BeaconControlConfigurationVM.stepManager = stepManager;
    BeaconControlConfigurationVM.stepOptions = adMeasurementConstants.beaconControlConfigurationStepTypes;
    BeaconControlConfigurationVM.steps = stepManager.steps[stepManager.currentStepIndex];
    BeaconControlConfigurationVM.currentStepIndex = stepManager.currentNestedStepIndex;
    BeaconControlConfigurationVM.currentStep = stepManager.currentSubStep;

    BeaconControlConfigurationVM.exposurePixels = [];
    BeaconControlConfigurationVM.selectedPixel = null;
    BeaconControlConfigurationVM.ux = {
        selectedPixelsExpanded: false,
        demographicsExpanded: false,
        ready: false,
        filterText: '',
        errorMsg: '',
    };

    // VM exposed functions
    BeaconControlConfigurationVM.next = next;
    BeaconControlConfigurationVM.back = back;
    BeaconControlConfigurationVM.updateDemographicsStrategy = updateDemographicsStrategy;
    BeaconControlConfigurationVM.getBeaconControlConfigurationCreationError = getBeaconControlConfigurationCreationError;
    BeaconControlConfigurationVM.onControlTypeSelected = onControlTypeSelected;
    BeaconControlConfigurationVM.onPixelSelected = onPixelSelected;
    BeaconControlConfigurationVM.onAudienceConfigSelected = onAudienceConfigSelected;
    BeaconControlConfigurationVM.pixelFilter = pixelFilter;
    BeaconControlConfigurationVM.clearErrorMsg = clearErrorMsg;
    BeaconControlConfigurationVM.getDemographicsStrategyDescription = adMeasurementService.getDemographicsStrategyDescription;
    BeaconControlConfigurationVM.toggleAdvancedDemographics = toggleAdvancedDemographics;

    init();

    function init() {
        beaconService.listTags({
            userId: $rootScope.survey.user.id,
        }).then(function(exposurePixels) {
            BeaconControlConfigurationVM.exposurePixels = exposurePixels;
        });
    }

    /**
     * @param e
     */
    function next(e) {
        BeaconControlConfigurationVM.ux.errorMsg = getBeaconControlConfigurationCreationError();
        if (BeaconControlConfigurationVM.ux.errorMsg) {
            return;
        }
        if (stepManager.currentNestedStepIndex) {
            return save(e);
        }
        BeaconControlConfigurationVM.configuration.name = adMeasurementService.getBeaconControlConfigurationName(BeaconControlConfigurationVM.configuration, $rootScope.survey, BeaconControlConfigurationVM.selectedPixel);
        stepManager.setCurrentStep(e, stepManager.currentStepIndex, ++BeaconControlConfigurationVM.currentStepIndex);
        BeaconControlConfigurationVM.ux.errorMsg = getBeaconControlConfigurationCreationError();
    }

    /**
     *
     */
    function getBeaconControlConfigurationCreationError() {
        return adMeasurementService.checkBeaconControlConfigurationForErrors(BeaconControlConfigurationVM.currentStep.name, BeaconControlConfigurationVM.configuration, $scope.beaconControlConfigurations);
    }

    /**
     * @param e
     */
    function back(e) {
        if (BeaconControlConfigurationVM.currentStepIndex) {
            stepManager.setCurrentStep(e, stepManager.currentStepIndex, --BeaconControlConfigurationVM.currentStepIndex);
        }
        else {
            stepManager.setCurrentStep(e, stepManager.currentStepIndex);
        }
    }

    /**
     * @param e
     */
    function saveBeaconControlConfiguration(e) {
        return adMeasurementApiService.createBeaconControlConfiguration($rootScope.survey, BeaconControlConfigurationVM.configuration).then(function(response) {
            $scope.$evalAsync(() => {
                $scope.beaconControlConfigurations = $scope.beaconControlConfigurations || [];
                $scope.beaconControlConfigurations.push(response);
                response.preventDelete = true;
                $scope.audienceConfiguration.beaconControlConfigurationUuid = response.uuid;
                $scope.audienceConfiguration.beaconControlConfigurationName = response.name;
                stepManager.setCurrentStep(e, stepManager.currentStepIndex);
            });
        }, function(error) {
            console.log('error is: ' + JSON.stringify(error));
        });
    }

    /**
     * @param e
     */
    function save(e) {
        if (BeaconControlConfigurationVM.selectedPixel) {
            let newBeaconPool = {
                name: BeaconControlConfigurationVM.configuration.beaconPoolName,
                beaconPoolBeacons: [{
                    beaconDefinition: BeaconControlConfigurationVM.selectedPixel,
                    beaconInclusion: 'allowed',
                }],
                beaconType: BeaconControlConfigurationVM.selectedPixel.beaconType,
            };
            return beaconService.createBeaconPool(newBeaconPool).then(function(beaconPool) {
                $scope.$evalAsync(() => {
                    BeaconControlConfigurationVM.configuration.beaconPoolUuid = beaconPool.uuid;
                    return saveBeaconControlConfiguration(e);
                });
            });
        }
        return saveBeaconControlConfiguration(e);
    }

    /**
     * @param controlType
     */
    function onControlTypeSelected(controlType) {
        if (controlType === adMeasurementConstants.DEMOGRAPHIC_ONLY) {
            BeaconControlConfigurationVM.selectedPixel = null;
            BeaconControlConfigurationVM.configuration.beaconPoolUuid = null;
            delete BeaconControlConfigurationVM.configuration.beaconPoolName;
        }
    }

    /**
     * @param selectedPixel
     */
    function onPixelSelected(selectedPixel) {
        BeaconControlConfigurationVM.selectedPixel = selectedPixel;
        // Refresh name for both BCC and beacon pool based on selected pixel
        BeaconControlConfigurationVM.configuration.name = adMeasurementService.getBeaconControlConfigurationName(BeaconControlConfigurationVM.configuration,
            $rootScope.survey,
            BeaconControlConfigurationVM.selectedPixel);
        BeaconControlConfigurationVM.configuration.beaconPoolName = BeaconControlConfigurationVM.configuration.name;
        clearErrorMsg();
    }

    /**
     * @param config
     */
    function onAudienceConfigSelected(config) {
        BeaconControlConfigurationVM.configuration.beaconPoolName = config.name;
    }

    function toggleAdvancedDemographics() {
        BeaconControlConfigurationVM.ux.demographicsExpanded = !BeaconControlConfigurationVM.ux.demographicsExpanded;
        if (BeaconControlConfigurationVM.ux.demographicsExpanded && !BeaconControlConfigurationVM.configuration.demographicsStrategy) {
            BeaconControlConfigurationVM.configuration.demographicsStrategy = adMeasurementConstants.demographicsStrategies.matching.value;
            updateDemographicsStrategy();
        }
    }

    function clearErrorMsg() {
        delete BeaconControlConfigurationVM.ux.errorMsg;
    }

    function updateDemographicsStrategy() {
        if (BeaconControlConfigurationVM.configuration.demographicsStrategy === adMeasurementConstants.MATCHING) {
            // Clear out quota params, if they exist
            _.forEach(BeaconControlConfigurationVM.demographicsStrategies.quotas.options, function(quotaType) {
                _.forEach(quotaType.options, function(quotaItem) {
                    delete BeaconControlConfigurationVM.configuration[quotaItem.type];
                });
            });
            // Initialize matching options
            _.forEach(BeaconControlConfigurationVM.demographicsStrategies.matching.options, function(matchingItem) {
                if (BeaconControlConfigurationVM.configuration.controlType === adMeasurementConstants.DEMOGRAPHIC_ONLY) {
                    BeaconControlConfigurationVM.configuration[matchingItem.type] = true;
                }
                else {
                    BeaconControlConfigurationVM.configuration[matchingItem.type] = matchingItem.defaultValue;
                }
            });
        }
        else {
            // Clear out matching params, if they exist
            _.forEach(BeaconControlConfigurationVM.demographicsStrategies.matching.options, function(matchingItem) {
                delete BeaconControlConfigurationVM.configuration[matchingItem.type];
            });
            // Initialize to default values
            _.forEach(BeaconControlConfigurationVM.demographicsStrategies.quotas.options, function(quotaType) {
                _.forEach(quotaType.options, function(quotaItem) {
                    BeaconControlConfigurationVM.configuration[quotaItem.type] = quotaItem.defaultValue;
                });
            });
        }

        // Clear any existing error messages
        clearErrorMsg();
    }

    /**
     * @param item
     */
    function pixelFilter(item) {
        return !BeaconControlConfigurationVM.ux.filterText.length || item.exportName.includes(BeaconControlConfigurationVM.ux.filterText);
    }
}
