function publisherDateService() {
    function getStartDate() {
        var now = new Date(),
            threeDaysAgo = new Date(),
            year = now.getFullYear(),
            month = now.getMonth(),
            firstOfPreviousMonth = new Date(year, month - 1, 1),
            firstOfMonth = new Date(year, month, 1);
        threeDaysAgo.setDate(now.getDate() - 3);
        if (threeDaysAgo <= firstOfMonth) {
            return firstOfPreviousMonth;
        }
        return firstOfMonth;
    }

    function getEndDate() {
        var today = new Date();
        return today;
    }

    return {
        getStartDate: getStartDate,
        getEndDate: getEndDate,
    };
}

export default angular.module('publisherDate.service', [])
    .service('publisherDateService', publisherDateService);
