/* eslint compat/compat: "off" */
import _ from 'lodash';
import U from '../../../../../common/js/util';
import httpDeferred from '../../../../../common/js/http-deferred';
import analysisConfigConstants from './analysis-config-constants';

/**
 * Add is_control to json
 *
 * @param {object} json
 * @param {string} val
 *
 * @returns {object}
 */
function addIsControlToJSON(json, val) {
    let isControlJSON = {
        $eq: ['is_control', val],
    };
    json = json || {
        $and: [],
    };
    if (!json.$and) {
        const newJson = {
            $and: [],
        };
        newJson.$and.push(json);
        json = newJson;
    }
    json.$and.push(isControlJSON);

    return json;
}

/**
 * Clean a JSON object
 *
 * @param {object} json
 *
 * @returns {object}
 */
function cleanJson(json) {
    const keys = _.keys(json);
    _.forEach(keys, key => {
        const value = json[key];
        if (Array.isArray(value) && !value.length) {
            delete json[key];
        }
        else if (!Array.isArray(value)) {
            cleanJson(value);
        }
    });
    return _.isEmpty(json) ? null : json;
}

/**
 * Remove is_control from a json object, and return a string
 *
 * @param {object} json
 *
 * @returns {string}
 */
function removeIsControl(json) {
    if (!json) {
        return;
    }
    return JSON.stringify(json).replace(/\{"\$eq":\["is_control",(0|1)\]\},/g, '');
}

///////////////////////////////////////////
//      Analysis Configuration CRUD
///////////////////////////////////////////
/**
 * Post request to create a new analysis configuration with the survey uuid
 *
 * @param {string} surveyUuid
 *
 * @returns {Promise}
 */
function createAnalysisConfiguration(surveyUuid) {
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/analysis-configuration');
}

/**
 * Get the list of analysis configuration for a survey
 *
 * @param {string} surveyUuid
 *
 * @returns {Promise}
 */
function listAnalysisConfigurations(surveyUuid) {
    return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/analysis-configuration/');
}

/**
 * Update an analysis configuration
 *
 * @param {object} analysisConfig
 *
 * @returns {Promise}
 */
function updateAnalysisConfiguration(analysisConfig) {
    const promises = [];
    _.forEach(analysisConfigConstants.factorTypes, factorType => {
        let factorList;
        if (factorList.field) {
            factorList = analysisConfig[factorType.field];
        }
        else {
            factorList = analysisConfig.confounders.behavioral;
        }
        _.forEach(factorList, (factor, idx) => {
            let original = analysisConfig[factorType.original][idx],
                shouldUpdateAnalysisFactor = !original || original.name !== factorType.name || original.analysisUsage !== factor.analysisUsage.value;
            if (shouldUpdateAnalysisFactor) {
                updateAnalysisFactor(analysisConfig.version, factor).then(kpiFactor => {
                    promises.push(updateFactorLevels(kpiFactor));
                });
            }
            else {
                promises.push(updateFactorLevels(factor));
            }
        });
    });
    _.forEach(analysisConfig.humanCutter.cuts, (cut, name) => {
        promises.push(updateAnalysisCut(analysisConfig.version, name, cut));
    });
    return Promise.all(promises);
}

//////////////////////////////////////////////////////////////
// Analysis config settings CRUD (kinda. Read and Update only)
//////////////////////////////////////////////////////////////
/**
 * Get the analysis config settings by the uuid
 *
 * @param {string} analysisConfigUuid
 *
 * @returns {Promise}
 */
function getAnalysisConfigSettings(analysisConfigUuid) {
    return new Promise(resolve => {
        httpDeferred.get(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-configuration-parameters/analysis-plan-settings-list`).then(response => {
            resolve(response);
        }, () => {
            resolve(analysisConfigConstants.defaultAnalysisPlanSettings);
        });
    });
}

/**
 * Save the analysis configuration settings
 *
 * @param {string} analysisConfigUuid
 * @param {object} settings
 *
 * @returns {Promise}
 */
function saveAnalysisConfigSettings(analysisConfigUuid, settings) {
    return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-configuration-parameters/analysis-plan-settings`, settings);
}

///////////////////////////////////////////
//           Analysis cut CRUD
///////////////////////////////////////////
/**
 * Create or update an analysis cut under the parent analysis configuration.
 * Cleans exposed and control JSON of empty arrays or values before saving.
 *
 * @param {string} analysisConfigUuid - UUID of the parent analysis configuration
 * @param {string} name - The cut name to be saved
 * @param {object} cut - The analysis cut object
 *
 * @returns {Promise} A promise to be resolved when REST call returns (or rejected, if call fails)
 */
function updateAnalysisCut(analysisConfigUuid, name, cut) {
    const updatedCut = {
        name: name,
        exposedFormulaJson: cut.exposed,
        controlFormulaJson: cut.type.value === 'custom' ? cut.control : null,
        controlType: cut.type.value,
    };
    cut.exposed = cleanJson(cut.exposed);
    cut.control = cut.type.value === 'custom' ? cleanJson(cut.control) : null;

    if (cut.uuid) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/analysis-configuration/' + analysisConfigUuid + '/analysis-cut/' + cut.uuid, updatedCut);
    }
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/analysis-configuration/' + analysisConfigUuid + '/analysis-cut', updatedCut);
}

/**
 * Create or update an analysis cut under the parent analysis cut category
 * Cleans exposed and control JSON of empty arrays or values before saving.
 *
 * @param {string} analysisCutCategoryUuid - UUID of the parent analysis cut category
 * @param {string} name - The cut name to be saved
 * @param {object} cut - The analysis cut object
 *
 * @returns {Promise} A promise to be resolved when REST call returns (or rejected, if call fails)
 */
function updateAnalysisCutByCategory(analysisCutCategoryUuid, name, cut) {
    const updatedCut = {
        name: name,
        exposedFormulaJson: cut.exposed,
        controlFormulaJson: cut.type.value === 'custom' ? cut.control : null,
        controlType: cut.type.value,
        analysisCutCategory: cut.analysisCutCategory,
    };
    cut.exposed = cleanJson(cut.exposed);
    cut.control = cut.type.value === 'custom' ? cleanJson(cut.control) : null;

    if (cut.uuid) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/analysis-cut-category/' + analysisCutCategoryUuid + '/analysis-cut/' + cut.uuid, updatedCut);
    }
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/analysis-cut-category/' + analysisCutCategoryUuid + '/analysis-cut', updatedCut);
}

/**
 * List all the analysis cuts for an analysis config
 *
 * @param {string} analysisConfigUuid
 *
 * @returns {Promise}
 */
function listAnalysisCuts(analysisConfigUuid) {
    return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-cut/`);
}

/**
 * Get all analysis cuts by category
 *
 * @param {string} analysisCutCategoryUuid
 *
 * @returns {Promise}
 */
function listAnalysisCutsByCategory(analysisCutCategoryUuid) {
    return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/analysis-cut-category/${analysisCutCategoryUuid}/analysis-cut/`);
}

/**
 * Delete an analysis cut by its uuid
 *
 * @param {string} cutUuid
 *
 * @returns {Promise}
 */
function deleteAnalysisCutByUuid(cutUuid) {
    return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/analysis-cut/' + cutUuid);
}

///////////////////////////////////////////
//       Analysis cut category CRUD
///////////////////////////////////////////
/**
 * Create analysis cut category.
 *
 * @param {string} analysisConfigUuid - Uuid of the parent configuration
 * @param {object} cutCategory - Cut category to create
 * @returns {Promise} Promise to be resolved when API call finishes
 */
function createAnalysisCutCategory(analysisConfigUuid, cutCategory) {
    return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-cut-category`, cutCategory);
}

/**
 * Update analysis cut category.
 *
 * @param {string} analysisConfigUuid - Uuid of the parent configuration
 * @param {object} cutCategory - Cut category to update
 * @returns {Promise} Promise to be resolved when API call finishes
 */
function updateAnalysisCutCategory(analysisConfigUuid, cutCategory) {
    return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-cut-category/${cutCategory.uuid}`, cutCategory);
}

/**
 * Get all the cut categories of an analysis configuration
 *
 * @param {string} analysisConfigUuid
 *
 * @returns {Promise}
 */
function listAnalysisCutCategories(analysisConfigUuid) {
    return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-cut-category`);
}

/**
 * Get all the cut categories and cuts of an analysis configuration
 *
 * @param {string} analysisConfigUuid
 *
 * @returns {Promise}
 */
function listAnalysisCutCategoriesAndCuts(analysisConfigUuid) {
    return new Promise((resolve, reject) => {
        listAnalysisCutCategories(analysisConfigUuid).then(categories => {
            const promises = [];
            _.forEach(categories, category => {
                promises.push(listAnalysisCutsByCategory(category.uuid));
            });
            Promise.all(promises).then(cutsByCategory => {
                _.forEach(cutsByCategory, (categoryCuts, idx) => {
                    categories[idx].cuts = categoryCuts;
                    categories[idx].categoryTypeLabel = _.capitalize(categories[idx].analysisCutCategoryType);
                });
                resolve(categories);
            }, reject);
        }, reject);
    });
}

///////////////////////////////////////////
//         Analysis Factor CRUD
///////////////////////////////////////////
/**
 * Save an analysis factor
 *
 * @param {string} analysisConfigUuid
 * @param {object} analysisFactor
 *
 * @returns {Promise}
 */
function saveAnalysisFactor(analysisConfigUuid, analysisFactor) {
    let params = {
        name: analysisFactor.name,
        factorType: analysisFactor._factorType,
        analysisUsage: analysisFactor.analysisUsage.value,
        objectivePriority: (analysisFactor.objectivePriority || {}).value,
        kpiType: (analysisFactor.kpiType || {}).value,
        confounderType: (analysisFactor.confounderType || {}).value,
    };
    if (analysisFactor.uuid) {
        return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-factor/${analysisFactor.uuid}`, params);
    }
    return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/analysis-factor/`, params);
}

/**
 * Update an analysis factor
 *
 * @param {string} analysisConfigUuid
 * @param {object} analysisFactor
 *
 * @returns {Promise}
 */
function updateAnalysisFactor(analysisConfigUuid, analysisFactor) {
    return new Promise((resolve, reject) => {
        saveAnalysisFactor(analysisConfigUuid, analysisFactor).then(updated => {
            if (updated) {
                analysisFactor.uuid = updated.uuid;
                analysisFactor._displayName = U.limitStrLength(analysisFactor.name, 80);
                analysisFactor._displayNameFull = analysisFactor.name.replace(/^\d+\.(\d+\.)? /, '');
            }
            updateFactorLevels(analysisFactor).then(() => {
                resolve(analysisFactor);
            }, reject);
        }, reject);
    });
}

/**
 * Get all the factors of an analysis configuration
 *
 * @param {string} analysisConfigUuid
 *
 * @returns {Promise}
 */
function listAnalysisFactors(analysisConfigUuid) {
    return httpDeferred.get(httpDeferred.dashboardHost + '/v1/analysis-configuration/' + analysisConfigUuid + '/analysis-factor/');
}

/**
 * Delete an analysis factor
 *
 * @param {object} factor
 *
 * @returns {Promise}
 */
function deleteAnalysisFactor(factor) {
    return new Promise((resolve, reject) => {
        const promises = [];
        httpDeferred.delete(httpDeferred.dashboardHost + '/v1/analysis-factor/' + factor.uuid).then(() => {
            _.forEach(factor.levels, level => {
                promises.push(deleteFactorLevel(level.uuid));
            });
        });
        Promise.all(promises).then(resolve, reject);
    });
}

///////////////////////////////////////////
//       Analysis Factor Level CRUD
///////////////////////////////////////////

/**
 * Get all the factor levels of a factor
 *
 * @param {string} analysisFactorUuid
 *
 * @returns {Promise}
 */
function listFactorLevels(analysisFactorUuid) {
    return httpDeferred.get(httpDeferred.dashboardHost + '/v1/analysis-factor/' + analysisFactorUuid + '/factor-level/');
}

/**
 * Update a factor level
 *
 * @param {string} analysisFactorUuid
 * @param {object} level
 *
 * @returns {Promise}
 */
function updateFactorLevel(analysisFactorUuid, level) {
    let factorLevel = {
        name: level.name,
        definitionJson: level.definitionJson,
        testType: level.testType && level.testType.value,
        factorType: level.factorType || 'kpi',
        isBrand: level.isBrand,
        brandUuid: level.brandUuid,
        isTargetOption: level.isTargetOption,
    };
    if (level.uuid) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/analysis-factor/' + analysisFactorUuid + '/factor-level/' + level.uuid, factorLevel);
    }
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/analysis-factor/' + analysisFactorUuid + '/factor-level', factorLevel);
}

/**
 * Update all the factor levels of a factor
 *
 * @param {object} factor
 *
 * @returns {Promise}
 */
function updateFactorLevels(factor) {
    const promises = [];
    _.forEach(factor.levels, level => {
        if (level.deleted) {
            promises.push(deleteFactorLevel(level.uuid));
        }
        else {
            const call = updateFactorLevel(factor.uuid, level);
            promises.push(call);
            call.then(updated => {
                level.uuid = updated.uuid;
                level._displayName = U.limitStrLength(level.name, 80);
                level._displayNameFull = level.name;
            });
        }
    });
    return Promise.all(promises);
}

/**
 * Delete a factor level
 *
 * @param {string} factorLevelUuid
 *
 * @returns {Promise}
 */
function deleteFactorLevel(factorLevelUuid) {
    return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/factor-level/' + factorLevelUuid);
}

/**
 * Get lift results of an analysis configuration
 *
 * @param {string} analysisConfigUuid
 *
 * @returns {Promise}
 */
function getLiftResults(analysisConfigUuid) {
    return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/analysis-configuration/${analysisConfigUuid}/lift-result/download`);
}

///////////////////////////////////////////
//      General data fetching
///////////////////////////////////////////

/**
 * Gets the list of metro areas from the backend
 *
 * @returns {Promise} - the list of metro areas or an error
 */
function getMetroAreas() {
    return new Promise((resolve, reject) => {
        httpDeferred.get(`${httpDeferred.dashboardHost}/v1/metro-area`)
            .then(metroAreas => {
                if (metroAreas.length) {
                    resolve(metroAreas);
                }
                else {
                    reject(new Error('Unable to collect the list of metro areas'));
                }
            })
            .catch(e => console.error(e));
    });
}

/**
 * Gets the list of kpi types from the backend
 *
 * @returns {Promise} - the list of kpi types or an error
 */
function getKpiTypes() {
    return new Promise((resolve, reject) => {
        httpDeferred.get(`${httpDeferred.dashboardHost}/v1/kpi-type`)
            .then(kpiTypes => {
                if (kpiTypes.length) {
                    resolve(kpiTypes);
                }
                else {
                    reject(new Error('Unable to collect the list of kpi types'));
                }
            })
            .catch(e => console.error(e));
    });
}

/**
 * Gets the list of confounder types from the backend
 *
 * @returns {Promise} - the list of confounder types or an error
 */
function getConfounderTypes() {
    return new Promise((resolve, reject) => {
        httpDeferred.get(`${httpDeferred.dashboardHost}/v1/confounder-type`)
            .then(confounderTypes => {
                if (confounderTypes.length) {
                    resolve(confounderTypes);
                }
                else {
                    reject(new Error('Unable to collect the list of confounder types'));
                }
            })
            .catch(e => console.error(e));
    });
}
/**
 * Gets the list of objective priorities from the backend
 *
 * @returns {Promise} - the list of objective priorities or an error
 */
function getObjectivePriorities() {
    return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/objective-priority`)
        .then(objectivePriorities => {
            if (!objectivePriorities.length) {
                const error = new Error('Unable to collect the list of objective priorities');
                throw error;
            }
            return objectivePriorities;
        })
        .catch(error => {
            console.log(error);
            throw error;
        });
}

const analysisConfigDataService = {
    //Analysis config CRUD.
    createAnalysisConfiguration,
    updateAnalysisConfiguration,
    listAnalysisConfigurations,

    // Analysis cut category CRUD
    listAnalysisCutCategories,
    listAnalysisCutCategoriesAndCuts,
    createAnalysisCutCategory,
    updateAnalysisCutCategory,

    //Factor level CRUD
    updateFactorLevels,
    updateFactorLevel,
    listFactorLevels,
    deleteFactorLevel,

    //Analysis cut CRUD
    updateAnalysisCut,
    updateAnalysisCutByCategory,
    listAnalysisCuts,
    deleteAnalysisCutByUuid,

    //Analysis factor CRUD
    saveAnalysisFactor,
    updateAnalysisFactor,
    deleteAnalysisFactor,
    listAnalysisFactors,

    //Get static lists from BE
    getMetroAreas,
    getKpiTypes,
    getConfounderTypes,
    getObjectivePriorities,

    removeIsControl,
    addIsControlToJSON,

    getAnalysisConfigSettings,
    saveAnalysisConfigSettings,
    getLiftResults,
};

export default analysisConfigDataService;
