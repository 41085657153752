/* eslint compat/compat: "off" */
/* eslint complexity: "off" */
/* global domtoimage */
import * as d3 from 'd3-selection';
import exportConstants from './export-constants';

const SVG = 'svg',
    WIDTH = 'width',
    HEIGHT = 'height',
    TRANSFORM = 'transform',
    TAGS_WITH_NO_ATTRIBUTES = {
        div: true,
        svg: true,
    };

/**
 * @name setComputedStyles
 * @param {element} element - The original element in the dom
 * @param {element} cloned - The cloned element
 * @param {number} scale - The amount to scale the elements by
 * @param {boolean} isSvg - Whether we're setting the computed style for an svg or not
 *
 * @description
 * Iterates through every child of the element to add the elements' computed styles as an
 * inline style for the cloned element. Ignores width, height, and animation styles.
 */
function setComputedStyles(element, cloned, scale, isSvg) {
    let elementTagName = element.tagName.toLowerCase(),
        tagName = cloned.tagName.toLowerCase();
    if (tagName === 'image') {
        if (isSvg) {
            cloned.setAttribute(WIDTH, convertPixelToFloat(element.getAttribute(WIDTH)) * scale);
            cloned.setAttribute(HEIGHT, convertPixelToFloat(element.getAttribute(HEIGHT)) * scale);
            cloned.setAttribute('href', element.getAttribute('href'));
            cloned.removeAttribute('xlink:href');
        }
        else {
            cloned.remove();
        }
        return cloned;
    }
    if (tagName === 'div') {
        let newCloned = document.createElementNS(exportConstants.svgNamespace, SVG);
        newCloned.innerHTML = cloned.innerHTML;
        if (cloned.parentNode) {
            cloned.parentNode.replaceChild(newCloned, cloned);
        }
        cloned.remove();
        cloned = newCloned;
        tagName = SVG;
    }
    if (tagName === SVG && !cloned.parentNode && !isSvg) {
        d3.select(cloned).attr('xmlns', exportConstants.svgNamespace);
    }
    if (!TAGS_WITH_NO_ATTRIBUTES[elementTagName]) {
        cloned = setScaledElementAttr(element, cloned, scale);
    }
    cloned = setScaledStyle(element, cloned, scale);
    cloned = removeUnnecessaryAttribute(cloned);
    _.forEach(cloned.children, (child, idx) => {
        setComputedStyles(element.children[idx], child, scale, isSvg);
    });

    return cloned;
}

/**
 * @name prepareLogo
 *
 * @description
 * To be used later to append the survata logo to Safari downloads
 * note from NATE — the logos are directives but not using class names any longer. See `survata-logos.js`
 */
function prepareLogo() {
    var elem = document.getElementsByTagName('survata-icon')[0];
    elem.style['font-size'] = '4.5em';
    return domtoimage.toPng(elem, {
        quality: 1.0,
    }).then(data => {
        exportConstants.logo.dataUrl = data.canvas.toDataURL();
        exportConstants.logo.width = data.canvas.width;
        exportConstants.logo.height = data.canvas.height;
        elem.style['font-size'] = '';
    });
}

function getTranslateValues(elem) {
    let translate = elem.getAttribute(TRANSFORM).match(/translate\((\d+\.?\d*),\s*(\d+.?\d*)\)/) || [];
    return {
        x: parseFloat(translate[1] || 0),
        y: parseFloat(translate[2] || 0),
    };
}

function createSvgNode(options) {
    let svgNode = document.createElementNS(exportConstants.svgNamespace, 'svg');
    svgNode.setAttribute('xmlns', exportConstants.svgNamespace);
    setDimensionalAttributes(svgNode, options);
    return svgNode;
}

function createForeignObjectNode(options) {
    let foreign = document.createElementNS(exportConstants.svgNamespace, 'foreignObject');
    setDimensionalAttributes(foreign, options);
    return foreign;
}

function createDocumentElement(tag, options) {
    let elem = document.createElement(tag);
    setDimensionalAttributes(elem, options);
    return elem;
}

function fontHasChanged(font1, font2) {
    let font1Properties = font1.split(' '),
        font2Properties = font2.split(' ');
    if (!(font1Properties[0] === 'bold' && font2Properties[0] === '700')) {
        return true;
    }
    return font1Properties[1] !== font2Properties[1];
}

/* Private functions */
function removeUnnecessaryAttribute(cloned) {
    let attributes = cloned.attributes,
        attributesToIgnore = [];

    for (let i = 0; i < attributes.length; i++) {
        let attr = attributes[i];
        if (!attr) {
            continue;
        }
        if (attr.name.match(/^(data-|ng-|class$)/)) {
            attributesToIgnore.push(attr.name);
        }
    }
    _.forEach(attributesToIgnore, attr => {
        cloned.removeAttribute(attr);
    });
    return cloned;
}

function setScaledElementAttr(element, cloned, scale) {
    let elementTagName = element.tagName.toLowerCase(),
        transform = cloned.getAttribute(TRANSFORM);
    if (transform) {
        let translate = transform.match(/translate\((-?[0-9.]+),\s?(-?[0-9.]+)\)/) || [],
            translateX = parseFloat(translate[1] || 0) * scale,
            translateY = parseFloat(translate[2] || 0) * scale;
        if (translate && !(translateX === '0' && translateY === '0')) {
            d3.select(cloned).attr(TRANSFORM, transform.replace(/translate\(-?[0-9.]+,\s?-?[0-9.]+\)/,
                'translate(' + translateX + ', ' + translateY + ')'));
        }
    }
    _.forEach([
        'x',
        'y',
        'x1',
        'y1',
        'x2',
        'y2',
        HEIGHT,
        WIDTH,
    ], attr => {
        let value = cloned.getAttribute(attr);
        if (value) {
            d3.select(cloned).attr(attr, (parseFloat(value) * scale) + value.replace(/-?[0-9.]+/, '') || value);
        }
    });
    if (elementTagName === 'path' && element.getAttribute('class') === 'line') {
        let values = _.map(cloned.getAttribute('d').split(/[ML,]/), num => {
                return parseFloat(num);
            }),
            descriptions = cloned.getAttribute('d').split(/[0-9.]+/),
            newD = '',
            valueIdx = isNaN(values[0]) ? 1 : 0;
        _.forEach(descriptions, description => {
            if (description.length) {
                newD += description + (values[valueIdx++] * scale);
            }
        });
        d3.select(cloned).attr('d', newD);
    }
    return cloned;
}

function getNumericValues(value, scale) {
    let values = [],
        currentString = '',
        currentDigit = '';
    for (var i = 0; i < value.length; i++) {
        let char = value[i];
        if (char.match(/\d|\./)) {
            currentDigit += char;
            if (currentString) {
                values.push(currentString);
                currentString = '';
            }
        }
        else {
            currentString += char;
            if (currentDigit) {
                let suffix = char + value[i + 1];
                values.push(parseFloat(currentDigit) * (suffix === 'em' || char === '%' ? 1 : scale));
                currentDigit = '';
            }
        }
    }
    if (currentDigit) {
        values.push(parseFloat(currentDigit) * scale);
    }
    if (currentString) {
        values.push(currentString);
    }
    return values.join('');
}

function setScaledStyle(element, cloned, scale) {
    let css = window.getComputedStyle(element),
        styles = '',
        elementTagName = element.tagName.toLowerCase(),
        bounds = element.getBoundingClientRect();

    for (let i = 0; i < css.length; i++) {
        let style = css[i],
            property = css.getPropertyValue(style);
        if (isScaleableProperty(property || element.style[style], style)) {
            styles += style + ':' + getNumericValues(property || element.style[style], scale) + ';';
        }
        else if (!styleToSkip(property) && isUsefulStyle(style, property)) {
            styles += style + ':' + property + ';';
        }
    }
    styles += 'overflow:visible;';
    d3.select(cloned).attr('style', styles);
    if (!cloned.style.display) {
        cloned.style.display = 'block';
    }
    if (elementTagName === 'div' && cloned.parentNode) {
        d3.select(cloned).attr('x', (element.style.left ? parseFloat(element.style.left) : bounds.left) * scale);
        d3.select(cloned).attr('y', (element.style.top ? parseFloat(element.style.top) : bounds.top) * scale);
    }
    if (!cloned.parentNode) {
        cloned.style.opacity = 1;
    }
    if (cloned.tagName.toLowerCase() === SVG) {
        d3.select(cloned).attr(WIDTH, bounds.width * scale);
        d3.select(cloned).attr(HEIGHT, bounds.height * scale);
    }
    return cloned;
}

function isScaleableProperty(value, style) {
    return value && !styleToSkip(style) && !value.match(/rgba?/) && value.match(/\d/) && !isUsefulStyle(style);
}

function styleToSkip(property) {
    return property.match(/transition|animation|webkit|flex|font-variant|tab-size|stop|object-|border-image|orphans|widows|zoom|shape|flood|background-position|transform/);
}

function isUsefulStyle(property) {
    return property.match(/font-(?!size)|color|text|fill|stroke|opacity|visibility|display|rotate/);
}

function convertPixelToFloat(float) {
    return parseFloat(float.replace('px', ''));
}

function setDimensionalAttributes(elem, options) {
    elem.setAttribute('width', options.width || 0);
    elem.setAttribute('height', options.height || 0);
    elem.setAttribute('x', options.x || 0);
    elem.setAttribute('y', options.y || 0);
}

export {
    setComputedStyles, convertPixelToFloat, prepareLogo, getTranslateValues, createSvgNode, createForeignObjectNode, createDocumentElement, fontHasChanged,
};
