(function(w, d, t, v) {
    w[v] = w[v] || {}; w[v].qr = []; w[v].qf = [];
    var got = 0,
        g = function() {
            if (got) {
                return;
            } got = 1;
            w[v].ft = setTimeout(function() {
                for (var i = 0; i < w[v].qf.length; i++) {
                    w[v].qf[i][0].call(w[v]);
                }
                w[v].f = 1; w[v].qf = [];
                w[v].fail = function(fn) {
                    fn.call(w[v]); return w[v];
                };
            }, 5000);
            var s = d.createElement(t);
            var protocol = document.location.protocol === 'https:' ? 'https:' : 'http:'; // Determine protocol to use
            var cb = (new Date().getTime() / 1e3).toFixed(); // Cache buster
            s.src = 'https://api.survata.net/latest/js/survata.js?cb=' + cb;
            var scr = d.getElementsByTagName(t)[0],
                par = scr.parentNode; par.insertBefore(s, scr);
        };
    w[v].ready = function() {
        g(); w[v].qr.push(arguments); return w[v];
    };
    w[v].fail = function() {
        g(); w[v].qf.push(arguments); return w[v];
    };
    w[v].publisher = '79060320-f80c-11e3-a3ac-0800200c9a66';
})(window, document, 'script', 'Survata');
