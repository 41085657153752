/* eslint compat/compat: "off" */
import textExportService from './text-export-service';
import imageExportService from './image-export-service';

const pngExport = {
    export: _export,
};

/**
 * @name export
 * @param {Object} params - The params containing download options
 *
 * @description
 *
 */
function _export(params) {
    let titleOffset = 0;
    const displayOptions = {
        yOffset: params.displayOptions.yOffset,
        elementMargin: params.displayOptions.elementMargin,
        exportMargin: params.displayOptions.exportMargin,
    };
    // Draw title
    textExportService.draw(params.canvas, params.title, displayOptions);
    // Draw subtitle
    if (params.subtitle) {
        textExportService.draw(params.canvas, params.subtitle, displayOptions);
    }
    // Draw chart descriotion
    if (params.chartDescription) {
        textExportService.draw(params.canvas, params.chartDescription, displayOptions);
    }
    titleOffset = displayOptions.yOffset;

    // Increment y offset
    if (params.chartImage) {
        displayOptions.yOffset += params.chartImage.height + params.displayOptions.elementMargin;
    }
    if (params.clickmap) {
        _.forEach(params.clickmap, clickmap => {
            displayOptions.yOffset += clickmap.height + params.displayOptions.elementMargin;
        });
    }
    if (params.chart) {
        displayOptions.yOffset += params.chart.height;
    }

    //Draw ranking, rating, or numeric
    if (params.footerText) {
        displayOptions.yOffset -= params.displayOptions.elementMargin;
        textExportService.draw(params.canvas, params.footerText, displayOptions);
    }
    else {
        displayOptions.yOffset += params.displayOptions.elementMargin;
    }
    params.displayOptions.yOffset = displayOptions.yOffset;

    return new Promise((resolve, reject) => {
        let deferred = [],
            x;
        displayOptions.yOffset = titleOffset;
        // Draw title image
        if (params.chartImage) {
            x = getMedian(params.canvas.width, params.chartImage.width);
            deferred.push(imageExportService.export(params.canvas, params.chartImage, x, displayOptions.yOffset));
            displayOptions.yOffset += params.chartImage.height + params.displayOptions.elementMargin;
        }
        // Draw clickmap
        if (params.clickmap) {
            _.forEach(params.clickmap, clickmap => {
                x = getMedian(params.canvas.width, clickmap.width);
                deferred.push(imageExportService.exportClickmap(params.canvas, clickmap, x, displayOptions.yOffset));
                displayOptions.yOffset += clickmap.height + params.displayOptions.elementMargin;
            });
        }
        // Draw chart
        if (params.chart) {
            deferred.push(drawChart(params, displayOptions));
            displayOptions.yOffset += params.chart.height;
        }

        Promise.all(deferred).then(() => {
            resolve();
        }, reject);
    });
}

function drawChart(params, displayOptions) {
    return new Promise((resolve, reject) => {
        const chartTop = displayOptions.yOffset,
            x = getMedian(params.canvas.width, params.chart.width);
        if (x + params.chart.width > params.canvas.width) {
            console.log(params);
        }
        if (displayOptions.yOffset > params.canvas.height) {
            console.log(params);
        }
        imageExportService.export(params.canvas, params.chart, x, displayOptions.yOffset).then(() => {
            params.chart.chartElem.remove();
            if (params.axisLabelImages) {
                Promise.all(_.map(params.axisLabelImages, image => {
                    return imageExportService.export(params.canvas, image, x + image.xOffset, chartTop + image.yOffset);
                })).then(resolve, resolve);
            }
            else {
                resolve();
            }
        }, reject);
    });
}

function getMedian(x, y) {
    return Math.max((x - y) / 2, 0);
}

export default pngExport;
