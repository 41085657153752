import deviceOptions from './options';

angular.module('device.summaryView', [])
    .directive('deviceSummary', deviceSummary)
    .controller('DeviceSummaryCtrl', DeviceSummaryCtrl);

function deviceSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/device-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'DeviceSummaryCtrl',
        controllerAs: 'DeviceSummaryVM',
    };
}
DeviceSummaryCtrl.$inject = ['$scope'];

function DeviceSummaryCtrl($scope) {
    var DeviceSummaryVM = this;
    DeviceSummaryVM.options = deviceOptions.list;
    DeviceSummaryVM.selected = selected;

    function selected(option) {
        return $scope.requirement && $scope.requirement.details && ($scope.requirement.details.indexOf(option.id) !== -1);
    }
}
