/* eslint func-names: ["error","never"] */
import _ from 'lodash';
import {
    Axes, Components, Dataset, Plots, Scales,
} from 'plottable/plottable';
import '../common-components/viewport-pub-sub';
import {
    PERCENT_BASED_FORMAT,
    COUNT_ONLY_FORMAT,
    COUNT_BASED_FORMAT,
} from './charts-formatter';
import {
    getChartData,
} from './chart-data-parser';
import './charts-utils';
import './chart-factory';
import './chart-tooltips';
import './errorbars';
import chartsConstant from './charts-constant';
import myChartUtils from './charts-utils';
import PlotLabels from './chart-plot-labels';

const Plottable = {
    Axes: Axes,
    Components: Components,
    Dataset: Dataset,
    Plots: Plots,
    Scales: Scales,
};

angular.module('charts.service', [
    'viewportPubSub',
    'chart',
    'chart.tooltips',
    'chart.errorbars',
])
    .service('myChartService', myChartService);
myChartService.$inject = [
    'ViewportPubSub',
    'Chart',
    'Errorbars',
    'Tooltips',
];
/**
 * @param ViewportPubSub
 * @param Chart
 * @param Errorbars
 * @param Tooltips
 */
function myChartService(ViewportPubSub, Chart, Errorbars, Tooltips) {
    const chartInView = new ViewportPubSub(ViewportPubSub.IN_NEXT_SCREEN);
    chartInView.setMaxUnresolved(4);
    // QUESTION: @samuelmburu how will this work with exisiting display horizontal logic in reports?
    /**
     * @param chartData
     * @param categoryAccessor
     */
    function displayChartHorizontaly(chartData, categoryAccessor) {
        // These numbers can change, they worked well enough with the
        // datasets they were tested against
        const LONG_LABEL_LENGTH = 14,
            MIN_LONG_LABELS_FOR_HORIZONTAL_DSIPLAY = 3;

        // If chart data is not an array, initialize it to an empty array
        if (!Array.isArray(chartData)) {
            chartData = [];
        }

        var longLabels = chartData.filter(datum => {
            const data = categoryAccessor(datum);
            return typeof data !== 'string' || data.trim().length >= LONG_LABEL_LENGTH;
        });
        return longLabels.length >= MIN_LONG_LABELS_FOR_HORIZONTAL_DSIPLAY;
    }

    /**
     * @param aChart
     */
    function destroyOldInteractions(aChart) {
        if (!aChart || !aChart.table()._isAnchored || !aChart.table()._isSetup) {
            return;
        }

        aChart.clearEventListenerTimers();
        aChart.destroy();
        aChart = undefined;
    }

    /**
     * @param oldChart
     */
    function destroyChart(oldChart) {
        if (!Array.isArray(oldChart)) {
            oldChart = [oldChart];
        }

        _.forEach(oldChart, (aChart, _idx) => {
            if (aChart.table() && aChart.table()._isAnchored && aChart.table()._isSetup) {
                destroyOldInteractions(aChart);
            }
        });

        oldChart = void 0;
    }

    /**
     * @param type
     */
    function getCategoricLabelText(type) {
        if (type === 'rating') {
            return 'Rating';
        }
        return '';
    }

    /**
     * @param chartData
     * @param options
     */
    function getComparisonChart(chartData, options) {
        var xAccessor = (d, _i, _ds) => {
                return d.x;
            },
            yAccessor = (d, _i, _ds) => {
                return d.cntProp * 100.0;
            },
            xScale = new Plottable.Scales.Category(),
            yScale = new Plottable.Scales.Linear(),
            xAxis = new Plottable.Axes.Category(xScale, 'bottom').formatter(myChartUtils.categoryFormatter).tickLabelAngle(-90),
            yAxis = new Plottable.Axes.Numeric(yScale, 'left'),
            xLabel = new Plottable.Components.AxisLabel('', '0'),
            yLabel = new Plottable.Components.AxisLabel(myChartUtils.getNumericLabelText('percent'), '270'),
            plot = new Plottable.Plots.ClusteredBar(),
            gridlines = new Plottable.Components.Gridlines(null, yScale),
            colorScale = new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY),
            legend = new Plottable.Components.Legend(colorScale).maxEntriesPerRow(Infinity),
            hasImages = myChartUtils.dataHasImages(chartData),
            group;

        // Add data to plot(s)
        _.forEach(chartData, (dataset, i) => {
            plot.addDataset(new Plottable.Dataset(dataset, {
                name: i,
                hasImages: hasImages,
            }));
        });

        // Configure plot(s)
        plot
            .x(xAccessor, xScale)
            .y(yAccessor, yScale)
            .animated(true)
            .attr('fill', (_d, _i, ds) => {
                return ds.metadata().name;
            }, colorScale);

        group = new Plottable.Components.Group([gridlines, plot]);

        var table = new Plottable.Components.Table([
            [null,
                yLabel,
                yAxis,
                group],
            [null,
                null,
                null,
                xAxis],
            [null,
                null,
                null,
                xLabel],
            [null,
                null,
                null,
                legend],
        ]);

        var plotlabels = new PlotLabels({
                plot: plot,
                group: group,
                formatter: PERCENT_BASED_FORMAT,
                data: plot.datasets(),
            }),
            tooltips = new Tooltips()
                .plot(plot)
                .group(group)
                .data(plot.datasets())
                .fillFn((d, _i, _ds) => {
                    return d.x;
                })
                .colorScale(new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY));

        var plugins = {
            plotlabels: plotlabels,
            tooltips: tooltips,
        };

        // For group 0 we have plugins
        return {
            table: table,
            plugins:
            {
                0: plugins,
            },
            horizontal: _.isBoolean(options.horizontal) ? options.horizontal : plot.datasets()[0].data().length > 10 || displayChartHorizontaly(_.values(chartData)[0], xAccessor),
        };
    }

    /**
     * @param chartData
     * @param options
     */
    function getRankingChart(chartData, options) {
        // Define scales, axisLabels, plot(s)
        var xScale = new Plottable.Scales.Category(),
            yScale = new Plottable.Scales.Linear(),
            xAxis = new Plottable.Axes.Category(xScale, 'bottom').formatter(myChartUtils.categoryFormatter).tickLabelAngle(-90),
            yAxis = new Plottable.Axes.Numeric(yScale, 'left'),
            xLabel = new Plottable.Components.AxisLabel('', '0'),
            yLabel = new Plottable.Components.AxisLabel(myChartUtils.getNumericLabelText('ranking'), '270'),
            plot = new Plottable.Plots.ClusteredBar(),
            gridlines = new Plottable.Components.Gridlines(null, yScale),
            colorScale = new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY),
            legend = new Plottable.Components.Legend(colorScale).maxEntriesPerRow(Infinity),
            hasImages = myChartUtils.dataHasImages(chartData),
            group;

        // Add data to plot(s)
        _.forEach(chartData, (dataset, i) => {
            plot.addDataset(new Plottable.Dataset(dataset, {
                name: i,
                hasImages: hasImages,
            }));
        });

        // Configure plot(s)
        plot
            .x((d, _i, _ds) => {
                return d.x;
            }, xScale)
            .animated(true)
            .y((d, _i, _ds) => {
                return d.y;
            }, yScale)
            .attr('fill', (_d, _i, ds) => {
                return ds.metadata().name;
            }, colorScale);

        group = new Plottable.Components.Group([gridlines, plot]);

        var table = new Plottable.Components.Table([
            [null,
                yLabel,
                yAxis,
                group],
            [null,
                null,
                null,
                xAxis],
            [null,
                null,
                null,
                xLabel],
            [null,
                null,
                null,
                legend],
        ]);

        var plotlabels = new PlotLabels({
                plot: plot,
                group: group,
                formatter: COUNT_ONLY_FORMAT,
                data: plot.datasets(),
            }),
            tooltips = new Tooltips()
                .plot(plot)
                .group(group)
                .data(plot.datasets())
                .fillFn((d, _i, _ds) => {
                    return d.x;
                })
                .colorScale(new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY));

        var plugins = {
            plotlabels: plotlabels,
            tooltips: tooltips,
        };

        // For group 0 we have plugins
        return {
            table: table,
            plugins: {
                0: plugins,
            },
            horizontal: _.isBoolean(options.horizontal) ? options.horizontal : true,
        };
    }

    /**
     * @param chartData
     * @param options
     */
    function getCustomVariableChart(chartData, options) {
        // Define scales, axisLabels, plot(s)
        var xAccessor = (d, _i, _ds) => {
                return d.x;
            },
            yAccessor = (d, _i, _ds) => {
                return d.y;
            },
            xScale = new Plottable.Scales.Category(),
            yScale = new Plottable.Scales.Linear(),
            xAxis = new Plottable.Axes.Category(xScale, 'bottom').formatter(myChartUtils.categoryFormatter).tickLabelAngle(-90),
            yAxis = new Plottable.Axes.Numeric(yScale, 'left'),
            xLabel = new Plottable.Components.AxisLabel(' ', '0'),
            yLabel = new Plottable.Components.AxisLabel(myChartUtils.getNumericLabelText('customVariable'), '270'),
            gridlines = new Plottable.Components.Gridlines(null, yScale),
            plot = new Plottable.Plots.Bar(),
            colorScale = new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY),
            group,
            table,
            plotlabels,
            plugins;

        // Add data to plot(s)
        plot.addDataset(new Plottable.Dataset(chartData, {
            name: 'foo',
            // Custom variable charts don't have images as axis labels
            hasImages: false,
        }));

        group = new Plottable.Components.Group([gridlines, plot]);

        // Configure plot(s)
        plot.x(xAccessor, xScale)
            .y(yAccessor, yScale)
            .animated(true)
            .attr('fill', (d, _i, _ds) => {
                return d.x;
            }, colorScale);

        // Return table
        table = new Plottable.Components.Table([
            [yLabel,
                yAxis,
                group],
            [null,
                null,
                xAxis],
            [null,
                null,
                xLabel],
            // [null,  null,   legend || null],
        ]);
        plotlabels = new PlotLabels({
            plot: plot,
            group: group,
            formatter: COUNT_BASED_FORMAT,
            data: plot.datasets(),
        });
        plugins = {
            plotlabels: plotlabels,
        };

        // For plot 0 we have plugins
        return {
            table: table,
            plugins:
            {
                0: plugins,
            },
            horizontal: _.isBoolean(options.horizontal) ? options.horizontal : chartData.length > 11 || displayChartHorizontaly(chartData, xAccessor),
        };
    }

    /**
     * @param chartData
     * @param type
     * @param options
     */
    function getRegularChart(chartData, type, options) {
        // Define scales, axisLabels, plot(s)
        var xAccessor = (d, _i, _ds) => {
                return d.x;
            },
            yAccessor = (d, _i, _ds) => {
                return d.y;
            },
            xScale = new Plottable.Scales.Category(),
            yScale = new Plottable.Scales.Linear(),
            xAxis = new Plottable.Axes.Category(xScale, 'bottom').formatter(myChartUtils.categoryFormatter).tickLabelAngle(-90),
            yAxis = new Plottable.Axes.Numeric(yScale, 'left'),
            xLabel = new Plottable.Components.AxisLabel(getCategoricLabelText(type), '0'),
            yLabel = new Plottable.Components.AxisLabel(myChartUtils.getNumericLabelText('count'), '270'),
            gridlines = new Plottable.Components.Gridlines(null, yScale),
            plot = new Plottable.Plots.Bar(),
            colorScale = new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY),
            // Legend,
            group;

        // Add data to plot(s)
        plot.addDataset(new Plottable.Dataset(chartData, {
            name: 'foo',
            hasImages: myChartUtils.dataHasImages(chartData),
        }));

        group = new Plottable.Components.Group([gridlines, plot]);

        // Configure plot(s)
        plot.x(xAccessor, xScale)
            .y(yAccessor, yScale)
            .animated(true)
            .attr('fill', (d, _i, _ds) => {
                return d.x;
            }, colorScale);

        // Return table
        var table = new Plottable.Components.Table([
                [yLabel,
                    yAxis,
                    group],
                [null,
                    null,
                    xAxis],
                [null,
                    null,
                    xLabel],
            // [null,  null,   legend || null],
            ]),
            plotlabels = new PlotLabels({
                plot: plot,
                group: group,
                formatter: COUNT_BASED_FORMAT,
                data: plot.datasets(),
            }),
            errorBars = new Errorbars()
                .plot(plot)
                .data(plot.datasets())
                .group(group),
            plugins = {
                plotlabels: plotlabels,
                errorbars: errorBars,
            };

        // For plot 0 we have plugins
        return {
            table: table,
            plugins:
            {
                0: plugins,
            },
            horizontal: _.isBoolean(options.horizontal) ? options.horizontal : chartData.length > 11 || displayChartHorizontaly(chartData, xAccessor),
        };
    }

    /**
     * @param chartData
     */
    function getTrackerChart(chartData) {
        // Define scales, axisLabels, plot(s)
        var xScale = new Plottable.Scales.Category(),
            yScale = new Plottable.Scales.Linear(),
            xAxis = new Plottable.Axes.Category(xScale, 'bottom').formatter(myChartUtils.categoryFormatter),
            yAxis = new Plottable.Axes.Numeric(yScale, 'left'),
            xLabel = new Plottable.Components.AxisLabel('Collection Periods', '0'),
            yLabel = new Plottable.Components.AxisLabel(myChartUtils.getNumericLabelText('percent'), '270'),
            linePlot = new Plottable.Plots.Line(),
            scatterPlot = new Plottable.Plots.Scatter(),
            colorScale = new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY),
            hasImages = myChartUtils.dataHasImages(chartData),
            xAccessor = (d, _i, _ds) => {
                return d.period.startDate.toLocaleDateString() + (d.period.endDate ? ' to ' + d.period.endDate.toLocaleDateString() : '');
            },
            yAccessor = (d, _i, _ds) => {
                return d.cntProp * 100.0;
            },
            fillAccessor = (_d, _i, ds) => {
                return ds.metadata().name;
            },
            gridlines = new Plottable.Components.Gridlines(null, yScale),
            legend = new Plottable.Components.Legend(colorScale).maxEntriesPerRow(Infinity),
            group,
            table,
            tooltips,
            plugins;

        // Add formatter to xAxis
        xAxis.formatter(myChartUtils.trackerTimeAxisDateFormatter);

        // Add data to plot(s)
        _.forEach(chartData, (dataset, _i, _fullObj) => {
            linePlot.addDataset(new Plottable.Dataset(dataset, {
                name: dataset[0].x,
                hasImages: hasImages,
            }));
            scatterPlot.addDataset(new Plottable.Dataset(dataset, {
                name: dataset[0].x,
                hasImages: hasImages,
            }));
        });

        // Configure plot(s)
        linePlot.x(xAccessor, xScale)
            .y(yAccessor, yScale)
            .animated(true)
            .attr('stroke', fillAccessor, colorScale);

        scatterPlot.x(xAccessor, xScale)
            .y(yAccessor, yScale)
            .animated(true)
            .attr('fill', fillAccessor, colorScale);

        group = new Plottable.Components.Group([gridlines,
            linePlot,
            scatterPlot]);

        // Add-ons
        // .enableChartTooltip(true)
        // .showChartTooltipTrackingLine(true)
        // .enableLegend(true)
        // .enablePanZoom(true)
        // .enablePlotLabels(false)
        // .enableErrorbars(false)

        // QUESTION:  what to do with
        // if (options.horizontal) {
        //     chartObj.setHorizontal();
        // }

        // return table
        table = new Plottable.Components.Table([[
            yLabel,
            yAxis,
            group,
        ],
        [
            null,
            null,
            xAxis],
        [
            null,
            null,
            xLabel,
        ],
        [
            null,
            null,
            legend,
        ]]);
        tooltips = new Tooltips()
            .plot(scatterPlot)
            .group(group)
            .fillFn((d, _i, _dataset) => {
                return d.x;
            })
            .colorScale(new Plottable.Scales.Color().range(chartsConstant.COLOR_ARRAY))
            .data(scatterPlot.datasets())
            .trackingLineEnabled(true);
        plugins = {
            tooltips: tooltips,
        };

        // For group 0 we have plugins
        return {
            table: table,
            plugins: {
                0: plugins,
            },
        };
    }

    /**
     * @param data
     * @param question
     * @param params
     * @param oldChart
     * @param options
     */
    function getChart(data, question, params, oldChart, options) {
        if (!params || _.isEmpty(params)) {
            console.error('params is required to get chart');
            return;
        }
        options = options || {};
        var questionType = question.type,
            chartData,
            chartObj;

        // Old chart needs to be destroyed when switching between Tracker/bar chart
        if (oldChart) {
            destroyChart(oldChart);
        }
        chartData = getChartData(data, question, params);
        if (params.isTracker) {
            if (questionType === 'ranking') {
                let rankingChartConfig = getRankingChart(chartData, options);
                chartObj = new Chart()
                    .plugins(rankingChartConfig.plugins)
                    .table(new Plottable.Components.Table([[
                        rankingChartConfig.table,
                    ]]));
            }
            else {
                let trackerConfig = getTrackerChart(chartData);
                chartObj = new Chart()
                    .plugins(trackerConfig.plugins)
                    .table(new Plottable.Components.Table([[
                        trackerConfig.table,
                    ]]))
                    .isTracker(true);
            }
        }
        else if (params.isComparison) {
            let comparisonConfig = getComparisonChart(chartData, options);

            chartObj = new Chart()
                .plugins(comparisonConfig.plugins)
                .table(new Plottable.Components.Table([[
                    comparisonConfig.table,
                ]]));

            if (comparisonConfig.horizontal) {
                chartObj.swapAxis();
            }
        }
        else if (params.isCustomVariableChart) {
            let customVariableChartConfig = getCustomVariableChart(chartData, options);
            chartObj = new Chart()
                .plugins(customVariableChartConfig.plugins)
                .table(new Plottable.Components.Table([[
                    customVariableChartConfig.table,
                ]]));
        }
        else if (questionType === 'ranking') {
            let rankingChartConfig = getRankingChart(chartData, options);

            chartObj = new Chart()
                .plugins(rankingChartConfig.plugins)
                .table(new Plottable.Components.Table([[
                    rankingChartConfig.table,
                ]]));

            if (rankingChartConfig.horizontal) {
                chartObj.swapAxis();
            }
        }
        else {
            let regChartConfig = getRegularChart(chartData, questionType, options);

            chartObj = new Chart()
                .plugins(regChartConfig.plugins)
                .table(new Plottable.Components.Table([[
                    regChartConfig.table,
                ]]));

            if (regChartConfig.horizontal) {
                chartObj.swapAxis();
            }
        }

        // If (questionType !== 'ranking' && _setHorizontal(chartData) && !params.isComparison) {
        // NOTE: Add this back in when the comparison label bug gets fixed
        // chartObj.setHorizontal()
        // .yAxis().tickLabelMaxWidth(220);
        // }
        chartObj.isTracker(params.isTracker && questionType !== 'ranking');

        chartObj.renderContainer(params.selector);

        return chartObj.buildChart();
    }
    return {
        getChart,
        destroyOldInteractions,
        chartInView,
    };
}
