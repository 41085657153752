import '../../../../common/js/angular-util';
import {
    parseDateForApi,
} from '../common-components/datepicker-service';
import adMeasurementConstants from './ad-measurement.constants';

angular.module('adMeasurement.apiService', [
    'angularUtil',
])
    .service('adMeasurementApiService', adMeasurementApiService);

adMeasurementApiService.$inject = [
    '$rootScope',
    '$q',
    'httpDeferred',
];

/**
 * API service for ad measurement
 *
 * @param {object} $rootScope
 * @param {object} $q
 * @param {object} httpDeferred
 *
 * @returns {object} functions
 */
function adMeasurementApiService($rootScope, $q, httpDeferred) {
    const MAX_IMPRESSION_PARAM_ITEMS_PER_UPLOAD = 50;

    /**
     * Prep audience configuration
     *
     * @param {object} audienceConfiguration
     *
     * @returns {object} audienceConfigurationObject
     */
    function prepAudienceConfiguration(audienceConfiguration) {
        let audienceConfigurationObj = {
            surveyUuid: $rootScope.survey.uuid,
            uuid: audienceConfiguration.uuid,
            name: audienceConfiguration.name,
            beaconType: audienceConfiguration.beaconType || audienceConfiguration.beaconDefinitions[0].beaconType,
            paused: audienceConfiguration.paused,
            beaconControlConfiguration: audienceConfiguration.beaconControlConfigurationUuid,
        };

        audienceConfigurationObj.beaconDefinitions = _.map(audienceConfiguration.beaconPoolBeacons || audienceConfiguration.beaconDefinitions, function(pixel) {
            return {
                beaconDefinition: pixel.beaconDefinition ? pixel.beaconDefinition.uuid : pixel.uuid,
                beaconInclusion: pixel.suppressed ? adMeasurementConstants.SUPPRESSED : adMeasurementConstants.ALLOWED,
                countForFrequency: pixel.countForFrequency,
            };
        });
        return audienceConfigurationObj;
    }

    // CRUD for audience configurations (composite beacon)
    /**
     * Update audience configuration
     *
     * @param {object} compositeBeacon
     * @param {string} audienceUuid
     *
     * @returns {Promise} promise
     */
    function updateAudienceConfiguration(compositeBeacon, audienceUuid) {
        if (compositeBeacon.uuid) {
            return httpDeferred.post(httpDeferred.dashboardHost + '/v1/composite-beacon/' + compositeBeacon.uuid, prepAudienceConfiguration(compositeBeacon));
        }
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/audience/' + audienceUuid + '/composite-beacon', prepAudienceConfiguration(compositeBeacon));
    }

    /**
     * List audience configurations
     *
     * @param {object} surveyAudience
     *
     * @returns {Promise} promise
     */
    function listAudienceConfigurations(surveyAudience) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/audience/' + surveyAudience.uuid + '/composite-beacon', {
            surveyUuid: $rootScope.survey.uuid,
        });
    }

    /**
     * Delete audience configuration by uuid
     *
     * @param {string} compositeBeaconUuid
     *
     * @returns {Promise} promise
     */
    function deleteAudienceConfigurationByUuid(compositeBeaconUuid) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/composite-beacon/' + compositeBeaconUuid + '?surveyUuid=' + $rootScope.survey.uuid);
    }

    /**
     * Create beacon control configuration
     *
     * @param {object} survey
     * @param {object} beaconControlConfiguration
     *
     * @returns {Promise} promise
     */
    function createBeaconControlConfiguration(survey, beaconControlConfiguration) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/beacon-control-configuration', beaconControlConfiguration);
    }

    /**
     * Update beacon control configuration
     *
     * @param {object} beaconControlConfiguration
     *
     * @returns {Promise} promise
     */
    function updateBeaconControlConfiguration(beaconControlConfiguration) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/beacon-control-configuration/' + beaconControlConfiguration.uuid, beaconControlConfiguration);
    }

    /**
     * List beacon control configurations
     *
     * @param {object} survey
     *
     * @returns {Promise} promise
     */
    function listBeaconControlConfigurations(survey) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/beacon-control-configuration');
    }

    /**
     * Delete beacon control configuration
     *
     * @param {string} beaconControlConfigurationUuid
     *
     * @returns {Promise} promise
     */
    function deleteBeaconControlConfigurationByUuid(beaconControlConfigurationUuid) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/beacon-control-configuration/' + beaconControlConfigurationUuid);
    }

    /**
     * Save impression parameter values
     *
     * @param {string} surveyUuid
     * @param {object[]} impressionParams
     *
     * @returns {Promise} promise
     */
    function saveImpressionParameterValuesBySurveyUuid(surveyUuid, impressionParams) {
        const deferred = $q.defer(),
            chunks = _.chunk(impressionParams || [], MAX_IMPRESSION_PARAM_ITEMS_PER_UPLOAD),
            numChunks = chunks.length,
            returnObj = {
                loadedValues: [],
                warnings: [],
                duplicateValues: [],
            };

        /**
         * Upload chunk
         *
         * @param {object[]} numChunk
         */
        function uploadChunk(numChunk) {
            if (numChunk === numChunks) {
                // We reached the end. Resolve now.
                deferred.resolve(returnObj);
                return;
            }
            if (numChunk === numChunks - 1) {
                // This is the last chunk to upload. Find the last item in this chunk and add isFinal param to it.
                const lastItem = _.last(chunks[numChunk]);
                lastItem.isFinal = true;
            }
            httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/impression-parameter-value-group/bulk-load', chunks[numChunk])
                .then(function(response) {
                    returnObj.loadedValues = returnObj.loadedValues.concat(_.map(response.loadedValues, 'impressionParameterValue'));
                    returnObj.duplicateValues = returnObj.duplicateValues.concat(_.map(response.duplicateValues, 'impressionParameterValue'));
                    returnObj.warnings = returnObj.warnings.concat(_.map(response.warnings, item => {
                        return {
                            warningMessage: item,
                        };
                    }));
                    let numUploaded = MAX_IMPRESSION_PARAM_ITEMS_PER_UPLOAD * (numChunk + 1);
                    deferred.notify('Uploaded ' + numUploaded + ' of ' + impressionParams.length + ' rows');
                    uploadChunk(numChunk + 1);
                }, function() {
                    deferred.reject('Server error on chunk: ' + numChunk);
                });
        }
        uploadChunk(0);

        return deferred.promise;
    }

    /**
     * Get impression group by impression value
     *
     * @param {string} surveyUuid
     * @param {string} impressionValue
     *
     * @returns {Promise} promise
     */
    function getImpressionGroupByImpressionValue(surveyUuid, impressionValue) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/impression-parameter-group?impressionValue=' + encodeURIComponent(impressionValue));
    }

    /**
     * List impression parameter groups
     *
     * @param {string} surveyUuid
     *
     * @returns {Promise} promise
     */
    function listImpressionParameterGroups(surveyUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/impression-parameter-group');
    }

    /**
     * List impression parameter value group
     *
     * @param {string} uuid
     *
     * @returns {Promise} promise
     */
    function listImpressionParameterValueGroup(uuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/impression-parameter-group/' + uuid + '/impression-parameter-value-group');
    }

    /**
     * List impression parameter values
     *
     * @param {string} surveyUuid
     *
     * @returns {Promise} promise
     */
    function listImpressionParameterValues(surveyUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/impression-parameter-value');
    }

    /**
     * Get impression day summary
     *
     * @param {string} uuid
     * @param {object} dateRange
     *
     * @returns {Promise} promise
     */
    function getImpressionDaySummary(uuid, dateRange) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + uuid + '/beacon-day-impression-summary?startDate=' + parseDateForApi(dateRange.startDate) + '&endDate=' + parseDateForApi(dateRange.endDate));
    }

    /**
     * Get impression group count
     *
     * @param {string} uuid
     * @param {object} dateRange
     *
     * @returns {Promise} promise
     */
    function getImpressionGroupCount(uuid, dateRange) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/impression-parameter-group/' + uuid + '/count?startDate=' + parseDateForApi(dateRange.startDate) + '&endDate=' + parseDateForApi(dateRange.endDate));
    }

    /**
     * Get impression impression count
     *
     * @param {string} uuid
     * @param {object} dateRange
     *
     * @returns {Promise} promise
     */
    function getBeaconImpressionCount(uuid, dateRange) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid + '/count?startDate=' + parseDateForApi(dateRange.startDate) + '&endDate=' + parseDateForApi(dateRange.endDate));
    }

    /**
     * Get tags for survey
     *
     * @param {object} survey
     *
     * @returns {Promise} promise
     */
    function getTagsForSurvey(survey) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/audience/' + survey._audienceId + '/composite-beacon').then(response => {
            const tagList = [];
            _.forEach(response, beaconPool => {
                if (beaconPool.beaconPoolBeacons.length) {
                    tagList.push(beaconPool.beaconPoolBeacons[0].beaconDefinition);
                }
            });
            return tagList;
        });
    }

    /**
     * Given an object of parameters, creates a campaign
     *
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function createCampaign(params) {
        return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/survey/${params.surveyUuid}/campaign`, params);
    }

    /**
     * Given an object of parameters, lists campaigns
     *
     * @param {object} params
     * @param {string} params.userId
     *
     * @returns {Promise} promise
     */
    function listCampaigns(params) {
        if (params && params.userId) {
            // Endpoint accepts a user id to list campaigns only for that user
            return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/campaign?userId=${params.userId}`);
        }
        return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/campaign`);
    }

    /**
     * Given an object of parameters, shows a single campaign
     *
     * @param {*} params
     * @param {string} params.campaignUuid
     *
     * @returns {Promise} promise
     */
    function showCampaign(params) {
        return httpDeferred.get(`${httpDeferred.dashboardHost}/v1/campaign/${params.campaignUuid}`);
    }

    /**
     * Given an object of parameters, updates a campaign
     *
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function updateCampaign(params) {
        return httpDeferred.post(`${httpDeferred.dashboardHost}/v1/campaign/${params.uuid}`, params);
    }

    /**
     * Given an object of parameters, creates or updates a campaign
     *
     * @param {object} params
     * @param {string} params.uuid - required - the campaign uuid
     *
     * @returns {Promise} promise
     */
    function saveCampaign(params) {
        if (params.uuid) {
            return updateCampaign(params);
        }

        return createCampaign(params);
    }

    /**
     * Given a user and a survey, returns the survey's associated campaign
     *
     * @param {object} user - A user object
     * @param {object} survey - a survey object
     *
     * @returns {Promise} promise
     */
    function getCampaignForSurvey(user, survey) {
        const deferred = $q.defer();
        const params = {
            userId: user.id,
        };

        listCampaigns(params).then(response => {
            if (response) {
                const campaign = response.find(campaign => {
                    return campaign.surveyUuid === survey.uuid;
                });

                deferred.resolve(campaign);
            }
            else {
                deferred.reject('There was an error while retrieving the list of campaigns: ' + response.msg);
            }
        });
        return deferred.promise;
    }

    return {
        // Audience configuration CRUD
        updateAudienceConfiguration: updateAudienceConfiguration,
        listAudienceConfigurations: listAudienceConfigurations,
        deleteAudienceConfigurationByUuid: deleteAudienceConfigurationByUuid,

        // Beacon control configuration CRUD
        createBeaconControlConfiguration: createBeaconControlConfiguration,
        updateBeaconControlConfiguration: updateBeaconControlConfiguration,
        listBeaconControlConfigurations: listBeaconControlConfigurations,
        deleteBeaconControlConfigurationByUuid: deleteBeaconControlConfigurationByUuid, // Unused function

        // Campaign CRUD
        createCampaign: createCampaign,
        listCampaigns: listCampaigns,
        showCampaign: showCampaign,
        updateCampaign: updateCampaign,

        // Other stuff...
        saveCampaign: saveCampaign,
        getCampaignForSurvey: getCampaignForSurvey,
        saveImpressionParameterValuesBySurveyUuid: saveImpressionParameterValuesBySurveyUuid,
        listImpressionParameterValues: listImpressionParameterValues,
        listImpressionParameterGroups: listImpressionParameterGroups,
        listImpressionParameterValueGroup: listImpressionParameterValueGroup,
        getImpressionDaySummary: getImpressionDaySummary,
        getImpressionGroupByImpressionValue: getImpressionGroupByImpressionValue,
        getBeaconImpressionCount: getBeaconImpressionCount,
        getImpressionGroupCount: getImpressionGroupCount,
        getTagsForSurvey: getTagsForSurvey,
    };
}
