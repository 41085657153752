import '@uirouter/angularjs';
import exportConstants from '../export/export-constants';
import exportFactory from '../export/export-factory';
import '../../../../common/js/inc/sv-notify';
import '../common-components/pub-sub';

angular.module('chartsWidget.gridCharts', [
    'svNotify', 'pubSub',
])
    .controller('GridChartCtrl', GridChartCtrl)
    .directive('gridChartWidget', gridChartWidget);

gridChartWidget.$inject = [
    '$notify', '$state',
];

/**
 * @param $notify
 * @param $state
 */
function gridChartWidget($notify, $state) {
    var CUSTOM_REPORT = 'customReports';
    return {
        restrict: 'E',
        scope: {
            question: '=',
            chartNum: '@',
            chartDisplayNum: '@',
            visualMetadata: '=?',
            params: '=',
        },
        templateUrl: function() {
            return $state.current.name === CUSTOM_REPORT ? '/dashboard-templates/custom-reports/grid-chart-widget.html' : '/dashboard-templates/results/grid-chart-widget.html';
        },
        controller: 'GridChartCtrl',
        controllerAs: 'GridChartVM',
        link: function(scope, elem, _attr, GridChartVM) {
            var EXPORT_ERROR_MSG_BROWSER = 'At this time chart exports are not supported on this browser. Please try another browser or contact Survata for further assistance.';
            GridChartVM.fullExportPng = exportAs('png');
            GridChartVM.fullExportSvg = exportAs('svg');

            /**
             * @param type
             */
            function exportAs(type) {
                return function() {
                    let scale = Math.max(exportConstants.chartDimensions.width / elem[0].clientWidth, exportConstants.chartDimensions.height / elem[0].clientHeight);
                    exportFactory.exportGrid(type, {
                        elem: elem[0],
                        scale: scale,
                    }).then(function(response) {
                        if (type === 'png') {
                            exportFactory.appendLogo(response, scale, true).then(() => {
                                exportFactory.download(type, response, scope.question.details.statement.text);
                            });
                        }
                        else {
                            exportFactory.download(type, response, scope.question.details.statement.text);
                        }
                    }, function(errorMsg) {
                        console.error('export error: ' + errorMsg);
                        $notify.error(EXPORT_ERROR_MSG_BROWSER);
                    });
                };
            }
        },
    };
}

GridChartCtrl.$inject = ['$rootScope',
    '$scope',
    'pubSubService'];

/**
 * @param $rootScope
 * @param $scope
 * @param pubSubService
 */
function GridChartCtrl($rootScope, $scope, pubSubService) {
    var GridChartVM = this;
    GridChartVM.title = $scope.question && $scope.question.details.statement.text.replace(/^\d+\.\s*/, '');
    GridChartVM.questionDetails = $scope.question.details;
    GridChartVM.chartNum = $scope.chartNum;
    GridChartVM.chartDisplayNum = $scope.chartDisplayNum;
    GridChartVM.visualMetadata = $scope.visualMetadata || {
        lines: [],
    };
    GridChartVM.visualMetadata.lines = GridChartVM.visualMetadata.lines || [];
    GridChartVM.ux = {};
    GridChartVM.orderings = _.range(0, $scope.question.details.rowStatements.length);
    GridChartVM.toggleFullWidthDisplay = toggleFullWidthDisplay;

    init();

    function init() {
        const renderGridTopic = 'render-chart-' + GridChartVM.chartNum + '-0';
        GridChartVM.rowQuestions = _.map($scope.question.details.rowStatements, (row, idx) => {
            const rowQuestion = Object.assign({}, $scope.question);
            rowQuestion.details = Object.assign({}, $scope.question.details);
            rowQuestion.details.statement = row;
            rowQuestion.details.choices = rowQuestion.details.choices[idx];
            rowQuestion.details.type = rowQuestion.details.gridType;
            delete rowQuestion.rowStatements;
            return rowQuestion;
        });
        pubSubService.subscribe(renderGridTopic, $scope.$id, onLoadGrid);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(renderGridTopic, $scope.$id);
        });
    }

    /**
     * @param gridDataPromise
     * @param params
     */
    function onLoadGrid(gridDataPromise, params) {
        if (!gridDataPromise) {

        }
    }

    /**
     * @name toggleFullWidthDisplay
     * @param {Event} $event - The click event
     *
     * @description
     * Save the full width status on each line.
     */
    function toggleFullWidthDisplay($event) {
        $event.preventDefault(); // Stops browser from jumping to top
        $event.stopPropagation(); // Keeps dropdown from closing on click
        GridChartVM.visualMetadata.fullWidth = !GridChartVM.visualMetadata.fullWidth;
        pubSubService.notify('toggle-full-width-' + GridChartVM.chartNum);
        _.forEach($scope.question.details.rowStatements, (_, idx) => {
            GridChartVM.visualMetadata.lines[idx] = GridChartVM.visualMetadata.lines[idx] || {};
            GridChartVM.visualMetadata.lines[idx].fullWidth = GridChartVM.visualMetadata.fullWidth;
        });
    }
}
