import '../../../../common/js/angular-util';
import U from '../../../../common/js/util';

export default angular.module('retargetingService', ['angularUtil'])
    .service('retargetingService', [
        '$q',
        'httpDeferred',
        function($q, httpDeferred) {
            /**
             * Gets the list of RetargetingAccounts
             *
             * @returns {*}
             */
            function getRetargetingAccounts() {
                const deferred = $q.defer();
                httpDeferred.get(`${httpDeferred.dashboardHost}/rest/retargeting-account/list`)
                    .then(function(response) {
                        if (response.error) {
                            deferred.reject('Error getting retargeting accounts: ' + response.msg);
                        }
                        else {
                            deferred.resolve(response);
                        }
                    }, function() {
                        deferred.reject('Failed to retrieve retargeting accounts!');
                    });
                return deferred.promise;
            }

            /**
             * Updates a Survey's RetargetingAccount
             *
             * @param {object} retargetingAccount
             * @param {string} surveyUuid
             * @returns {*}
             */
            function updateSurveysRetargetingId(retargetingAccount, surveyUuid) {
                const params = {
                    uuid: retargetingAccount.uuid,
                    parentResource: 'survey',
                    puuid: surveyUuid,
                };

                const deferred = $q.defer();
                httpDeferred.post(`${httpDeferred.dashboardHost}/rest/retargeting-account/update${U.buildParamString(params)}`)
                    .then(function(response) {
                        if (response.uuid === retargetingAccount.uuid) {
                            deferred.resolve(response);
                        }
                        else {
                            deferred.reject('There was an error updating this survey: ' + response.msg);
                        }
                    });
                return deferred.promise;
            }

            return {
                getRetargetingAccounts: getRetargetingAccounts,
                updateSurveysRetargetingId: updateSurveysRetargetingId,
            };
        }]);
