export const tagCreationMacros = new Map([
    ['ABC', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['ADCOLONY', new Map([
        ['adId', '[RAW_AD_CREATIVE_ID]'],
        ['campaignId', '[RAW_AD_CAMPAIGN_ID]'],
        ['creativeId', '[RAW_AD_CREATIVE_ID]'],
        ['placementId', '[RAW_AD_GROUP_ID]'],
        ['siteId', '[APP_ID]'],
        ['deviceId', '&did=[DEVICE_ID]'],
    ])],
    ['ADELPHIC', new Map([
        ['adId', 'noValue'],
        ['campaignId', '${ADELPHIC_CAMPAIGNID}'],
        ['creativeId', '${ADELPHIC_CREATIVEID}'],
        ['placementId', '${ADELPHIC_SITEID}'],
        ['siteId', '${ADELPHIC_SITEID}'],
        ['deviceId', '&did=${ADELPHIC_DID_ANYTYPE}'],
    ])],
    ['ADSWIZZ', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'noValue'],
        ['creativeId', '{bannerid}'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did={listenerid}'],
    ])],
    ['ADTHEORENT', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'noValue'],
        ['creativeId', '[CREATIVEID]'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=[IDFA]'],
    ])],
    ['AFAR', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%eexcid!'],
        ['creativeId', '%erid!'],
        ['placementId', '%eexpid!'],
        ['siteId', '%esid!'],
    ])],
    ['AKI', new Map([
        ['adId', '{MOMENT_IDS}'],
        ['campaignId', '{CAMP_ID}'],
        ['creativeId', '{CRT_ID}'],
        ['placementId', 'noValue'],
        ['siteId', '{BUNDLE_ID}'],
        ['deviceId', '&did={UCDID_ID}'],
    ])],
    ['AMAZON', new Map([
        ['adId', '{%ad_cfid}'],
        ['campaignId', '{%campaign_cfid}'],
        ['creativeId', '{%creative_cfid}'],
        ['placementId', 'noValue'],
        ['siteId', '{%advertiser_cfid}'],
        ['deviceId', '&did=[[AAX_IDFA]]'],
    ])],
    ['AUDIOLOGY', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'noValue'],
        ['creativeId', '{bannerid}'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did={listenerid}'],
    ])],
    ['BARKLEY', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
    ])],
    ['BEESWAX', new Map([
        ['adId', '{{LINE_ITEM_ID}}'],
        ['campaignId', '{{CAMPAIGN_ID}}'],
        ['creativeId', '{{CREATIVE_ID}}'],
        ['placementId', '{{PLACEMENT_ID}}'],
        ['siteId', '{{SITE_ID}}'],
        ['deviceId', '&did={{USER_ID_SHA1}}'],
    ])],
    ['BIDTELLECT', new Map([
        ['adId', '{advdeviceid}'],
        ['campaignId', '{campaignid}'],
        ['creativeId', '{creativeid}'],
        ['placementId', '{placementid}'],
        ['siteId', '{siteid}'],
        ['deviceId', '&did={advdeviceid}'],
    ])],
    ['BRIGHTROLL', new Map([
        ['adId', '${TRAFFICKEDADID}'],
        ['campaignId', '${ORDERID}'],
        ['creativeId', '${LIBRARYADID}'],
        ['placementId', 'NoValue'],
        ['siteId', '${PUBLISHERID}'],
        ['deviceId', '&did=${DEVICE_ID}'],
    ])],
    ['CBS', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['CELTRA', new Map([
        ['adId', 'noValue'],
        ['campaignId', '{celtraCampaignId}'],
        ['creativeId', '{celtraCreativeId}'],
        ['placementId', '{celtraPlacementId}'],
        ['siteId', '{celtraExternalSiteId}'],
        ['deviceId', '&did=${celtraPlatformAdvId}'],
    ])],
    ['CNET', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
    ])],
    ['CNN', new Map([
        ['adId', '#{ad.id}'],
        ['campaignId', '#{ad.campaign.id}'],
        ['creativeId', '#{ad.creative.id}'],
        ['placementId', '#{ad.placement.id}'],
        ['siteId', '#{site.id}'],
        ['deviceId', '&did=#{request.deviceId}'],
    ])],
    ['DCM', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
        ['deviceId', '&did=%pdid=!'],
    ])],
    ['DFP', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['DOT_DASH', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['EDITORIALIST', new Map([
        ['adId', '%epid!'],
        ['campaignId', '%eaid!'],
        ['creativeId', '%ecid!'],
        ['placementId', 'noValue'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=noValue'],
    ])],
    ['ESSENCE', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
        ['deviceId', '&did=%pdid!;'],
    ])],
    ['FLASHTALKING', new Map([
        ['adId', '[%FT_CONFID%]'],
        ['campaignId', '[%campaignID%]'],
        ['creativeId', '[%creativeID%]'],
        ['placementId', '[%placementID%]'],
        ['siteId', '[%siteID%]'],
        ['deviceId', '&did=[%IDFA%]'],
    ])],
    ['FW', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'noValue'],
        ['creativeId', '#{ad.creative.id}'],
        ['placementId', '#{ad.placement.id}'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=#{request.deviceId}'],
    ])],
    ['GOOP', new Map([
        ['adId', '%eadv!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%eaid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['GUMGUM', new Map([
        ['adId', '[adBuyId]'],
        ['campaignId', '[campaignId]'],
        ['creativeId', '[creativeId]'],
        ['placementId', '[packageId]'],
        ['siteId', '[trackingId]'],
        ['deviceId', ''],
    ])],
    ['HULU', new Map([
        ['adId', '${flight_id}'],
        ['campaignId', '${campaign_id}'],
        ['creativeId', '${placement_id}'],
        ['placementId', '${adunit_id}'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=${unique_id}'],
    ])],
    ['IHEART', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'campaignid'],
        ['creativeId', '{bannerid}'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did={listenerid}'],
    ])],
    ['INNOVID', new Map([
        ['adId', '{iv_adid}'],
        ['campaignId', '{iv_campaignid}'],
        ['creativeId', '{iv_creativeid}'],
        ['placementId', '{iv_placementid}'],
        ['siteId', '{iv_publisherid}'],
        ['deviceId', '&did={ivc_deviceid_raw}'],
    ])],
    ['KARGO', new Map([
        ['campaignId', '%%TTD_CAMPAIGNID%%'],
        ['creativeId', '%%TTD_CREATIVEID%%'],
        ['siteId', '%%TTD_SITE%%'],
        ['deviceId', '&did=%%TTD_DEVICEID%%'],
    ])],
    ['KING', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['MATCH_MEDIA', new Map([
        ['adId', '%%ADVERTISING_IDENTIFIER_PLAIN%%'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%eaid!'],
        ['siteId', '%%ADUNIT%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['MEDIAMATH', new Map([
        ['adId', 'noValue'],
        ['campaignId', '[AD_ATTR.campaign]'],
        ['creativeId', '[AD_ATTR.creative]'],
        ['placementId', '[AD_ATTR.strategy]'],
        ['siteId', '[BID_ATTR.site_id]'],
        ['deviceId', '&did=[MOBILE_DEVICE_ID]'],
    ])],
    ['MEGAPHONE', new Map([
        ['adId', '%%ip%%'],
        ['campaignId', 'noValue'],
        ['creativeId', 'noValue'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=noValue'],
    ])],
    ['MEREDITH', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['NATIVO', new Map([
        ['adId', '[ad_id]'],
        ['campaignId', '[adcampaign_id]'],
        ['creativeId', '[content_id]'],
        ['placementId', '[placement_id]'],
        ['siteId', '[publication_id]'],
    ])],
    ['NFL', new Map([
        ['adId', '%epid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%eaid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['OATH', new Map([
        ['adId', '{adid}'],
        ['campaignId', '{campaignid}'],
        ['creativeId', '{creative}'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=${MI}'],
    ])],
    ['OGURY', new Map([
        ['adId', 'NONE'],
        ['campaignId', 'NONE'],
        ['creativeId', 'NONE'],
        ['placementId', 'NONE'],
        ['siteId', 'NONE'],
        ['deviceId', '&did=[user_advertising_id]'],
    ])],
    ['OMD', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
        ['deviceId', '&did=%pdid!;'],
    ])],
    ['PANDORA', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['R1', new Map([
        ['adId', 'noValue'],
        ['campaignId', '$!{PACKAGE_ID}'],
        ['creativeId', '$!{CREATIVE_ID}'],
        ['placementId', '$!{LINE_ITEM_ID}'],
        ['siteId', '$!{SITE_URL}'],
        ['deviceId', '&did=$!{DEVICE_SHA1}'],
    ])],
    ['REDDIT', new Map([
        ['adId', 'noValue'],
        ['campaignId', '{{CAMPAIGN_ID}}'],
        ['creativeId', '{{AD_ID}}'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did={blank}'],
    ])],
    ['REFINERY_29', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
        ['deviceId', '&did=%pdid!;'],
    ])],
    ['SHARETHROUGH', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'noValue'],
        ['creativeId', 'noValue'],
        ['placementId', 'noValue'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=[campaign_identifier=X]'],
    ])],
    ['SIZMEK', new Map([
        ['adId', 'noValue'],
        ['campaignId', '[%tp_CampaignID%]'],
        ['creativeId', '[%tp_AdID%]'],
        ['placementId', '[%tp_PlacementID%]'],
        ['siteId', '[%tp_SiteID%]'],
        ['deviceId', '&did=[%tp_DeviceAdvID%]'],
    ])],
    ['SPOTIFY', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%%SITE%%'],
        ['deviceId', '&did=%%ADVERTISING_IDENTIFIER_PLAIN%%'],
    ])],
    ['TEADS', new Map([
        ['adId', '[LID]'],
        ['campaignId', '[CAMPAIGN ID]'],
        ['creativeId', '[CID]'],
        ['placementId', '[PID]'],
        ['siteId', '[DOMAIN]'],
        ['deviceId', '[DEVICEID]'],
    ])],
    ['THETRADEDESK', new Map([
        ['adId', '%%TTD_ADGROUPID%%'],
        ['campaignId', '%%TTD_CAMPAIGNID%%'],
        ['creativeId', '%%TTD_CREATIVEID%%'],
        ['placementId', 'noValue'],
        ['siteId', '%%TTD_SITE%%'],
        ['deviceId', '&did=%%TTD_DEVICEID%%'],
    ])],
    ['TINDER', new Map([
        ['adId', 'noValue'],
        ['campaignId', 'noValue'],
        ['creativeId', '[LR_RUNTIME_CREATIVE_ID]'],
        ['placementId', '[LR_RUNTIME_ORDER_LINE_ID]'],
        ['siteId', 'noValue'],
        ['deviceId', '&did={blank}'],
    ])],
    ['TREMOR', new Map([
        ['adId', '[ADVERTISER_ID]'],
        ['campaignId', '[CAMPAIGN_ID]'],
        ['creativeId', '[CREATIVE_ID]'],
        ['placementId', '[PLACEMENT_ID]'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=###MOBILE_DEVICE_ID###'],
    ])],
    ['TRIPLE_LIFT', new Map([
        ['campaignId', '%%TTD_CAMPAIGNID%%'],
        ['creativeId', '%%TTD_CREATIVEID%%'],
        ['siteId', '%%TTD_SITE%%'],
        ['deviceId', '&did=%%TTD_DEVICEID%%'],
    ])],
    ['TRUCKER_PATH', new Map([
        ['adId', '{advertId}'],
        ['campaignId', '{advertId}'],
        ['creativeId', '{advertId}'],
        ['placementId', '{placementid}'],
        ['siteId', 'TruckerPath'],
        ['deviceId', '&did={deviceid}'],
    ])],
    ['TUBE_MOGUL', new Map([
        ['adId', 'noValue'],
        ['campaignId', '${TM_CAMPAIGN_ID_NUM}'],
        ['creativeId', '${TM_AD_ID_NUM}'],
        ['placementId', '${TM_PLACEMENT_ID_NUM}'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=${USER_ID}'],
    ])],
    ['UNDERTONE', new Map([
        ['adId', '{iolineitemid}'],
        ['campaignId', '{campaignid}'],
        ['creativeId', '{bannerid}'],
        ['placementId', 'noValue'],
        ['siteId', '{zoneid}'],
        ['deviceId', '&did={mobile_device_id}'],
    ])],
    ['URBAN_ONE', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
        ['deviceId', '&did=%pdid!;'],
    ])],
    ['URBAN_ONE', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
        ['deviceId', '&did=%pdid!;'],
    ])],
    ['VERIZON_DSP', new Map([
        ['adId', '${TRAFFICKEDADID}'],
        ['campaignId', '{ORDER_ID}'],
        ['creativeId', '{CREATIVE_ID}'],
        ['placementId', '${PLACEMENTID}'],
        ['siteId', 'noValue'],
        ['deviceId', '&did=${DEVICE_ID}'],
    ])],
    ['VERIZON_ONE', new Map([
        ['adId', '_ADADID_'],
        ['campaignId', 'ADMCID'],
        ['creativeId', '_ADBNUID_'],
        ['placementId', '_ADCUID_'],
        ['siteId', '_ADWEBSITEID_'],
        ['deviceId', '&did=ADDCP(guid)'],
    ])],
    ['VM1', new Map([
        ['adId', '%eaid!'],
        ['campaignId', '%ebuy!'],
        ['creativeId', '%ecid!'],
        ['placementId', '%epid!'],
        ['siteId', '%esid!'],
    ])],
    ['VMG_GEMINI_NATIVE', new Map([
        ['adId', '{adid}'],
        ['campaignId', '{campaignid}'],
        ['creativeId', '{creative}'],
        ['placementId', '{adgroupid}'],
        ['siteId', '${SUPPLY_NAME}'],
        ['deviceId', '&did=${MI}'],
    ])],
    ['XANDR', new Map([
        ['adId', '${CPG_ID}'],
        ['campaignId', '${CP_ID}'],
        ['creativeId', '${CREATIVE_ID}'],
        ['placementId', '${TAG_ID}'],
        ['siteId', '${SITE_ID}'],
        ['deviceId', '&did=${DEVICE_AAID}${DEVICE_APPLE_IDA}'],
    ])],
]);
