/* eslint compat/compat: "off" */
import _ from 'lodash';
import U from '../../../../../common/js/util';
import analysisConfigConstants from './analysis-config-constants';
import filterLogicConstants from '../../filter-logic/filter-logic-constants';
import analysisConfigDataService from './analysis-config-data-service';

angular.module('analysisFactorService', [])
    .service('analysisFactorService', analysisFactorService);

analysisFactorService.$inject = [];

/**
 * Analysis factor service.
 *
 * @returns {object} exposed functions for the service
 */
function analysisFactorService() {
    const DEFAULT_INDEX = 0,
        MAX_QUESTION_NAME_LENGTH = 80,
        KPI_FACTOR_TYPE = analysisConfigConstants.questionFactorTypes[DEFAULT_INDEX].value,
        DEFAULT_QUESTION_FACTOR_TYPE_INDEX = 1,
        DEMOGRAPHIC_CONFOUNDERS = /^(Gender|Region|Age|Operating System|Temporal|Urbanicity \(based on Postal Code\)|Median Income Range of Postal Code)$/;

    /**
     * Get default mapping of question factor to choice level.
     *
     * @param {Array} questions - Array of questions
     * @returns {object} Mapping of question name to factor
     */
    function getDefaultQuestionFactorToChoiceLevel(questions) {
        const analysisFactorTypesNameMap = {},
            map = {};

        analysisConfigConstants.analysisFactorTypes.forEach(type => {
            if (type.type) {
                analysisFactorTypesNameMap[type.type] = type;
            }
        });

        _.forEach(questions, (question, idx) => {
            if (question.disabled) {
                map[question.name] = {
                    disabled: true,
                    name: question.name,
                    displayName: question.displayName,
                    displayNameFull: question.displayNameFull,
                    levels: question.options,
                    idx: idx,
                    label: question.analysisPlanLabel,
                };
            }

            else {
                const levels = {};

                if (question.questionType !== 'longFreeResponse') {
                    question.options.forEach(choice => {
                        levels[choice.name] = choice;
                    });
                }

                map[question.name] = {
                    levels: levels,
                    idx: idx,
                    statsId: question.id,
                    id: question.analysisPlanId,
                    type: question.type,
                    label: question.analysisPlanLabel + (question.gridStatement ? '.' + question.rowIndex : ''),
                    factorType: analysisFactorTypesNameMap[question.gridType ? question.gridType + 'Select' : question.type],
                    name: question.name,
                    displayName: question.displayName,
                    displayNameFull: question.displayNameFull,
                    isGrid: !!question.gridStatement,
                };
            }
        });

        return map;
    }

    /**
     * Get empty factor level.
     *
     * @param {string } name - Name for the factor level
     * @param {number} idx - Choice index
     * @returns {object} Factor level object
     */
    function getEmptyFactorLevel(name, idx) {
        return {
            isComplement: false,
            isKpi: false,
            isMarketResearch: false,
            isSynthetic: false,
            levelType: analysisConfigConstants.factorLevelTypes[DEFAULT_INDEX],
            name: name,
            _displayName: U.limitStrLength(name.replace(/^\d+\.(\d+\.)? /, ''), MAX_QUESTION_NAME_LENGTH),
            _displayNameFull: name,
            _label: String.fromCharCode((idx || 0) + 65).toLowerCase(),
            rebaseValue: null,
            testType: analysisConfigConstants.testTypes[DEFAULT_INDEX],
            brandUuid: null,
            isBrand: false,
            isTargetOption: false,
        };
    }

    /**
     * Get empty analysis factor.
     *
     * @param {object} question
     * @param {string} factorType
     * @returns {object} factor object
     */
    function getEmptyFactor(question, factorType) {
        question = question || {
            displayName: '',
            name: '',
        };
        return {
            factorType: question.factorType || analysisConfigConstants.analysisFactorTypes[0],
            levels: question.disabled ? question.levels : [],
            _index: question.idx,
            _id: question.id,
            _label: question.label,
            name: question.name,
            _displayName: U.limitStrLength(question.displayName || '', MAX_QUESTION_NAME_LENGTH),
            _displayNameFull: question.name.replace(/^\d+\. /, ''),
            analysisUsage: analysisConfigConstants.analysisUsages[DEFAULT_INDEX],
            _disabled: question.disabled,
            type: question.type,
            _question: question,
            _factorType: factorType,
        };
    }

    /**
     * Build an analysis factor.
     *
     * @param {object} factor - The analysis factor to process
     * @param {object} question - The question associated with the factor
     * @returns {object} The complete factor
     */
    function processFactor(factor, question) {
        let analysisFactor = getEmptyFactor(question || factor, factor.factorType);
        analysisFactor.kpiType = factor.kpiType;
        analysisFactor.confounderType = factor.confounderType;
        analysisFactor.objectivePriority = factor.objectivePriority;
        analysisFactor._factorType = factor.factorType;
        analysisFactor.analysisUsage = _.find(analysisConfigConstants.analysisUsages, type => {
            return type.value === factor.analysisUsage;
        }) || analysisConfigConstants.analysisUsages[DEFAULT_INDEX];
        analysisFactor.uuid = factor.uuid;

        if (question) {
            analysisFactor._question = question;
            analysisFactor.factorType = factor.factorType;
        }
        return analysisFactor;
    }

    /**
     * Parse choice from definition json.
     *
     * @param {object} json - The JSON to parse
     * @param {object} choiceMap - Map of choice name to choice object
     * @returns {object} The choice parsed out of the JSON
     */
    function getChoiceFromDefinitionJson(json, choiceMap) {
        if (!json) {
            console.log('Warning: Definition json was not specified!');
            return;
        }
        const eq = json.$eq || (json.$or && json.$or.length === 1 && json.$or[0].$eq);
        return eq && choiceMap[eq[0]];
    }

    /**
     * Get levels for factor.
     *
     * @param {object} factor - The factor to get levels for
     * @param {object} question - The question associated with the factor
     * @returns {object} The factor, now populated with factor levels
     */
    function getLevelsForFactor(factor, question) {
        return new Promise(resolve => {
            analysisConfigDataService.listFactorLevels(factor.uuid).then(levels => {
                const questionChoices = {};

                if (question && question.levels) {
                    _.forEach(question.levels, level => {
                        questionChoices[level.analysisPlanId] = level;
                    });
                }

                _.forEach(levels, (level, idx) => {
                    const choice = question && getChoiceFromDefinitionJson(level.definitionJson, questionChoices),
                        factorLevel = getEmptyFactorLevel((choice || level).name, idx),
                        isSynthetic = question && (!choice || choice.name.replace('’', '\'').indexOf(level.name) === -1);

                    factorLevel.uuid = level.uuid;
                    factorLevel.definitionJson = level.definitionJson;
                    factorLevel.brandUuid = level.brandUuid;
                    factorLevel.isTargetOption = level.isTargetOption;

                    factorLevel.testType = _.find(analysisConfigConstants.testTypes, type => {
                        return type.value === level.testType;
                    });

                    factorLevel.factorType = level.factorType || factor.factorType;

                    if (isSynthetic) {
                        if (question.type !== 'longFreeResponse') {
                            factorLevel.levelsToCombine = getLevelsToCombineForSyntheticFactorLevel(level, questionChoices);
                        }

                        factorLevel.isSynthetic = true;
                    }
                    else if (choice) {
                        factorLevel.isComplement = !!choice.noneOfTheAbove;
                        factorLevel.isSynthetic = false;
                        factorLevel.rebaseValue = choice.noneOfTheAbove ? 1 : 0;
                        factorLevel.id = choice.id;

                        setLevelDefinitionJsonForQuestion(factorLevel, choice);
                    }
                    factor.levels.push(factorLevel);
                });
                // The list of levels from the server is not in order. Use the 'value' param of question.levels to sort it properly
                // T2B levels aren't in question.levels so set the index to 100 so they display at the end
                factor.levels = question ? _.orderBy(factor.levels, level => {
                    return question.levels[level.name] ? question.levels[level.name].value : 100;
                }) : factor.levels;

                resolve(factor);
            }, () => {
                let idx = 0;

                _.forEach(question.levels, level => {
                    const factorLevel = getEmptyFactorLevel(level.name, idx++);
                    factorLevel._displayName = level.displayName;
                    factorLevel.isComplement = !!level.noneOfTheAbove;
                    factorLevel.rebaseValue = level.noneOfTheAbove ? 1 : 0;
                    setLevelDefinitionJsonForQuestion(factorLevel, level);
                    factor.levels.push(factorLevel);
                });

                resolve(factor);
            });
        });
    }

    /**
     * Constructs and returns the levelsToCombine property for a factorLevel
     *
     * @param {object} level - A factor level object
     * @param {object} questionChoices - An object containing the question choices (and their metadata) for a question
     * @returns {Array} Array of levels to combine
     */
    function getLevelsToCombineForSyntheticFactorLevel(level, questionChoices) {
        if (!level.definitionJson) {
            return;
        }

        const logic = Object.keys(level.definitionJson)[0];
        //Regex that checks for `Question$[1-9]|Choice$[1-9]`
        //ensures that we are only looking for values in questionChoices if
        //these synthetic options are question choices
        const questionChoiceRegEx = new RegExp(/(Question\$[1-9]\|Choice\$[1-9])/);

        // Using flatMap ensures that factorLevels.levelsToCombine ends up being a single array
        // instead of a 2d array
        const flattenedArray = level.definitionJson[logic].flatMap(choices => {
            let choice;
            const logicType = Object.keys(choices)[0];

            // Non-nested logic
            if (logicType === '$eq' && choices && choices.$eq && questionChoiceRegEx.test(choices.$eq[0])) {
                const choiceSlug = choices.$eq[0];

                // The choice slug for this factor level was not found in the list of question choices.
                // This usually means that the question choices were changed after creating an analysis plan,
                // making any factor levels based on deleted choices invalid. Nothing we can do with these, so just
                // console.log and skip it.
                if (!questionChoices[choiceSlug]) {
                    console.log('Unable to find question choice with id: ' + choiceSlug);
                    return '';
                }
                // Otherwise, return question name
                return questionChoices[choiceSlug].name;
            }

            // Nested logic
            else if (logicType === '$or' || logicType === '$and') {
                const questionChoicesArr = [];

                // Here logicType can be '$or' or '$and' so we use a string literal
                // to dynamically access $and or $or property
                choices[`${logicType}`].forEach(choice => {
                    const filterLogic = Object.keys(choice);

                    if (filterLogic.length === 1 && filterLogic[0] === '$eq' && questionChoiceRegEx.test(choice.$eq[0])) {
                        const questionChoice = questionChoices[choice.$eq[0]];

                        if (questionChoice) {
                            questionChoicesArr.push(questionChoice.name);
                            return questionChoice.name;
                        }

                        console.log('Unable to find choice: ' + choice.$eq[0] + ' within available question choices =>');
                        console.log(questionChoices);
                    }
                });

                if (questionChoicesArr.length < 1) {
                    console.log('Unable to find question choices for id: ' + JSON.stringify(choices));
                }
                return questionChoicesArr;
            }

            // Check for <, <=, >, >=, and not (!) logic
            else if (logicType in filterLogicConstants.comparisonOperatorMap) {
                console.log(`Skipping question choice with ${logicType} logic: `, choices[logicType]);
                return '';
            }

            // Handle instances where we are unable to parse the json / find the question choice
            if (!choice && logicType === '$eq') {
                console.log('Unable to find question choice with id: ' + choices.$eq[0]);
                return '';
            }
        });

        return flattenedArray;
    }

    /**
     * Set definition json for a factor, given question.
     *
     * @param {object} factorLevel - The factor level to set
     * @param {object} level - The level
     */
    function setLevelDefinitionJsonForQuestion(factorLevel, level) {
        factorLevel.parsedCriteria = [{
            id: level.id.replace(/\|Choice\$\d+$/, ''),
            criteria: [level.id],
            logic: '$eq',
        }];
    }

    /**
     * Find question by name.
     *
     * @param {string} questionName - The question name to find
     * @param {object} questionsMap - The map of questions to search
     * @returns {object} The matching question
     */
    function findQuestionByName(questionName, questionsMap) {
        const regex = new RegExp(questionName.replace(/[.?$\\()]/g, x => {
            return '\\' + x;
        }));
        let found;

        _.forEach(questionsMap, (_, name) => {
            if (regex.test(name) || questionName === name) {
                found = name;
                return false;
            }
        });

        return found;
    }

    /**
     * Get analysis factors list.
     *
     * @param {string} analysisConfigUuid - Analysis configuration uuid
     * @param {object} defaultQuestionsToLevelMap - Mapping of questions to factor levels
     * @returns {Array} Array of analysis factors.
     */
    function getAnalysisFactors(analysisConfigUuid, defaultQuestionsToLevelMap) {
        const result = {
            customFactors: [],
            demographicFactors: [],
            questionFactors: [],
            kpiFactors: [],
            confounders: [],
            marketResearch: [],
            traitFactors: [],
        };
        const includedDefaultQuestions = {};
        const promises = [];

        return new Promise((resolve, reject) => {
            analysisConfigDataService.listAnalysisFactors(analysisConfigUuid).then(factors => {
                // Split the factors array into question factors and demographic factors.
                // Demographic factors are identifiable because they have no analysis configuration uuid
                const questionFactors = factors.filter(factor => factor.analysisConfigurationUuid);
                const demographicFactors = factors.filter(factor => !factor.analysisConfigurationUuid);

                // Add demographic factors to result object
                result.demographicFactors = demographicFactors;

                // If no question factors were present in DB, initialize them now
                if (!questionFactors.length) {
                    const defaultQuestionFactors = getDefaultQuestionFactorsList(!result.questionFactors.length, defaultQuestionsToLevelMap, includedDefaultQuestions);
                    result.questionFactors = result.questionFactors.concat(defaultQuestionFactors);

                    resolve(result);
                }

                // Iterate through and process question factors
                questionFactors.forEach(factor => {
                    //To remove after testing
                    const isBasic = DEMOGRAPHIC_CONFOUNDERS.test(factor.name),
                        cleanedFactorName = U.unicodeToChar(factor.name.replace(/(__occurrence#\d+)$/, '')),
                        newName = !isBasic && findQuestionByName(cleanedFactorName, defaultQuestionsToLevelMap),
                        question = defaultQuestionsToLevelMap[newName];
                    let analysisFactor,
                        promise;
                    if (question) {
                        factor.name = question.name;
                    }
                    analysisFactor = processFactor(factor, question);
                    promise = new Promise(resolve => {
                        getLevelsForFactor(analysisFactor, question).then(() => {
                            if (factor.name === analysisConfigConstants.TARGET_AUDIENCE_NAME) {
                                result.targetAudience = analysisFactor;
                            }
                            if (factor.name !== analysisConfigConstants.TARGET_AUDIENCE_NAME && (isBasic || analysisFactor._factorType !== analysisConfigConstants.KPI)) {
                                result.traitFactors.push(analysisFactor);
                            }
                            if (question) {
                                result.questionFactors.push(analysisFactor);
                                includedDefaultQuestions[newName] = true;
                            }
                            else if (!isBasic) {
                                result.customFactors.push(analysisFactor);
                            }
                            else if (analysisFactor._factorType === analysisConfigConstants.CONFOUNDER) {
                                result.confounders.push(analysisFactor);
                            }
                            else if (analysisFactor._factorType === analysisConfigConstants.KPI) {
                                result.kpiFactors.push(analysisFactor);
                            }
                            else if (!isBasic) {
                                result.marketResearch.push(analysisFactor);
                            }
                            resolve();
                        }, resolve);
                    });
                    promises.push(promise);
                });

                Promise.all(promises).then(() => {
                    // For any question factors that were not in the DB, initialize them now and add to the list
                    const defaultQuestionFactors = getDefaultQuestionFactorsList(!result.questionFactors.length, defaultQuestionsToLevelMap, includedDefaultQuestions);

                    result.questionFactors = result.questionFactors.concat(defaultQuestionFactors);
                    result.questionFactors = result.questionFactors.sort((a, b) => {
                        return a._index - b._index;
                    });

                    resolve(result);
                });
            }, reject);
        });
    }

    /**
     * Get default list of question factors.
     *
     * @param {boolean} noFactors - Are there no factors?
     * @param {Array} questions - List of questions
     * @param {object} questionsToSkip - Map of factors to be skipped, based on name
     * @returns {Array} List of question factorrs
     */
    function getDefaultQuestionFactorsList(noFactors, questions, questionsToSkip) {
        const questionFactors = [];

        _.forEach(questions, (data, questionName) => {
            if (!questionsToSkip[questionName]) {
                const factorType = data._factorType || (noFactors ? analysisConfigConstants.questionFactorTypes[DEFAULT_QUESTION_FACTOR_TYPE_INDEX].value : void 0),
                    factor = getEmptyFactor(data, factorType),
                    levels = [];
                let idx = 0;
                _.forEach(data.levels, level => {
                    const factorLevel = getEmptyFactorLevel(level.name, idx++);
                    factorLevel.isComplement = !!level.noneOfTheAbove;
                    factorLevel.rebaseValue = level.noneOfTheAbove ? 1 : 0;
                    setLevelDefinitionJsonForQuestion(factorLevel, level);
                    levels.push(factorLevel);
                });
                factor.levels = levels;
                questionFactors.push(factor);
            }
        });
        return questionFactors;
    }

    /**
     * Save analysis factor.
     *
     * @param {object} analysisConfig - Analysis configuration object
     * @param {object} factor - The factor to save
     * @returns {Promise} - Promise to be resolved when call to update factor completes
     */
    function saveAnalysisFactor(analysisConfig, factor) {
        let levels = [],
            kpiLevels = [];
        _.forEach(factor.levels, level => {
            if (!level.deleted) {
                if (level.isKpi && factor._factorType !== KPI_FACTOR_TYPE) {
                    kpiLevels.push(level);
                }
                else {
                    levels.push(level);
                }
            }
        });
        factor.levels = levels;

        if (kpiLevels.length) {
            let newFactor = {};
            _.forEach(factor, (value, key) => {
                if (key !== 'uuid' && key !== 'levels' && key !== '_factorType') {
                    newFactor[key] = angular.copy(value);
                }
            });

            newFactor._factorType = KPI_FACTOR_TYPE;
            newFactor.levels = kpiLevels;
            analysisConfigDataService.updateAnalysisFactor(analysisConfig.version, newFactor).then(kpi => {
                newFactor.uuid = kpi.uuid;
                analysisConfig.kpiFactors.push(newFactor);
                analysisConfig.customFactors.push(newFactor);
            });
        }
        return analysisConfigDataService.updateAnalysisFactor(analysisConfig.version, factor);
    }

    /**
     * Array to object key map.
     *
     * @param {Array} arr - Array of items
     * @param {string} paramName - Name of param
     * @returns {object} - Object converted from array based on given key
     */
    function arrayToObjKeyMap(arr, paramName) {
        return _.reduce(arr, (obj, item) => {
            obj[item[paramName]] = item[paramName] ? item : void 0;
            return obj;
        }, {});
    }

    /**
     * For each question choice in a factor/question, check if the factor level
     * exists yet. If it doesn't create it now.
     *
     * @param {object} factor - The analysis factor that we want to create levels for
     * @returns {Promise} - Resolves when the factor has been created
     */
    function initializeLevelsForFactor(factor) {
        const promises = [],
            // Factor._question.levels is an Object with the choice text as keys.
            // What we really want is an object, ordered by 'value', which will
            // ensure that the choices remain in the order in which they were intended
            levels = _.orderBy(Object.values(factor._question.levels), 'value'),
            hasFactorIdMap = arrayToObjKeyMap(factor.levels, 'id');

        return new Promise(resolve => {
            levels.forEach(level => {
                if (!hasFactorIdMap[level.id]) {
                    level.definitionJson = {
                        $or: [{
                            $eq: [level.analysisPlanId, 1],
                        }],
                    };
                    level.factorType = factor.isComplement ? analysisConfigConstants.MARKET_RESEARCH : analysisConfigConstants.CONFOUNDER;
                    promises.push(analysisConfigDataService.updateFactorLevel(factor.uuid, level));
                }
            });
            Promise.all(promises).then(resolve, () => {
                factor._initializationFailed = true;
                resolve();
            });
        });
    }

    /**
     * Calls the API to create a new analysis factor
     *
     * @param {object} analysisConfig - The analysis configuration object
     * @param {object} factor - The analysis factor to be saved
     * @returns {Promise} - Resolves when the factor has been created
     */
    function initializeQuestionFactor(analysisConfig, factor) {
        return new Promise(resolve => {
            analysisConfigDataService.saveAnalysisFactor(analysisConfig.version, factor).then(response => {
                factor.uuid = response.uuid;
                return initializeLevelsForFactor(factor);
            }, () => {
                factor._initializationFailed = true;
                resolve(factor);
            });
        });
    }

    /**
     * Initialize question factors by iterating through default question factions in analysis config
     * and checking if they have been created yet (if they have a uuid). If not, create the factor now.
     * Also, ensure that factor levels exist for each question and create them if necessary
     *
     * @param {object} analysisConfig - The analysis configuration object
     * @returns {Promise} - A promise that resolves when all factors have been initialized
     *
     */
    function initializeQuestionFactors(analysisConfig) {
        const promises = [];

        return new Promise((resolve, reject) => {
            analysisConfig.questionFactors.forEach(factor => {
                if (!factor.uuid && !factor._disabled) {
                    factor._factorType = factor._factorType || analysisConfigConstants.questionFactorTypes[1].value;
                    promises.push(initializeQuestionFactor(analysisConfig, factor));
                }
                else if (factor.levels.length < _.size(factor._question.levels)) {
                    promises.push(initializeLevelsForFactor(factor));
                }
            });

            Promise.all(promises).then(() => {
                resolve(Boolean(promises.length));
            }, reject);
        });
    }

    /**
     * Builds the parsedCriteria for exisiting fuzzy recodings.
     * To do that for existing fuzzy recodings, we must first iterate through the filter json and parse out the fuzzy selections.
     * Since parsedCriteria is an array of objects, we then build an object for each fuzzy selection and it's related data
     *
     * @param {object} levelToUpdate - Existing level/custom grouping, if we're editing rather than creating a new one
     * @param {object} factor - The parent factor for the factor levels to be combined
     */
    function buildParsedCriteriaForFuzzyFactor(levelToUpdate, factor) {
        const selections = Object.entries(levelToUpdate.definitionJson);
        const [filterLogic, filterSelections] = selections[0];
        levelToUpdate.questionType = 'longFreeResponse';
        // If there is only one fuzzy recoding then we dont need to worry about nested logic
        if (selections[0][0] === '$fuzzy_contains') {
            levelToUpdate.parsedCriteria = [{
                longFreeResponse: true,
                id: factor._question.id,
                criteria: filterSelections[1],
                logic: '$fuzzy_contains',
            }];
        }
        else {
            let nestedLogic;
            const nestedLogicMap = {},
                selectionChoices = [];

            filterSelections.forEach(selection => {
                if (selection.$fuzzy_contains) {
                    selectionChoices.push(selection.$fuzzy_contains[1]);
                }
                else {
                    nestedLogic = Object.keys(selection);

                    selection[nestedLogic].forEach(selection => {
                        nestedLogicMap[selection.$fuzzy_contains[1]] = nestedLogic[0];
                        selectionChoices.push(selection.$fuzzy_contains[1]);
                    });
                }
            });

            levelToUpdate.parsedCriteria = selectionChoices.map(selection => {
                return {
                    longFreeResponse: true,
                    id: factor._question.id,
                    parentLogic: nestedLogicMap[selection] ? nestedLogicMap[selection] : filterLogic,
                    criteria: selection,
                    logic: '$fuzzy_contains',
                };
            });
        }
    }

    return {
        getDefaultQuestionFactorToChoiceLevel,
        getAnalysisFactors,
        getEmptyFactorLevel,
        getEmptyFactor,
        saveAnalysisFactor,
        getDefaultQuestionFactorsList,
        initializeQuestionFactors,
        buildParsedCriteriaForFuzzyFactor,
    };
}
