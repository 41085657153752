angular.module('questionTypes.longFreeResponse', [])
    .service('questionTypes.longFreeResponse.service', LongFreeResponseService);

function LongFreeResponseService() {
    function toString(q) {
        return (q.statement.text || '') + '\n' +
            (q.statement.image ? '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n' : '') +
            '[free response]\n' +
            (q.details && q.details.placeholder ? 'placeholder: ' + q.details.placeholder : '') + '\n';
    }

    function errors(_q) {
        return [];
    }
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'longFreeResponse',
        name: 'Free response',
        description: 'Write an open-ended response',
        icon: 'fas fa-edit',
        toString: toString,
        errors: errors,
    };
}
