import _ from 'lodash';
import '../../common-components/sv-dialog';
import '../ad-measurement.api-service';

angular
    .module('audienceConfigurationList.view', [
        'common.dialog', 'adMeasurement.apiService',
    ])
    .directive('audienceConfigurationList', audienceConfigurationList)
    .controller('AudienceConfigurationListCtrl', AudienceConfigurationListCtrl);

function audienceConfigurationList() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/audience-configurations.html',
        controller: 'AudienceConfigurationListCtrl',
        controllerAs: 'AudienceConfigurationListVM',
    };
}

AudienceConfigurationListCtrl.$inject = [
    '$rootScope',
    '$scope',
    'dialog',
    'adMeasurementApiService',
];

function AudienceConfigurationListCtrl($rootScope, $scope, dialog, adMeasurementApiService) {
    const AudienceConfigurationListVM = this,
        beaconConfigurationMap = {},
        audienceConfigurationNames = {};
    // VM exposed variables
    AudienceConfigurationListVM.tooltipUrl = '/dashboard-templates/ad-measurement/audience-configuration/beacon-control-configuration-tooltip.html';
    // VM exposed functions
    AudienceConfigurationListVM.editAudienceConfiguration = editAudienceConfiguration;
    AudienceConfigurationListVM.togglePauseForAudienceConfiguration = togglePauseForAudienceConfiguration;
    AudienceConfigurationListVM.deleteAudienceConfiguration = deleteAudienceConfiguration;
    AudienceConfigurationListVM.openWindowDaysModal = openWindowDaysModal;

    init();

    function init() {
        const promises = [];
        promises.push(adMeasurementApiService.listAudienceConfigurations({
            uuid: $rootScope.survey._audienceId,
        }).then(audienceConfigurations => {
            $scope.$evalAsync(() => {
                AudienceConfigurationListVM.audienceConfigurations = audienceConfigurations;
            });
            _.forEach(audienceConfigurations, config => {
                audienceConfigurationNames[config.name] = config.uuid;
            });
        }));
        promises.push(adMeasurementApiService.listBeaconControlConfigurations($rootScope.survey).then(beaconControlConfigurations => {
            AudienceConfigurationListVM.beaconControlConfigurations = beaconControlConfigurations;
            _.forEach(beaconControlConfigurations, config => {
                setBeaconControlConfigTooltip(config);
                beaconConfigurationMap[config.uuid] = config;
            });
            AudienceConfigurationListVM.beaconConfigurationMap = beaconConfigurationMap;
        }));
        Promise.all(promises).then(() => {
            let numBeaconConfigs = _.size(AudienceConfigurationListVM.beaconConfigurationMap);
            _.forEach(AudienceConfigurationListVM.audienceConfigurations, config => {
                const beaconConfig = AudienceConfigurationListVM.beaconConfigurationMap[config.beaconControlConfigurationUuid];
                if (beaconConfig) {
                    numBeaconConfigs += beaconConfig.preventDelete ? 0 : 1;
                    beaconConfig.preventDelete = true;
                }
                // Short circuit if all beacon control configurations have been used
                if (!numBeaconConfigs) {
                    return false;
                }
            });
        });
    }

    function setBeaconControlConfigTooltip(config) {
        const matching = [],
            percentage = [];
        _.forEach(config, (val, key) => {
            const matchingKey = key.match(/^match([A-Z].+)$/);
            let percentageKey = key.match(/^([a-zA-Z]+)Percent$/);
            // Quotas start with `match` or end with `percentage`. Ignore other keys
            if (matchingKey && val) {
                matching.push(matchingKey[1].replace(/([A-Z])/g, ' $1').trim());
            }
            else if (percentageKey && val) {
                const ageRange = percentageKey[1].match(/^ageRange(.+)/);
                percentageKey = ageRange ? ageRange[1].replace('To', ' - ') : percentageKey[1];
                percentage.push(percentageKey.replace(/([A-Z])/g, ' $1') + ': ' + (val * 100) + '%');
            }
        });
        config.rules = matching.length ? matching : percentage;
        config.demographicStrategy = matching.length ? 'Matching' : 'Quotas';
    }

    function openWindowDaysModal() {
        dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/audience-configuration/window-days-modal.html',
            controller: 'WindowDaysCtrl',
            controllerAs: 'WindowDaysVM',
        });
    }

    // Audience configuration
    function editAudienceConfiguration(audienceConfiguration, idx) {
        dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/audience-configuration/audience-configuration-modal.html',
            controller: 'AudienceConfigurationCtrl',
            controllerAs: 'AudienceConfigurationVM',
            windowClass: 'modal-sv--lg-width modal-sv--scroll-on-content',
            resolve: {
                audienceConfigurationToEdit: () => {
                    return audienceConfiguration ? angular.copy(audienceConfiguration) : audienceConfiguration;
                },
                audienceConfigurationNames: () => {
                    return audienceConfigurationNames;
                },
                beaconControlConfigurations: () => {
                    return AudienceConfigurationListVM.beaconControlConfigurations;
                },
            },
        }).result.then(updated => {
            AudienceConfigurationListVM.audienceConfigurations = AudienceConfigurationListVM.audienceConfigurations || [];
            if (updated) {
                if (audienceConfiguration) {
                    AudienceConfigurationListVM.audienceConfigurations[idx] = updated;
                    delete audienceConfigurationNames[audienceConfiguration.name];
                }
                else {
                    AudienceConfigurationListVM.audienceConfigurations.push(updated);
                }
                audienceConfigurationNames[updated.name] = updated.uuid;
                _.forEach(updated.beaconDefinitions, beaconDefinition => {
                    AudienceConfigurationListVM.beaconConfigurationMap[beaconDefinition.uuid].preventDelete = true;
                });
            }
        });
    }

    function deleteAudienceConfiguration(audienceConfiguration) {
        adMeasurementApiService.deleteAudienceConfigurationByUuid(audienceConfiguration.uuid).then(() => {
            audienceConfiguration.deleted = true;
            delete audienceConfigurationNames[audienceConfiguration.name];
        });
    }

    function togglePauseForAudienceConfiguration(audienceConfiguration) {
        audienceConfiguration.paused = !audienceConfiguration.paused;
        adMeasurementApiService.updateAudienceConfiguration(audienceConfiguration).then(() => {}, () => {
            audienceConfiguration.paused = !audienceConfiguration.paused;
        });
    }
}
