import '@uirouter/angularjs';
import '../common-components/pub-sub';
import '../survey/api-survey-service';
import '../survey/survey-statuses';
import '../survey-creation/survey-creation-service';

angular.module('surveyTarget.ctrl', [
    'ui.router',
    'pubSub',
    'apiSurveyService',
    'surveyCreation.service',
    'surveyStatusesService',
])
    .controller('SurveyTargetCtrl', SurveyTargetCtrl);
SurveyTargetCtrl.$inject = ['$scope',
    '$rootScope',
    '$timeout',
    '$state',
    'pubSubService',
    'surveyService',
    'surveyCreationService',
    'surveyStatusesService',
];

/**
 * @param $scope
 * @param $rootScope
 * @param $timeout
 * @param $state
 * @param pubSubService
 * @param surveyService
 * @param surveyCreationService
 * @param surveyStatusesService
 */
function SurveyTargetCtrl($scope, $rootScope, $timeout, $state, pubSubService, surveyService, surveyCreationService, surveyStatusesService) {
    $scope.ux = {
        activeRequirement: null,
        targetingType: '',
        editDetailsStatus: '',
        nextUrl: getNextUrl(),
        accordian: {
            closeOthers: true,
            specify: false,
            describe: false,
            unsure: false,
        },
    };
    $scope.openSaveModal = surveyCreationService.openSaveModal;
    $scope.dashboardView = $state.current.parent === 'dashboard';
    $scope.rootux = $rootScope.rootux;
    $scope.survey = $rootScope.survey;
    $rootScope.rootux.saveSurveyStatus = 'unsaved';
    // Navigate to the next page
    $scope.saveTargetingDetails = saveTargetingDetails;
    $scope.next = next;

    init();

    function init() {
        var trackTargetPageTraffic = _.once(U.fireSurveyTargetTraffic);
        $scope.$on('$viewContentLoaded', function() {
            trackTargetPageTraffic();
        });

        pubSubService.subscribe('select-active-json', $scope.$id, selectActiveJson);

        $scope.$on('$destroy', () => {
            pubSubService.destroy('select-active-json', $scope.$id);
        });

        if ($scope.survey.surveyQuoteRequest && $scope.survey.surveyQuoteRequest.audienceUnsure) {
            $scope.ux.targetingType = 'unsure';
            $scope.ux.accordian.unsure = true;
            $scope.ux.accordian.describe = false;
            $scope.ux.accordian.specify = false;
        }
        else if ($scope.survey.targetingDetails) {
            $scope.ux.targetingType = 'description';
            $scope.ux.accordian.describe = true;
            $scope.ux.accordian.unsure = false;
            $scope.ux.accordian.specify = false;
        }
        else if ($scope.survey.targetingReqs && $scope.survey.targetingReqs.length > 1) {
            $scope.ux.targetingType = 'builder';
            $scope.ux.accordian.specify = true;
            $scope.ux.accordian.unsure = false;
            $scope.ux.accordian.describe = false;
        }
    }

    /**
     * @param question
     */
    function selectActiveJson(question) {
        if (angular.isDefined) {
            $scope.ux.active = null;
            $scope.ux.activeJson = question;
        }
        else {
            $scope.ux.activeJson = null;
        }
    }

    /**
     *
     */
    function getNextUrl() {
        return $state.href('design', surveyStatusesService.getRouteParams($rootScope.survey));
    }

    /**
     * @param targetingDetails
     */
    function next(targetingDetails) {
        if (surveyStatusesService.isAdvancedFlow()) {
            if ($scope.ux.targetingType === '') {
                $scope.ux.targetingType = 'unsure';
            }

            surveyService.updateQuoteRequest($scope.survey.uuid, {
                audienceUnsure: $scope.ux.targetingType === 'unsure' ? 'true' : 'false',
            })
                .then(response => {
                    $scope.survey.surveyQuoteRequest = response;
                });
        }
        saveTargetingDetails(targetingDetails);
    }

    /**
     * @param newTargetingText
     */
    function saveTargetingDetails(newTargetingText) {
        $scope.ux.editDetailsStatus = 'saving';
        surveyService.updateSurvey($scope.survey.uuid, {
            targetingDetails: newTargetingText,
        }).then(response => {
            $scope.survey.targetingDetails = response.targetingDetails;
            $timeout(() => {
                $scope.ux.editDetailsStatus = 'success';
                $timeout(() => {
                    $scope.ux.editDetailsStatus = '';
                }, 750);
            }, 750);
        });
    }
}
