import './sv-affix';
var tooltipTemplateString = '' +
    '<div class="popover" role="tooltip">' +
    '<div class="arrow"></div>' +
    '<h3 class="popover-title"></h3>' +
    '<div class="popover-content content-style"></div>' +
    '</div>';

angular.module('svWidgets', ['svAffix'])
    .directive('svSubmitButton', function($timeout) {
        return {
            restrict: 'CA',
            scope: {
                svSubmitButton: '=',
                icon: '@',
                label: '@',
                labelInProgress: '@',
                labelSuccess: '@',
                labelFail: '@',
            },
            templateUrl: 'shared-templates/submit-button.html',
            link: function(scope, element, attrs) {
                var unwatch = scope.$watch('svSubmitButton', function() {
                    if (scope.svSubmitButton === 'success') {
                        $timeout(function() {
                            scope.svSubmitButton = '';
                            unwatch();
                        }, 2000);
                    }
                });
            },
        };
    })

    .directive('svDashboardBannerInfo', function() {
    // Using this directive as an "include" type of file
        return {
            restrict: 'E',
            templateUrl: 'shared-templates/sv-dashboard-banner-info.html',
        };
    })

// Example on how to use this tooltip
// <div sv-tooltip data-ng-attr-hide-tooltip="true" data-toggle="popover" data-content="Text to be displayed in popover">
//
// Extra functionality for the tooltips:
//     - To hide popover programmatically add the attribute to an
//         ancestor of the popover, e.g.
//             data-ng-attr-hide-tooltip="{{YOUR_EXPRESSION}}"
//
//     - To display popover contents in one line add the attribute
//         to an ancestor of the popover, e.g.
//             data-one-line
//
//     - To enable a min-width of 150px on the tooltip add the following property
//         data-popover-min-width
//         * This property does not need a value, just to be set on the elmenet

    .directive('svTooltip', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                attrs.html = attrs.html != 'false';

                var contentDefinition = attrs.dynamicContent ? function() {
                    return element.data().content || element.html();
                } : attrs.content;

                // Var popoverTrigger = attrs.trigger || 'hover';
                // TODO -- fix - this closes the popup before it opens
                // // allow close on trigger if trigger != click or hover
                // var closeOnTrigger = !(popoverTrigger.match('hover | click').length);
                // if (closeOnTrigger) {
                //     element.on(attrs.closePopoverTrigger, $.support.transition.end, function() {
                //         element.data('bs.popover').hide();
                //     });
                // }

                element.popover({
                    container: attrs.container || element,
                    html: true && (attrs.html || attrs.html === null), // Html is true by default. only false if the front-end sets it to false
                    trigger: attrs.trigger || 'hover',
                    animation: true,
                    content: contentDefinition,
                    title: attrs.title,
                    placement: attrs.placement || 'auto left',
                    template: tooltipTemplateString,
                    delay: {
                        show: attrs.delayShow || 200,
                        hide: 0,
                    },
                });
            },
        };
    });
