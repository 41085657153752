angular.module('questionTypes.ranking', [])
    .service('questionTypes.ranking.service', rankingService);

function rankingService() {
    var labels = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    function toString(q) {
        var text = '',
            i = 0;

        text += (q.statement.text || '') + '\n';
        if (q.statement.image) {
            text += '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n';
        }
        text += '[ranking - ' + q.details.responseType + ']\n';

        _.forEach((q.details && q.details.choices) || [], function(e) {
            if (e[q.details.responseType]) {
                text += '*' + labels[i++] + '. ' + e[q.details.responseType] + '\n';
            }
        });

        return text;
    }

    function errors(q) {
        var errs = [];

        var count = 0,
            optionsHash = {};
        _.forEach(q.details.choices, function(c) {
            count = c[q.details.responseType] ? count + 1 : count;
            if (c.text) {
                if (optionsHash[c.text]) {
                    errs.push('Answer options can not contain duplicates.');
                }
                optionsHash[c.text] = true;
            }
        });

        if (count < 2) {
            errs.push('Enter at least two items to rank.');
        }
        return errs;
    }

    return {
        basic: {
            maxAllowed: 2,
        },
        extended: {
            maxAllowed: 4,
        },
        advanced: true,
        id: 'ranking',
        name: 'Ranking',
        description: 'Rank the presented items',
        icon: 'fas fa-sort-amount-up',
        toString: toString,
        errors: errors,
        initialDetails: {
            choices: [],
            choicesToDisplay: 6,
            responseType: 'text',
            order: 'shuffle',
        },
    };
}
