angular.module('questionTypes.grid', [])
    .service('questionTypes.grid.service', function() {
        var labels = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

        function toString(q) {
            var text = '',
                i = 0;

            text += (q.statement.text || '') + '\n';
            if (q.statement.image) {
                text += '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n';
            }
            text += '[' + q.type + ']\n';

            text += '~~ Row Statements ~~\n';
            _.forEach((q.details && q.details.rowStatements) || [], function(rowStatement) {
                if (rowStatement.text) {
                    text += '*' + labels[i++] + '. ' + rowStatement.text + '\n';
                }
            });
            i = 0;
            text += '~~ Column Choices ~~\n';
            _.forEach((q.details && q.details.choices) || [], function(choice) {
                if (choice.text) {
                    text += '*' + labels[i++] + '. ' + choice.text + '\n';
                }
            });

            return text;
        }

        function errors(q) {
            var errs = [];

            var rowsHash = {},
                optionsHash = {},
                numChoices = 0,
                numRowStatements = 0;
            _.forEach(q.details.choices, function(choice) {
                var val = choice.text;
                if (val && val.length) {
                    if (optionsHash[val]) {
                        errs.push('Column choices can not contain duplicates.');
                    }
                    optionsHash[val] = true;
                    numChoices++;
                }
            });
            _.forEach(q.details.rowStatements, function(statement) {
                var val = statement.text;
                if (val && val.length) {
                    if (rowsHash[val]) {
                        errs.push('Row statements can not contain duplicates.');
                    }
                    rowsHash[val] = true;
                    numRowStatements++;
                }
            });

            // Require at least two response choices.
            if (!q.details || (q.details.gridType !== 'numeric' && numChoices < 2)) {
                errs.push('Enter at least two response choices.');
            }
            // Require at least two row statements
            if (!q.details || numRowStatements < 2) {
                errs.push('Enter at least two row statements.');
            }

            return errs;
        }

        return {
            basic: false,
            advanced: {
                rows: 20,
                choices: 10,
                maxRowStatementChars: 256,
                maxAnswerChars: 60,
            },
            extended: {
                maxAllowed: 2,
                rows: 5,
                choices: 5,
                maxRowStatementChars: 60,
                maxAnswerChars: 60,
            },
            screener: {
                adminOnly: true,
            },
            id: 'grid',
            name: 'Grid',
            description: 'Select choices from a table',
            icon: 'fal fa-table',
            toString: toString,
            errors: errors,
            initialDetails: {
                gridType: 'single',
                displayType: 'standard',
                choices: [],
                rowStatements: [],
                rowStatementsAlt: [],
            },
        };
    });
