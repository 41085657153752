angular.module('questionTypes.select', [])
    .service('questionTypes.select.singleSelect', SingleSelect)
    .service('questionTypes.select.sliderSelect', SliderSelect)
    .service('questionTypes.select.comboboxSelect', ComboboxSelect)
    .service('questionTypes.select.dropdownSelect', DropdownSelect)
    .service('questionTypes.select.multiSelect', MultiSelect);
var labels = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

/**
 * @param displayType
 */
function getInitialDetails(displayType) {
    let details = {
        displayType: 'normal',
        responseType: 'text',
        choices: [],
        order: 'shuffle',
        type: '',
    };
    if (displayType) {
        details.displayType = displayType;
    }
    return details;
}

/**
 * @param q
 */
function toString(q) {
    var text = '',
        i = 0;
    q.details = q.details || {};
    text += (q.statement.text || '') + '\n';
    if (q.statement.image) {
        text += '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n';
    }
    text += q.screener ? '[screener]\n' : '';
    text += '[' + q.type + (angular.isDefined(q.details.responseType) ? ' - ' + q.details.responseType : '') + ']\n';
    text += angular.isDefined(q.details.order) ? '[' + q.details.order + ']\n' : '';
    _.forEach(q.details.choices || [], function(e) {
        var pinned = e.pinned && q.details.order !== 'fixed' ? ' [pinned]' : '',
            disqualifier = e.disqualifier ? ' (STOP)' : '';

        if (e[q.details.responseType]) {
            text += '*' + labels[i++] + '. ' + e[q.details.responseType] + pinned + disqualifier + '\n';
        }
        else if (e.specifyOptionEnabled) {
            text += '*' + labels[i++] + '. other/specify' + pinned + disqualifier + '\n';
        }
    });

    return text;
}

/**
 * @param q
 */
function errors(q) {
    var errs = [];

    // Require at least two response choices.
    var count = 0,
        hasPassing = false,
        hasFailing = false,
        optionsHash = {},
        specifyOptionEnabled = q.details.specifyOptionEnabled ? 1 : 0;

    _.forEach(q.details.choices, function(c) {
        hasPassing = hasPassing || !c.disqualifier;
        hasFailing = hasFailing || c.disqualifier;
        count = c[q.details.responseType] ? count + 1 : count;
        if (c.text) {
            if (optionsHash[c.text]) {
                errs.push('Answer options can not contain duplicates.');
            }
            optionsHash[c.text] = true;
        }
    });

    // If custom screening logic has been defined, no need to choose a failing option
    if (q.screener && !q.details.screeningLogic) {
        if (!hasPassing) {
            errs.push('Mark a choice that passes a respondent.');
        }
        else if (!hasFailing) {
            errs.push('Mark a choice that fails a respondent.');
        }
    }

    if ((count + specifyOptionEnabled) < 2) {
        errs.push('Enter at least two response choices.');
    }
    return errs;
}

/**
 *
 */
function SingleSelect() {
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'singleSelect',
        name: 'Single Select',
        description: 'Select one of multiple choices',
        icon: 'far fa-dot-circle',
        screener: {
            adminOnly: false,
        },
        toString: toString,
        errors: errors,
        initialDetails: getInitialDetails(),
    };
}

/**
 *
 */
function SliderSelect() {
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'singleSelect',
        name: 'Slider',
        description: 'Select choices from a slider',
        icon: 'far fa-sliders-h',
        screener: {
            adminOnly: false,
        },
        initialDetails: getInitialDetails('slider'),
        toString: toString,
        errors: errors,
    };
}

/**
 *
 */
function ComboboxSelect() {
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'singleSelect',
        name: 'Combo box',
        description: 'Search for choices from a list of items',
        icon: 'fas fa-search-plus',
        screener: {
            adminOnly: true,
        },
        initialDetails: getInitialDetails('combobox'),
        toString: toString,
        errors: errors,
    };
}

/**
 *
 */
function DropdownSelect() {
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'singleSelect',
        name: 'Dropdown',
        description: 'Select one choice from a list of items',
        icon: 'far fa-caret-square-down',
        screener: {
            adminOnly: false,
        },
        initialDetails: getInitialDetails('dropdown'),
        toString: toString,
        errors: errors,
    };
}

/**
 *
 */
function MultiSelect() {
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'multiSelect',
        name: 'Multi Select',
        description: 'Select any number of multiple choices',
        icon: 'far fa-check-square',
        screener: {
            adminOnly: false,
        },
        toString: toString,
        errors: errors,
        initialDetails: getInitialDetails(),
    };
}
