import FilterLogicModel from '../filter-logic/filter-logic-model';
import * as defaultFilterService from './default-filter-service';
import './filter-and-compare-service';

angular.module('filterAndCompare.modelManager', [
    'filterAndCompare.service',
])
    .service('filterAndCompareModelManager', filterAndCompareModelManager);
filterAndCompareModelManager.$inject = [
    '$rootScope', 'filterAndCompareService',
];

/**
 * @param $rootScope
 * @param filterAndCompareService
 */
function filterAndCompareModelManager($rootScope, filterAndCompareService) {
    var _filterModel,
        _comparisonData,
        _collectionPeriods,
        _parsedCriteria;

    /**
     * @param data
     */
    function filterModel(data) {
        if (arguments.length) {
            _filterModel = data;
        }
        if (!_filterModel) {
            console.error('Filter model not initialized');
            return;
        }
        return angular.copy(_filterModel);
    }

    /**
     * @param data
     */
    function comparisonData(data) {
        if (arguments.length) {
            _comparisonData = data;
        }
        return angular.copy(_comparisonData);
    }

    /**
     * @param data
     */
    function collectionPeriodModel(data) {
        if (arguments.length) {
            _collectionPeriods = data;
        }
        return angular.copy(_collectionPeriods);
    }

    /**
     * @param data
     */
    function parsedCriteria(data) {
        if (arguments.length) {
            _parsedCriteria = data;
        }
        return _parsedCriteria;
    }

    /**
     * @name initialize
     * @param {object} [defaultFilter] - if there is a default filter applied.
     *
     * @description
     * Fetches the comparison data, filter model, filter tree, period data, and map from filter type to filter node.
     */
    function initialize(defaultFilter) {
        let isLegacyFilter = defaultFilter && typeof defaultFilter.gender === 'string',
            isDeprecatedFilter = defaultFilter && defaultFilter.Question$Choice,
            isOldFilter = isLegacyFilter || isDeprecatedFilter,
            parsedCriteria = [];
        _filterModel = new FilterLogicModel($rootScope.survey);
        if (defaultFilter) {
            if (isLegacyFilter) {
                parsedCriteria = defaultFilterService.convertLegacyFilterModel(defaultFilter);
            }
            else if (isDeprecatedFilter) {
                parsedCriteria = defaultFilterService.convertDeprecatedFilterModel(defaultFilter);
            }
            else {
                const params = Array.isArray(defaultFilter.tallyParams) ? defaultFilter.tallyParams[0].filter_by : defaultFilter.tallyParams;
                if (_.size(params) && _.values(params)[0].length) {
                    parsedCriteria = defaultFilterService.convertFilterBy(params, []);
                }
            }
            _.forEach(parsedCriteria, criteria => {
                defaultFilterService.setModelById(_filterModel, criteria);
            });
        }
        defaultFilter = defaultFilter || {};
        _parsedCriteria = parsedCriteria;
        _comparisonData = isOldFilter ? convertComparisonData(defaultFilter.comparisonData) : defaultFilter.comparisonData;
        _collectionPeriods = filterAndCompareService.getCollectionPeriodModel($rootScope.survey, defaultFilter);
    }

    /**
     * @param comparisonData
     */
    function convertComparisonData(comparisonData) {
        _.forEach(comparisonData, data => {
            if (data.ages) {
                data.Demographic$Age = data.ages;
                delete data.ages;
            }
            if (data.beaconPools) {
                data.Beacon$Pool = data.beaconPools;
                delete data.beaconPools;
            }
            if (data.gender) {
                data.Demographic$Gender = data.gender;
                delete data.gender;
            }
        });
        return comparisonData;
    }

    return {
        initialize,
        filterModel,
        comparisonData,
        collectionPeriodModel,
        parsedCriteria,
    };
}
