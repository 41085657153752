import moment from 'moment';
import U from '../../../../common/js/util';
import '../common-components/pub-sub';
import '../common-components/modal-service';
import customVariablesService from '../custom-variables/custom-variables.api-service';
import filterLogicConstants from '../filter-logic/filter-logic-constants';
import filterItemService from './filter-item.service';
import {
    DEFAULT_FUZZY_THRESHOLD,
    getModelById,
} from './default-filter-service';
import '../common-components/sv-datepicker';

angular.module('filterItem.view', [
    'pubSub', 'modalService',
])
    .directive('filterItemEditor', filterItemEditor)
    .controller('FilterItemController', FilterItemController);

/**
 * Filter item directive definition
 *
 * @returns {object} - The filter item directive
 */
function filterItemEditor() {
    return {
        restrict: 'E',
        templateUrl: '/shared-templates/filter-item.html',
        scope: {
            parsedCriteria: '=',
            filterModel: '=',
            onChange: '<?',
        },
        controllerAs: 'FilterItemVM',
        controller: 'FilterItemController',
    };
}

FilterItemController.$inject = [
    '$rootScope',
    '$scope',
    'modalService',
    'pubSubService',
];

/**
 * Controller for filter item directive.
 *
 * @param {object} $rootScope - The root scope of the app
 * @param {object} $scope - The scope for this directive/controller
 * @param {object} modalService - Service for handling modals
 * @param {object} pubSubService - Pub/sub service
 */
function FilterItemController($rootScope, $scope, modalService, pubSubService) {
    const FilterItemVM = this;

    // VM exposed variables
    FilterItemVM.ux = {};
    FilterItemVM.sharedUx = {};
    FilterItemVM.auth = $rootScope.auth;
    FilterItemVM.datePickerOptions = {
        minDate: new Date($rootScope.survey.started),
        maxDate: $rootScope.survey.completed ? new Date($rootScope.survey.completed) : new Date(),
        ngChange: updateDate,
    };

    // VM exposed functions
    FilterItemVM.selectCriteriaType = selectCriteriaType;
    FilterItemVM.selectFilterByLogic = selectFilterByLogic;
    FilterItemVM.updateSelectedCriteriaItem = updateSelectedCriteriaItem;
    FilterItemVM.openEditor = openEditor;
    FilterItemVM.showDropdown = showDropdown;
    FilterItemVM.toggleDropdown = toggleDropdown;
    FilterItemVM.toggleSearchBar = toggleSearchBar;
    FilterItemVM.clearSearch = clearSearch;
    FilterItemVM.selectAll = selectAll;
    FilterItemVM.validateNumber = validateNumber;
    FilterItemVM.updateDate = updateDate;

    init();

    function init() {
        refreshFilterItem();
        pubSubService.subscribe('refresh-filter-item', $scope.$id, refreshFilterItem);
        $scope.$on('$destroy', () => {
            pubSubService.destroy('refresh-filter-item', $scope.$id);
        });
    }

    /**
     * Refresh filter item.
     */
    function refreshFilterItem() {
        if (FilterItemVM.criteria && FilterItemVM.criteria.id !== $scope.parsedCriteria.id) {
            _.forEach(FilterItemVM.criteria.logicPhrase, phrase => {
                delete phrase.selected;
            });
            _.forEach(FilterItemVM.criteria.options, option => {
                option.set = /Demographic\$Age|Beacon\$Pool$/.test(FilterItemVM.criteria.id) ? !option.disabled : false;
            });
            FilterItemVM.isAvailable = true;
            FilterItemVM.selectedLogicPhrase = void 0;
            FilterItemVM.selectedOptionsPhrase = void 0;
            FilterItemVM.criteria = void 0;
        }
        if ($scope.parsedCriteria.id) {
            $scope.$evalAsync(() => {
                FilterItemVM.criteria = getModelById($scope.filterModel, $scope.parsedCriteria.id);
                if (FilterItemVM.criteria) {
                    initializeSelected();
                }
            });
        }
    }

    /**
     * Initialize selected critieria.
     */
    function initializeSelected() {
        if (FilterItemVM.criteria.id === filterLogicConstants.criteriaTypes.SURVEY_COMPLETION_DATE) {
            initializeDatePickerModel($scope.parsedCriteria.criteria[0]);
        }
        FilterItemVM.selectedLogicPhrase = _.find(FilterItemVM.criteria.logicPhrase, 'selected') || FilterItemVM.criteria.logicPhrase[0];
        FilterItemVM.selectedLogicPhrase.selected = true;
        FilterItemVM.selectedOptionsPhrase = filterItemService.getSelectedOptionsPhrase(FilterItemVM.criteria);
        FilterItemVM.ux.canShowSearch = FilterItemVM.auth.user.isAdmin && (FilterItemVM.criteria.options || []).length > 5;
        if (FilterItemVM.criteria.options) {
            updateSelectAllFlag();
        }
    }

    /**
     * Callback function for when the user updates the selected date using the datepicker widget.
     * Modifies the date using the dateTimeToUTCZeroISOString function, then stores in the parsed criteria object.
     */
    function updateDate() {
        $scope.parsedCriteria.criteria[0] = U.datetimeToUTCZeroISOString(FilterItemVM.datePickerModel.endDate);

        // If onChange callback was provided, call it now to propogate change to parent
        if ($scope.onChange) {
            $scope.onChange($scope.parsedCriteria);
        }
    }

    /**
     * Initialize the date picker model for the ui-bootstrap-datepicker widget.
     * Uses a Utii function to ensure date consistency. (See more comments in Util.js)
     *
     * @param {Date} date - A UTC date object
     */
    function initializeDatePickerModel(date) {
        FilterItemVM.datePickerModel = {
            endDate: undefined,
        };
        if (date) {
            FilterItemVM.datePickerModel.endDate = U.applyUTCOffsetToDate(date);
        }
    }

    /**
     * Validate numeric input.
     *
     * @param {*} input - Input to be checked. *Should* be a number, but might not be.
     */
    function validateNumber(input) {
        if (isNaN(input)) {
            _.pull($scope.parsedCriteria.criteria, input);
            return;
        }
        $scope.parsedCriteria.criteria = _.map($scope.parsedCriteria.criteria, item => {
            return parseInt(item, 10);
        });
    }

    /**
     * Select filter criteria type.
     *
     * @param {object} model - Filter model
     */
    function selectCriteriaType(model) {
        if ($scope.parsedCriteria.id) {
            filterItemService.resetModel(FilterItemVM.criteria);
        }
        $scope.parsedCriteria.id = model.id;
        $scope.parsedCriteria.logic = model.logicPhrase[0].value;
        if (model.isTextInput) {
            $scope.parsedCriteria.criteria = [];
        }
        else {
            $scope.parsedCriteria.criteria = _.map(_.filter(model.options, 'set'), 'id');
        }
        if (model.isDateInput) {
            initializeDatePickerModel();
        }
        FilterItemVM.criteria = _.cloneDeep(model);
        initializeSelected();
        if (!filterItemService.mutipleCriteriaAllowedForCriteriaId(FilterItemVM.criteria.id)) {
            delete model.isAvailable;
        }
        if ($scope.onChange) {
            $scope.onChange($scope.parsedCriteria);
        }
    }

    /**
     * Select filter-by logic.
     *
     * @param {string} phrase - Selected filter by phrase
     */
    function selectFilterByLogic(phrase) {
        delete FilterItemVM.selectedLogicPhrase.selected;
        phrase.selected = true;
        $scope.parsedCriteria.logic = phrase.value;
        FilterItemVM.selectedLogicPhrase = phrase;
        if ($scope.onChange) {
            $scope.onChange(phrase, $scope.filterModel);
        }
    }

    /**
     * Update selected criteria.
     *
     * @param {object} item - The criteria item to update
     */
    function updateSelectedCriteriaItem(item) {
        if (/^(fe)?male$/.test(item.id)) {
            _.forEach(FilterItemVM.criteria.options, gender => {
                delete gender.set;
            });
            $scope.parsedCriteria.criteria.splice(0, 1, item.id);
            item.set = true;
        }
        else {
            item.set = !item.set;
            if (item.set) {
                $scope.parsedCriteria.criteria.push(item.id);
            }
            else {
                const idx = _.indexOf($scope.parsedCriteria.criteria, item.id);
                $scope.parsedCriteria.criteria.splice(idx, 1);
            }
        }
        FilterItemVM.selectedOptionsPhrase = filterItemService.getSelectedOptionsPhrase(FilterItemVM.criteria);
        if ($scope.onChange) {
            $scope.onChange($scope.parsedCriteria);
        }
        updateSelectAllFlag();
    }

    /**
     * Open the custom variable editor.
     *
     * @param {object} cv - Custom variable object
     * @param {Event} $event - Click event
     */
    function openEditor(cv, $event) {
        cv = cv || {
            name: '',
            description: '',
            detailsJson: {},
        };
        modalService.optionCustomVariableEditor(cv, $event).result.then(updatedCv => {
            if (!updatedCv) {
                return;
            }
            if (!$rootScope.survey._statsConfig.filters.customVariables) {
                $rootScope.survey._statsConfig.filters.customVariables = customVariablesService.emptyCustomVarFilter();
            }
            let convertedCv = customVariablesService.getConvertedStatsConfigObj(updatedCv, 0);
            $rootScope.survey._statsConfig.filters.customVariables.options.push(convertedCv);
            $scope.filterModel.Custom$Variable.options.push(convertedCv);
            pubSubService.notify('custom-variable-filters-updated', []);
        });
    }

    /**
     * Toggle the dropdown.
     */
    function toggleDropdown() {
        $scope.$evalAsync(() => {
            FilterItemVM.ux.dropdownSearchShowing = !FilterItemVM.ux.dropdownSearchShowing;
            if (!FilterItemVM.ux.dropdownSearchShowing) {
                delete FilterItemVM.ux.showSearch;
                delete FilterItemVM.ux.searchQuery;
                updateSelectAllFlag();
            }
        });
    }

    /**
     * Determine whether to show dropdown.
     *
     * @returns {boolean} Whether to show dropdown
     */
    function showDropdown() {
        return !(FilterItemVM.criteria.isNumericInput || FilterItemVM.criteria.isTextInput || FilterItemVM.criteria.isDateInput);
    }

    /**
     * Clear the search bar.
     *
     * @param {Event} $event - The click event
     */
    function clearSearch($event) {
        $event.stopPropagation();
        delete FilterItemVM.ux.searchQuery;
        updateSelectAllFlag();
    }

    /**
     * Toggle the search bar on or off.
     *
     * @param {Event} $event - The click event
     */
    function toggleSearchBar($event) {
        let showSearch = !FilterItemVM.ux.showSearch;
        $event.stopPropagation();
        $scope.$evalAsync(() => {
            FilterItemVM.ux.showSearch = showSearch && FilterItemVM.ux.canShowSearch;
        });
        if (!showSearch || !FilterItemVM.ux.canShowSearch) {
            delete FilterItemVM.ux.searchQuery;
            updateSelectAllFlag();
        }
    }

    /**
     * Update the select all flag.
     */
    function updateSelectAllFlag() {
        let numDisabled = 0,
            numSelected = 0;
        _.forEach(FilterItemVM.criteria.options, option => {
            if (option.set) {
                numSelected++;
            }
            else if (option.disabled) {
                numDisabled++;
            }
        });
        FilterItemVM.sharedUx.selectAll = FilterItemVM.criteria.options.length - numDisabled === numSelected ? 2 : numSelected ? 1 : 0;
    }

    /**
     * Select all items.
     *
     * @param {Event} e - The click event
     */
    function selectAll(e) {
        let list;
        if (e) {
            e.stopPropagation();
        }
        if (FilterItemVM.ux.searchQuery) {
            const regex = new RegExp(FilterItemVM.ux.searchQuery, 'i');
            list = _.filter(FilterItemVM.criteria.options, option => {
                return regex.test(option.name || option.displayNameFull);
            });
        }
        else {
            list = FilterItemVM.criteria.options;
        }
        $scope.parsedCriteria.criteria = [];
        _.forEach(list, item => {
            if (FilterItemVM.sharedUx.selectAll === 2) {
                delete item.set;
            }
            else if (!item.disabled) {
                item.set = true;
                $scope.parsedCriteria.criteria.push(item.id);
            }
        });
        FilterItemVM.sharedUx.selectAll = FilterItemVM.sharedUx.selectAll === 2 ? 0 : 2;
        FilterItemVM.selectedOptionsPhrase = FilterItemVM.sharedUx.selectAll ? 'All selected' : 'None selected';
        if ($scope.onChange) {
            $scope.onChange(void 0, $scope.filterModel);
        }
    }
}
