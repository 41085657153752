import '../common-components/pub-sub';
import '../question-types/question-type-groups';
import '../targeting-attributes/targeting-attribute-groups';
import '../survey-creation/survey-creation-service';
import '../survey-dashboard/survey-dashboard-service';
import '../survey/api-survey-service';

angular.module('targetingBuilder.view', [
    'pubSub',
    'apiSurveyService',
    'questionTypeGroups.service',
    'targetingAttributeGroups.service',
    'surveyDashboardService',
    'surveyCreation.service',
])
    .directive('targetingBuilderSummary', targetingBuilderSummary)
    .directive('targetingBuilder', targetingBuilder)
    .controller('TargetingBuilderCtrl', TargetingBuilderCtrl);

/**
 *
 */
function targetingBuilderSummary() {
    return {
        restrict: 'E',
        scope: {
            requirement: '=',
            activeJson: '=',
        },
        templateUrl: 'survey-creation-templates/target/targeting-builder-summary.html',
    };
}
targetingBuilder.$inject = [
    '$rootScope',
    'surveyService',
    'pubSubService',
];

/**
 * @param $rootScope
 * @param surveyService
 * @param pubSubService
 */
function targetingBuilder($rootScope, surveyService, pubSubService) {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/targeting-builder.html',
        controller: 'TargetingBuilderCtrl',
        controllerAs: 'TargetingBuilderVM',
        scope: {
            activeRequirement: '=',
        },
        link: function(scope, _elem, _attr, TargetingBuilderVM) {
            TargetingBuilderVM.ux = {};
            TargetingBuilderVM.updateDropUp = updateDropUp;

            function updateDropUp() {
                // Display as a "drop-up" if the drop-up menu fits in the window.
                var $dropButton = $('.req-dropdown-btn'),
                    $dropMenu = $('.req-dropdown-menu'),
                    $groupNames = $('.dropdown-group-name'),
                    width = 0;
                TargetingBuilderVM.ux.dropUp = ($dropMenu.height() + 100) < $dropButton.offset().top;

                // Set an explicit width on the dropdown menu group names
                // so that group/attribute names align.
                _.forEach($groupNames, function(e) {
                    var w = $(e).width();
                    width = w > width ? w : width;
                });
                $groupNames.css('min-width', width.toFixed() + 'px');
            }
            TargetingBuilderVM.sortableOptions = {
                tolerance: 'pointer',
                containment: '.screening-question-list',
                disabled: !$rootScope.auth.user.isAdmin,
                start: function(e, ui) {
                    $(e.target).data('ui-sortable').floating = true;
                },
                stop: function(event, ui) {
                    var idx = ui.item.index(),
                        question = $rootScope.survey.screeningQuestions[idx],
                        referenceQuestion;

                    // No-op of only one question in list
                    if ($rootScope.survey.screeningQuestions.length === 1) {
                        return;
                    }

                    if (idx === 0) {
                        referenceQuestion = $rootScope.survey.screeningQuestions[1];
                        pubSubService.notify('update-save-in-progress', [true]);
                        pubSubService.notify('update-save-in-progress', [true]);
                        surveyService.moveQuestionBefore(question.uuid, referenceQuestion.uuid).then(function() {
                            pubSubService.notify('update-save-in-progress', [false]);
                        }, function() {
                            pubSubService.notify('update-save-in-progress', [false]);
                        });
                    }
                    else {
                        referenceQuestion = $rootScope.survey.screeningQuestions[idx - 1];
                        pubSubService.notify('update-save-in-progress', [true]);
                        surveyService.moveQuestionAfter(question.uuid, referenceQuestion.uuid).then(function() {
                            pubSubService.notify('update-save-in-progress', [false]);
                        }, function() {
                            pubSubService.notify('update-save-in-progress', [false]);
                        });
                    }
                },
            };
        },
    };
}
TargetingBuilderCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    'pubSubService',
    'questionTypeGroupsService',
    'targetingAttributeGroups',
    'surveyService',
    'surveyCreationService',
    '$dashboardService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param pubSubService
 * @param QTGS
 * @param TAGS
 * @param surveyService
 * @param surveyCreationService
 * @param $dashboardService
 */
function TargetingBuilderCtrl($rootScope, $scope, $timeout, pubSubService, QTGS, TAGS, surveyService, surveyCreationService, $dashboardService) {
    var TargetingBuilderVM = this;
    TargetingBuilderVM.questionTypeGroups = QTGS.groupList;
    TargetingBuilderVM.questionTypeName = QTGS.questionTypeName;
    TargetingBuilderVM.attributeTypes = TAGS.attributes;
    TargetingBuilderVM.requirementsList = [];
    TargetingBuilderVM.ux = {};
    TargetingBuilderVM.add = add;
    TargetingBuilderVM.addScreener = addScreener;
    TargetingBuilderVM.disableAttribute = disableAttribute;
    TargetingBuilderVM.saveTargetingDetails = surveyCreationService.saveTargetingDetails;
    TargetingBuilderVM.attributeGroups = attributeGroups;
    TargetingBuilderVM.remove = remove;
    TargetingBuilderVM.attributes = attributes;
    TargetingBuilderVM.select = select;
    TargetingBuilderVM.hideEditButtons = hideEditButtons;
    TargetingBuilderVM.editJson = editJson;
    TargetingBuilderVM.isEligibleAttribute = isEligibleAttribute;
    TargetingBuilderVM.isEligibleScreener = isEligibleScreener;
    init();

    function init() {
        pubSubService.subscribe('refresh-targeting-reqs', $scope.$id, refreshTargetingReqs);
        refreshTargetingReqs();
        TargetingBuilderVM.survey = $rootScope.survey;
        TargetingBuilderVM.dashboardView = $rootScope.dashboardView;
        TargetingBuilderVM.auth = $rootScope.auth;
        TargetingBuilderVM.funnelType = $rootScope.funnelType;
        $scope.$on('$destroy', function() {
            pubSubService.destroy('refresh-targeting-reqs', $scope.$id);
        });
    }

    function refreshTargetingReqs() {
        TargetingBuilderVM.requirementsList = surveyCreationService.refreshTargetingReqs();
    }

    // Edit a question's raw json
    /**
     * @param q
     */
    function editJson(q) {
        if (TargetingBuilderVM.ux.saveInProgress) {
            return;
        }
        var json = angular.copy(q);
        json.statement = JSON.stringify(json.statement);
        json.details = JSON.stringify(json.details);
        pubSubService.notify('select-active-json', json);
    }

    // Whether to display the "Add requirements" menu as a drop-up or drop-down.
    /**
     * @param attr
     */
    function add(attr) {
        if (disableAttribute(attr)) {
            return;
        }
        $scope.activeRequirement = {
            attribute: attr.id,
        };
        pubSubService.notify('set-check-errors', [false]);
    }

    /**
     * @param type
     */
    function addScreener(type) {
        $scope.activeRequirement = {
            uuid: null,
            statement: {
                text: '',
                image: null,
            },
            type: type.id,
            screener: true,
            details: angular.copy(type.initialDetails || {}),
        };
        TargetingBuilderVM.ux.checkQuestionErrors = false;

        $timeout(function() {
            $('textarea.question-statement-plain').focus();
        }, 750);
    }

    // Gray out/disable a targeting attribute
    /**
     * @param attr
     */
    function disableAttribute(attr) {
        if (attr.id === 'postalCode') {
            return false;
        }

        var countries = null;
        if ($rootScope.survey && $rootScope.survey.countryReq && $rootScope.survey.countryReq.details.type === 'international') {
            countries = $rootScope.survey.countryReq.details.countries;
        }

        if (attr.id === 'metro' || attr.id === 'region') {
            if (!countries) {
                return false;
            }

            var hasGeoOptions = false;
            _.forEach(attr.options, function(country) {
                if (countries.indexOf(country.code) !== -1 && (country[$rootScope.funnel.id] || $rootScope.auth.user.isAdmin)) {
                    hasGeoOptions = true;
                }
            });
            return !hasGeoOptions;
        }

        return false; // Otherwise, attribute is enabled
    }
    // Attribute groups that are not yet exhausted
    /**
     *
     */
    function attributeGroups() {
        var result = [];
        _.forEach(TAGS.groupList, function(group) {
            var complete = true;
            _.forEach(group.attributeList, function(attribute) {
                complete = complete && (!!surveyCreationService.getReq(attribute.id) || ($scope.activeRequirement && ($scope.activeRequirement.attribute === attribute.id)));
            });
            if (!complete) {
                result.push(group);
            }
        });
        return result;
    }
    // Delete: remove a targeting requirement
    /**
     * @param req
     */
    function remove(req) {
        if (TargetingBuilderVM.ux.saveInProgress) {
            return;
        }

        // Standard targeting attribute
        if (req.attribute) {
            var idx = $rootScope.survey.targetingReqs.indexOf(req);

            if (idx !== -1) {
                surveyService.deleteAudienceChild($rootScope.survey._audienceId, req.attribute).then(function() {
                    $rootScope.survey.targetingReqs.splice(idx, 1);
                    refreshTargetingReqs();
                });
            }

            if ($scope.activeRequirement && ($scope.activeRequirement.attribute === req.attribute)) {
                $scope.activeRequirement = null;
            }
        }
        // Screening question
        else if (req.type) {
            var screener = req.details.question;
            if (req.uuid) {
                surveyService.deleteQuestion(req.uuid).then(function() {
                    var idx = surveyCreationService.getScreenerIdx(req.uuid);
                    if (idx !== -1) {
                        $rootScope.survey.screeningQuestions.splice(idx, 1);
                    }
                });
            }

            if ($scope.activeRequirement && ($scope.activeRequirement.uuid === req.uuid)) {
                $scope.activeRequirement = null;
            }
        }
    }

    // Attributes within a group that are not yet used.
    /**
     * @param attributeList
     */
    function attributes(attributeList) {
        var result = [];
        _.forEach(attributeList, function(e) {
            // Only admins can add more than one screening question
            if (e.id === 'screeningQuestion') {
                if ($rootScope.auth.user && ($rootScope.auth.user.isAdmin || !$rootScope.survey.screeningQuestions.length)) {
                    result.push(e);
                }
            }
            else if (!surveyCreationService.getReq(e.id) && !e.hide && (!$scope.activeRequirement || ($scope.activeRequirement.attribute != e.id))) {
                result.push(e);
            }
        });
        return result;
    }

    // Select a requirement or screener for editing
    /**
     * @param r
     */
    function select(r) {
        if ((!$scope.activeRequirement || r.attribute !== $scope.activeRequirement.attribute || r.id !== $scope.activeRequirement.id) &&
            (!$rootScope.survey.reviewStarted || $rootScope.auth.user.isAdmin)) {
            $scope.activeRequirement = angular.copy(r);
            pubSubService.notify('set-check-errors', [false]);
        }
    }

    /**
     *
     */
    function hideEditButtons() {
        return $dashboardService.hideEditButtons($rootScope.survey, $rootScope.auth.user);
    }

    /**
     * @param group
     * @param attribute
     */
    function isEligibleAttribute(group, attribute) {
        var isAdmin = $rootScope.auth.user.isAdmin;

        return (isAdmin && !attribute.deprecated) || (group[$rootScope.funnel.id] && attribute[$rootScope.funnel.id]);
    }

    /**
     * @param type
     */
    function isEligibleScreener(type) {
        var exceedsMaxScreeners = $rootScope.survey.screeningQuestions && $rootScope.survey.screeningQuestions.length >= $rootScope.funnel.maxScreeners;

        if (!type.screener) {
            return false;
        }
        else if (type.screener.adminOnly || exceedsMaxScreeners) {
            return $rootScope.auth.user.isAdmin;
        }
        return true;
    }
}
