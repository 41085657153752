import './sv-wordcloud-service';
import d3Cloud from 'd3-cloud';
import * as d3 from 'd3-selection';

angular.module('svWordcloud', ['pubSub', 'svWordcloud.sevice'])
    .directive('svWordcloud', svWordcloud);
svWordcloud.$inject = [
    '$timeout',
    '$window',
    'pubSubService',
    'svWordcloudService',
];

function svWordcloud($timeout, $window, pubSubService, svWordcloudService) {
    return {
        restrict: 'EA',
        scope: {
            words: '=',
            numResponses: '=',
        },
        link: function($scope, $element, _$attrs) {
            var chartWidth = parseInt(($(window).innerWidth() - 280) / 2, 10), // (window.innerWidth - 200(sideNav width) - 80 (padding on containers) )/2
                size = [chartWidth, Math.floor(chartWidth / 2.1)],
                translate = 'translate(' + size[0] / 2 + ',' + size[1] / 2 + ')'; /* Not sure why the magic number 2 :( */
            init();

            function init() {
                pubSubService.subscribe('refresh-wordcloud', $scope.$id, buildWordcloud);
                $scope.$on('$destroy', function() {
                    pubSubService.destroy('refresh-wordcloud', $scope.$id);
                });
                angular.element($window).bind('resize', recalculateSVGSize);

                // Resize the word cloud just in case
                $timeout(function() {
                    recalculateSVGSize();
                }, 55);

                buildWordcloud();
            }
            // Specify how to draw the word cloud
            function draw(words) {
                d3.select($element[0])
                    .append('svg')
                    .attr('width', size[0])
                    .attr('height', size[1])
                    .append('g')
                    .attr('transform', translate)
                    .attr('viewBox', size[0] + ' ' + size[1])
                    .selectAll('text')
                    .data(words, function(t) {
                        if (!angular.isDefined(t.text)) {
                            return '';
                        }
                        t.text += '';
                        return t.text.toLowerCase();
                    })
                    .enter().append('text')
                    .style('padding', '3px')
                    .style('font-family', 'Lato, sans-serif')
                    .style('font-size', function(d) {
                        return d.size + 'px';
                    })
                    .style('fill', function(d, i) {
                        return svWordcloudService.COLOR.getRandom();
                    })
                    .attr('text-anchor', 'middle')
                    .attr('transform', function(d, i) {
                        return 'translate(' + [d.x, d.y] + ')rotate(' + svWordcloudService.calcRotation(i, d.rotate) + ')';
                    })
                    .text(function(d) {
                        return d.text;
                    });
            }

            function buildWordcloud() {
                $timeout(function() {
                    var scaledWords = svWordcloudService.constructWords($scope.words, $scope.numResponses);
                    $element.empty();

                    d3Cloud()
                        .size(size)
                        .words(scaledWords.slice(0, 120))
                        .padding(3)
                        .rotate(svWordcloudService.Rotate['20'])
                        .font('Lato')
                        .fontStyle('normal')
                        .fontWeight('100')
                        .fontSize(function(d) {
                            return d.size;
                        })
                        .on('end', draw)
                        .start();
                });
            }

            function recalculateSVGSize() {
                var widthStr = $($element).parent().css('width'),
                    newWidth = (widthStr && widthStr.replace(/[a-z]/g, '')) || '1',
                    newHeight = parseInt(size[1] / size[0] * newWidth, 10),
                    svgEl = $element.find('svg'),
                    g;

                if (!svgEl) {
                    return;
                }

                if (svgEl[0]) {
                    svgEl[0].setAttribute('width', newWidth);
                    svgEl[0].setAttribute('height', newHeight);
                }
                g = svgEl.find('g');
                if (g[0]) {
                    g[0].setAttribute('viewBox', newWidth + ' ' + newHeight);
                    g[0].setAttribute('transform', 'translate(' + Math.floor(newWidth / 2) + ' ' + Math.floor(newHeight / 2) + ') scale(' + newWidth / size[0] + ' ' + newHeight / size[1] + ')');
                }
            }
        },

    };
}
