import './inc/survata-logos';
import './inc/sv-notify';
import './inc/sv-viewlet';
import './inc/sv-breakpoint';
import './zapier-service';
import './pro-signup-ctrl';
    

    angular.module('svCommon', [
        'ngAnimate',
        'ui.sortable',
        'survataLogos',
        'svNotify',
        'svViewlet',
        'svBreakpoint',
        'ZapierModule',
        'ProSignupModule',
    ]);

