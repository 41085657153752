import '@uirouter/angularjs';
import _ from 'lodash';
import '../../../../common/js/inc/sv-notify';
import '../survey-creation/survey-creation-service';
angular.module('dataAnalysisOptions.view', [
    'ui.router',
    'svNotify',
    'surveyCreation.service',
])
    .directive('surveyDataAnalysisOptions', surveyDataAnalysisOptions)
    .controller('DataAnalysisOptionsCtrl', DataAnalysisOptionsCtrl);

/**
 * Data analysis options
 *
 * @returns {object}
 */
function surveyDataAnalysisOptions() {
    return {
        restrict: 'EA',
        templateUrl: 'base-templates/data-analysis.html',
        controller: 'DataAnalysisOptionsCtrl',
        controllerAs: 'DataAnalysisOptionsVM',
    };
}
DataAnalysisOptionsCtrl.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    '$notify',
    'surveyCreationService',
];

/**
 * Main controller
 *
 * @param {object} $rootScope
 * @param {object} $state
 * @param {object} $stateParams
 * @param {object} $notify
 * @param {object} surveyCreationService
 */
function DataAnalysisOptionsCtrl($rootScope, $state, $stateParams, $notify, surveyCreationService) {
    var DataAnalysisOptionsVM = this;

    // VM exposed variables
    DataAnalysisOptionsVM.displayOptions = {
        hideIncludes: true,
        hideTurnaround: true,
    };

    // VM exposed functions
    DataAnalysisOptionsVM.next = next;
    DataAnalysisOptionsVM.back = back;
    DataAnalysisOptionsVM.enableNext = enableNext;
    DataAnalysisOptionsVM.openSaveModal = surveyCreationService.openSaveModal;
    DataAnalysisOptionsVM.onOptOutChanged = onOptOutChanged;

    init();

    function init() {
        let collectionPeriodLines = $rootScope.survey.latestCollectionPeriod && $rootScope.survey.latestCollectionPeriod.collectionPeriodLines;

        // For already selected line items, initialize the UI
        _.forEach(collectionPeriodLines, function(line) {
            if (line) {
                _.forEach(DataAnalysisOptionsVM.dataAnalysisOptions, function(addOn) {
                    if (line.collectionPeriodLineType === addOn.id) {
                        addOn.uuid = line.uuid;
                        addOn.selected = true;
                    }
                });
            }
            else if ($rootScope.auth.user.isAdmin) {
                $notify.error('Received null collection period line. Something is off in the DB');
            }
        });
    }

    /**
     * Save and go to given destination
     *
     * @param {string} destination
     */
    function saveAndGoTo(destination) {
        $state.go(destination, {
            surveyId: $stateParams.surveyId,
            funnelType: $rootScope.funnelType.toLowerCase(),
        });
    }

    function next() {
        saveAndGoTo($rootScope.auth.user && !$rootScope.auth.user.anonymous ? 'confirm' : 'contact_info');
    }

    /**
     * Enable next button
     *
     * @returns {boolean}
     */
    function enableNext() {
        // If an insight add-on is selected, make sure opt-out is deselected
        if (_.some(DataAnalysisOptionsVM.dataAnalysisOptions, {
            selected: true,
        })) {
            DataAnalysisOptionsVM.optOut = false;
            return true;
        }
        return !!DataAnalysisOptionsVM.optOut;
    }

    function onOptOutChanged() {
        // If user selects opt out, deselect insight add-on options
        if (DataAnalysisOptionsVM.optOut) {
            _.forEach(DataAnalysisOptionsVM.dataAnalysisOptions, function(item) {
                item.selected = false;
            });
        }
    }

    // Navigate to the previous page
    function back() {
        saveAndGoTo('sample');
    }
}
