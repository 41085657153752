import '@uirouter/angularjs';
import '../../../common/js/inc/sv-notify';
import '../../../common/js/angular-util';
import U from '../../../common/js/util';
import './survey/api-survey-service';
import './survey-app-common';

angular.module('SurveyInviteApp', [
    'ui.router',
    'angularUtil',
    'svNotify',
    'SurveyAppCommon',
    'apiSurveyService',
])
    .directive('surveyInvite', surveyInvite)
    .controller('SurveyInviteCtrl', SurveyInviteCtrl);

/**
 * Gets a survey invite.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 */
function surveyInvite() {
    return {
        restrict: 'E',
        templateUrl: 'shared-templates/survey-invite.html',
        controller: 'SurveyInviteCtrl',
    };
}
SurveyInviteCtrl.$inject = [
    '$rootScope',
    '$scope',
    'httpDeferred',
    '$timeout',
    '$notify',
    '$state',
    'surveyService',
];

/**
 * Defines the survey invite controller.
 *
 * @param {*} $rootScope
 * @param {*} $scope
 * @param {*} httpDeferred
 * @param {*} $timeout
 * @param {*} $notify
 */
function SurveyInviteCtrl($rootScope, $scope, httpDeferred, $timeout, $notify) {
    $scope.survey = $rootScope.survey;
    $scope.status = '';
    $scope.submitted = false;
    $scope.colleague = {
        name: '',
        email: '',
        comments: '',
    };
    $scope.suggestion = '';
    $scope.invites = undefined;
    $scope.ux = {
        submitting: false,
        showQuestionHider: false,
    };

    $scope.$watch('colleague.email', function() {
        $scope.submitted = false;
        if ($scope.colleague.email && U.validEmail($scope.colleague.email)) {
            // Don't bother to spell-check until it's in the xxx@xxx.xxx format.
            U.spellcheckEmail(
                $scope.colleague.email,
                function(suggestion) {
                    $scope.suggestion = suggestion.full;
                },
                function() {
                    $scope.suggestion = '';
                }
            );
        }
        else {
            $scope.suggestion = '';
        }
    });

    $scope.acceptSuggestion = function() {
        $scope.colleague.email = $scope.suggestion;
        $scope.suggestion = '';
    };

    $scope.sendInvite = function sendInvite(ngFormCtrl, $event) {
        $event.stopPropagation();

        if ($scope.status === 'during') {
            return;
        }

        if (ngFormCtrl.$invalid || !U.validEmail($scope.colleague.email)) {
            $scope.status = 'during';
            $timeout(function() {
                $scope.submitted = true;
                ngFormCtrl.$setPristine();
                $scope.status = '';
            }, 125);
            return;
        }

        $scope.status = 'during';

        var params = {
            name: $scope.colleague.name,
            email: $scope.colleague.email,
            customText: $scope.colleague.comments,
            surveyUuid: $scope.survey.uuid,
        };
        httpDeferred.post(httpDeferred.dashboardHost + '/rest/invite/create', params).then(function(response) {
            if (response.error) {
                $scope.status = 'failed';
                $notify.error('Error sending invitation.');
            }
            else {
                $scope.status = 'success';
                $timeout(function() {
                    $scope.submitted = false;
                    ngFormCtrl.$setPristine();
                    $scope.status = '';
                    $scope.colleague.name = '';
                    $scope.colleague.email = '';
                    $scope.colleague.comments = '';
                    $scope.refreshInvites();
                    $('#colleague-collaboration-modal').modal('hide');
                    $notify.success('Invitation sent succesfully');
                }, 750);
            }
        }, function() {
            $scope.status = 'failed';
        });
    };

    $scope.refreshInvites = function() {
        if (!$scope.dashboard) {
            return;
        }
        httpDeferred.get(httpDeferred.dashboardHost + '/rest/invite/list' + U.buildParamString({
            surveyUuid: $scope.survey.uuid,
        })).then(function(response) {
            if (response && response.length > 0) {
                $scope.invites = response;
            }
        });
    };

    $scope.isQuestionHidden = function() {
        return false;
    };

    $scope.getDisplayName = function(user) {
        if (user.name) {
            return user.name + ' (' + user.email + ')';
        }
        return user.email;
    };

    $scope.refreshInvites();
}
