import metroOptions from './options';

angular.module('metro.summaryView', [])
    .directive('metroSummary', metroSummary)
    .controller('MetroSummaryCtrl', MetroSummaryCtrl);

function metroSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/metro-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'MetroSummaryCtrl',
        controllerAs: 'MetroSummaryVM',
    };
}
MetroSummaryCtrl.$inject = ['$scope'];

function MetroSummaryCtrl($scope) {
    var MetroSummaryVM = this;
    MetroSummaryVM.summary = summary;

    function summary() {
        return _.map($scope.requirement.details || [], function(city) {
            return metroOptions.map[city].name;
        }).join(', ');
    }
}
