export default angular.module('screeningQuestion', [])
    .service('targetingAttributeScreeningQuestion', targetingAttributeScreeningQuestion);

function targetingAttributeScreeningQuestion() {
    var labels = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    function toString(req) {
        var q = req.statement ? req : req.details.question,
            text = '';

        text += (q.statement.text || '') + '\n';
        text += '[screener]\n';
        _.forEach((q.details && q.details.choices) || [], function(e, i) {
            text += '*' + labels[i] + '. ' + e.html + (e.disqualifier ? ' (STOP)' : '') + '\n';
        });
        return text;
    }

    function errors(req) {
        var errs = [];

        // Require a question statement.
        if (!req.details || !req.details.question || !req.details.question.statement) {
            errs.push('Enter a question statement.');
        }

        // Require at least one response choice that allows the respondent to
        // continue the survey.
        var responseChoices = (req.details && req.details.question && req.details.question.details && req.details.question.details.choices) || [],
            passCt = 0,
            failCt = 0;
        _.forEach(responseChoices, function(c) {
            failCt += c.disqualifier ? 1 : 0;
            passCt += c.disqualifier ? 0 : 1;
        });

        if (failCt === 0) {
            errs.push('Mark a choice that fails a respondent.');
        }
        if (passCt === 0) {
            errs.push('Mark a choice that passes a respondent.');
        }

        return errs;
    }
    return {
        id: 'screeningQuestion',
        name: 'Screening question',
        description: 'Filter by response to a single select question',
        toString: toString,
        errors: errors,
    };
}
