/* global base64js */
import ClickmapExportOptions from './clickmap-export-options';
import exportConstants from './export-constants';
import {
    setComputedStyles, convertPixelToFloat, getTranslateValues,
} from './export-service';

const BASE_EXPORT_MARGIN = 25;
const BASE_ELEMENT_MARGIN = BASE_EXPORT_MARGIN / 2 + 10;

class DisplayOptions {
    constructor(scale, yOffset) {
        this.scale = scale;
        this.exportMargin = BASE_EXPORT_MARGIN * scale;
        this.elementMargin = BASE_ELEMENT_MARGIN * scale;
        this.yOffset = yOffset || 0;
    }
}

class ChartExportOptions {
    constructor(elem, scale) {
        let styles = window.getComputedStyle(elem),
            serialized;
        this.chartElem = setComputedStyles(elem, elem.cloneNode(true), scale);
        document.body.append(this.chartElem);
        serialized = (new XMLSerializer()).serializeToString(this.chartElem);
        this.src = 'data:image/svg+xml' + (exportConstants.isFirefox || exportConstants.isSafari ? ',' + encodeURIComponent(serialized) : ';base64,' + base64js.fromByteArray(new TextEncoder().encode(serialized)));
        this.width = elem.clientWidth * scale;
        this.height = elem.clientHeight * scale;
        this.marginLeft = (convertPixelToFloat(styles.marginLeft) + convertPixelToFloat(styles.marginRight)) * scale;
    }
}

class AxisLabelImages {
    constructor(image, xOffset, yOffset, scale) {
        this.width = convertPixelToFloat(image.getAttribute('width')) * scale;
        this.height = convertPixelToFloat(image.getAttribute('height')) * scale;
        this.yOffset = yOffset * scale;
        this.xOffset = xOffset * scale;
        this.src = image.href.animVal;
    }
}

class ChartImageOptions {
    constructor(elem, scale, canvasWidth) {
        const styles = window.getComputedStyle(elem, null);
        if (elem.width * scale > canvasWidth) {
            const adjustedScale = canvasWidth / elem.width;
            this.width = canvasWidth;
            this.height = elem.height * adjustedScale;
        }
        else {
            this.width = elem.width * scale;
            this.height = elem.height * scale;
        }
        this.elementMargin = convertPixelToFloat(styles['margin-left']) * scale;
        this.src = elem.src;
    }
}

class TextOptions {
    constructor(elem, scale) {
        const styles = window.getComputedStyle(elem, null),
            color = styles.color,
            underlineColor = styles.textDecoration.indexOf('underline') > -1 ? color : void 0,
            isItalic = styles.textDecoration.indexOf('italic') > -1,
            isBold = elem.tagName === 'STRONG' || styles.textDecoration.indexOf('bold') > -1,
            fontSize = convertPixelToFloat(styles.fontSize) * scale,
            font = [];
        if (isBold) {
            font.push('bold');
        }
        if (isItalic) {
            font.push('italic');
        }
        font.push(fontSize + 'px');
        font.push('Arial');
        this.text = elem.innerText;
        this.font = font.join(' ');
        this.underlineColor = underlineColor;
        this.lineHeight = convertPixelToFloat(styles.lineHeight) * scale;
        this.color = color;
    }
}

class TextExportOptions {
    constructor(elem, scale) {
        const textArray = elem.querySelectorAll('p');
        let textOptions;
        this.text = [];
        this.alignment = window.getComputedStyle(elem, null).textAlign;
        this.yOffset = 0;
        if (textArray.length) {
            _.forEach(textArray, child => {
                let maxLineHeight = 0;
                const segments = child.querySelectorAll('span');
                if (segments.length) {
                    const line = [];
                    _.forEach(segments, segment => {
                        textOptions = new TextOptions(segment, scale);
                        maxLineHeight = Math.max(maxLineHeight, textOptions.lineHeight);
                        line.push(textOptions);
                    });
                    this.text.push(line);
                }
                else {
                    textOptions = new TextOptions(child, scale);
                    maxLineHeight = textOptions.lineHeight;
                    this.text.push([textOptions]);
                }
                this.yOffset += maxLineHeight;
            });
        }
        else {
            textOptions = new TextOptions(elem, scale);
            this.yOffset += textOptions.lineHeight;
            this.text.push([textOptions]);
        }
    }
}

export default class PngExportOptions {
    constructor(elem, params) {
        if (!elem || !params.canvas) {
            console.error((elem ? 'Canvas' : 'Element') + ' is required');
            return;
        }
        let titles = elem.querySelectorAll('.chart__title'),
            chartImage = elem.querySelector('.chart__image'),
            scale = params.scale || 1;
        this.type = 'png';
        this.canvas = params.canvas;
        this.displayOptions = new DisplayOptions(scale, params.yOffset);
        this.title = new TextExportOptions(titles[0], scale);
        this.displayOptions.yOffset += this.title.yOffset;

        if (chartImage) {
            if (chartImage.firstElementChild.tagName === 'IMG') {
                this.chartImage = new ChartImageOptions(chartImage.firstElementChild, scale, this.canvas.width);
            }
            else {
                this.clickmap = _.map(chartImage.querySelectorAll('.clickmap-chart'), elem => {
                    return new ClickmapExportOptions(elem, scale, this.canvas.width);
                });
            }
        }
        if (!params.isGridWrapper) {
            let chartDescription = elem.querySelector('.chart__text-area'),
                footerText = elem.querySelector('.chart-text'),
                chart = elem.querySelector('.chart.plottable');
            if (titles.length > 1) {
                this.subtitle = new TextExportOptions(titles[params.row + 1], scale);
            }
            if (chartDescription) {
                this.chartDescription = new TextExportOptions(chartDescription, scale);
            }
            if (chart) {
                let chart = elem.querySelector('.chart.plottable'),
                    chartOptions = new ChartExportOptions(chart, scale),
                    axisLabelImages = chart.querySelectorAll('image');
                this.chart = chartOptions;
                if (axisLabelImages.length) {
                    let xAxis = chart.querySelector('.component.axis.x-axis.category-axis'),
                        tickLabelContainer = xAxis.querySelector('.tick-label-container'),
                        tickLabelContainers = tickLabelContainer.querySelectorAll('.tick-label.tick-label--has-image'),
                        xOffset = convertPixelToFloat(xAxis.style.left);

                    this.axisLabelImages = _.map(axisLabelImages, (image, idx) => {
                        const transform = getTranslateValues(tickLabelContainers[idx]),
                            tickLabelOffset = transform.x + convertPixelToFloat(image.getAttribute('x')),
                            yOffset = transform.y + convertPixelToFloat(xAxis.style.top);

                        return new AxisLabelImages(image, xOffset + tickLabelOffset, yOffset, scale);
                    });
                }
            }
            if (footerText) {
                this.footerText = new TextExportOptions(footerText, scale);
            }
        }
    }
}
