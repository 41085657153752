angular.module('postalCode', [])
    .service('targetingAttributePostalCode', targetingAttributePostalCode);
targetingAttributePostalCode.$inject = ['$rootScope'];

/**
 * @param $rootScope
 */
function targetingAttributePostalCode($rootScope) {
    /**
     * @param req
     */
    function toString(req) {
        return '[' + req.details.join(', ') + '] ';
    }

    /**
     * @param req
     */
    function errors(req) {
        var isAdmin = !!$rootScope.auth.user.isAdmin,
            errs = [],
            length = (req.details && req.details.length) || 0,
            minSelected = 100;

        // Require at least 100 zip codes
        if (!isAdmin && length < minSelected) {
            errs.push('Not enough selected. Select at least 100.');
        }

        return errs;
    }

    return {
        id: 'postalCode',
        name: 'Postal Code (Zip)',
        description: 'Filter by the respondent\'s postal code (zip).',
        basic: true,
        extended: true,
        advanced: true,
        toString: toString,
        errors: errors,
    };
}
