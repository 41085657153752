/* eslint compat/compat: "off" */
import {
    createDocumentElement,
} from './export-service';
import imageExportService from './image-export-service';

const svgExport = {
    export: _export,
};

function _export(params) {
    return new Promise(function(resolve, reject) {
        // Draw title
        let deferred = [],
            textNode = createTextNode(params.title, 0, params.displayOptions.yOffset);
        params.displayOptions.yOffset += params.title.height + params.displayOptions.elementMargin;
        params.svg.appendChild(textNode);
        // Draw subtitle
        if (params.subtitle) {
            textNode = createTextNode(params.subtitle, 0, params.displayOptions.yOffset);
            params.displayOptions.yOffset += params.subtitle.height + params.displayOptions.elementMargin;
            params.svg.appendChild(textNode);
        }
        // Draw title image
        if (params.chartImage) {
            let image = createDocumentElement('image', {
                width: params.chartImage.width,
                height: params.chartImage.height,
                x: getCanvasMedian(params.svg, params.chartImage.width),
                y: params.displayOptions.yOffset,
            });
            image.setAttribute('href', params.chartImage.src);
            params.svg.appendChild(image);
            params.displayOptions.yOffset += params.chartImage.height + params.displayOptions.elementMargin;
        }
        // Draw clickmap
        else if (params.clickmap) {
            deferred.push(drawClickMap(params));
        }
        // Draw chart
        if (params.svgChart) {
            params.svgChart.setAttribute('y', params.displayOptions.yOffset);
            params.svg.appendChild(params.svgChart);
            params.displayOptions.yOffset += parseFloat(params.svgChart.getAttribute('height'));
        }

        // Draw ranking/rating
        if (params.footerText) {
            params.svg.appendChild(createTextNode(params.footerText, 0, params.displayOptions.yOffset));
        }

        Promise.all(deferred).then(() => {
            resolve(params.svg);
        }, () => {
            reject(params.svg);
        });
    });
}

function drawClickMap(params) {
    return new Promise(resolve => {
        let numDeferred = 0;
        _.forEach(params.clickmap, clickmap => {
            const image = createDocumentElement('image', {
                    width: clickmap.background.width,
                    height: clickmap.background.height,
                    x: getCanvasMedian(params.svg, clickmap.width),
                    y: params.displayOptions.yOffset,
                }),
                canvas = createDocumentElement('canvas', {
                    width: clickmap.background.width,
                    height: clickmap.background.height,
                });
            imageExportService.exportClickmap(canvas, clickmap, 0, 0).then(() => {
                image.setAttribute('href', canvas.toDataURL());
                if (++numDeferred === params.clickmap.length) {
                    resolve();
                }
            }, () => {
                if (++numDeferred === params.clickmap.length) {
                    resolve();
                }
            });
            params.svg.appendChild(image);
            params.displayOptions.yOffset += clickmap.height + params.displayOptions.elementMargin;
        });
    });
}

function getCanvasMedian(canvas, width) {
    let canvasWidth = canvas.width.baseVal.value;
    return (canvasWidth - width) / 2;
}

function createTextNode(text, x, y) {
    let yOffset = y + text.height / 2,
        textNode = createDocumentElement('text', {
            x: x,
            y: yOffset,
        });
    _.forEach(text.lines, segment => {
        let textLine = createDocumentElement('tspan', {
            x: segment.textAnchor === 'middle' ? segment.width / 2 : 0,
            y: yOffset + segment.height / 2,
        });
        textLine.innerText = segment.text;
        textLine.innerHTML = textLine.innerHTML.replace(/&[a-zA-Z]+;/g, ' ');
        textLine.setAttribute('fill', segment.color);
        textLine.setAttribute('font-weight', segment.fontWeight);
        textLine.setAttribute('font-size', segment.fontSize);
        textLine.setAttribute('font-family', 'Arial');
        textLine.setAttribute('text-anchor', segment.textAnchor);
        if (segment.underline || segment.italic) {
            textLine.setAttribute('font-style', segment.underline ? 'underline' : 'italic');
        }
        yOffset += segment.height;
        textNode.appendChild(textLine);
    });
    return textNode;
}

export default svgExport;
