import _ from 'lodash';
import FilterLogicModel from '../../filter-logic/filter-logic-model';
import getLogicPhraseById from '../../filter-logic/filter-logic-phrases';
import filterLogicConstants from '../../filter-logic/filter-logic-constants';
import adMeasurementService from '../ad-measurement.service';
import frequencyOptions from './frequency-options';

const beaconFields = {
    ControlBeacon$: 'Control beacon',
    ControlBeaconPool$: 'Control beacon pool',
    ControlFor$: 'Control for exposed beacon pool',
    Beacon$: 'Beacon',
};

export default class AnalysisConfigModel {
    constructor(survey, isUsRegion = true) {
        let statsConfig = survey._statsConfig;
        this.ImpressionParameterGroups = {
            id: filterLogicConstants.criteriaTypes.IMPRESSION_PARAMETER_GROUPS,
            name: 'Impression parameter groups',
            hasSubmenu: true,
            isAvailable: true,
            options: adMeasurementService.setImpressionParameterGroups(statsConfig && statsConfig.impressionParameterGroups),
        };
        this[filterLogicConstants.criteriaTypes.ROUNDED_DAYS_SINCE_LAST_EXPOSURE] = {
            id: filterLogicConstants.criteriaTypes.ROUNDED_DAYS_SINCE_LAST_EXPOSURE,
            name: 'Rounded days since last exposure',
            isAvailable: true,
            logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.ROUNDED_DAYS_SINCE_LAST_EXPOSURE),
            isNumericInput: true,
        };
        this[filterLogicConstants.criteriaTypes.SURVEY_COMPLETION_DATE] = {
            id: filterLogicConstants.criteriaTypes.SURVEY_COMPLETION_DATE,
            name: 'Survey completion date',
            isAvailable: true,
            logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.SURVEY_COMPLETION_DATE),
            isDateInput: true,
        };
        this.Frequency = {
            id: filterLogicConstants.criteriaTypes.FREQUENCY,
            name: 'Frequency',
            hasSubmenu: true,
            isAvailable: true,
            options: [{
                name: 'Numeric frequency',
                id: 'Frequency',
                isAvailable: true,
                isNumericInput: true,
                logicPhrase: getLogicPhraseById('Frequency'),
            }, {
                name: 'Week day frequency',
                id: 'Frequency_',
                isAvailable: true,
                logicPhrase: getLogicPhraseById('Frequency_'),
                options: frequencyOptions,
            }],
        };
        if (isUsRegion) {
            this['Urbanicity (based on Postal Code)'] = {
                id: 'Urbanicity (based on Postal Code)',
                name: 'Urbanicity',
                logicPhrase: getLogicPhraseById('Urbanicity'),
                isAvailable: true,
                options: [{
                    name: 'Rural',
                    id: 'Rural',
                },
                {
                    name: 'Small Town',
                    id: 'Small Town',
                },
                {
                    name: 'Large City',
                    id: 'Large City',
                },
                {
                    name: 'No Data',
                    id: 'Not Available',
                }],
            };

            this['Median Income Range of Postal Code'] = {
                id: 'Median Income Range of Postal Code',
                name: 'Median income range of postal code',
                logicPhrase: getLogicPhraseById('MedianIncomeRangeOfPostalCode'),
                isAvailable: true,
                options: [{
                    name: '$1 - 25,000',
                    id: '$1 - 25,000',
                },
                {
                    name: '$25,001 - $50,000',
                    id: '$25,001 - $50,000',
                },
                {
                    name: '$50,001 - $75,000',
                    id: '$50,001 - $75,000',
                },
                {
                    name: '$75,001 - $100,000',
                    id: '$75,001 - $100,000',
                },
                {
                    name: '$100,000+',
                    id: '$100,000+',
                },
                {
                    name: 'No Data',
                    id: 'Not Available',
                }],
            };
        }
        this.BeaconFrequency$ = {
            id: 'BeaconFrequency$',
            name: 'Beacon frequency',
            isAvailable: true,
            isNumericInput: true,
            hasSubmenu: true,
            options: _.map(statsConfig.BeaconFrequency$, option => {
                option.isAvailable = true;
                option.isNumericInput = true;
                option.logicPhrase = getLogicPhraseById('BeaconFrequency$');
                return option;
            }),
        };

        _.forEach(beaconFields, (name, field) => {
            this[field] = {
                id: field,
                name,
                logicPhrase: getLogicPhraseById(field),
                isAvailable: true,
                options: statsConfig[field],
            };
        });

        _.assignIn(this, new FilterLogicModel(survey, true, isUsRegion));

        // Set the question and choice ids to the analysis plan id
        _.forEach(this.Question$Choice.options, question => {
            question.id = question.analysisPlanId;
            _.forEach(question.options, option => {
                option.id = option.analysisPlanId;
            });
        });
    }
}

