angular.module('postalCode.editorView', [])
    .directive('postalCodeEditor', postalCodeEditor)
    .controller('PostalCodeEditorCtrl', PostalCodeEditorCtrl);

function postalCodeEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/postal-code-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, PostalCodeEditorVM) {
            scope.$watch('requirement.details', updatePostListFromReqDetailsArray, true);
            scope.$watch('PostalCodeEditorVM.postList', updateReqDetailsArrayFromPostList, true);
            updatePostListFromReqDetailsArray();

            /* Sync details from req details object to local scope */
            function updatePostListFromReqDetailsArray() {
                PostalCodeEditorVM.postList = scope.requirement.details.join('\n');
            }

            /* Sync changes from local scope to req details object */
            function updateReqDetailsArrayFromPostList() {
                scope.requirement.details = PostalCodeEditorVM.postList.split('\n');
            }
        },
        controller: 'PostalCodeEditorCtrl',
        controllerAs: 'PostalCodeEditorVM',
    };
}
PostalCodeEditorCtrl.$inject = ['$scope'];

function PostalCodeEditorCtrl($scope) {
    var PostalCodeEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    PostalCodeEditorVM.postList = '';
}
