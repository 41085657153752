import metroOptions from './options';

angular.module('metro.editorView', [])
    .directive('metroEditor', metroEditor)
    .controller('MetroEditorCtrl', MetroEditorCtrl);
metroEditor.$inject = ['$timeout'];

function metroEditor($timeout) {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/metro-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, MetroEditorVM) {
            var updateCount = U.debounce(function() {
                $timeout(function() {
                    MetroEditorVM.selectionsCount = scope.requirement.details.length;
                }, 0);
            }, 600);
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('MetroEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);
            updateCount();
            updateOptionsObjectFromReqDetailsArray();

            /* Sync details from req details object to local scope */
            function updateOptionsObjectFromReqDetailsArray() {
                angular.forEach(MetroEditorVM.options, function(country) {
                    angular.forEach(country.cities, function(e) {
                        e.selected = !!scope.requirement.details && (scope.requirement.details.indexOf(e.id) !== -1);
                    });
                });
            }

            /* Sync changes from local scope to req details object */
            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];
                for (var i = 0; i < MetroEditorVM.options.length; i++) {
                    var country = MetroEditorVM.options[i];
                    for (var j = 0; j < country.cities.length; j++) {
                        var e = country.cities[j];
                        if (e.selected) {
                            x.push(e.id);
                        }
                    }
                }
                scope.requirement.details = x;
                updateCount();
            }
        },
        controller: 'MetroEditorCtrl',
        controllerAs: 'MetroEditorVM',
    };
}
MetroEditorCtrl.$inject = [
    '$rootScope', '$scope',
];

function MetroEditorCtrl($rootScope, $scope) {
    var MetroEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    $scope.auth = $rootScope.auth;
    $scope.funnelType = $rootScope.funnelType;
    MetroEditorVM.selectionsCount = 0;
    MetroEditorVM.options = angular.copy(metroOptions.list);
    MetroEditorVM.setAll = setAll;
    MetroEditorVM.showMetros = showMetros;

    function setAll(list, val) {
        angular.forEach(list, function(e) {
            e.selected = val;
        });
    }

    function showMetros(countryCode) {
        var countries = null;

        angular.forEach($rootScope.survey.targetingReqs, function(req) {
            if (req.attribute === 'country' && req.details.type === 'international') {
                countries = req.details.countries;
            }
        });
        return countries !== null ? countries.indexOf(countryCode) !== -1 : countryCode === 'US';
    }
}
