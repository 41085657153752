const surveyCreationConstant = new Map([
    ['TAG_MAP', {
        trap: {
            icon: 'fas fa-ban',
            text: 'Trap question',
        },
        screener: {
            icon: 'fas fa-ban',
            text: 'Screener',
        },
        skip: {
            icon: 'far fa-code-fork',
            text: 'Skip logic',
        },
        suppress: {
            icon: 'fas fa-lock',
            text: 'Supression logic',
        },
        release: {
            icon: 'fas fa-unlock-alt',
            text: 'Release logic',
        },
        piping: {
            icon: 'far fa-share-square',
            text: 'Piping logic',
        },
        randomized: {
            icon: 'far fa-random',
            text: 'Order randomized',
        },
    }],
    ['REORDER_MSG', 'Reorder questions'],
    ['EDIT_MSG', 'Return to editing'],
    ['ERROR_MESSAGES', {
        SAVE_QUESTION_ERROR: 'There was an error saving your question. Please try<br>again and contact us if you continue to have issues.',
        COPY_QUESTION_ERROR: 'There was an error copying your question. Please try again and contact us if you continue to have issues.',
        VIDEO_TYPE_UNSUPPORTED: 'Video type not supported.',
    }],
]);

export default surveyCreationConstant;
