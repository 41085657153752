import './analysis-plan.view';
import './analysis-plan-overview.view';
import './cuts/anlaysis-cuts-module';
import './answer-coding/answer-coding-module';
import './target-audiences/target-audiences-module';
import './overview/analysis-plan-settings.view';
import './overview/analysis-plan-review.view';

angular.module('analysisPlan', [
    'ui.router',
    'analysisPlan.view',
    'analysisPlanOverview.view',
    'analysisPlan.cuts',
    'analysisPlan.answerCoding',
    'analysisPlan.targetAudiences',

    // Other modules
    'analysisPlanSettings.view',
    'analysisPlanReview.view',
]).config([
    '$stateProvider', $stateProvider => {
        const states = {
            analysisPlanOverview: {
                url: '#analysis-plan-overview',
                template: '<div analysis-plan-overview></div>',
                parent: 'analysisPlan',
            },
            analysisPlanCuts: {
                url: '#analysis-plan-cuts',
                template: '<div analysis-plan-cuts></div>',
                parent: 'analysisPlan',
            },
            answerCoding: {
                url: '#answer-coding',
                template: '<div answer-coding></div>',
                parent: 'analysisPlan',
            },
            targetAudiences: {
                url: '#target-audiences',
                template: '<div target-audiences></div>',
                parent: 'analysisPlan',
            },
        };
        _.forEach(states, (state, stateName) => {
            $stateProvider.state(stateName, state);
        });
    }]);
