import filterLogicConstants from '../filter-logic/filter-logic-constants';
var SCREENER_TXT = 'Screener';
/**
 * @name parseQuestionChoiceString
 * @param {string} choiceString - In the format (Question|Screener)$X|Line$Y|Choice$Z
 *
 * @description
 * Return an object that contains questionId: Question$X or Screener$X,
 * screener: boolean, questionIndex: number, choiceIndex: number,
 * grindLineIndex: number.
 */
function parseQuestionChoiceString(choiceString) {
    var parts = choiceString.match(/^((Question|Screener)\$(\d+)(?:\|Line\$(\d+))?)\|(?:Choice\$(\d+))$/);

    return {
        questionId: parts[1],
        screener: parts[2] === SCREENER_TXT,
        questionIndex: parts[3],
        choiceIndex: parts[5],
        gridLineIndex: parts[4],
    };
}

// Given the criteria info, return the appropriate text to show in the selection options drowndown
/**
 * @name getSelectedOptionsPhrase
 * @param {object} criteria - The criteria for which to get the options phrase.
 * @param {object[]} itemsSelected - The selected items.
 *
 * @description
 * If only one item is selected, display the selected item's name. Otherwise,
 * display the number of selected items, or 'All' if it equals the total selectable
 * options.
 */
function getSelectedOptionsPhrase(criteria) {
    if (criteria.isNumericInput || criteria.isDateInput) {
        return;
    }
    const itemsSelected = _.filter(criteria.options, 'set');
    if (criteria.id === filterLogicConstants.criteriaTypes.GENDER) {
        return itemsSelected[0] ? itemsSelected[0].name : 'Either';
    }
    const numAvailableOptions = criteria.options.length - _.filter(criteria.options, 'disabled').length;
    if (itemsSelected.length === 1) {
        return itemsSelected[0].displayName || itemsSelected[0].name;
    }
    if (itemsSelected.length === numAvailableOptions) {
        return 'All selected';
    }
    if (itemsSelected.length < 1) {
        return 'None selected';
    }
    return `${itemsSelected.length} selected`;
}

/**
 * @param model
 */
function resetModel(model) {
    if (!model) {
        return;
    }
    _.forEach(model.options, option => {
        option.set = model.id === 'Demographic$Age' ? !option.disabled : model.id === 'Beacon$Pool';
    });
    _.forEach(model.logicPhrase, phrase => {
        delete phrase.selected;
    });
    model.isAvailable = true;
}

/**
 * @param criteriaId
 */
function mutipleCriteriaAllowedForCriteriaId(criteriaId) {
    return _.includes([filterLogicConstants.criteriaTypes.FREQUENCY,
        filterLogicConstants.criteriaTypes.SURVEY_COMPLETION_DATE,
        filterLogicConstants.criteriaTypes.QUESTION,
        filterLogicConstants.criteriaTypes.BEACON_POOL], criteriaId);
}

const filterItemService = {
    getSelectedOptionsPhrase,
    parseQuestionChoiceString,
    resetModel,
    mutipleCriteriaAllowedForCriteriaId,
};

export default filterItemService;
