import _ from 'lodash';
var list = [{
        id: 'male',
        name: 'Male',
        icon: 'fas fa-male',
    }, {
        id: 'female',
        name: 'Female',
        icon: 'fas fa-female',
    }],
    map = {};
_.forEach(list, function(e) {
    map[e.id] = e;
});

angular.module('gender.options', [])
    .constant('genderOptions', {
        list: list,
        map: map,
    });
