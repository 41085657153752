import analysisConfigConstants from '../analysis-config-constants';

const ANALYSIS_USAGE_DEFAULT_INDEX = 0;
const targetAudiencesConstants = {
    tableHeaders: [{
        name: 'Target audience name',
        sortable: true,
        tooltip: true,
        columnType: 'text',
        sortField: 'name',
        value: 'name',
    },
    {
        columnType: 'action',
        columnClass: 'text-right',
        actionName: '<i class="fas fa-edit fa-fw"></i> Edit',
        eventName: 'open-target-audience-editor',
    },
    {
        columnType: 'action',
        columnClass: 'text-left',
        cellClass: 'btn-sv--circle',
        actionName: '<i class="fas fa-trash-alt fa-fw"></i>',
        eventName: 'delete-target-audience',
    }],
    tableOptions: {
        noSearch: true,
    },
    newFactorModel: {
        name: analysisConfigConstants.TARGET_AUDIENCE_NAME,
        _factorType: analysisConfigConstants.MARKET_RESEARCH,
        analysisUsage: analysisConfigConstants.analysisUsages[ANALYSIS_USAGE_DEFAULT_INDEX],
    },
    maxTargetAudiences: 10,
};

export default targetAudiencesConstants;
