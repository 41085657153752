const frequencyOptions = [{
    name: 'Sunday',
    id: 'Frequency_Sunday',
},
{
    name: 'Monday',
    id: 'Frequency_Monday',
},
{
    name: 'Tuesday',
    id: 'Frequency_Tuesday',
},
{
    name: 'Wednesday',
    id: 'Frequency_Wednesday',
},
{
    name: 'Thursday',
    id: 'Frequency_Thursday',
},
{
    name: 'Friday',
    id: 'Frequency_Friday',
},
{
    name: 'Saturday',
    id: 'Frequency_Saturday',
}];

export default frequencyOptions;
