var ccTypeToIcon = function(type) {
    return (type || '').replace(/ /, '-').toLowerCase();
};

angular.module('svCcSummary', [])
    .directive('svCcSummary', [function() {
        return {
            restrict: 'CAE',
            template: '' +
                '<div>' +
                '<div class="cc-icon {{ccIconClass()}}">' +
                '{{cardType()}}' +
                '</div>' +
                '<div class="va-middle display-inline-block padding-left-sm">' +
                '** {{card.lastFour}} expires {{card.month}}/{{card.year}}' +
                '</div>' +
                '</div>',
            scope: {
                card: '=',
            },
            link: function(scope, element, attrs) {
                scope.ccIconClass = function() {
                    return 'cc-icon-' + ccTypeToIcon(scope.card && scope.card.type);
                };

                scope.cardType = function() {
                    return scope.card.type === 'American Express' ? 'Amex' : scope.card.type;
                };
            },
        };
    }]);
