import analysisConfigDataService from './analysis-config-data-service';

let kpiTypes,
    confounderTypes,
    objectivePriorities,
    metroAreas;

/**
 * Get KPI types (from server or cached locally)
 *
 * @returns {Promise}
 */
function getKpiTypes() {
    return new Promise((resolve, reject) => {
        if (kpiTypes) {
            resolve(kpiTypes);
        }
        else {
            analysisConfigDataService.getKpiTypes().then(types => {
                kpiTypes = types;
                resolve(kpiTypes);
            },
            () => {
                reject(new Error('Error fetching KPI Types from server.'));
            });
        }
    });
}

/**
 * Get confounder types (from server or cached locally)
 *
 * @returns {Promise}
 */
function getConfounderTypes() {
    return new Promise((resolve, reject) => {
        if (confounderTypes) {
            resolve(confounderTypes);
        }
        else {
            analysisConfigDataService.getConfounderTypes().then(types => {
                confounderTypes = types;
                resolve(confounderTypes);
            },
            () => {
                reject(new Error('Error fetching Confounder Types from server.'));
            });
        }
    });
}

/**
 * Get the objectivePriorities for the objectives dropdown
 *
 * @returns {Promise}
 */
function getObjectivePriorities() {
    if (!objectivePriorities) {
        objectivePriorities = analysisConfigDataService.getObjectivePriorities();
    }
    return objectivePriorities;
}

/**
 * Get metro areas (from server or cached locally)
 *
 * @returns {Promise}
 */
function getMetroAreas() {
    return new Promise((resolve, reject) => {
        if (metroAreas) {
            resolve(metroAreas);
        }
        else {
            analysisConfigDataService.getMetroAreas().then(areas => {
                metroAreas = areas;
                resolve(metroAreas);
            },
            () => {
                reject(new Error('Error fetching metro areas from server.'));
            });
        }
    });
}

const analysisConfigServerConstantsService = {
    getKpiTypes: getKpiTypes,
    getConfounderTypes: getConfounderTypes,
    getMetroAreas: getMetroAreas,
    getObjectivePriorities: getObjectivePriorities,
};

export default analysisConfigServerConstantsService;
