import ageOptions from './options';

angular.module('age.summaryView', [])
    .directive('ageSummary', ageSummary)
    .controller('AgeSummaryCtrl', AgeSummaryCtrl);

function ageSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/age-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'AgeSummaryCtrl',
        controllerAs: 'AgeSummaryVM',
    };
}
AgeSummaryCtrl.$inject = ['$scope'];

function AgeSummaryCtrl($scope) {
    var AgeSummaryVM = this;
    AgeSummaryVM.options = ageOptions.list;
    AgeSummaryVM.selected = selected;

    function selected(option) {
        return $scope.requirement && $scope.requirement.details && ($scope.requirement.details.indexOf(option.id) !== -1);
    }
}
