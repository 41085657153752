import './question-dropdown-view';
import './questions-editor-view';
import './survey-builder-view';
import './survey-contact-info-ctrl';
import './survey-creation-service';
import './survey-creation-ctrl';
import './survey-design-ctrl';
import './survey-quote-ctrl';
import './survey-sample-ctrl';
import './survey-target-ctrl';
import './survey-thank-you-ctrl';
import './targeting-builder-view';
import './targeting-editor-view';
import './data-analysis-options.view';

angular.module('surveyCreationModule', [
    'surveyCreation.service',
    'surveyCreation.ctrl',
    'targetingEditor.view',
    'targetingBuilder.view',
    'surveyTarget.ctrl',
    'questionDropdown.view',
    'questionsEditor.view',
    'surveyBuilder.view',
    'questionsEditor.view',
    'surveyCreation.ctrl',
    'surveyContactInfo.ctrl',
    'surveyCreation.ctrl',
    'surveyDesign.ctrl',
    'surveyQuote.ctrl',
    'surveySample.ctrl',
    'surveyTarget.ctrl',
    'surveyThankYou.ctrl',
    'dataAnalysisOptions.view',
]);
