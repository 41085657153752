angular.module('checkboxIndicator', [])
    .directive('checkboxIndicator', checkboxIndicator)
    .controller('CheckboxIndicatorCtrl', CheckboxIndicatorCtrl);

const selectionTypes = {
    NONE: 0,
    SOME: 1,
    ALL: 2,
};

/**
 * Checkbox indicator directive.
 *
 * @returns {object} The checkbox indicator directive contents
 */
function checkboxIndicator() {
    return {
        restrict: 'EA',
        templateUrl: '/shared-templates/checkbox-indicator.html',
        scope: {
            selectionStatus: '=',
        },
        controller: 'CheckboxIndicatorCtrl',
        controllerAs: 'CheckboxIndicatorVM',
    };
}

CheckboxIndicatorCtrl.$inject = [
    '$scope',
];

/**
 * The checkbox indicator controller.
 *
 * @param {object} $scope
 */
function CheckboxIndicatorCtrl($scope) {
    var CheckboxIndicatorVM = this;
    CheckboxIndicatorVM.selectionTypes = selectionTypes;
}

export default selectionTypes;
