import '@uirouter/angularjs';
import '../app-components/api-account-service';
import '../survey-creation/survey-creation-service';
import authService from './auth-service';
import U from '../../../../common/js/util';

angular.module('signUp.ctrl', [
    'ui.router',
    'apiAccountService',
    'surveyCreation.service',
])
    .controller('SignUpController', SignUpController);
SignUpController.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    'accountService',
    '$location',
    '$state',
    'surveyCreationService',
];

/**
 * Controller definition
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $timeout
 * @param {object} accountService
 * @param {object} $location
 * @param {object} $state
 * @param {object} surveyCreationService
 */
function SignUpController($rootScope, $scope, $timeout, accountService, $location, $state, surveyCreationService) {
    $scope.status = '';
    $scope.submitted = false;
    $scope.suggestion = '';
    $scope.userInfo = {
        email: authService.get('email') || '',
        password: authService.get('password') || '',
        name: authService.get('name') || '',
        company: '',
        phone: '',
    };

    $scope.$watch('userInfo.email', function() {
        authService.sync('email', $scope.userInfo.email);

        if ($scope.userInfo.email && U.validEmail($scope.userInfo.email)) {
            // Don't bother to spell-check until it's in the xxx@xxx.xxx format.
            U.spellcheckEmail(
                $scope.userInfo.email,
                function(suggestion) {
                    $scope.suggestion = suggestion.full;
                },
                function() {
                    $scope.suggestion = '';
                }
            );
        }
        else {
            $scope.suggestion = '';
        }
    });
    $scope.$watch('userInfo.password', function() {
        authService.sync('password', $scope.userInfo.password);
    });
    $scope.$watch('userInfo.name', function() {
        authService.sync('name', $scope.userInfo.name);
    });

    $scope.acceptSuggestion = acceptSuggestion;
    $scope.signUp = signUp;
    $scope.signIn = signIn;

    function acceptSuggestion() {
        $scope.userInfo.email = $scope.suggestion;
        $scope.suggestion = '';
    }

    /**
     * Signup
     *
     * @param {object} ngFormCtrl
     */
    function signUp(ngFormCtrl) {
        if ($scope.status === 'during') {
            return;
        }

        // Check validity of form input
        if (ngFormCtrl.$invalid) {
            $scope.status = 'during';
            $timeout(function() {
                $scope.submitted = true;
                ngFormCtrl.$setPristine();
                $scope.status = '';
            }, 125);
            return;
        }

        // Check validity of email address (with more stringent criteria)
        if (!U.validEmail($scope.userInfo.email)) {
            $scope.status = 'error';
            ngFormCtrl.$setPristine();
            return;
        }

        var name = U.splitName($scope.userInfo.name);

        $scope.status = 'during';

        accountService.signUp($scope.userInfo.email, $scope.userInfo.password, name.first || '', name.last || '', $scope.userInfo.phone || '', $scope.userInfo.company || '')
            .then(function() {
                $scope.status = 'success';
                U.fireAccountPixels();
                $timeout(function() {
                    $scope.ux.accountView = null;
                    authService.reset();
                    surveyCreationService.closeSaveModal();
                }, 250);
            },
            function() {
                $scope.status = 'error';
                ngFormCtrl.$setPristine();
            });
    }

    function signIn() {
        $scope.ux.accountView = 'signIn';
        $state.go('signIn');
    }
}
