import '../../../../../../common/js/inc/sv-notify';
import '../../../common-components/loading-button';
import '../../../common-components/checkbox-indicator';
import '../../../common-components/pub-sub';
import '../../../common-components/display-table';
import '../analysis-plan.service';
import '../analysis-config-service';
import standardCutsConstants from './standard-cuts-constants';

angular
    .module('standardCuts.view', [
        'displayTable',
        'svNotify',
        'pubSub',
        'checkboxIndicator',
        'analysisPlan.service',
    ])
    .directive('standardCuts', standardCuts)
    .controller('StandardCutsCtrl', StandardCutsCtrl);

function standardCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/standard-cuts.html',
        controller: 'StandardCutsCtrl',
        controllerAs: 'StandardCutsVM',
    };
}

StandardCutsCtrl.$inject = [
    '$scope',
    '$notify',
    'pubSubService',
    'analysisPlanService',
];

function StandardCutsCtrl($scope, $notify, pubSubService, analysisPlanService) {
    const StandardCutsVM = this;

    // VM exposed variables
    StandardCutsVM.ux = {
        loading: false,
        categorySelectionTypes: analysisPlanService.categorySelectionTypes,
    };

    analysisPlanService.getStandardCuts()
        .then(cuts => {
            $scope.$evalAsync(() => {
                StandardCutsVM.standardCuts = cuts;
                StandardCutsVM.ux.allCategoriesSelectedType = analysisPlanService.getSelectionTypeForMultipleCategories(StandardCutsVM.standardCuts);
            });
        })
        .catch(e => console.error(e));

    StandardCutsVM.tableHeaders = standardCutsConstants.tableHeaders;
    StandardCutsVM.tableOptions = standardCutsConstants.tableOptions;

    // VM exposed functions
    StandardCutsVM.save = save;

    init();

    function init() {
        analysisPlanService.getCutCategoryMap().then(cutCategoryMap => {
            StandardCutsVM.tableHeaders[0]._selected = analysisPlanService.initializeViewForCuts(StandardCutsVM.standardCuts, cutCategoryMap);
        });
        pubSubService.subscribe('update-cut-list', $scope.$id, updateCutList);
        $scope.$on('$destroy', () => {
            pubSubService.destroy('update-cut-list', $scope.$id);
        });
    }

    function updateCutList(category) {
        category.cutList = analysisPlanService.getCutListForCategory(category);
    }

    function save() {
        StandardCutsVM.ux.loading = true;
        analysisPlanService.saveMultipleCutsCategories(StandardCutsVM.standardCuts).then(onSave, onSaveFailed);
    }

    function onSave() {
        $scope.$evalAsync(() => {
            StandardCutsVM.ux.loading = false;
        });
    }

    function onSaveFailed() {
        $notify.error('An error occurred saving standard cuts');
        onSave();
    }
}
