import {
    Components, Dataset, Scales, Plots,
} from 'plottable/plottable';

const Plottable = {
    Components: Components,
    Dataset: Dataset,
    Plots: Plots,
    Scales: Scales,
};

angular.module('chart.errorbars', [])
    .factory('Errorbars', errorbars);

function errorbars() {
    function Errorbars() {
        // Private properties
        var _chart,
            _plot,
            _group,
            _data = [],
            _className = 'error-bars',
            _errorPlotGroup,
            _scatterPlot,
            _visible = false,
            id = '_errorbars__' + (Math.random() * 1e3); // Needed to generate random-enough id values

        Errorbars.init = init;
        Errorbars.plot = plot;
        Errorbars.group = group;
        Errorbars.chart = chart;
        Errorbars.destroy = destroy;
        Errorbars.data = data;
        Errorbars.visible = visible;

        function chart(val) {
            if (!arguments.length) {
                return _chart;
            }

            _chart = val;

            return Errorbars;
        }

        function plot(val) {
            if (!arguments.length) {
                return _plot;
            }

            _plot = val;

            return Errorbars;
        }

        function group(val) {
            if (!_plot || !_data) {
                // We need _plot and _data for the getErrorBarPlotGroup call
                // This is to make sure we render chart with correct scale
                throw new Error('Plot or data must be defined before group');
            }

            if (!arguments.length) {
                return _group;
            }

            _group = val;
            _errorPlotGroup = getErrorBarPlotGroup();
            _group.append(_errorPlotGroup);

            return Errorbars;
        }

        function data(value, filterFn) {
            if (!arguments.length) {
                return _data;
            }
            if (value.length) {
                if (filterFn) {
                    value = value.filter(filterFn);
                }

                // NOTE: testing for Plottable Dataset since d3 uses plain arrays
                if (value[0]._data) {
                    for (let i = 0; i < value.length; i++) {
                        _data.push(value[i].data());
                    }
                }
                else {
                    // Add plain array to data
                    _data.push(value);
                }
            }
            _data = _.flatten(_data); // NOTE: necessary when there are multiple data sets need to flatten them to one big array for d3
            return Errorbars;
        }

        function visible(val) {
            if (!arguments.length) {
                return _visible;
            }

            _visible = val;
            return Errorbars;
        }

        function init() {
            // Register event listeners
            _chart.addEventListener(_chart.EVENTS.ANCHOR_COMPLETE, eventListenerHandler, id, true);
            _chart.addEventListener(_chart.EVENTS.RESIZE_COMPLETE, eventListenerHandler, id);
            _chart.addEventListener(_chart.EVENTS.FLIP_AXIS_COMPLETE, eventListenerHandler, id);

            return Errorbars;
        }

        function destroy() {
            _chart.removeEventListener(_chart.EVENTS.ANCHOR_COMPLETE, id);
            _chart.removeEventListener(_chart.EVENTS.RESIZE_COMPLETE, id);
            _chart.removeEventListener(_chart.EVENTS.FLIP_AXIS_COMPLETE, id);

            // Remove the errorbar grouping element
            _group.destroy();
        }

        // Event handler (same for all events)
        function eventListenerHandler() {
            // Make sure all the dom things we need are set up
            Errorbars();
        }

        // Private methods
        function getErrorBarPlotGroup() {
            if (!_errorPlotGroup || !_errorPlotGroup._isAnchored) {
                // Create scatter plot over the regular chart with the upperBound values
                // so we don't have to re-adjust the chart for errorbars
                let scatterPlotData = new Plottable.Dataset(_data);
                if (_plot._isVertical) {
                    _scatterPlot = new Plottable.Plots.Scatter()
                        .addDataset(scatterPlotData)
                        .x(_plot.x().accessor, _plot.x().scale)
                        .y(function(d) {
                            return d.upperBound;
                        }, _plot.y().scale);
                }
                else {
                    _scatterPlot = new Plottable.Plots.Scatter()
                        .addDataset(scatterPlotData)
                        .x(function(d) {
                            return d.upperBound;
                        }, _plot.x().scale)
                        .y(_plot.y().accessor, _plot.y().scale);
                }

                _errorPlotGroup = new Plottable.Components.Group([_scatterPlot]);

                _errorPlotGroup.addClass(_className);
            }

            return _errorPlotGroup;
        }

        function _getDistanceToLowerBoundForEntity(e) {
            var axisScale = _plot._isVertical ? _plot.y().scale : _plot.x().scale;

            return axisScale.scale(e.upperBound) - axisScale.scale(e.lowerBound);
        }

        /* The bar top width refers to the top and bottom flat errorbar width */
        function _getErrorBarTopWidth(size) {
            var defaultErrorBarFlatSideWidth = 10;
            // Ensure that the size of the flat end is sized proportional to the bar width
            return size >= (defaultErrorBarFlatSideWidth * 1.8) ? defaultErrorBarFlatSideWidth : size / 2;
        }

        function _getPathForErrorBarForEntity(e, size) {
            // Could def be more sexy and concise, but couldn't think of a way to do this at the point of writting this 😄
            var distanceFromUpperToLowerBound = _getDistanceToLowerBoundForEntity(e),
                barWidth = _getErrorBarTopWidth(size),
                upperBoundPath = [
                    'M0,0', // Start from entity value (this is upperBound from scatter plot)
                    'm-' + (barWidth / 2.0) + ',0', // Move to begining of top bar
                    'h' + barWidth, // Draw upperBound bar
                ],
                lowerBoundPath = [
                    'M0,0', // Start from entity (upperBound) value
                    'v' + (-1.0 * distanceFromUpperToLowerBound), // Draw vertical bar from upperBound to lowerBound
                    'm-' + (barWidth / 2.0) + ',0', // Move to begining of lowerBound bar
                    'h' + barWidth, // Draw lowerBound bar
                ];

            if (!_plot._isVertical) {
                upperBoundPath = [
                    'M0,0', // Start from entity value
                    'm0,' + (-1 * barWidth / 2.0), // Move to begining of top bar
                    'v' + barWidth, // Draw upperBound bar
                ];
                lowerBoundPath = [
                    'M0,0', // Start from entity value
                    'h' + (-1.0 * distanceFromUpperToLowerBound), // Draw horizontal bar from upperBound value to lowerBound
                    'm0,' + (-1 * barWidth / 2.0), // Move to begining of lowerBound bar
                    'v' + barWidth, // Draw lowerBound bar
                ];
            }
            return [upperBoundPath.join(''), lowerBoundPath.join('')];
        }

        // Public function
        function Errorbars() {
            let attrProjector = _plot._generateAttrToProjector &&
                _plot._generateAttrToProjector(_plot),
                attrProjectorAttr = _plot._isVertical ? 'width' : 'height';
            _scatterPlot.content()
                .selectAll('.symbol')
                .classed(_className + '-item', true)
                .attr('d', function(d, i, dataset) {
                    return _getPathForErrorBarForEntity(d, attrProjector[attrProjectorAttr](d, i, dataset));
                });

            return Errorbars;
        }

        return Errorbars;
    }
    return Errorbars;
}
