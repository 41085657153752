import U from '../../../../../common/js/util';
import 'ng-file-upload/dist/angular-file-upload-all';
import '../../common-components/display-table';
import '../../common-components/paginator';
import '../../common-components/pub-sub';
import {
    compareDate,
} from '../../common-components/datepicker-service';
import tagCreationConstant from '../../beacons/tag-creation-constant';
import '../ad-measurement.api-service';
import adMeasurementConstants from '../ad-measurement.constants';
import adMeasurementService from '../ad-measurement.service';
import './impression-reporting-service';

angular.module('impressionParameter.view', [
    'common.paginator',
    'pubSub',
    'common.dialog',
    'angularFileUpload',
    'adMeasurement.apiService',
    'impressionReportingService',
])
    .directive('impressionParameters', impressionParameters)
    .controller('ImpressionParameterCtrl', ImpressionParameterCtrl);

/**
 * Returns the impression parameters object
 *
 * @returns {object}
 */
function impressionParameters() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/impression-parameters.html',
        controller: 'ImpressionParameterCtrl',
        controllerAs: 'ImpressionParameterVM',
    };
}
ImpressionParameterCtrl.$inject = [
    '$scope',
    '$rootScope',
    'pubSubService',
    'Paginator',
    'dialog',
    'adMeasurementApiService',
    'impressionReportingService',
];

/**
 * Initialize the controller
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} pubSubService
 * @param {object} Paginator
 * @param {object} dialog
 * @param {object} adMeasurementApiService
 * @param {object} impressionReportingService
 */
function ImpressionParameterCtrl($scope, $rootScope, pubSubService, Paginator, dialog, adMeasurementApiService, impressionReportingService) {
    const ITEMS_PER_PAGE = 20,
        PARAMETER_ID = 'parameterId',
        TAG = 'tag',
        IMPRESSION_GROUPS = 'impressionGroups',
        ImpressionParameterVM = this,
        impressionValues = new Map();
    let previousState,
        previousDates;
    ImpressionParameterVM.ux = {
        loading: true,
    };
    // Variables
    ImpressionParameterVM.impressionCountTypes = adMeasurementConstants.impressionCountTypes;
    ImpressionParameterVM.selectedCountType = {};
    ImpressionParameterVM.dataRetrievalStatus = {
        impressionGroups: false,
        tag: false,
        parameterId: false,
    };
    ImpressionParameterVM.impressions = {};
    ImpressionParameterVM.tabOptions = {
        stateChangeEvent: 'select-impression-count-type',
        orientation: 'vertical',
    };
    // Functions
    ImpressionParameterVM.showFilePicker = showFilePicker;
    ImpressionParameterVM.selectImpressionCountType = selectImpressionCountType;
    ImpressionParameterVM.isInDateRage = isInDateRage;
    ImpressionParameterVM.updateTableData = updateTableData;
    ImpressionParameterVM.downloadAsCsv = downloadAsCsv;
    ImpressionParameterVM.filterImpression = filterImpression;
    // Note: Had to do this because ng-file-select did not work when trying AdMeasurementVM.onImpressionFileSelected
    $scope.onImpressionFileSelected = onImpressionFileSelected;

    init();

    function init() {
        const surveyStartDate = new Date($rootScope.survey.started),
            endDate = new Date(),
            tag = [],
            beaconPoolBeacons = {},
            //Initialize impression groups
            listImpressionParameterGroups = new Promise(resolve => {
                adMeasurementApiService.listImpressionParameterGroups($rootScope.survey.uuid).then(ipg => {
                    ImpressionParameterVM.impressions.impressionGroups = new Paginator({
                        list: _.map(_.groupBy(ipg, 'categoryName'), (parameters, name) => {
                            return {
                                name: name,
                                parameters: parameters,
                                uuid: U.uuid4(),
                            };
                        }),
                        itemsPerPage: ITEMS_PER_PAGE,
                    });
                    impressionReportingService.getImpressionGroupBeacons(ImpressionParameterVM.impressions.impressionGroups.list);
                    resolve();
                }, resolve);
            }),
            //Initialize impression values
            listImpressionParameterValues = new Promise(resolve => {
                adMeasurementApiService.listImpressionParameterValues($rootScope.survey.uuid).then(ipv => {
                    ipv = _.isEmpty(ipv) ? [] : ipv;
                    _.forEach(ipv, value => {
                        impressionValues.set(value.value, value);
                        value.uuid = U.uuid4();
                    });
                    resolve();
                }, resolve);
            });
        let startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 6);
        if (startDate - surveyStartDate < 0) {
            startDate = surveyStartDate;
        }
        ImpressionParameterVM.dateRange = {
            startDate: startDate,
            endDate: endDate,
        };
        ImpressionParameterVM.tableOptions = {
            dateRange: ImpressionParameterVM.dateRange,
            noSearch: true,
        };
        //Initialize beacon definitions
        _.forEach($rootScope.survey.beaconPools, beaconPools => {
            _.forEach(beaconPools.beaconPoolBeacons, bpb => {
                let beaconDefinition = bpb.beaconDefinition,
                    exportName = beaconDefinition.exportName,
                    uuid = beaconDefinition.uuid;
                if (!beaconPoolBeacons[uuid]) {
                    beaconPoolBeacons[uuid] = true;
                    tag.push({
                        id: exportName,
                        uuid: uuid,
                        name: exportName,
                        beaconType: _.find(tagCreationConstant.get('BEACON_TYPES'), {
                            value: beaconDefinition.beaconType,
                        }) || {},
                        dmp: beaconDefinition.dataPartner ? _.find(tagCreationConstant.get('REDIRECTS'), redirect => {
                            return redirect.name.toLowerCase().indexOf(name) !== -1;
                        }) : {},
                    });
                }
            });
        });
        ImpressionParameterVM.impressions.tag = new Paginator({
            list: tag,
            itemsPerPage: ITEMS_PER_PAGE,
        });
        Promise.all([listImpressionParameterValues, listImpressionParameterGroups]).then(() => {
            $scope.$evalAsync(() => {
                delete ImpressionParameterVM.ux.loading;
                selectImpressionCountType(ImpressionParameterVM.impressionCountTypes[0]);
            });
        });
        pubSubService.subscribe('update-date-range', $scope.$id, updateDateRange);
        pubSubService.subscribe('action-button-callback', $scope.$id, openModal);
        pubSubService.subscribe('select-impression-count-type', $scope.$id, selectImpressionCountType);
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'update-date-range',
                'action-button-callback',
                'select-impression-count-type',
            ], $scope.$id);
        });
    }

    /**
     * Sets the selected impression count type and updates the table data
     *
     * @param {object} selected
     */
    function selectImpressionCountType(selected) {
        let displayType;
        if (ImpressionParameterVM.selectedCountType.view !== selected.view) {
            previousState = ImpressionParameterVM.selectedCountType;
            ImpressionParameterVM.selectedCountType = impressionReportingService.IMPRESSION_PARAMETER_MAP[selected.view];
        }
        displayType = ImpressionParameterVM.selectedCountType.displayType;
        ImpressionParameterVM.columnHeaders = adMeasurementConstants.impressionTableViewColumns[displayType];
        if (displayType === PARAMETER_ID) {
            ImpressionParameterVM.columnHeaders[0].name = ImpressionParameterVM.selectedCountType.name;
        }
        else {
            pubSubService.notify('set-paginator', [ImpressionParameterVM.impressions[displayType]]);
        }
        delete ImpressionParameterVM.ux.search;
        updateTableData();
    }

    /**
     * Checks if the date in the provided parameter is in the expected range
     *
     * @param {object} params
     * @returns {object}
     */
    function isInDateRage(params) {
        return ImpressionParameterVM.dateRange.startDate <= params.date && params.date <= ImpressionParameterVM.dateRange.endDate;
    }

    /**
     * Sets the date range and refresh the table data
     *
     * @param {string} currentDateString
     */
    function updateDateRange(currentDateString) {
        if (currentDateString !== ImpressionParameterVM.dateRangeString) {
            updateDataRetrievalFlag();
            ImpressionParameterVM.dateRangeString = currentDateString;
        }
        updateTableData();
    }

    function updateTableData() {
        ImpressionParameterVM.ux.refreshing = previousState.displayType !== ImpressionParameterVM.selectedCountType.displayType;
        switch (ImpressionParameterVM.selectedCountType.displayType) {
            case TAG:
                getTags();
                delete ImpressionParameterVM.ux.refreshing;
                break;
            case IMPRESSION_GROUPS:
                getImpressionGroups();
                delete ImpressionParameterVM.ux.refreshing;
                break;
            default:
                getImpressionDaySummary();
        }
    }

    function showFilePicker() {
        // eslint-disable-next-line no-undef
        $('#upload-file-select').click();
    }

    /**
     * Starts converting the impression file into JSON when an impression file has been selected
     *
     * @param {object} $files
     */
    function onImpressionFileSelected($files) {
        adMeasurementService.convertCsvFileToJson($files && $files[0]).then(openImpressionParameterPreviewModal, openImpressionParameterPreviewModal);
    }

    /**
     * Display the impression parameters preview modal
     *
     * @param {object} impressionParameterData
     */
    function openImpressionParameterPreviewModal(impressionParameterData) {
        dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/impression-parameters/impression-param-value-modal.html',
            controller: 'ImpressionParamValueCtrl',
            controllerAs: 'ImpressionParamValueVM',
            windowClass: 'modal-sv--lg-width modal-sv--scroll-on-content',
            resolve: {
                impressionParameterData: () => {
                    return impressionParameterData;
                },
            },
        });
    }

    /**
     * Display the modal
     *
     * @param {object} arg
     */
    function openModal(arg) {
        if (ImpressionParameterVM.selectedCountType.view === TAG) {
            openViewTagModal(arg);
        }
        else {
            openImpressionParameterListModal(arg);
        }
    }

    /**
     * Display the impression parameters list
     *
     * @param {object} ipg
     */
    function openImpressionParameterListModal(ipg) {
        impressionReportingService.getImpressionGroupImpressionCounts(ipg, ImpressionParameterVM.dateRange).then(() => {
            dialog.popup({
                templateUrl: '/dashboard-templates/ad-measurement/impression-parameters/impression-parameter-list.html',
                controller: 'ImpressionParameterListCtrl',
                controllerAs: 'ImpressionParameterListVM',
                windowClass: 'modal-sv--lg-width',
                resolve: {
                    ipg: () => {
                        return ipg;
                    },
                    dateRange: () => {
                        return ImpressionParameterVM.dateRange;
                    },
                },
            });
        });
    }

    /**
     * Display the tag modal
     *
     * @param {object} beaconDefinition
     */
    function openViewTagModal(beaconDefinition) {
        let opening = '<img width="0" height="0" src="' + (beaconDefinition.beaconType.value === 'ipaddress' ? 'https://ir.surveywall-api.survata.com?eid=' : 'https://ev.surveywall-api.survata.com/r?eid=') + '"',
            tag = beaconDefinition.name + ',' + opening + beaconDefinition.uuid + '">';
        dialog.popup({
            templateUrl: '/modal-templates/beacon-definition-code-modal.html',
            controller: 'BeaconDefinitionCodeModalCtrl',
            controllerAs: 'BeaconDefinitionCodeVM',
            resolve: {
                tag: () => {
                    return tag;
                },
            },
        });
    }

    /**
     * Filter based on the provided impression
     *
     * @param {object} impression
     * @returns {object}
     */
    function filterImpression(impression) {
        //Filter out impressions that don't have the same IPV as the selected IPV, unless users select to show counts by beacon
        if (ImpressionParameterVM.selectedCountType.displayType === PARAMETER_ID && ImpressionParameterVM.selectedCountType.view !== impression.impressionParameter) {
            return;
        }

        if (ImpressionParameterVM.ux.search) {
            let impressionString = JSON.stringify(_.values(impression)),
                regex = new RegExp(ImpressionParameterVM.ux.search, 'i');
            return regex.test(impressionString);
        }
        return true;
    }

    function getImpressionGroups() {
        const data = ImpressionParameterVM.impressions.impressionGroups.data;
        ImpressionParameterVM.dataRetrievalStatus.impressionGroups = true;
        ImpressionParameterVM.hasImpressions = data.length;
        previousDates = angular.copy(ImpressionParameterVM.dateRange);
    }

    function getTags() {
        let data = ImpressionParameterVM.impressions.tag;
        // On page load, .data isn't defined, so have to get it.
        if (!data.data) {
            refreshList();
        }
        data = getPageData();
        ImpressionParameterVM.dataRetrievalStatus.beacon = true;
        ImpressionParameterVM.hasImpressions = data.length;
        impressionReportingService.getBeaconImpressionCounts(data, ImpressionParameterVM.dateRange);
        previousDates = angular.copy(ImpressionParameterVM.dateRange);
    }

    function updateDataRetrievalFlag() {
        _.forEach(ImpressionParameterVM.dataRetrievalStatus, (value, key) => {
            delete ImpressionParameterVM.dataRetrievalStatus[key];
        });
    }

    function getImpressionDaySummary() {
        if (isSameDateRange() && ImpressionParameterVM.dataRetrievalStatus.parameterId) {
            pubSubService.notify('set-paginator', [ImpressionParameterVM.impressions.parameterId]);
            impressionReportingService.getImpressionGroupsByImpressionValues($rootScope.survey.uuid, ImpressionParameterVM.impressions.parameterId.data, impressionValues);
            ImpressionParameterVM.hasImpressions = !!ImpressionParameterVM.impressions.parameterId.filtered.length;
            delete ImpressionParameterVM.ux.refreshing;
            return;
        }
        impressionReportingService.getParameterImpressionCounts($rootScope.survey.uuid, ImpressionParameterVM.dateRange, impressionValues).then(() => {
            ImpressionParameterVM.dataRetrievalStatus.parameterId = true;
            ImpressionParameterVM.hasImpressions = !!impressionValues.size;
            ImpressionParameterVM.impressions.parameterId = new Paginator({
                list: Array.from(impressionValues.values()),
                itemsPerPage: ITEMS_PER_PAGE,
            });
            $scope.$evalAsync(() => {
                delete ImpressionParameterVM.ux.refreshing;
            });
            pubSubService.notify('set-paginator', [ImpressionParameterVM.impressions.parameterId]);
            impressionReportingService.getImpressionGroupsByImpressionValues($rootScope.survey.uuid, ImpressionParameterVM.impressions.parameterId.data, impressionValues);
        }, () => {
            ImpressionParameterVM.hasImpressions = false;
        });
        previousDates = angular.copy(ImpressionParameterVM.dateRange);
    }

    function refreshList() {
        ImpressionParameterVM.impressions[ImpressionParameterVM.selectedCountType.displayType].refreshList(filterImpression);
    }

    /**
     * Returns the page data based on the provided parameter
     *
     * @param {object} idx
     * @returns {object}
     */
    function getPageData(idx) {
        return ImpressionParameterVM.impressions[ImpressionParameterVM.selectedCountType.displayType].getPageData(idx);
    }

    /**
     * Check if the provided date ranges are the same
     *
     * @param {object} dateRange1
     * @param {object} dateRange2
     * @returns {boolean}
     */
    function isSameDateRange(dateRange1, dateRange2) {
        if (!dateRange2 && !previousDates) {
            return false;
        }
        let startDate1 = (dateRange1 || ImpressionParameterVM.dateRange).startDate,
            startDate2 = (dateRange2 || previousDates).startDate,
            endDate1 = (dateRange1 || ImpressionParameterVM.dateRange).endDate,
            endDate2 = (dateRange2 || previousDates).endDate;
        return !compareDate(startDate1, startDate2) && !compareDate(endDate1, endDate2);
    }

    function downloadAsCsv() {
        let blob;
        const header = [],
            body = [];
        _.forEach(ImpressionParameterVM.columnHeaders, columnHeader => {
            if (columnHeader.name) {
                header.push(U.stringToCsvStringCompat(columnHeader.name));
            }
        });
        body.push(header.join(','));
        _.forEach(ImpressionParameterVM.impressions[ImpressionParameterVM.selectedCountType.displayType].filtered, impression => {
            const row = [];
            _.forEach(ImpressionParameterVM.columnHeaders, header => {
                if (header.value) {
                    const obj = impression[header.value];

                    if (Array.isArray(obj)) {
                        row.push(U.stringToCsvStringCompat(_.map(obj, 'name').join(',')));
                    }
                    else {
                        row.push(U.stringToCsvStringCompat((_.isObject(obj) ? obj.name : obj) || obj.defaultValue || ''));
                    }
                }
            });
            body.push(row.join(','));
        });
        blob = new Blob([body.join('\n')], {
            type: 'data:text/csv;charset=utf-8',
        });
        // eslint-disable-next-line no-undef
        saveAs(blob, ImpressionParameterVM.selectedCountType.value + '-' + $rootScope.survey.name + '.csv');
    }
}
