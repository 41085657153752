import ageOptions from './age/options';

/**
 * @param ageRange
 */
function parseId(ageRange) {
    var parts = ageRange.split('_');

    return {
        lower: parseInt(parts[0], 10),
        upper: parseInt(parts[2], 10) || undefined,
    };
}

/**
 * @param req
 */
function toString(req) {
    var runs = [],
        prev, cur,
        details = (req && req.details) || [],
        x = [];

    for (var i = 0; i < details.length; i++) {
        cur = parseId(details[i]);
        if (prev && (prev.upper === (cur.lower - 1))) {
            // Merge adjacent ranges
            prev.upper = cur.upper;
        }
        else {
            if (prev) {
                runs.push(angular.copy(prev));
            }
            prev = cur;
        }
    }
    if (prev) {
        runs.push(angular.copy(prev));
    }

    _.forEach(runs, function(e) {
        x.push(e.lower.toString() + (e.upper ? '-' + e.upper.toString() : '+') + ' yrs');
    });
    return x.join(', ');
}

/**
 * @param req
 */
function errors(req) {
    var errs = [],
        l = (req.details && req.details.length) || 0;

    // Require at least one group to be selected.
    if (l === 0) {
        errs.push('No age group is selected. Select at least one.');
    }

    // Require at least one group to be un-selected.
    if (ageOptions.list.length === l) {
        errs.push('All age groups are selected. Unselect at least one.');
    }

    return errs;
}

const targetingAttributeAge = {
    id: 'age',
    name: 'Age',
    description: 'Filter by respondent age',
    options: ageOptions.list,
    basic: true,
    extended: true,
    advanced: true,
    toString: toString,
    errors: errors,
};

export default targetingAttributeAge;
