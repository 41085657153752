import surveyListConstants from './survey-list-constants';
import '../survey/api-survey-service';
import '../survey/survey-statuses';

angular.module('SurveyListModule', [
    'apiSurveyService', 'surveyStatusesService',
])
    .directive('surveyList', surveyList)
    .controller('SurveyListCtrl', SurveyListCtrl);

/**
 * Survey list
 *
 * @returns {object}
 */
function surveyList() {
    return {
        restrict: 'AE',
        templateUrl: '/admin-templates/survey-list.html',
        controller: 'SurveyListCtrl',
        controllerAs: 'SurveyListVM',
    };
}
SurveyListCtrl.$inject = [
    '$timeout',
    'surveyService',
    'surveyStatusesService',
];

/**
 * Survey list controller
 *
 * @param {object} $timeout
 * @param {object} surveyService
 * @param {object} surveyStatusesService
 */
function SurveyListCtrl($timeout, surveyService, surveyStatusesService) {
    const DEFAULT_SURVEYS_PER_PAGE = 100;
    var SurveyListVM = this;
    SurveyListVM.pages = {
        current: 0,
    };
    SurveyListVM.sort = {
        reverse: true,
        filters: surveyListConstants.tableFilters,
        statusFilters: surveyListConstants.statusFilters,
    };
    SurveyListVM.ux = {
        ready: false,
        listRefreshing: false,
        cancellationReason: '',
        cancellationComment: '',
        currentSurvey: {},
    };

    SurveyListVM.updateSortFilters = updateSortFilters;
    SurveyListVM.refreshSurveyList = refreshSurveyList;
    SurveyListVM.refreshSurveys = refreshSurveys;
    SurveyListVM.sortBy = sortBy;
    SurveyListVM.captureCurrentSurveyDetails = captureCurrentSurveyDetails;
    SurveyListVM.cancelSurvey = cancelSurvey;
    SurveyListVM.updateSurveysPerPage = updateSurveysPerPage;
    SurveyListVM.changePage = changePage;
    SurveyListVM.showCancel = showCancel;

    init();

    function init() {
        updateSurveysPerPage(DEFAULT_SURVEYS_PER_PAGE);
    }

    function updateSortFilters() {
        if (SurveyListVM.refreshPromise) {
            $timeout.cancel(SurveyListVM.refreshPromise);
        }
        SurveyListVM.refreshPromise = $timeout(function() {
            refreshSurveyList();
        }, 500);
    }

    function refreshSurveyList() {
        if (SurveyListVM.ux.listRefreshing) {
            $timeout(function() {
                refreshSurveyList();
            }, 100);
        }
        else {
            refreshSurveys();
        }
    }

    function refreshSurveys() {
        let params = {
                max: SurveyListVM.surveysPerPage,
                offset: SurveyListVM.pages.current * SurveyListVM.surveysPerPage,
            },
            filters = {};
        SurveyListVM.ux.listRefreshing = true;

        _.forEach(SurveyListVM.sort.statusFilters, function(filter) {
            params[filter.id] = filter.value ? 'true' : 'false';
        });

        _.forEach(SurveyListVM.sort.filters, function(filter) {
            if (filter.expression) {
                if (filter.field === 'user.name') {
                    filters.client = filter.expression;
                }
                else {
                    filters[filter.field] = filter.expression;
                }
            }
        });
        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        surveyService.listAllSurveys(params).then(function(surveys) {
            SurveyListVM.surveys = surveys;

            _.forEach(surveys, survey => {
                survey.status = surveyStatusesService.getStatus(survey);
                if (survey.status === 'Running') {
                    surveyService.getResultsSummary(survey.uuid).then(function(summary) {
                        survey._numPendingResponses = summary.pending;
                        survey._numCurrentResponses = summary.responses;
                        survey._finishDate = summary.finishDate;
                    });
                }
            });
            setReady();
        }, setReady);
    }

    function setReady() {
        $timeout(() => {
            SurveyListVM.ux.ready = true;
            SurveyListVM.ux.listRefreshing = false;
        }, 0);
    }

    /**
     * Sort by given criteria
     *
     * @param {object} criteria
     */
    function sortBy(criteria) {
        if (SurveyListVM.sort.criteria === criteria) {
            SurveyListVM.sort.reverse = !SurveyListVM.sort.reverse;
        }
        else {
            SurveyListVM.sort.criteria = criteria;
        }
    }

    /**
     * Capture ID and UUID for survey
     *
     * @param {string} uuid
     * @param {number} id
     */
    function captureCurrentSurveyDetails(uuid, id) {
        SurveyListVM.ux.currentSurvey.id = id;
        SurveyListVM.ux.currentSurvey.uuid = uuid;
    }

    function cancelSurvey() {
        let params = {
            cancel: 'true',
            cancellationReason: SurveyListVM.ux.cancellationReason,
        };
        if (SurveyListVM.ux.cancellationComment) {
            params.cancellationComment = SurveyListVM.ux.cancellationComment;
        }

        surveyService.updateSurvey(SurveyListVM.ux.currentSurvey.uuid, params).then(function() {
            SurveyListVM.surveys[SurveyListVM.ux.currentSurvey.id].canceled = true;
            refreshSurveyList();
            $('#survey-cancel-modal').modal('hide');

            // Clearing current survey data now since it has been stored
            SurveyListVM.ux.currentSurvey = {};
        });

        // Clear comment and reason
        SurveyListVM.ux.cancellationReason = '';
        SurveyListVM.ux.cancellationComment = '';
    }

    /**
     * Update number of surveys per page
     *
     * @param {number} perPage
     */
    function updateSurveysPerPage(perPage) {
        SurveyListVM.surveysPerPage = perPage;
        refreshSurveyList();
    }

    /**
     * Change current page
     *
     * @param {number} page
     */
    function changePage(page) {
        if (page < 0) {
            return;
        }
        SurveyListVM.pages.current = page;
        refreshSurveyList();
    }

    /**
     * Should show cancel button
     *
     * @param {object} survey
     * @returns {boolean}
     */
    function showCancel(survey) {
        return (survey.draftCompleted || survey.quoteRequested) && !survey.canceled;
    }
}
