import './operating-system/options';
angular.module('operatingSystem', ['operatingSystem.options'])
    .service('targetingAttributeOperatingSystem', targetingAttributeOperatingSystem);
targetingAttributeOperatingSystem.$inject = ['operatingSystemOptions'];

function targetingAttributeOperatingSystem(operatingSystemOptions) {
    function toString(req) {
        return _.map(req.details, function(id) {
            return operatingSystemOptions.map[id].name;
        }).join(', ');
    }

    function errors(req) {
        var errs = [],
            l = (req.details && req.details.length) || 0;

        // Require at least one group to be selected.
        if (l === 0) {
            errs.push('No operating system is selected. Select at least one.');
        }

        // Require at least one group to be un-selected.
        if (operatingSystemOptions.list.length === l) {
            errs.push('All operating systems are selected. Unselect at least one.');
        }

        return errs;
    }
    return {
        id: 'operatingSystem',
        name: 'Operating system',
        basic: true,
        extended: true,
        advanced: false,
        description: 'Filter by the respondent&rsquo;s operating system',
        options: operatingSystemOptions.list,
        toString: toString,
        errors: errors,
    };
}
