
    angular.module('ZapierModule', [])
        .service('ZapierService', ['$http', function($http) {
            var fire = function(zapId, params) {
                if (U.isProd()) {
                // Add referrer and utm information, if available
                    if (U.cookie('utm_log')) {
                        params.utm_log = U.cookie('utm_log');
                    }
                    if (U.cookie('ref_log')) {
                        params.referrer_log = U.cookie('ref_log');
                    }

                    var url = 'https://zapier.com/hooks/catch/' + zapId + '/' + U.buildParamString(params);
                    return $http.get(url, {
                        withCredentials: false,
                    });
                }
            };
            return {
                fire: fire,
            };
        }]);

