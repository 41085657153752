angular.module('UrlBuilderApp', [])
    .controller('UrlBuilderCtrl', ['$scope', function($scope) {
        $scope.destination = 'https://www.upwave.com/';
        $scope.url = function url() {
            var destination = $scope.destination || 'https://www.upwave.com/';
            if ((destination.indexOf('http://') !== 0) && (destination.indexOf('https://') !== 0)) {
                destination = 'http://' + destination;
            }
            var url = U.Url(destination);
            var utm_source_prefix = '';

            if ($scope.utm_source) {
                url.param('utm_source', $scope.utm_source);
                utm_source_prefix = $scope.utm_source + '-';
            }
            if ($scope.utm_campaign) {
                url.param('utm_campaign', utm_source_prefix + $scope.utm_campaign);
            }
            if ($scope.utm_medium) {
                url.param('utm_medium', $scope.utm_medium);
            }
            if ($scope.utm_content) {
                url.param('utm_content', utm_source_prefix + $scope.utm_content);
            }
            if ($scope.utm_term) {
                url.param('utm_term', utm_source_prefix + $scope.utm_term);
            }
            return url.url();
        };
    }]);
