import _ from 'lodash';
import {
    convertFilterBy,
    QUESTION_REGEX,
    parseQuestionChoiceString,
    isQuestionCriteriaString,
} from '../filter-and-compare/default-filter-service';
import filterLogicConstants from './filter-logic-constants';
import defaultCutNames from '../ad-measurement/analysis-plan/cuts/default-cut-names';

const filterLogicService = {
    getFilteredObject,
    validSubVariable,
    filterJsonToPlainEnglish,
    filterJsonToCategory,
};

function validSubVariable(parsedCriteria) {
    let valid = true;
    _.forEach(parsedCriteria, criteria => {
        if (Array.isArray(criteria)) {
            valid = validSubVariable(criteria);
            return valid;
        }
        valid = criteria.logic && criteria.criteria.length;
        return valid;
    });
    return !!valid;
}

function filterJsonToCategory(json, cutName) {
    const flattenedFilterJson = flattenFilterJson(convertFilterBy(json, []), {}),
        keys = _.keys(flattenedFilterJson),
        firstCategory = QUESTION_REGEX.test(keys[0]) ? filterLogicConstants.criteriaTypes.QUESTION : keys[0],
        values = _.values(flattenedFilterJson);
    let category = firstCategory;

    if (json.$bool) {
        return 'Overall';
    }
    if (json.$not) {
        return filterLogicConstants.criteriaTypes.CUSTOM;
    }
    if (keys.length > 1 || (firstCategory === filterLogicConstants.criteriaTypes.QUESTION && _.flatten(values).length > 1)) {
        category = filterLogicConstants.criteriaTypes.CUSTOM;
    }
    if (/^Device Type: (Mobile|Desktop)$/.test(cutName)) {
        return 'Device Type';
    }
    if (/^Urbanicity( \(based on Postal Code\))?: /.test(cutName)) {
        return 'Urbanicity';
    }
    if (/^DaysSinceLastExposure\$/.test(cutName)) {
        return 'DaysSinceLastExposure';
    }
    if (/(\d+\+? Exposures|Frequency \d+.*)$/.test(cutName)) {
        return 'Frequency';
    }
    if (/^ImpressionParameterGroups\$/.test(cutName)) {
        return 'ImpressionParameterGroups';
    }
    if (category === filterLogicConstants.criteriaTypes.CUSTOM || (firstCategory !== filterLogicConstants.criteriaTypes.QUESTION && !defaultCutNames[cutName])) {
        return filterLogicConstants.criteriaTypes.CUSTOM;
    }

    return category;
}

/**
 * Given a single filter item, return the selected options component of it (i.e. "male" for gender, or "18-24", "25-34" for ages)
 *
 * @param {Array} filterItem - A single filter criteria item (i.e. Ages or Gender)

 * @returns {String} The selected options component of a filter item
 */
function getCriteriaText(filterItem) {
    if (isQuestionCriteriaString(filterItem.id)) {
        return _.map(filterItem.criteria, item => {
            return parseQuestionChoiceString(item).choiceIndex;
        });
    }
    return _.map(filterItem.criteria, item => {
        return (filterLogicConstants.comparisonOperatorMap[filterItem.logic] || '') + ' ' + item;
    });
}

/**
 * Converts a single criteria item to a plain english summary string
 *
 * @param {Object} criteriaItem - A single filter criteria item (i.e. Ages or Gender)
 *
 * @returns {String} Plain english string for a single filter criteria item
 */
function getCriteriaItemString(criteriaItem) {
    const isQuestion = isQuestionCriteriaString(criteriaItem.id),
        criteriaType = (isQuestion ? criteriaItem.id.replace('$', ' ') : filterLogicConstants.idToIn[criteriaItem.id] || criteriaItem.id) + ': ',
        criteriaText = getCriteriaText(criteriaItem);
    return criteriaType + (criteriaItem.logic === 'EXACTLY' ? 'is exactly' : '') + criteriaText.join(', ');
}

/**
 * Converts an array of filter criteria to an array of plain english strings describing each filter criteria
 *
 * @param {Array} criteria - An array of filter criteria. Each element may be a single filter criteria item, or an array of nested criteria
 * @param {boolean} isNested - Indicates whether the criteria array is nested.
 *
 * @returns {Array} An array of plain english strings, each descriping one set of filter criteria
 */
function getCriteriaStrings(criteria, isNested) {
    const allCriteriaStrings = [];

    _.forEach(criteria, (criteriaItem, idx) => {
        let criteriaItemString = Array.isArray(criteriaItem) ? getCriteriaStrings(criteriaItem, true) : getCriteriaItemString(criteriaItem);
        if (criteriaItem.parentLogic && idx < criteria.length - 1) {
            criteriaItemString += ' ' + filterLogicConstants.filterLogicToStringMap[criteriaItem.parentLogic];
        }
        allCriteriaStrings.push(criteriaItemString);
    });
    return isNested ? allCriteriaStrings.join(' ') : allCriteriaStrings;
}

/**
 * Converts the provide filter criteria JSON object into a plain english readable string.
 * Uses convertFilterBy function to convert the original JSON to an array of filter criteria objects.
 *
 * @param {Object} json - JSON containing all of the filter criteria
 *
 * @returns {String} A plain english string describing the filter criteria
 */
function filterJsonToPlainEnglish(json) {
    if (!json) {
        return '';
    }
    const converted = convertFilterBy(json, []),
        allCriteriaStrings = getCriteriaStrings(converted);

    return (json.$not ? 'NOT: ' : '') + allCriteriaStrings.join('<br>');
}

function flattenFilterJson(parsedCriteria, flattened) {
    _.forEach(parsedCriteria, criteria => {
        if (Array.isArray(criteria)) {
            flattenFilterJson(criteria, flattened);
        }
        else {
            flattened[criteria.id] = flattened[criteria.id] || [];
            flattened[criteria.id] = flattened[criteria.id].concat(criteria.criteria);
        }
    });
    return flattened;
}

function getFilteredObject(filteredObject, filterCriteria) {
    let logic = _.keys(filterCriteria);
    if (logic.length < 2 && filterLogicConstants.criteriaNodeLogics[logic[0]]) {
        logic = logic[0];
    }
    if (!Array.isArray(logic) && filterCriteria[logic]) {
        if (filterCriteria.$eq && filterCriteria.$eq[0] === 'is_control') {
            return;
        }
        let criteriaObject = filterCriteria[logic],
            isQuestion = /^((Question|Screener)\$(\d+)(?:\|Line\$(\d+))?)\|(?:Choice\$(\d+))$/.test(criteriaObject[0]),
            id = isQuestion ? filterLogicConstants.criteriaTypes.QUESTION : criteriaObject[0],
            criteria = isQuestion ? criteriaObject[0] : criteriaObject[1];
        if (!filteredObject[id]) {
            filteredObject[id] = [];
        }
        filteredObject[id].push(criteria);
    }
    else {
        _.forEach(_.values(filterCriteria), criteriaObject => {
            getFilteredObject(filteredObject, criteriaObject);
        });
    }
}

export default filterLogicService;
