import selectionTypes from './checkbox-indicator';

angular
    .module('multiDropdown', [])
    .directive('multiDropdown', multiDropdown)
    .controller('MultiDropdownCtrl', MultiDropdownCtrl);

/**
 * Multi-dropdown Directive
 *
 * @returns {object} The multi-dropdown directive definition
 */
function multiDropdown() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            choices: '=',
            initialState: '@',
            onChange: '<?',
            placeholder: '@',
        },
        templateUrl: 'shared-templates/multi-dropdown.html',
        controller: 'MultiDropdownCtrl',
        controllerAs: 'MultiDropdownVM',
    };
}

MultiDropdownCtrl.$inject = [
    '$scope',
];

/**
 * Multi-dropdown controller.
 *
 * @param {object} $scope
 */
function MultiDropdownCtrl($scope) {
    var MultiDropdownVM = this;
    const onChange = $scope.onChange || angular.noop;

    // VM exposed variables
    MultiDropdownVM.choices = [];
    MultiDropdownVM.groupSelectionStatus = selectionTypes.NONE;
    MultiDropdownVM.dropdownButtonText = $scope.placeholder || 'Select...';

    // VM exposed functions
    MultiDropdownVM.toggleChoice = toggleChoice;
    MultiDropdownVM.selectAll = selectAll;

    init();

    /**
     * Initialize the dropdown.
     */
    function init() {
        $scope.$watch('choices', latest => {
            MultiDropdownVM.choices = latest;
            MultiDropdownVM.groupSelectionStatus = selectionTypes.NONE;

            if ($scope.initialState === 'all') {
                selectAll();
            }
        });
    }

    /**
     * User has opted to toggle all options. This may select all, or select none,
     * depending on the current status of choices selected.
     */
    function selectAll() {
        let statusToApply = MultiDropdownVM.groupSelectionStatus !== selectionTypes.ALL;

        MultiDropdownVM.choices.forEach(choice => {
            choice.selected = statusToApply;
        });

        updateSelectionStatus();
    }

    /**
     * User has selected one option.
     *
     * @param {object} choice - The choice that the user is toggling
     */
    function toggleChoice(choice) {
        choice.selected = !choice.selected;
        updateSelectionStatus();
    }

    /**
     * Update the UI based on the selection status.
     */
    function updateSelectionStatus() {
        let numSelected = 0;
        let selectedChoice = '';

        // Figure out how many choices have been selected.
        // If only one, capture the choice name.
        MultiDropdownVM.choices.forEach(choice => {
            if (choice.selected) {
                selectedChoice = choice.name;
                numSelected++;
            }
        });

        // Depending on what selections have been made, update the checkbox and dropdown text.
        if (!numSelected) {
            MultiDropdownVM.groupSelectionStatus = selectionTypes.NONE;
            MultiDropdownVM.dropdownButtonText = 'No items selected';
        }
        else {
            MultiDropdownVM.groupSelectionStatus = numSelected === MultiDropdownVM.choices.length ? selectionTypes.ALL : selectionTypes.SOME;

            if (numSelected === 1) {
                MultiDropdownVM.dropdownButtonText = selectedChoice;
            }
            else if (MultiDropdownVM.groupSelectionStatus === selectionTypes.ALL) {
                MultiDropdownVM.dropdownButtonText = 'All items selected';
            }
            else {
                MultiDropdownVM.dropdownButtonText = numSelected + ' items selected';
            }
        }

        // If a callback was specified, call it now.
        if (onChange) {
            onChange(MultiDropdownVM.choices);
        }
    }
}
