import '../../../../../../common/js/inc/sv-notify';
import '../../../common-components/loading-button';
import '../../../common-components/checkbox-indicator';
import '../../../common-components/display-table';
import '../../../common-components/pub-sub';
import '../analysis-plan.service';
import './analysis-cut-category';
import questionAnswerCutsConstants from './question-answer-cuts-constants';

angular
    .module('questionAnswerCuts.view', [
        'common.dialog',
        'pubSub',
        'svNotify',
        'analysisCutCategory',
        'displayTable',
        'checkboxIndicator',
        'loadingButton',
        'analysisPlan.service',
    ])
    .directive('questionAnswerCuts', questionAnswerCuts)
    .controller('QuestionAnswerCutsCtrl', QuestionAnswerCutsCtrl);

/**
 * Question answer cuts directive object.
 *
 * @returns {object} - The definition object for the questionAnswerCuts directive
 */
function questionAnswerCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/question-answer-cuts.html',
        controller: 'QuestionAnswerCutsCtrl',
        controllerAs: 'QuestionAnswerCutsVM',
    };
}

QuestionAnswerCutsCtrl.$inject = [
    '$scope',
    '$notify',
    'dialog',
    'pubSubService',
    'analysisPlanService',
];

/**
 * The question answer cuts list controller.
 *
 * @param {object} $scope - The scope for this controller
 * @param {object} $notify - Notification service
 * @param {object} dialog - Service for popping a modal
 * @param {object} pubSubService - Pub/sub service
 * @param {object} analysisPlanService - Service for analysis plan CRUD
 */
function QuestionAnswerCutsCtrl($scope, $notify, dialog, pubSubService, analysisPlanService) {
    const QuestionAnswerCutsVM = this;
    let analysisCutCategoryMap;

    // VM exposed variables
    QuestionAnswerCutsVM.questionAnswerCuts = analysisPlanService.getQuestionAnswerCuts();
    QuestionAnswerCutsVM.tableHeaders = questionAnswerCutsConstants.tableHeaders;
    QuestionAnswerCutsVM.tableOptions = questionAnswerCutsConstants.tableOptions;
    QuestionAnswerCutsVM.ux = {
        loading: false,
    };

    // VM exposed functions
    QuestionAnswerCutsVM.save = save;

    init();

    function init() {
        analysisPlanService.getCutCategoryMap().then(cutCategoryMap => {
            analysisCutCategoryMap = cutCategoryMap;
            QuestionAnswerCutsVM.tableHeaders[0]._selected = analysisPlanService.initializeViewForCuts(QuestionAnswerCutsVM.questionAnswerCuts, cutCategoryMap);
        });
        pubSubService.subscribe('update-cut-category', $scope.$id, updateCutCategoryName);
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'update-cut-category',
            ], $scope.$id);
        });
    }

    /**
     * Callback function from cuts table.
     * Called when user updates cut category name, which overrides the question name.
     *
     * @param {object} cutCategory - Updated cut category
     */
    function updateCutCategoryName(cutCategory) {
        const editor = dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/cuts/analysis-cut-category.html',
            controller: 'AnalysisCutCategoryCtrl',
            controllerAs: 'AnalysisCutCategoryVM',
            windowClass: 'analysis-config modal-sv--full-heightt modal-sv--full-width',
            resolve: {
                category: () => {
                    return {
                        ...cutCategory,
                        name: cutCategory._displayNameFull, // Override name with full display name
                    };
                },
                categoryMap: () => {
                    return analysisCutCategoryMap;
                },
            },
        });
        editor.result.then(updatedCategory => {
            if (updatedCategory) {
                cutCategory._displayNameFull = updatedCategory.name;
                pubSubService.notify('refresh-table');
            }
        });
    }

    /**
     * When save button clicked, display loading spinner, then try to save all the cuts.
     */
    function save() {
        QuestionAnswerCutsVM.ux.loading = true;
        analysisPlanService.saveMultipleCutsCategories(QuestionAnswerCutsVM.questionAnswerCuts).then(onSave, onSaveFailed);
    }

    /**
     * After a save (successful or not), stop displaying the loading spinner
     */
    function onSave() {
        $scope.$evalAsync(() => {
            QuestionAnswerCutsVM.ux.loading = false;
        });
    }

    /**
     * Display a notification if save failed.
     */
    function onSaveFailed() {
        $notify.error('An error occurred saving question cuts');
        onSave();
    }
}
