angular.module('questionTypes.numeric', [])
    .service('questionTypes.numeric.service', numericService);

function numericService() {
    function toString(q) {
        return (q.statement.text || '') + '\n' +
            (q.statement.image ? '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n' : '') +
            '[numeric]\n' +
            (q.details && q.details.placeholder ? 'placeholder: ' + q.details.placeholder : '') + '\n';
    }

    function errors(q) {
        var errs = [];

        if (q.details.maxValue && q.details.minValue > q.details.maxValue) {
            errs.push('Min value can not be more than max value.');
        }

        if (q.details.maxValue && !angular.isNumber(q.details.maxValue)) {
            errs.push('Max value must be a number.');
        }

        if (q.details.minValue && !angular.isNumber(q.details.minValue)) {
            errs.push('Min value must be a number.');
        }

        return errs;
    }
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'numeric',
        name: 'Numeric input',
        description: 'Enter a numeric response',
        icon: 'fas fa-calculator',
        toString: toString,
        errors: errors,
        initialDetails: {
            minValue: 0,
            maxValue: null,
            placeholder: '',
        },
    };
}
