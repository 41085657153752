import '../../../../../common/js/inc/sv-notify';
import '../../common-components/sv-filters';
import '../../app-components/api-account-service';
import userBasicsConstants from './user-basics-constants';

angular.module('userBasicsModule', [
    'svNotify',
    'apiAccountService',
    'svFilters',
])
    .controller('userBasicsCtrl', userBasicsCtrl)
    .directive('userBasics', userBasics);

/**
 *
 */
function userBasics() {
    return {
        restrict: 'E',
        templateUrl: '/admin-templates/user-basics.html',
        controller: 'userBasicsCtrl',
        scope: {
            appData: '=',
        },
    };
}
userBasicsCtrl.$inject = [
    '$scope',
    '$rootScope',
    'accountService',
    '$notify',
];

/**
 * @param $scope
 * @param $rootScope
 * @param accountService
 * @param $notify
 */
function userBasicsCtrl($scope, $rootScope, accountService, $notify) {
    $scope.survey = $rootScope.survey;
    $scope.ux = {};
    $scope.ux.clientInfo = userBasicsConstants.clientInfoFields;
    $scope.saveClientInfo = saveClientInfo;
    $scope.editCompany = editCompany;
    $scope.notifyAssignment = notifyAssignment;

    /**
     * @param field
     */
    function saveClientInfo(field) {
        var params = {};

        params[field.name] = $scope.appData.currentUser[field.name];

        accountService.updateUser($scope.appData.currentUser.id, params).then(function(_response) {
            $notify.success('User info saved!', {
                clear: true,
                timeout: 1500,
            });
        });
    }

    function editCompany() {
        accountService.updateUser($scope.appData.currentUser.id, {
            organization: {
                name: $scope.appData.currentUser.organization.name,
            },
        })
            .then(function(user) {
                // Updated
                $scope.appData.currentUser.organization = user.organization;
            }, function() {
                // Error
            });
    }

    /**
     * @param email
     */
    function notifyAssignment(email) {
        var surveyUuid = $rootScope.survey ? $rootScope.survey.uuid : null;
        accountService.sendEmail('notify-assignment', {
            surveyUuid: surveyUuid,
            userId: $scope.appData.currentUser.id,
            notificationEmail: email,
        }).then(function(_response) {
            $notify.success('Email sent.');
        },
        function() {
            $notify.error('Error sending notification email');
        });
    }

    var userListener = $scope.$watch('appData.currentUser', function() {
        if ($scope.appData && $scope.appData.currentUser) {
            userListener();
        }
    });
}
