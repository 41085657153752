angular.module('wrap.ctrl', [])
    .controller('TargetingEditorWrapCtrl', ['$scope', function($scope) {
        function update() {
            $scope.requirement = ($scope.ux && $scope.ux.active) || {};
        }
        $scope.$watch('ux.active', update);
        update();
    }])
    .controller('AttributeWrapCtrl', ['$scope', function($scope) {
        function update(req) {
            $scope.requirement = req || null;
        }
        $scope.$watch(function() {
            return $scope.getRequirement && $scope.attribute && $scope.attribute.id ? $scope.getRequirement($scope.attribute.id) : null;
        }, update);
        update();
    }]);
