import '../common-components/pub-sub';

/**
 * @ngdoc directive
 * @name navTabs.directive //start with the module name. the second part is always directive. the directive name goes after the the column
 * @restrict 'A'
 * @element ANY
 * @scope
 **/
angular.module('navTabs', ['ui.router'])
    .directive('navTabs', navTabs)
    .controller('NavTabsCtrl', NavTabsCtrl);
/**
 *
 */
function navTabs() {
    return {
        templateUrl: () => {
            return '/shared-templates/nav-tabs.html';
        },
        restrict: 'A',
        scope: {
            /*
             * Visual options
             * @param {Object} options
             * @param {string} [orientation] - 'vertical' or 'horizontal', defaults to horizontal
             * @param {string} [stateChangeEvent] - when set, a state change will not be triggered, and an event will be fired instead
             */
            options: '=?',
            /*
             * Tabs to show
             * @param {Object[]} tabs
             * @param {string} tabs[].name - The name of the tab
             * @param {string} tabs[].id - The id value of the tab content
             * @param {string} [tabs[].view] - The ui-view name for the tab body content
             */
            tabs: '=',
            /*
             * Callback triggered when user selects a new tab
             */
            tabSelectCallback: '<?',
        },
        link: (scope, elem, _attr) => {
            if (scope.options && scope.options.orientation === 'vertical') {
                const scrollable = document.body.querySelector('.c-content-container.c-content-container__inner'),
                    nav = elem[0].querySelector('ul.nav'),
                    topNav = document.body.querySelector('.navbar.navbar-sv');
                scrollable.onscroll = _.debounce(() => {
                    if (scrollable.scrollTop >= elem[0].offsetTop) {
                        nav.classList.add('nav--sticky');
                        nav.style.top = `${topNav.clientHeight}px`;
                    }
                    else {
                        nav.classList.remove('nav--sticky');
                        nav.style.top = 0;
                    }
                });
            }
        },
        controller: 'NavTabsCtrl',
        controllerAs: 'NavTabsVM',
    };
}

NavTabsCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$transitions',
    'pubSubService',
];
/**
 * @param $rootScope
 * @param $scope
 * @param $state
 * @param $transitions
 * @param pubSubService
 */
function NavTabsCtrl($rootScope, $scope, $state, $transitions, pubSubService) {
    const NavTabsVM = this;
    let previousState,
        previousChildState;
    NavTabsVM.ux = {};
    NavTabsVM.auth = $rootScope.auth;
    NavTabsVM.goToState = goToState;
    NavTabsVM.dropdownSelectCallback = dropdownSelectCallback;

    init();

    function init() {
        const state = ($state.transition && $state.transition.to()) || $state.current;
        previousState = getViewByName(state.name, state.parent) || $scope.tabs[0];
        previousState.active = true;
        if (previousState.subViews && !previousState.selected) {
            previousState.selected = previousState.subViews[0];
        }
        if (!$scope.options || !$scope.options.stateChangeEvent) {
            handleTransitions();
        }
    }

    /**
     * @param name
     * @param parentName
     */
    function getViewByName(name, parentName) {
        const state = _.find($scope.tabs, view => {
            if (view.subViews) {
                return !!_.find(view.subViews, subView => {
                    return subView.view === name || subView.view === parentName;
                }) || view.view === name;
            }
            return view.view === name || view.view === parentName;
        });
        return state;
    }

    function handleTransitions() {
        const criteria = {};
        _.forEach($scope.tabs, tab => {
            criteria[tab.view] = true;
            if (tab.subViews) {
                _.forEach(tab.subViews, subView => {
                    criteria[subView.view] = true;
                });
            }
        });
        $transitions.onFinish({
            to: state => {
                return criteria[state.name];
            },
        }, transition => {
            delete previousState.active;
            previousState = getViewByName(transition.$to().name);
            previousState.active = true;
            if (previousState.subViews && !previousState.selected) {
                previousState.selected = previousState.subViews[0];
            }
        });
    }

    /**
     * @param view
     * @param e
     */
    function goToState(view, e) {
        let nextView;
        if (view.parent) {
            _.find($scope.tabs, {
                view: view.parent,
            }).selected = view;
        }
        if (e) {
            e.stopPropagation();
        }
        if ($scope.options && $scope.options.stateChangeEvent) {
            delete previousState.active;
            previousState = getViewByName(view.view, view.parent);
            if (view.subViews && !view.selected) {
                view.selected = view.subViews[0];
            }
            nextView = view.selected || view;
            pubSubService.notify($scope.options.stateChangeEvent, [nextView]);
            if (previousChildState) {
                delete previousChildState.active;
            }
            if (view.parent) {
                view.active = true;
                previousChildState = view;
            }
            else {
                previousChildState = void 0;
            }
            previousState.active = true;
        }
        else {
            nextView = view.subViews ? (view.selected || view.subViews[0]).view : view.view;
            $state.go(nextView);
        }
        if ($scope.tabSelectCallback) {
            $scope.tabSelectCallback(nextView, e);
        }
    }

    /**
     * @param view
     * @param _oldValue
     * @param _model
     * @param e
     */
    function dropdownSelectCallback(view, _oldValue, _model, e) {
        goToState(view, e);
    }
}
