import {
    schemeCategory10, scaleLinear,
} from 'd3';

const d3 = {
    schemeCategory10: schemeCategory10,
    scaleLinear: scaleLinear,
};

angular.module('svWordcloud.sevice', [])
    .service('svWordcloudService', svWordcloudService);

function svWordcloudService() {
    var Rotate = {
            NONE: function() {
                return 0;
            },
            90: function(word, index) {
                return calcRotation(index, ~~(Math.random() * 2) * 90);
            },
            45: function(word, index) {
                return calcRotation(index, ~~(Math.random() * 2) * 45);
            },
            20: function(word, index) {
                return calcRotation(index, (~~(Math.random() * 6) - 3) * 20);
            },
        },
        COLOR = {
            RANDOM: d3.schemeCategory10,
            RANGE: d3.scaleLinear().domain([
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                10,
                15,
                20,
                100,
            ]).range([
                '#ddd',
                '#ccc',
                '#bbb',
                '#aaa',
                '#999',
                '#888',
                '#777',
                '#666',
                '#555',
                '#444',
                '#333',
                '#222',
            ]),
            getRandom: function() {
                return this.RANDOM[getRandomInt()];
            },
        };

    function calcRotation(index, rotation) {
        // DEVNOTE: This function is used in two places 1)d3.layout and 2)draw method

        // keeps all items under the "index" horizontal we don't care how the other ones show up
        return index <= 5 ? 0 : rotation;
    }

    function getRandomInt() {
        return Math.floor(Math.random() * Math.floor(COLOR.RANDOM.length));
    }

    function constructWords(words, _numResponses) {
        var scaledWords = [],
            wordSum = 0,
            adjuster = 0,
            minSize = 12;
        //Getting some really weird behavior where iterating over words using forEach loops flips out
        // and thinks that objects with numeric values as the first keys are arrays.
        for (var word in words) {
            wordSum += words[word];
        }

        for (var word in words) {
            var count = words[word];
            adjuster = Math.pow((count / wordSum) * 250, 1.2);
            scaledWords.push({
                text: word,
                size: Math.min(minSize + adjuster, 100),
            });
        }

        return scaledWords.sort(function(a, b) {
            return b.size - a.size;
        });
    }

    return {
        constructWords: constructWords,
        calcRotation: calcRotation,
        Rotate: Rotate,
        COLOR: COLOR,
    };
}
