angular.module('svEditButton', [])
    .directive('svEditButton', [function() {
        return {
            restrict: 'ECA',
            templateUrl: '/shared-templates/sv-edit-button.html',
            scope: {
                onEdit: '=',
                onSave: '=',
                onCancel: '=',
                editing: '=',
            },
        };
    }]);
