angular.module('select.preview', [])
    .directive('selectPreview', selectPreview)
    .controller('SelectPreviewCtrl', SelectPreviewCtrl);

function selectPreview() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/select-preview.html',
        scope: {
            question: '=',
            isAdmin: '=',
        },
        controller: 'SelectPreviewCtrl',
    };
}
SelectPreviewCtrl.$inject = ['$scope'];

function SelectPreviewCtrl($scope) {
    var allOfTheAbove = {
        text: $scope.question.details.responseType === 'text' ? $scope.question.details.allOfTheAboveText : undefined,
        image: $scope.question.details.responseType === 'image' ? $scope.question.details.allOfTheAboveText : undefined,
        allOfTheAbove: true,
        pinned: true,
    };
    $scope.activateSliderPreview = activateSliderPreview;

    init();

    function init() {
        update();
        $scope.$watch('question.details', update, true);
    }

    function update() {
        $scope.choicesPreview = angular.copy($scope.question.details.choices);

        if ($scope.question.details.hasAllOfTheAbove) {
            if ($scope.question.details.hasNoneOfTheAbove) {
                $scope.choicesPreview.splice($scope.choicesPreview.length - 1, 0, allOfTheAbove);
            }
            else {
                $scope.choicesPreview.push(allOfTheAbove);
            }
        }
    }

    // Add jQueryUI widget to slider divs
    function activateSliderPreview() {
        $('.slider').slider({
            disabled: true,
        });
    }
}
