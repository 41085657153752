angular.module('introStatement.editor', [])
    .directive('introStatementEditor', introStatementEditor)
    .controller('IntroStatementEditorCtrl', IntroStatementEditorCtrl);

function introStatementEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/intro-statement-editor.html',
        scope: {
            question: '=',
        },
        controller: 'IntroStatementEditorCtrl',
        controllerAs: 'IntroStatementEditorVM',
    };
}
IntroStatementEditorCtrl.$inject = ['$rootScope', '$scope'];

function IntroStatementEditorCtrl($rootScope, $scope) {
    var IntroStatementEditorVM = this;
    // Response choice character count
    IntroStatementEditorVM.auth = $rootScope.auth;
    IntroStatementEditorVM.maxAnswerChars = 500;
    IntroStatementEditorVM.charCountColor = $rootScope.charCountColor;
    IntroStatementEditorVM.showCharCount = $rootScope.showCharCount;

    // If user clears out the delay value, remove the param completely instead of leaving it to clutter the JSON
    $scope.$watch('question.details.delay', function() {
        $scope.question.details.delay = $scope.question.details.delay || undefined;
    });
}
