import '../../../../common/js/inc/sv-notify';
import '../common-components/sv-dialog';
import '../app-components/api-account-service';
import '../admin-components/survey-admin-service';
import '../survey/api-survey-service';
angular.module('ClientInfoModule', [
    'ui.router',
    'svNotify',
    'common.dialog',
    'apiSurveyService',
    'surveyAdminService',
    'apiAccountService',
])
    .controller('ClientInfoCtrl', ClientInfoCtrl);
ClientInfoCtrl.$inject = [
    '$rootScope',
    '$stateParams',
    '$notify',
    'dialog',
    'surveyService',
    'adminService',
    'accountService',
];

/**
 * @param $rootScope
 * @param $stateParams
 * @param $notify
 * @param dialog
 * @param surveyService
 * @param adminService
 * @param accountService
 */
function ClientInfoCtrl($rootScope, $stateParams, $notify, dialog, surveyService, adminService, accountService) {
    var ClientInfoVM = this,
        modal;
    ClientInfoVM.survey = $rootScope.survey;
    ClientInfoVM.languages = [{
        code: 'en',
        name: 'English',
    },
    {
        code: 'de',
        name: 'German',
    },
    {
        code: 'es',
        name: 'Spanish',
    },
    {
        code: 'fr',
        name: 'French',
    },
    {
        code: 'pt',
        name: 'Portuguese',
    },
    {
        code: 'zh',
        name: 'Chinese',
    }];
    ClientInfoVM.ux = {
        surveyInfo: adminService.getSurveyInfoFields(),
        deadlineDraft: null,
    };
    ClientInfoVM.appData = {
        currentUser: null,
    };
    ClientInfoVM.saveSurveyInfo = saveSurveyInfo;
    ClientInfoVM.openClientInviteModal = openClientInviteModal;
    ClientInfoVM.openClientEmailModal = openClientEmailModal;

    init();

    function init() {
        if ($stateParams.surveyId) {
            ClientInfoVM.appData.currentUser = ClientInfoVM.survey.user;
        }
    }

    function openClientInviteModal() {
        modal = dialog.popup({
            templateUrl: '/modal-templates/client-invite-modal.html',
            controller: ClientInviteModalCtrl,
            controllerAs: 'ClientInviteModalVM',
        });
    }

    function openClientEmailModal() {
        modal = dialog.popup({
            templateUrl: '/modal-templates/client-email-modal.html',
            controller: ClientEmailModalCtrl,
            controllerAs: 'ClientEmailModalVM',
        });
    }

    function ClientEmailModalCtrl() {
        var ClientEmailModalVM = this;
        ClientEmailModalVM.ux = {};
        ClientEmailModalVM.emailTypes = adminService.getEmailTypes();
        ClientEmailModalVM.close = modal.close;
        ClientEmailModalVM.sendEmail = sendEmail;
        ClientEmailModalVM.hasCollaboratorOption = hasCollaboratorOption;

        /**
         * @param type
         */
        function sendEmail(type) {
            var params = {
                surveyuuid: $rootScope.survey.uuid,
            };
            if (ClientEmailModalVM.ux.includeCollaborators) {
                params.includeCollaborators = true;
            }
            accountService.sendEmail(type, params).then(function() {
                $notify.success('Email sent.', {
                    clear: true,
                    timeout: 1500,
                });
                ClientEmailModalVM.close();
            },
            function() {
                $notify.error('Error sending email.', {
                    clear: true,
                    timeout: 1500,
                });
                ClientEmailModalVM.close();
            });
        }

        /**
         * @param type
         */
        function hasCollaboratorOption(type) {
            return adminService.hasCollaboratorOption(type);
        }
    }

    function ClientInviteModalCtrl() {
        var ClientInviteModalVM = this;
        ClientInviteModalVM.ux = {};
        ClientInviteModalVM.close = modal.close;
        ClientInviteModalVM.sendClientInvite = sendClientInvite;

        function sendClientInvite() {
            adminService.sendClientInvite($rootScope.survey.uuid, $rootScope.survey.user.email).then(
                function(response) {
                    ClientInviteModalVM.ux.invite = response;
                    $notify.success('Invitation sent.', {
                        clear: true,
                        timeout: 1500,
                    });
                },
                function() {
                    $notify.error('Error sending invitation.', {
                        clear: true,
                        timeout: 1500,
                    });
                }
            );
        }
    }

    /**
     * @param field
     */
    function saveSurveyInfo(field) {
        var params = {};
        params[field.name] = ClientInfoVM.survey[field.name];
        surveyService.updateSurvey(ClientInfoVM.survey.uuid, params).then(function() {
            $notify.success('Survey info saved!', {
                clear: true,
                timeout: 1500,
            });
            ClientInfoVM.ux.deadlineDraft = null;
        });
    }
}
