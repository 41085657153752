import 'angular-sanitize';
import _ from 'lodash';
import './paginator';
import {
    DAY_IN_MILLISECONDS, stringToDate,
} from '../common-components/datepicker-service';
import '../common-components/pub-sub';
import '../common-components/checkbox-indicator';

export default angular.module('displayTable', [
    'ngSanitize',
    'checkboxIndicator',
    'common.paginator',
    'pubSub',
])
    .directive('displayTable', displayTable)
    .controller('DisplayTableCtrl', DisplayTableCtrl);

/**
 * Display table directive.
 *
 * @returns {object} Display table definition object
 */
function displayTable() {
    return {
        templateUrl: '/shared-templates/display-table.html',
        restrict: 'E',
        scope: {
            tableHeaders: '=',
            tableId: '@?',
            /*
             * One of table body or paginator required
             */
            tableBody: '=?',
            paginator: '=?',
            tableFooter: '=?',
            /*
             * Table options
             * @param {Object} [tableOptions]
             */
            tableOptions: '=?',
            /*
             * Whether to show pagination
             * @param {Object} [paginated]
             */
            paginationOptions: '=?',
            /*
             * Callback for page navigation
             * @param {Function} [onPaginateChange]
             */
            onPaginateChange: '<?',
            /*
             * External filter function to be applied on the table
             * @param {Function} [filterFn]
             */
            filterFn: '<?',
            actionCallback: '<?',
            loading: '=?',
        },
        controller: 'DisplayTableCtrl',
        controllerAs: 'DisplayTableVM',
    };
}

DisplayTableCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    'Paginator',
    'pubSubService',
];

/**
 * Display table controller.
 *
 * @param {object} $rootScope - Application root scope
 * @param {object} $scope - Scope of this controller
 * @param {object} $timeout - Angular timeout utility
 * @param {object} Paginator - Paginator service
 * @param {object} pubSubService - Pub/sub service
 */
function DisplayTableCtrl($rootScope, $scope, $timeout, Paginator, pubSubService) {
    const DisplayTableVM = this,
        ITEMS_PER_PAGE = 25;
    let setStartDate,
        selectAll,
        nested;
    DisplayTableVM.onPaginateChange = $scope.onPaginateChange || onPaginateChange;
    DisplayTableVM.actionCallback = $scope.actionCallback || angular.noop;
    DisplayTableVM.ux = {};
    DisplayTableVM.updateDateRange = updateDateRange;
    DisplayTableVM.toggleDatepicker = toggleDatepicker;
    DisplayTableVM.handleEditButton = handleEditButton;
    DisplayTableVM.refreshTable = refreshTable;
    DisplayTableVM.setOrderBy = setOrderBy;
    DisplayTableVM.notifySubscriber = notifySubscriber;
    DisplayTableVM.handleDropdown = handleDropdown;
    DisplayTableVM.toggleSelectionForAll = toggleSelectionForAll;
    DisplayTableVM.toggleSelection = toggleSelection;
    DisplayTableVM.onSelectionChanged = onSelectionChanged;

    init();

    function init() {
        $scope.tableOptions = $scope.tableOptions || {};
        if ($scope.tableOptions.dateRange) {
            DisplayTableVM.dateRange = $scope.tableOptions.dateRange;
            DisplayTableVM.selectedDate = DisplayTableVM.dateRange.endDate;
            DisplayTableVM.dateRangeString = stringToDate(DisplayTableVM.dateRange.startDate, DisplayTableVM.dateRange.endDate);
            DisplayTableVM.datePickerOptions = {
                minDate: new Date($rootScope.survey.started),
                maxDate: DisplayTableVM.dateRange.endDate,
                customClass: isInDateRange,
                showWeeks: false,
            };
        }
        selectAll = $scope.tableHeaders[0]._selected === 2;
        pubSubService.subscribe('set-paginator', $scope.$id, setPaginator);
        pubSubService.subscribe('refresh-table', $scope.$id, refreshTable);
        pubSubService.subscribe(`refresh-table-${$scope.tableId}`, $scope.$id, refreshTable);
        if ($scope.paginator || $scope.tableBody) {
            setPaginator();
        }
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'refresh-table',
                'set-paginator',
                `refresh-table-${$scope.tableId}`,
            ], $scope.$id);
        });
    }

    /**
     * Set the paginator.
     *
     * @param {object} paginator - Paginator
     */
    function setPaginator(paginator) {
        DisplayTableVM.paginator = paginator || $scope.paginator || new Paginator({
            list: $scope.tableBody,
            itemsPerPage: $scope.paginationOptions && $scope.paginationOptions.itemsPerPage || ITEMS_PER_PAGE,
        });
        refreshTable();
        _.forEach($scope.tableHeader, header => {
            if (header.calendar) {
                header.isInDateRage = params => {
                    return header.dateRange.startDate <= params.date && params.date <= header.dateRange.endDate;
                };
                header.ux = {};
            }
        });
    }

    /**
     * Toggle selection for all table rows.
     *
     * @param {object} header - Table header object
     * @param {Event} e - Click event
     */
    function toggleSelectionForAll(header, e) {
        setNested();
        if (header._expanded) {
            e.stopPropagation();
        }
        _.forEach($scope.tableBody, row => {
            _.forEach(row[nested], option => {
                option._selected = !selectAll;
            });
            row._selected = selectAll ? 0 : 2;
        });
        selectAll = !selectAll;
        header._selected = selectAll ? 2 : 0;
    }

    /**
     * Toggle the selection that was clicked.
     *
     * @param {object} row - The row to be toggled
     */
    function toggleSelection(row) {
        setNested();
        const selection = row._selected === 2,
            headerCheckbox = _.find($scope.tableHeaders, 'selectAll');
        let selectedRows;
        _.forEach(row[nested], option => {
            option._selected = !selection;
        });
        row._selected = selection ? 0 : 2;
        // If nothing was selected, but something became selected
        // or if everything was selected, but something got deselecte
        if ((!headerCheckbox._selected && row._selected) || (headerCheckbox._selected === 2 && !row._selected)) {
            headerCheckbox._selected = 1;
            return;
        }
        selectedRows = _.groupBy($scope.tableBody, '_selected');
        // If some things were selected, but something got deselected/selected
        if (headerCheckbox._selected === '1') {
            headerCheckbox._selected = (selectedRows[0] || []).length === $scope.tableBody.length ? 0 : (selectedRows[2] || []).length === $scope.tableBody.length ? 2 : 1;
        }
    }

    function setNested() {
        nested = nested || ($scope.tableOptions && ($scope.tableOptions.nestedRowField || (_.find($scope.tableOptions.nestedRows, 'nestedRowField') || {}).nestedRowField));
    }

    /**
     * Callback for when selection is changed.
     *
     * @param {object} row - The selected row
     */
    function onSelectionChanged(row) {
        setNested();
        const list = ($scope.tableOptions.isNested ? $scope.tableOptions.parent : row)[nested],
            numSelected = (_.groupBy(list, '_selected').true || []).length;
        if ($scope.tableOptions.isNested) {
            $scope.tableOptions.parent._selected = numSelected === list.length ? 2 : numSelected ? 1 : 0;
        }
        else {
            row._selected = numSelected === list.length ? 2 : numSelected ? 1 : 0;
        }
    }

    /**
     * Determine if is in date range.
     *
     * @param {object} params - Params object, which contains date
     * @returns {boolean} - Is in date range or not
     */
    function isInDateRange(params) {
        return DisplayTableVM.dateRange.startDate <= params.date && params.date <= DisplayTableVM.dateRange.endDate ? 'active' : '';
    }

    /**
     * Callback to set ordering for a table column on user click.
     *
     * @param {string} type - The column type to order by
     */
    function setOrderBy(type) {
        if (DisplayTableVM.ux.orderBy === type) {
            DisplayTableVM.ux.reverse = !DisplayTableVM.ux.reverse;
        }
        else {
            DisplayTableVM.ux.orderBy = type;
        }
        DisplayTableVM.paginator.sortPageData(DisplayTableVM.ux.orderBy, DisplayTableVM.ux.reverse);
        DisplayTableVM.paginator.getPageData();
    }

    /**
     * Update the date range in date picker.
     */
    function updateDateRange() {
        let currentDateString = DisplayTableVM.dateRangeString;
        if (setStartDate && DisplayTableVM.dateRange.startDate - DisplayTableVM.selectedDate < DAY_IN_MILLISECONDS) {
            DisplayTableVM.dateRange.endDate = DisplayTableVM.selectedDate;
            setStartDate = void 0;
        }
        else {
            DisplayTableVM.dateRange.startDate = DisplayTableVM.selectedDate;
            setStartDate = true;
        }
        DisplayTableVM.dateRangeString = stringToDate(DisplayTableVM.dateRange.startDate, DisplayTableVM.dateRange.endDate);
        pubSubService.notify('update-date-range', [currentDateString]);
    }

    /**
     * Toggle date picker open/closed.
     *
     * @param {Event} $event - Click event
     */
    function toggleDatepicker($event) {
        if ($event && DisplayTableVM.ux.dateRangeOpen) {
            $event.stopPropagation();
        }
        else {
            DisplayTableVM.ux.dateRangeOpen = !DisplayTableVM.ux.dateRangeOpen;
        }
    }

    /**
     * Callback for when paginator changes.
     */
    function onPaginateChange() {
        _.forEach(DisplayTableVM.paginator.data, row => {
            // Collapase row when it is expanded
            // Fixes problem where inner display table does not update with new data
            if (row._expanded) {
                row._expanded = false;
                $timeout(() => {
                    row._expanded = true;
                });
            }
        });
    }

    function refreshTable() {
        DisplayTableVM.paginator.refreshList(filterTable);
        DisplayTableVM.paginator.getPageData();
    }

    /**
     * Filter table rows by search text.
     *
     * @param {object} row - A row of table data
     * @returns {boolean} Does this row satisfy the filter?
     */
    function filterTable(row) {
        if (DisplayTableVM.ux.search) {
            const rowString = JSON.stringify(_.values(row)),
                regex = new RegExp(DisplayTableVM.ux.search, 'i'),
                filtered = regex.test(rowString);
            if (filtered) {
                return $scope.filterFn ? $scope.filterFn.apply([row]) : true;
            }
            return false;
        }
        return $scope.filterFn ? $scope.filterFn.apply(this, [row]) : true;
    }

    /**
     * Notifies subscribers/listeners that event has been triggered.
     *
     * @param {string} event - The name of the event that was triggered
     * @param {object} row - The row of data that is associated with this event
     * @param {object} oldValue - Old data value
     * @param {object} column - The column that is associated with this event
     */
    function notifySubscriber(event, row, oldValue, column) {
        pubSubService.notify(event, [$scope.tableOptions.parent || row, oldValue || row]);
        if (column && column.columnType === 'input') {
            onSelectionChanged(row);
        }
    }

    /**
     * Handle dropdown selection.
     *
     * @param {object} header - Header object
     * @param {object} oldValue - Previous value
     * @param {object} item - Selected itemn
     */
    function handleDropdown(header, oldValue, item) {
        if (header.eventName) {
            $timeout(() => {
                notifySubscriber(header.eventName, item || header, oldValue);
            }, 1);
        }
    }

    /**
     * Handle edit button click.
     *
     * @param {object} row - Selected row
     * @param {object} eventName - The event associated with the button
     */
    function handleEditButton(row, eventName) {
        row._editing = !row._editing;
        if (!row._editing) {
            notifySubscriber(eventName, $scope.tableOptions.parent || row);
        }
    }
}
