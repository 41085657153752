import '../../common-components/pub-sub';
import '../../common-components/paginator';
import adMeasurementConstants from '../ad-measurement.constants';
import adMeasurementService from '../ad-measurement.service';

angular.module('audienceConfiguration.view', [
    'pubSub', 'common.paginator',
])
    .controller('AudienceConfigurationCtrl', AudienceConfigurationCtrl);

AudienceConfigurationCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'pubSubService',
    'beaconControlConfigurations',
    'audienceConfigurationToEdit',
    'audienceConfigurationNames',
];

function AudienceConfigurationCtrl($scope, $uibModalInstance, pubSubService, beaconControlConfigurations, audienceConfigurationToEdit, audienceConfigurationNames) {
    var AudienceConfigurationVM = this,
        stepManager = adMeasurementService.getStepManager(!!(audienceConfigurationToEdit || {}).uuid);

    // VM exposed variables
    AudienceConfigurationVM.beaconControlConfigurations = beaconControlConfigurations;
    AudienceConfigurationVM.audienceConfigurationNames = audienceConfigurationNames;
    AudienceConfigurationVM.audienceConfiguration = audienceConfigurationToEdit;
    AudienceConfigurationVM.stepManager = stepManager;
    AudienceConfigurationVM.stepOptions = adMeasurementConstants.audienceConfigurationStepTypes;
    AudienceConfigurationVM.steps = stepManager.steps;
    AudienceConfigurationVM.currentStep = stepManager.currentStep;

    // VM exposed functions
    AudienceConfigurationVM.closeModal = $uibModalInstance.close;

    init();

    function init() {
        if (audienceConfigurationToEdit) {
            AudienceConfigurationVM.audienceConfiguration = angular.copy(audienceConfigurationToEdit);
            AudienceConfigurationVM.audienceConfiguration.beaconDefinitions = [];
            _.forEach(AudienceConfigurationVM.audienceConfiguration.beaconPoolBeacons, function(beaconPoolBeacon) {
                AudienceConfigurationVM.audienceConfiguration.beaconDefinitions.push({
                    exportName: beaconPoolBeacon.beaconDefinition.exportName,
                    uuid: beaconPoolBeacon.beaconDefinition.uuid,
                    suppressed: beaconPoolBeacon.beaconInclusion === adMeasurementConstants.SUPPRESSED,
                    countForFrequency: beaconPoolBeacon.countForFrequency,
                });
            });
            delete AudienceConfigurationVM.audienceConfiguration.beaconPoolBeacons;
        }
        else {
            AudienceConfigurationVM.audienceConfiguration = {
                beaconDefinitions: [],
            };
        }
        pubSubService.subscribe('close-audience-configuration-modal', $scope.$id, AudienceConfigurationVM.closeModal);
        $scope.$on('$destroy', () => {
            pubSubService.destroy('close-audience-configuration-modal', $scope.$id);
        });
    }
}
