import '../survey/survey-to-string-service';
angular.module('surveyText.modal', ['surveyToStringService'])
    .controller('SurveyTextCtrl', SurveyTextCtrl);
SurveyTextCtrl.$inject = [
    '$rootScope',
    '$uibModalInstance',
    'STSS',
];

function SurveyTextCtrl($rootScope, $uibModalInstance, STSS) {
    var SurveyTextVM = this;
    SurveyTextVM.surveyText = STSS.surveyToString($rootScope.survey);
    SurveyTextVM.close = $uibModalInstance.close;
    SurveyTextVM.copyToClipboard = copyToClipboard;

    function copyToClipboard() {
        var inp = document.getElementById('copy-to-clipboard');
        if (inp && inp.select) {
            inp.select();
            try {
                document.execCommand('copy');
                inp.blur();
            }
            catch (err) {
                SurveyTextVM.copyError = 'Unable to copy text, please press Ctrl/Cmd + C to copy.';
            }
        }
    }
}
