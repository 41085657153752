import '../../../../common/js/inc/sv-notify';
import '../common-components/pub-sub';
import '../filter-and-compare/filter-and-compare-model-manager';

angular.module('dashboardResults.gridChart', [
    'svNotify',
    'pubSub',
    'filterAndCompare.modelManager',
])
    .controller('DashboardResultsGridChartCtrl', DashboardResultsGridChartCtrl)
    .directive('dashboardResultsGridChart', dashboardResultsGridChart);

/**
 *
 */
function dashboardResultsGridChart() {
    return {
        restrict: 'E',
        require: '^GridChartCtrl',
        controller: 'DashboardResultsGridChartCtrl',
        controllerAs: 'DashboardResultsGridChartVM',
        scope: false,
    };
}
DashboardResultsGridChartCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'filterAndCompareModelManager',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $state
 * @param filterAndCompareModelManager
 */
function DashboardResultsGridChartCtrl($rootScope, $scope, $state, filterAndCompareModelManager) {
    var DashboardResultsGridChartVM = this,
        GridChartVM = $scope.GridChartVM;
    DashboardResultsGridChartVM.visualMetadata = {
        lines: [],
    };
    DashboardResultsGridChartVM.ux = {
        fullWidth: $scope.parentFullWidth,
    };
}
