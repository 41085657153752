const impressionParameterConstants = {
    beaconDefinitionTableHeaders: [{
        name: '',
        options: [{
            value: true,
            name: '&nbsp;',
        }],
        columnType: 'input',
        inputType: 'radio',
        model: '_selected',
        iterable: true,
        eventName: 'select-beacon-definition',
    }, {
        name: 'Tag name',
        sortable: true,
        columnType: 'text',
        value: 'exportName',
    }],

    csvErrorsTableHeaders: [{
        name: 'Row #',
        sortable: true,
        columnType: 'text',
        value: 'row',
    }, {
        name: 'Warning message',
        sortable: true,
        columnType: 'text',
        value: 'warningMessage',
    }],

    placementReportUploadStatusTabs: [{
        name: 'Warnings',
        id: 'warnings',
        displayTableHeaders: [{
            name: 'Warning message',
            sortable: true,
            columnType: 'text',
            value: 'warningMessage',
        }],
    },
    {
        name: 'Insertions',
        id: 'loadedValues',
        displayTableHeaders: [{
            name: 'Parameter',
            sortable: true,
            columnType: 'text',
            value: 'value',
        },
        {
            name: 'Value',
            sortable: true,
            columnType: 'text',
            value: 'impressionParameter',
        },
        {
            name: 'Mapping',
            sortable: true,
            columnType: 'text',
            value: 'displayName',
        }],
    },
    {
        name: 'Ignored duplicates',
        id: 'duplicateValues',
        displayTableHeaders: [{
            name: 'Parameter',
            sortable: true,
            columnType: 'text',
            value: 'value',
        },
        {
            name: 'Value',
            sortable: true,
            columnType: 'text',
            value: 'impressionParameter',
        },
        {
            name: 'Mapping',
            sortable: true,
            columnType: 'text',
            value: 'displayName',
        }],
    }],
};

export default impressionParameterConstants;
