import '@uirouter/angularjs';
import './retargeting-service';
import '../common-components/sv-loading-spinner';
import '../../../../common/js/inc/sv-notify';

angular.module('RetargetingAdminModule', [
    'ui.router',
    'retargetingService',
    'svLoadingSpinner',
    'svNotify',
])
    .controller('RetargetingAdminCtrl', RetargetingAdminCtrl);

RetargetingAdminCtrl.$inject = [
    '$rootScope',
    '$scope',
    'retargetingService',
    '$notify',
];

function RetargetingAdminCtrl($rootScope, $scope, retargetingService, $notify) {
    const RetargetingAdminVM = this;

    RetargetingAdminVM.auth = $rootScope.auth;
    RetargetingAdminVM.ux = {
        ready: false,
        accounts: null,
        selectedRetargetingAccount: null,
        surveyUuid: null,
    };

    RetargetingAdminVM.refreshAccounts = refreshAccounts;
    RetargetingAdminVM.updateSurveysRetargetingId = updateSurveysRetargetingId;

    init();

    function init() {
        refreshAccounts();
    }

    function refreshAccounts() {
        RetargetingAdminVM.ux.ready = false;
        retargetingService.getRetargetingAccounts().then(retargetingAccounts => {
            RetargetingAdminVM.ux.ready = true;
            $scope.$evalAsync(() => {
                RetargetingAdminVM.ux.accounts = retargetingAccounts;
            });
        },
        error => {
            RetargetingAdminVM.ux.ready = true;
        });
    }

    function updateSurveysRetargetingId() {
        RetargetingAdminVM.ux.ready = false;

        if (RetargetingAdminVM.ux.selectedRetargetingAccount && RetargetingAdminVM.ux.surveyUuid) {
            retargetingService.updateSurveysRetargetingId(RetargetingAdminVM.ux.selectedRetargetingAccount, RetargetingAdminVM.ux.surveyUuid).then(response => {
                RetargetingAdminVM.ux.ready = true;
                RetargetingAdminVM.ux.surveyUuid = null;
                RetargetingAdminVM.ux.selectedRetargetingAccount = null;
                $notify.success('Survey\'s retargeting account updated!', {
                    clear: true,
                    timeout: 3000,
                });
            },
            error => {
                RetargetingAdminVM.ux.surveyUuid = null;
                RetargetingAdminVM.ux.selectedRetargetingAccount = null;
                RetargetingAdminVM.ux.ready = true;
                $notify.error('Error retargeting this survey, please check the survey uuid and try again, or contact the administrator', {
                    clear: true,
                    timeout: 3000,
                });
            });
        }
    }
}
