import '../question-types/question-types-service';
import '../question-types/question-type-groups';
import '../targeting-attributes/targeting-attribute-groups';

angular.module('surveyToStringService', ['questionTypeGroups.service', 'targetingAttributeGroups.service'])
    .service('STSS', STSS);
STSS.$inject = [
    'questionTypeGroupsService', 'targetingAttributeGroups',
];

function STSS(QTGS, TAGS) {
    function questionToString(question) {
        if (QTGS.types[question.type]) {
            return QTGS.types[question.type].toString(question);
        }

        // This should never happen, but just in case...
        return '* unknown question type *';
    }

    function questionsToString(survey) {
        let str = '',
            questions = [];

        questions = survey.screeningQuestions ? angular.copy(survey.screeningQuestions) : questions;
        questions = survey.questions ? questions.concat(survey.questions) : questions;

        _.forEach(questions, function(q, i) {
            var typePrototype = QTGS.types[q.type];
            if (typePrototype) {
                str += (i + 1).toString() + '. ' + QTGS.types[q.type].toString(q) + ' \n';
            }
        });
        return str;
    }

    function targetingReqsToString(survey) {
        var str = '';

        if (!survey.targetingReqs || !survey.targetingReqs.length) {
            str = 'General US population\n';
        }
        else {
            angular.forEach(survey.targetingReqs, function(r, i) {
                var attrPrototype = TAGS.attributes[r.attribute];
                if (attrPrototype) {
                    str += attrPrototype.name + ': ' + attrPrototype.toString(r) + ' \n';
                }
            });
        }
        return str;
    }

    function surveyToString(survey) {
        var str = 'Questions: \n' + questionsToString(survey) +
            ' Sample size: ' + (survey.latestCollectionPeriod.sampleSize || 0).toString() + '\n' +
            ' Targeting reqs: ' + targetingReqsToString(survey) + '\n';

        return str;
    }

    return {
        surveyToString: surveyToString,
        questionToString: questionToString,
        questionsToString: questionsToString,
        targetingReqsToString: targetingReqsToString,
    };
}
