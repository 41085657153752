import httpDeferred from '../../../../common/js/http-deferred';
import U from '../../../../common/js/util';

const ResponseStatuses = {
        ACCEPTED: 'accepted',
        REJECTED: 'rejected',
        PENDING: 'pending',
    },
    RejectionReasons = {
        SPEED: 'speed',
        SCREENED: 'screened',
        SKIPPED: 'skipped',
        ABANDONED: 'abandoned',
        MANUAL: 'manual',
    },
    ExportTypes = {
        EXCEL: 'excel',
        CSV: 'csv',
    };

/**
 * Gets a list of Survey responses.
 *
 * @param {string} surveyUuid
 * @param {*} params
 * @returns {Promise}
 */
function listResponses(surveyUuid, params) {
    // Params - max, offset, responseStatus
    return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/survey-response' + U.buildParamString(params));
}

/**
 * Sets a response status and if needed the rejection reason.
 *
 * @param {string} responseUuid
 * @param {string} status
 * @param {string} rejectionReason
 * @returns {Promise}
 */
function setStatus(responseUuid, status, rejectionReason) {
    var params = {
        responseStatus: status,
    };
    if (status === ResponseStatuses.REJECTED) {
        params.rejectionReason = rejectionReason;
    }
    return updateResponse(responseUuid, params);
}

/**
 * Updates a response.
 *
 * @param {string} responseUuid
 * @param {*} params
 * @returns {Promise}
 */
function updateResponse(responseUuid, params) {
    return httpDeferred.put(httpDeferred.dashboardHost + '/v1/survey-response/' + responseUuid, params);
}

/**
 * Gets the response statuses.
 *
 * @returns {*}
 */
function listResponseStatuses() {
    return ResponseStatuses;
}

/**
 * Gets the rejection reasons.
 *
 * @returns {*}
 */
function listRejectionReasons() {
    return RejectionReasons;
}

/**
 * Gets the export types.
 *
 * @returns {*}
 */
function listExportTypes() {
    return ExportTypes;
}

/**
 * Requests the Survey's export.
 *
 * @param {string} surveyUuid
 * @param {*} params
 * @returns {Promise}
 */
function createXlsExport(surveyUuid, params) {
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/export/export', params);
}

/**
 * Requests the Survey's csv export.
 *
 * @param {string} surveyUuid
 * @param {*} params
 * @returns {Promise}
 */
function createCsvExport(surveyUuid, params) {
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/export/csv-export', params);
}

/**
 * Requests the Survey's term report.
 *
 * @param {string} surveyUuid
 * @param {*} params
 * @returns {Promise}
 */
function createTermReport(surveyUuid, params) {
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/export/term-report', params);
}

/**
 * Requests the Survey's export request.
 *
 * @param {string} surveyUuid
 * @param {*} params
 * @returns {Promise}
 */
function requestExport(surveyUuid, params) {
    return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/export/export-request', params);
}

const responseService = {
    listResponses,
    setStatus,
    updateResponse,
    listResponseStatuses,
    listRejectionReasons,
    listExportTypes,
    createXlsExport,
    createCsvExport,
    createTermReport,
    requestExport,
};
export default responseService;
