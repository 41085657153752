const ITEMS_PER_PAGE_DEFAULT = 10;

export default class Paginate {
    constructor(params) {
        if (!params.func) {
            console.error('func is a required field');
        }
        this.itemsPerPage = params.itemsPerPage || ITEMS_PER_PAGE_DEFAULT;
        this.page = params.startPage || 0;
        this.hasNext = false;
        this.hasPrev = false;
        this.callback = params.func;
    }
    getPage() {
        let offset = this.page * this.itemsPerPage,
            lastItem = offset + this.itemsPerPage;

        // Retrieve the next page's data by calling func with searchParams (the last params applied to the fetch call), with updated offset and max fields
        if (lastItem >= this.data.length) {
            this.callback(_.extend({
                offset: lastItem,
                max: this.itemsPerPage,
            }, this.searchParams)).then(results => {
                this.data = this.data.concat(results);
                this.hasNext = this.data.length > lastItem;
            });
        }
        else {
            this.hasNext = this.data.length > lastItem;
        }
        this.hasPrev = this.page > 0;

        return {
            data: this.data.slice(offset, offset + this.itemsPerPage),
            page: this.page,
            hasNext: this.hasNext,
            hasPrev: this.hasPrev,
        };
    }
    getNext() {
        return this.getPage(++this.page);
    }

    getPrev() {
        return this.getPage(--this.page);
    }

    fetch(search) {
    //I wish I could tell you wtf this line is doing
        this.page = _.isEqual(search || {}, this.searchParams || {}) ? this.page : 0;
        this.searchParams = search;
        return new Promise(resolve => {
        // Fetch two pages the first time, so we know if there's a next
            this.callback(_.extend({
                offset: this.page * this.itemsPerPage,
                max: this.itemsPerPage * 2,
            }, this.searchParams || {})).then(results => {
                this.data = results;
                // I don't know why we have to call params.func again via getPage, but we do
                resolve(this.getPage());
            });
        });
    }
}
