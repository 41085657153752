/* eslint camelcase: "off" */
import _ from 'lodash';
import filterLogicConstants from './filter-logic-constants';
import getLogicPhraseById from './filter-logic-phrases';

/**
 * Class for filter logic model
 *
 * @param {object} survey
 * @param {boolean} addExtraDemographic
 * @param {boolean} isUsRegion
 */
function FilterLogicModel(survey, addExtraDemographic, isUsRegion = true) {
    let statsConfig = survey._statsConfig,
        customVariables = statsConfig && statsConfig.filters.customVariables && statsConfig.filters.customVariables.options,
        beaconPools = statsConfig && statsConfig.filters.Beacon$Pool && statsConfig.filters.Beacon$Pool.options;

    this.Demographic$Age = {
        id: filterLogicConstants.criteriaTypes.AGE,
        name: 'Age',
        index: 0,
        logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.AGE),
        isAvailable: true,
        options: filterLogicConstants.Demographic$AgeOptions,
    };
    this.Demographic$Gender = {
        id: filterLogicConstants.criteriaTypes.GENDER,
        index: 1,
        name: 'Gender',
        logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.GENDER),
        isAvailable: true,
        options: filterLogicConstants.Demographic$GenderOptions,
    };
    if (addExtraDemographic) {
        if (isUsRegion) {
            this.Demographic$State = {
                id: 'Demographic$State',
                name: 'State',
                index: 2,
                logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.STATE),
                isAvailable: true,
                options: filterLogicConstants.Demographic$StateOptions,
            };
            this.Demographic$Region = {
                id: filterLogicConstants.criteriaTypes.REGION,
                name: 'Region',
                index: 3,
                logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.REGION),
                isAvailable: true,
                options: filterLogicConstants.Demographic$RegionOptions,
            };
        }
        this.Operating$System = {
            id: filterLogicConstants.criteriaTypes.OPERATING_SYSTEM,
            name: 'Operating System',
            index: 4,
            logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.OPERATING_SYSTEM),
            isAvailable: true,
            options: filterLogicConstants.Operating$SystemOptions,
        };
    }
    if (beaconPools) {
        this.Beacon$Pool = {
            id: filterLogicConstants.criteriaTypes.BEACON_POOL,
            index: 5,
            name: 'Beacon pool',
            logicPhrase: getLogicPhraseById(filterLogicConstants.criteriaTypes.BEACON_POOL),
            isAvailable: true,
            options: _.map(beaconPools, option => {
                return {
                    id: option.id,
                    set: true,
                    isAvailable: true,
                    name: option.name,
                    // eslint-disable-next-line no-undef
                    displayNameFull: U.stringRemoveHtml(option.name),
                    // eslint-disable-next-line no-undef
                    displayName: U.limitStrLength(option.name, 45, {
                        removeHtml: true,
                    }),
                };
            }),
        };
    }

    if (statsConfig) {
        this.Question$Choice = {
            id: 'Question$Choice',
            name: 'Question choice',
            index: 6,
            isAvailable: true,
            hasSubmenu: true,
            options: _.map(statsConfig.filters.questions.options, option => {
                let questionLogicPhraseType = 'Question$Choice';
                if (option.type === 'multiSelect') {
                    questionLogicPhraseType = 'Question$ChoiceExtended';
                }
                else if (option.type === 'longFreeResponse') {
                    questionLogicPhraseType = 'Question$ChoiceFuzzy';
                    option.enabled = addExtraDemographic;
                    option.isTextInput = addExtraDemographic;
                    option.disabled = !option.enabled;
                }
                option.logicPhrase = getLogicPhraseById(questionLogicPhraseType);
                return {
                    ...option,
                };
            }),
        };
    }

    this.Custom$Variable = {
        id: filterLogicConstants.criteriaTypes.CUSTOM_VARIABLE,
        name: 'Variable',
        index: 7,
        hasSubmenu: true,
        isAvailable: true,
        options: buildVariableChoiceFilter(customVariables || []),
    };

    disableDemographicsByTargetingReqs(survey.targetingReqs, this);
}

/**
 * Disable the demographics by targeting reqs
 *
 * @param {object[]} targetingReqs
 * @param {object} filterModel
 */
function disableDemographicsByTargetingReqs(targetingReqs, filterModel) {
    _.forEach(targetingReqs, req => {
        switch (req.attribute) {
            case 'age':
                disableTargetingReq(req, filterModel.Demographic$Age.options);
                break;
            case 'region':
                disableTargetingReq(req, (filterModel.Demographic$State || {}).options);
                break;
            case 'gender':
                delete filterModel.Demographic$Gender;
                break;
            default:
                break;
        }
    });
}

/**
 * Disable the targeting req
 *
 * @param {object[]} targetingReqs
 * @param {object[]} options
 */
function disableTargetingReq(targetingReqs, options) {
    let targetingReqMap = {};
    _.forEach((targetingReqs || {}).details, id => {
        targetingReqMap[id.replace(/_/g, ' ')] = true;
    });
    _.forEach(options, option => {
        option.disabled = !!targetingReqs && !targetingReqMap[option.id];
        option.set = !option.disabled;
    });
}

/**
 * Build the variable choice filters from custom variables
 *
 * @param {object[]} customVariables
 * @returns {object[]} the filter
 */
function buildVariableChoiceFilter(customVariables) {
    _.forEach(customVariables, cv => {
        cv.id = cv.code;
        cv.logicPhrase = getLogicPhraseById(filterLogicConstants.criteriaTypes.CUSTOM_VARIABLE);
        _.forEach(cv.options, sv => {
            sv.id = sv.name;
            sv.isAvailable = true;
        });
    });
    return customVariables;
}

export default FilterLogicModel;
