/* eslint compat/compat: "off" */
angular.module('common.deferred', [])
    .factory('Deferred', Deferred);
Deferred.$inject = ['$q'];

function Deferred($q) {
    /**
     * @name Deferred
     * @description A constructor to handle deferred promises for functions that have multiple api calls.
     */
    function Deferred(data) {
        var promises = [],
            errors = [],
            _data = data,
            deferred = $q.defer();

        function self(deferredCall) {
            let deferred = $q.defer();
            deferredCall.then(function(response) {
                deferred.resolve(response);
            }, function(err) {
                errors.push(err);
                deferred.resolve();
            });
            promises.push(deferred.promise);
            return deferred.promise;
        }
        self.promise = promise;
        self.resolve = resolve;
        self.numDeferred = numDeferred;

        function promise() {
            $q.all(promises).then(function() {
                if (errors.length) {
                    deferred.reject(self.errors);
                }
                else {
                    deferred.resolve(_data);
                }
            }, function(err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function resolve() {
            deferred.resolve();
        }

        function numDeferred() {
            return promises.length;
        }

        return self;
    }
    return Deferred;
}
