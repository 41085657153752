import selectConstants from './constants';

angular.module('select.editor', ['surveyCreation.service'])
    .directive('selectEditor', selectEditor)
    .controller('SelectEditorCtrl', SelectEditorCtrl);

/**
 * Select Editor Directive
 *
 * @returns {object} directive
 */
function selectEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/select-editor.html',
        scope: {
            question: '=',
            checkQuestionErrors: '=',
            isAdmin: '=',
        },
        controller: 'SelectEditorCtrl',
        controllerAs: 'SelectEditorVM',
    };
}
SelectEditorCtrl.$inject = [
    '$rootScope',
    '$scope',
    'surveyCreationService',
];

/**
 * Select Editor Controller
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} surveyCreationService
 */
function SelectEditorCtrl($rootScope, $scope, surveyCreationService) {
    const NUM_REQUIRED_OPTIONS = 2;
    var SelectEditorVM = this,
        displayTypeConfig = selectConstants.get('displayTypeConfig'),
        numOptionsMissing = NUM_REQUIRED_OPTIONS,
        choicesToDisplay = 6,
        otherOption = {
            text: 'Other',
            placeholder: 'please specify',
            pinned: true,
            specifyOptionEnabled: true,
        },
        noneOfTheAboveOption = {
            text: 'None of the above',
            noneOfTheAbove: true,
            exclusive: true,
            pinned: true,
        };
    SelectEditorVM.auth = $rootScope.auth;
    SelectEditorVM.showCharCount = $rootScope.showCharCount;
    SelectEditorVM.displayTypes = selectConstants.get('displayTypes');
    SelectEditorVM.orderTypes = selectConstants.get('orderTypes');
    SelectEditorVM.otherSpecify = {};
    SelectEditorVM.show = {};
    SelectEditorVM.bulkEditor = {
        text: '',
        error: '',
    };
    // Configure the "choices" sortable.
    // Use start, stop, and mouseover to maintain element
    // width during sorting.
    SelectEditorVM.sortableOptions = {
        axis: 'y',
        tolerance: 'pointer',
        containment: 'parent',
        handle: '.sort-handle',
        scroll: true,
        distance: 10,
        start: function(e, ui) {
            var choiceCell = ui.item.find('.choice-cell');
            choiceCell.css('width', choiceCell.data('choice-cell-width'));
            $('.tooltip').hide();
        },
        stop: function(e, ui) {
            ui.item.find('.choice-cell').css('width', 'auto');
        },
    };

    SelectEditorVM.exportValues = {
        show: false,
    };
    SelectEditorVM.specificOptions = {
        noneOfTheAbove: {},
        other: {},
    };
    SelectEditorVM.toggleSpecificOption = toggleSpecificOption;
    SelectEditorVM.toggleAllOfTheAbove = toggleAllOfTheAbove;
    SelectEditorVM.toggleNoneOfTheAbove = toggleNoneOfTheAbove;
    SelectEditorVM.toggleOtherSpecify = toggleOtherSpecify;
    SelectEditorVM.numChoices = numChoices;
    SelectEditorVM.otherEnabled = displayTypeConfig[$scope.question.details.displayType].otherEnabled;
    SelectEditorVM.noneOfTheAboveEnabled = displayTypeConfig[$scope.question.details.displayType].noneOfTheAboveEnabled;
    SelectEditorVM.maxUserAnswerChars = displayTypeConfig[$scope.question.details.displayType][$rootScope.funnel.id].maxAnswerChars;
    SelectEditorVM.maxAnswerChars = SelectEditorVM.auth.user.isAdmin ? 256 : SelectEditorVM.maxUserAnswerChars;
    SelectEditorVM.chooseFile = chooseFile;
    SelectEditorVM.typeDisabled = typeDisabled;
    SelectEditorVM.maxDisplayChoices = maxDisplayChoices;
    SelectEditorVM.imageOptionsDisabled = imageOptionsDisabled;
    SelectEditorVM.imageOptionsMessage = imageOptionsMessage;
    SelectEditorVM.typeDisabledMessage = typeDisabledMessage;
    SelectEditorVM.onDisplayChange = onDisplayChange;
    SelectEditorVM.allowAllOfTheAbove = allowAllOfTheAbove;
    SelectEditorVM.allowTypeChange = allowTypeChange;
    SelectEditorVM.isFilled = isFilled;
    SelectEditorVM.editingLogic = editingLogic;
    SelectEditorVM.hideActiveLogic = hideActiveLogic;
    SelectEditorVM.openBulkEditor = openBulkEditor;
    SelectEditorVM.submitBulkEdits = submitBulkEdits;
    SelectEditorVM.hasError = hasError;
    SelectEditorVM.updateQuestionIsTrap = updateQuestionIsTrap;
    SelectEditorVM.getOrderText = getOrderText;
    SelectEditorVM.charCountColor = surveyCreationService.charCountColor;

    init();

    function init() {
        updateMaxChoices();
        updateTrapType();
        updateScreenerType();
        // Update();
        $(document).on('mouseover', '.sort-handle', function(e) {
            // Observe element width before dragging removes
            // it from the document flow.
            var choiceCell = $(e.target).parent().next('.choice-cell');
            choiceCell.data('choice-cell-width', choiceCell.css('width'));
        });

        /* Add functionality to navigate through response choices via key presses */
        $(document).on('keydown', '.faux-btn input', function(e) {
            e.stopPropagation(); // Stop propagation - don't want to navigate through questions

            if (e.keyCode === 13 || e.keyCode === 40) {
                $(this).parent().parent().parent().next().find('input').focus();
            }
            else if (e.keyCode === 38) {
                $(this).parent().parent().parent().prev().find('input').focus();
            }
        });
    }

    /**
     * Get order text
     */
    function getOrderText() {
        return $scope.question.details.order === 'shuffle' ? 'Random' : $scope.question.details.order === 'random-v' ? 'Reverse' : 'Fixed';
    }

    function initializeChoices() {
        _.forEach($scope.question.details.choices, function(choice) {
            if (choice.specifyOptionEnabled) {
                SelectEditorVM.specificOptions.other = choice;
            }
            if (choice.noneOfTheAbove) {
                SelectEditorVM.specificOptions.noneOfTheAbove = choice;
            }
        });

        _.forEach(_.range(0, choicesToDisplay - numChoices() + 1), function() {
            $scope.question.details.choices.push({
                text: '',
                image: '',
                exportValue: null,
                pinned: false,
                disqualifier: false,
                skip: null,
                toSuppress: false,
                trap: false,
                exclusive: false,
            });
        });
    }

    /**
     * Get number of choices (subtract out none of the above, all of the above and other/specify choices)
     */
    function numChoices() {
        let numValidChoices = _.countBy($scope.question.details.choices, function(choice) {
            return !!(choice.text || choice.image);
        }).true || 0;
        return numValidChoices - ($scope.question.details.specifyOptionEnabled ? 1 : 0) - ($scope.question.details.hasNoneOfTheAbove ? 1 : 0) -
            ($scope.question.details.hasAllOfTheAbove ? 1 : 0);
    }

    function updateScreenerType() {
        if ($scope.question.screener) {
            $scope.question.details.screenType = $scope.question.details.screenType || 'passOne';
        }
    }

    function updateTrapType() {
        if ($scope.question.trap) {
            $scope.question.details.trapType = $scope.question.details.trapType || 'passOne';
        }
    }

    /**
     * Update question is trap
     *
     * @param {object} choice
     */
    function updateQuestionIsTrap(choice) {
        if (choice.trap) {
            $scope.question.trap = true;
        }
        else {
            $scope.question.trap = _.some($scope.question.details.choices, 'trap');
        }
        updateTrapType();
    }

    // Update the number of max choices
    function updateMaxChoices() {
        var length = numChoices() + 1,
            minChoices = displayTypeConfig[$scope.question.details.displayType][$rootScope.funnel.id].minChoices;
        SelectEditorVM.maxChoices = displayTypeConfig[$scope.question.details.displayType][$rootScope.funnel.id].maxChoices;
        choicesToDisplay = SelectEditorVM.auth.user.isAdmin ? Math.max(minChoices, length) : Math.min(SelectEditorVM.maxChoices, Math.max(minChoices, length));
        initializeChoices();
    }

    function toggleAllOfTheAbove() {
        $scope.question.details.hasAllOfTheAbove = !$scope.question.details.hasAllOfTheAbove;
        toggleSpecificOption('allOfTheAbove');
    }

    function toggleNoneOfTheAbove() {
        $scope.question.details.hasNoneOfTheAbove = !$scope.question.details.hasNoneOfTheAbove;
        toggleSpecificOption('noneOfTheAbove');
    }

    function toggleOtherSpecify() {
        $scope.question.details.specifyOptionEnabled = !$scope.question.details.specifyOptionEnabled;
        toggleSpecificOption('other');
    }

    /**
     * Toggle specific option
     *
     * @param {string} optionType
     */
    function toggleSpecificOption(optionType) {
        let specificOptionMap = new Map([
                ['other', new Map([
                    ['flag', 'specifyOptionEnabled'],
                    ['choiceFlag', 'specifyOptionEnabled'],
                    ['choice', angular.copy(otherOption)],
                    ['fieldsToClear', ['otherText', 'otherPlaceholder']],
                    ['defaultText', 'Other'],
                ])],
                ['noneOfTheAbove', new Map([
                    ['flag', 'hasNoneOfTheAbove'],
                    ['choiceFlag', 'noneOfTheAbove'],
                    ['choice', angular.copy(noneOfTheAboveOption)],
                    ['fieldsToClear', []],
                    ['defaultText', 'None of the above'],
                ])],
                ['allOfTheAbove', new Map([
                    ['flag', 'hasAllOfTheAbove'],
                    ['fieldsToClear', ['hasAllOfTheAbove', 'allOfTheAboveText']],
                    ['defaultText', 'All of the above'],
                ])],
            ]),
            questionDetailOptions = specificOptionMap.get(optionType);

        if ($scope.question.details[questionDetailOptions.get('flag')]) {
            let choice = questionDetailOptions.get('choice');
            if (choice) {
                choice.text = SelectEditorVM.specificOptions[optionType].text || choice.text || questionDetailOptions.get('defaultText');
                $scope.question.details.choices.push(choice);
                SelectEditorVM.specificOptions[optionType] = _.last($scope.question.details.choices);
            }
            else if (optionType === 'allOfTheAbove') {
                $scope.question.details.allOfTheAboveText = $scope.question.details.allOfTheAboveText || questionDetailOptions.get('defaultText');
                SelectEditorVM.specificOptions[optionType] = {
                    pinned: true,
                };
            }
        }
        else {
            _.forEach(questionDetailOptions.get('fieldsToClear'), function(field) {
                delete $scope.question.details[field];
            });
            if (questionDetailOptions.get('choice')) {
                for (var i = 0; i < $scope.question.details.choices.length; i++) {
                    if ($scope.question.details.choices[i][questionDetailOptions.get('choiceFlag')]) {
                        $scope.question.details.choices.splice(i, 1);
                        break;
                    }
                }
            }
            SelectEditorVM.specificOptions[optionType] = {
                text: questionDetailOptions.get('defaultText'),
            };
        }
    }

    /**
     * Pick a file using the filepicker
     *
     * @param {option} choice
     */
    function chooseFile(choice) {
        var maxFiles = SelectEditorVM.maxChoices;
        _.forEach($scope.question.details.choices, function(c) {
            maxFiles = c.image ? maxFiles - 1 : maxFiles;
        });

        filepicker.pickMultiple({
            services: [
                'COMPUTER',
                'URL',
                'IMAGE_SEARCH',
                'WEBCAM',
                'DROPBOX',
                'BOX',
                'GOOGLE_DRIVE',
            ],
            maxFiles: maxFiles,
        }, function(imageFiles) {
            if (imageFiles.length) {
                var idx = $scope.question.details.choices.indexOf(choice);
                $scope.$apply(function() {
                    _.forEach(imageFiles, function(imageFile) {
                        // Find next available choice with no image
                        while ($scope.question.details.choices[idx].image) {
                            idx = idx < SelectEditorVM.maxChoices - 1 ? idx + 1 : 0;
                        }
                        $scope.question.details.choices[idx].image = imageFile.url;
                        if (!$scope.question.details.choices[idx].exportValue) {
                            $scope.question.details.choices[idx].exportValue = 'Image ' + (idx + 1);
                        }
                    });
                });
            }
        });
    }

    /**
     * Return whether the button to change to a different type should be disabled
     *
     * @param {string} displayType
     * @returns {boolean} isDisabled
     */
    function typeDisabled(displayType) {
        return !!typeDisabledMessage(displayType);
    }

    /**
     * Max display choices
     *
     * @param {string} displayType
     * @returns {number} max choices
     */
    function maxDisplayChoices(displayType) {
        var config = displayTypeConfig[displayType.id][$rootScope.funnel.id];
        return config.maxChoices;
    }

    /**
     * Image options disaabled
     *
     * @returns {boolean} image options disabled
     */
    function imageOptionsDisabled() {
        return false;
    }

    /**
     * Image options message
     *
     * @returns {string} message
     */
    function imageOptionsMessage() {
        if (imageOptionsDisabled()) {
            return 'This functionality is enabled only for Upwave Pro clients.';
        }
        return '';
    }

    /**
     * Disabled message
     *
     * @param {string} displayType
     *
     * @returns {string} message
     */
    function typeDisabledMessage(displayType) {
        var config = displayTypeConfig[displayType.id][$rootScope.funnel.id];
        var maxChoices = config.maxChoices;
        // Don't allow display type with fewer max choices than we already have
        if (!SelectEditorVM.auth.user.isAdmin && numChoices() > maxChoices) {
            return 'You have too many choices for this type.';
        }
        // If question type is multi select, don't allow slider or dropdown
        else if ($scope.question.type === 'multiSelect' && (displayType.id === 'slider' || displayType.id === 'dropdown')) {
            return 'This option is not available for multi-select questions.';
        }
    }

    // Called when user changes to a different display type (normal,slider,dropdown,combobox)
    function onDisplayChange() {
        SelectEditorVM.otherEnabled = displayTypeConfig[$scope.question.details.displayType].otherEnabled && $scope.question.details.responseType === 'text';
        SelectEditorVM.noneOfTheAboveEnabled = displayTypeConfig[$scope.question.details.displayType].noneOfTheAboveEnabled;

        // Disable 'all of the above' option if not standard multi select
        $scope.question.details.hasAllOfTheAbove = $scope.question.type === 'multiSelect' && $scope.question.details.displayType === 'normal' ? $scope.question.details.hasAllOfTheAbove : undefined;

        // Clear other/specify for slider type or if response type changed to 'image'
        if (!SelectEditorVM.otherEnabled) {
            delete $scope.question.details.specifyOptionEnabled;
        }
        if (!SelectEditorVM.noneOfTheAboveEnabled) {
            delete $scope.question.details.hasNoneOfTheAbove;
        }

        // Number of choices allowed may have changed upon changing display type
        updateMaxChoices();
    }

    /**
     * Allow all of the above response for this type/display type combination?
     *
     * @returns {boolean} allow all-of-the-above
     */
    function allowAllOfTheAbove() {
        return $scope.question.type === 'multiSelect' && $scope.question.details.displayType === 'normal';
    }

    // Return if we are allowed to change between single and multi select types
    /**
     *
     */
    function allowTypeChange() {
        return $scope.question.details.displayType !== 'dropdown' && $scope.question.details.displayType !== 'slider';
    }

    /**
     * Depending on which response type is selected, determine if a response is present
     *
     * @param {object} choice
     *
     * @returns {boolean} is filled
     */
    function isFilled(choice) {
        return !!choice[$scope.question.details.responseType];
    }

    /**
     * Return whether we're editing logic
     *
     * @returns {boolean} is editing
     */
    function editingLogic() {
        return SelectEditorVM.show.skipLogic || SelectEditorVM.show.screenLogic || SelectEditorVM.show.trapLogic || SelectEditorVM.show.suppressionLogic || SelectEditorVM.show.exportValues;
    }

    // Hide the logic editor stuff
    function hideActiveLogic() {
        SelectEditorVM.show = {};
    }

    /**
     * Open the bulk response editor
     *
     * @param {object} evt
     */
    function openBulkEditor(evt) {
        _.forEach($scope.question.details.choices, function(choice) {
            if (choice.text && !choice.noneOfTheAbove && !choice.specifyOptionEnabled) {
                SelectEditorVM.bulkEditor.text += choice.text + '\n';
            }
        });
        SelectEditorVM.show.bulkEditor = true;
        evt.preventDefault();
    }

    // Submit response choices via text area
    function submitBulkEdits() {
        SelectEditorVM.bulkEditor.error = '';
        let choices = SelectEditorVM.bulkEditor.text.split('\n'),
            numQuestionChoices = $scope.question.details.choices.length;

        if (!SelectEditorVM.auth.user.isAdmin && choices.length > SelectEditorVM.maxChoices) {
            SelectEditorVM.bulkEditor.error = 'Too many choices entered.';
            return;
        }
        _.forEach(choices, function(choice) {
            if (choice.length > SelectEditorVM.maxAnswerChars) {
                SelectEditorVM.bulkEditor.error = 'Choice exceeds maximum length.';
            }
        });
        if (SelectEditorVM.bulkEditor.error) {
            return;
        }

        // For empty choices, add the bulk input text
        _.forEach(choices, function(choice, i) {
            if (!choice.noneOfTheAbove && !choice.specifyOptionEnabled) {
                if (i < numQuestionChoices) {
                    $scope.question.details.choices[i].text = choice;
                }
                else {
                    $scope.question.details.choices.push({
                        text: choice,
                    });
                }
            }
        });
        SelectEditorVM.bulkEditor = {
            text: '',
            error: '',
        };
        delete SelectEditorVM.show.bulkEditor;
    }

    /**
     * Has error
     *
     * @param {object} choice
     * @param {number} idx
     *
     * @returns {boolean} has errorr
     */
    function hasError(choice, idx) {
        if (!SelectEditorVM.checkQuestionErrors) {
            return;
        }
        var type = $scope.question.details.responseType;
        return !choice[type] && (idx < numOptionsMissing || (idx < numOptionsMissing + 1 && $scope.question.details.choices[0][type]));
    }
}
