import _ from 'lodash';
var list = [{
        id: 'windows',
        name: 'Windows',
        icon: 'fab fa-windows',
    },
    {
        id: 'macos',
        name: 'Mac OS X',
        icon: 'fab fa-apple',
    },
    {
        id: 'ios',
        name: 'iOS',
        icon: 'fas fa-mobile-alt',
    },
    {
        id: 'android',
        name: 'Android',
        icon: 'fab fa-android',
    },
    {
        id: 'other',
        name: 'Other',
        icon: 'fas fa-question-circle',
    }],
    map = {};
_.forEach(list, function(e) {
    map[e.id] = e;
});

angular.module('operatingSystem.options', [])
    .constant('operatingSystemOptions', {
        list: list,
        map: map,
    });
