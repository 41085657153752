export const partnerTypeLabels = {
    AD_SERVER: '3rd Party Ad Server',
    AGENCY: 'Agency',
    DSP: 'DSP',
    PUBLISHER: 'Publisher',
};

export const partnerTypeOrder = {
    AD_SERVER: 0,
    DSP: 1,
    PUBLISHER: 2,
    AGENCY: 3,
};

// Given the partner type sort order map, sort an array of partner items
export const partnerTypeSortFunction = (a, b) => {
    const orderA = partnerTypeOrder[a.partnerType];
    const orderB = partnerTypeOrder[b.partnerType];

    const primaryOrder = orderA - orderB;
    if (primaryOrder !== 0) return primaryOrder;
    else return a.name.localeCompare(b.name);
};
