const liftResultsStateConstants = {
    NOT_READY: {
        status: 'Analysis Results require questions and answers to be marked for KPIs before generating for the first time',
    },
    READY: {
        alert: {
            class: 'text-success',
            message: 'Ready to run',
        },
        status: 'Your first plan will be available for download by tomorrow 8am PST',
    },
    FIRST_FAILED: {
        alert: {
            class: 'text-danger',
            message: 'Last run failed. Upwave engineering is on it.',
        },
        status: 'Your first plan will be available for download by tomorrow 8am PST',
    },
    HAS_RESULTS_AND_NO_CHANGES: {
        status: 'New results generate nightly if there are any new results or changes to the Analysis Plan',
    },
    HAS_RESULTS_AND_CHANGES: {
        alert: {
            class: 'text-success',
            message: 'New changes',
        },
        status: 'You can download new results by Tomorrrow 8am PST',
    },
    HAS_RESULTS_AND_ERROR: {
        alert: {
            class: 'text-danger',
            message: 'Last run failed. Upwave engineering is on it.',
        },
        status: 'You will be able to download new results when we have fixed the issue',
    },
};

export default liftResultsStateConstants;
