import '../../../../common/js/angular-util';
import '../common-components/deferred';
import {
    partnerTypeLabels,
} from './tag-creation.ad-server-constants';

export default angular
    .module('campaignManagerApi', ['angularUtil', 'common.deferred'])
    .service('mediaPartnerService', mediaPartnerService);

mediaPartnerService.$inject = [
    'httpDeferred', 'Deferred',
];

/**
 * Campaign manager service to fetch Media Partners
 *
 * @param {object} httpDeferred
 * @param {object} Deferred
 * @returns {object} an instance of MediaPartnerService
 */
function mediaPartnerService(httpDeferred, Deferred) {
    // The max number of media partes to be requested to the server
    const MAX_MEDIA_PARTNERS = 300;

    /**
     * Queries campaign-manager to fetch all the media partners
     *
     * @returns {Promise} promise
     */
    function listMediaPartners() {
        const url = `/campaign-manager/media-partner?_size=${MAX_MEDIA_PARTNERS}`;
        let deferred = new Deferred(),
            mediaPartners = [];

        deferred(httpDeferred.get(url).then(res => {
            mediaPartners = res.content.map(mp => ({
                ...mp,
                partnerTypeLabel: partnerTypeLabels[mp.partnerType],
            }));
        }, function(error) {
            console.log(error);
        }));

        return deferred.promise().then(() => mediaPartners);
    }

    return {
        listMediaPartners: listMediaPartners,
    };
}
