import _ from 'lodash';
import '../../../../common/js/angular-util';
import '../common-components/pub-sub';
import './stats-module';
import '../charts-widget/chart-data-parser';
import '../filter-and-compare/compare-service';
import filterLogicConstants from '../filter-logic/filter-logic-constants';

angular.module('respondentData.service', [
    'angularUtil',
    'pubSub',
    'StatsModule',
    'chart.dataParser',
    'compareService',
])
    .service('respondentDataService', respondentDataService);
respondentDataService.$inject = [
    '$rootScope',
    '$q',
    'httpDeferred',
    'pubSubService',
    '$statsService',
    'chartDataParser',
    'compareService',
];

/**
 * Defines the respondent data service.
 *
 * @param {*} $rootScope
 * @param {*} $q
 * @param {*} httpDeferred
 * @param {*} pubSubService
 * @param {*} $statsService
 * @param {*} chartDataParser
 * @param {*} compareService
 * @returns {*}
 */
function respondentDataService($rootScope, $q, httpDeferred, pubSubService, $statsService, chartDataParser, compareService) {
    var valueToDisplayName = {
            Demographic$Gender: 'Gender Breakdown',
            Demographic$Age: 'Age Group Breakdown',
            Demographic$Location: 'Location Breakdown',
            Beacon$Pool: 'Beacon Pool Breakdown',
        },
        filterTypeToTally = {
            Demographic$Age: 'ageTally',
            age: 'ageTally',
            Demographic$Gender: 'genderTally',
            gender: 'genderTally',
            Demographic$Location: 'regionTally',
            region: 'regionTally',
        },
        allChartTypes = _.keys(valueToDisplayName),
        respondentData = [],
        FILTER_TOO_NARROW_CODE = 10;

    /**
     * Gets respondent data.
     *
     * @param {*} beaconPools
     * @returns {*[]}
     */
    function getRespondentData(beaconPools) {
        if (!respondentData.length) {
            initializeRespondentData(beaconPools);
        }
        return respondentData;
    }

    /**
     * Initializes respondent data.
     *
     * @param {*} beaconPools
     */
    function initializeRespondentData(beaconPools) {
        _.forEach(allChartTypes, chartType => {
            let noBeaconPools = chartType === filterLogicConstants.criteriaTypes.BEACON_POOL && (!beaconPools || !beaconPools.length),
                notUS = chartType === 'Demographic$Location' && _.find($rootScope.survey.targetingReqs, {
                    attribute: 'country',
                }).details.type.toUpperCase() !== 'US';
            if (noBeaconPools || notUS) {
                return;
            }
            respondentData.push(makeFilteredData(chartType, beaconPools));
        });
    }

    /**
     * Gets respondent data for type.
     *
     * @param {*} survey
     * @param {*} question
     * @param {*} tallyParams
     * @returns {Promise<boolean>|*}
     */
    function getRespondentDataForType(survey, question, tallyParams) {
        var chartType = question.respondentDataType;
        if (chartType === filterLogicConstants.criteriaTypes.BEACON_POOL) {
            return getRespondentDataForBeaconPools(survey, tallyParams, question.beaconPools).then(response => {
                return response;
            });
        }
        return getRespondentDataForDemographic(survey, tallyParams, question.beaconPools).then(response => {
            let numRespondents = getNumRespondents(tallyParams, response);
            return chartDataParser.parseTallyCount(tallyParams, response.filtered[filterTypeToTally[chartType]], numRespondents, chartType);
        });
    }

    /**
     * Gets chart data.
     *
     * @param {*} survey
     * @param {*} params
     * @param {*} beaconPools
     * @returns {*[]}
     */
    function getChartData(survey, params, beaconPools) {
        var promiseMap = {};
        _.forEach(respondentData, data => {
            var deferred = $q.defer();
            promiseMap[data.respondentDataType] = deferred;
            pubSubService.notify('render-chart-' + data.id, [deferred.promise, params]);
        });
        getRespondentDataForDemographic(survey, params, beaconPools).then(response => {
            let numRespondents = getNumRespondents(params, response);
            _.forEach(filterTypeToTally, (tallyType, comparisonType) => {
                let data = promiseMap[comparisonType] && chartDataParser.parseTallyCount(params, response.filtered[tallyType], comparisonType === response.comparisonType ? response.summary.numRespondentsInVisiblePeriods : numRespondents, comparisonType);
                if (!data) {
                    delete promiseMap[comparisonType];
                    return;
                }
                promiseMap[comparisonType].resolve(data);
            });
        });
        if (beaconPools) {
            getRespondentDataForBeaconPools(survey, params, beaconPools).then(response => {
                promiseMap.Beacon$Pool.resolve(response);
            });
        }
        return respondentData;
    }

    /**
     * Gets respondent data for demographic.
     *
     * @param {*} survey
     * @param {*} params
     * @param {*} beaconPools
     * @returns {Promise<*>}
     */
    function getRespondentDataForDemographic(survey, params, beaconPools) {
        var _isComparison = !!params.comparisonData,
            dataGetter;
        dataGetter = _isComparison ? getComparisonData(params, survey, beaconPools) : $statsService.getRespondentData(survey, params.tallyParams, params.collectionPeriods);
        return dataGetter.then(response => {
            pubSubService.notify('respondents-get-tally', [response.summary]);
            return response;
        }, function error(response) {
            pubSubService.notify('update-results-status-msg', response);
            return response;
        });
    }

    /**
     * Gets the number of respondents.
     *
     * @param {*} params
     * @param {*} response
     * @returns {number|*|number[]|[]}
     */
    function getNumRespondents(params, response) {
        var _isComparison = !!params.comparisonData;
        if (chartDataParser.isTracker(params.collectionPeriods)) {
            return response.summary.numRespondentsByCollectionPeriod;
        }
        else if (_isComparison) {
            return response.numRespondents;
        }
        return response.summary.numRespondentsInVisiblePeriods;
    }

    /**
     * Gets respondent data for beacon pools.
     *
     * @param {*} survey
     * @param {*} tallyParams
     * @param {*} beaconPools
     * @returns {*}
     */
    function getRespondentDataForBeaconPools(survey, tallyParams, beaconPools) {
        const _isComparison = !!tallyParams.comparisonData;
        if (_isComparison) {
            const comparisonType = _.keys(tallyParams.comparisonData[0])[0];
            if (comparisonType === filterLogicConstants.criteriaTypes.BEACON_POOL) {
                beaconPools = tallyParams.comparisonData[0].Beacon$Pool.concat(tallyParams.comparisonData[1].Beacon$Pool);
            }
        }
        return getBeaconPoolTallySummary(survey, tallyParams, beaconPools).then(results => {
            return chartDataParser.parseTallyCount(tallyParams, results.count, results.numRespondents, filterLogicConstants.criteriaTypes.BEACON_POOL);
        });
    }

    /**
     * Makes filtered data.
     *
     * @param {*} type
     * @param {*} beaconPools
     * @returns {{statement: {text}, details: {statement: {text}, type: string}, id, type: string, respondentDataType, beaconPools}}
     */
    function makeFilteredData(type, beaconPools) {
        return {
            type: 'singleSelect',
            respondentDataType: type,
            id: type.replace('$', ''),
            beaconPools: beaconPools,
            details: {
                statement: {
                    text: valueToDisplayName[type],
                },
                type: 'singleSelect',
            },
            statement: {
                text: valueToDisplayName[type],
            },
        };
    }

    function resetInitializedRespondentData() {
        respondentData = [];
    }

    /**
     * Gets comparison data.
     *
     * @param {*} params
     * @param {*} survey
     * @param {*} beaconPools
     * @returns {Promise<*>}
     */
    function getComparisonData(params, survey, beaconPools) {
        var comparisonKeys = [],
            beaconPoolMap = {},
            comparisonType = _.keys(params.comparisonData[0])[0],
            comparisonTypeTallyName = filterTypeToTally[comparisonType],
            comparedTally = {},
            numRespondents = {};
        if (beaconPools) {
            _.forEach(beaconPools.options || beaconPools, bp => {
                beaconPoolMap[bp.id] = bp;
            });
        }

        return $statsService.getRespondentData(survey, compareService.getComparisonStatsParam(params), params.collectionPeriods).then(response => {
            _.forEach(params.comparisonData, (comparison, idx) => {
                let keys = _.map(comparison[comparisonType], 'id'),
                    stringKey;
                if (comparisonType === filterLogicConstants.criteriaTypes.BEACON_POOL) {
                    stringKey = '';
                    _.forEach(keys, bp => {
                        let beaconPool = beaconPoolMap[bp];
                        comparisonKeys.push(beaconPool);
                        stringKey += beaconPool.name + ', ';
                    });
                    stringKey = stringKey.slice(0, stringKey.length - 1);
                }
                else {
                    stringKey = keys.join(',');
                    comparisonKeys.push(stringKey);
                }
                numRespondents[stringKey] = 0;
                delete response.filtered.numRespondents;
                _.forEach(keys, key => {
                    if (comparisonTypeTallyName) {
                        comparedTally[key] = response.filtered[comparisonTypeTallyName][idx][key] || 0;
                        numRespondents[stringKey] += comparedTally[key];
                    }
                    else {
                        numRespondents[stringKey] += _.reduce(_.values(_.values(response.filtered)[0][idx]), (m, n) => {
                            return m + n;
                        }, 0);
                    }
                });
            });
            if (comparisonTypeTallyName) {
                response.filtered[comparisonTypeTallyName] = comparedTally;
            }
            return {
                summary: response.summary,
                filtered: response.filtered,
                comparisonKeys: comparisonKeys,
                numRespondents: numRespondents,
                comparisonType: comparisonType,
            };
        });
    }

    /**
     * Removes conflicting filter by.
     *
     * @param {*} filterBy
     * @param {*} regex
     * @param {*} cleaned
     */
    function removeConflictingFilterBy(filterBy, regex, cleaned) {
        const logic = _.keys(filterBy)[0],
            isFirstLevelLogic = /^\$any|lt|le|gt|ge|in|eq$/.test(logic);
        if (isFirstLevelLogic && !regex.test(filterBy[logic][0])) {
            cleaned.push(filterBy);
        }
        else if (!isFirstLevelLogic) {
            _.forEach(_.values(filterBy[logic]), criteriaObject => {
                const criteria = {};
                removeConflictingFilterBy(criteriaObject, regex, criteria);
                if (!_.isEmpty(criteria)) {
                    cleaned[logic] = cleaned[logic] || [];
                    cleaned[logic].push(criteria);
                }
            });
        }
    }

    /**
     * Gets beacon pool tally summary.
     *
     * @param {*} survey
     * @param {*} params
     * @param {*} beaconPools
     * @returns {*}
     */
    function getBeaconPoolTallySummary(survey, params, beaconPools) {
        const promises = [],
            beaconDefinition = {},
            comparisonKeys = {},
            bannerKeys = {
                type: 'Demographic',
                field: 'Gender',
            },
            tallyParams = {
                // eslint-disable-next-line camelcase
                significance_level: 0.95,
                // eslint-disable-next-line camelcase
                banner_keys: [bannerKeys],
            },
            counts = [],
            numRespondents = {};
        let regexp,
            isComparisonByBP;
        if (params.comparisonData) {
            const regex = [];
            _.forEach(params.comparisonData, data => {
                _.forEach(data, (_, key) => {
                    comparisonKeys[key] = true;
                });
            });
            _.forEach(comparisonKeys, (_, key) => {
                if (key === 'Beacon$Pool') {
                    isComparisonByBP = true;
                    regex.push('BeaconPool\\$(\\d+|Control)');
                }
                else {
                    regex.push(key);
                }
            });
            regexp = new RegExp(regex.join('|'));
        }

        _.forEach(beaconPools.slice(0, 10), bp => {
            if (bp.set) {
                beaconDefinition[bp.displayNameFull] = {
                    $or: [{
                        $eq: [bp.id, 1],
                    }],
                };
            }
        });
        // eslint-disable-next-line camelcase
        tallyParams.row_keys = [{
            type: 'Synthetic',
            statement: 'beaconPools',
            definition: beaconDefinition,
        }];
        _.forEach(isComparisonByBP ? [params.tallyParams[0]] : params.tallyParams, (filter, idx) => {
            const crosstabParam = angular.copy(tallyParams),
                defer = $q.defer();
            if (filter && filter.filter_by) {
                let filterBy;
                if (params.comparisonData) {
                    filterBy = {};
                    removeConflictingFilterBy(filter.filter_by, regexp, filterBy);
                }
                else {
                    filterBy = filter.filter_by;
                }
                if (!_.isEmpty(filterBy)) {
                    // eslint-disable-next-line camelcase
                    crosstabParam.filter_by = filterBy;
                }
            }
            promises.push(defer.promise);
            httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/crosstab?crosstabRequest=' + escape(JSON.stringify(crosstabParam))).then(response => {
                const summary = {};
                if (response.code === FILTER_TOO_NARROW_CODE) {
                    response.result = {
                        numRespondents: 0,
                    };
                    _.forEach(beaconPools.slice(0, 10), beacon => {
                        summary[beacon.name] = 0;
                    });
                }
                else {
                    _.forEach(response.result.crosstab, (val, key) => {
                        _.forEach(val, count => {
                            summary[key] = summary[key] || 0;
                            summary[key] += count;
                        });
                    });
                }
                counts.push(summary);
                if (params.comparisonData && !comparisonKeys.Beacon$Pool) {
                    numRespondents[_.map(params.comparisonData[idx], 'id').join(', ')] = response.result.numRespondents;
                }
                else {
                    numRespondents[idx] = response.result.numRespondents;
                }
                defer.resolve();
            }, () => {
                counts.push({});
                defer.resolve();
            });
        });
        return $q.all(promises).then(() => {
            return {
                count: counts.length > 1 ? counts : counts[0],
                numRespondents: counts.length > 1 ? numRespondents : _.values(numRespondents)[0],
            };
        });
    }

    return {
        getChartData: getChartData,
        getRespondentData: getRespondentData,
        resetInitializedRespondentData: resetInitializedRespondentData,
        getRespondentDataForType: getRespondentDataForType,
        getBeaconPoolTallySummary: getBeaconPoolTallySummary,
    };
}
