const customCutsConstants = {
    tableHeaders: [{
        name: 'Cut name',
        sortable: true,
        tooltip: true,
        columnType: 'text',
        sortField: 'name',
        value: 'name',
    },
    {
        name: 'Cut category',
        sortable: true,
        tooltip: true,
        columnType: 'text',
        sortField: '_categoryName',
        value: '_categoryName',
    },
    {
        name: 'Exposed',
        tooltip: true,
        columnType: 'text',
        value: '_exposedPlainEnglish',
    },
    {
        name: 'Control',
        tooltip: true,
        columnType: 'text',
        value: '_controlPlainEnglish',
    },
    {
        columnType: 'action',
        cellClass: 'btn-sv--circle',
        actionName: '<i class="fas fa-edit fa-fw"></i>',
        eventName: 'open-cut-editor',
    },
    {
        columnType: 'action',
        cellClass: 'btn-sv--circle',
        actionName: '<i class="fas fa-trash-alt fa-fw"></i>',
        eventName: 'delete-cut',
    }],
};

export default customCutsConstants;
