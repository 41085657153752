import $ from 'jquery';
import _ from 'lodash';
import utm from './utm';
import moment from 'moment';

export default (function(window, document, undefined) {
    var U = {};

    /**
     * @param str
     */
    function toUnicode(str) {
        let unicodeString = '';
        _.forEach(str, function(char, i) {
            if (isRegularCharacter(char)) {
                unicodeString += char;
            }
            else {
                let theUnicode = str.charCodeAt(i).toString(16);
                while (theUnicode.length < 4) {
                    theUnicode = '0' + theUnicode;
                }
                theUnicode = '\\u' + theUnicode;
                unicodeString += theUnicode;
            }
        });
        return unicodeString;
    }

    /**
     * @param text
     */
    function unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi, function(match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    }

    /**
     * @param json
     */
    function stringToCsvStringCompat(json) {
        if (typeof json !== 'string') {
            json = JSON.stringify(json);
        }
        if (!json.match(/("|,)/g)) {
            return json;
        }
        return '"' + json.replace(/","/g, '"",""').replace(/"/g, '""').replace(/"""",""""/g, '"",""') + '"';
    }

    /**
     * @param char
     */
    function isRegularCharacter(char) {
        return /[~`?!\.#$%\^&*+=\-\_\(\)\[\]\\';,/{}|\\":<>\?\w\d\s]/g.test(char);
    }

    /**
     *
     */
    function isFirefox() {
        return typeof InstallTrigger !== 'undefined';
    }

    /**
     *
     */
    function isSafari() {
        return /constructor/i.test(window.HTMLElement) || (function(p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));
    }

    /**
     *
     */
    function isIe() {
        return 'msTextCombineHorizontal' in document.body.style;
    }

    var isInteger = Number.isInteger || function(value) {
        return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    };

    /**
     * @param start
     * @param end
     */
    function calculateDifferenceInDays(start, end) {
        var difMiliseconds = new Date(end) - new Date(start);
        // Day in miliseconds = 1000ms * 60s * 60m * 24hr
        return difMiliseconds / (1000 * 60 * 60 * 24);
    }

    /**
     * @param obj
     * @param params
     */
    function objToArray(obj, params) {
        var arr = _.map(obj, function(val, key) {
            var item = {};
            item[params.keyName || 'key'] = key;
            item[params.valueName || 'value'] = val;
            return item;
        });
        if (params.sortBy) {
            arr = _.sortBy(arr, params.sortBy);
        }
        return arr;
    }

    /**
     * @param obj
     */
    function toArray(obj) {
        if (!obj) {
            return obj;
        }
        return Array.isArray(obj) ? obj : [obj];
    }

    /**
     * @param value
     */
    function toCamelCase(value) {
        if (!value) {
            return;
        }
        return value.replace(/_\w/g, function(x) {
            return x[1].toUpperCase();
        });
    }

    // Add basic event handling and triggering functionality to an object
    /**
     * @param obj
     */
    function eventable(obj) {
        var handlers = {},
            eventable = {
                on: function(evt, handler) {
                    handlers[evt] = handlers[evt] || [];
                    handlers[evt].push(handler);
                },
                once: function(evt, handler) {
                    obj.on(evt, _.once(handler));
                },
                off: function(evt) {
                    handlers[evt] = [];
                },
                trigger: function(evt, data) {
                    $.map(handlers[evt] || [], function(func) {
                        func.call(this, data);
                    });
                },
            };
        return $.extend(obj || {}, eventable);
    }

    /**
     * @param str
     */
    function isValidJsonStr(str) {
        var result = true;
        try {
            JSON.parse(str);
        }
        catch (err) {
            result = false;
        }
        return result;
    }

    // This doesn't seem to work all the time....
    /**
     * @param text
     */
    function copyToClipboard(text) {
        try {
            var temp = document.createElement('input');
            temp.setAttribute('value', text);
            document.body.appendChild(temp);
            temp.select();
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);

            document.body.removeChild(temp);
        }
        catch (err) {
            console.log('Oops, unable to copy');
        }
    }

    // Trim object params that are null, undefined or empty obj
    /**
     * @param obj
     */
    function trimObject(obj) {
        obj = obj || {};
        _.forEach(obj, function(val, key) {
            if (val === undefined || val === null) {
                delete obj[key];
            }
            else if (typeof val === 'object' && !_.size(val)) {
                delete obj[key];
            }
        });
        return obj;
    }

    // Convert a number to alpha code. 1 = a, 2 = b, 27 = aa...
    /**
     * @param num
     */
    function numberToAlphaCode(num) {
        var alphabet = 'abcdefghijklmnopqrstuvwxyz'.split(''),
            pass = Math.floor(num / 26);
        return '' + (pass > 0 ? alphabet[pass - 1] : '') + alphabet[num % 26];
    }

    // Convert a alpha character. a = 1, b = 2 ... aa = 27.
    /**
     * @param alpha
     */
    function alphaToNumber(alpha) {
        var alphabet = 'abcdefghijklmnopqrstuvwxyz'.split(''),
            dictionary = {};
        _.forEach(alphabet, (letter, i) => {
            dictionary[letter] = i + 1;
        });
        let num = 0;
        _.forEach(alpha, char => {
            num *= 26;
            num += dictionary[char];
        });
        return num;
    }

    // Not exported
    /**
     * @param merged
     * @param obj
     */
    function __helperMerge(merged, obj) {
        _.forEach(obj, function(count, key) {
            if (typeof count === 'object') {
                merged[key] = merged[key] || {};
                __helperMerge(merged[key], count);
            }
            else {
                merged[key] = (merged[key] || 0) + count;
            }
        });
        return merged;
    }

    // Rename a property of the given object
    /**
     * @param obj
     * @param prevProp
     * @param newProp
     */
    function renameObjProperty(obj, prevProp, newProp) {
        obj[newProp] = obj[prevProp];
        delete obj[prevProp];
        return obj;
    }

    /**
     * @param original
     * @param prototype
     */
    function reorderObjKeys(original, prototype) {
        var newObj = {},
            prototypeIsArray = Array.isArray(prototype);
        _.forEach(prototype, function(x, y) {
            if (prototypeIsArray) {
                newObj[x] = original[x];
            }
            else {
                newObj[y] = original[y];
            }
        });
        return newObj;
    }

    // Determine whether two objects are equal except for one key
    /**
     * @param obj1
     * @param obj2
     * @param exceptKey
     */
    function isObjectEqualExceptForKey(obj1, obj2, exceptKey) {
        // If there are more keys in one obj than the other, it's not possible that they're 'equal'
        // this isn't completely foolproof, as they could the same numer of, but different keys
        // but this is sufficient for our purposes (mainly just guarding against emtpy obj1)
        if (_.size(obj1) !== _.size(obj2)) {
            return false;
        }
        for (var key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                if (key !== exceptKey && !_.isEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }
        }
        return true;
    }

    // Copy all of one objects properties into another except for a given key
    /**
     * @param origin
     * @param destination
     * @param exceptProperty
     */
    function copyAllObjectPropertiesExcept(origin, destination, exceptProperty) {
        _.forEach(origin, function(value, key) {
            if (key !== exceptProperty) {
                destination[key] = {
                    ...value,
                };
            }
        });
    }

    // Clone an object except for specified key or keys
    /**
     * @param origin
     * @param except
     */
    function cloneObjectExcept(origin, except) {
        var destination = {
                ...origin,
            },
            exceptions = except && (_.isArray(except) ? except : [except]);

        // Make sure we actually have exceptions to remove
        if (exceptions && exceptions.length) {
            // Remove the specified items from the destination object
            for (var i = 0; i < exceptions.length; i++) {
                delete destination[exceptions[i]];
            }
        }
        return destination;
    }

    /**
     * @param object
     */
    function alphabetizeObjectKeys(object) {
        var sortedObject = {},
            keys = _.keys(object).sort();
        _.forEach(keys, function(key) {
            if (key) {
                sortedObject[key] = object[key];
            }
        });
        return sortedObject;
    }

    // Given a list of objects, find the one with the given uuid
    /**
     * @param arr
     * @param uuid
     */
    function findByUuid(arr, uuid) {
        return _.find(arr, {
            uuid: uuid,
        });
    }

    // Limit length of a string. If exceeds limitTo, cut off the rest and add '...'
    // Options are an optional third argument
    /**
     * @param str
     * @param limitTo
     * @param options
     */
    function limitStrLength(str, limitTo, options = {
        maxLength: limitTo,
    }) {
        let {
            maxLength = limitTo,
        } = options;

        if (options.removeHtml) {
            str = U.stringRemoveHtml(str);
        }

        if (str.length > maxLength) {
            // Eventually would love for a true elipses character
            // Need to subtract 3 from the limit, to account for the '...'
            return str.substring(0, limitTo - 3) + '...';
        }
        return str;
    }

    // String functions
    var trim = (function() {
        var nativeTrim = String.prototype.trim,
            rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

        return function trim(s) {
            s = s || '';

            return nativeTrim ? s.trim() : s.replace(rtrim, '');
        };
    })();

    /**
     * @param text
     */
    function stringRemoveHtml(text) {
        // Replace breaks with a space, replace html, then replace any double spaces that were created
        // regex ref: https://stackoverflow.com/questions/17289448/angularjs-to-output-plain-text-instead-of-html
        return text.replace('<br>', ' ').replace(/<[^>]+>/gm, '').replace('  ', ' ');
    }

    // Return the count of items in the object
    /**
     * @param obj
     */
    function objLength(obj) {
        return _.keys(obj).length;
    }

    // Given a date string from the server, create a Javascript date and optionally add a day offset
    /**
     * @param dateStr
     * @param dayOffset
     */
    function makeDate(dateStr, dayOffset) {
        if (!dateStr) {
            return null;
        }
        var newDate = new Date(dateStr);

        if (dayOffset) {
            newDate.setDate(newDate.getDate() + dayOffset);
        }
        return newDate;
    }

    // Should eventually import momentJS to format dates
    /**
     * @param date
     * @param format
     */
    function _dateFormatter(date, format) {
        if (!date) {
            return '';
        }
        var month = _monthFormatter(date.getMonth(), format.month),
            day = _dayFormatter(date.getDate(), format.day),
            year = _yearFormatter(date.getFullYear(), format.year);
        return month + (format.delimiters[0] || '') + day + (format.delimiters[1] || '') + year;
    }

    /**
     * @param day
     * @param format
     */
    function _dayFormatter(day, format) {
        return !day || !format ? '' : day < 10 && format === 'DD' ? '0' + day : day + '';
    }

    /**
     * @param month
     * @param format
     */
    function _monthFormatter(month, format) {
        var monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        switch (format) {
            case 'M':
                return month + 1;
            case 'MM':
                return month < 9 ? '0' + month + 1 : month + 1;
            case 'MMM':
                return monthNames[month].slice(0, 3);
            case 'MMMM':
                return monthNames[month];
            default:
                return '';
        }
    }

    /**
     * @param year
     * @param format
     */
    function _yearFormatter(year, format) {
        return !format || !year ? '' : format === 'YY' ? (year + '').slice(2, 4) : year;
    }

    // An augmented version of underscore min, that also strips out any undefined or null values in the array
    // otherwise, it would return NaN
    /**
     * @param arr
     */
    function min(arr) {
        return _.min(_.without(arr, undefined, null));
    }

    // Return a debounced version of a function
    /**
     * @param func
     * @param delay
     */
    function debounce(func, delay) {
        return _.debounce(func, delay || 300);
    }

    // Check for empty object
    /**
     * @param obj
     */
    function isEmpty(obj) {
        return _.isEmpty(obj);
    }

    // Check for empty array?
    /**
     * @param arr
     */
    function isEmptyArray(arr) {
        return $.isArray(arr) && !arr.length;
    }

    // Check if an array contains a given value
    /**
     * @param arr
     * @param item
     */
    function arrayContains(arr, item) {
        return _.includes(arr, item);
    }

    //Computes the list of values that are the intersection of all the arrays. Each value in the result is present in each of the arrays
    // ex) U.arrayIntersection([1, 2, 3], [101, 2, 1, 10], [2, 1]); --> [1, 2]
    /**
     *
     */
    function arrayIntersection() {
        return _.intersection.apply(this, arguments);
    }

    // Parse a boolean value given a string
    /**
     * @param str
     */
    function parseBool(str) {
        return str && str.toLowerCase() === 'true';
    }

    // Redirect to a given url and append additional params if supplied
    /**
     * @param u
     * @param getParams
     */
    function redirect(u, getParams) {
        if (getParams && !isEmpty(getParams)) {
            var urlParams = Url(u).params() || {};
            $.extend(urlParams, getParams);
            u = Url(u).params(urlParams).url();
        }
        window.location.replace(u);
    }

    // Given a length and start offset, return an array of sequential values
    // Useful for ng-repeat'ing a set number of times
    /**
     * @param length
     * @param start
     */
    function range(length, start) {
        var result = [];
        for (var i = start || 0; i < length + (start || 0); i++) {
            result.push(i);
        }
        return result;
    }

    // Seedable random number generator
    /**
     * @param seedVal
     */
    function rand(seedVal) {
        var seed = null;

        var initSeed = function(seedVal) {
            seed = seedVal || new Date().getSeconds();
        };

        var generate = function(min, max) {
            if (!seed) {
                initSeed();
            }
            min = min || 0;
            max = max || 1;
            seed = (seed * 9301 + 49297) % 233280;
            var rnd = seed / 233280;

            return min + rnd * (max - min);
        };

        if (seedVal) {
            initSeed(seedVal);
        }

        return {
            initSeed: initSeed,
            generate: generate,
        };
    }

    // Clone an object or an array
    /**
     * @param thing
     */
    function clone(thing) {
        if ($.isArray(thing)) {
            return thing.slice(0);
        }

        return JSON.parse(JSON.stringify(thing));
    }

    //To use object, U.extend(obj1, obj2, obj3, ...etc);
    /**
     *
     */
    function extend() {
        return _.extend.apply(this, arguments);
    }

    // Fisher-Yates shuffle algorithm, from the inter-webs
    /**
     * @param original
     * @param subset
     * @param seed
     */
    function arrayShuffle(original, subset, seed) {
        var shuffled = clone(original),
            r = rand(),
            counter = shuffled.length,
            temp,
            index;

        if (seed) {
            r.initSeed(seed);
        }

        while (counter > 0) {
            index = Math.floor(r.generate() * counter); // Pick random index
            counter--; // Decrease counter by 1

            // And swap the last element with it
            temp = clone(shuffled[counter]);
            shuffled[counter] = clone(shuffled[index]);
            shuffled[index] = clone(temp);
        }
        return subset && subset < shuffled.length ? shuffled.slice(0, subset) : shuffled;
    }

    // Show the surveywall - for preview and take link
    /**
     * @param params
     */
    function showSurveyWall(params) {
        var ResultsText = {
            complete: 'Thank you for your participation',
            screened: 'Sorry, you did not qualify for this survey',
            quota: 'Sorry, the quota has been filled for this survey',
            taken: 'You have already taken this survey. Thanks!',
            error: 'Sorry, there was an error loading the survey',
            redirect: 'Redirecting you to another page',
            quality: 'Thank you for your participation',
        };
        var redirectOverrides = params.redirectOverrides || {
            complete: null,
            quota: null,
            screened: null,
        };
        var disallowRedirect = params.disallowRedirect || false;

        Survata.ready(function() {
            var s = Survata.createSurveywall({
                brand: params.brand || 'Upwave Survey Demo',
                brandLogo: params.brandLogo || null,
                explainer: params.explainer || 'This is a demo of your survey',
                preview: params.preview || null,
                take: params.take || null,
                takePreview: params.takePreview || null,
                parent: params.parent || null,
                allowBack: params.allowBack || false,
                allowSkip: params.allowSkip || false,
                disallowClose: params.disallowClose || false,
                hideHeader: params.hideHeader || false,
                hideFooter: params.hideFooter || false,
                hideProgress: params.hideProgress || false,
                hideBorder: params.hideBorder || true,
                verticalLayout: params.verticalLayout || false,
                panelId: params.panelId || null,
                panelistId: params.panelistId || null,
                mobileAdId: params.mobileAdId || null,
                respondentGroup: params.respondentGroup || null,
                sourceId: params.sourceId || null,
                resultCallback: params.resultCallback || null,
            });

            var processResult = function(type, redirect) {
                var url = redirectOverrides[type] || (redirect && redirect.url);

                if (url && !disallowRedirect) {
                    if (redirect && redirect.forwardGetParams) {
                        var urlParams = Url(url).params() || {};
                        $.extend(urlParams, params.getParams);
                        url = Url(url).params(urlParams).url();
                    }
                    if (params.resultCallback) {
                        params.resultCallback(ResultsText.redirect);
                    }
                    window.location.replace(url);
                }
                else if (params.resultCallback) {
                    params.resultCallback(ResultsText[type]);
                }
            };

            // Handle survey load event
            s.on('load', function(data) {
                if (data.status === 'earnedCredit' || data.status === 'alreadyTaken') {
                    processResult('taken');
                }
                else if (data.status === 'quotaFilled') {
                    processResult('quota', data && data.redirect);
                }
                else {
                    s.startInterview();
                }
            });

            // Handle the various survey termination events
            s.on('interviewComplete', function(data) {
                if (params.onComplete) {
                    params.onComplete();
                }
                processResult('complete', data && data.redirect);
            });
            s.on('quotaFilled', function(data) {
                processResult('quota', data && data.redirect);
            });
            s.on('respondentDisqualified', function(data) {
                processResult('screened', data && data.redirect);
            });
            s.on('qualityTerm', function(data) {
                processResult('quality', data && data.redirect);
            });
            s.on('interviewError', function() {
                processResult('error');
            });
            s.on('next', function() {
                if (params.take || params.takePreview) {
                    window.scrollTo(0, 0);
                }
            });
        }, true);
    }

    //>>> Date/time functions
    /**
     *
     */
    function now() {
        return new Date();
    }

    /**
     * @param date
     */
    function toUTCDate(date) {
        date = new Date(date);
        var utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(),
            date.getUTCSeconds());
        return utc;
    }

    /**
     * @param date
     * @param days
     */
    function getFutureDate(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    //<<< Date/time functions

    //<<< Environment checking
    /**
     *
     */
    function isProd() {
        if (/^(local|devish)/.test('prod')) {
            return false;
        }
        return true;
    }
    //<<< Environment checking

    //>>> Given an object, build a GET request param string from the object's properties
    /**
     * @param params
     */
    function buildParamString(params) {
        var paramString = '';

        if (typeof params === 'object') {
            for (var key in params) {
                paramString += (paramString === '' ? '?' : '&') + key + '=' + encodeURIComponent(params[key]);
            }
        }
        return paramString;
    }

    /**
     * @param email
     * @param suggestedFcn
     * @param emptyFcn
     */
    function spellcheckEmail(email, suggestedFcn, emptyFcn) {
        // Mailcheck settings
        var domains = ['gmail.com',
            'yahoo.com',
            'comcast.net',
            'verizon.net',
            'sbcglobal.net',
            'icloud.com',
            'hotmail.com'];
        var secondLevelDomains = ['yahoo',
            'hotmail',
            'outlook',
            'gmail'];
        var topLevelDomains = ['com',
            'net',
            'org',
            'edu',
            'info'];

        $.fn.mailcheck({
            email: email,
            domains: domains,
            secondLevelDomains: secondLevelDomains,
            topLevelDomains: topLevelDomains,
            suggested: suggestedFcn,
            empty: emptyFcn,
        });
    }

    // Simple check for email validity
    /**
     * @param email
     */
    function validEmail(email) {
        var emailPattern = /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~;.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
        return email.match(emailPattern);
    }

    // Split a name string into first and last name components
    /**
     * @param name
     */
    function splitName(name) {
        var part1 = name ? name.substr(0, name.indexOf(' ')) : '',
            part2 = name ? name.substr(name.indexOf(' ') + 1) : '';

        return {
            first: part1 || part2 || '',
            last: part1 ? part2 : '',
        };
    }

    // Identify user with segment
    /**
     * @param args
     */
    function identifyUser(args) {
        var id,
            user,
            identifyParams = ['email',
                'name',
                'phone',
                'jobTitle',
                'userCategory',
                'userSource',
                'sourceComments',
                'company',
                'referer',
                'firstPayment',
                'created',
                'notes',
                'anonymous',
                'utmLog',
                'hasPublisher',
                'organization',
                'proRole',
                'eGuideRequested',
                'lastSurvey',
                'feedbackRequestSent',
                'lastQuote',
                'creditGranted',
                'accountManager',
                'contactRequested',
                'isAdmin'];

        // Passed email and name
        if (arguments.length > 1) {
            id = arguments[0];
            user = {
                email: arguments[0],
                name: arguments[1],
            };
        }
        // Passed a JSON object
        else {
            var userJson = arguments[0];
            id = userJson.id;
            user = {};
            for (var i in identifyParams) {
                var param = identifyParams[i];
                user[param] = userJson[param] || undefined;
            }
        }
        analytics.identify(id, user, {
            integrations: {
                Salesforce: true,
            },
        });
    }

    /**
     * @param event
     */
    function trackSegmentEvent(event) {
        if (window.analytics && U.isProd()) {
            window.analytics.track(event);
        }
    }

    /**
     * @param event
     */
    function trackSegmentPageView(event) {
        if (window.analytics) {
            window.analytics.page(event);
        }
    }

    function fireAccountPixels() {
        U.trackSegmentEvent('Account Creation');
    }

    function fireDrafterPixels() {
        U.trackSegmentEvent('Drafters Conversion');
    }

    /**
     * @param surveyPackage
     */
    function fireSubmissionPixels(surveyPackage) {
        U.trackSegmentEvent('Survey Submission');
        if (surveyPackage) {
            U.trackSegmentEvent('Survey ' + surveyPackage + ' Submission');
        }
        if (U.isProd()) {
            new Image().src = 'https://www.quora.com/_/ad/29002f7da28f45ca4899b999cfc74371/pixel';
        }
    }

    /**
     * @param email
     */
    function fireContactUsPixels(email) {
        U.trackSegmentEvent('Contact Us Submission');
    }

    /**
     * @param email
     */
    function fireQuotePixels(email) {
        U.trackSegmentEvent('Get A Quote');
        if (U.isProd()) {
            new Image().src = 'https://www.quora.com/_/ad/29002f7da28f45ca4899b999cfc74371/pixel';
        }
    }

    /**
     * @param email
     */
    function fireEguidePixels(email) {
        U.trackSegmentEvent('Eguide Download');
    }

    function fireSurveyTargetTraffic() {
        U.trackSegmentPageView('Survey design target audience page');
    }

    function fireSurveyDesignTraffic() {
        U.trackSegmentPageView('Survey design page');
    }

    function fireSurveySampleTraffic() {
        U.trackSegmentPageView('Survey design sample page');
    }

    function fireSurveyPaymentTraffic() {
        U.trackSegmentPageView('Survey design payment page');
    }

    //<<< end of analytics functions

    // Add a method to an object with different versions
    // of the method triggered based on different numbers
    // parameters.
    /**
     * @param object
     * @param name
     * @param fn
     */
    function addMethod(object, name, fn) {
        var old = object[name];
        object[name] = function() {
            if (fn.length === arguments.length) {
                return fn.apply(this, arguments);
            }
            else if (typeof old === 'function') {
                return old.apply(this, arguments);
            }
        };
        return U;
    }

    /**
     *
     */
    function uuid4() {
        // Taken from -- https://stackoverflow.com/a/8809472
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //Use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
        });
    }

    /**
     * @param uuid
     */
    function isValidUuid(uuid) {
        return (uuid.length >= 28) && /^[0-9A-Za-z-]+$/.test(uuid);
    }

    // Read/write cookie value
    var cookie = (function() {
        var writeCookie = function(name, value, settings) {
            settings = settings || {};
            var cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';' +
                    (settings.path ? ' path=' + settings.path + ';' : '') +
                    (settings.expires && settings.expires.toGMTString ? ' expires=' + settings.expires.toGMTString() + ';' : '') +
                    (settings.maxAge ? ' max-age=' + settings.maxAge + ';' : '') +
                    (settings.domain ? ' domain=' + settings.domain + ';' : '') +
                    (settings.secure ? ' secure;' : '');
            document.cookie = cookie;
            return U;
        };
        var readCookie = function(name) {
            var cookie = _.chain(document.cookie.split(';'))
                .map(function(x) {
                    x = trim(x);
                    var p = x.indexOf('='),
                        n = p === -1 ? x : x.substr(0, p),
                        v = p === -1 ? '' : x.substr(p + 1);
                    return {
                        n: decodeURIComponent(n),
                        v: decodeURIComponent(v),
                    };
                })
                .filter(function(x) {
                    return name === x.n;
                })
                .value();

            return cookie.length ? cookie[0].v : undefined;
        };

        return function cookie() {
            var fn = arguments.length === 1 ? readCookie : writeCookie;
            return fn.apply(U, arguments);
        };
    })();

    // List all of the cookies on this domain
    /**
     *
     */
    function cookies() {
        var allCookies = _.chain(document.cookie.split(';'))
            .map(function(x) {
                x = trim(x);
                var p = x.indexOf('='),
                    n = p === -1 ? x : x.substr(0, p),
                    v = p === -1 ? '' : x.substr(p + 1);
                return {
                    n: decodeURIComponent(n),
                    v: decodeURIComponent(v),
                };
            });
        allCookies = allCookies._wrapped;
        return allCookies;
    }

    // Delete a cookie
    /**
     * @param name
     * @param settings
     */
    function deleteCookie(name, settings) {
        var url = U.Url(),
            paths = [],
            pathParts,
            domains = [],
            domainParts,
            expiration, i, j, l;

        if (settings && settings.path && (settings.path === '*')) { // All possible paths
            paths.push('', '/');
            pathParts = url.path().split('/').slice(1, -1);
            for (i = 1, l = pathParts.length; i <= l; i++) {
                paths.push('/' + pathParts.slice(0, i).join('/') + '/');
            }
        }
        else if (settings && settings.path) { // A single explicit path
            paths.push(settings.path);
        }
        else { // The default path
            paths.push('');
        }

        if (settings && settings.domain && (settings.domain === '*')) { // All possible domains
            domains.push('');
            domainParts = url.host().split('.');
            for (i = 0, l = domainParts.length; i < l; i++) {
                domains.push(domainParts.slice(i, l).join('.'));
                domains.push('.' + domainParts.slice(i, l).join('.'));
            }
        }
        else if (settings && settings.domain) { // A single explicit domain
            domains.push(settings.domain);
        }
        else { // Default domain
            domains.push('');
        }

        // Delete the cookie by setting its expiration in the past
        // for all relevant combinations of path and domain
        expiration = now();
        expiration.setTime(expiration.getTime() - 3600 * 24 * 1000);
        for (i = 0; i < paths.length; i++) {
            for (j = 0; j < domains.length; j++) {
                cookie(name, '', {
                    path: paths[i],
                    domain: domains[j],
                    expires: expiration,
                });
            }
        }

        return U;
    }

    // Test if the browser settings allow us to write cookies.
    /**
     *
     */
    function canWriteCookie() {
        var testCookieName = '__' + uuid4(),
            testCookieValue = uuid4(),
            canSet;

        // Set a cookie and test if the value sticks.
        cookie(testCookieName, testCookieValue);
        canSet = testCookieValue === cookie(testCookieName);
        deleteCookie(testCookieName);

        return canSet;
    }
    //<<< Cookie functions

    // Test whether the current device supports touch events.
    var isTouchDevice = (function() {
        var _isTouchDevice = !!(('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch));

        return function isTouchDevice() {
            return _isTouchDevice;
        };
    })();

    //>>> Class for URL manipulation
    var Url = (function() {
        return function Url(url) {
            var result = {},
                _protocol = '',
                _host = '',
                _port = '',
                _path = '',
                _params = {},
                _fragment = '';

            // Parse a URL query string into separate parameters.
            var parseQueryString = function(queryString) {
                _params = {};

                _.forEach(queryString.split('&'), function(param) {
                    var ix = param.indexOf('='),
                        key = ix === -1 ? param : param.slice(0, ix),
                        value = ix === -1 ? '' : param.slice(ix + 1);
                    if (key) {
                        _params[key] = decodeURIComponent(value);
                    }
                });
            };
                // Build a URL query string from separate parameter values.
            var buildQueryString = function() {
                return _.chain(_params).map(function(v, k) {
                    return k + '=' + encodeURIComponent(v);
                }).value().join('&');
            };

            // Break a URL into its consituent parts.
            var parseUrl = function(url) {
                var ix, rest, query, _queryParams;

                ix = url.indexOf('//');
                _protocol = ix === -1 ? url : url.slice(0, ix);
                _protocol = _protocol || ((window && window.location && window.location.protocol) || '');
                rest = ix === -1 ? '' : url.slice(ix + 2);

                // Strip anchor tag off end of url but make sure its not part of angular route
                ix = rest.indexOf('#');
                if (rest[ix + 1] !== '/') {
                    _fragment = ix === -1 ? '' : rest.slice(ix + 1);
                    rest = ix === -1 ? rest : rest.slice(0, ix);
                }

                // Strip GET params off of end of url
                ix = rest.indexOf('?');
                query = ix === -1 ? '' : rest.slice(ix + 1);
                rest = ix === -1 ? rest : rest.slice(0, ix);
                parseQueryString(query);

                ix = rest.indexOf(':');
                if (ix === -1) { // No port
                    _port = '';
                    ix = rest.indexOf('/');
                    _host = ix === -1 ? rest : rest.slice(0, ix);
                    _path = ix === -1 ? '' : rest.slice(ix);
                }
                else {
                    _host = rest.slice(0, ix);
                    rest = rest.slice(ix + 1);
                    ix = rest.indexOf('/');
                    _port = ix === -1 ? rest : rest.slice(0, ix);
                    _path = ix === -1 ? '' : rest.slice(ix);
                }
            };

            // Build a URL from its constituent parts.
            var buildUrl = function() {
                var queryString = buildQueryString();

                return _protocol + '//' + _host + (_port ? ':' + _port : '') +
                        _path + (queryString ? '?' + queryString : '') +
                        (_fragment ? '#' + _fragment : '');
            };

            // Get/set full URL
            U.addMethod(result, 'url', function() {
                return buildUrl();
            }).addMethod(result, 'url', function(url) {
                parseUrl(url);
                return this;
            });

            // Get/set URL origin = protocol + host + port
            U.addMethod(result, 'origin', function() {
                return _protocol + '//' + _host + (_port ? ':' + _port : '');
            }).addMethod(result, 'origin', function(url) {
                var ix, rest;

                ix = url.indexOf('//');
                _protocol = ix === -1 ? url : url.slice(0, ix);
                _protocol = _protocol || ((window && window.location && window.location.protocol) || '');
                rest = ix === -1 ? '' : url.slice(ix + 2);

                ix = rest.indexOf(':');
                if (ix === -1) { // No port
                    _port = '';
                    ix = rest.indexOf('/');
                    _host = ix === -1 ? rest : rest.slice(0, ix);
                    _path = ix === -1 ? '' : rest.slice(ix);
                }
                else {
                    _host = rest.slice(0, ix);
                    rest = rest.slice(ix + 1);
                    ix = rest.indexOf('/');
                    _port = ix === -1 ? rest : rest.slice(0, ix);
                    _path = ix === -1 ? '' : rest.slice(ix);
                }

                return this;
            });

            // Get/set a single URL parameter
            U.addMethod(result, 'param', function(key) {
                return _params[key] || '';
            }).addMethod(result, 'param', function(key, value) {
                _params[key] = value && value.toString && value.toString();
                return this;
            });

            // Get/set all URL parameters
            U.addMethod(result, 'params', function() {
                return _params;
            }).addMethod(result, 'params', function(params) {
                _params = params;
                return this;
            });

            // Get/set the query string
            U.addMethod(result, 'queryString', function() {
                return buildQueryString();
            }).addMethod(result, 'queryString', function(queryString) {
                parseQueryString(queryString);
                return this;
            });

            // Get/set URL protocol
            U.addMethod(result, 'protocol', function() {
                return _protocol;
            }).addMethod(result, 'protocol', function(protocol) {
                _protocol = protocol;
            });

            // Get/set URL host
            U.addMethod(result, 'host', function() {
                return _host;
            }).addMethod(result, 'host', function(host) {
                _host = host;
                return this;
            });

            // Get/set URL port
            U.addMethod(result, 'port', function() {
                return _port;
            }).addMethod(result, 'port', function(port) {
                _port = port;
                return this;
            });

            // Get/set URL path
            U.addMethod(result, 'path', function() {
                return _path;
            }).addMethod(result, 'path', function(path) {
                _path = path;
                return this;
            });

            // Get/set URL fragment
            U.addMethod(result, 'fragment', function() {
                return _fragment;
            }).addMethod(result, 'fragment', function(fragment) {
                _fragment = fragment;
                return this;
            });

            // Convert an absolute to be relative to the URL.
            U.addMethod(result, 'relativize', function(otherUrlString) {
                var otherUrl = Url(otherUrlString),
                    result = otherUrl.url(),
                    basePath = _path.split('/'),
                    otherPath = otherUrl.path().split('/'),
                    i, j,
                    bpl = basePath.length,
                    opl = otherPath.length,
                    otherQueryString, otherFragment;

                if ((otherUrl.host().replace(/^www\./, '') === _host.replace(/^www\./, '')) && (otherUrl.port() === _port) &&
                        ((otherUrl.protocol() === _protocol) ||
                         ((otherUrl.protocol() === 'https:') && (_protocol === 'http:')) ||
                         ((otherUrl.protocol() === 'http:') && (_protocol === 'https:')))) {
                    result = '';
                    i = 0;
                    while ((i < bpl) && (i < opl) && (basePath[i] === otherPath[i])) {
                        i++;
                    }
                    for (j = i; j < (bpl - 1); j++) {
                        result += result === '' ? '..' : '/..';
                    }
                    for (j = i; j < opl; j++) {
                        result += result === '' ? otherPath[j] : '/' + otherPath[j];
                    }

                    otherQueryString = otherUrl.queryString();
                    otherFragment = otherUrl.fragment();

                    result = result + (otherQueryString ? '?' + otherQueryString : '') +
                            (otherFragment ? '#' + otherFragment : '');
                }

                return result;
            });

            //>>> Initialize
            parseUrl(url || (window && window.location && window.location.href) || '');

            return result;
        };
    })();
        //<<< Class for URL manipulation

    /**
     *
     */
    function utmSource() {
        var utmLog,
            firstSource, lastSource;

        try {
            utmLog = JSON.parse(U.cookie('utm_log'));
        }
        catch (e) {}

        firstSource = (utmLog && utmLog.first && utmLog.first.source) || '';
        lastSource = (utmLog && utmLog.last && utmLog.last.source) || '';

        if (firstSource === lastSource) {
            return firstSource;
        }

        return firstSource + ' / ' + lastSource;
    }

    /**
     * Given a UTC date, return a "hybrid" version of this date such that the date portion is in local time,
     * but the time is 00:00:00 UTC time. That way, the date will be what the user intended (because the UTC date could be
     * a day ahead at certain times of day) Additionally, we want time to consistently be 00:00:00 (UTC, not local) instead
     * of whatever time it is when the user entered the date.
     *
     * @param {Date} utcDate - UTC date object
     * @param {string} [format] - Date formatting string
     * @returns {string} - Formatted date string of the modified UTC date
     */
    function datetimeToUTCZeroISOString(utcDate, format) {
        const utcMoment = moment.utc(utcDate),
            localMoment = moment(utcDate),

            // Get the local day, month, and year
            localDay = localMoment.day(),
            localMonth = localMoment.month(),
            localYear = localMoment.year(),

            // Create the hybrid date with local date and UTC 00:00:00
            zeroUtcMoment = utcMoment
                .day(localDay).month(localMonth).year(localYear) // Set the date to whatever LOCAL date it is now
                .hours(0).minutes(0).seconds(0).milliseconds(0); // Set the time to 00:00:00

        // If an alternate format was provided, return it in that form
        if (format) {
            return zeroUtcMoment.format('YYYY-MM-DD HH:mm:ss');
        }

        // Convert to ISO 8601 date string format (ex. "2019-10-31T00:00:00.000Z")
        return zeroUtcMoment.toISOString();
    }

    /**
     * Apply UTC offset from local date.
     * If a date is provided, we need to first ensure that the date portion displays correctly in the UI
     * We are given a UTC date, with time 00:00:00. If we're in a time zone that is behind UTC,
     * this means that the date portion would be one day behind UTC. To get around this, determine the offset hours
     * and if it is negative, add the correponding number of hours to the date object. This will ensure that day of the local date is
     * the same as UTC.
     *
     * @param {Date} date - A UTC date object
     */
    function applyUTCOffsetToDate(date) {
        // Get the hours offset between utc and local time
        // Note that utcOffset returns the offset in minutes, so divide by 60 to convert to hours
        const utcOffsetHours = moment().utcOffset() / 60;
        let utcMoment = moment.utc(date);

        // If local is behind UTC time, we need to add the corresponding hours to the date
        // so that the date portion of local and UTC are the same
        if (utcOffsetHours < 0) {
            utcMoment = utcMoment.hours(utcOffsetHours * -1);
        }

        return utcMoment.toDate();
    }

    // Placing at bottom so the 'var'ed expresssions are evaluated and U
    U.calculateDifferenceInDays = calculateDifferenceInDays;
    U.objToArray = objToArray;
    U.toArray = toArray;
    U.toCamelCase = toCamelCase;
    U.numberToAlphaCode = numberToAlphaCode;
    U.alphaToNumber = alphaToNumber;
    U.eventable = eventable;
    U.isValidJsonStr = isValidJsonStr;
    U.copyToClipboard = copyToClipboard;
    U.alphabetizeObjectKeys = alphabetizeObjectKeys;
    U.limitStrLength = limitStrLength;
    U.stringRemoveHtml = stringRemoveHtml;
    U.dateFormatter = _dateFormatter;
    U.isSafari = isSafari;
    U.isFirefox = isFirefox;
    U.isIe = isIe;
    U.addMethod = addMethod;
    U.uuid4 = uuid4;
    U.isValidUuid = isValidUuid;
    U.cookie = cookie;
    U.cookies = cookies;
    U.findByUuid = findByUuid;
    U.deleteCookie = deleteCookie;
    U.canWriteCookie = canWriteCookie;
    U.trim = trim;
    U.isTouchDevice = isTouchDevice;
    U.Url = Url;
    U.fireEguidePixels = fireEguidePixels;
    U.fireQuotePixels = fireQuotePixels;
    U.fireSubmissionPixels = fireSubmissionPixels;
    U.fireContactUsPixels = fireContactUsPixels;
    U.fireDrafterPixels = fireDrafterPixels;
    U.fireAccountPixels = fireAccountPixels;
    U.fireSurveyTargetTraffic = fireSurveyTargetTraffic;
    U.fireSurveyDesignTraffic = fireSurveyDesignTraffic;
    U.fireSurveySampleTraffic = fireSurveySampleTraffic;
    U.fireSurveyPaymentTraffic = fireSurveyPaymentTraffic;
    U.trackSegmentPageView = trackSegmentPageView;
    U.trackSegmentEvent = trackSegmentEvent;
    U.identifyUser = identifyUser;
    U.utmSource = utmSource;
    U.splitName = splitName;
    U.validEmail = validEmail;
    U.spellcheckEmail = spellcheckEmail;
    U.buildParamString = buildParamString;
    U.utm = utm.loadUtil(U); // This should be working... why isn't it??   :() 🎅 🚲 💩 🍏 🎯
    U.isProd = isProd;
    U.getFutureDate = getFutureDate;
    U.toUTCDate = toUTCDate;
    U.now = now; // Removable??
    U.rand = rand;
    U.clone = clone;
    U.extend = extend;// Removable??
    U.arrayShuffle = arrayShuffle; ///removable  use lodash/underscore?
    U.showSurveyWall = showSurveyWall;
    U.range = range;
    U.redirect = redirect;
    U.parseBool = parseBool;
    U.arrayIntersection = arrayIntersection;
    U.arrayContains = arrayContains;
    U.isEmptyArray = isEmptyArray;
    U.isEmpty = isEmpty;
    U.debounce = debounce;
    U.min = min;
    U.makeDate = makeDate;
    U.objLength = objLength;
    U.isInteger = isInteger;
    U.trimObject = trimObject;
    U.reorderObjKeys = reorderObjKeys;
    U.renameObjProperty = renameObjProperty;
    U.isObjectEqualExceptForKey = isObjectEqualExceptForKey;
    U.copyAllObjectPropertiesExcept = copyAllObjectPropertiesExcept;
    U.cloneObjectExcept = cloneObjectExcept;
    U.toUnicode = toUnicode;
    U.unicodeToChar = unicodeToChar;
    U.stringToCsvStringCompat = stringToCsvStringCompat;
    U.datetimeToUTCZeroISOString = datetimeToUTCZeroISOString;
    U.applyUTCOffsetToDate = applyUTCOffsetToDate;

    window.U = U; // Expose on the global object

    return U;
})(window, document);
