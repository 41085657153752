angular.module('svModal', [])
    .directive('svModal', [function() {
        return {
            restrict: 'ECA',
            replace: true,
            transclude: true,
            templateUrl: '/shared-templates/sv-modal.html',
            scope: {
                title: '@',
                caption: '@',
                description: '@',
                footerOptions: '=',
            },
        };
    }])
    .directive('inputFormatter', ['$filter', function($filter) {
        return {
            require: 'ngModel',
            link: function(elem, $scope, attrs, ngModel) {
                ngModel.$formatters.push(function(val) {
                    return parseFloat($filter('number')(val, parseInt(attrs.inputFormatter, 10)));
                });
            },
        };
    }]);
