angular.module('postalCode.summaryView', [])
    .directive('postalCodeSummary', postalCodeSummary)
    .controller('PostalCodeSummaryCtrl', PostalCodeSummaryCtrl);

function postalCodeSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/postal-code-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'PostalCodeSummaryCtrl',
        controllerAs: 'PostalCodeSummaryVM',
    };
}
PostalCodeSummaryCtrl.$inject = ['$scope'];

function PostalCodeSummaryCtrl($scope) {
    var PostalCodeSummaryVM = this;
    PostalCodeSummaryVM.summary = summary;

    function summary() {
        var fullString = $scope.requirement.details.join(', ');
        if (fullString.length > 100) {
            return fullString.substring(0, 100) + '...';
        }
        return fullString;
    }
}
