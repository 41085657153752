import '../../survey/api-survey-service';
import adMeasurementService from '../ad-measurement.service';
angular.module('audienceConfiguration.windowDays', ['apiSurveyService'])
    .controller('WindowDaysCtrl', WindowDaysCtrl);
WindowDaysCtrl.$inject = [
    '$rootScope',
    '$uibModalInstance',
    '$notify',
    '$timeout',
    'surveyService',
];

/**
 * Creates the Window Days controller
 *
 * @param {object} $rootScope -- Object that refers to application model
 * @param {object} $uibModalInstance -- Modal instance
 * @param {object} $notify -- Notification service
 * @param {object} $timeout -- Built-in timeout service
 * @param {object} surveyService -- Survey service
 */
function WindowDaysCtrl($rootScope, $uibModalInstance, $notify, $timeout, surveyService) {
    var WindowDaysVM = this;
    WindowDaysVM.closeModal = $uibModalInstance.close;
    WindowDaysVM.sharedUx = {};
    WindowDaysVM.updateWindowDaysChanged = updateWindowDaysChanged;

    init();

    /**
     * Grabs beacon pool targeting requirements, including window days
     *
     */
    function init() {
        let beaconPoolTargetingReq = adMeasurementService.getBeaconPoolTargetingReq($rootScope.survey.targetingReqs);
        WindowDaysVM.windowDays = beaconPoolTargetingReq && beaconPoolTargetingReq.details.windowDays;
    }

    /**
     * Updates window days stored in the database based on UI input, and alerts user to success or failure
     *
     */

    function updateWindowDaysChanged() {
        surveyService.updateAudience($rootScope.survey._audienceId, {
            windowDays: WindowDaysVM.windowDays,
        }).then(response => {
            $timeout(() => {
                // Because we're getting all the targetingReqs, we set windowDays on $rootScope survey
                // to make sure user value saved after Submit, and so UI is consistent with DB.
                $rootScope.survey.targetingReqs = response.targetingReqs;
                $notify.success(`Window days successfully updated!`);
                $uibModalInstance.close();
            }, 200);
        }).catch(() => {
            $notify.error(`Error saving window days.`);
        });
    }
}

