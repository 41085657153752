var templates = {
    COUNT_AND_PERCENT: '<tr class="data-point"><td class="heading" style="color: <%- datasetColor %>"><%- name %></td><td class="value"><%- primaryData %></td><td class="percent"><%- secondaryData %></td></tr>',
    COUNT_ONLY: '<tr class="data-point"><td class="heading" style="color: <%- datasetColor %>"><%- name %></td><td class="value"><%- primaryData %></td></tr>',
};
const chartsConstant = Object.freeze({
    COUNT_AND_PERCENT_TEMPLATE: _.template(templates.COUNT_AND_PERCENT),
    COUNT_ONLY_TEMPLATE: _.template(templates.COUNT_ONLY),
    PLOTTABLE_SCALES: {
        CATEGORY: 'Category',
        LINEAR: 'Linear',
        MODIFIED_LOG: 'ModifiedLog',
        TIME: 'Time',
    }, // NOTE: only a few listed can be updated later
    PLOTTABLE_PLOT_TYPES: {
        BAR: 'BAR',
        CLUSTEREDBAR: 'CLUSTEREDBAR',
        SEGMENT: 'SEGMENT',
        SCATTER: 'SCATTER',
        LINE: 'LINE',
    },
    COLOR_ARRAY: [
        '#00ADEF', // @survata-blue
        '#0D5B8F', // @survata-blue-dark
        '#F07800', // @survata-orange
        '#216e8e', // @survata-blue-special3
        '#163659', // @survata-blue-special1
        '#893f25', // @color-xtab-brown-dark
        '#1179be', // @color-blue-medium
        '#004c6c', // @survata-blue-special12
        '#d36400', // @survat-orange-medium
    ],
});

export default chartsConstant;
