import {
    fontHasChanged,
} from './export-service';

const textExportService = {
    draw: draw,
};

const LEFT = 'left';

function draw(canvas, params, displayOptions) {
    let ctx = canvas.getContext('2d'),
        lines = [],
        yOffset = displayOptions.yOffset,
        strokeStyle,
        textColor;
    ctx.textAlign = params.alignment;
    _.forEach(params.text, line => {
        lines = lines.concat(computeTextLinesToDraw(line, ctx, canvas.width - displayOptions.exportMargin));
    });
    // Changing stroke color apparently isn't performant, so it's better to do it in two for-loops
    _.forEach(lines, line => {
        let xOffset = params.alignment === LEFT ? displayOptions.exportMargin : canvas.width / 2,
            maxLineHeight = 0;
        _.forEach(line, segment => {
            if (segment.strokeStyle) {
                yOffset += segment.lineHeight / 2;
                if (strokeStyle !== segment.strokeStyle) {
                    ctx.strokeStyle = segment.strokeStyle;
                    strokeStyle = ctx.strokeStyle;
                }
                ctx.beginPath();
                ctx.lineWidth = segment.lineHeight;
                ctx.moveTo(xOffset - segment.width / 2, yOffset);
                ctx.lineTo(xOffset + segment.width / (params.alignment === LEFT ? 1 : 2), yOffset);
                ctx.stroke();
                yOffset -= segment.lineHeight / 2;
            }
            xOffset += segment.width;
        });
        yOffset += maxLineHeight / 2;
    });
    yOffset = displayOptions.yOffset;
    strokeStyle = void 0;
    _.forEach(lines, line => {
        let xOffset = params.alignment === LEFT ? displayOptions.exportMargin : canvas.width / 2,
            maxLineHeight = 0,
            buffer = 0;
        _.forEach(line, segment => {
            if (textColor !== segment.color) {
                ctx.fillStyle = segment.color;
                textColor = ctx.fillStyle;
            }
            yOffset += segment.lineHeight / 2;
            if (segment.underlineColor) {
                yOffset += segment.fontSize / 2;
                if (strokeStyle !== segment.underlineColor) {
                    ctx.strokeStyle = segment.underlineColor;
                    strokeStyle = segment.underlineColor;
                }
                ctx.beginPath();
                ctx.lineWidth = segment.lineWidth * displayOptions.scale;
                ctx.moveTo(xOffset - (params.alignment === LEFT ? 0 : segment.width / 2), yOffset);
                ctx.lineTo(xOffset + segment.width / (params.alignment === LEFT ? 1 : 2), yOffset);
                ctx.stroke();
                buffer = Math.max(buffer, ctx.lineWidth);
                yOffset -= segment.fontSize / 2;
            }
            if (segment.text) {
                if (fontHasChanged(ctx.font, segment.font)) {
                    ctx.font = segment.font;
                }
                ctx.fillText(segment.text, xOffset, yOffset);
            }
            maxLineHeight = Math.max(maxLineHeight, segment.lineHeight);
            xOffset += segment.width;
            yOffset -= segment.lineHeight / 2;
        });
        yOffset += maxLineHeight / 2 + buffer;
    });

    displayOptions.yOffset = yOffset;
}

/**
 * @name computeTextLinesToDraw
 * @param {CanvasRenderingContext2D} context - The context that the title will be rendered to
 * @param {number} maxWidth - The maxWidth allowed for the text
 * @returns {number} The total vertical space that the text will take up
 *
 * @description
 * Computes the total vertical space that the text will take up and puts
 * an array of strings composed of the lines of text the initial text will
 * be broken up into
 */
function computeTextLinesToDraw(line, context, maxWidth) {
    let lines = [],
        lineSegment = [],
        widthSoFar = 0;
    _.forEach(line, segment => {
        let currentSegment = getCurrentSegmentOptions(segment, '', segment.width);
        if (widthSoFar + currentSegment.width > maxWidth) {
            lines.push(lineSegment);
            lineSegment = [currentSegment];
        }
        else {
            lineSegment.push(currentSegment);
        }
        if (segment.text) {
            _.forEach(segment.text.split(' '), word => {
                let nextWord = (currentSegment.text.length ? ' ' : '') + word,
                    testLine = currentSegment.text + nextWord,
                    metrics;
                context.font = segment.font;
                metrics = context.measureText(testLine);
                if (metrics.width > maxWidth) {
                    let prevWidth = currentSegment.width;
                    lines.push(lineSegment);
                    currentSegment = getCurrentSegmentOptions(segment, word, metrics.width - prevWidth);
                    lineSegment = [currentSegment];
                }
                else {
                    currentSegment.text += nextWord;
                    currentSegment.width = metrics.width + (segment.width || 0);
                }
                widthSoFar = currentSegment.width;
            });
        }
    });
    if (lineSegment.length) {
        lines.push(lineSegment);
    }
    return lines;
}

function getCurrentSegmentOptions(segment, text, width) {
    let options = {
        font: segment.font,
        text: text,
        fontSize: segment.font.match(/(\d+)px/)[1],
        lineHeight: segment.lineHeight,
        width: width || 0,
        color: segment.color,
    };
    if (segment.underlineColor) {
        options.underlineColor = segment.underlineColor;
        options.lineWidth = segment.lineWidth || 1;
    }
    if (segment.strokeStyle) {
        options.strokeStyle = segment.strokeStyle;
    }
    return options;
}

export default textExportService;
