import './age/editor-view';
import './age/summary-view';
import './beacon-pool/editor-view';
import './beacon-pool/summary-view';
import './country/editor-view';
import './country/summary-view';
import './device/editor-view';
import './device/summary-view';
import './gender/editor-view';
import './gender/summary-view';
import './metro/editor-view';
import './metro/summary-view';
import './operating-system/editor-view';
import './operating-system/summary-view';
import './postal-code/editor-view';
import './postal-code/summary-view';
import './region/editor-view';
import './region/summary-view';
import './screening-question/editor-summary-view';
import './tracking-method/editor-view';
import './tracking-method/summary-view';
import './web-browser/editor-view';
import './web-browser/summary-view';

angular.module('targetingAttributes.views', [
    'age.editorView',
    'age.summaryView',
    'beaconPool.editorView',
    'beaconPool.summaryView',
    'country.editorView',
    'country.summaryView',
    'device.editorView',
    'device.summaryView',
    'gender.editorView',
    'gender.summaryView',
    'metro.editorView',
    'metro.summaryView',
    'operatingSystem.editorView',
    'operatingSystem.summaryView',
    'postalCode.editorView',
    'postalCode.summaryView',
    'region.editorView',
    'region.summaryView',
    'screeningQuestion.editorSummaryView',
    'trackingMethod.editorView',
    'trackingMethod.summaryView',
    'webBrowser.editorView',
    'webBrowser.summaryView',
]);
