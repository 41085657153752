import '../survey/api-survey-service';
import '../survey/survey-statuses';

angular.module('moveToAnalystReview.modal', ['apiSurveyService', 'surveyStatusesService'])
    .controller('MoveToAnalystReviewCtrl', MoveToAnalystReviewCtrl);
MoveToAnalystReviewCtrl.$inject = [
    '$rootScope',
    '$uibModalInstance',
    'surveyService',
    'surveyStatusesService',
];

/**
 * Controller for moving to analyst review
 *
 * @param {object} $rootScope
 * @param {object} $uibModalInstance
 * @param {object} surveyService
 * @param {object} surveyStatusesService
 */
function MoveToAnalystReviewCtrl($rootScope, $uibModalInstance, surveyService, surveyStatusesService) {
    var MoveToAnalystReviewVM = this;
    MoveToAnalystReviewVM.close = $uibModalInstance.close;
    MoveToAnalystReviewVM.changeSurveyStatus = changeSurveyStatus;

    /**
     * Change survey status
     *
     * @param {object} action
     */
    function changeSurveyStatus(action) {
        surveyStatusesService.changeSurveyStatus($rootScope.survey, action);
        MoveToAnalystReviewVM.close();
    }
}
