const standardCutsConstants = {
    tableHeaders: [{
        name: '',
        selectAll: true,
    },
    {
        name: 'Cut name',
        sortable: true,
        tooltip: true,
        columnType: 'text',
        sortField: 'name',
        value: 'category',
    },
    {
        name: 'Cut list',
        tooltip: true,
        columnType: 'text',
        value: 'cutList',
        columnClass: 'is-inactive',
    },
    {
        name: 'Control type',
        tooltip: true,
        columnType: 'text',
        value: 'controlType',
    },
    {
        name: '',
        expandable: true,
        simple: true,
    }],

    tableOptions: {
        noSearch: true,
        nestedRows: [{
            nestedRowField: 'options',
            type: 'table',
            rowClass: 'overflow-visible',
            columns: [{
                iterable: true,
                columnType: 'input',
                inputType: 'checkbox',
                cellClass: 'padding-right-sm',
                eventName: 'update-cut-list',
                model: '_selected',
            }],
        }],
    },
};

export default standardCutsConstants;
