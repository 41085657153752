import webBrowserOptions from './options';

angular.module('webBrowser.summaryView', [])
    .directive('webBrowserSummary', webBrowserSummary)
    .controller('WebBrowserSummaryCtrl', WebBrowserSummaryCtrl);

function webBrowserSummary() {
    return {
        restrict: 'E',
        templateUrl: '/survey-creation-templates/target/web-browser-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'WebBrowserSummaryCtrl',
        controllerAs: 'WebBrowserSummaryVM',
    };
}
WebBrowserSummaryCtrl.$inject = ['$scope'];

function WebBrowserSummaryCtrl($scope) {
    var WebBrowserSummaryVM = this;
    WebBrowserSummaryVM.options = webBrowserOptions.list;
    WebBrowserSummaryVM.selected = selected;

    function selected(option) {
        return $scope.requirement && $scope.requirement.details && ($scope.requirement.details.indexOf(option.id) !== -1);
    }
}
