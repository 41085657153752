import '../../../common-components/pub-sub';
import {
    prepareLogic,
} from '../../../filter-and-compare/default-filter-service';
import {
    convertParsedCriteria,
} from '../../../filter-and-compare/filter-service';
import '../../../filter-logic/filter-logic-editor';
import analysisConfigConstants from '../analysis-config-constants';
import '../analysis-plan.service';
import AnalysisConfigModel from '../analysis-config-model';
import Config from '../../../config';

angular.module('analysisCut', [
    'pubSub',
    'analysisPlan.service',
    'filterLogicEditor',
])
    .controller('AnalysisCutCtrl', AnalysisCutCtrl);
AnalysisCutCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$uibModalInstance',
    'pubSubService',
    'analysisPlanService',
    'cut',
    'customCutCategories',
];

/**
 * @param $scope
 * @param $rootScope
 * @param $uibModalInstance
 * @param pubSubService
 * @param analysisPlanService
 * @param cut
 * @param customCutCategories
 */
function AnalysisCutCtrl($scope, $rootScope, $uibModalInstance, pubSubService, analysisPlanService, cut, customCutCategories) {
    var AnalysisCutVM = this;
    const MAX_CUT_NAME_LENGTH = 255;

    // VM exposed variables
    AnalysisCutVM.customCutCategories = customCutCategories;
    AnalysisCutVM.cutTypes = ['exposed', 'control'];
    AnalysisCutVM.types = analysisConfigConstants.cutControlTypes;
    AnalysisCutVM.setUpdatedCuts = {
        exposed: setUpdatedCuts('exposed'),
        control: setUpdatedCuts('control'),
    };
    AnalysisCutVM.newAnalysisCutCategory = {
        analysisCutCategoryType: 'custom',
    };
    AnalysisCutVM.ux = {
        cutNameCharsRemaining: MAX_CUT_NAME_LENGTH,
        showCutNameCharsRemaining: false,
        cutNameMaxLength: MAX_CUT_NAME_LENGTH,
    };

    // VM Exposed functions
    AnalysisCutVM.save = save;
    AnalysisCutVM.enableSave = enableSave;
    AnalysisCutVM.close = close;
    AnalysisCutVM.onNewCutCategoryNameChanged = onNewCutCategoryNameChanged;
    AnalysisCutVM.onCutNameChanged = onCutNameChanged;

    init();

    function init() {
        const analysisCut = angular.copy(cut);
        analysisCut._parentAnalysisCutCategory = analysisCut.analysisCutCategory;

        AnalysisCutVM.filterLogic = {
            exposed: {
                parsedCriteria: prepareLogic(analysisCut.exposed),
                label: 'Exposed',
                filterLogicModel: new AnalysisConfigModel($rootScope.survey, Config.isUsRegion),
            },
            control: {
                parsedCriteria: prepareLogic(analysisCut.control),
                label: 'Control',
                filterLogicModel: new AnalysisConfigModel($rootScope.survey, Config.isUsRegion),
            },
        };

        pubSubService.subscribe('filter-logic-group-error', $scope.$id, displayErrorMessage);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(['filter-logic-group-error'], $scope.$id);
        });
        AnalysisCutVM.cut = analysisCut;
    }

    function onCutNameChanged() {
        AnalysisCutVM.ux.cutNameCharsRemaining = MAX_CUT_NAME_LENGTH - AnalysisCutVM.cut.name.length;
        AnalysisCutVM.ux.showCutNameCharsRemaining = AnalysisCutVM.ux.cutNameCharsRemaining < 10;
    }

    /**
     * @param name
     */
    function categoryNameAlreadyExists(name) {
        return _.find(customCutCategories, item => {
            return item.name === name;
        });
    }

    function onNewCutCategoryNameChanged() {
        if (categoryNameAlreadyExists(AnalysisCutVM.newCutCategoryName)) {
            displayErrorMessage('That category name already exists. Please type a different name');
            return;
        }
        AnalysisCutVM.errorMsg = null;
        if (AnalysisCutVM.newAnalysisCutCategory.name) {
            AnalysisCutVM.cut.analysisCutCategory = null;
        }
    }

    /**
     * @param errorMsg
     */
    function displayErrorMessage(errorMsg) {
        $scope.$evalAsync(() => {
            AnalysisCutVM.errorMsg = errorMsg;
        });
    }

    /**
     * @param updatedCut
     */
    function close(updatedCut) {
        $uibModalInstance.close(updatedCut);
    }

    /**
     *
     */
    function hasCutCategoryName() {
        return AnalysisCutVM.newAnalysisCutCategory.name || (AnalysisCutVM.cut.analysisCutCategory && AnalysisCutVM.cut.analysisCutCategory.name);
    }

    /**
     * Checks whether the user has specified any criteria
     *
     * If parsedCriteria array is empty, then it's automatically false. If not empty,
     * check the first item in the array. If the item is another array, then we have nested $and criteria.
     * Otherwise, check that the first item contains at least one criteria item
     *
     * @returns {boolean} - Whether any exposed criteria has been specified
     */
    function hasExposedCriteria() {
        const parsedCriteria = AnalysisCutVM.filterLogic.exposed.parsedCriteria;

        if (parsedCriteria.length) {
            return Array.isArray(parsedCriteria[0]) ? true : parsedCriteria[0].criteria.length;
        }
        return false;
    }

    /**
     *
     */
    function enableSave() {
        return AnalysisCutVM.cut.name && hasCutCategoryName() && hasExposedCriteria();
    }

    /**
     * @param type
     */
    function setJson(type) {
        AnalysisCutVM.cut[type] = convertParsedCriteria(AnalysisCutVM.filterLogic[type].parsedCriteria, AnalysisCutVM.filterLogic[type].filterLogicModel);
    }

    /**
     * @param type
     */
    function setUpdatedCuts(type) {
        return parsedCriteria => {
            if (parsedCriteria) {
                AnalysisCutVM.filterLogic[type].parsedCriteria = parsedCriteria;
            }
        };
    }

    /**
     *
     */
    function save() {
        setJson('exposed');
        setJson('control');

        return analysisPlanService.saveCustomCut(AnalysisCutVM.cut, AnalysisCutVM.newAnalysisCutCategory.name).then(response => {
            AnalysisCutVM.cut.uuid = response.uuid;
            AnalysisCutVM.cut.analysisCutCategory = response.analysisCutCategory;
            close(AnalysisCutVM.cut);
        }, errorMsg => {
            displayErrorMessage(errorMsg);
        });
    }
}
