angular.module('gender.summaryView', ['gender.options'])
    .directive('genderSummary', genderSummary)
    .controller('GenderSummaryCtrl', GenderSummaryCtrl);

function genderSummary() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/gender-summary.html',
        scope: {
            requirement: '=',
        },
        controller: 'GenderSummaryCtrl',
        controllerAs: 'GenderSummaryVM',
    };
}
GenderSummaryCtrl.$inject = ['$scope', 'genderOptions'];

function GenderSummaryCtrl($scope, genderOptions) {
    var GenderSummaryVM = this;
    GenderSummaryVM.options = genderOptions.list;
    GenderSummaryVM.selected = selected;

    function selected(option) {
        return $scope.requirement && $scope.requirement.details && ($scope.requirement.details.indexOf(option.id) !== -1);
    }
}
