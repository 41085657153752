import 'angular-ui-sortable';
import './pub-sub';

angular.module('dragAndDropSort', ['ui.sortable', 'pubSub'])
    .directive('dragAndDropSort', dragAndDropSort);

/**
 * Directive for drag and drop functionality
 *
 * @returns {object} an object with a directive's structure
 */
function dragAndDropSort() {
    return {
        templateUrl: 'shared-templates/drag-and-drop-sort.html',
        scope: {
            sortableOptions: '=?',
            choices: '=?',
            selection: '=',
            changed: '=',
        },
        controller: function($scope, pubSubService) {
            var orig = $scope.selection.join(','),
                prev = orig,
                sortableOptions = $scope.sortableOptions || {
                    axis: 'y',
                    connectWith: '.ui-sortable-sv',
                    containment: '#ui-sortable-container',
                    start: function(e, ui) {
                        var choiceCell = ui.item.find('.choice-cell');
                        choiceCell.css('width', choiceCell.data('choice-cell-width'));
                        $('.tooltip').hide();
                    },
                    stop: function(e, ui) {
                        var curr = getStringOfIds($scope.selection);
                        ui.item.find('.choice-cell').css('width', 'auto');
                        if (!$scope.changed) {
                            $scope.changed = curr === orig;
                        }
                        if (prev !== curr) {
                            prev = curr;
                            pubSubService.notify('updated-drag-and-sort', $scope.changed(curr));
                        }
                    },
                };
            $scope.sortableOptions = angular.copy(sortableOptions);
            $scope.sortableOptions.tolerance = 'pointer';
            $scope.sortableOptions.distance = 10;
            $scope.sortableOptions.handle = '.sort-handle';
            if ($scope.choices) {
                $scope.unselectedOptions = angular.copy(sortableOptions);
            }
            else {
                delete $scope.sortableOptions.connectWith;
            }

            // Init the unselected choices list
            if ($scope.choices) {
                $scope.unselectedChoices = [];
                $scope.choices.forEach(choice => {
                    if (!$scope.selection.find(s => s.value === choice.value)) {
                        $scope.unselectedChoices.push(choice);
                    }
                });
            }
            $scope.addToSelected = addToSelected;
            $scope.removeFromSelected = removeFromSelected;

            /**
             * Serializes all the selected items
             *
             * @param {object} selections
             * @returns {string} the serialized ids as a comma separated string
             */
            function getStringOfIds(selections) {
                var str = '';
                _.forEach(selections, function(selection) {
                    str += selection.value + ',';
                });
                return str.slice(0, -1);
            }

            /**
             * Add one item to the selected list
             *
             * @param {object} item
             * @param {number} index - the index of the item in the unselectedChoices list
             */
            function addToSelected(item, index) {
                $scope.selection.push(item);
                $scope.unselectedChoices.splice(index, 1);
                var curr = getStringOfIds($scope.selection);
                pubSubService.notify('updated-drag-and-sort', $scope.changed(curr));
            }

            /**
             * Removes one item to the selected list
             *
             * @param {object} item
             * @param {number} index - the index of the item in the selection list
             */
            function removeFromSelected(item, index) {
                $scope.selection.splice(index, 1);
                $scope.unselectedChoices.splice(0, 0, item);
                var curr = getStringOfIds($scope.selection);
                pubSubService.notify('updated-drag-and-sort', $scope.changed(curr));
            }
        },
    };
}
