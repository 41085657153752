import '../../../../../../common/js/inc/sv-notify';
import '../../../common-components/checkbox-indicator';
import '../../../common-components/loading-button';
import '../../../common-components/pub-sub';
import '../../../common-components/display-table';
import '../analysis-plan.service';
import timeBasedCutsConstants from './time-based-cuts-constants';

angular.module('timeBasedCuts.view', [
    'displayTable',
    'svNotify',
    'pubSub',
    'checkboxIndicator',
    'analysisPlan.service',
])
    .directive('timeBasedCuts', timeBasedCuts)
    .controller('TimeBasedCutsCtrl', TimeBasedCutsCtrl);

function timeBasedCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/time-based-cuts.html',
        controller: 'TimeBasedCutsCtrl',
        controllerAs: 'TimeBasedCutsVM',
    };
}

TimeBasedCutsCtrl.$inject = [
    '$scope',
    '$notify',
    'pubSubService',
    'analysisPlanService',
];

function TimeBasedCutsCtrl($scope, $notify, pubSubService, analysisPlanService) {
    const TimeBasedCutsVM = this;

    // VM exposed variables
    TimeBasedCutsVM.tableHeaders = timeBasedCutsConstants.tableHeaders;
    TimeBasedCutsVM.tableOptions = timeBasedCutsConstants.tableOptions;
    TimeBasedCutsVM.timeBasedCuts = analysisPlanService.getTimeBasedCuts();
    TimeBasedCutsVM.ux = {
        loading: false,
        categorySelectionTypes: analysisPlanService.categorySelectionTypes,
    };

    // VM exposed functions
    TimeBasedCutsVM.save = save;

    init();

    function init() {
        analysisPlanService.getCutCategoryMap().then(cutCategoryMap => {
            TimeBasedCutsVM.tableHeaders[0]._selected = analysisPlanService.initializeViewForCuts(TimeBasedCutsVM.timeBasedCuts, cutCategoryMap);
        });
        pubSubService.subscribe('update-time-list', $scope.$id, updateCutList);
        $scope.$on('$destroy', () => {
            pubSubService.destroy('update-time-list', $scope.$id);
        });
    }

    function updateCutList(category) {
        category.cutList = analysisPlanService.getCutListForCategory(category);
    }

    function save() {
        TimeBasedCutsVM.ux.loading = true;
        analysisPlanService.saveMultipleCutsCategories(TimeBasedCutsVM.timeBasedCuts).then(onSave, onSaveFailed);
    }

    function onSave() {
        $scope.$evalAsync(() => {
            TimeBasedCutsVM.ux.loading = false;
        });
    }

    function onSaveFailed() {
        $notify.error('An error occurred saving time-based cuts');
        onSave();
    }
}
