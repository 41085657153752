
function loadUtil(U) {
    // Record utm parameters in the utm_log cookie and log events in our analytics services.
    var url = U.Url(),
        params = url.params(),
        k,
        v,
        utm_log,
        ref_log,
        utm_now = null,
        new_person = false,
        cookie_max_age = 14 * 24 * 3600,
        paramsToStore = [
            'gclid',
            'ref',
            'kw',
            'aP',
            'mT',
            'n',
            'p',
            'c',
        ];

    try {
        utm_log = JSON.parse(U.cookie('utm_log'));
    }
    catch (e) {
        utm_log = {};
        new_person = true;
    }

    for (k in params) {
        if (params.hasOwnProperty(k)) {
            v = params[k];
            if (v && paramsToStore.indexOf(k) != -1) {
                utm_now = utm_now || {};
                utm_now[k] = v;
            }
            else if (v && (k.indexOf('utm_') === 0)) {
                utm_now = utm_now || {};
                utm_now[k.substr(4)] = v;
            }
        }
    }

    // Record referrer information in the ref_log cookie.
    if (document.referrer) {
        var ref,
            ref_log,
            ref_url = U.Url(document.referrer),
            ref_host = ref_url.host(),
            ref_short_url = ref_url.url().substr(0, 256),
            ref_params = ref_url.params();

        try {
            ref_log = JSON.parse(U.cookie('ref_log'));
        }
        catch (e) {
            ref_log = {};
        }

        if (!ref_host.match(/upwave.com$/)) {
            ref = {
                host: ref_host,
                url: ref_short_url,
            };
            if (ref_host.match(/google\.com$/) && ref_params.q) {
                ref.query = ref_params.q;
            }

            ref_log.first = ref_log.first || ref;
            ref_log.last = ref;

            utm_log.first = utm_log.first || utm_now || {
                source: ref_host,
            };
            utm_log.last = utm_now || {
                source: ref_host,
            };
            U.cookie('utm_log', JSON.stringify(utm_log), {
                path: '/',
                domain: '.upwave.com',
                maxAge: cookie_max_age,
            });
            U.cookie('ref_log', JSON.stringify(ref_log), {
                path: '/',
                domain: '.upwave.com',
                maxAge: cookie_max_age,
            });
        }
    }
    else if (new_person || utm_now) {
        utm_log.first = utm_log.first || utm_now || {
            source: 'Direct',
        };
        utm_log.last = utm_now || {
            source: 'Direct',
        };
        U.cookie('utm_log', JSON.stringify(utm_log), {
            path: '/',
            domain: '.upwave.com',
            maxAge: cookie_max_age,
        });
    }
}

export default {
    loadUtil: loadUtil,
};

