angular.module('beaconDefinitionCodeModal', [])
    .controller('BeaconDefinitionCodeModalCtrl', BeaconDefinitionCodeModalCtrl);
BeaconDefinitionCodeModalCtrl.$inject = [
    '$uibModalInstance', 'tag',
];

function BeaconDefinitionCodeModalCtrl($uibModalInstance, tag) {
    var BeaconDefinitionCodeVM = this;
    BeaconDefinitionCodeVM.close = $uibModalInstance.close;
    BeaconDefinitionCodeVM.copyToClipboard = copyToClipboard;
    BeaconDefinitionCodeVM.tag = tag;

    function copyToClipboard() {
        var inp = document.getElementById('copy-to-clipboard');
        if (inp && inp.select) {
            inp.select();
            try {
                document.execCommand('copy');
                inp.blur();
                delete BeaconDefinitionCodeVM.copyError;
                BeaconDefinitionCodeVM.copySuccess = 'Tag copied to clipboard';
            }
            catch (err) {
                delete BeaconDefinitionCodeVM.copySuccess;
                BeaconDefinitionCodeVM.copyError = 'Unable to copy text, please press Ctrl/Cmd + C to copy.';
            }
        }
        else {
            BeaconDefinitionCodeVM.copyError = 'Unable to copy text, please press Ctrl/Cmd + C to copy.';
        }
    }
}
