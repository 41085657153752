import surveyConstants from '../survey/survey-constants';
import '../filter-and-compare/filter-and-compare-model-manager';
import './api-results-service';

angular.module('surveyResults.overview', [
    'apiResultsService', 'filterAndCompare.modelManager',
])
    .controller('resultsOverviewCtrl', resultsOverviewCtrl)
    .directive('resultsOverview', resultsOverview);

/**
 *
 */
function resultsOverview() {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/results-overview.html',
        controller: 'resultsOverviewCtrl',
        controllerAs: 'ResultsOverviewVM',
    };
}
resultsOverviewCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$resultsService',
    'filterAndCompareModelManager',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $resultsService
 * @param filterAndCompareModelManager
 */
function resultsOverviewCtrl($rootScope, $scope, $resultsService, filterAndCompareModelManager) {
    var ResultsOverviewVM = this,
        totalSampleSize = 0;
    ResultsOverviewVM.ux = {
        overviewShowing: true,
        retargeting: $rootScope.survey.retargetingAccount,
    };
    ResultsOverviewVM.canRunAgain = canRunAgain;
    ResultsOverviewVM.togglePane = togglePane;

    init();

    function init() {
        ResultsOverviewVM.resultsCollectedText = resultsCollectedText();
        ResultsOverviewVM.surveyResult = $rootScope.survey.surveyResult ? $rootScope.survey : $rootScope.survey.latestCollectionPeriod;
        ResultsOverviewVM.surveyResultItem = ResultsOverviewVM.surveyResult.surveyResult;
        // DEVNOTE: This will change once we get collection period configurations working
        ResultsOverviewVM.ux.surveyCompleted = ResultsOverviewVM.surveyResultItem && ResultsOverviewVM.surveyResult.completed;
        ResultsOverviewVM.ux.hasResults = $rootScope.survey.latestCollectionPeriod._summary.responses || $rootScope.survey.collectionPeriods.length > 1;

        if (ResultsOverviewVM.ux.surveyCompleted) {
            ResultsOverviewVM.surveyResultItem._completionTimeInDays = U.calculateDifferenceInDays($rootScope.survey.latestCollectionPeriod.started, ResultsOverviewVM.surveyResult.completed);
        }
        var summary = $rootScope.survey.latestCollectionPeriod._summary;
        ResultsOverviewVM.ux.showEstimatedCompleteDate = summary.finishDate && (summary.responses / summary.responsesNeeded) > 0.10;
        ResultsOverviewVM.latestCollectionPeriodSummary = $rootScope.survey.latestCollectionPeriod._summary;
        totalSampleSize = _.reduce($rootScope.survey.collectionPeriods, function(soFar, collectionPeriod) {
            return soFar + collectionPeriod.sampleSize;
        }, 0);
        $scope.surveyResultsSummary = {
            numRespondentsUnfiltered: $rootScope.survey._resultsSummary.responses,
            numRespondentsInLatestCollectionPeriod: $rootScope.survey.latestCollectionPeriod.currentResponses,
        };
        if ($rootScope.survey._isTracker && !filterAndCompareModelManager.collectionPeriodModel().consolidate && !$rootScope.survey.completed) {
            ResultsOverviewVM.resultsCount = $scope.surveyResultsSummary.numRespondentsInLatestCollectionPeriod;
        }
        else {
            ResultsOverviewVM.resultsCount = $scope.surveyResultsSummary.numRespondentsUnfiltered;
        }
    }

    /**
     *
     */
    function resultsCollectedText() {
        if ($rootScope.survey._isTracker) {
            if ($rootScope.survey.completed) {
                return 'Total results collected';
            }

            return 'Results collected in latest period';
        }
        return 'Results collected';
    }

    /**
     *
     */
    function canRunAgain() {
        return ($rootScope.auth.user.isAdmin || $rootScope.survey.surveyPackage !== surveyConstants.PACKAGES.ADVANCED) && ($rootScope.survey.completed || $rootScope.survey.latestCollectionPeriod.completed);
    }

    function togglePane() {
        ResultsOverviewVM.ux.overviewShowing = !ResultsOverviewVM.ux.overviewShowing;
    }
}
