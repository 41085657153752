import '@uirouter/angularjs';
import './ad-measurement.view';
import './audience-configuration/audience-configuration-module';
import './impression-reporting/impression-reporting-module';
import './analysis-plan/analysis-plan.module';
import './campaign/campaign-module';

angular.module('adMeasurement', [
    'ui.router',
    //Shared
    'adMeasurement.view',
    //Audience Configuration
    'audienceConfiguration',
    //Impression reporting
    'impressionReporting',
    //Analysis plan
    'analysisPlan',
    //Campaign form
    'campaignForm',
]).config([
    '$stateProvider', function($stateProvider) {
        const states = {
            audienceConfigurationList: {
                url: '#audience-configuration-list',
                template: '<div audience-configuration-list></div>',
                parent: 'adMeasurement',
            },
            impressionParameterReporting: {
                url: '#impression-parameter-reporting',
                template: '<div impression-parameters></div>',
                parent: 'adMeasurement',
            },
            analysisPlan: {
                template: '<analysis-plan></analysis-plan>',
                parent: 'adMeasurement',
            },
            campaignForm: {
                url: '#campaign-form',
                template: '<div campaign-form></div>',
                parent: 'adMeasurement',
            },
        };
        _.forEach(states, (state, stateName) => {
            state.data = {
                dashboardParent: 'adMeasurement',
            };
            $stateProvider.state(stateName, state);
        });
    }]);
