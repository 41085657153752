angular.module('ranking.editor', [])
    .directive('rankingEditor', rankingEditor)
    .controller('RankingEditorCtrl', RankingEditorCtrl);

/**
 *
 */
function rankingEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/ranking-editor.html',
        scope: {
            question: '=',
        },
        controller: 'RankingEditorCtrl',
        controllerAs: 'RankingEditorVM',
    };
}
RankingEditorCtrl.$inject = ['$rootScope', '$scope'];

/**
 * @param $rootScope
 * @param $scope
 */
function RankingEditorCtrl($rootScope, $scope) {
    const MAX_CHOICES = 6;
    const REQUIRED = 2;
    var RankingEditorVM = this;
    RankingEditorVM.missing = REQUIRED;
    RankingEditorVM.auth = $rootScope.auth;
    RankingEditorVM.bulkEditor = {
        show: false,
        text: '',
    };
    RankingEditorVM.exportValues = {
        show: false,
    };
    RankingEditorVM.sortableOptions = {
        axis: 'y',
        tolerance: 'pointer',
        containment: 'parent',
        handle: '.sort-handle',
        scroll: true,
        distance: 10,
        start: function(e, ui) {
            var choiceCell = ui.item.find('.choice-cell');
            choiceCell.css('width', choiceCell.data('choice-cell-width'));
            $('.tooltip').hide();
        },
        stop: function(e, ui) {
            ui.item.find('.choice-cell').css('width', 'auto');
        },
    };
    RankingEditorVM.maxUserChars = 60;
    RankingEditorVM.maxChars = $rootScope.auth.user.isAdmin ? 256 : RankingEditorVM.maxUserChars;
    RankingEditorVM.showCharCount = showCharCount;
    RankingEditorVM.charCountColor = charCountColor;
    RankingEditorVM.imageOptionsDisabled = imageOptionsDisabled;
    RankingEditorVM.maybeAddAnotherRow = maybeAddAnotherRow;
    RankingEditorVM.imageOptionsMessage = imageOptionsMessage;
    RankingEditorVM.openBulkEditor = openBulkEditor;
    RankingEditorVM.submitBulkEdits = submitBulkEdits;
    RankingEditorVM.chooseFile = chooseFile;

    init();

    function init() {
        // Configure the "choices" sortable.
        // Use start, stop, and mouseover to maintain element
        // width during sorting.
        let initialNumChoices = getMaxChoices();
        while ($scope.question.details.choices.length < initialNumChoices) {
            $scope.question.details.choices.push({
                text: '',
                image: '',
            });
        }

        // Observe element width before dragging removes
        // it from the document flow.
        $(document).on('mouseover', '.sort-handle', function(e) {
            var choiceCell = $(e.target).parent().next('.choice-cell');
            choiceCell.data('choice-cell-width', choiceCell.css('width'));
        });

        /* Add functionality to navigate through response choices via key presses */
        $(document).on('keydown', '.faux-btn input', function(e) {
            e.stopPropagation(); // Stop propagation - don't want to navigate through questions

            if (e.keyCode === 13 || e.keyCode === 40) {
                $(this).parent().parent().parent().next().find('input').focus();
            }
            else if (e.keyCode === 38) {
                $(this).parent().parent().parent().prev().find('input').focus();
            }
        });
    }

    function maybeAddAnotherRow() {
        if ($rootScope.auth.user.isAdmin && !_.some($scope.question.details.choices, function(choice) {
            return !choice.text.length && !choice.image.length;
        })) {
            $scope.question.details.choices.push({
                text: '',
                image: '',
            });
        }
    }

    /**
     *
     */
    function getMaxChoices() {
        return $rootScope.auth.user.isAdmin ? Math.max($scope.question.details.choices.length + 1, MAX_CHOICES) : MAX_CHOICES;
    }

    // Response choice character count
    /**
     * @param chars
     */
    function showCharCount(chars) {
        return (2 * chars) >= RankingEditorVM.maxUserChars;
    }

    /**
     * @param chars
     */
    function charCountColor(chars) {
        var red = Math.min(255, Math.round(255 * chars / RankingEditorVM.maxUserChars, 0)).toString(16);
        red = red.length === 1 ? '0' + red : red;
        return '#' + red + '3333';
    }

    /**
     *
     */
    function imageOptionsDisabled() {
        return false;
    }

    /**
     *
     */
    function imageOptionsMessage() {
        if (imageOptionsDisabled()) {
            return 'This functionality is enabled only for Upwave Pro clients.';
        }
        return '';
    }

    // Open the bulk response editor
    /**
     * @param evt
     */
    function openBulkEditor(evt) {
        _.forEach($scope.question.details.choices, function(choice) {
            if (choice.text) {
                RankingEditorVM.bulkEditor.text += choice.text + '\n';
            }
        });
        RankingEditorVM.bulkEditor.show = true;
        evt.preventDefault();
    }

    // Submit response choices via text area
    function submitBulkEdits() {
        var choices = RankingEditorVM.bulkEditor.text.split('\n'),
            i = 0;

        // For empty choices, add the bulk input text
        _.forEach($scope.question.details.choices, function(choice) {
            choice.text = choices[i++];
        });
        // If there are remaining bulk inputs, push onto choices
        while (i < choices.length) {
            $scope.question.details.choices.push({
                text: choices[i++],
            });
        }
        RankingEditorVM.bulkEditor = {
            show: false,
            text: '',
        };
    }

    /* Pick a file using the filepicker */
    /**
     * @param choice
     */
    function chooseFile(choice) {
        var maxFiles = getMaxChoices();
        _.forEach($scope.question.details.choices, function(c) {
            maxFiles = c.image ? maxFiles - 1 : maxFiles;
        });

        filepicker.pickMultiple({
            services: [
                'COMPUTER',
                'URL',
                'IMAGE_SEARCH',
                'WEBCAM',
                'DROPBOX',
                'BOX',
                'GOOGLE_DRIVE',
            ],
            maxFiles: maxFiles,
        }, function(imageFiles) {
            if (imageFiles.length) {
                var idx = $scope.question.details.choices.indexOf(choice);
                $scope.$apply(function() {
                    _.forEach(imageFiles, function(imageFile) {
                        // Find next available choice with no image
                        while ($scope.question.details.choices[idx].image) {
                            idx = idx < getMaxChoices() - 1 ? idx + 1 : 0;
                        }
                        $scope.question.details.choices[idx].image = imageFile.url;
                        if (!$scope.question.details.choices[idx].exportValue) {
                            $scope.question.details.choices[idx].exportValue = 'Image ' + (idx + 1);
                        }
                    });
                });
            }
        });
    }
}
