import _ from 'lodash';
const list = [{
        code: 'US',
        country: 'United States',
        basic: true,
        extended: true,
        advanced: true,
        regions: [{
            name: 'Alabama',
            id: 'AL',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Alaska',
            id: 'AK',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Arizona',
            id: 'AZ',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Arkansas',
            id: 'AR',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'California',
            id: 'CA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Colorado',
            id: 'CO',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Connecticut',
            id: 'CT',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Delaware',
            id: 'DE',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Dist. of Columbia',
            id: 'DC',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Florida',
            id: 'FL',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Georgia',
            id: 'GA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Hawaii',
            id: 'HI',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Idaho',
            id: 'ID',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Illinois',
            id: 'IL',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Indiana',
            id: 'IN',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Iowa',
            id: 'IA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Kansas',
            id: 'KS',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Kentucky',
            id: 'KY',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Louisiana',
            id: 'LA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Maine',
            id: 'ME',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Maryland',
            id: 'MD',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Massachusetts',
            id: 'MA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Michigan',
            id: 'MI',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Minnesota',
            id: 'MN',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Mississippi',
            id: 'MS',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Missouri',
            id: 'MO',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Montana',
            id: 'MT',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Nebraska',
            id: 'NE',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Nevada',
            id: 'NV',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'New Hampshire',
            id: 'NH',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'New Jersey',
            id: 'NJ',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'New Mexico',
            id: 'NM',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'New York',
            id: 'NY',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'North Carolina',
            id: 'NC',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'North Dakota',
            id: 'ND',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Ohio',
            id: 'OH',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Oklahoma',
            id: 'OK',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Oregon',
            id: 'OR',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Pennsylvania',
            id: 'PA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Rhode Island',
            id: 'RI',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'South Carolina',
            id: 'SC',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'South Dakota',
            id: 'SD',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Tennessee',
            id: 'TN',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Texas',
            id: 'TX',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Utah',
            id: 'UT',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Vermont',
            id: 'VT',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Virginia',
            id: 'VA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Washington',
            id: 'WA',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'West Virginia',
            id: 'WV',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Wisconsin',
            id: 'WI',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Wyoming',
            id: 'WY',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        }],
    }, {
        code: 'CA',
        country: 'Canada',
        basic: false,
        extended: false,
        advanced: false,
        regions: [{
            name: 'Alberta',
            id: 'AB',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'British Columbia',
            id: 'BC',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Manitoba',
            id: 'MB',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Newfoundland',
            id: 'NL',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'New Brunswick',
            id: 'NB',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Nova Scotia',
            id: 'NS',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Ontario',
            id: 'ON',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Pr. Edward Island',
            id: 'PE',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Quebec',
            id: 'QC',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Saskatchewan',
            id: 'SK',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        }],
    }],
    map = {},
    countryMap = {};

_.forEach(list, country => {
    _.forEach(country.regions, e => {
        map[e.id] = e;
        countryMap[e.id] = country.country;
    });
});
const regionOptions = {
    list,
    map,
    countryMap,
};

export default regionOptions;
