import '../common-components/modal-service';

angular.module('confirmDelete.modal', ['modalService'])
    .controller('ConfirmDeleteCtrl', ConfirmDeleteCtrl)
    .directive('confirmDelete', confirmDelete);
confirmDelete.$inject = ['modalService'];
/**
 * @ngdoc directive
 * @name confirmDelete.modal:confirmDelete
 * @restrict 'A'
 * @requires common.dialog:dialog
 * @scope
 * @description
Shows a confirm delete modal
 * @example <button type="button" confirm-delete data-callback="callbackFunction" data-item="itemBeingDeleted">Delete</button>
 * @param modalService
 */
function confirmDelete(modalService) {
    return {
        restrict: 'A',
        scope: {
            callback: '&',
            item: '@',
            title: '@?',
            arg: '=?',
        },
        link: function(scope, elem) {
            elem.bind('click', openModal);
            scope.$on('$destroy', function() {
                elem.off('click');
            });
            function openModal() {
                modalService.openConfirmDeleteModal({
                    item: () => {
                        return scope.item;
                    },
                    callback: () => {
                        return scope.callback();
                    },
                    title: () => {
                        return scope.title;
                    },
                    arg: () => {
                        return scope.arg;
                    },
                });
            }
        },
    };
}
ConfirmDeleteCtrl.$inject = [
    '$uibModalInstance',
    'item',
    'callback',
    'title',
    'arg',
];

/**
 * @param $uibModalInstance
 * @param item
 * @param callback
 * @param title
 * @param arg
 */
function ConfirmDeleteCtrl($uibModalInstance, item, callback, title, arg) {
    var ConfirmDeleteVM = this;
    ConfirmDeleteVM.title = title || 'Delete ' + item + '?';
    ConfirmDeleteVM.item = item;
    ConfirmDeleteVM.close = $uibModalInstance.close;
    ConfirmDeleteVM.confirm = confirm;

    function confirm() {
        callback.apply(this, _.flatten([arg]));
        ConfirmDeleteVM.close();
    }
}
