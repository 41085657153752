import regionOptions from './options';

angular.module('region.editorView', [])
    .directive('regionEditor', regionEditor)
    .controller('RegionEditorCtrl', RegionEditorCtrl);

function regionEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/region-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, RegionEditorVM) {
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('RegionEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);
            updateOptionsObjectFromReqDetailsArray();
            /* Sync details from req details object to local scope */
            function updateOptionsObjectFromReqDetailsArray() {
                _.forEach(RegionEditorVM.options, function(country) {
                    _.forEach(country.regions, function(e) {
                        e.selected = !!scope.requirement.details && (scope.requirement.details.indexOf(e.id) !== -1);
                    });
                });
            }

            /* Sync changes from local scope to req details object */
            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];

                _.forEach(RegionEditorVM.options, function(country) {
                    _.forEach(country.regions, function(e) {
                        if (e.selected) {
                            x.push(e.id);
                        }
                    });
                });
                scope.requirement.details = x;
            }
        },
        controller: 'RegionEditorCtrl',
        controllerAs: 'RegionEditorVM',
    };
}
RegionEditorCtrl.$inject = [
    '$rootScope', '$scope',
];

function RegionEditorCtrl($rootScope, $scope) {
    var RegionEditorVM = this;
    /* If no details are set, create empty array */
    $scope.requirement.details = $scope.requirement.details || [];
    /* Get options info from options.js */
    RegionEditorVM.options = angular.copy(regionOptions.list);
    RegionEditorVM.auth = $rootScope.auth;
    RegionEditorVM.funnelType = $rootScope.funnelType;
    RegionEditorVM.setAll = setAll;
    RegionEditorVM.showRegions = showRegions;

    function setAll(list, val) {
        _.forEach(list, function(e) {
            e.selected = val;
        });
    }

    function showRegions(countryCode) {
        var countries = null;

        _.forEach($rootScope.survey.targetingReqs, function(req) {
            if (req.attribute === 'country' && req.details.type === 'international') {
                countries = req.details.countries;
            }
        });
        return countries !== null ? countries.indexOf(countryCode) !== -1 : countryCode === 'US';
    }
}
