import '@uirouter/angularjs';
import '../../../../common/js/inc/sv-notify';
import '../common-components/sv-dialog';
import '../common-components/pub-sub';
import Paginate from '../common-components/paginate-service';
import './api-beacon-service';
import Config from '../config';

angular.module('beaconCtrl', [
    'common.dialog',
    'pubSub',
    'apiBeaconService',
    'svFilters',
])
    .controller('beaconCtrl', beaconCtrl);
beaconCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    'dialog',
    'pubSubService',
    'beaconService',
    '$notify',
    '$q',
];

/**
 * Beacon controller.
 *
 * @param {object} $scope - The scope for the beacon controller
 * @param {object} $rootScope - Access to application root scope (use caution!)
 * @param {object} $state - Application state
 * @param {object} $stateParams - Application state params
 * @param {object} dialog - Dialog service for triggering popups
 * @param {object} pubSubService - Pub sub service
 * @param {object} beaconService - Service for beacon CRUD
 * @param {object} $notify - Notification service
 * @param {object} $q - Angular promise service
 */
function beaconCtrl($scope, $rootScope, $state, $stateParams, dialog, pubSubService, beaconService, $notify, $q) {
    var currentUserId = $stateParams.userId || $rootScope.auth.user.id;
    $scope.paginatedTags = {
        offset: 0,
        max: 10,
        page: 0,
        hasNext: false,
        hasPrev: false,
    };
    $scope.statsLastUpdated = null;
    $scope.ux.viewName = $state.current.name;
    $scope.openSegmentCreationModal = openSegmentCreationModal;
    $scope.openTagCreationModal = openTagCreationModal;
    $scope.openBeaconPoolEditorModal = openBeaconPoolEditorModal;
    $scope.fetchTags = fetchTags;
    $scope.deleteTag = deleteTag;
    $scope.deleteBeaconPool = deleteBeaconPool;
    $scope.isUsRegion = Config.isUsRegion;

    var DEFAULT_BEACON_TYPE = 'cookie',
        tags;

    init();

    function init() {
        tags = new Paginate({
            func: fetchTags,
        });
        $rootScope.authReady.then(function() {
            refreshBeaconData();
        });
    }

    /**
     * Add tag or tags to beacon list.
     *
     * @param {object} tag - Tag to update
     */
    function updateBeaconList(tag) {
        $scope.tags = $scope.tags || [];
        if (Array.isArray(tag)) {
            $scope.tags = $scope.tags.concat(tag);
        }
        else if (tag) {
            $scope.tags.push(tag);
        }
    }

    /**
     * Opens the segment creation modal.
     *
     * @param {object} [segment] - Segment to edit
     * @param {number} idx - Index
     */
    function openSegmentCreationModal(segment, idx) {
        var deferred = $q.defer();
        dialog.popup({
            templateUrl: 'modal-templates/segment-creation.html',
            controller: 'SegmentCreationModal',
            controllerAs: 'SegmentCreationVM',
            size: 'lg',
            resolve: {
                segment: function() {
                    return segment ? segment : {
                        userId: currentUserId,
                    };
                },
                deferred: function() {
                    return deferred;
                },
            },
        });
        deferred.promise.then(function(response) {
            if (segment) {
                $scope.tags[idx] = response;
            }
            else if (response) {
                updateBeaconList(response);
            }
            refreshBeaconData();
        });
    }

    /**
     * Open the tag creation modal.
     *
     * @param {object} [tag] - Tag to edit
     * @param {number} idx - Index of tag in tag list
     * @param {boolean} generatingCode - Whether we're generating code
     */
    function openTagCreationModal(tag, idx, generatingCode) {
        if (tag && tag.segment) {
            openSegmentCreationModal(tag, idx);
            return;
        }
        var deferred = $q.defer();
        dialog.popup({
            templateUrl: '/account-templates/tag-creation.html',
            controller: 'TagCreationModalCtrl',
            controllerAs: 'TagCreationVM',
            windowClass: 'tag-creation-modal',
            size: 'lg',
            resolve: {
                tag: function() {
                    return tag;
                },
                generatingCode: function() {
                    return generatingCode;
                },
                userId: function() {
                    return currentUserId;
                },
                deferred: function() {
                    return deferred;
                },
            },
        });
        deferred.promise.then(function(response) {
            if (response) {
                updateBeaconList(response);
            }
        });
    }

    /**
     * Open the beacon pool editor modal.
     *
     * @param {object} [beaconPool] - Beacon pool to edit
     */
    function openBeaconPoolEditorModal(beaconPool) {
        let beaconPoolToEdit;
        if (beaconPool) {
            beaconPoolToEdit = angular.copy(beaconPool);
            beaconPoolToEdit.beaconTypeLocked = true;
        }
        else {
            beaconPoolToEdit = {
                name: 'New beacon pool',
                beaconPoolBeacons: [],
                beaconType: DEFAULT_BEACON_TYPE,
                beaconTypeLocked: false,
            };
        }

        dialog.popup({
            templateUrl: 'modal-templates/beacon-pool-editor.html',
            controller: 'BeaconPoolEditorModalCtrl',
            controllerAs: 'BeaconPoolEditorVM',
            resolve: {
                tags: function() {
                    return tags;
                },
                beaconPools: function() {
                    return $scope.beaconPools;
                },
                paginatedTags: function() {
                    return $scope.paginatedTags;
                },
                beaconPoolToEdit: function() {
                    return beaconPoolToEdit;
                },
            },
        });
    }

    /**
     * Fetch the list of tags.
     *
     * @param {object} params - Params for fetching tags
     * @returns {Promise} - Resolved when tags are fetched
     */
    function fetchTags(params) {
        var deferred = $q.defer();
        // eslint-disable-next-line no-warning-comments
        // ToDo: move exportName/param queries away from $scope.paginatedTags
        if ($state.current.name === 'admin.userDetails' || !$scope.paginatedTags.exportName) {
            params.userId = currentUserId;
        }
        beaconService.listTags(params).then(tags => {
            $scope.tags = tags;
            _.forEach($scope.tags, tag => {
                if (!tag.beaconType) {
                    tag.beaconType = DEFAULT_BEACON_TYPE;
                }
                beaconService.getBeaconStats(tag.uuid).then(stats => {
                    $scope.$evalAsync(() => {
                        var lastUpdate = stats.lastUpdated ? Date.parse(stats.lastUpdated) : null;
                        if (lastUpdate && (!$scope.statsLastUpdated || lastUpdate > $scope.statsLastUpdated)) {
                            $scope.statsLastUpdated = lastUpdate;
                        }
                        tag._oneDayCount = stats.oneDayCount;
                        tag._sevenDayCount = stats.sevenDayCount;
                    });
                });
                deferred.resolve(tags);
            });
        }, function(msg) {
            deferred.reject(msg);
        });
        return deferred.promise;
    }

    /**
     * Refresh list of tags and beacon pools.
     */
    function refreshBeaconData() {
        tags.fetch({
            userId: currentUserId,
        }).then(function(tags) {
            $scope.paginatedTags = tags;
        });

        beaconService.listBeaconPools({
            userId: currentUserId,
        }).then(function(beaconPools) {
            $scope.beaconPools = beaconPools;
        });
    }

    /**
     * Delete a tag.
     *
     * @param {object} tag - The tag to be deleted
     */
    function deleteTag(tag) {
        let index = $scope.paginatedTags.data.indexOf(tag);
        beaconService.deleteTag(tag.uuid).then(function(result) {
            tag.deleted = result.deleted;
            if (index !== -1) {
                $scope.paginatedTags.data.splice(index, 1);
            }
        }, function() {
            $notify.error('Tags can not be deleted if they are included in a beacon pool.', {
                position: 'center',
                timeout: 2500,
            });
        });
    }

    /**
     * Delete a beacon pool.
     *
     * @param {object} beaconPool - The beacon pool to delete
     */
    function deleteBeaconPool(beaconPool) {
        beaconService.deleteBeaconPool(beaconPool.uuid).then(function(pool) {
            beaconPool.deleted = pool.deleted;
            let index = $scope.beaconPools.indexOf(beaconPool);
            if (index !== -1) {
                $scope.beaconPools.splice(index, 1);
            }
        },
        function() {
            $notify.error('Beacon pools can not be deleted if they are targeted by a survey.', {
                position: 'center',
                timeout: 2500,
            });
        });
    }
}
