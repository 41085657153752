import ageOptions from './options';

angular.module('age.editorView', [])
    .directive('ageEditor', ageEditor)
    .controller('AgeEditorCtrl', AgeEditorCtrl);

function ageEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/age-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, AgeEditorVM) {
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('AgeEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);
            updateOptionsObjectFromReqDetailsArray();

            function updateOptionsObjectFromReqDetailsArray() {
                _.forEach(AgeEditorVM.options, function(e) {
                    e.selected = !!scope.requirement.details && (scope.requirement.details.indexOf(e.id) !== -1);
                });
            }

            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];
                _.forEach(AgeEditorVM.options, function(e) {
                    if (e.selected) {
                        x.push(e.id);
                    }
                });
                scope.requirement.details = x;
            }
        },
        controller: 'AgeEditorCtrl',
        controllerAs: 'AgeEditorVM',
    };
}
AgeEditorCtrl.$inject = ['$scope'];

function AgeEditorCtrl($scope) {
    var AgeEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    AgeEditorVM.options = angular.copy(ageOptions.list);
    AgeEditorVM.setAll = setAll;

    function setAll(val) {
        _.forEach(AgeEditorVM.options, function(e) {
            e.selected = val;
        });
    }
}
