import '../analysis-config-manager';
import '../analysis-config-data-service';
import '../analysis-factor-service';
import '../analysis-config-service';

angular.module('answerCoding.view', [
    'analysisConfig.manager',
    'analysisFactorService',
    'analysisConfig.service',
])
    .directive('answerCoding', answerCoding)
    .controller('AnswerCodingCtrl', AnswerCodingCtrl);

/**
 * Answer coding directive
 *
 * @returns {object} The directive JSON
 */
function answerCoding() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/answer-coding/answer-coding.html',
        controller: 'AnswerCodingCtrl',
        controllerAs: 'AnswerCodingVM',
    };
}

AnswerCodingCtrl.$inject = [
    '$rootScope',
    'analysisConfigManager',
    'analysisFactorService',
    'analysisConfigService',
];

/**
 * Answer coding controller
 *
 * @param {object} $rootScope
 * @param {object} analysisConfigManager
 * @param {object} analysisFactorService
 * @param {object} analysisConfigService
 */
function AnswerCodingCtrl($rootScope, analysisConfigManager, analysisFactorService, analysisConfigService) {
    const AnswerCodingVM = this;
    const analysisConfig = analysisConfigManager.get();
    AnswerCodingVM.auth = $rootScope.auth;

    init();

    function init() {
        analysisFactorService.initializeQuestionFactors(analysisConfig).then(hasUpdates => {
            if (hasUpdates) {
                analysisConfigService.getFullAnalysisConfiguration(analysisConfig.version).then(updatedConfig => {
                    analysisConfigManager.set(updatedConfig);
                    setReady();
                });
            }
            else {
                setReady();
            }
        }, setReady);
    }

    /**
     * Set the UI to be ready
     */
    function setReady() {
        $rootScope.$evalAsync(() => {
            AnswerCodingVM.ready = true;
        });
    }
}
