angular.module('viewportPubSub', [])
    .factory('ViewportPubSub', ViewportPubSub);
ViewportPubSub.$inject = [
    '$window',
    '$q',
    '$location',
];

/**
 * @param $window
 * @param $q
 * @param $location
 */
function ViewportPubSub($window, $q, $location) {
    var IN_NEXT_SCREEN = 'IN_NEXT_SCREEN',
        PARTIALLY_VISIBLE = 'PARTIALLY_VISIBLE',
        COMPLETELY_VISIBLE = 'COMPLETELY_VISIBLE',
        debouncedList = {},
        isNotPublic = $location.path().indexOf('public') === -1,
        debounced = _.debounce(debouncee, 250),
        idCount = 0,
        numSubscribersRemaining = 0,
        viewPortLimit = 1.3,
        container,
        bound;

    function bindToScroll() {
        if (isNotPublic) {
            container = document.querySelector('.c-content-container.c-content-container__inner');
            if (container) {
                container.addEventListener('scroll', debounced);
            }
        }
        else {
            //Will fix later.
            document.addEventListener('scroll', debounced);
            container = document;
        }
        bound = !!container;
    }

    function unbindFromScroll() {
        if (container) {
            container.removeEventListener('scroll', debounced);
        }
        bound = false;
    }

    function rebind() {
        if (!bound) {
            bindToScroll();
        }
    }

    function debouncee() {
        if (numSubscribersRemaining) {
            _.forEach(debouncedList, function(callback) {
                callback && callback.apply(this, []);
            });
        }
        else {
            unbindFromScroll();
        }
    }

    /**
     *
     */
    function getViewportDimensions() {
        var html = document.documentElement;
        return {
            height: $window.innerHeight || html.clientHeight,
            width: $window.innerWidth || html.clientWidth,
        };
    }
    /**
     * @name isPartiallyInViewport
     * @param {$element} element - The element to check if it is within the viewport bounds
     * @returns {boolean} returns true if element is in viewport bounds
     *
     * @description
     * Given an element, checks to see if the top-left corner of the element is contained
     * by the top-left corner of the visible page, and that the bottom-right corner of the
     * element is contained within 400px of the bottom-right corner of the visible page
     */
    function isPartiallyInViewport(element) {
        var rect = element.getBoundingClientRect(),
            viewport = getViewportDimensions();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= viewport.height * viewPortLimit &&
            rect.right <= viewport.width * viewPortLimit
        );
    }

    /**
     * @param element
     */
    function isInNextViewport(element) {
        var rect = element.getBoundingClientRect(),
            viewport = getViewportDimensions();
        return viewport.height <= rect.top && rect.top <= viewport.height * viewPortLimit;
    }

    /**
     * @param element
     */
    function isInViewport(element) {
        var rect = element.getBoundingClientRect(),
            viewport = getViewportDimensions();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= viewport.height &&
            rect.right <= viewport.width
        );
    }

    /**
     * @param type
     * @param id
     */
    function ViewportPubSub(type, id) {
        var _subscribers = {},
            _unresolvedPromises = {},
            _retriggerLimit = 2,
            _unresolvedCount = 0,
            _maxUnresolved = Number.MAX_SAFE_INTEGER,
            _flush = false;
        id = id || idCount;
        debouncedList[id] = initializeViewportFunction(type);
        idCount++;

        rebind();

        /**
         * @param type
         */
        function initializeViewportFunction(type) {
            switch (type) {
                case IN_NEXT_SCREEN:
                    return triggerCallback(function(element) {
                        return isInViewport(element) || isInNextViewport(element);
                    });
                case COMPLETELY_VISIBLE:
                    return triggerCallback(isInViewport);
                case PARTIALLY_VISIBLE:
                default:
                    return triggerCallback(isPartiallyInViewport);
            }
        }

        /**
         * @name triggerCallback
         * @description
Iterates over an object of subscribers, and triggers the callback method if the isPartiallyInViewport returns
         * true for the viewport
         * @param condition
         */
        function triggerCallback(condition) {
            return function() {
                _.forEach(_subscribers, function(subscriber, subscriberId) {
                    if (!subscriber) {
                        return;
                    }
                    if (!subscriber.element) {
                        subscriber.element = document.getElementById(subscriberId);
                    }
                    if (!subscriber.called && subscriber.element && condition(subscriber.element) && subscriber.callback) {
                        incrementUnresolvedCount(subscriberId);
                        subscriber.called = true;
                        subscriber.callback.apply(this, subscriber.args);
                    }
                });
            };
        }
        /**
         * @name subscribe
         * @param {string} elementId - The element id of element waiting to be in viewport
         * @param {Function} callback - The function that is triggered by inViewport
         * @param elementOrId
         * @param callImmediately
         * @param {object[]} [args] - Optional arguments to be applied to the callback
         * @description
Subscribes the element to inViewport and triggers inViewport.
         */
        function subscribe(elementOrId, callback, args, callImmediately) {
            rebind();
            args = Array.isArray(args) ? args : [args];
            var elementId = typeof elementOrId === 'string' ? elementOrId : elementOrId.id,
                element = typeof elementOrId === 'string' ? document.getElementById(elementId) : elementOrId,
                deferred = _subscribers[elementId] ? _subscribers[elementId].deferred : $q.defer(),
                subscriber = {
                    callback: callback,
                    args: args || [],
                    deferred: deferred,
                    element: element,
                    renderNow: function() {
                        callback.apply(this, args);
                    },
                };
            _subscribers[elementId] = subscriber;
            if (callImmediately) {
                incrementUnresolvedCount(elementId);
                subscriber.called = true;
                callback.apply(this, args);
            }
            else {
                debouncedList[id](element);
            }
            numSubscribersRemaining++;

            return deferred.promise;
        }
        /**
         * @name desubscribe
         * @param {string} subscriberId - The element id of element waiting to be in viewport
         *
         * @description
         * Removes the subscriber with the specified id from inViewport's subscribers
         * object.
         */
        function desubscribe(subscriberId) {
            resolvePromise(subscriberId);
            delete _subscribers[subscriberId];
            numSubscribersRemaining--;
        }

        function destroy() {
            delete debouncedList[id];
        }

        /**
         * @name resolvePromise
         * @param subscriberId
         * @param {string} id - The element id
         * @description
This, along with incrementUnresolvedCount, keeps track of the number of resolved promises.
         */
        function resolvePromise(subscriberId) {
            if (_unresolvedPromises[subscriberId]) {
                _unresolvedPromises[subscriberId].resolve();
                delete _unresolvedPromises[subscriberId];
                _unresolvedCount--;
            }
        }

        /**
         * @param subscriberId
         */
        function incrementUnresolvedCount(subscriberId) {
            _unresolvedCount++;
            _unresolvedPromises[subscriberId] = _subscribers[subscriberId].deferred;
        }

        /**
         * @name loadNext
         * @param subscriberId
         * @param {string} id - The element id of element waiting to be in viewport
         * @description
Indicate that the subscriber with id has been called, and checks to make sure
         * if _loadNext is true, then two more subscriber that haven't been called yet
         * are triggered.
         */
        function loadNext(subscriberId) {
            rebind();
            desubscribe(subscriberId);
            if (_unresolvedCount < 1) {
                _flush = false;
            }
            if (_unresolvedCount < _maxUnresolved && !_flush) {
                var retriggerCount = 0,
                    subscriberKeys = _.keys(_subscribers),
                    subscriber;
                for (var i = 0; i < subscriberKeys.length; i++) {
                    let nextSubscriberId = subscriberKeys[i];
                    subscriber = _subscribers[nextSubscriberId];
                    if (!subscriber || !subscriber.callback) {
                        continue;
                    }
                    if (!subscriber.called) {
                        incrementUnresolvedCount(nextSubscriberId);
                        subscriber.callback.apply(this, [subscriber]);
                        subscriber.called = true;
                        retriggerCount++;
                    }
                    if (retriggerCount >= _retriggerLimit) {
                        return;
                    }
                }
            }
            else if (_unresolvedCount >= _maxUnresolved - 1) {
                _flush = true;
            }
        }

        /**
         * @name setMaxUnresolved
         * @param {number} loadLimit - The number of calls to wait until triggering more subscribers
         * @param maxUnresolved
         * @param {number} retriggerLimit - The number of subscribers to retrigger when the loadLimit is hit
         * @description
See params
         */
        function setMaxUnresolved(maxUnresolved) {
            _maxUnresolved = maxUnresolved || _maxUnresolved;
        }

        /**
         * @param subscriberId
         */
        function getPromise(subscriberId) {
            if (!_subscribers[subscriberId]) {
                _subscribers[subscriberId] = {
                    deferred: $q.defer(),
                };
            }
            return _subscribers[subscriberId].deferred.promise;
        }

        /**
         * @param subscriberId
         */
        function renderNow(subscriberId) {
            let subscriber = _subscribers[subscriberId];
            if (!subscriber || (!subscriber.called && subscriber.element)) {
                return;
            }
            _subscribers[subscriberId].renderNow();
            return true;
        }

        /**
         * @param recall
         */
        function flushAll(recall) {
            const promises = [];
            _.forEach(_subscribers, (subscriber, subscriberId) => {
                if (subscriber) {
                    if (!subscriber.element) {
                        subscriber.element = document.getElementById(subscriberId);
                    }
                    if ((!subscriber.called || recall) && subscriber.element && subscriber.callback) {
                        promises.push(getPromise(subscriberId));
                        incrementUnresolvedCount(subscriberId);
                        subscriber.callback.apply(this, subscriber.args);
                        subscriber.called = true;
                    }
                }
            });
            return promises;
        }

        return {
            subscribe: subscribe,
            desubscribe: desubscribe,
            loadNext: loadNext,
            setMaxUnresolved: setMaxUnresolved,
            destroy: destroy,
            resolvePromise: resolvePromise,
            getPromise: getPromise,
            renderNow: renderNow,
            flushAll: flushAll,
        };
    }

    ViewportPubSub.IN_NEXT_SCREEN = IN_NEXT_SCREEN;
    ViewportPubSub.PARTIALLY_VISIBLE = PARTIALLY_VISIBLE;
    ViewportPubSub.COMPLETELY_VISIBLE = COMPLETELY_VISIBLE;
    ViewportPubSub.isPartiallyInViewport = isPartiallyInViewport;

    return ViewportPubSub;
}
