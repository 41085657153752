import svOffline from '../../../../common/js/sv-offline';

export default angular.module('svOfflineService', [])
    .service('svOfflineService', svOfflineService);
svOfflineService.$inject = [
    '$window',
    '$timeout',
    '$notify',
];

/**
 * Defines the sv offline service.
 *
 * @param {*} $window
 * @param {*} $timeout
 * @param {*} $notify
 * @returns {*}
 */
function svOfflineService($window, $timeout, $notify) {
    const dashboardHost = $window.config.dashboardHost;
    const LARGE_TIMEOUT = 99999999;
    // DEVNOTE: Hand over to Samuel to create an elegant way of displaying
    // disconnected status to the user
    const CLEAR_NOTIFICATIONS_TIME = 500;

    function init() {
        var ux = {
            isOnline: true,
        };
        svOffline.config({
            interval: 5000,
            retry: true,
            url: `${dashboardHost}/rest/ping`,
            alwaysOn: true,
        }).onDisconnected(() => {
            if (ux.isOnline) {
                $timeout(() => {
                    ux.isOnline = false;
                    $notify.warning('We&rsquo;re sorry. There seems to be a connection issue. Please check your internet connection.', {
                        clear: true,
                        timeout: LARGE_TIMEOUT,
                    });
                }, CLEAR_NOTIFICATIONS_TIME);
            }
        }).onConnected(() => {
            if (!ux.isOnline) {
                $timeout(() => {
                    ux.isOnline = true;
                    $notify.success('Successfully reconnected.', {
                        clear: true,
                    });
                }, CLEAR_NOTIFICATIONS_TIME);
            }
        }).start();

        if ($window.onbeforeunload) {
            // Kill workers so we don't have multiples running for a user
            $window.onbeforeunload = () => {
                svOffline.terminate();
            };
        }
    }
    return {
        init: init,
    };
}
