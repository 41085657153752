/* eslint compat/compat: "off" */
import _ from 'lodash';
import U from '../../../../../common/js/util';
import filterLogicService from '../../filter-logic/filter-logic-service';
import analysisConfigConstants from './analysis-config-constants';
import AnalysisConfigModel from './analysis-config-model';
import analysisConfigDataService from './analysis-config-data-service';
import roundedDaysSinceLastExposureCuts from './cuts/types/rounded-days-since-last-exposure';
import './analysis-factor-service';

angular.module('analysisConfig.service', [
    'analysisFactorService',
])
    .service('analysisConfigService', analysisConfigService);
analysisConfigService.$inject = [
    '$rootScope', 'analysisFactorService',
];

/**
 * Service for analysis configuration
 *
 * @param {object} $rootScope
 * @param {object} analysisFactorService
 * @returns {object} service functions
 */
function analysisConfigService($rootScope, analysisFactorService) {
    const DEFAULT_INDEX = 0,
        defaultCutNameMap = _.reduce(roundedDaysSinceLastExposureCuts.options, (map, cut) => {
            map[cut.value] = cut.name;
            return map;
        }, {});

    let globalCuts;

    var nonNullableInputParams = [
            'columnExportFname', 'weightingSchemeFname',
        ],
        nonNullableSurveyParams = [
            'weeksInTimeBin',
            'maxDaysSinceExposure',
            'surveyId',
        ],
        formTypeToListMap = {
            inputParams: nonNullableInputParams,
            surveyParams: nonNullableSurveyParams,
        },
        filterModel = new AnalysisConfigModel($rootScope.survey);

    /**
     * Validate the form
     *
     * @param {string} formType
     * @param {object} form
     * @returns {boolean} whether the form is validated
     */
    function validateForm(formType, form) {
        let errors = {};
        _.forEach(formTypeToListMap[formType], paramName => {
            if (!_.isDefined(form[paramName])) {
                errors[paramName] = paramName + ' is required';
            }
        });
        return !_.isEmpty(errors) && errors;
    }

    /**
     * Get the factor type from question
     *
     * @param {object} question
     * @returns {object}
     */
    function getFactorTypeFromQuestion(question) {
        return _.find(analysisConfigConstants.analysisFactorTypes, type => {
            return type.type === question.type || type.type === question.gridType + 'Select';
        });
    }

    /**
     * Convert an object to snake case
     *
     * @param {object} object
     * @param {boolean} keyShouldNotBeSnakeCase
     * @returns {object} the object with the keys converted to snake case
     */
    function convertObjectKeysToSnakecase(object, keyShouldNotBeSnakeCase) {
        let isArray = Array.isArray(object),
            keysToIgnore = analysisConfigConstants.keysToIgnore,
            converted = isArray ? [] : {};
        if (!_.isObject(object)) {
            return object;
        }
        _.forEach(object, (value, key) => {
            let convertedKey = key;
            if (keysToIgnore[key] || key[0] === '_') {
                return;
            }
            if (!keyShouldNotBeSnakeCase) {
                convertedKey = _.snakeCase(key);
                if (convertedKey[0] !== key[0]) {
                    convertedKey = key[0] + convertedKey;
                }
            }
            if (key === 'exposed' || key === 'control') {
                value = JSON.parse(JSON.stringify(value).replace(/Demographic\$/g, ''));
            }

            if (isArray) {
                converted[convertedKey] = convertObjectKeysToSnakecase(value);
            }
            else if (Array.isArray(value)) {
                converted[convertedKey] = _.map(value, val => {
                    return convertObjectKeysToSnakecase(val);
                });
            }
            else if (key === 'name') {
                converted[convertedKey] = U.toUnicode(value);
            }
            else {
                let convertedValue = value;
                if (angular.isObject(value) && !_.isUndefined(value.value)) {
                    convertedValue = value.value;
                }
                else if (angular.isObject(value)) {
                    convertedValue = convertObjectKeysToSnakecase(value, key === 'cuts');
                }
                converted[convertedKey] = convertedValue;
            }
        });
        return converted;
    }

    /**
     * Get the default survey params
     *
     * @returns {object}
     */
    function getDefaultSurveyParams() {
        return {
            maxDaysSinceExposure: analysisConfigConstants.DEFAULT_MAX_DAYS_SINCE_EXPOSURE,
            weeksInTimeBin: analysisConfigConstants.DEFAULT_WEEKS_IN_TIME_BIN,
            clientName: $rootScope.survey.user.company || ($rootScope.survey.user.firstName + $rootScope.survey.user.lastName),
            questionOffsetFromSchema: 1,
            studyName: $rootScope.survey.study.name || $rootScope.survey.name,
            subfolder: '',
            version: 1,
            maxRowsToWeight: null,
        };
    }

    /**
     * Get the default weighting params
     *
     * @returns {object}
     */
    function getDefaultWeightingParams() {
        return {
            balanceCutoff: 0.1,
            complexityLimit: analysisConfigConstants.DEFAULT_COMPLEXITY_LIMIT,
            degeneracyErrorImportance: true,
            includeInteractions: true,
            liftCalculationMethod: analysisConfigConstants.liftCalculationMethods[DEFAULT_INDEX],
            lowPriorityFactors: [],
            mandatoryFactors: [
                'Age',
                'Gender',
                'Operating System',
            ],
            maxSteps: analysisConfigConstants.DEFAULT_MAX_STEPS,
            minControlSample: analysisConfigConstants.DEFAULT_SAMPLE,
            minCount: analysisConfigConstants.DFAULT_MIN_COUNT,
            minExposedSample: analysisConfigConstants.DEFAULT_SAMPLE,
            nonOverlapDrop: true,
            notabilityCutoff: analysisConfigConstants.DEFAULT_NOTABILITY_CUTOFF,
            removeTrimmedFromParticipation: true,
            renormalizationMethod: 'NONE',
            selectionCriterion: analysisConfigConstants.selectionCriterions[DEFAULT_INDEX],
            selectionMethod: analysisConfigConstants.weightingSelectionMethods[DEFAULT_INDEX],
            shouldUsePublisherWeights: false,
            shouldWeightUsingStratification: true,
            significanceLevels: [],
            tmax: analysisConfigConstants.DEFAULT_TMAX,
            treatmentEffectMethod: analysisConfigConstants.treatmentEffectMethods[DEFAULT_INDEX],
            trim: analysisConfigConstants.DEFAULT_TRIM,
            useOnlyMarginallySignificantRegressors: false,
        };
    }

    /**
     * Get the default output params
     *
     * @returns {object}
     */
    function getDefaultOutputParams() {
        return {
            akqaFormatting: false,
            maxTabNameLength: analysisConfigConstants.DEFAULT_MAX_TAB_NAME_LENGTH,
            outputToMultipleSheets: false,
            shouldExportConfounderBalanceTable: false,
            shouldExportLiftTable: true,
            shouldExportMainEffectsTable: true,
            shouldExportWeights: true,
            tableFormat: analysisConfigConstants.tableFormats[DEFAULT_INDEX],
        };
    }

    /**
     * Get the default input params
     *
     * @returns {object}
     */
    function getDefaultInputParams() {
        return {
            candidateCutPlanFname: null,
            columnExportFname: '',
            columnExportFolder: null,
            conformedDataframeFname: null,
            humanSheetname: '',
            machineSheetname: '',
            requireAllColumnsPresentForCuts: true,
            schemaExportFname: '',
            studyConfigFname: null,
            studyFolder: null,
            weightingSchemeFname: '',
            weightingSchemeFolder: null,
        };
    }

    /**
     * Get the default audience profile params
     *
     * @returns {object}
     */
    function getDefaultAudienceProfileParams() {
        return {
            cutComparisons: [],
            evaluateInCut: true,
            evaluateReach: true,
            includeControlComparison: false,
            includeExposedComparison: true,
            overallCut: analysisConfigConstants.controlTypes[DEFAULT_INDEX],
            testLevels: [
                0.001,
                0.01,
                0.05,
                0.1,
            ],
        };
    }

    /**
     * Get the default human cutter
     *
     * @returns {object}
     */
    function getDefaultHumanCutter() {
        return {
            controlIndicator: analysisConfigConstants.indicatorTypes[DEFAULT_INDEX],
            cuts: {},
            excludedStandardDemographicCuts: [],
            includeOverall: true,
            includeStandardDemographicCuts: true,
            minSample: analysisConfigConstants.DEFAULT_SAMPLE,
        };
    }

    /**
     * Get default cut factor by factor uuid
     *
     * @param {string} factorUuid
     * @returns {object}
     */
    function getDefaultCutFactor(factorUuid) {
        return {
            analysisFactorUuid: factorUuid,
            inclusion: analysisConfigConstants.inclusions[DEFAULT_INDEX],
        };
    }

    /**
     * Get top two box from question and factor type
     *
     * @param {object} question
     * @param {string} factorType
     * @returns {object}
     */
    function getTopTwoBox(question, factorType) {
        return {
            factorType: getFactorTypeFromQuestion(question),
            analysisUsage: analysisConfigConstants.analysisUsages[DEFAULT_INDEX],
            levels: [{
                isComplement: false,
                isKpi: factorType === analysisConfigConstants.KPI,
                levelType: angular.copy(analysisConfigConstants.factorLevelTypes[DEFAULT_INDEX]),
                name: 'Top Two Box: (' + question.name + ')',
                rebaseValue: 0,
                testType: angular.copy(analysisConfigConstants.testTypes[DEFAULT_INDEX]),
            }],
            name: question.name,
            isCustomLevel: true,
            questionId: question.id,
        };
    }

    /**
     * Get an empty factor from question and factor type
     *
     * @param {object} question
     * @param {string} factorType
     * @returns {object}
     */
    function getEmptyFactor(question, factorType) {
        return {
            factorType: getFactorTypeFromQuestion(question),
            levels: [],
            name: (question.gridType ? question.gridStatement + ' (' + question.name + ')' : question.name) || '',
            analysisUsage: analysisConfigConstants.analysisUsages[DEFAULT_INDEX],
            disabled: question.disabled,
            _question: question,
            _factorType: factorType,
        };
    }

    /**
     * Get the empty config object by uuid
     *
     * @param {string} analysisConfigUuid
     * @returns {object}
     */
    function getEmptyConfig(analysisConfigUuid) {
        let defaultQuestionsToLevelMap = analysisFactorService.getDefaultQuestionFactorToChoiceLevel(filterModel.Question$Choice && filterModel.Question$Choice.options);
        return {
            uuid: analysisConfigUuid,
            defaultQuestionsToLevelMap: defaultQuestionsToLevelMap,
            confounder: {
                demographic: [],
                temporal: [],
            },
            marketResearch: [],
            globalCuts: {},
            cutFactors: [],
            specificCuts: [],
            specificFactors: [],
            customFactors: [],
            questionFactors: analysisFactorService.getDefaultQuestionFactorsList(true, defaultQuestionsToLevelMap, {}),
            traitFactors: [],
        };
    }

    /**
     * Given choice map and cut name, return the question choice.
     *
     * @param {object} choiceMap - Maps question statement string to question choices
     * @param {string} cutName
     * @returns {object} question choice object
     */
    function getQuestionChoiceFromCutName(choiceMap, cutName) {
        let cutRegex,
            selected;

        try {
            cutRegex = new RegExp(cutName.replace(/\$/g, '\\$').replace(/\./g, '\\.').replace(/\+/g, '\\+'));
        }
        catch (err) {
            console.log('Warning! Invalid string for RegEx. ' + err);
        }

        _.forEach(choiceMap, question => {
            selected = _.find(question.levels, choice => {
                return cutRegex && cutRegex.test(choice.name) || choice.name === cutName || choice.id === cutName;
            });
            if (selected) {
                return false;
            }
        });

        return selected;
    }

    /**
     * Get full analysis config by uuid
     *
     * @param {string} analysisConfigUuid
     * @returns {object}
     */
    function getFullAnalysisConfiguration(analysisConfigUuid) {
        const configuration = getEmptyConfig(analysisConfigUuid),
            cutFactors = [],
            uuidToFactor = {},
            uuidToCuts = {};
        let listAnalysisCuts,
            getAnalysisFactors;

        listAnalysisCuts = new Promise(resolve => {
            analysisConfigDataService.listAnalysisCuts(analysisConfigUuid).then(cuts => {
                if (!cuts.length) {
                    resolve();
                    return;
                }

                cuts.forEach(cut => {
                    //Not pre-setting fields in object because want to preserve ordering in json output.
                    const analysisCut = {};
                    let choice;
                    analysisCut.exposed = typeof cut.exposedFormulaJson === 'string' ? JSON.parse(JSON.parse(cut.exposedFormulaJson)) : cut.exposedFormulaJson;
                    analysisCut.categoryLabel = filterLogicService.filterJsonToCategory(analysisCut.exposed, cut.name);
                    choice = analysisCut.categoryLabel === 'Question$Choice' && getQuestionChoiceFromCutName(configuration.defaultQuestionsToLevelMap, cut.name);

                    analysisCut.name = (choice || cut).name;
                    analysisCut._displayName = defaultCutNameMap[cut.name] || cut.name.replace(/^\d+\. /, '');
                    analysisCut.type = _.find(analysisConfigConstants.cutControlTypes, {
                        value: cut.controlType === 'overall' ? 'all' : cut.controlType,
                    });
                    analysisCut.uuid = cut.uuid;
                    analysisCut.analysisCutCategory = cut.analysisCutCategory;
                    uuidToCuts[cut.uuid] = cut;
                    configuration.globalCuts[analysisCut.name] = analysisCut;
                    if (analysisCut.type.value === 'custom') {
                        analysisCut.control = cut.controlFormulaJson;
                    }
                });
                globalCuts = configuration.globalCuts;
                resolve();
            }, resolve);
        });

        getAnalysisFactors = new Promise(resolve => {
            analysisFactorService.getAnalysisFactors(analysisConfigUuid, configuration.defaultQuestionsToLevelMap).then(response => {
                configuration.customFactors = response.customFactors;
                configuration.questionFactors = response.questionFactors;
                configuration.kpi = response.kpiFactors;
                configuration.marketResearch = response.marketResearch;
                configuration.confounder.behavioral = response.confounders;
                configuration.traitFactors = response.traitFactors;
                configuration.targetAudience = response.targetAudience;
                resolve();
            }, resolve);
        });

        return Promise.all([listAnalysisCuts, getAnalysisFactors]).then(() => {
            configuration.specificFactors = _.values(uuidToFactor);
            configuration.cutFactors = _.map(cutFactors, cutFactor => {
                configuration.specificCuts.push(uuidToCuts[cutFactor.analysisCut]);
                return {
                    uuid: cutFactor.uuid,
                    cut: uuidToCuts[cutFactor.analysisCut],
                    factor: uuidToFactor[cutFactor.analaysisFactor],
                    inclusion: _.find(analysisConfigConstants.inclusions, inclusion => {
                        return inclusion.value === cutFactor.inclusion;
                    }),
                };
            });
            return configuration;
        }, () => {
            return configuration;
        });
    }

    /**
     * Get impression count columns
     *
     * @returns {object[]}
     */
    function getImpressionCountColumns() {
        return _.map($rootScope.survey._statsConfig && $rootScope.survey._statsConfig.impressionCountColumns, positions => {
            return {
                start: positions.firstColumnNum,
                end: _.reduce(positions.choiceColumns, (max, value) => {
                    return Math.max(max, value);
                }, 0),
            };
        });
    }

    /**
     * Correct the order of cuts. Just days since exposure for now
     *
     * @param {object[]} cuts
     */
    function orderCuts(cuts) {
        cuts.DaysSinceLastExposure = _.map(roundedDaysSinceLastExposureCuts.options, cut => {
            return globalCuts[cut.name] || cut;
        });
    }

    return {
        validateForm: validateForm,
        convertObjectKeysToSnakecase,
        filterModel: filterModel,
        getEmptyFactor: getEmptyFactor,
        getTopTwoBox: getTopTwoBox,
        //Defaults
        getDefaultWeightingParams: getDefaultWeightingParams,
        getDefaultOutputParams: getDefaultOutputParams,
        getDefaultInputParams: getDefaultInputParams,
        getDefaultSurveyParams: getDefaultSurveyParams,
        getDefaultAudienceProfileParams: getDefaultAudienceProfileParams,
        getDefaultHumanCutter: getDefaultHumanCutter,
        getDefaultCutFactor: getDefaultCutFactor,
        getFullAnalysisConfiguration: getFullAnalysisConfiguration,
        getImpressionCountColumns: getImpressionCountColumns,
        //Process functions
        getEmptyConfig: getEmptyConfig,
        orderCuts: orderCuts,
    };
}
