import '../common-components/pub-sub';
import '../survey/survey-validation-service';
import '../survey/api-survey-service';
import '../survey-creation/survey-creation-service';
import '../targeting-attributes/targeting-attribute-groups';

angular.module('targetingEditor.view', [
    'pubSub',
    'surveyValidation.service',
    'apiSurveyService',
    'surveyCreation.service',
    'targetingAttributeGroups.service',
])
    .controller('TargetingEditorCtrl', TargetingEditorCtrl)
    .directive('targetingEditor', targetingEditor);

function targetingEditor() {
    return {
        templateUrl: 'survey-creation-templates/target/targeting-editor.html',
        restrict: 'E',
        scope: {
            activeRequirement: '=',
        },
        controller: 'TargetingEditorCtrl',
        controllerAs: 'TargetingEditorVM',
    };
}
TargetingEditorCtrl.$inject = [
    '$rootScope',
    '$scope',
    'pubSubService',
    'targetingAttributeGroups',
    'surveyValidationService',
    'surveyService',
    'surveyCreationService',
];

function TargetingEditorCtrl($rootScope, $scope, pubSubService, TAGS, SVS, surveyService, surveyCreationService) {
    var TargetingEditorVM = this;
    TargetingEditorVM.attributeTypes = TAGS.attributes;
    TargetingEditorVM.ux = {};
    TargetingEditorVM.save = save;
    TargetingEditorVM.errors = errors;
    TargetingEditorVM.unselectActive = unselectActive;

    init();

    function init() {
        pubSubService.subscribe('set-check-errors', $scope.$id, setCheckErrors);
        pubSubService.flushTopic('set-check-errors');
        $scope.$on('$destroy', function() {
            pubSubService.destroy('set-check-errors', $scope.$id);
        });
    }

    function unselectActive() {
        $scope.activeRequirement = null;
    }

    function setCheckErrors(bool) {
        TargetingEditorVM.ux.checkErrors = bool;
    }
    // Update: save the active requirement.
    function save() {
        var active = $scope.activeRequirement;
        setCheckErrors(true);

        // This is a standard targeting requirement
        if (active.attribute) {
            if (SVS.targetingReqErrors(active).length) {
                return;
            }

            var old = surveyCreationService.getReq(active.attribute);
            if (old) {
                _.forEach(active, function(e, i) {
                    old[i] = angular.copy(e);
                });
                _.forEach(old, function(e, i) {
                    if (!active[i]) {
                        delete old[i];
                    }
                });
            }
            else {
                $rootScope.survey.targetingReqs = $rootScope.survey.targetingReqs || [];
                $rootScope.survey.targetingReqs.push(active);
            }
            pubSubService.notify('refresh-targeting-reqs', []);
            surveyService.updateAudience($rootScope.survey._audienceId, {
                targetingReqs: [active],
            });
        }
        // This is a screening question
        else if (active.type) {
            TargetingEditorVM.ux.checkQuestionErrors = true;

            if (SVS.questionErrors(active).length) {
                return;
            }

            if (active.uuid) {
                surveyService.updateQuestion(active.uuid, active, $rootScope.creativeSetNames).then(function(response) {
                    var idx = surveyCreationService.getScreenerIdx(active.uuid);
                    if (idx !== -1) {
                        $rootScope.survey.screeningQuestions[idx] = angular.copy(response);
                        TargetingEditorVM.requirementsList = TargetingEditorVM.refreshTargetingReqs();
                    }
                });
            }
            else {
                surveyService.createScreeningQuestion($rootScope.survey._audienceId, active).then(function(response) {
                    $rootScope.survey.screeningQuestions = $rootScope.survey.screeningQuestions || [];
                    $rootScope.survey.screeningQuestions.push(response);
                    TargetingEditorVM.requirementsList = TargetingEditorVM.refreshTargetingReqs();
                });
            }
        }
        $scope.activeRequirement = void 0;
    }

    // Errors in a requirement
    function errors(r) {
        if (!r) {
            return;
        }
        if (r.attribute) {
            return SVS.targetingReqErrors(r);
        }
        else if (r.type) {
            return SVS.questionErrors(r);
        }
    }
}
