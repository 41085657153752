import '../../../../../../common/js/inc/sv-notify';
import '../../../common-components/pub-sub';
import '../../../common-components/checkbox-indicator';
import '../analysis-plan.service';
import impressionParameterCutsConstants from './impression-parameter-cuts-constants';

angular
    .module('impressionParameterCuts.view', [
        'svNotify',
        'checkboxIndicator',
        'pubSub',
        'analysisPlan.service',
    ])
    .directive('impressionParameterCuts', impressionParameterCuts)
    .controller('ImpressionParameterCutsCtrl', ImpressionParameterCutsCtrl);

function impressionParameterCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/impression-parameter-cuts.html',
        controller: 'ImpressionParameterCutsCtrl',
        controllerAs: 'ImpressionParameterCutsVM',
    };
}

ImpressionParameterCutsCtrl.$inject = [
    '$scope',
    '$notify',
    'pubSubService',
    'analysisPlanService',
];

function ImpressionParameterCutsCtrl($scope, $notify, pubSubService, analysisPlanService) {
    const ImpressionParameterCutsVM = this;

    // VM exposed variables
    ImpressionParameterCutsVM.impressionParameterCuts = analysisPlanService.getImpressionParameterCuts();
    ImpressionParameterCutsVM.tableHeaders = impressionParameterCutsConstants.tableHeaders;
    ImpressionParameterCutsVM.tableOptions = impressionParameterCutsConstants.tableOptions;
    ImpressionParameterCutsVM.paginationOptions = {
        itemsPerPage: 12,
    };
    ImpressionParameterCutsVM.ux = {
        loading: false,
        categorySelectionTypes: analysisPlanService.categorySelectionTypes,
    };

    // VM exposed functions
    ImpressionParameterCutsVM.save = save;

    init();

    function init() {
        analysisPlanService.getCutCategoryMap().then(cutCategoryMap => {
            ImpressionParameterCutsVM.tableHeaders[0]._selected = analysisPlanService.initializeViewForCuts(ImpressionParameterCutsVM.impressionParameterCuts.options, cutCategoryMap);
        });
        pubSubService.subscribe('on-impression-cut-changed', $scope.$id, updateCutList);
        $scope.$on('$destroy', () => {
            pubSubService.destroy('on-impression-cut-changed', $scope.$id);
        });
    }

    function updateCutList(category) {
        category.cutList = analysisPlanService.getCutListForCategory(category);
    }

    function save() {
        ImpressionParameterCutsVM.ux.loading = true;
        analysisPlanService.saveMultipleCutsCategories(ImpressionParameterCutsVM.impressionParameterCuts.options).then(onSave, onSaveFailed);
    }

    function onSave() {
        $scope.$evalAsync(() => {
            ImpressionParameterCutsVM.ux.loading = false;
        });
    }

    function onSaveFailed() {
        $notify.error('An error occurred saving IPG cuts');
        onSave();
    }
}
