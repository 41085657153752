const metroCuts = {
    category: 'Metro Area',
    categoryType: 'demographic',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    controlType: 'Equivalent',
    // Metro area options are collected from the database and not a constant. collected here: analysis-plan-service.js buildMetroCuts()
    options: [],
};

export default metroCuts;
