import * as d3 from 'd3-selection';
import myChartUtils from './charts-utils';

angular.module('charts.angledAxisLabels', [])
    .factory('angledAxisLabels', angledAxisLabels);

function angledAxisLabels() {
    function angledAxisLabels() {
        var _chart,
            _plot,
            _group,
            _axisLabels,
            _id = '_angledAxisLabels__' + (Math.random() * 1e3); // Needed to generate random-enough id values;

        angledAxisLabels.init = init;
        angledAxisLabels.plot = plot;
        angledAxisLabels.group = group;
        angledAxisLabels.chart = chart;
        angledAxisLabels.destroy = destroy;

        function init() {
            _chart.addEventListener(_chart.EVENTS.ANCHOR_COMPLETE, handleChartAnchorComplete, _id, true);
            _chart.addEventListener(_chart.EVENTS.RESIZE_COMPLETE, handleChartResizeComplete, _id);
            _chart.addEventListener(_chart.EVENTS.FLIP_AXIS_COMPLETE, handleChartFlipAxisComplete, _id);
            return angledAxisLabels;
        }

        function destroy() {
            _chart.removeEventListener(_chart.EVENTS.Y_AXIS_ANCHOR_COMPLETE, _id);
            _chart.removeEventListener(_chart.EVENTS.X_AXIS_ANCHOR_COMPLETE, _id);
            _chart.removeEventListener(_chart.EVENTS.RESIZE_COMPLETE, _id);
        }

        function plot(val) {
            if (!arguments.length) {
                return _plot;
            }
            _plot = val;

            return angledAxisLabels;
        }

        function group(val) {
            if (!arguments.length) {
                return _group;
            }
            _group = val;

            return angledAxisLabels;
        }

        function chart(val) {
            if (!arguments.length) {
                return _chart;
            }
            _chart = val;

            return angledAxisLabels;
        }

        function handleChartAnchorComplete() {
            angledAxisLabels();
        }

        function handleChartResizeComplete() {
            angledAxisLabels();
        }

        function handleChartFlipAxisComplete() {
            angledAxisLabels();
        }

        function angledAxisLabels() {
            // Only deal with vertical charts for now
            if (!isPlotVertical()) {
                return;
            }

            // Caching selection
            var axisSelection = getPlotCategoricAxisSelection();
            const ANGLED_LABEL_BREATHING_ROOM = 5;

            _axisLabels = axisSelection.selectAll('.tick-label');

            if (!shouldLabelsBeAngled()) {
                return;
            }

            if (angleLabel()) {
                // Rotate labels from -90° to 45°
                _axisLabels.each(function() {
                    var axisLabel = d3.select(this),
                        currentTransform = myChartUtils.getTokenizedTransformValue(axisLabel.attr('transform')),
                        bbox = axisLabel.node().getBBox(),
                        rotationXAdjustment = bbox.x + bbox.width / 2.0,
                        yTranslation = currentTransform.translate[1] || 0, // When no y translation ensure we a number value AKA zero
                        rotationYAdjustment = parseFloat(yTranslation) + (Math.exp(Math.PI / 4) + Math.exp(-Math.PI / 4)) / 2 * ANGLED_LABEL_BREATHING_ROOM,
                        updatedTransform = [];

                    for (var type in currentTransform) {
                        if (type === 'rotate') {
                            continue;
                        } // Skip rotate if it is present

                        updatedTransform.push(type + '(' + currentTransform[type].join(',') + ')');
                    }

                    // Update transform attribute
                    axisLabel.attr('transform', updatedTransform.join(' ') + ' rotate(45, ' + rotationXAdjustment + ', ' + rotationYAdjustment + ')');
                    axisLabel.attr('class', axisLabel.attr('class') + ' tick-label--is-rotated');
                });
            }
            else {
                // Rotate labels from -90° to 0°
                _axisLabels.each(function() {
                    var axisLabel = d3.select(this),
                        currentTransform = myChartUtils.getTokenizedTransformValue(axisLabel.attr('transform')),
                        bbox = axisLabel.node().getBBox(),
                        rotationXAdjustment = bbox.x,
                        yTranslation = currentTransform.translate[1] || 0, // When no y translation ensure we a number value AKA zero
                        rotationYAdjustment = parseFloat(yTranslation) + (Math.exp(Math.PI / 4) + Math.exp(-Math.PI / 4)) / 2 * ANGLED_LABEL_BREATHING_ROOM,
                        updatedTransform = [];

                    for (var type in currentTransform) {
                        if (type === 'rotate') {
                            continue;
                        } // Skip rotate if it is present

                        updatedTransform.push(type + '(' + currentTransform[type].join(',') + ')');
                    }

                    axisLabel.selectAll('.text-line').attr('text-anchor', 'middle');

                    // Update transform attribute
                    axisLabel.attr('transform', updatedTransform.join(' ') + ' rotate(90, ' + rotationXAdjustment + ', ' + rotationYAdjustment + ')');
                    axisLabel.attr('class', axisLabel.attr('class') + ' tick-label--is-rotated');
                });
            }
        }

        function shouldLabelsBeAngled() {
            // We should not flip images so return false
            return !myChartUtils.dataHasImages(_axisLabels.data());
        }

        // Logic to figure out if the label needs to be rotated to zero degrees
        function angleLabel() {
            // We will make a generalization that all characters are 2.5px
            // We could calculate the actual dimensions of the text, but since
            // this calclulation needs to happen quickly and rather often and
            // we do not need to be very exact this is good rough solution
            const GENERALIZED_CHAR_WIDTH = 2.5,
                LABEL_PADDING = _chart.categoryAxis().tickLabelPadding(),
                MIN_WRAPPING_LABELS = 2;
            var labelsLargerAvailableSpace = _.filter(_axisLabels.data(), function(labelText) {
                let availableSpaceForLabelText = Math.abs(_plot.x().scale.rangeBand() - (2 * LABEL_PADDING)),
                    spaceRequestedForLabelText = labelText.length * GENERALIZED_CHAR_WIDTH;
                return spaceRequestedForLabelText > availableSpaceForLabelText;
            });
            return labelsLargerAvailableSpace.length >= MIN_WRAPPING_LABELS;
        }

        function isPlotVertical() {
            // Since scatter plots are only ever rendered vertically always return true for them
            return 'size' in _plot ? true : _plot._isVertical;
        }

        function getPlotCategoricAxisSelection() {
            return _chart.categoryAxis().element();
        }

        return angledAxisLabels;
    } // End of angledAxisLabels

    return angledAxisLabels;
}
