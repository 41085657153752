angular.module('rating.ctrl', ['rating.options'])
    .controller('RatingCtrl', RatingCtrl);
RatingCtrl.$inject = [
    '$rootScope',
    '$scope',
    'ratingOptions',
];

function RatingCtrl($rootScope, $scope, options) {
    var RatingVM = this;
    RatingVM.minLength = options.minLength;
    RatingVM.maxLength = options.maxLength;
    RatingVM.options = options.list;
    RatingVM.auth = $rootScope.auth;
    RatingVM.idToIcon = idToIcon;
    RatingVM.idToName = idToName;
    RatingVM.range = _.range;
    RatingVM.activateSliderPreview = activateSliderPreview;
    $scope.question.details.displayType = $scope.question.details.displayType || 'glyph';

    function idToIcon(id) {
        return (options.map[id] && options.map[id].icon) || '';
    }

    function idToName(id) {
        return (options.map[id] && options.map[id].name) || '';
    }

    function activateSliderPreview() {
        $('.slider').slider({
            disabled: true,
        });
    }
}
