import {
    getDefaultDateRangePickerOptions,
} from '../common-components/datepicker-service';
import './api-publisher-service';
import './publisher-date-service';
import U from '../../../../common/js/util';

angular.module('publisherUser', [
    'SurveyAppCommon',
    'apiPublisherService',
    'publisherDate.service',
])
    .controller('publisherSummaryCtrl', publisherSummaryCtrl)
    .controller('publisherDetailCtrl', publisherDetailCtrl);

publisherSummaryCtrl.$inject = [
    '$rootScope',
    '$scope',
    'publisherService',
    'publisherDateService',
];

/**
 * Controller definition
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} publisherService
 * @param {object} publisherDateService
 */
function publisherSummaryCtrl($rootScope, $scope, publisherService, publisherDateService) {
    $scope.ux = {
        ready: false,
        summaries: null,
    };

    $scope.date = {
        start: publisherDateService.getStartDate(),
        end: publisherDateService.getEndDate(),
    };

    $scope.datePickerOptions = getDefaultDateRangePickerOptions($scope.date, refreshPublishers);
    $scope.refreshPublishers = refreshPublishers;
    $scope.payoutAmount = payoutAmount;

    /**
     * Payout amount calculation
     *
     * @param {object} summary
     * @returns {number}
     */
    function payoutAmount(summary) {
        if (summary.payoutCents) {
            return summary.payoutCents / 100;
        }
        return summary.clientCreditedInterviews * 0.20;
    }

    function refreshPublishers() {
        $scope.ux.ready = false;
        $rootScope.startDate = $scope.date.start;
        $rootScope.endDate = $scope.date.end;
        publisherService.listPublisherSummaries($scope.date.start, $scope.date.end).then(function(publisherSummaries) {
            $scope.ux.summaries = publisherSummaries;
            $scope.ux.ready = true;
        });
    }

    $scope.refreshPublishers();
}

publisherDetailCtrl.$inject = ['$rootScope',
    '$scope',
    'publisherService',
    '$stateParams',
    'publisherDateService',
    '$filter',
];

/**
 * Publisher detail controller
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} publisherService
 * @param {object} $stateParams
 * @param {object} publisherDateService
 * @param {object} $filter
 */
function publisherDetailCtrl($rootScope, $scope, publisherService, $stateParams, publisherDateService, $filter) {
    $scope.ux = {
        ready: false,
        publisherId: $stateParams.publisherId,
        details: null,
    };
    $scope.date = {
        start: $rootScope.startDate,
        end: $rootScope.endDate,
    };

    if (!$scope.date.start) {
        $scope.date.start = publisherDateService.getStartDate();
        $scope.date.end = publisherDateService.getEndDate();
    }

    $scope.datePickerOptions = getDefaultDateRangePickerOptions($scope.date, refreshDetails);
    $scope.payoutAmount = payoutAmount;
    $scope.formatDate = formatDate;
    $scope.refreshDetails = refreshDetails;

    /**
     * Payout amount
     *
     * @param {object} day
     * @returns {number} Calculated payout amount
     */
    function payoutAmount(day) {
        if (day.payoutCents) {
            return day.payoutCents / 100;
        }
        return day.clientCreditedInterviews * 0.20;
    }

    /**
     * Format date
     *
     * @param {Date} date
     * @returns {Date}
     */
    function formatDate(date) {
        return $filter('date')(U.toUTCDate(date), 'shortDate');
    }

    function refreshDetails() {
        $scope.ux.ready = false;
        publisherService.getDayStats($scope.ux.publisherId, $scope.date.start, $scope.date.end).then(function(stats) {
            $scope.ux.details = stats;
            $scope.ux.ready = true;
        });
    }

    $scope.refreshDetails();
}
