import 'angular-ui-bootstrap';

angular.module('common.dialog', ['ui.bootstrap'])
    .controller('ConfirmModalCtrl', ConfirmModalCtrl)
    .service('dialog', dialog);
dialog.$inject = ['$compile', '$uibModal'];

function dialog($compile, $uibModal) {
    this.popup = popup;
    this.confirm = confirm;

    var defaultPopupOptions = {
            controllerAs: 'vm',
            modalFade: true,
            size: 'sm',
        },
        defaultConfirmOptions = {
            templateUrl: '/modal-templates/default-confirm-modal.html',
            controller: 'ConfirmModalCtrl',
            controllerAs: 'ConfirmModalVM',
        },
        defaultModalWindowClass = ' modal-sv';
    /**
     * @name popup
     * @param {Object} [modalOptions] - The options for the modal. See http://angular-ui.github.io/bootstrap/versioned-docs/0.14.0/#/modal for supported options
     * @param {Object} [modalResolve] - Any values that the modal's controller should have access to. Defaults to
     * 'OK' and 'Cancel' buttons, which closes the modal
     *
     * @returns {$uibModalInstance} The modal instance with the following properties: close, dismiss, results, opened, and rendered.
     * @description
     * Creates a modal instance given the options. If no options are specified, default options are applied.
     */
    function popup(modalOptions) {
        var _modalOptions = {};
        angular.extend(_modalOptions, defaultPopupOptions, modalOptions);
        _modalOptions.windowClass = (_modalOptions.windowClass || '') + defaultModalWindowClass;

        return $uibModal.open(_modalOptions);
    }

    function confirm(modalOptions) {
        var _modalOptions = {};
        modalOptions = modalOptions || {};
        angular.extend(_modalOptions, defaultConfirmOptions, modalOptions);
        _modalOptions.windowClass = (_modalOptions.windowClass || '') + defaultModalWindowClass;
        if (_modalOptions.resolve) {
            _modalOptions.resolve = {
                resolve: () => {
                    return modalOptions.resolve;
                },
            };
        }
        return $uibModal.open(_modalOptions);
    }
}
ConfirmModalCtrl.$inject = [
    '$state',
    '$uibModalInstance',
    'resolve',
];

function ConfirmModalCtrl($state, $uibModalInstance, resolve) {
    var ConfirmModalVM = this;
    ConfirmModalVM.close = $uibModalInstance.close;
    ConfirmModalVM.leave = leave;

    function leave() {
        resolve.leave();
        ConfirmModalVM.close();
    }
}
