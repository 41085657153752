import './zapier-service';
import './angular-util';
    angular.module('ProSignupModule', ['ZapierModule', 'angularUtil'])
        .controller('ProSignupCtrl', [
            'httpDeferred',
            '$timeout',
            'ZapierService',
            function(httpDeferred, $timeout, $zapier) {
                var proContactVM = this;

                proContactVM.formStatus = '';
                proContactVM.name = '';
                proContactVM.email = '';
                proContactVM.phone = '';
                proContactVM.company = '';
                proContactVM.comments = '';

                proContactVM.submitProContact = function submitProContact() {
                    proContactVM.formStatus = 'submitting';

                    if (!proContactVM.email || !U.validEmail(proContactVM.email)) {
                        $timeout(function() {
                            proContactVM.formStatus = 'error';
                        }, 500);
                    }
                    else {
                        var params = {
                            email: proContactVM.email,
                            name: proContactVM.name,
                            phone: proContactVM.phone,
                            company: proContactVM.company,
                            comment: proContactVM.comments,
                            contactType: 'pro',
                        };

                        // Add referrer and utm information, if available
                        if (U.cookie('utm_log')) {
                            params.utmLog = U.cookie('utm_log');
                        }
                        if (U.cookie('ref_log')) {
                            params.refererLog = U.cookie('ref_log');
                        }

                        // Submission logic
                        httpDeferred.post('https://rest.upwave.com/rest/contact-request/create', params)
                            .then(function(response) {
                                if (response.error) {
                                    proContactVM.formStatus = 'error';
                                }
                                else {
                                    U.fireContactUsPixels(proContactVM.email);
                                    $zapier.fire('368gjg', params);
                                    proContactVM.formStatus = 'success';
                                }
                            }, function(error) {
                                proContactVM.formStatus = 'error';
                            });
                    }
                };
            }]);

