import '../../../common/js/inc/sv-notify';
import './survey/api-survey-service';
import './survey-app-common';

angular.module('SurveyAttachmentApp', [
    'svNotify',
    'SurveyAppCommon',
    'apiSurveyService',
])

    .controller('SurveyAttachmentsCtrl', SurveyAttachmentsCtrl);
SurveyAttachmentsCtrl.$inject = ['$rootScope',
    '$scope',
    '$timeout',
    '$notify',
    'surveyService',
];

/**
 * Defines the SurveyAttachment controller.
 *
 * @param {*} $rootScope
 * @param {*} $scope
 * @param {*} $timeout
 * @param {*} $notify
 * @param {*} surveyService
 */
function SurveyAttachmentsCtrl($rootScope, $scope, $timeout, $notify, surveyService) {
    $scope.survey = $rootScope.survey;
    $scope.uploadStatus = '';
    $scope.saveStatus = '';
    $scope.attachment = {
        fileLabel: '',
        adminOnly: false,
        fileUrl: '',
        insertedText: '',
    };
    $scope.ux = $.extend($scope.ux || {}, {
        loaded: false,
        attachment: {
            formVisible: false,
            buttonText: 'Add attachment',
            buttonTextState: {
                formHidden: 'Add attachment',
                formVisible: 'Hide attachment form',
            },
        },
        accordionConfig: {
            closeOthers: true,
            url: false,
            text: false,
            upload: true,
        },
        accordion: [{
            id: 'url',
            admin: $rootScope.auth.user.isAdmin,
        },
        {
            id: 'text',
            admin: $rootScope.auth.user.isAdmin,
        },
        {
            id: 'upload',
            admin: true,
        }],
    });
    $scope.attachments = [];

    $scope.refreshAttachments = refreshAttachments;
    function refreshAttachments() {
        surveyService.getAttachments($scope.survey.uuid).then(function(attachments) {
            $scope.attachments = attachments;
            $timeout(function() {
                $scope.ux.loaded = true;
            }, 600);
        });
    }

    $scope.uploadFile = uploadFile;
    function uploadFile() {
        $('#upload-file-select').click();
    }

    $scope.uploadText = uploadText;
    function uploadText() {
        $scope.uploadStatus = 'uploading';

        if (!$scope.attachment.insertedText) {
            $scope.uploadStatus = 'failed';
            $notify.error('Please insert text');
            return;
        }
        surveyService.uploadText($scope.survey.uuid, $scope.attachment.fileLabel, $scope.attachment.insertedText, $scope.attachment.adminOnly).then(
            function(response) {
                $scope.attachmentSaveSuccess(response);
            },
            function(response) {
                $scope.uploadStatus = 'failed';
                if (response && response.error) {
                    $notify.error(response.error);
                }
            });
    }

    $scope.attachmentSaveSuccess = attachmentSaveSuccess;

    /**
     * Attachment save success.
     *
     * @param {*} response
     */
    function attachmentSaveSuccess(response) {
        $scope.saveStatus = '';
        $scope.uploadStatus = '';
        $scope.attachment.fileLabel = '';
        $scope.attachment.fileUrl = '';
        $scope.attachment.insertedText = '';
        $scope.attachment.adminOnly = false;
        $scope.attachments.push(response);
        $notify.success('Attachment added');
    }

    $scope.onFileSelect = onFileSelect;

    /**
     * On file select.
     *
     * @param {*} $files
     */
    function onFileSelect($files) {
        $scope.uploadStatus = 'uploading';
        // Should be only one file
        var file = $files[0];
        surveyService.uploadAttachment($scope.survey.uuid, $scope.attachment.fileLabel, file, $scope.attachment.adminOnly).then(
            function(response) {
                $scope.attachmentSaveSuccess(response);
            },
            function(response) {
                $scope.uploadStatus = 'failed';
                if (response && response.error) {
                    $notify.error(response.error);
                }
            });
    }

    $scope.isAdmin = isAdmin;

    /**
     * Is admin.
     *
     * @returns {*}
     */
    function isAdmin() {
        return $rootScope.auth.user.isAdmin;
    }

    $scope.saveAttachment = saveAttachment;
    function saveAttachment() {
        if (!$scope.attachment.fileUrl) {
            $scope.saveStatus = 'failed';
            return;
        }
        $scope.saveStatus = 'saving';
        surveyService.saveAttachment($scope.survey.uuid, $scope.attachment.fileLabel, $scope.attachment.fileUrl,
            $scope.attachment.adminOnly).then(function(response) {
            $scope.attachmentSaveSuccess(response);
        }, function() {
            $scope.saveStatus = 'failed';
        });
    }

    $scope.getAttachmentIdx = getAttachmentIdx;

    /**
     * Get attachment index.
     *
     * @param {*} attachmentUuid
     * @returns {number}
     */
    function getAttachmentIdx(attachmentUuid) {
        var result = -1;

        angular.forEach($scope.attachments, function(q, idx) {
            if (q.uuid === attachmentUuid) {
                result = idx;
            }
        });
        return result;
    }

    $scope.$watch('ux.loaded', showFormLogic);

    $scope.$watch('attachments', showFormLogic, true);

    $scope.toggleAttachmentUploadForm = toggleAttachmentUploadForm;
    function toggleAttachmentUploadForm() {
        // True to toggle between visibility for the form
        setAttachmentUploadFormVisibility(!$scope.ux.attachment.formVisible);
    }

    $scope.getAttachmentIcon = getAttachmentIcon;

    /**
     * Get attachment icon.
     *
     * @param {*} attachmentName
     * @returns {string}
     */
    // eslint-disable-next-line complexity
    function getAttachmentIcon(attachmentName) {
        var extension = attachmentName.split('.').splice(-1).toString().toLowerCase(),
            icontype;

        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'tiff':
            case 'gif':
            case 'webp':
                icontype = 'far fa-file-image';
                break;
            case 'doc':
            case 'docx':
                icontype = 'far fa-file-word';
                break;
            case 'ppt':
            case 'pptx':
                icontype = 'far fa-file-powerpoint';
                break;
            case 'xls':
            case 'xlsx':
            case 'csv':
                icontype = 'far fa-file-excel';
                break;
            case 'pdf':
                icontype = 'far fa-file-pdf';
                break;
            case 'zip':
            case 'dmg':
                icontype = 'far fa-file-archive';
                break;
            case 'avi':
            case 'mov':
            case 'mpg':
            case 'mp4':
            case 'swf':
            case 'wmv':
                icontype = 'far fa-file-video';
                break;
            case 'mp3':
            case 'mpa':
            case 'm4a':
            case 'wav':
            case 'wma':
                icontype = 'far fa-file-audio';
                break;
            default:
                icontype = 'far fa-file-alt';
        }
        return icontype;
    }

    $scope.deleteAttachmentSuccess = deleteAttachmentSuccess;

    /**
     * Delete attachment success.
     *
     * @param {*} attachment
     */
    function deleteAttachmentSuccess(attachment) {
        var idx = $scope.getAttachmentIdx(attachment.uuid);
        $scope.attachments.splice(idx, 1);

        // Flash message
        $notify.success('Attachment deleted');
    }

    $scope.deleteAttachment = deleteAttachment;

    /**
     * Delete attachment.
     *
     * @param {*} attachment
     */
    function deleteAttachment(attachment) {
        if (!attachment) {
            $scope.removeStatus = 'failed';
            return;
        }

        $scope.removeStatus = 'removing';
        surveyService.deleteAttachment(attachment).then(function() {
            $scope.deleteAttachmentSuccess(attachment);
        }, function(response) {
            $scope.removeStatus = 'failed';
            if (response && response.error) {
                $notify.error(response.error);
            }
        });
    }

    $scope.showUploadMethod = showUploadMethod;

    /**
     * Show upload method.
     *
     * @param {*} value
     * @returns {boolean}
     */
    function showUploadMethod(value) {
        return $rootScope.auth.user.isAdmin || (!$rootScope.auth.user.isAdmin && value.id === 'upload');
    }

    // Private methods
    /**
     * Set attachment upload form visibility.
     *
     * @param {*} visibility
     */
    function setAttachmentUploadFormVisibility(visibility) {
        if (visibility) {
            $scope.ux.attachment.formVisible = true;
            $scope.ux.attachment.buttonText = $scope.ux.attachment.buttonTextState.formVisible;
        }
        else {
            $scope.ux.attachment.formVisible = false;
            $scope.ux.attachment.buttonText = $scope.ux.attachment.buttonTextState.formHidden;
        }
    }

    /**
     * Show form logic.
     *
     * @param {*} newValue
     */
    function showFormLogic(newValue) {
        var showForm = false;
        // We don't do anything until everything is ready
        if ($scope.ux.loaded) {
            if (!$scope.attachments.length) {
                showForm = true;
            }
            else if ($scope.ux.attachment.formVisible) {
                showForm = true;
            }
            else if (!$scope.ux.attachment.formVisible && newValue.length === 0) {
                showForm = true;
            }
            else {
                // Something
            }
        }
        setAttachmentUploadFormVisibility(showForm);
    }

    $scope.refreshAttachments();
}
