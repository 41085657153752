import analysisConfigConstants from '../analysis-config-constants';
import '../../../filter-logic/filter-logic-editor';
import '../../../common-components/multi-dropdown';
import filterLogicService from '../../../filter-logic/filter-logic-service';
import '../analysis-plan.service';
import analysisConfigDataService from '../analysis-config-data-service';
import AnalysisConfigModel from '../analysis-config-model';

angular
    .module('massCutCreator', [
        'pubSub',
        'analysisPlan.service',
        'filterLogicEditor',
        'multiDropdown',
    ])
    .controller('MassCutCreatorCtrl', MassCutCreatorCtrl);

MassCutCreatorCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$uibModalInstance',
    'analysisPlanService',
    'allCategoriesAndCuts',
];

/**
 * Controller for Mass Cut Creator.
 *
 * @param {object} $scope - $scope of the controller
 * @param {object} $rootScope - $rootScope of the controller (which gets access to parent shit)
 * @param {object} $uibModalInstance - Instance of the modal containing the mass cut creator
 * @param {object} analysisPlanService - Analysis plan service
 * @param {Array} allCategoriesAndCuts - Array of all cuts
 */
function MassCutCreatorCtrl($scope, $rootScope, $uibModalInstance, analysisPlanService, allCategoriesAndCuts) {
    var MassCutCreatorVM = this;

    // VM exposed variables
    MassCutCreatorVM.massCuts = {
        categoryName: null,
        cutCategoriesToCross: [{}],
        cutsToCreate: [],
    };
    MassCutCreatorVM.ux = {
        selectedControlType: null,
        includeSurvataControl: true,
        controlTypes: analysisConfigConstants.cutControlTypes,
        allCategoriesAndCuts: allCategoriesAndCuts,
        filterModel: null,
    };

    // VM exposed functions
    MassCutCreatorVM.saveMassCuts = saveMassCuts;
    MassCutCreatorVM.enableSaveButton = enableSaveButton;
    MassCutCreatorVM.close = close;
    MassCutCreatorVM.onCutCategorySelected = onCutCategorySelected;
    MassCutCreatorVM.onCutSelectedChanged = onCutSelectedChanged;
    MassCutCreatorVM.onControlUpdated = onControlUpdated;
    MassCutCreatorVM.enableAddCategoryButton = enableAddCategoryButton;
    MassCutCreatorVM.addCategory = addCategory;
    MassCutCreatorVM.removeCategory = removeCategory;
    MassCutCreatorVM.numCutsToCreate = numCutsToCreate;
    MassCutCreatorVM.getCriteriaText = getCriteriaText;
    MassCutCreatorVM.onCutSelectionDropdownChanged = onCutSelectionDropdownChanged;

    init();

    function init() {
        MassCutCreatorVM.ux.selectedControlType = analysisConfigConstants.cutControlTypes[0];
        MassCutCreatorVM.ux.filterModel = new AnalysisConfigModel($rootScope.survey);
    }

    /**
     * Crosses the cuts from a given category with an existing array to create an new array of cross cuts
     * For example, given category Gender, and a list of age cuts, it will produce something like
     * Male + Age 13-17, Female + Age 13-17, Male + Age 18-24, Female + Age 18-24...etc
     *
     * @param {object} category - UUID of the parent analysis cut category
     * @param {Array} previousCrossCuts - An list of the previously created cross cuts (from previous calls of this function)
     *
     * @returns {Array} crossCuts - The list of cuts created from crossing the given category with the existing list of cuts
     */
    function getCrossCuts(category, previousCrossCuts = []) {
        let crossCuts = [];

        category.cuts.forEach(newCut => {
            let control = {
                $and: [],
            }; // Empty control definition JSON

            if (!newCut.selected) {
                // Skip this cut if not selected in dropdown
            }
            // If we're adding to previously created crosscuts, go down this path
            else if (previousCrossCuts.length) {
                previousCrossCuts.forEach(existingCut => {
                    // If custom control is selected, build the control json
                    if (isCustomControlSelected()) {
                        // Clone the  existing control JSON from previous crosscuts
                        control = _.cloneDeep(existingCut.control);

                        // If current category is include in custom control, concat the new JSON
                        if (category._includeInCustomControl) {
                            control = {
                                $and: control.$and.concat(newCut.exposedFormulaJson),
                            };
                        }
                    }

                    crossCuts.push({
                        name: `${existingCut.name} X ${newCut.name}`,
                        exposed: {
                            $and: existingCut.exposed.$and.concat(newCut.exposedFormulaJson),
                        },
                        control,
                        type: MassCutCreatorVM.ux.selectedControlType || existingCut.controlType || newCut.controlType,
                    });
                });
            }
            // We don't have any existing crosscuts, so build the first set
            else {
                // If this category is included in the custom control, add the exposed formula JSON
                if (isCustomControlSelected() && category._includeInCustomControl) {
                    control.$and = [newCut.exposedFormulaJson];
                }

                crossCuts.push({
                    name: newCut.name,
                    exposed: {
                        $and: [newCut.exposedFormulaJson],
                    },
                    control,
                    type: MassCutCreatorVM.ux.selectedControlType || newCut.controlType,
                });
            }
        });

        return crossCuts;
    }

    /**
     * When something in the UI is changed, the contents of the cross cuts needs to be
     * refreshed.
     */
    function refreshCrossCuts() {
        // The last item in the list of cut categories to cross is an empty object
        // until the user selects a category from the dropdown. If this has not been done yet,
        // don't try to process the cross cuts yet
        if (_.isEmpty(MassCutCreatorVM.massCuts.cutCategoriesToCross[MassCutCreatorVM.massCuts.cutCategoriesToCross.length - 1])) {
            return;
        }
        MassCutCreatorVM.massCuts.cutsToCreate = [];
        MassCutCreatorVM.massCuts.cutCategoriesToCross.forEach(category => {
            MassCutCreatorVM.massCuts.cutsToCreate = getCrossCuts(category, MassCutCreatorVM.massCuts.cutsToCreate);
        });

        // If Upwave control toggled on, add it to the JSON of each cut
        if (MassCutCreatorVM.ux.includeSurvataControl) {
            MassCutCreatorVM.massCuts.cutsToCreate = MassCutCreatorVM.massCuts.cutsToCreate.map(cut => {
                analysisConfigDataService.addIsControlToJSON(cut.control, 1);
                return cut;
            });
        }
    }

    /**
     * Callback function from UI, triggered when cut category is selected in the dropdown
     * refreshed. Default _includeInCustomControl to true.
     *
     * @param {object} category - The analysis cut category that was selected
     */
    function onCutCategorySelected(category) {
        category._includeInCustomControl = true;
        refreshCrossCuts();
    }

    /**
     * Given a criteria json return a plain english representation of it.
     *
     * @example { $eq: ['Demographic$Gender', 'female'] } would be "Gender: female"
     *
     * @param {object} criteriaJson - Upwave query lanauge formatted JSON
     * @returns {string} - The plain english representation
     */
    function getCriteriaText(criteriaJson) {
        return filterLogicService.filterJsonToPlainEnglish(criteriaJson);
    }

    /**
     * When control is updated, either by changing control type, or configuring custom control
     */
    function onControlUpdated() {
        refreshCrossCuts();
    }

    /**
     * Helper function returning whether user has selected custom control type.
     * Note that analysisConfigConstants is an array of the control type options, and custom is the 3rd
     * option in the array.
     *
     * @returns {boolean} - Return whether control type is 'custom'
     */
    function isCustomControlSelected() {
        return MassCutCreatorVM.ux.selectedControlType &&
            MassCutCreatorVM.ux.selectedControlType.value === analysisConfigConstants.cutControlTypes[2].value;
    }

    /**
     * Determine whether to enable to the save button, based on whether the user has selected
     * the necessary critiera for saving mass cuts.
     *
     * @returns {boolean} - Return whether save button should be enabled
     */
    function enableSaveButton() {
        return enableAddCategoryButton() && MassCutCreatorVM.massCuts.categoryName;
    }

    /**
     * Determine whether to enable the add category button, based on whether user has
     * specified the previous category.
     *
     * @returns {boolean} - Should button be enabled?
     */
    function enableAddCategoryButton() {
        return _.last(MassCutCreatorVM.massCuts.cutCategoriesToCross).name;
    }

    /**
     * Add a new empty category to to the cut category array. User still needs to
     * configure this category in the UI.
     */
    function addCategory() {
        MassCutCreatorVM.massCuts.cutCategoriesToCross.push({});
    }

    /**
     * Remove a cut category from the list of cut categories to cross.
     *
     * @param {number} index - The index of the category we want to remove
     */
    function removeCategory(index) {
        MassCutCreatorVM.massCuts.cutCategoriesToCross.splice(index, 1);
        refreshCrossCuts();
    }

    /**
     * Return the number of cuts we'll be creating, given the current configuration
     */
    function onCutSelectedChanged() {
        numCutsToCreate();
    }

    /**
     * Callback function for cut selection dropdown. Updated cuts is passed back,
     * but we don't actually need it. Refresh cross cuts.
     */
    function onCutSelectionDropdownChanged() {
        refreshCrossCuts();
    }

    /**
     * Return the number of cuts we'll be creating, given the current configuration
     *
     * @returns {boolean} - Number of cuts that will be created.
     */
    function numCutsToCreate() {
        return _.countBy(MassCutCreatorVM.massCuts.cutsToCreate, '_selected').true;
    }

    /**
     * Save the mass cuts, then close the modal
     */
    function saveMassCuts() {
        analysisPlanService.saveMassCuts(MassCutCreatorVM.massCuts).then(newCuts => {
            close(newCuts);
        });
    }

    /**
     * Close the modal and pass the new cuts to the parent
     *
     * @param {Array} cuts - Array of new cuts
     */
    function close(cuts) {
        $uibModalInstance.close(cuts);
    }
}
