import * as d3 from 'd3';
import 'angular-ui-bootstrap';
import {
    Components, Dataset, Interactions, Plots,
} from 'plottable/plottable';
import chartsConstant from './charts-constant';

const Plottable = {
    Components: Components,
    Dataset: Dataset,
    Interactions: Interactions,
    Plots: Plots,
};

angular.module('chart.tooltips', ['ui.bootstrap.popover'])
    .factory('Tooltips', tooltips);

function tooltips() {
    const COUNT_AND_PERCENT = 'COUNT_AND_PERCENT_TEMPLATE';
    const ENTITY_NEAREST_BY_X_THEN_Y = 'entityNearestByXThenY';
    const TOOLTIP_ARROW_WIDTH = 5; // Half of the arrow width
    const TOOLTIP_PADDING = 200; // Padding to account for tooltip width
    function tooltip() {
        var _chart,
            _group,
            _plot,
            id = '__tooltip__' + Math.random() * 1e3,
            _data = [],
            _fillFn,
            _colorScale,
            guidelineGroup,
            compiledTooltipTemplate,
            calculatedLengths = {},
            //Private state
            _ignoreMouseInteraction = false,
            _trackingLineEnabled,
            _tooltipContainerElement, // Done only to cache so we don't pay for DOM look ups everytime
            _tooltipAnchor,
            _className = 'guide-line-group',
            _pointer, // Get top most plot
            _tooltipSubheading,
            _guideline,
            _selectedPoint;

        tooltip.getGuidelineGroup = getGuidelineGroup;
        tooltip.subHeading = subHeading;
        tooltip.getSetPointer = getSetPointer;
        tooltip.disable = disable;
        tooltip.destroy = destroy;

        tooltip.chart = chart;
        tooltip.init = init;
        tooltip.group = group;
        tooltip.plot = plot;
        tooltip.data = data;
        tooltip.fillFn = fillFn;
        tooltip.colorScale = colorScale;
        tooltip.trackingLineEnabled = trackingLineEnabled;

        function init() {
            _chart.addEventListener(_chart.EVENTS.ANCHOR_COMPLETE, handleChartAnchorResizeComplete, id, true);
            _chart.addEventListener(_chart.EVENTS.RESIZE_COMPLETE, handleChartAnchorResizeComplete, id);
            _chart.addEventListener(_chart.EVENTS.FLIP_AXIS_COMPLETE, handleChartFlipAxisComplete, id);
            _group.append(getGuidelineGroup());
            return tooltip;
        }

        function data(value, filterFn) {
            if (!arguments.length) {
                return _data;
            }
            if (value.length) {
                if (filterFn) {
                    value = value.filter(filterFn);
                }

                // NOTE: testing for Plottable Dataset since d3 uses plain arrays
                if (value[0]._data) {
                    for (let i = 0; i < value.length; i++) {
                        _data.push(value[i].data());
                    }
                }
                else {
                    // Add plain array to data
                    _data.push(value);
                }
            }
            _data = _.flatten(_data); // NOTE: necessary when there are multiple data sets need to flatten them to one big array for d3
            return tooltip;
        }

        function fillFn(val) {
            if (!arguments.length) {
                return _fillFn;
            }
            _fillFn = val;
            return tooltip;
        }

        function colorScale(val) {
            if (!arguments.length) {
                return _colorScale;
            }
            _colorScale = val;
            return tooltip;
        }

        function chart(val) {
            if (!arguments.length) {
                return _chart;
            }
            _chart = val;
            return tooltip;
        }

        function group(val) {
            if (!val) {
                return _group;
            }
            _group = val;
            return tooltip;
        }

        function plot(val) {
            if (!val) {
                return _plot;
            }
            _plot = val;
            return tooltip;
        }

        function trackingLineEnabled(val) {
            if (!arguments.length) {
                return _trackingLineEnabled;
            }
            _trackingLineEnabled = val;
            return tooltip;
        }

        function destroy() {
            _chart.removeEventListener(_chart.EVENTS.ANCHOR_COMPLETE, id);
            _chart.removeEventListener(_chart.EVENTS.RESIZE_COMPLETE, id);
            _chart.removeEventListener(_chart.EVENTS.FLIP_AXIS_COMPLETE, id);

            if (_pointer) {
                _pointer.enabled(false);
            }
            return tooltip;
        }

        function tooltipTemplate(templateName) {
            if (!arguments.length) {
                return compiledTooltipTemplate;
            }
            compiledTooltipTemplate = chartsConstant[templateName];
        }

        function subHeading(newSubheading) {
            if (!arguments.length) {
                return _tooltipSubheading;
            }
            _tooltipSubheading = newSubheading;
        }

        function initPointer() {
            // Creates and adds pointer event handler to track mouse movements
            if (!_pointer) {
                _pointer = new Plottable.Interactions.Pointer()
                    .onPointerExit(pointerExitHandler)
                    .onPointerMove(pointerMoveHandler)
                    .onPointerEnter(pointerEnterHandler);
            }

            _pointer.enabled(true)
                .attachTo(_chart.table());
        }

        function getGuidelineGroup() {
            if (guidelineGroup && guidelineGroup._isAnchored) {
                return guidelineGroup;
            }

            if (isPlotVertical()) {
                _guideline = new Plottable.Components.GuideLineLayer(Plottable.Components.GuideLineLayer.ORIENTATION_VERTICAL)
                    .scale(_plot.x().scale);
            }
            else {
                _guideline = new Plottable.Components.GuideLineLayer(Plottable.Components.GuideLineLayer.ORIENTATION_HORIZONTAL)
                    .scale(_plot.y().scale);
            }

            // Creates + adds a layer group so as to not disturb existing content
            let datasetForFocusPoint = new Plottable.Dataset();

            _selectedPoint = new Plottable.Plots.Scatter()
                .x(_plot.x().accessor, _plot.x().scale)
                .y(_plot.y().accessor, _plot.y().scale) // TODO move to CSS
                .size(8)
                .attr('opacity', 1)
                .addDataset(datasetForFocusPoint)
                .attr('fill', _fillFn, _colorScale) // NOTE: this might bring up interesting issues
                .attr('stroke', _fillFn, _colorScale); // NOTE: this might bring up interesting issues

            let selectedPointHighlight = new Plottable.Plots.Scatter()
                .x(_plot.x().accessor, _plot.x().scale)
                .y(_plot.y().accessor, _plot.y().scale) // TODO move to CSS
                .size(16)
                .attr('opacity', 0.25)
                .addDataset(datasetForFocusPoint)
                .attr('fill', _fillFn, _colorScale) // NOTE: this might bring up interesting issues
                .attr('stroke', _fillFn, _colorScale); // NOTE: this might bring up interesting issues;

            guidelineGroup = new Plottable.Components.Group([
                _guideline,
                selectedPointHighlight,
                _selectedPoint,
            ])
                .addClass(_className);

            if (!_trackingLineEnabled) {
                _guideline.addClass('guide-line-layer--disabled');
            }

            // Destroy original guidelineGroup if it exists
            // if (chart.chartTooltips()) {
            //     guidelineGroup = chart.chartTooltips().getguidelineGroup();
            //     $(guidelineGroup.components()[0].content().node().childNodes[0]).popover('destroy');
            //
            //     _pointer = chart.chartTooltips().getSetPointer();
            //     _pointer.detachFrom(guidelineGroup);
            //
            //     chart.plotGroup().remove(guidelineGroup);
            //     guidelineGroup.destroy();
            //     guidelineGroup = undefined;
            // }
            return guidelineGroup;
        }

        function isPlotVertical() {
            // Trackers are essentially vertical for our purposes
            return (ENTITY_NEAREST_BY_X_THEN_Y in _plot) || _plot._isVertical;
        }

        function handleChartAnchorResizeComplete() {
            if (ENTITY_NEAREST_BY_X_THEN_Y in _plot) {
                // Ensure we have guide line group so our labels are contained
                guidelineGroup = getGuidelineGroup();
                _group.append(guidelineGroup);
            }
            initPointer();
            // Now that the chart is anchored, it's safe to set tooltip
            tooltip();
        }

        function handleChartFlipAxisComplete() {
            if (ENTITY_NEAREST_BY_X_THEN_Y in _plot) {
                // Rebuild grouping layer to build tooltips on
                guidelineGroup = getGuidelineGroup();
                _group.append(guidelineGroup);
            }
            initPointer();

            tooltip();
        }

        function pointerEnterHandler() {
            var evt = window.event;
            // ignore mouse movements when both are true
            // 1. mouse button (1- left click, 2-left click for left-handed) is down (https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons)
            // 2. mouse event target is this plot
            //
            // Meaning this "drag" event occurred outside of this chart and we should not honor it
            //  so we delay any interactions for 175ms -- and set a flag
            //  If user reenters plot before _ignoreMouseInteraction has been cleared, clear it ==> this should make the interactions feel snappier
            if (evt && (getButtonPressed(evt) === 1 || getButtonPressed(evt) === 2)) {
                if (evt.target.viewportElement) {
                    _ignoreMouseInteraction = '#' + evt.target.viewportElement.id === _chart.renderContainer();
                    return;
                }
                for (var i = 0; i < evt.paths.length; i++) {
                    var elem = evt.paths[i];
                    if ('#' + elem.getAttribute('id') === _chart.renderContainer()) {
                        _ignoreMouseInteraction = true;
                    }
                }
            }
        }

        function axisAccessor() {
            return isPlotVertical() ? _plot.x() : _plot.y();
        }

        function pointerMoveHandler(point) {
            var closest = ENTITY_NEAREST_BY_X_THEN_Y in _plot ? _plot.entityNearestByXThenY(point) : _plot.entityNearest(point),
                _chartCoord = _chart.table().rootElement().node().getBoundingClientRect();
            if (!closest || !closest.datum) {
                return;
            }

            var plotIsVertical = isPlotVertical(),
                chartIsTracker = ENTITY_NEAREST_BY_X_THEN_Y in _plot,
                html = document.documentElement,
                xPlacement,
                yPlacement,
                medianYValue,
                allPoints,
                shouldUpdateTooltipPosition,
                title;
            // If we have a data point and it is not our previous one then update tooltip content
            if (plotIsVertical) {
                shouldUpdateTooltipPosition = !_tooltipAnchor.attr('data-pos') || (_tooltipAnchor.attr('data-pos') !== _plot.x().accessor.call(null, closest.datum));
            }
            else {
                shouldUpdateTooltipPosition = !_tooltipAnchor.attr('data-pos') || (_tooltipAnchor.attr('data-pos') !== _plot.y().accessor.call(null, closest.datum));
            }

            if (shouldUpdateTooltipPosition) {
                allPoints = getAllDataPointsOnSameMainAxis(closest);
                medianYValue = d3.median(allPoints, function(d) {
                    return d.position.y;
                });

                // Calculate most up-to-date top offset for tooltip since the tooltip is attached to the body container
                if (plotIsVertical) {
                    xPlacement = closest.position.x;
                    yPlacement = medianYValue;
                }
                else {
                    xPlacement = d3.max(allPoints, function(d) {
                        return d.position.x;
                    });
                    yPlacement = closest.position.y;
                }

                title = axisAccessor().accessor.call(null, closest.datum);

                // Add highlighting for trackers
                if (chartIsTracker && ENTITY_NEAREST_BY_X_THEN_Y in _plot) {
                    _selectedPoint.datasets()[0].data(
                        _.map(allPoints, function(point) {
                            return point.datum;
                        })
                    );
                }

                // Update tooltip content + positioning
                _tooltipAnchor.attr({
                    x: xPlacement,
                    y: yPlacement,
                    'data-pos': title,
                    'data-original-title': title,
                    'data-content': getFormattedTooltipContent(allPoints, title),
                });
                if (plotIsVertical && xPlacement + _chartCoord.left + calculatedLengths[title] > Math.min(window.innerWidth || html.clientWidth)) {
                    _tooltipAnchor.data('tooltip-position', 'left');
                }
                else {
                    _tooltipAnchor.data('tooltip-position', 'right');
                }

                if (chartIsTracker) {
                    // Move tracking line
                    d3.select(_guideline.content().node().childNodes[0])
                        .attr('x1', xPlacement)
                        .attr('x2', xPlacement);
                    // We need this to make the tracking line visible
                    // Only using _plot.x() because this only applies to trackers anyways
                    _guideline.value(_plot.x().accessor.call(null, closest.datum));
                    showTrackingLine();
                }

                // Show tooltip
                _tooltipAnchor.popover('show');
            }
        }

        function pointerExitHandler() {
            if (_ignoreMouseInteraction) {
                _ignoreMouseInteraction = false;
                return;
            }

            if (ENTITY_NEAREST_BY_X_THEN_Y in _plot) {
                // Hide tracking line if we have it
                hideTrackingLine();
                // Hide point highlights for tracker
                _selectedPoint.datasets()[0].data([]);
            }

            // Hide tooltip as well
            _tooltipAnchor.popover('hide');

            // Clear last data point history
            _tooltipAnchor.removeAttr('data-pos');
        }

        function tooltip() {
            var _tooltipSelector;
            _tooltipAnchor = $(_guideline.content().node().childNodes[0]); // Attach to tracking line

            _tooltipSelector = _chart.renderContainer();
            _tooltipContainerElement = $(_tooltipSelector + ' .component.gridlines');

            _tooltipAnchor.popover({
                template: '<div class="popover chart__popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
                animation: false,
                container: _tooltipContainerElement,
                placement: function() {
                    return _tooltipAnchor.data('tooltip-position');
                },
                e: '',
                html: true,
                trigger: 'manual',
            });

            // Position tooltip at correct y value
            _tooltipAnchor.on('shown.bs.popover', function() {
                let popoverElement = _tooltipAnchor.data('bs.popover'),
                    yPlacement = $(this).attr('y'),
                    xPlacement = parseFloat($(this).attr('x')),
                    popoverHeight = popoverElement.$tip.height() / 2,
                    isTracker = ENTITY_NEAREST_BY_X_THEN_Y in _plot;
                popoverElement.$tip.css('top', (yPlacement - popoverHeight) + 'px');
                if (!isTracker) {
                    if (isPlotVertical() && _tooltipAnchor.data('tooltip-position') === 'left') {
                        popoverElement.$tip.css('left', (xPlacement - popoverElement.$tip.width()) + 'px');
                    }
                    else {
                        popoverElement.$tip.css('left', xPlacement + 'px');
                    }
                }
                popoverElement.$arrow.css('top', ''); // Clear the arrow styling -- somehow it's getting set and leaving the arrow somewhere far away from the tooltip
            });
        }

        function getFormattedTooltipContent(entities, title) {
            var toolTipAdjustmentWidth = 5,
                plotIsVertical = isPlotVertical(),
                formattedTooltipContent = _.map(entities, function(e) {
                    var valueAxis = plotIsVertical ? e.datum.y : e.datum.x,
                        axisPlot = _plot._isVertical ? _plot.y() : _plot.x(), // Not used for trackers
                        value = ENTITY_NEAREST_BY_X_THEN_Y in _plot ? valueAxis : axisPlot.accessor(e.datum),
                        percentVal = d3.format('.1%')(e.datum.cntProp),
                        respondentVal = Number.isInteger(e.datum.cntValue) ? e.datum.cntValue : d3.format('.2f')(e.datum.cntValue);

                    if (!compiledTooltipTemplate) {
                        tooltipTemplate(COUNT_AND_PERCENT);
                    }
                    return compiledTooltipTemplate({
                        datasetColor: colorScale().scale(e.dataset.metadata().name),
                        name: (e.dataset && e.dataset.metadata() && e.dataset.metadata().name) || valueAxis,
                        primaryData: percentVal === d3.format('.1%')(value) ? percentVal : respondentVal,
                        secondaryData: percentVal === d3.format('.1%')(value) ? respondentVal : percentVal,
                    });
                }),
                content = (_tooltipSubheading ? '<p>' + _tooltipSubheading + '</p>' : '') +
                '<table class="table table-condensed">' + formattedTooltipContent.join('') + '</table>';
            if (!calculatedLengths[title]) {
                $('.c-content-container.c-content-container__inner').append('' +
                    '<div id="tempElem" class="popover chart__popover" role="tooltip" style="display: inline">' +
                    '<h3 class="popover-title" style="display: inline">' + title + '</h3></div>');
                var temp = $('#tempElem');
                calculatedLengths[title] = temp.width() + toolTipAdjustmentWidth + TOOLTIP_PADDING;
                temp.remove();
            }
            return content;
        }

        function getAllDataPointsOnSameMainAxis(closest) {
            if (data().length === 1) {
                return closest;
            }

            function isEntityInChartViewPort(entity) {
                // Verifying that data point is in view by checking for values in __data__ attribute
                if (entity.selection.node() && entity.selection.node().__data__) {
                    return !_.isEmpty(entity.selection.node().__data__);
                }
            }

            let plotIsVertical = isPlotVertical(),
                results = _.groupBy(_plot.entities(), function(d) {
                    if (isEntityInChartViewPort(d)) {
                        if (plotIsVertical) {
                            // This is for trackers OR a vertical plot
                            return _plot.x().accessor.call(null, closest.datum) === _plot.x().accessor.call(null, d.datum);
                        }
                        return _plot.y().accessor.call(null, closest.datum) === _plot.y().accessor.call(null, d.datum);
                    }
                    return false;
                });

            // Returning true for the other points that matched the closest
            return results.true;
        }

        function getButtonPressed(evt) {
            // All browsers except safari have the "buttons" property
            // safari only has support for the "which" property
            //
            // we also need the typeof check because "buttons" returns 0 if no button is pressed
            return typeof evt.buttons === 'undefined' ? evt.which : evt.buttons;
        }

        function showTrackingLine() {
            _guideline.addClass('guide-line-layer--is-active');
        }

        function hideTrackingLine() {
            _guideline.removeClass('guide-line-layer--is-active');
        }

        function getSetPointer(p) {
            if (!arguments.length) {
                return _pointer;
            }
            _pointer = p;
            return _pointer;
        }

        function disable() {
            if (!_pointer) {
                console.info('a _pointer doesnt exist while disabling tooltip()');
                return;
            }
            _pointer.enabled(false)
                .detachFrom(_chart.table());

            return tooltip;
        }

        return tooltip;
    }

    return tooltip;
}
