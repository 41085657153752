/* eslint compat/compat: "off" */
import exportConstants from './export-constants';
import {
    createDocumentElement, createSvgNode,
} from './export-service';
import pngExport from './png-export';
import svgExport from './svg-export';
import imageExportService from './image-export-service';
import PngExportOptions from './png-export-options';
import SvgExportOptions from './svg-export-options';

const exportFactory = {
    export: _export,
    exportGrid: exportGrid,
    exportChart: exportChart,
    download: download,
    fillCanvas: fillCanvas,
    extendCanvas: extendCanvas,
    appendLogo: appendLogo,
};

const MIME_TYPE = 'image/png';
const PNG = 'png';
const SVG = 'svg';
/**
 * @name _export
 * @param {object} params - The params containing download options
 *
 * @returns {Promise} Represents the success/failure of an export
 * @description
 * The main wrapper to trigger exports. Handles exports depending on the browser and download type as indicated by params.type.
 * Not supported: grid download with axis label images.
 */
function _export(params) {
    switch (params.type) {
        case SVG:
            return exportConstants.isIe ? svgExport.exportCompat(params) : svgExport.export(params);
        case PNG:
        default:
            return pngExport.export(params);
    }
}

/**
 * @param type
 * @param object
 * @param fileName
 */
function download(type, object, fileName) {
    fileName += '.' + type;
    switch (type) {
        case SVG:
            window.saveAs(new Blob(['<?xml version="1.0" standalone="no"?>\r\n', object.outerHTML], {
                type: 'image/svg+xml;charset=utf-8',
            }), fileName);
            object.remove();
            break;
        case PNG:
        default:
            object.toBlob(blob => {
                if (blob) {
                    window.saveAs(blob, fileName);
                }
                else {
                    //Fallback
                    let imgURL = object.toDataURL(MIME_TYPE),
                        dlLink = document.createElement('a');
                    dlLink.download = fileName;
                    dlLink.href = imgURL;
                    document.body.appendChild(dlLink);
                    dlLink.click();
                    document.body.removeChild(dlLink);
                }
                object.remove();
            }, MIME_TYPE);
            break;
    }
}

/**
 * @param canvas
 * @param previousHeight
 */
function fillCanvas(canvas, previousHeight) {
    let context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, previousHeight || 0, canvas.width, canvas.height);
}

/**
 * @param canvas
 * @param width
 * @param height
 */
function extendCanvas(canvas, width, height) {
    let oldctx = canvas.getContext('2d'),
        oldCanvas = oldctx.getImageData(0, 0, canvas.width, canvas.height);
    canvas.height += height;
    canvas.width = Math.max(width, canvas.width);
    canvas.getContext('2d').putImageData(oldCanvas, 0, 0);
    return canvas;
}

/**
 * @param canvas
 * @param scale
 */
function appendLogo(canvas, scale) {
    let logo = angular.copy(exportConstants.logo);
    logo.height *= scale;
    logo.width *= scale;
    return imageExportService.export(canvas, logo, canvas.width - logo.width, canvas.height - logo.height);
}

/**
 * @param type
 * @param options
 */
function exportGrid(type, options) {
    let deferred = [],
        yOffset = 0,
        exportOptions;
    options.object = options.object || getExportObject(type, options.elem, options.scale);
    options.type = type;
    options.isGridWrapper = true;
    exportOptions = getExportOptions(options);

    // Draw title and title image
    deferred.push(_export(exportOptions));
    yOffset = exportOptions.displayOptions.yOffset;
    // Draw charts
    _.forEach(options.elem.querySelectorAll('.chart.chart--grid-item'), (elem, idx) => {
        exportOptions = getExportOptions({
            type: type,
            elem: elem,
            scale: options.scale,
            row: idx,
            object: options.object,
        });
        exportOptions.displayOptions.yOffset = yOffset;
        deferred.push(_export(exportOptions));
        yOffset = exportOptions.displayOptions.yOffset;
    });
    return Promise.all(deferred).then(() => {
        if (type === SVG) {
            options.object.remove();
        }
        return options.object;
    });
}

/**
 * @param type
 * @param options
 */
function exportChart(type, options) {
    let isGridChart = _.isNumber(options.row),
        chart = isGridChart ? options.elem.parentElement.parentElement.parentElement : options.elem,
        exportOptions;
    options.object = options.object || getExportObject(type, options.elem, options.scale);
    options.type = type;
    options.elem = chart;
    exportOptions = getExportOptions(options);
    if (exportOptions.chartImage && isGridChart) {
        if (type === PNG) {
            options.object.height += exportOptions.chartImage.height + exportOptions.displayOptions.exportMargin;
        }
        else {
            options.object.setAttribute('height', parseFloat(options.object.getAttribute('height')) + exportOptions.chartImage.height + exportOptions.displayOptions.exportMargin);
        }
    }
    return _export(exportOptions).then(() => {
        if (type === SVG) {
            options.object.remove();
        }
        return options.object;
    });
}

/**
 * @param type
 * @param elem
 * @param scale
 */
function getExportObject(type, elem, scale) {
    let object;
    if (type === PNG) {
        object = createDocumentElement('canvas', {
            width: elem.clientWidth * scale,
            height: elem.clientHeight * scale,
        });
        fillCanvas(object);
        document.body.appendChild(object);
    }
    else if (type === SVG) {
        object = createSvgNode({
            width: elem.clientWidth * scale,
            height: elem.clientHeight * scale,
        });
        document.body.appendChild(object);
    }
    return object;
}

/**
 * @param options
 */
function getExportOptions(options) {
    let params = {
        scale: options.scale,
        yOffset: options.yOffset,
    };
    if (options.isGridWrapper) {
        params.isGridWrapper = true;
    }
    if (_.isNumber(options.row)) {
        params.row = options.row;
    }
    if (options.type === PNG) {
        params.canvas = options.object;
        return new PngExportOptions(options.elem, params);
    }
    params.svg = options.object;
    return new SvgExportOptions(options.elem, params);
}

export default exportFactory;
