import fastclick from 'fastclick';
import papaparse from 'papaparse';
import base64js from 'base64-js';
import * as datatables from 'datatables';
import 'text-encoding';
import 'operative';
import 'dom-to-image';
import 'lodash';
import 'jquery';
import 'jquery-ui';
import 'bootstrap/dist/js/bootstrap';
import 'mailcheck';
import 'angular';
import 'd3-timer';
import 'angular-animate';
import 'angular-cookies';
import 'angular-route';
import 'angular-sanitize';
import 'angular-dragdrop';
import 'angular-ui-bootstrap';
import '@uirouter/angularjs';
import 'angular-ui-sortable';
import 'jquery-autosize';
import 'angulartics';
import 'angulartics/dist/angulartics-ga.min';
import 'ng-file-upload';
import 'angularjs-slider';
import 'ui-select';

(function() {
    window.Papa = papaparse;
    window.FastClick = fastclick;
    window.base64js = base64js;
    window.datatables = datatables;
})();
