let _signUpSync = {
    email: '',
    password: '',
    name: '',
};

function sync(field, input) {
    _signUpSync[field] = input;
}

function get(field) {
    return _signUpSync[field];
}

function reset() {
    _signUpSync = {
        email: '',
        password: '',
        name: '',
    };
}

const authService = {
    sync: sync,
    get: get,
    reset: reset,
};

export default authService;
