angular.module('beaconPool', [])
    .service('targetingAttributeBeaconPool', targetingAttributeBeaconPool);

function targetingAttributeBeaconPool() {
    function toString(_req) {
        return 'Beacon Pool targeting applied';
    }

    function validationErrors(req) {
        return req.details.beaconPools.length ? [] : ['Please add at least one beacon pool'];
    }
    return {
        id: 'beaconPool',
        name: 'Beacon Pools',
        basic: false,
        advanced: false,
        description: 'Beacon Pool targeting',
        options: {},
        toString: toString,
        errors: validationErrors,
    };
}
