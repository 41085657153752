import './demography';
import './geography';
import './technography';
import './screener';

angular.module('targetingAttributeGroups.service', [
    'targetingAttributeGroup.demography',
    'targetingAttributeGroup.geography',
    'targetingAttributeGroup.technography',
    'targetingAttributeGroup.screener',
])
    .service('targetingAttributeGroups', targetingAttributeGroups);
targetingAttributeGroups.$inject = [
    'demography',
    'geography',
    'technography',
];

function targetingAttributeGroups(demography, geography, technography) {
    var groupList = [demography,
            geography,
            technography,
        ],
        attributes = {};

    _.forEach(groupList, function(group) {
        _.forEach(group.attributeList, function(attribute) {
            attributes[attribute.id] = attribute;
        });
    });

    return {
        groupList: groupList,
        attributes: attributes,
    };
}
