import '../../../../common/js/inc/sv-notify';
import '../beacons/api-beacon-service';
import Config from '../config';
import U from '../../../../common/js/util';

angular.module('beaconPoolEditor.modal', [
    'apiBeaconService', 'svNotify',
])
    .controller('BeaconPoolEditorModalCtrl', BeaconPoolEditorModalCtrl);
BeaconPoolEditorModalCtrl.$inject = [
    '$rootScope',
    '$uibModalInstance',
    'beaconService',
    '$notify',
    'tags',
    'beaconPools',
    'paginatedTags',
    'beaconPoolToEdit',
];

/**
 * Controller of the beacon pool modal
 *
 * @param {object} $rootScope - Access to application root scope (use caution!)
 * @param {object} $uibModalInstance - Modal instance
 * @param {object} beaconService - Service for beacon CRUD
 * @param {object} $notify - Notification service
 * @param {object[]} tags - List of tags
 * @param {object[]} beaconPools - List of beacon pools
 * @param {object[]} paginatedTags - List of tags of the current user
 * @param {object} beaconPoolToEdit - The beacon pool to edit
 */
function BeaconPoolEditorModalCtrl($rootScope, $uibModalInstance, beaconService, $notify, tags, beaconPools, paginatedTags, beaconPoolToEdit) {
    var BeaconPoolEditorVM = this;

    BeaconPoolEditorVM.beaconPoolToEdit = beaconPoolToEdit;
    BeaconPoolEditorVM.paginatedTags = paginatedTags;
    BeaconPoolEditorVM.beaconPools = beaconPools;
    BeaconPoolEditorVM.beaconTypes = Config.isUsRegion ? [
        'cookie',
        'mobile',
        'cookieAndMobile',
        'ipaddress',
    ] : ['ipaddress'];

    BeaconPoolEditorVM.closeModal = $uibModalInstance.close;
    BeaconPoolEditorVM.addBeaconToPool = addBeaconToPool;
    BeaconPoolEditorVM.removeBeaconFromPool = removeBeaconFromPool;
    BeaconPoolEditorVM.saveBeaconPool = saveBeaconPool;
    BeaconPoolEditorVM.filterForBeaconPoolEditor = filterForBeaconPoolEditor;
    BeaconPoolEditorVM.beaconTypeFilter = beaconTypeFilter;
    BeaconPoolEditorVM.nextPage = nextPage;
    BeaconPoolEditorVM.prevPage = prevPage;

    init();

    // Keep track of uuids for all beaconPoolBeacons (performance in filterForBeaconPoolEditor)
    function init() {
        BeaconPoolEditorVM.beaconPoolBeaconIds = {};
        _.forEach(BeaconPoolEditorVM.beaconPoolToEdit.beaconPoolBeacons, function(beaconPoolBeacon) {
            let uuid = beaconPoolBeacon.beaconDefinition.uuid;
            BeaconPoolEditorVM.beaconPoolBeaconIds[uuid] = uuid;
        });
    }

    /**
     * Add beacon to the beacon pool
     *
     * @param {object} beaconDef - The beacon definition
     * @param {object} beaconInclusion - The flag for inclusion
     */
    function addBeaconToPool(beaconDef, beaconInclusion) {
        let uuid = beaconDef.uuid;
        BeaconPoolEditorVM.beaconPoolToEdit.beaconPoolBeacons.push({
            beaconDefinition: beaconDef,
            beaconInclusion: beaconInclusion,
        });
        BeaconPoolEditorVM.beaconPoolBeaconIds[uuid] = uuid;
        if (BeaconPoolEditorVM.beaconPoolToEdit.beaconPoolBeacons.length === 1) {
            BeaconPoolEditorVM.beaconPoolToEdit.beaconTypeLocked = true;
        }
    }

    /**
     * Remove beacon from the beacon pool
     *
     * @param {object} beaconInfo - The beacon information
     * @param {number} idx - The index to remove
     */
    function removeBeaconFromPool(beaconInfo, idx) {
        let uuid = beaconInfo.beaconDefinition.uuid;
        delete BeaconPoolEditorVM.beaconPoolBeaconIds[uuid];
        BeaconPoolEditorVM.beaconPoolToEdit.beaconPoolBeacons.splice(idx, 1);
        if (BeaconPoolEditorVM.beaconPoolToEdit.beaconPoolBeacons.length === 0) {
            BeaconPoolEditorVM.beaconPoolToEdit.beaconTypeLocked = false;
        }
    }

    /**
     * Create or update the beacon pool
     *
     * @param {object} beaconPool - The beacon pool to save
     */
    function saveBeaconPool(beaconPool) {
        if (beaconPool.uuid) {
            // We know already that beaconPool is in the list due to the conditional
            let index = _.findIndex(BeaconPoolEditorVM.beaconPools, function(bp) {
                return beaconPool.uuid === bp.uuid;
            });
            beaconService.updateBeaconPool(BeaconPoolEditorVM.beaconPoolToEdit.uuid, BeaconPoolEditorVM.beaconPoolToEdit).then(function(updatedBeaconPool) {
                BeaconPoolEditorVM.beaconPools[index] = updatedBeaconPool;
                BeaconPoolEditorVM.closeModal();
            },
            function() {
                $notify.error('Error updating beacon pool.');
            });
        }
        else {
            U.extend(beaconPool, {
                userId: $rootScope.auth.user.id,
            });
            beaconService.createBeaconPool(beaconPool).then(function(newBeaconPool) {
                BeaconPoolEditorVM.beaconPools = BeaconPoolEditorVM.beaconPools || [];
                BeaconPoolEditorVM.beaconPools.push(newBeaconPool);
                BeaconPoolEditorVM.closeModal();
            },
            function() {
                $notify.error('Error creating beacon pool.');
            });
        }
    }

    /**
     * Filter out tags that are already selected
     *
     * @param {object} item - The beacon pool to filter
     * @returns {boolean} - true if beacon pool ID found in the list
     */
    function filterForBeaconPoolEditor(item) {
        if (!BeaconPoolEditorVM.beaconPoolToEdit.uuid) {
            // New beacon pool; all tags are eligible
            return true;
        }
        return !(item.uuid in BeaconPoolEditorVM.beaconPoolBeaconIds);
    }

    /**
     * Only show tags of the specified beaconType
     *
     * @param {object} item - The beacon type to filter
     * @returns {boolean} - true if type found in the list
     */
    function beaconTypeFilter(item) {
        return item && item.beaconType === BeaconPoolEditorVM.beaconPoolToEdit.beaconType;
    }

    function nextPage() {
        BeaconPoolEditorVM.paginatedTags = tags.next();
    }

    function prevPage() {
        BeaconPoolEditorVM.paginatedTags = tags.prev();
    }
}
