import '../common-components/pub-sub';
import '../filter-and-compare/filter-and-compare-model-manager';
import '../filter-and-compare/compare-service';
import {
    getStatsParams,
} from '../filter-and-compare/filter-service';
import './respondent-data-service';

angular.module('respondentData.view', [
    'pubSub',
    'respondentData.service',
    'filterAndCompare.modelManager',
    'compareService',
])
    .directive('respondentData', respondentData)
    .controller('RespondentDataCtrl', RespondentDataCtrl);

/**
 *
 */
function respondentData() {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/respondent-data.html',
        scope: {
            chartsRefreshing: '=',
        },
        controller: 'RespondentDataCtrl',
        controllerAs: 'RespondentDataVM',
    };
}
RespondentDataCtrl.$inject = [
    '$rootScope',
    '$scope',
    'pubSubService',
    'respondentDataService',
    'filterAndCompareModelManager',
    'compareService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param pubSubService
 * @param respondentDataService
 * @param filterAndCompareModelManager
 * @param compareService
 */
function RespondentDataCtrl($rootScope, $scope, pubSubService, respondentDataService, filterAndCompareModelManager, compareService) {
    const RespondentDataVM = this,
        params = {};

    RespondentDataVM.ux = {};
    RespondentDataVM.survey = $rootScope.survey;
    init();

    function init() {
        pubSubService.subscribe(['update-chart-data'], $scope.$id, getChartData);
        pubSubService.subscribe('respondents-get-tally', $scope.$id, getTally);
        filterAndCompareModelManager.initialize();
        pubSubService.notify('toggle-datasource');
        getChartData();
        respondentDataService.resetInitializedRespondentData();
        $scope.$on('$destroy', () => {
            pubSubService.destroy([
                'respondents-get-tally', 'update-chart-data',
            ], $scope.$id);
        });
    }

    function getChartData() {
        let beaconPool;
        params.filterModel = filterAndCompareModelManager.filterModel();
        params.collectionPeriods = filterAndCompareModelManager.collectionPeriodModel();
        params.comparisonData = filterAndCompareModelManager.comparisonData();
        params.parsedCriteria = filterAndCompareModelManager.parsedCriteria();
        params.tallyParams = params.comparisonData ? compareService.getComparisonParams(params) : getStatsParams(params);
        beaconPool = params.filterModel.Beacon$Pool || {};
        RespondentDataVM.respondentData = respondentDataService.getRespondentData(beaconPool.options);
        respondentDataService.getChartData(RespondentDataVM.survey, params, beaconPool.options);
    }

    /**
     * @param response
     */
    function getTally(response) {
        pubSubService.notify('get-tally-complete');
        pubSubService.notify('update-language', [response]);
    }
}
