import angular from 'angular';
import pubSubService from '../common-components/pub-sub';

class SvResize {
    constructor($timeout, $window, pubSubService) {
        const self = this;
        this.restrict = 'A';

        /* Dependency injections */
        this.$timeout = $timeout;
        this.pubSubService = pubSubService;
        /* Methods and variables */
        this.w = angular.element($window);
        this.debouncedOnResizeHandler = _.debounce(e => {
            self.onResizeHandler.call(self, e);
        }, 250);
        this.w.on('resize', this.debouncedOnResizeHandler);
        this.pubSubService.subscribe('resize-userInitiated', 'resize', this.debouncedOnResizeHandler);
    }
    onResizeHandler(event) {
        const self = this,
            data = {
                height: self.w.height(),
                width: self.w.width(),
            };
        this.$timeout(() => {
            if (event) {
                data.userInitiated = true;
            }
            // Firing from rootScope so all scopes get it
            self.pubSubService.notify('sv-window-resized', [event, data]);
        }, 0);
    }
}

function init($timeout, $window, pubSubService) {
    return new SvResize($timeout, $window, pubSubService);
}

init.$inject = [
    '$timeout',
    '$window',
    'pubSubService',
];

export default angular.module('svResize', [pubSubService.name])
    .directive('svResize', init);

