import exportPDF from './common-components/export-pdf';
angular.module('surveyMain.ctrl', [])
    .controller('SurveyMainCtrl', SurveyMainCtrl);
SurveyMainCtrl.$inject = [
    '$scope', '$rootScope',
];

function SurveyMainCtrl($scope, $rootScope) {
    $scope.htmlToPDFCustom = exportPDF.htmlToPDFCustom;
    $scope.htmlToPDFBasic = exportPDF.htmlToPDFBasic;

    // Survey pricing functions
    $scope.cpi = cpi;

    function cpi(survey) {
        var countryReq = null;

        survey = survey || $rootScope.survey;
        angular.forEach(survey.targetingReqs, function(req) {
            countryReq = req.attribute === 'country' ? req : countryReq;
        });

        return countryReq && (countryReq.details.type === 'international') ? $rootScope.funnel.cpi.international : $rootScope.funnel.cpi.us;
    }
}
