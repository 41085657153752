const defaultCuts = {
    Overall: {
        exposed: {
            $bool: true,
        },
        type: 'overall',
    },
    'Gender: Male': {
        exposed: {
            $eq: ['Demographic$Gender', 'Male'],
        },
    },
    'Gender: Female': {
        exposed: {
            $eq: ['Demographic$Gender', 'Female'],
        },
    },
    'Age: 13 to 17': {
        exposed: {
            $eq: ['Demographic$Age', '13 to 17'],
        },
    },
    'Age: 18 to 24': {
        exposed: {
            $eq: ['Demographic$Age', '18 to 24'],
        },
    },
    'Age: 25 to 34': {
        exposed: {
            $eq: ['Demographic$Age', '25 to 34'],
        },
    },
    'Age: 35 to 44': {
        exposed: {
            $eq: ['Demographic$Age', '35 to 44'],
        },
    },
    'Age: 45 to 54': {
        exposed: {
            $eq: ['Demographic$Age', '45 to 54'],
        },
    },
    'Age: 55 to 64': {
        exposed: {
            $eq: ['Demographic$Age', '55 to 64'],
        },
    },
    'Age: 65+': {
        exposed: {
            $eq: ['Demographic$Age', '65 and over'],
        },
    },
    'Age: 18 to 34': {
        exposed: {
            $or: [{
                $eq: ['Demographic$Age', '18 to 24'],
            }, {
                $eq: ['Demographic$Age', '25 to 34'],
            }],
        },
    },
    'Age: 35 to 54': {
        exposed: {
            $or: [{
                $eq: ['Demographic$Age', '35 to 44'],
            }, {
                $eq: ['Demographic$Age', '45 to 54'],
            }],
        },
    },
    'Age: 55+': {
        exposed: {
            $or: [{
                $eq: ['Demographic$Age', '55 to 64'],
            }, {
                $eq: ['Demographic$Age', '65 and over'],
            }],
        },
    },
    'Operating System: Windows': {
        exposed: {
            $eq: ['Operating$System', 'windows'],
        },
    },
    'Operating System: Android': {
        exposed: {
            $eq: ['Operating$System', 'android'],
        },
    },
    'Operating System: MacOS': {
        exposed: {
            $eq: ['Operating$System', 'macos'],
        },
    },
    'Operating System: iOS': {
        exposed: {
            $eq: ['Operating$System', 'ios'],
        },
    },
    'Operating System: Other': {
        exposed: {
            $eq: ['Operating$System', 'other'],
        },
    },
    'Operating System: Mobile': {
        exposed: {
            $or: [{
                $eq: ['Operating$System', 'ios'],
            }, {
                $eq: ['Operating$System', 'android'],
            }],
        },
    },
    'Operating System: Desktop': {
        exposed: {
            $or: [{
                $eq: ['Operating$System', 'windows'],
            }, {
                $eq: ['Operating$System', 'macos'],
            }],
        },
    },
    'Region: Northeast': {
        exposed: {
            $eq: ['Demographic$Region', 'Northeast'],
        },
    },
    'Region: Midwest': {
        exposed: {
            $eq: ['Demographic$Region', 'Midwest'],
        },
    },
    'Region: South': {
        exposed: {
            $eq: ['Demographic$Region', 'South'],
        },
    },
    'Region: West': {
        exposed: {
            $eq: ['Demographic$Region', 'West'],
        },
    },
    '1 Exposure': {
        exposed: {
            $eq: ['Frequency', 1],
        },
        type: 'overall',
    },
    '2-4 Exposures': {
        exposed: {
            $or: [{
                $eq: ['Frequency', 2],
            },
            {
                $eq: ['Frequency', 3],
            },
            {
                $eq: ['Frequency', 4],
            }],
        },
        type: 'overall',
    },
    '5-10 Exposures': {
        exposed: {
            $or: [{
                $eq: ['Frequency', 5],
            },
            {
                $eq: ['Frequency', 6],
            },
            {
                $eq: ['Frequency', 7],
            },
            {
                $eq: ['Frequency', 8],
            },
            {
                $eq: ['Frequency', 9],
            },
            {
                $eq: ['Frequency', 10],
            }],
        },
        type: 'overall',
    },
    '10+ Exposures': {
        exposed: {
            $ge: [
                'Frequency', 10,
            ],
        },
        type: 'overall',
    },
};

export default defaultCuts;
