import '../app-components/api-account-service';
import '../targeting-attributes/targeting-attribute-groups';
import '../survey/api-survey-service';
import '../survey/survey-to-string-service';
angular.module('surveyQuote.ctrl', ['targetingAttributeGroups.service'])
    .controller('SurveyQuoteCtrl', SurveyQuoteCtrl);
SurveyQuoteCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    '$timeout',
    'surveyService',
    'accountService',
    'targetingAttributeGroups',
    'STSS',
];

/**
 * @param $scope
 * @param $rootScope
 * @param $filter
 * @param $timeout
 * @param surveyService
 * @param accountService
 * @param TAGS
 * @param STSS
 */
function SurveyQuoteCtrl($scope, $rootScope, $filter, $timeout, surveyService, accountService, TAGS, STSS) {
    $scope.survey = $rootScope.survey;
    $scope.ux = {
        submitStatus: '',
        commentsStatus: '',
        audience: null,
        numQuestions: null,
        respondents: null,
        additionalComments: '',
        targetingStrs: null,
    };
    var screenerText = null,
        submittedAttachments = null,
        questionsCreated = false;

    // Get full/verbose survey and display info
    surveyService.getSurveyVerbose($scope.survey.uuid).then(function(survey) {
        $scope.survey = survey;

        // Check for screening questions
        if ($scope.survey.screeningQuestions && $scope.survey.screeningQuestions.length) {
            screenerText = '';
            angular.forEach($scope.survey.screeningQuestions, function(screener, idx) {
                screenerText += (idx + 1) + '. ' + STSS.questionToString(screener);
            });
        }
        // Determine what to display for target audience
        if ($scope.survey.surveyQuoteRequest && $scope.survey.surveyQuoteRequest.audienceUnsure) {
            $scope.ux.audience = '';
        }
        else if ($scope.survey.targetingDetails) {
            $scope.ux.audience = $scope.survey.targetingDetails;
        }
        else if ($scope.survey.targetingReqs) {
            $scope.ux.targetingStrs = [];
            _.forEach($scope.survey.targetingReqs, function(req) {
                var attrPrototype = TAGS.attributes[req.attribute];
                if (attrPrototype) {
                    $scope.ux.targetingStrs.push(attrPrototype.name + ': ' + attrPrototype.toString(req));
                }
            });
        }

        // Determine what to display for questions
        surveyService.getAttachments($scope.survey.uuid).then(function(attachments) {
            if ($scope.survey.questions && $scope.survey.questions.length) {
                $scope.ux.numQuestions = $scope.survey.questions.length;
                questionsCreated = true;
            }
            else if (attachments.length) {
                $scope.ux.numQuestions = '*Document provided*';
                submittedAttachments = attachments;
            }
            else if ($scope.survey.surveyQuoteRequest.approximateQuestionLength) {
                $scope.ux.numQuestions = $scope.survey.surveyQuoteRequest.approximateQuestionLength;
            }
            else {
                $scope.ux.numQuestions = '';
            }

            // Submit the quote request immediately
            $scope.submitQuoteRequest();
        });

        // Display additional comments if any were previously submitted
        $scope.ux.additionalComments = $scope.survey.surveyQuoteRequest.additionalComments || '';
    });

    // Return appropriate sample size text
    $scope.sampleSize = function sampleSize() {
        if ($scope.survey.surveyQuoteRequest && $scope.survey.surveyQuoteRequest.respondentsUnsure) {
            return 'I&rsquo;m not sure yet';
        }
        else if ($scope.survey.latestCollectionPeriod.sampleSize) {
            var extraDetails = '';
            var types = {
                daily: 'days',
                weekly: 'weeks',
                monthly: 'months',
                quarterly: 'quarters',
                yearly: 'years',
                other: 'cycles',
            };
            return '' + $scope.survey.latestCollectionPeriod.sampleSize + ' respondents' + extraDetails;
        }
        return 'I&rsquo;m not sure yet';
    };

    /**
     * @param date
     */
    function formatShortDate(date) {
        return $filter('date')(date, 'shortDate');
    }

    // Return appropriate targeting reqs text
    $scope.targeting = function() {
        if ($scope.ux.targetingStrs && $scope.ux.targetingStrs.length) {
            var targetingStr = '';
            angular.forEach($scope.ux.targetingStrs, function(str, idx) {
                targetingStr += (idx ? ' * ' : '* ') + str;
            });
            return targetingStr;
        }
        else if ($scope.ux.audience) {
            return $scope.ux.audience;
        }
        return 'unsure';
    };

    var questionsText = function() {
        var text = '';

        if (submittedAttachments && submittedAttachments.length) {
            angular.forEach(submittedAttachments, function(a, idx) {
                text += a.url + ' ';
            });
        }
        else {
            text = $scope.ux.numQuestions + (questionsCreated ? ' (created)' : '');
        }
        return text;
    };

    // Update quote request with additional comments
    $scope.saveComments = function() {
        $scope.ux.commentsStatus = 'submitting';

        surveyService.updateQuoteRequest($rootScope.survey.uuid, {
            additionalComments: $scope.ux.additionalComments,
        }).then(function() {
            $timeout(function() {
                $scope.ux.commentsStatus = 'success';
            }, 750);
        });
    };

    // Submit the quote request
    $scope.submitQuoteRequest = function submitQuoteRequest() {
        var comment = 'TARGETING: ' + $scope.targeting() +
            (screenerText ? '<br>SCREENERS: ' + screenerText : '') +
            '<br>RESPONDENTS: ' + $scope.sampleSize() +
            '<br>QUESTIONS: ' + questionsText() +
            '<br>ADDITIONAL INFO: ' + $scope.ux.additionalComments;

        // Fire these before submitting to server, in case that fails
        U.fireQuotePixels($scope.auth.user.email);

        // Submit to server
        $scope.ux.submitStatus = 'submitting';
        accountService.sendEmail('quote-requested', {
            surveyUuid: $scope.survey.uuid,
            quoteInfoText: comment,
        });
        surveyService.updateSurvey($rootScope.survey.uuid, {
            quoteRequested: 'true',
        })
            .then(function() {
                $rootScope.survey.quoteRequested = true;
                $timeout(function() {
                    $scope.ux.submitStatus = 'success';
                }, 250);
            },
            function(error) {
                $scope.ux.submitStatus = 'error';
            });
    };
}
