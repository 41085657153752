angular.module('common.selectAll', [])
    .directive('selectAllInput', selectAll)
    .controller('SelectAllCtrl', SelectAllCtrl);

function selectAll() {
    return {
        restrict: 'E',
        replace: true,
        template: '<input class="form-check-input" type="checkbox" ng-model="SelectAllVM.ux.selectAll" ng-change="SelectAllVM.toggleSelectAll()" ng-disabled="SelectAllVM.disabled()"/>',
        controller: 'SelectAllCtrl',
        controllerAs: 'SelectAllVM',
        scope: {
            list: '=',
            ux: '=',
            disabled: '<?',
            filter: '@',
        },
    };
}
SelectAllCtrl.$inject = ['$scope', '$filter'];

function SelectAllCtrl($scope, $filter) {
    var SelectAllVM = this;
    SelectAllVM.ux = $scope.ux;
    SelectAllVM.disabled = $scope.disabled || (() => {
        return false;
    });
    SelectAllVM.toggleSelectAll = toggleSelectAll;

    function toggleSelectAll() {
        let list = $scope.filter ? $filter('filter')($scope.list, $scope.filter) : $scope.list;
        _.forEach(list, function(factor) {
            if (SelectAllVM.ux.selectAll) {
                factor.set = SelectAllVM.ux.selectAll;
                SelectAllVM.ux.numSelected++;
            }
            else {
                delete factor.set;
            }
        });
        if (!SelectAllVM.ux.selectAll) {
            SelectAllVM.ux.numSelected = 0;
        }
    }
}
