const frequencyCuts = {
    category: 'Frequency',
    categoryType: 'timeBased',
    type: {
        value: 'all',
        name: 'Overall',
    },
    controlType: 'Overall',
    optionColumns: 3,
    options: [{
        name: '1',
        value: '1 Exposure',
        exposed: {
            $eq: ['Frequency', 1],
        },
        column: 1,
    },
    {
        name: '2',
        value: '2 Exposures',
        exposed: {
            $eq: ['Frequency', 2],
        },
        column: 1,
    },
    {
        name: '3',
        value: '3 Exposures',
        exposed: {
            $eq: ['Frequency', 3],
        },
        column: 1,
    },
    {
        name: '4',
        value: '4 Exposures',
        exposed: {
            $eq: ['Frequency', 4],
        },
        column: 1,
    },
    {
        name: '5+',
        value: '5+ Exposures',
        exposed: {
            $ge: ['Frequency', 5],
        },
        column: 3,
    },
    {
        name: '10+',
        value: '10+ Exposures',
        exposed: {
            $ge: ['Frequency', 10],
        },
        column: 3,
    },
    {
        name: '1-2',
        value: '1-2 Exposures',
        exposed: {
            $or: [
                {
                    $eq: ['Frequency', 1],
                }, {
                    $eq: ['Frequency', 2],
                },
            ],
        },
        column: 2,
    },
    {
        name: '3-4',
        value: '3-4 Exposures',
        exposed: {
            $or: [
                {
                    $eq: ['Frequency', 3],
                }, {
                    $eq: ['Frequency', 4],
                },
            ],
        },
        column: 2,
    },
    {
        name: '5-9',
        value: '5-9 Exposures',
        exposed: {
            $or: [
                {
                    $eq: ['Frequency', 5],
                },
                {
                    $eq: ['Frequency', 6],
                },
                {
                    $eq: ['Frequency', 7],
                },
                {
                    $eq: ['Frequency', 8],
                },
                {
                    $eq: ['Frequency', 9],
                },
            ],
        },
        column: 2,
    }],
};
export default frequencyCuts;
