angular.module('operatingSystem.editorView', ['operatingSystem.options'])
    .directive('operatingSystemEditor', operatingSystemEditor)
    .controller('OperatingSystemEditorCtrl', OperatingSystemEditorCtrl);

function operatingSystemEditor() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/target/operating-system-editor.html',
        scope: {
            requirement: '=',
        },
        link: function(scope, _el, _attr, OperatingSystemEditorVM) {
            scope.$watch('requirement.details', updateOptionsObjectFromReqDetailsArray, true);
            scope.$watch('OperatingSystemEditorVM.options', updateReqDetailsArrayFromOptionsObject, true);

            updateOptionsObjectFromReqDetailsArray();

            function updateOptionsObjectFromReqDetailsArray() {
                _.forEach(OperatingSystemEditorVM.options, function(e) {
                    e.selected = !!scope.requirement.details && (scope.requirement.details.indexOf(e.id) !== -1);
                });
            }

            function updateReqDetailsArrayFromOptionsObject() {
                var x = [];
                _.forEach(OperatingSystemEditorVM.options, function(e) {
                    if (e.selected) {
                        x.push(e.id);
                    }
                });
                scope.requirement.details = x;
            }
        },
        controller: 'OperatingSystemEditorCtrl',
        controllerAs: 'OperatingSystemEditorVM',
    };
}
OperatingSystemEditorCtrl.$inject = [
    '$scope', 'operatingSystemOptions',
];

function OperatingSystemEditorCtrl($scope, operatingSystemOptions) {
    var OperatingSystemEditorVM = this;
    $scope.requirement.details = $scope.requirement.details || [];
    OperatingSystemEditorVM.setAll = setAll;
    OperatingSystemEditorVM.options = angular.copy(operatingSystemOptions.list);

    function setAll(val) {
        _.forEach(OperatingSystemEditorVM.options, function(e) {
            e.selected = val;
        });
    }
}
