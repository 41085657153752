import _ from 'lodash';
var list = [{
        code: 'US',
        country: 'United States',
        basic: true,
        extended: true,
        advanced: true,
        cities: [{
            name: 'Atlanta',
            id: 'atl',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Austin',
            id: 'aus',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Baltimore',
            id: 'bal',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Boston',
            id: 'bos',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Buffalo',
            id: 'buf',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Charleston',
            id: 'csc',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Charlotte',
            id: 'car',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Chicago',
            id: 'chi',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Cincinnati',
            id: 'cin',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Cleveland',
            id: 'cle',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Columbus',
            id: 'col',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Dallas',
            id: 'dfw',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Des Moines',
            id: 'des',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Denver',
            id: 'den',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Detroit',
            id: 'det',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Grand Rapids, MI',
            id: 'grm',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Hartford',
            id: 'hart',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Houston',
            id: 'houston',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Indianapolis',
            id: 'ind',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Kansas City',
            id: 'kc',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Lansing',
            id: 'lan',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Las Vegas',
            id: 'lv',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Los Angeles',
            id: 'la',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Louisville',
            id: 'lou',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Miami',
            id: 'mia',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Milwaukee',
            id: 'mil',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Minneapolis',
            id: 'mn',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Nashville',
            id: 'nash',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'New Orleans',
            id: 'nola',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'New York',
            id: 'nyc',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Oklahoma City',
            id: 'okc',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Orlando',
            id: 'orl',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Philadelphia',
            id: 'phi',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Phoenix',
            id: 'phx',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Pittsburgh',
            id: 'pit',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Portland',
            id: 'por',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Raleigh',
            id: 'ral',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Sacramento',
            id: 'sac',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Salt Lake City',
            id: 'slc',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'San Antonio',
            id: 'sat',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'San Diego',
            id: 'sd',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'San Francisco',
            id: 'sfo',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'Seattle',
            id: 'sea',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'St. Louis',
            id: 'stl',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Tampa',
            id: 'tam',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Tucson',
            id: 'tuc',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Tulsa',
            id: 'tok',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        },
        {
            name: 'Waco, TX',
            id: 'waco',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Washington D.C.',
            id: 'dc',
            basic: true,
            extended: true,
            advanced: true,
            selected: false,
        },
        {
            name: 'West Palm Beach-Ft. Pierce',
            id: 'wpb',
            basic: false,
            extended: false,
            advanced: true,
            selected: false,
        }],
    }, {
        code: 'CA',
        country: 'Canada',
        basic: false,
        extended: false,
        advanced: false,
        cities: [{
            name: 'Calgary',
            id: 'cal',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Edmonton',
            id: 'edm',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Hamilton',
            id: 'ham',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Kitchener',
            id: 'kit',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Montreal',
            id: 'mon',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Ottawa',
            id: 'ott',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Quebec',
            id: 'que',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Toronto',
            id: 'tor',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Vancouver',
            id: 'van',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        },
        {
            name: 'Winnipeg',
            id: 'win',
            basic: false,
            extended: false,
            advanced: false,
            selected: false,
        }],
    }],
    map = {},
    countryMap = {};

_.forEach(list, function(country) {
    _.forEach(country.cities, function(e) {
        map[e.id] = e;
        countryMap[e.id] = country.country;
    });
});

const metroOptions = {
    list,
    map,
    countryMap,
};

export default metroOptions;
