export default angular.module('collapsiblePanel', [])
    .directive('collapsiblePanel', collapsiblePanel);

function collapsiblePanel() {
    return {
        templateUrl: '/shared-templates/collapsible-panel.html',
        restrict: 'E',
        transclude: true,
        scope: {
            heading: '@?',
            id: '@?',
        },
    };
}
