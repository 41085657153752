import {
    convertPixelToFloat, setComputedStyles,
} from './export-service';
import ClickmapExportOptions from './clickmap-export-options';

const BASE_EXPORT_MARGIN = 25;
const BASE_ELEMENT_MARGIN = BASE_EXPORT_MARGIN / 2 + 10;
class ChartImageOptions {
    constructor(img, scale) {
        this.src = img.src;
        this.width = img.width * scale;
        this.height = img.height * scale;
    }
}

class TextExportOptions {
    constructor(elem, scale) {
        let options = {
                height: 0,
                width: 0,
            },
            lines = elem.childElementCount ? elem.querySelectorAll('p') : [elem];
        this.lines = getTextFromElem(lines, scale, options);
        this.height = options.height;
        this.width = options.width;
    }
}

function getTextFromElem(textArray, scale, options) {
    let lines = [];
    _.forEach(textArray, line => {
        let styles = window.getComputedStyle(line);
        options.height += line.offsetHeight * scale;
        options.width = Math.max(line.offsetWidth * scale, options.width);
        lines.push({
            text: line.innerText,
            height: line.offsetHeight * scale,
            width: line.offsetWidth * scale,
            fontSize: convertPixelToFloat(styles.fontSize) * scale,
            fontWeight: convertPixelToFloat(styles.fontWeight),
            color: styles.color,
            underline: styles.textDecoration.indexOf('underline') > -1,
            italic: styles.textDecoration.indexOf('italic') > -1,
            textAnchor: styles.textAlign === 'center' ? 'middle' : 'start',
        });
    });
    return lines;
}
export default class SvgExportOptions {
    constructor(elem, params) {
        let footerText = elem.querySelector('.chart-text'),
            titles = elem.querySelectorAll('.chart__title'),
            chartImage = titles[0].nextElementSibling,
            chart = elem.querySelector('.chart.plottable'),
            scale = params.scale || 1;
        if (!params.svg) {
            console.error('An svg node is required');
            return;
        }
        this.type = 'svg';
        this.scale = scale;
        this.displayOptions = {
            yOffset: 0,
            elementMargin: BASE_EXPORT_MARGIN * scale,
            exportMargin: BASE_ELEMENT_MARGIN * scale,
        };
        this.svg = params.svg;
        this.title = new TextExportOptions(titles[0], this.scale);
        if (chartImage) {
            if (chartImage.firstElementChild.tagName === 'IMG') {
                this.chartImage = new ChartImageOptions(chartImage.firstElementChild, this.scale);
            }
            else {
                this.clickmap = _.map(chartImage.querySelectorAll('.clickmap-chart'), elem => {
                    return new ClickmapExportOptions(elem, scale, params.svg.width);
                });
            }
        }
        if (!params.isGridWrapper) {
            if (titles.length > 1) {
                let subtitle = titles[params.row + 1];
                this.subtitle = new TextExportOptions(subtitle, this.scale);
            }
            if (chart) {
                this.svgChart = setComputedStyles(chart, chart.cloneNode(true), this.scale, true);
            }
            if (footerText) {
                this.footerText = new TextExportOptions(footerText, this.scale);
            }
        }
    }
}
