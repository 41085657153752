import 'es6-promise/auto';
/*
    Using this polyfill instead of the one provided by babel because this one
    automatically updates the window object when Promises are not supported by
    that browser e.g. ie11
 */

export default function loadPolyfills() {
    const fillFetch = () => new Promise(resolve => {
        if ('fetch' in window) {
            return resolve();
        }

        require.ensure([], require => {
            require('whatwg-fetch');
            /* Console.info required for ie 11 */
            // console.info('require:', require);

            resolve();
        }, 'fetch');
    });

    const fillCoreJs = () => new Promise(resolve => {
        if (
            'startsWith' in String.prototype &&
            'endsWith' in String.prototype &&
            'includes' in Array.prototype &&
            'assign' in Object &&
            'keys' in Object
        ) {
            return resolve();
        }

        require.ensure([], require => {
            require('core-js');

            resolve();
        }, 'core-js');
    });

    return Promise.all([
        fillFetch(), fillCoreJs(),
    ]);
}
