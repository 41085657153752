import '@uirouter/angularjs';
import '../../../../../common/js/inc/sv-notify';
import '../../app-components/api-account-service';
import '../../common-components/pub-sub';
import '../../survey/api-survey-service';
import surveyConstants from '../../survey/survey-constants';
import '../../survey/survey-statuses';
import Config from '../../config';

angular.module('userAccount.ctrl', [
    'pubSub',
    'apiSurveyService',
    'apiAccountService',
])
    .controller('UserAccountCtrl', UserAccountCtrl);
UserAccountCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$notify',
    '$timeout',
    '$filter',
    '$window',
    '$state',
    '$transitions',
    'accountService',
    'surveyService',
    'surveyStatusesService',
    'pubSubService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $notify
 * @param $timeout
 * @param $filter
 * @param $window
 * @param $state
 * @param $transitions
 * @param accountService
 * @param surveyService
 * @param surveyStatusesService
 * @param pubSubService
 */
function UserAccountCtrl($rootScope, $scope, $notify, $timeout, $filter, $window,
    $state, $transitions, accountService, surveyService, surveyStatusesService, pubSubService) {
    $scope.ux = {
        user: null,
        userStatus: null,
        password: null,
        checkErrors: false,
        ready: false,
        surveysLoaded: false,
        showApiToken: false,
        currentView: $state.current.name.split('.')[1],
        surveyEditingMode: false,
        surveyListSearchQuery: '',
        tempNewSurveyName: '',
        savingSurveyEdit: false,
        sortFilterSettings: null,
        screenSizeChecked: false,
        searchFieldVisibility: false,
        searchResultCount: null,
        showCardView: null, // Necessary for small screens since table view doesn't translate very well
        surveyListHeaders: [{
            name: 'Survey Name',
            sortable: true,
            id: 'name',
            sortDirection: null,
        },
        {
            name: 'Status',
            sortable: true,
            id: '_status',
            sortDirection: null,
        },
        {
            name: 'Date Created',
            sortable: true,
            id: '_created_date',
            sortDirection: null,
        },
        {
            name: 'Tags',
            sortable: true,
            id: '_tags',
            sortDirection: null,
        },
        {
            name: 'Actions',
            sortable: false,
            id: 'actions',
            sortDirection: null,
        }],
    };
    $scope.appData = {
        currentUser: null,
    };
    $scope.userSurveys = [];
    $scope.sharedSurveys = [];
    $scope.allUserViewableSurveys = [];
    $scope.isUsRegion = Config.isUsRegion;

    $scope.priorityOrder = priorityOrder;
    $scope.deleteDraft = deleteDraft;
    $scope.forceNewSurvey = forceNewSurvey;
    $scope.surveyLink = surveyLink;
    $scope.editUser = editUser;
    $scope.saveUser = saveUser;
    $scope.editPassword = editPassword;
    $scope.savePassword = savePassword;
    $scope.duplicateSurvey = duplicateSurvey;
    $scope.openSaveNameModal = openSaveNameModal;
    $scope.saveSurveyLocal = saveSurveyLocal;
    $scope.enterEditMode = enterEditMode;
    $scope.cancelEditMode = cancelEditMode;
    $scope.toggleSort = toggleSort;
    $scope.stopEventPropagation = stopEventPropagation;
    $scope.toggleSearchInputVisibility = toggleSearchInputVisibility;
    $scope.searchResultList = searchResultList;
    $scope.toggleSideNavWidth = toggleSideNavWidth;

    init();

    function init() {
        $transitions.onStart({}, function(transition) {
            $scope.ux.currentView = transition.$to().name.split('.')[1];
        });
        // Check if the user has been authenticated before fetching their data
        if ($rootScope.auth && $rootScope.auth.user) {
            refreshUserData();
        }
        // Else {
        //     // On sign in success, fetch all of the user's data
        //     $rootScope.$on('signInSuccess', function() {
        //         refreshUserData();
        //     });
        // }
        pubSubService.subscribe('sv-window-resized', $scope.$id, verifyViewportBreakpoints);

        // Trigger initial screen size check
        var w = angular.element($window);
        verifyViewportBreakpoints(null, {
            height: w.height(),
            width: w.width(),
        });

        $timeout(function() {
            if ($rootScope.auth.user && !$rootScope.auth.user.anonymous && !$rootScope.auth.user.firstName) {
                $notify.warning('Please update your account with your name and phone number. Thanks!', {
                    timeout: 3000,
                });
            }
            $scope.ux.ready = true;
        }, 1000);

        $scope.$watch('ux.accountView', function(newView, oldView) {
            if (oldView && !newView) {
                $state.go('signIn');
            }
        });

        $scope.$watch(function() {
            var groupedSurveys;

            $scope.ux.filteredUserViewableSurveys = $scope.$eval('allUserViewableSurveys | orderBy: ux.sortFilterSettings | filter: ux.surveyListSearchQuery');

            // Group filtered surveys by owner for OWN and SHARED survey lists
            groupedSurveys = _.groupBy($scope.ux.filteredUserViewableSurveys, function(surveyItem) {
                return surveyItem.user.id === $scope.auth.user.id;
            });
            $scope.ux.filteredOwnSurveys = groupedSurveys.true;
            $scope.ux.filteredSharedSurveys = groupedSurveys.false;
        });

        $scope.$on('$destroy', () => {
            pubSubService.destroy('sv-window-resized', $scope.$id);
        });
    }

    // Augment every survey item with normalized status, tags(owner/collaborator)
    /**
     * @param surveys
     */
    function _normalizeSurveyListing(surveys) {
        if (Array.isArray(surveys)) {
            return _.map(surveys, _augmentSurveyObj);
        }
        return _augmentSurveyObj(surveys);
    }

    /**
     * @param surveyItem
     */
    function _augmentSurveyObj(surveyItem) {
        surveyItem._tags = [$filter('uppercase')(surveyItem.user.id === $scope.auth.user.id ? 'Owner' : 'Collaborator')];
        surveyItem._status = surveyStatusesService.getStatus(surveyItem).toUpperCase();
        surveyItem._created_date = surveyItem.created;

        return surveyItem;
    }

    /**
     * @param survey
     */
    function _addSurveysToInclusiveSurveyList(survey) {
        if (Array.isArray(survey)) {
            // $scope.allUserViewableSurveys
            _.forEach(survey, function(value) {
                // Since we are getting an array we take the value
                $scope.allUserViewableSurveys.push(_normalizeSurveyListing(value));
            });
        }
        else {
            $scope.allUserViewableSurveys.push(_normalizeSurveyListing(survey));
        }
    }

    // Refresh the user's data (surveys, beacons)
    function refreshUserData() {
        $scope.ux.surveysLoaded = false;

        // Check for viewport size to minimize chance of style flicker

        surveyService.listSurveys().then(function(surveys) {
            $scope.$evalAsync(() => {
                $scope.userSurveys = surveys;
                $scope.ux.ready = true;
                $scope.ux.surveysLoaded = true;
            });

            _addSurveysToInclusiveSurveyList(surveys);
        });

        surveyService.listSharedSurveys().then(function(sharedSurveys) {
            $scope.sharedSurveys = sharedSurveys;

            // Add in shared surveys to list of all surveys
            _addSurveysToInclusiveSurveyList($scope.sharedSurveys);
        });

        $scope.appData.currentUser = $rootScope.auth.user;
    }

    /**
     * @param survey
     */
    function priorityOrder(survey) {
        // Ensures 'Running' surveys are listed first -- the rest are ordered as they would be naturally
        return surveyStatusesService.getStatus(survey).toLowerCase() !== 'running';
    }

    // Delete a survey draft and give the user a chance to undo/restore
    /**
     * @param survey
     * @param $event
     */
    function deleteDraft(survey, $event) {
        if ($event) {
            $event.preventDefault();
        }

        survey.hide = true; // Hide the survey card from the list

        function finishDelete() {
            surveyService.deleteSurvey(survey.uuid).then(function(_response) {
                // Do nothing if it was successful
            }, function() {
                delete survey.hide; // If there was an error, show the survey again...
            });
        }
        $notify.success('Survey ' + survey.customerSurveyId + ' deleted', {
            button: {
                text: 'Undo',
                callback: function() {
                    delete survey.hide; // Show the survey card again
                },
            },
            onTimeout: finishDelete,
            onDismiss: finishDelete,
        });
    }

    // This is used before clicking a link into the survey creation flow and
    // sets some of the variables back to their initial states
    $rootScope.primeSurvey = primeSurvey;

    /**
     * @param modalSelector
     */
    function primeSurvey(modalSelector) {
        $rootScope.survey = null;
        $rootScope.dashboardView = false;
        $rootScope.isReady = false;
        $rootScope.funnel = {};
        $rootScope.funnelType = '';

        if (modalSelector) {
            $('#packages-modal').modal('hide');
        }
    }

    function forceNewSurvey() {
        $rootScope.forceNewSurvey = true;
    }

    /**
     * @param survey
     * @param path
     */
    function surveyLink(survey, path) {
        var params = {
                surveyId: survey.uuid,
            },
            to = 'target',
            funnelType;
        if (survey.draftCompleted || survey.quoteRequested) {
            to = 'dashboard' + (path ? '.' + path : '');
        }
        else {
            if (survey.surveyPackage === surveyConstants.PACKAGES.ADVANCED) {
                funnelType = 'quote';
            }
            else if (survey.surveyPackage === surveyConstants.PACKAGES.EXTENDED) {
                funnelType = 'extended';
            }
            params.funnelType = funnelType;
            if (survey.numQuestions) {
                to = 'design';
            }
        }
        return $state.href(to, params, {
            absolute: true,
        });
    }

    function editUser() {
        $scope.ux.user = angular.copy($scope.auth.user);

        if (!$scope.ux.user.firstName && !$scope.ux.user.lastName) {
            var name = U.splitName($scope.ux.user.name);
            $scope.ux.user.firstName = name.first;
            $scope.ux.user.lastName = name.last;
        }

        $scope.ux.userStatus = 'before';
        $scope.ux.checkErrors = false;
    }

    /**
     * @param ngFormCtrl
     */
    function saveUser(ngFormCtrl) {
        if (!ngFormCtrl.$valid) {
            $scope.ux.checkErrors = true;
        }
        else {
            var userUpdate = {
                firstName: $scope.ux.user.firstName || '',
                lastName: $scope.ux.user.lastName || '',
                phone: $scope.ux.user.phone || '',
                company: $scope.ux.user.company || '',
            };

            $scope.ux.userStatus = 'during';
            accountService.updateUser($scope.auth.user.id, userUpdate).then(function() {
                $scope.ux.userStatus = 'success';
                $rootScope.auth.user.firstName = $scope.ux.user.firstName;
                $rootScope.auth.user.lastName = $scope.ux.user.lastName;
                $rootScope.auth.user.phone = $scope.ux.user.phone;
                $rootScope.auth.user.company = $scope.ux.user.company;
                $scope.ux.user = null;
            });
        }
    }

    function editPassword() {
        $scope.ux.password = {
            currentPassword: '',
            newPassword: '',
            status: 'before',
        };
        $scope.ux.checkErrors = false;
    }

    /**
     * @param ngFormCtrl
     */
    function savePassword(ngFormCtrl) {
        if ($scope.ux.password.status === 'during') {
            return;
        }

        if (!ngFormCtrl.$valid) {
            $scope.ux.checkErrors = true;
        }
        else {
            $scope.ux.password.status = 'during';

            accountService.changePassword($scope.ux.password.currentPassword, $scope.ux.password.newPassword)
                .then(function() {
                    $scope.ux.password.status = 'success';
                    $timeout(function() {
                        $scope.ux.password = null;
                    }, 500);
                }, function() {
                    $scope.ux.password.status = 'error';
                });
        }
    }

    /**
     * @param survey
     * @param $index
     * @param $event
     */
    function duplicateSurvey(survey, $index, $event) {
        if ($event) {
            $event.preventDefault();
            // $event.stopPropagation();

            // hide drop down menu since it's open we are toggling it shut
            $timeout(function() {
                $($event.target).parents('.dropdown').dropdown('toggle');
            });
        }

        surveyService.duplicateSurvey(survey.uuid).then(function(result) {
            // Mark new survey as new -- only new for current list
            result.__isNew = true;

            $scope.userSurveys.push(result);
            // Insert new item after the one it duplicates (kind of fudges with the sort, but clearer to the user)
            $scope.allUserViewableSurveys.splice($index + 1, 0, _normalizeSurveyListing(result));
        });
    }

    /**
     * @param survey
     * @param evt
     */
    function openSaveNameModal(survey, evt) {
        evt.preventDefault();
        evt.stopPropagation();
        $scope.surveyDraft = {
            name: survey.name || '',
            surveyObj: survey,
        };
        $('.save-name-modal').modal({});
    }

    /**
     * @param newName
     * @param survey
     * @param $event
     */
    function saveSurveyLocal(newName, survey, $event) {
        if ($event) {
            $event.stopPropagation();
        } // Has to be checked because survey name modal could call this and the $event is null

        if (newName !== survey.name) {
            $scope.ux.savingSurveyEdit = true;

            $rootScope.saveSurvey(newName, survey.uuid).then(function() {
                survey.__isNew = null; // Remove new setting on survey if the name is changed -- won't hurt survey's that aren't new
                survey.name = newName;
                $scope.ux.savingSurveyEdit = false;
            });
        }
        $scope.cancelEditMode(survey);
    }

    /* Survey edit */
    /**
     * @param survey
     * @param $event
     */
    function enterEditMode(survey, $event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation(); // Stop click from getting passed to table row for primeSurvey
        }

        $scope.ux.tempNewSurveyName = survey.name; // Prefill edit field with current survey name

        // hide drop down menu since it's open we are toggling it shut
        $timeout(function() {
            $($event.target).parents('.dropdown').dropdown('toggle');
        });

        // Put table and row into edit mode
        $scope.ux.surveyEditingMode = survey._isEditing = true;
    }

    /**
     * @param survey
     * @param $event
     */
    function cancelEditMode(survey, $event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation(); // Stop click from getting passed to table row for primeSurvey
        }

        $scope.ux.surveyEditingMode = survey._isEditing = false;
    }
    /* End survey edit */

    /**
     * @param colHeader
     * @param $event
     */
    function toggleSort(colHeader, $event) {
        if ($event) {
            $event.preventDefault();
        }

        // Null = no sorting
        // 1 = sort ascending
        // -1 = sort descending
        if (colHeader.sortDirection === null) {
            // If no sort sort ascending
            colHeader.sortDirection = 1;
        }
        else if (colHeader.sortDirection == 1) {
            // If sort ascending sort descending
            colHeader.sortDirection = -1;
        }
        else {
            // Remove sorting altogether
            colHeader.sortDirection = null;
        }

        // Clear sortDire for all other columns filters
        _.forEach($scope.ux.surveyListHeaders, function(surveyListHeader) {
            if (surveyListHeader.id !== colHeader.id) {
                surveyListHeader.sortDirection = null;
            }
        });

        // Set filter string for survey listing
        $scope.ux.sortFilterSettings = (colHeader.sortDirection > 0 ? '+' : '-') + colHeader.id;
    }

    /**
     * @param $event
     * @param preventDefault
     */
    function stopEventPropagation($event, preventDefault) {
        if (preventDefault) {
            // Sometimes we want the default to not happen
            $event.preventDefault();
        }
        $event.stopPropagation();
    }

    function toggleSearchInputVisibility() {
        $scope.ux.searchFieldVisibility = !$scope.ux.searchFieldVisibility;
    }

    function searchResultList() {

    }

    function toggleSideNavWidth() {
        $scope.ux.sideNavExpanded = !$scope.ux.sideNavExpanded;
        $timeout(function() {
            // Since we are resizing the viewable area, we tell all
            // resize event handlers fire
            pubSubService.notify('resize-userInitiated');
        }, 100);
    }

    /*
        Checks to see if any of the given breakpoints have been met in order to update the UI
        -userInitiated, a resize action that is user initiated from within the viewport
    */
    /**
     * @param event
     * @param data
     */
    function verifyViewportBreakpoints(event, data) {
        var widthToSwitchToCardView = 770;

        if (!data.userInitiated && $rootScope.isViewportSizeSmall(data)) {
            $scope.ux.sideNavExpanded = false;
        }

        // Switch to card view regardless of who (browser or user) initiated the viewport size
        $scope.ux.showCardView = data.width <= widthToSwitchToCardView;

        // Set screen size as checked
        $scope.ux.screenSizeChecked = true;
    }
}
