import 'angulartics/dist/angulartics-ga.min';

import Config from './config';
import run from './create-survey-app-run';
import '../../../common/js/moe-service';
import '../../../common/js/angular-util';
import '../../../common/js/sv-common';
import './ui-widgets/sv-widgets';
import './app-components/wrap-ctrls';
import './results-components/api-results-service';
import './results-components/stats-module';
import './admin-components/survey-admin-service';
import './targeting-attributes/targeting-attributes-views';
import './targeting-attributes/targeting-attribute-groups';
import './question-types/question-types-module';
import './common-components/deferred';

import './common-components/sv-offline-service';
import './common-components/paginate-service';
import './common-components/modal-service';
import './common-components/select-all-input';
import './common-components/sv-datepicker';
import './common-components/datepicker-service';
import './common-components/sv-dropdown';
import './common-components/sv-dnd';
import './common-components/drag-and-drop-sort';
import './common-components/pub-sub';
import './common-components/sv-focus-me';
import './common-components/sv-dialog';
import './common-components/sv-clear-x';
import './common-components/sv-cc-summary';
import './common-components/sv-autosize-textarea';
import './common-components/viewport-pub-sub';
import './common-components/loading-button';
import './common-components/sv-edit-button';
import './common-components/sv-modal';
import './common-components/sv-clipboard';
import './common-components/sv-inset-modal';
import './common-components/sv-loading-spinner';
import './common-components/sv-filters';
import './common-components/sv-resize';
import './common-components/sv-offline-service';
import './survey/api-survey-service';
import './survey-main-ctrl';
import './survey-attachment-ctrl';
import './survey-invite-ctrl';
import './publisher/publisher-module';
import './survey-app-common';
import './auth/auth-module';
import './beacons/beacon-module';
import './user/admin/user-admin-module';
import './user/basics/user-basics-ctrl';
import './user/account/user-account-module';
import './survey-creation/survey-creation-module';
import './survey-dashboard/survey-dashboard-module';
import './results-components/survey-results-module';
import './admin-components/admin-module';
import './admin-components/retargeting-admin-ctrl';
import './filter-and-compare/filter-and-compare-module';
import './filter-logic/filter-logic-module';
import './custom-variables/custom-variables.module';
import './ad-measurement/ad-measurement.module';
import './modal-views/modals';
import './admin-components/admin-bar-view';
import './results-components/pane-state-manager';
import './charts/charts-module';
import './charts-widget/charts-widget-module';

export default angular.module('CreateSurveyApp', [
    // External
    'angulartics',
    'angulartics.google.analytics',
    'ngAnimate',
    'ngCookies',
    'ngSanitize',
    'ui.sortable',
    'ui.router',
    'ui.bootstrap',
    'ui.select',
    'rzModule',
    // Common
    'svCommon',
    'moeService',
    'angularUtil',
    // Common components
    'common.deferred',
    'dragAndDropSort',
    'loadingButton',
    'modalService',
    'common.paginator',
    'pubSub',
    'common.selectAll',
    'svAutosizeTextarea',
    'svCcSummary',
    'svClearX',
    'svClipboard',
    'common.dialog',
    'svDatePicker',
    'svDnd',
    'svDropdown',
    'svEditButton',
    'svFilters',
    'svFocusMe',
    'svInsetModal',
    'svLoadingSpinner',
    'svModal',
    'viewportPubSub',
    'svAutosizeTextarea',
    'svWidgets',
    'svResize',
    'svOfflineService',
    // API and Services
    'surveyStatusesService',
    'apiResultsService',
    'surveyValidation.service',
    'surveyAdminService',
    'surveyValidation.service',
    'targetingAttributes.views',
    'targetingAttributeGroups.service',
    // Apps and Modules
    'surveyMain.ctrl',
    'QuestionTypesModule',
    'wrap.ctrl',
    'SurveyAttachmentApp',
    'surveyCreationModule',
    'SurveyInviteApp',
    'SurveyDashboardModule',
    'publisherModule',
    'SurveyAppCommon',
    'AuthModule',
    'beaconModule',
    'userAdminModule',
    'userBasicsModule',
    'UserAccountModule',
    'StatsModule',
    'SurveyResultsModule',
    'SurvataAdminModule',
    'RetargetingAdminModule',
    'filterLogic',
    'FilterAndCompareModule',
    'customVariables',
    'adMeasurement',
    'modals',
    'adminBar.view',
    'PaneStateManager',
    'ChartsModule',
    'chartsWidgetModule',
]).config([
    '$sceProvider',
    '$httpProvider',
    '$urlServiceProvider',
    '$stateProvider',
    '$locationProvider',
    function($sceProvider, $httpProvider, $urlServiceProvider, $stateProvider, $locationProvider) {
        $sceProvider.enabled(false);
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.withCredentials = true;
        delete $httpProvider.defaults.headers.common['X-Requested-With'];
        $locationProvider.html5Mode(true).hashPrefix('!');
        $urlServiceProvider.config.strictMode(false);

        //Set up account routes
        _.forEach(Config.accountRoutes, function(options, name) {
            if (name !== 'account') {
                options.data = {
                    parent: 'account',
                };
            }
            $stateProvider.state(name, options);
        });
        $urlServiceProvider.rules.when('/account', '/account/info');
        //Set up all admin routes
        $urlServiceProvider.rules.when('/admin', '/admin/surveys');
        _.forEach(Config.adminRoutes, function(details, route) {
            if (name !== 'admin') {
                details.data = {
                    parent: 'admin',
                };
            }
            $stateProvider.state(route, details);
        });

        $urlServiceProvider.rules.when('/admin', '/admin/surveys');
        _.forEach(Config.routes, function(details, route) {
            $stateProvider.state(route, details);
        });
        $urlServiceProvider.rules.when('/:surveyId/dashboard/', '/:surveyId/dashboard');

        // Set up routing for each step in survey creation flow
        _.forEach(Config.steps, function(step, stepName) {
            var stateOptions;
            step.details.resolve = step.details.resolve || {};
            step.details.resolve.step = step.details.resolve.step || function() {
                return stepName;
            };
            stateOptions = angular.copy(step.details);
            stateOptions.url = '^/:funnelType/:surveyId' + step.route;
            stateOptions.data = {
                step: stepName,
                parent: 'surveyCreation',
            };
            stateOptions.parent = 'surveyCreation';
            stateOptions.params = {
                funnelType: {
                    squash: true,
                    value: null,
                },
            };
            $stateProvider.state(stepName.toLowerCase(), stateOptions);
            $urlServiceProvider.rules
                .when('/basic/:surveyId' + step.route, '/:surveyId' + step.route);
            $urlServiceProvider.rules
                .when('/advanced/:surveyId' + step.route, '/quote/:surveyId' + step.route);
        });

        //Set up dashboard routes
        _.forEach(Config.dashboardRoutes, function(options, name) {
            if (name !== 'dashboard') {
                options.data = {
                    parent: 'dashboard',
                };
                options.parent = 'dashboard';
            }
            $stateProvider.state(name, options);
        });
        _.forEach(Config.authRoutes, function(options, name) {
            $stateProvider.state(name, options);
        });
        $urlServiceProvider.rules.when('/:surveyId/dashboard/clientInfo', '/:surveyId/dashboard/client-info');
        $urlServiceProvider.rules.when('/:surveyId/dashboard/uploadResults', '/:surveyId/dashboard/upload');
        $urlServiceProvider.rules.when(new RegExp(':surveyId/dashboard\\?.*'), '/:surveyId/dashboard');

        $stateProvider.state('surveyCreation', {
            templateUrl: 'base-templates/survey-creation.html',
            url: '/:funnelType',
            params: {
                funnelType: {
                    squash: true,
                    value: null,
                },
            },
            controller: 'surveyCreationCtrl',
            controllerAs: 'SurveyCreationVM',
        });
    },
]).run([
    '$rootScope',
    '$timeout',
    '$window',
    '$urlService',
    '$transitions',
    '$state',
    '$stateParams',
    '$q',
    'accountService',
    'svOfflineService',
    'surveyStatusesService',
    'surveyService',
    run,
]);
