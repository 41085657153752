import U from './util';
import {
    timeout, interval,
} from 'd3-timer';
const d3 = {
    timeout,
    interval,
};

class SvOffline {
    constructor() {
        this.offline = U.eventable();
        this._config = {
            interval: 5000, // Time in ms to wait in between each connection check
            retry: false, // After triggering 'disconnect', should be continue checking on an interval?
            url: '//' + window.location.hostname, // The url to use for checking connection
            forceDisconnected: false, // If true, force status to be offline
            alwaysOn: false, // If false, wait for an explicit call to test connection, if true, check continuously
        };
        this.someoneListening = false;
        this.testingInterval = null;
        this.worker = null;
        this.start = this.startTesting;
        this.stop = this.stopTesting;
        this.check = this.testConnectionWorker;
    }
    getWorker() {
        return operative({
            testConnection: (obj, cb) => {
                const config = obj.config,
                    FN_MAP = {
                        start: 'startTesting',
                        stop: 'stopTesting',
                    },
                    response = {
                        isOffline: null,
                        fnToCall: null,
                    };

                // If forcing disconnected status
                if (config.forceDisconnected) {
                    response.isOffline = true;

                    if (config.retry && !config.testingInterval) {
                        response.fnToCall = FN_MAP.start;
                    }

                    cb(response);
                }

                var xhr = new XMLHttpRequest();
                var url = config.url + '/?cb=' + Math.floor((1 + Math.random()) * 0x10000);
                xhr.open('GET', url, false);
                xhr.withCredentials = true;

                try {
                    xhr.send();
                    if (xhr.status === 200 || _.includes(config.acceptedCodes || [], xhr.status)) {
                        response.isOffline = false;

                        if (!config.alwaysOn) {
                            response.fnToCall = FN_MAP.stop;
                        }
                    }
                    response.xhrStatus = xhr.status;
                    cb(response);
                }
                catch (error) {
                    response.isOffline = true;
                    if (config.retry) {
                        response.fnToCall = FN_MAP.start;
                    }
                    response.xhrStatus = xhr.status;
                    cb(response);
                }
            },
        }, [
            'https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.4/lodash.min.js', 'https://cdnjs.cloudflare.com/ajax/libs/d3-timer/1.0.7/d3-timer.min.js',
        ]);
    }
    callTestingFn(fnString) {
        if (!fnString) {
            return;
        }

        switch (fnString) {
            case 'startTesting':
                this.startTesting();
                break;
            case 'stopTesting':
                this.stopTesting();
                break;
            default:
        }
    }
    handleResponse(res) {
        if (res.isOffline) {
            this.offline.trigger('disconnected');
            this.callTestingFn(res.fnToCall);
        }
        else {
            this.offline.trigger('connected');
        }
    }

    testConnectionWorker() {
        const workerConfig = U.extend(this._config, {
            testingInterval: !!this.testingInterval,
            _onlineStatus: '',
            _fnToCall: '',
        });

        // If nobody is listening to offline events at the moment, don't bother
        if (!this.someoneListening) {
            return;
        }

        if (!this.worker) {
            this.worker = this.getWorker();
        }

        this.worker.testConnection({
            config: workerConfig,
        }, res => {
            d3.timeout(() => {
                if (res) {
                    this.handleResponse(res);
                }
            });
        });
    }

    startTesting() {
        const self = this;
        if (!this.testingInterval) {
            this.testingInterval = d3.interval(function() {
                self.testConnectionWorker();
            }, this._config.interval);
        }
    }

    stopTesting() {
        if (this.testingInterval) {
            this.testingInterval.stop();
        }
    }

    terminate() {
        if (this.worker) {
            this.worker.terminate();
        }
    }

    showTestUi() {
        const styleElement = document.createElement('div'),
            container = document.createElement('div');

        styleElement.innerHTML = '<style>.offline-simulate-ui {position: fixed; z-index: 100000; left: -4px; top: 45%; border: solid 1px rgba(0, 0, 0, 0.15); font-size: 12px; padding: 2px; padding-left: 6px; width: 25px; background: #f6f6f6; color: #888888; }</style>';
        container.className = 'offline-simulate-ui';
        container.innerHTML = '<input type="checkbox" id="offline-simulate-check" title="Simulate online/offline states">';
        document.body.appendChild(styleElement);
        document.body.appendChild(container);
        $('#offline-simulate-check').on('click', () => {
            if (this.checked) {
                this._config.forceDisconnected = true;
                this.testConnectionWorker();
            }
            else {
                this._config.forceDisconnected = false;
                this.testConnectionWorker();
            }
        });
        return this;
    }
    config(params) {
        U.extend(this._config, params || {});
        return this;
    }
    onDisconnected(func) {
        this.someoneListening = true;
        this.offline.on('disconnected', func);
        return this;
    }
    onConnected(func) {
        this.someoneListening = true;
        this.offline.on('connected', func);
        return this;
    }
}
const svOffline = new SvOffline();
export default svOffline;
