import * as d3 from 'd3-format';

function countBasedFormatter(d, _i, _nodeList, plot) {
    const axis = plot._isVertical ? 'y' : 'x',
        dataValue = plot[axis]().accessor(d);

    if (dataValue !== 0) {
        return dataValue + '\n' + d3.format('.1%')(d.cntProp);
    }
    return dataValue;
}

function countOnlyFormatter(d, _i, _nodeList, plot) {
    const axis = plot._isVertical ? 'y' : 'x',
        dataValue = plot[axis]().accessor(d);
    if (dataValue !== 0) {
        return U.isInteger(dataValue) ? dataValue : d3.format('.2f')(dataValue);
    }
    return dataValue;
}

function percentBasedFormatter(d, _i, _nodeList, plot) {
    const axis = plot._isVertical ? 'y' : 'x',
        dataValue = plot[axis]().accessor(d);
    if (dataValue !== 0) {
        return d3.format('.1f')(dataValue) + '%\n' + d.cntValue;
    }

    return dataValue;
}

function liftFormatter(d) {
    return d3.format('.1f')(parseFloat(d.split('---')[1]) * 100.0) + '%';
}

function percentBasedDifferenceFormatter(d, _i, _nodeList, plot) {
    function getAxisKey(_plot) {
        if ('size' in _plot) {
            // We have scatter plot which is ALWAYS vertical so return 'y'
            return 'y';
        }
        // Otherwise we have a plot that has orientation
        return _plot._isVertical ? 'y' : 'x';
    }

    // We need to show the same information for each datapoint regardless of the
    // dataset it belongs to.
    //
    // e.g. for point #1 we need to show D1[0]-D2[0]
    const axis = getAxisKey(plot),
        datumAccessor = plot.size ? d => {
            return d[axis];
        } : plot[axis]().accessor,
        datasetA = plot.datasets()[0].data(),
        datasetB = plot.datasets()[1].data(),
        datumIndex = _.findIndex(datasetB, i => {
            return i === d;
        }),
        differenceBA = Math.abs(datumAccessor(datasetB[datumIndex]) - datumAccessor(datasetA[datumIndex]));

    // When not 0 format by adding % to end of display value
    if (differenceBA !== 0) {
        return (_.isInteger(differenceBA) ? differenceBA : d3.format('.1f')(differenceBA)) + '%';
    }
    return ''; // Otherwise return nothing
}

export {
    countBasedFormatter as COUNT_BASED_FORMAT,
    countOnlyFormatter as COUNT_ONLY_FORMAT,
    percentBasedFormatter as PERCENT_BASED_FORMAT,
    percentBasedDifferenceFormatter as PERCENT_BASED_DIFFERENCE_FORMAT,
    liftFormatter as LIFT_FORMATTER,
};
