import '../../../../common/js/inc/sv-notify';
import '../common-components/pub-sub';
import '../common-components/sv-dialog';
import '../survey-dashboard/survey-dashboard-service';
import '../question-types/question-type-groups';
import '../targeting-attributes/targeting-attribute-groups';
import '../survey/api-survey-service';
import config from '../config';
import surveyConstants from '../survey/survey-constants';
import '../survey/survey-statuses';

angular.module('surveyCreation.service', [
    'svNotify',
    'common.dialog',
    'pubSub',
    'surveyDashboardService',
    'questionTypeGroups.service',
    'targetingAttributeGroups.service',
    'apiSurveyService',
    'surveyStatusesService',
])
    .service('surveyCreationService', surveyCreationService);
surveyCreationService.$inject = [
    '$rootScope',
    '$timeout',
    '$notify',
    'dialog',
    'pubSubService',
    '$dashboardService',
    'questionTypeGroupsService',
    'targetingAttributeGroups',
    'surveyService',
    'surveyStatusesService',
];

/**
 * @param $rootScope
 * @param $timeout
 * @param $notify
 * @param dialog
 * @param pubSubService
 * @param $dashboardService
 * @param QTGS
 * @param TAGS
 * @param surveyService
 * @param surveyStatusesService
 */
function surveyCreationService($rootScope, $timeout, $notify, dialog, pubSubService, $dashboardService, QTGS, TAGS, surveyService, surveyStatusesService) {
    var saveModal,
        questionTypes = QTGS.types;

    // Allow user to add a new question?
    /**
     *
     */
    function canAddQuestion() {
        return !atMaxSurveyLength() && surveyStatusesService.isEditable($rootScope.survey);
    }
    // Allow user to copy a question?
    /**
     * @param question
     */
    function canCopyQuestion(question) {
        var type = questionTypes[question.type];
        return canAddQuestion() && !questionDisabled(type);
    }

    // Maximum survey length
    /**
     *
     */
    function atMaxSurveyLength() {
        if ($rootScope.auth.user.isAdmin) {
            return false; // Admins can make additional questions because of logic
        }
        let maxQuestions;

        if ($rootScope.survey.surveyPackage === surveyConstants.PACKAGES.BASIC) {
            maxQuestions = config.funnelTypes.BASIC.maxQuestions;
        }
        else if ($rootScope.survey.surveyPackage === surveyConstants.PACKAGES.EXTENDED) {
            maxQuestions = config.funnelTypes.EXTENDED.maxQuestions;
        }
        else {
            maxQuestions = config.funnelTypes.ADVANCED.maxQuestions;
        }

        return $rootScope.survey.questions && ($rootScope.survey.questions.length >= maxQuestions);
    }

    /**
     *
     */
    function isAdvancedSurvey() {
        return surveyStatusesService.isAdvancedFlow() || ($rootScope.survey.surveyPackage === surveyConstants.PACKAGES.ADVANCED);
    }

    /**
     *
     */
    function refreshTargetingReqs() {
        var requirementsList = [];

        _.forEach($rootScope.survey.targetingReqs, function(e) {
            if (!TAGS.attributes[e.attribute].hide) {
                requirementsList.push(e);
            }
        });

        // Handle old screener JSON
        _.forEach($rootScope.survey.screeningQuestions, function(screener) {
            screener.type = screener.type === 'screener' ? 'singleSelect' : screener.type;
            screener.details.responseType = screener.details.responseType || 'text';

            _.forEach(screener.details.choices, function(choice) {
                choice.text = choice.text || choice.html;
            });
        });

        $rootScope.survey._targetingString = $dashboardService.createTargetingReqsString($rootScope.survey);
        return requirementsList;
    }

    /**
     * @param newTargetingText
     */
    function saveTargetingDetails(newTargetingText) {
        pubSubService.notify('update-edit-details-status', 'saving');
        return surveyService.updateSurvey($rootScope.survey.uuid, {
            targetingDetails: newTargetingText,
        }).then(function(response) {
            $rootScope.survey.targetingDetails = response.targetingDetails;
            $timeout(function() {
                pubSubService.notify('update-edit-details-status', 'success');
                $timeout(function() {
                    pubSubService.notify('update-edit-details-status', '');
                }, 750);
            }, 750);
        });
    }

    // Read: retrieve a requirement by attribute ID.
    // TargetingBuilderVM.getReq = getReq;
    /**
     * @param attrId
     */
    function getReq(attrId) {
        var requirement = null;

        _.forEach($rootScope.survey.targetingReqs, function(req) {
            requirement = req.attribute === attrId ? req : requirement;
        });
        return requirement;
    }

    // Read: retrieve the index of a screening question by UUID
    /**
     * @param uuid
     */
    function getScreenerIdx(uuid) {
        var result = -1;
        _.forEach($rootScope.survey.screeningQuestions, function(q, idx) {
            if (q.uuid === uuid) {
                result = idx;
            }
        });
        return result;
    }

    function openSaveModal() {
        saveModal = dialog.popup({
            templateUrl: '/modal-templates/save-survey-modal.html',
            controller: OpenSaveModalCtrl,
            controllerAs: 'OpenSaveModalVM',
            windowClass: 'login-modal',
        });
    }

    function closeSaveModal() {
        saveModal && saveModal.close();
    }

    /**
     * @param $uibModalInstance
     */
    function OpenSaveModalCtrl($uibModalInstance) {
        var self = this;
        self.survey = $rootScope.survey;
        self.auth = $rootScope.auth;
        self.closeModal = $uibModalInstance.close;
        self.saveSurvey = saveSurvey;

        function saveSurvey() {
            $rootScope.saveSurvey(self.survey.name);
            self.closeModal();
        }
    }

    // Prevent the user from adding any more of this question type?
    /**
     * @param type
     */
    function questionDisabled(type) {
        if (QTGS.questionDisabled(type, $rootScope.funnel)) {
            return !$rootScope.auth.user.isAdmin;
        }
        return false;
    }

    function updateQuestionCount() {
        QTGS.updateQuestionCount($rootScope.survey.questions, $rootScope.funnel);
    }

    // Delete: remove a question by UUID.
    /**
     * @param uuid
     */
    function removeQuestion(uuid) {
        var q = getQuestion(uuid);
        if (q) {
            var idx = $rootScope.survey.questions.indexOf(q);
            $rootScope.survey.questions.splice($rootScope.survey.questions.indexOf(q), 1);

            pubSubService.notify('update-save-in-progress', true);
            $notify.warning(q.statement.text || 'Deleted question #' + (idx + 1).toString(), {
                clear: true,
                timeout: 2000,
                heading: 'Deleted question #' + (idx + 1).toString(),
                button: {
                    text: 'Undo',
                    callback: function() {
                        if ($rootScope.survey.questions) {
                            $rootScope.survey.questions.splice(idx, 0, q);
                        }
                        else {
                            $rootScope.survey.questions = [q];
                        }
                        pubSubService.notify('update-save-in-progress', false);
                        updateQuestionCount();
                    },
                },
                onDismiss: function() {
                    surveyService.deleteQuestion(uuid).then(function() {
                        pubSubService.notify('update-save-in-progress', false);
                    });
                },
                onTimeout: function() {
                    surveyService.deleteQuestion(uuid).then(function() {
                        pubSubService.notify('update-save-in-progress', false);
                    });
                },
            });
            updateQuestionCount();
        }
    }

    // Read: retrieve a question by UUID.
    /**
     * @param uuid
     * @param isScreener
     */
    function getQuestion(uuid, isScreener) {
        var questions = isScreener ? $rootScope.survey.screeningQuestions : $rootScope.survey.questions,
            length = questions.length;
        for (var i = 0; i < length; i++) {
            var question = questions[i];
            if (question.uuid === uuid) {
                return question;
            }
        }
    }

    /**
     * @param chars
     * @param maxChars
     */
    function charCountColor(chars, maxChars) {
        var red = Math.min(255, Math.round(255 * chars / maxChars, 0)).toString(16);
        red = red.length === 1 ? '0' + red : red;
        return '#' + red + '3333';
    }

    return {
        refreshTargetingReqs: refreshTargetingReqs,
        saveTargetingDetails: saveTargetingDetails,
        getReq: getReq,
        getScreenerIdx: getScreenerIdx,
        openSaveModal: openSaveModal,
        closeSaveModal: closeSaveModal,
        questionDisabled: questionDisabled,
        removeQuestion: removeQuestion,
        getQuestion: getQuestion,
        canCopyQuestion: canCopyQuestion,
        canAddQuestion: canAddQuestion,
        isAdvancedSurvey: isAdvancedSurvey,
        charCountColor: charCountColor,
    };
}
