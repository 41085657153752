const filterAndCompareConstants = {
    iconMap: {
        singleSelect: 'far fa-dot-circle',
        multiSelect: 'far fa-check-square',
        rating: 'far fa-star',
        ranking: 'fas fa-sort-amount-up',
        longFreeResponse: 'fas fa-edit',
        clickmap: 'fal fa-bullseye',
        grid: 'fal fa-table',
        gridRow: 'fal fa-table',
        numeric: 'fas fa-calculator',
        introStatement: 'far fa-comment',
        // 'combobox':         'fas fa-search-plus',
        // 'dropdown':         'far fa-caret-square-down',
        // 'slider':           'far fa-sliders',
    },
};
export default filterAndCompareConstants;
