import svOffline from './sv-offline';

const RESOURCE_DOES_NOT_EXIST_CODE = 100;

/**
 * @param response
 */
function checkStatus(response) {
    if (response.ok) {
        return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}
/**
 * @param type
 * @param url
 * @param params
 * @param simulateError
 */
function deferredRequest(type, url, params, simulateError) {
    return new Promise((resolve, reject) => {
        const options = {
            method: type,
            mode: 'cors', // No-cors, cors, *same-origin
            cache: 'no-cache', // *Default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // Include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json', // "application/x-www-form-urlencoded",
            },
            redirect: 'follow', // Manual, *follow, error
            referrer: 'no-referrer', // No-referrer, *client
        };
        if (type === 'POST' || type === 'PUT') {
            options.body = JSON.stringify(params || {});
        }
        if (simulateError) {
            url += 'gibberish';
        }
        else if (type === 'MOCK') {
            resolve({
                ...params,
                uuid: '1234',
            });
            return;
        }

        fetch(url, options)
            .then(checkStatus)
            .then(response => response.json())
            .then(response => {
                if (response.error && response.code !== RESOURCE_DOES_NOT_EXIST_CODE) {
                    reject(response);
                    return;
                }
                resolve(response.error ? {} : response);
            })
            .catch(e => {
                // Retry server call once
                fetch(url, options)
                    .then(response => response.json())
                    .then(response => {
                        if (response.error && response.code !== RESOURCE_DOES_NOT_EXIST_CODE) {
                            reject(response);
                            return;
                        }
                        resolve(response.error ? {} : response);
                    })
                    .catch(() => {
                        if (e.data && e.data.code === RESOURCE_DOES_NOT_EXIST_CODE) {
                            resolve({});
                            return;
                        }
                        if (type === 'get') {
                            console.info('HTTP request failed', {
                                method: type,
                                url: url,
                                params: params,
                            });
                        }
                        else {
                            console.info('HTTP request failed', {
                                method: type,
                                url: url,
                                params: params,
                            });
                            svOffline.check(); // Only check offline status on non-GET requests
                        }
                        reject(e);
                    });
            });
    });
}

const httpDeferred = {
    get: (url, simulateError) => {
        return deferredRequest('GET', url, simulateError);
    },
    post: (url, params, simulateError) => {
        return deferredRequest('POST', url, params, simulateError);
    },
    put: (url, params, simulateError) => {
        return deferredRequest('PUT', url, params, simulateError);
    },
    delete: (url, simulateError) => {
        return deferredRequest('DELETE', url, simulateError);
    },
    mock: (url, params, simulateError) => {
        return deferredRequest('MOCK', url, params, simulateError);
    },
    dashboardHost: window.config?.dashboardHost,
};

export default httpDeferred;
