/*
    HTML:
        <ELEMENT data-sv-affix data-offset-top="55" data-target="parent">...</ELEMENT>
            OR
        <ELEMENT data-sv-affix data-offset-options="{}" [data-width-constrain-to]>...</ELEMENT>

    OPTIONS:
        data-target                     "jquerySelector" | "window"         // the element that your item will affix itself to
        data-offset-top                 UNITLESS_NUMBER | function | {}     // top pixel offset your element will have from its' `data-affix-target` top
        data-offset-bottom                                                  // bottom pixel offset your element will have from its' `data-affix-target` bottom
                                                                            // Can use both offset-[top|bottom]
        data-options                    {target:, offset:{top:, bottom:}}   // an element defining all three above properites
        data-scrolling-parent-container                                     // alias to "data-target"

        * More documentation here: http://getbootstrap.com/javascript/#affix

    EXTRA OPTIONS (not part of the bootstrap Affix plugin)
        -----------------
        data-trigger-affix-container    "jquerySelector"                    // necessary when the affixed container is nested inside of a contianer that doesn't scroll within the viewport
        data-width-constrain-to         "jquerySelector"                    // needed only if the elements' width needs to be constrained by some ancestor/parent that IS NOT the window

    NOTE -- Needs to handle bottom offset and possibly relative widths
*/

angular.module('svAffix', []).directive('svAffix', ['$window',
    '$rootScope',
    function($window, $rootScope) {
        var isolateScope = $rootScope; // Isolated rootScope so we can listen for events
        return {
            restrict: 'A',
            link: {
                post: function(scope, $element, attrs) {
                    var t = (attrs.options && attrs.options.target) || attrs.scrollingParentContainer,
                        resizeEvent = $element.parents('[sv-resize]').length ? 'sv-window-resized' : 'resize',
                        offsetTop = parseInt((attrs.options && attrs.options.top) || attrs.offsetTop, 10),
                        offsetBottom = parseInt((attrs.options && attrs.options.bottom) || attrs.offsetBottom, 10),
                        offsetOptions = {},
                        $scrollingParentContainer = $(t).length ? $(t) : $window,
                        $triggerContainer = $(attrs.triggerAffixContainer),
                        $constrainingWidthContainer = $element.parents(attrs.widthConstrainTo);

                    /*
                    Takes:
                        paddingString   $(ELEMENT).css('padding') string

                    Returns:
                        paddingObject = {top:NUMBER, right:NUMBER, bottom:NUMBER, left:NUMBER}

                    Notes:
                        Can only deal with pixels

                        Relative units (em|rem|%, etc) don't work here becuse
                        parseInt works by parsing out only the number.

                        Also handling relative units will probably need a special
                        function to figure out what each unit maps to in pixels
                 */
                    var getPadding = function(paddingString) {
                        var paddingStringArray = paddingString.split(' '),
                            paddingObj = {
                                top: null,
                                right: null,
                                bottom: null,
                                left: null,
                            };

                        switch (paddingStringArray.length) {
                            case 1:
                                paddingObj.right = paddingObj.left = paddingObj.top = paddingObj.bottom = parseInt(paddingString, 10);
                                break;
                            case 2:
                                paddingObj.top = paddingObj.bottom = parseInt(paddingStringArray[0], 10);
                                paddingObj.left = paddingObj.right = parseInt(paddingStringArray[1], 10);
                                break;
                            case 3:
                                paddingObj.top = parseInt(paddingStringArray[0], 10);
                                paddingObj.left = paddingObj.right = parseInt(paddingStringArray[1], 10);
                                paddingObj.bottom = parseInt(paddingStringArray[2], 10);
                                break;
                            default:
                                paddingObj.top = parseInt(paddingStringArray[0], 10);
                                paddingObj.right = parseInt(paddingStringArray[1], 10);
                                paddingObj.bottom = parseInt(paddingStringArray[2], 10);
                                paddingObj.left = parseInt(paddingStringArray[3], 10);
                        }
                        return paddingObj;
                    };

                    var resizeElement = function(removeConstrainingContainerPadding) {
                        var padding;

                        if ($element.hasClass('affix')) {
                            $element.width($constrainingWidthContainer.outerWidth());
                        }
                        else {
                            padding = getPadding($window.getComputedStyle($constrainingWidthContainer.context).padding);
                            if (removeConstrainingContainerPadding) {
                            // Remove padding and calculate using innerWidth
                                $element.width($constrainingWidthContainer.innerWidth() - padding.left - padding.right);
                            }
                            else {
                                $element.width($constrainingWidthContainer.innerWidth());
                            }
                        }
                    };

                    if ($triggerContainer.length) {
                        if (offsetTop || offsetTop === 0) {
                            offsetOptions.top = $triggerContainer.offset().top - $scrollingParentContainer.offset().top;
                        }
                        if (offsetBottom || offsetBottom === 0) {
                            offsetOptions.bottom = $triggerContainer.offset().top + $triggerContainer.height() - parseInt($scrollingParentContainer.css('padding-bottom'), 10);
                        }
                    }

                    // EVENT HANDLERS
                    // Global window resize event make sure we listen to the right event so we don't get into an endless resize event loop
                    isolateScope.$on(resizeEvent, function(data) {
                    // If our element is 'affixed' (AKA doesn't have .affix-top || .affix-bottom) then remove parent padding otherwise keep it
                        resizeElement(!$element.hasClass('affix'));
                        // On resize events we need to tell affix to verify our affixed content is displaying properly
                        $element.affix('checkPosition');
                    });

                    // Event fires immediately before the element has been affixed to the scrollingParentContainer
                    $element.on('affix.bs.affix affixed.bs.affix', function() {
                        resizeElement();
                    });

                    // Event fires immediately before the element has been affixed to top||bottom -- element is about to be put back in the document flow
                    $element.on('affix-top.bs.affix affix-bottom.bs.affix', function(event) {
                        if (event.type !== 'load') {
                            resizeElement(true);
                        }
                    });

                    // Event fires immediately after the element has been affixed to top||bottom -- element has been put back in the document flow
                    $element.on('affixed-top.bs.affix affixed-bottom.bs.affix', function(event) {
                        if (event.type !== 'load') {
                            resizeElement(true);
                        }
                    });

                    /* TODO need to set left edge of element to be that of the constrainingWidthContainer and left width in the case that the
                    element is nested in some deep container that has a different wiedth than that of eigther the constrainingWidthContainer
                    and/or scrollingParentContainer
                */

                    $element.affix({
                        offset: offsetOptions,
                        target: $scrollingParentContainer,
                    });
                },
            },
        };
    }]);
