import U from '../../../../common/js/util';
import '../../../../common/js/angular-util';
import '../common-components/deferred';
import tagCreationConstant from './tag-creation-constant';

export default angular.module('apiBeaconService', [
    'angularUtil', 'common.deferred',
])
    .service('beaconService', beaconService);

beaconService.$inject = [
    'httpDeferred', 'Deferred',
];

/**
 * Beacon service
 *
 * @param {object} httpDeferred
 * @param {object} Deferred
 *
 * @returns {object} service functions
 */
function beaconService(httpDeferred, Deferred) {
    /**
     * Create beacon pool
     *
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function createBeaconPool(params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/beacon-pool', params);
    }

    /**
     * List beacon pools
     *
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function listBeaconPools(params) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/beacon-pool' + U.buildParamString(params));
    }

    /**
     * Update beacon pool
     *
     * @param {string} uuid
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function updateBeaconPool(uuid, params) {
        return httpDeferred.put(httpDeferred.dashboardHost + '/v1/beacon-pool/' + uuid, params);
    }

    /**
     * Delete beacon pool
     *
     * @param {string} uuid
     *
     * @returns {Promise} promise
     */
    function deleteBeaconPool(uuid) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/beacon-pool/' + uuid);
    }

    /**
     * List beacon definitions (tags)
     *
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function listTags(params) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/beacon-definition' + U.buildParamString(params));
    }

    /**
     * Create beacon definition (tag)
     *
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function createTag(params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/beacon-definition', params);
    }

    /**
     * Get beacon definition (tag)
     *
     * @param {string} uuid
     *
     * @returns {Promise} promise
     */
    function getBeaconByUuid(uuid) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid);
    }

    /**
     * Get full beacon definition (tag)
     *
     * @param {object} tag
     *
     * @returns {Promise} promise
     */
    function getFullTag(tag) {
        let deferred = new Deferred(),
            fullBeacon = {
                name: tag.exportName,
                beaconType: tag.beaconType,
                savedBeaconType: tag.beaconType,
                uuid: tag.uuid,
                paramString: '',
                params: [],
                dmp: tag.dataPartner,
                mediaPartnerId: tag.mediaPartnerId,
            },
            impressionParameters = tagCreationConstant.get('IMPRESSION_PARAMETERS');

        const redirectsArr = tagCreationConstant.get('REDIRECTS');
        const dataPartner = fullBeacon.dmp === 'oracle' ? 'Oracle/BluKai' : _.startCase(fullBeacon.dmp);
        const redirect = _.find(redirectsArr, function(partner) {
            return partner.name === dataPartner;
        });

        fullBeacon.dmp = redirect;
        deferred(getBeaconImpressionParameters(tag.uuid)).then(function(impressionParams) {
            _.forEach(impressionParams, function(param, idx) {
                if (!param) {
                    return;
                }
                let impressionParam = impressionParameters[param.impressionParameter.name];
                fullBeacon.paramString += impressionParam.value + (idx === impressionParams.length - 1 ? '' : ',');
                fullBeacon.params.push(impressionParam);
            });
            fullBeacon.hasImpressionParameters = !!impressionParams.length;
        }, function() {
            fullBeacon.params = tagCreationConstant.get('DEFAULT_IMPRESSION_PARAMETERS');
            fullBeacon.hasImpressionParameters = false;
        });

        return deferred.promise().then(function() {
            return fullBeacon;
        });
    }

    /**
     * Create segment
     *
     * @param {object} segment
     *
     * @returns {Promise} promise
     */
    function createSegment(segment) {
        var params = {
            segment: 1,
            segmentId: (segment.dataPartner.prepend || '') + segment.segmentId,
            beaconType: segment.beaconType.value,
            exportName: segment.exportName,
            dataPartner: segment.dataPartner.value,
            userId: segment.userId,
            noDefaultBeacon: true,
        };
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/beacon-definition', params);
    }

    /**
     * Update tag
     *
     * @param {string} uuid
     * @param {object} params
     *
     * @returns {Promise} promise
     */
    function updateTag(uuid, params) {
        return httpDeferred.put(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid, params);
    }

    /**
     * Delete beacon definition (tag)
     *
     * @param {string} uuid
     *
     * @returns {Promise} promise
     */
    function deleteTag(uuid) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid);
    }

    /**
     * Get beacon definition stats
     *
     * @param {string} uuid
     *
     * @returns {Promise} promise
     */
    function getBeaconStats(uuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid + '/stats');
    }

    /**
     * Get beacons from survey
     *
     * @param {object} survey
     *
     * @returns {object[]} list of beacons
     */
    function getBeaconsFromSurvey(survey) {
        for (var i = 0; i < survey.targetingReqs.length; i++) {
            if (survey.targetingReqs[i].attribute === 'beacon') {
                return survey.targetingReqs[i].details.beacons;
            }
        }
        return null;
    }

    /**
     * Set beacon impression parameters
     *
     * @param {string} uuid
     * @param {object} impressionParameters
     *
     * @returns {Promise} promise
     */
    function setBeaconImpressionParameters(uuid, impressionParameters) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid + '/beacon-definition-parameter-configuration', {
            impressionParameters: impressionParameters,
        });
    }

    /**
     * Get beacon impression parameters
     *
     * @param {string} uuid
     *
     * @returns {Promise} promise
     */
    function getBeaconImpressionParameters(uuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/beacon-definition/' + uuid + '/beacon-definition-parameter-configuration');
    }

    return {
        createBeaconPool: createBeaconPool,
        listBeaconPools: listBeaconPools,
        updateBeaconPool: updateBeaconPool,
        deleteBeaconPool: deleteBeaconPool,

        listTags: listTags,
        createTag: createTag,
        updateTag: updateTag,
        deleteTag: deleteTag,

        getBeaconByUuid: getBeaconByUuid,
        getBeaconStats: getBeaconStats,
        getBeaconsFromSurvey: getBeaconsFromSurvey,
        getFullTag: getFullTag,

        setBeaconImpressionParameters: setBeaconImpressionParameters,
        getBeaconImpressionParameters: getBeaconImpressionParameters,

        createSegment: createSegment,
    };
}
