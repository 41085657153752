import '../../../common-components/pub-sub';
import {
    prepareLogic,
} from '../../../filter-and-compare/default-filter-service';
import '../../../filter-logic/filter-logic-editor';
import '../analysis-plan.service';
import {
    getTargetAudienceFilterLogicModel,
} from './target-audience-filter-model';
import {
    convertParsedCriteria,
} from '../../../filter-and-compare/filter-service';

angular.module('targetAudienceEditor', [
    'pubSub',
    'filterLogicEditor',
    'analysisConfig.manager',
])
    .controller('TargetAudienceEditorCtrl', TargetAudienceEditorCtrl);
TargetAudienceEditorCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$uibModalInstance',
    'pubSubService',
    'analysisConfigManager',
    'targetAudience',
];

/**
 * Controller for the target audience editor modal
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} $uibModalInstance
 * @param {object} pubSubService
 * @param {object} analysisConfigManager
 * @param {object} targetAudience
 */
function TargetAudienceEditorCtrl($scope, $rootScope, $uibModalInstance, pubSubService, analysisConfigManager, targetAudience) {
    const TargetAudienceEditorVM = this;
    const MAX_NAME_LENGTH = 1021;
    TargetAudienceEditorVM.mode = Object.keys(targetAudience).length === 0 ? 'add' : 'edit';
    TargetAudienceEditorVM.targetAudience = targetAudience;
    // VM exposed variables
    TargetAudienceEditorVM.setUpdatedTargetAudience = setUpdatedTargetAudience();
    TargetAudienceEditorVM.ux = {
        nameCharsRemaining: MAX_NAME_LENGTH,
        showNameCharsRemaining: false,
        nameMaxLength: MAX_NAME_LENGTH,
    };

    // VM Exposed functions
    TargetAudienceEditorVM.save = save;
    TargetAudienceEditorVM.enableSave = enableSave;
    TargetAudienceEditorVM.close = close;
    TargetAudienceEditorVM.onNameChange = onNameChange;

    init();

    function init() {
        const traitFactors = analysisConfigManager.get('traitFactors');

        TargetAudienceEditorVM.filterLogic = {
            parsedCriteria: prepareLogic(targetAudience.definitionJson),
            label: 'Target Audience',
            filterLogicModel: getTargetAudienceFilterLogicModel($rootScope.survey, traitFactors),
        };

        pubSubService.subscribe('filter-logic-group-error', $scope.$id, displayErrorMessage);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(['filter-logic-group-error'], $scope.$id);
        });
    }

    /**
     * Event handler on name change
     */
    function onNameChange() {
        TargetAudienceEditorVM.ux.nameCharsRemaining = MAX_NAME_LENGTH - TargetAudienceEditorVM.targetAudience.name.length;
        TargetAudienceEditorVM.ux.showNameCharsRemaining = TargetAudienceEditorVM.ux.nameCharsRemaining < 10;
        if (TargetAudienceEditorVM.ux.nameCharsRemaining <= 0) {
            displayErrorMessage('Max number of chars is 1021');
        }
    }

    /**
     * Display error message
     *
     * @param {string} errorMsg
     */
    function displayErrorMessage(errorMsg) {
        $scope.$evalAsync(() => {
            TargetAudienceEditorVM.errorMsg = errorMsg;
        });
    }

    /**
     * Event handler for closing the modal
     *
     * @param {object} updatedTargetAudience
     */
    function close(updatedTargetAudience) {
        $uibModalInstance.close(updatedTargetAudience);
    }

    /**
     * Checks whether the user has specified any criteria
     *
     * If parsedCriteria array is empty, then it's automatically false. If not empty,
     * check the first item in the array. If the item is another array, then we have nested $and criteria.
     * Otherwise, check that the first item contains at least one criteria item
     *
     * @returns {boolean} - Whether any exposed criteria has been specified
     */
    function hasParsedCriteria() {
        const parsedCriteria = TargetAudienceEditorVM.filterLogic.parsedCriteria;

        if (parsedCriteria && parsedCriteria.length) {
            return Array.isArray(parsedCriteria[0]) ? true : parsedCriteria[0].criteria.length;
        }
        return false;
    }

    /**
     * Validating whether save button should be enabled
     *
     * @returns {boolean} whether the user can save
     */
    function enableSave() {
        return TargetAudienceEditorVM.targetAudience.name && hasParsedCriteria();
    }

    /**
     * Set the updated target audience criteria to the object
     *
     * @returns {Function} set the criteria
     */
    function setUpdatedTargetAudience() {
        return parsedCriteria => {
            if (parsedCriteria) {
                TargetAudienceEditorVM.filterLogic.parsedCriteria = parsedCriteria;
            }
        };
    }

    /**
     * Function for save,
     * it is returning an instantiated promise temporarily
     * but it is supposed to return the promise from the api call that saves the target audience to DB
     *
     * @returns {Promise} promise that contains the target audience, also closes the modal
     */
    function save() {
        return new Promise(() => {
            setDefinitionJson(TargetAudienceEditorVM.filterLogic.parsedCriteria);
            close(TargetAudienceEditorVM.targetAudience);
        }, errorMessage => displayErrorMessage(errorMessage));
    }

    /**
     * Set the definition json of the target audience
     *
     * @param {object} parsedCriteria
     */
    function setDefinitionJson(parsedCriteria) {
        TargetAudienceEditorVM.targetAudience.parsedCriteria = parsedCriteria || TargetAudienceEditorVM.filterLogic.parsedCriteria;
        TargetAudienceEditorVM.targetAudience.definitionJson = convertParsedCriteria(TargetAudienceEditorVM.filterLogic.parsedCriteria, TargetAudienceEditorVM.filterLogic.filterLogicModel);
    }
}
