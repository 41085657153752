import '../common-components/pub-sub';
import '../results-components/stats-module';
import '../charts-widget/chart-data-parser';
import '../filter-and-compare/filter-and-compare-model-manager';

angular.module('customVariables.charts', [
    'pubSub',
    'StatsModule',
    'chart.dataParser',
    'filterAndCompare.modelManager',
])
    .directive('customVariablesCharts', customVariablesCharts)
    .controller('VariablesChartsCtrl', VariablesChartsCtrl);

/**
 *
 */
function customVariablesCharts() {
    return {
        restrict: 'E',
        templateUrl: 'dashboard-templates/results/custom-variables/custom-variable-charts.html',
        controller: 'VariablesChartsCtrl',
        controllerAs: 'VariablesChartsVM',
    };
}
VariablesChartsCtrl.$inject = [
    '$rootScope',
    'pubSubService',
    '$statsService',
    'chartDataParser',
    'filterAndCompareModelManager',
];

/**
 * @param $rootScope
 * @param pubSubService
 * @param $statsService
 * @param chartDataParser
 * @param filterAndCompareModelManager
 */
function VariablesChartsCtrl($rootScope, pubSubService, $statsService, chartDataParser, filterAndCompareModelManager) {
    const VariablesChartsVM = this;
    VariablesChartsVM.ux = {
        chartsRefreshing: true,
    };
    VariablesChartsVM.survey = $rootScope.survey;
    VariablesChartsVM.customVariables = $rootScope.survey._statsConfig.filters.customVariables ? angular.copy($rootScope.survey._statsConfig.filters.customVariables.options) : [];
    VariablesChartsVM.chartData = [];

    init();

    function init() {
        filterAndCompareModelManager.initialize();
        getChartData();
    }

    function getChartData() {
        const length = VariablesChartsVM.customVariables.length,
            promises = [];
        if (!length) {
            return;
        }
        _.forEach(VariablesChartsVM.customVariables, (variable, index) => {
            let promise;
            VariablesChartsVM.chartData[index] = {
                type: 'singleSelect',
                customVariable: variable,
                details: {
                    type: 'customVariables',
                    statement: {
                        text: variable.name,
                    },
                },
            };
            VariablesChartsVM.ux.chartsRefreshing = true;
            promise = new Promise(resolve => {
                $statsService.getVariableTally($rootScope.survey, variable).then(response => {
                    if (index + 1 === length) {
                        VariablesChartsVM.ux.chartsRefreshing = false;
                    }
                    resolve(chartDataParser.parseTallyData(response.countTally, response.numRespondents));
                }, () => {
                    resolve();
                });
            });
            promises.push(promise);
            pubSubService.notify('render-chart-' + variable.code, [promise, _.map(variable.options, 'filterJson')]);
        });
        Promise.all(promises).then(() => {
            VariablesChartsVM.ux.chartsRefreshing = false;
        });
    }
}
