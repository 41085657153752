const regionCuts = {
    // Region cut options
    category: 'Region',
    categoryType: 'demographic',
    type: {
        value: 'equivalent',
        name: 'Equivalent',
    },
    controlType: 'Equivalent',
    options: [{
        name: 'Northeast',
        value: 'Region: Northeast',
        exposed: {
            $eq: ['Demographic$Region', 'Northeast'],
        },

    },
    {
        name: 'Midwest',
        value: 'Region: Midwest',
        exposed: {
            $eq: ['Demographic$Region', 'Midwest'],
        },
    },
    {
        name: 'South',
        value: 'Region: South',
        exposed: {
            $eq: ['Demographic$Region', 'South'],
        },
    },
    {
        name: 'West',
        value: 'Region: West',
        exposed: {
            $eq: ['Demographic$Region', 'West'],
        },
    }],
};

export default regionCuts;
