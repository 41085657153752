import '../../../../common/js/inc/sv-notify';
import '../survey/api-survey-service';
import './survey-admin-service';
angular.module('SurveyTakeModule', [
    'ui.router',
    'svNotify',
    'apiSurveyService',
    'surveyAdminService',
])
    .controller('surveyTakeCtrl', surveyTakeCtrl);
surveyTakeCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$notify',
    '$stateParams',
    'surveyService',
    'adminService',
];
/**
 * @param $rootScope
 * @param $scope
 * @param $notify
 * @param $stateParams
 * @param surveyService
 * @param adminService
 */
function surveyTakeCtrl($rootScope, $scope, $notify, $stateParams, surveyService, adminService) {
    $scope.survey = $rootScope.survey;
    $scope.ux = {
        editingTotalPanelCosts: false,
        panelCostCents: 0,
    };

    $scope.takeParams = {
        panelist: '[ID]',
    };
    $scope.take = {
        storedParams: '',
        allowMultiple: false,
        panelConfigs: [],
    };

    if ($stateParams.surveyId) {
        if (!$scope.survey) {
            $scope.survey = $rootScope.survey;
        }
        if ($scope.survey.surveyTakeConfiguration) {
            $scope.take.storedParams = $scope.survey.surveyTakeConfiguration.rememberParameters;
            $scope.take.allowMultiple = $scope.survey.surveyTakeConfiguration.allowMultiple;
        }

        adminService.refreshPanelDetails($scope.survey).then(function(details) {
            $scope.$evalAsync(() => {
                $scope.take.panelConfigs = details;
            });
        });
    }

    $scope.saveTakeConfig = saveTakeConfig;
    function saveTakeConfig() {
        var params = {
            rememberParameters: $scope.take.storedParams || '',
            allowMultiple: $scope.take.allowMultiple ? 'true' : 'false',
        };
        surveyService.updateTakeConfiguration($scope.survey.uuid, params).then(function(config) {
            $scope.$evalAsync(() => {
                $scope.take.storedParams = config.rememberParameters;
                $scope.take.allowMultiple = config.allowMultiple;
            });
            $notify.success('Take info saved!', {
                clear: true,
                timeout: 1500,
            });
        });
    }

    $scope.editTotalPanelCost = editTotalPanelCost;
    function editTotalPanelCost() {
        $scope.ux.panelCostCents = $scope.survey.surveyTakeConfiguration ? $scope.survey.surveyTakeConfiguration.totalPanelCostCents : $scope.ux.panelCostCents;
    }

    $scope.cancelTotalPanelCost = cancelTotalPanelCost;
    function cancelTotalPanelCost() {
        $scope.ux.panelCostCents = 0;
    }

    $scope.saveTotalPanelCost = saveTotalPanelCost;
    function saveTotalPanelCost() {
        surveyService.updateTakeConfiguration($scope.survey.uuid, {
            totalPanelCostCents: $scope.ux.panelCostCents,
        }).then(function(takeConfig) {
            $scope.$evalAsync(() => {
                $rootScope.survey.surveyTakeConfiguration = takeConfig;
            });
        });
    }

    $scope.newPanelDetails = newPanelDetails;
    function newPanelDetails() {
        $scope.currentPanelConfig = {};
    }

    $scope.cancelPanelDetailsEdit = cancelPanelDetailsEdit;
    function cancelPanelDetailsEdit() {
        $scope.currentPanelConfig = null;
    }

    $scope.editPanelDetails = editPanelDetails;
    /**
     * @param config
     */
    function editPanelDetails(config) {
        $scope.currentPanelConfig = config;
    }

    $scope.savePanelDetails = savePanelDetails;
    function savePanelDetails() {
        adminService.savePanelDetails($scope.currentPanelConfig, $scope.survey).then(function() {
            adminService.refreshPanelDetails($scope.survey).then(function(details) {
                $scope.take.panelConfigs = details;
            });
            $scope.currentPanelConfig = null;
        });
    }

    $scope.routerUrl = routerUrl;
    /**
     * @param action
     */
    function routerUrl(action) {
        return 'https://router.survata.com/' + action + '/' + $scope.survey.uuid;
    }

    $scope.takeUrl = takeUrl;
    /**
     *
     */
    function takeUrl() {
        return 'https://take.survata.com#/' + $scope.survey.uuid + '/' + adminService.buildTakeParamString($scope.currentPanelConfig, $scope.takeParams);
    }

    $scope.previewUrl = previewUrl;
    /**
     *
     */
    function previewUrl() {
        return 'https://take.survata.com#/preview/' + $scope.survey.uuid + '/' + adminService.buildTakeParamString($scope.currentPanelConfig, $scope.takeParams);
    }
}
