import '../../../../common/js/inc/sv-notify';
import '../common-components/pub-sub';
import '../question-types/question-type-groups';
import '../survey-creation/survey-creation-service';

angular.module('questionDropdown.view', [
    'svNotify',
    'pubSub',
    'questionTypeGroups.service',
    'surveyCreation.service',
])
    .controller('QuestionDropdownCtrl', QuestionDropdownCtrl)
    .directive('questionDropdown', questionDropdown);

/**
 *
 */
function questionDropdown() {
    return {
        restrict: 'E',
        link: function(scope, _elem, _attr, QuestionDropdownVM) {
            // Whether to display the "Add question" menu as a drop-up or drop-down.
            QuestionDropdownVM.dropUp = false;
            QuestionDropdownVM.updateDropUp = updateDropUp;

            function updateDropUp() {
                // Display as a "drop-up" if the drop-up menu fits in the window.
                var $dropButton = $('.q-dropdown-btn'),
                    $dropMenu = $('.q-dropdown-menu');
                QuestionDropdownVM.dropUp = ($dropMenu.height() + 50) < $dropButton.offset().top;
                // Set an explicit width on the dropdown menu group names
                // so that group/attribute names align.
                var $groupNames = $('.dropdown-group-name'),
                    width = 0;
                _.forEach($groupNames, function(e) {
                    var w = $(e).width();
                    width = w > width ? w : width;
                });

                $groupNames.css('min-width', width.toFixed() + 'px');
            }
        },
        templateUrl: 'survey-creation-templates/design/question-dropdown.html',
        controller: 'QuestionDropdownCtrl',
        controllerAs: 'QuestionDropdownVM',

    };
}
QuestionDropdownCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$notify',
    '$timeout',
    'pubSubService',
    'questionTypeGroupsService',
    'surveyCreationService',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $notify
 * @param $timeout
 * @param pubSubService
 * @param QTGS
 * @param surveyCreationService
 */
function QuestionDropdownCtrl($rootScope, $scope, $notify, $timeout, pubSubService, QTGS, surveyCreationService) {
    var QuestionDropdownVM = this;
    $scope.dashboardView = $rootScope.dashboardView;
    QuestionDropdownVM.questionTypes = QTGS.types;
    QuestionDropdownVM.questionTypeGroups = QTGS.groupList;
    QuestionDropdownVM.questionDisabled = surveyCreationService.questionDisabled;
    QuestionDropdownVM.canAddQuestion = surveyCreationService.canAddQuestion;
    QuestionDropdownVM.add = add;
    QuestionDropdownVM.isEligibleQuestionType = isEligibleQuestionType;
    QuestionDropdownVM.questionTooltip = questionTooltip;

    init();

    function init() {
        _.forEach(QuestionDropdownVM.questionTypeGroups, function(group) {
            _.forEach(group.typeList, function(type) {
                type.disabled = QuestionDropdownVM.questionDisabled(type);
                type.tooltip = QuestionDropdownVM.questionTooltip(type);
            });
        });
    }

    // Is this an eligible question type for what type of survey we're running?
    /**
     * @param type
     */
    function isEligibleQuestionType(type) {
        if (type.alwaysHide) {
            return false;
        }
        return $rootScope.auth.user.isAdmin || type[$rootScope.survey.surveyPackage];
    }

    // Create: create a new question and select it for editing.
    /**
     * @param type
     */
    function add(type) {
        if (surveyCreationService.questionDisabled(type)) {
            return;
        }

        $notify.clearList(); // Trigger any pending deletes

        var q = {
            statement: {
                text: '',
                image: null,
                video: null,
            },
            type: type.id,
            details: angular.copy(type.initialDetails || {}),
            _config: QuestionDropdownVM.questionTypes[type.id],
        };
        pubSubService.notify('add-new-question', [q]);

        pubSubService.notify('update-check-question-errors', [false]);

        $timeout(function() {
            $('textarea.question-statement-plain').focus();
        }, 750);
    }

    // Question tooltips
    /**
     * @param type
     */
    function questionTooltip(type) {
        var tooltip = {
            type: '',
            placement: '',
            text: QTGS.questionTooltipText(type, $rootScope.funnel),
        };
        if (surveyCreationService.questionDisabled(type) && tooltip.text) {
            tooltip.placement = $scope.breakpoint === 'xxs' || $scope.breakpoint === 'xs' ? 'bottom' : 'right';
            tooltip.type = 'tooltip';
        }
        return tooltip;
    }
}
