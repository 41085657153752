import '../../../common-components/display-table';
import '../../../common-components/pub-sub';
import '../../../common-components/sv-dialog';
import '../../../../../../common/js/inc/sv-notify';
import '../../../filter-logic/filter-logic-editor';
import '../analysis-config-manager';
import './target-audience-editor';
import targetAudiencesConstants from './target-audiences-constants';
import analysisConfigConstants from '../analysis-config-constants';
import analysisConfigDataService from '../analysis-config-data-service';

angular.module('targetAudiences.view', [
    'pubSub',
    'svNotify',
    'common.dialog',
    'targetAudienceEditor',
    'modalService',
    'analysisConfig.manager',
])
    .directive('targetAudiences', targetAudiences)
    .controller('targetAudiencesCtrl', targetAudiencesCtrl);

/**
 * Target Audiences directive
 *
 * @returns {object} The directive JSON
 */
function targetAudiences() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/target-audiences/target-audiences.html',
        controller: 'targetAudiencesCtrl',
        controllerAs: 'targetAudiencesVM',
    };
}

targetAudiencesCtrl.$inject = [
    '$scope',
    '$notify',
    'pubSubService',
    'dialog',
    'modalService',
    'analysisConfigManager',
];

/**
 * Target Audiences controller
 *
 * @param {object} $scope
 * @param {object} $notify
 * @param {object} pubSubService
 * @param {object} dialog
 * @param {object} modalService
 * @param {object} analysisConfigManager
 */
function targetAudiencesCtrl($scope, $notify, pubSubService, dialog, modalService, analysisConfigManager) {
    const TargetAudiencesVM = this;
    const analysisConfig = analysisConfigManager.get();
    let targetAudienceFactor = analysisConfig.targetAudience;
    TargetAudiencesVM.tableHeaders = targetAudiencesConstants.tableHeaders;
    TargetAudiencesVM.tableOptions = targetAudiencesConstants.tableOptions;
    TargetAudiencesVM.targetAudiences = [];
    TargetAudiencesVM.openTargetAudienceEditor = openTargetAudienceEditor;

    init();

    function init() {
        TargetAudiencesVM.targetAudiences = targetAudienceFactor ? targetAudienceFactor.levels : [];
        TargetAudiencesVM.disableAdd = disableAdd;
        setReady();
        pubSubService.subscribe('open-target-audience-editor', $scope.$id, openTargetAudienceEditor);
        pubSubService.subscribe('delete-target-audience', $scope.$id, deleteTargetAudience);
        $scope.$on('$destroy', () => {
            pubSubService.destroy(['open-target-audience-editor', 'delete-target-audience'], $scope.$id);
        });
    }

    /**
     * Set the UI to be ready
     */
    function setReady() {
        $scope.$evalAsync(() => {
            TargetAudiencesVM.ready = true;
        });
    }

    /**
     * Disable add new target audience button
     *
     * @returns {boolean}
     */
    function disableAdd() {
        return TargetAudiencesVM.targetAudiences.length >= targetAudiencesConstants.maxTargetAudiences;
    }
    /**
     * Open custom cut editor.
     *
     * @param {object} [targetAudience] - Cut to edit
     */
    function openTargetAudienceEditor(targetAudience) {
        targetAudience = targetAudience || {};
        const targetAudienceUUID = targetAudience.uuid;
        if (!targetAudienceUUID && TargetAudiencesVM.targetAudiences.length >= targetAudiencesConstants.maxTargetAudiences) {
            $notify.error(`You can have at most 10 target audiences for each campaign`);
            return;
        }
        const editor = dialog.popup({
            templateUrl: '/dashboard-templates/ad-measurement/analysis-plan/target-audiences/target-audience-editor.html',
            controller: 'TargetAudienceEditorCtrl',
            controllerAs: 'TargetAudienceEditorVM',
            windowClass: 'analysis-config modal-sv--full-height modal-sv--lg-width',
            resolve: {
                targetAudience: () => {
                    // Create a new object so that if the save fails
                    // the existing item does not get changed
                    return Object.create(targetAudience);
                },

            },
        });

        editor.result.then(updatedTargetAudience => {
            if (updatedTargetAudience) {
                if (!targetAudienceFactor) {
                    createAndSaveFactor();
                }
                updatedTargetAudience.factorType = analysisConfigConstants.TARGET_AUDIENCE;
                analysisConfigDataService.updateFactorLevel(targetAudienceFactor.uuid, updatedTargetAudience).then(respTargetAudience => {
                    if (!respTargetAudience.uuid) {
                        $notify.error(`There was an error saving the target audience: ${updatedTargetAudience.name}`);
                        return;
                    }
                    if (targetAudienceUUID) {
                        const updateIndex = TargetAudiencesVM.targetAudiences.findIndex(item =>
                            item.uuid === respTargetAudience.uuid
                        );
                        TargetAudiencesVM.targetAudiences[updateIndex] = updatedTargetAudience;
                    }
                    else {
                        updatedTargetAudience.uuid = respTargetAudience.uuid;
                        TargetAudiencesVM.targetAudiences.push(updatedTargetAudience);
                        TargetAudiencesVM.targetAudiences.sort((target1, target2) => target1.name < target2.name ? -1 : 1);
                    }
                    pubSubService.notify('refresh-table');
                }, () => {
                    $notify.error(`There was an error saving the target audience: ${updatedTargetAudience.name}`);
                });
            }
        }, () => {
            // From the doc:
            // result (Type: promise) - Is resolved when a modal is closed and rejected when a modal is dismissed.
            // See below for more details:
            // https://angular-ui.github.io/bootstrap/#!#modal
            console.log('Modal dismissed');
        });
    }

    /**
     * Create and save an new target audience factor.
     */
    function createAndSaveFactor() {
        const newFactor = Object.create(targetAudiencesConstants.newFactorModel);
        analysisConfigDataService.saveAnalysisFactor(analysisConfig.version, newFactor).then(response => {
            if (!response.uuid) {
                $notify.error(`There was an error saving target audience factor for configuration: ${analysisConfig.uuid}`);
                return;
            }
            newFactor.uuid = response.uuid;
            targetAudienceFactor = newFactor;
        }, () => {
            $notify.error(`There was an error saving target audience factor for configuration: ${analysisConfig.uuid}`);
        });
    }

    /**
     * Delete a custom cut.
     *
     * @param {object} targetAudience - The cut to delete
     */
    function deleteTargetAudience(targetAudience) {
        const targetAudienceIndex = TargetAudiencesVM.targetAudiences.findIndex(item => item.uuid === targetAudience.uuid);
        modalService.openConfirmDeleteModal({
            item: () => {
                return 'target audience';
            },
            callback: () => {
                return () => {
                    analysisConfigDataService.deleteFactorLevel(targetAudience.uuid).then(deletedLevel => {
                        if (!deletedLevel.uuid) {
                            $notify.error(`There was an error deleting target audience: ${targetAudience.name}`);
                            return;
                        }
                        TargetAudiencesVM.targetAudiences.splice(targetAudienceIndex, 1);
                        pubSubService.notify('refresh-table');
                    }, () => {
                        $notify.error(`There was an error deleting target audience: ${targetAudience.name}`);
                    });
                };
            },
            arg: () => {
                return targetAudience;
            },
            title: () => {
                return `Are you sure you want to delete target audience "${targetAudience.name}"?`;
            },
        });
    }
}
