import _ from 'lodash';
import 'ng-file-upload/dist/angular-file-upload-all';
import httpDeferred from './../../../../common/js/http-deferred';
import U from './../../../../common/js/util';
import surveyConstants from '../survey/survey-constants';
import './survey-statuses';

angular.module('apiSurveyService', [
    'ui.router', 'angularFileUpload',
])
    .service('surveyService', surveyService);
surveyService.$inject = [
    '$q',
    '$state',
    '$upload',
];

/**
 * Defines the survey service.
 *
 * @param {*} $q
 * @param {*} $state
 * @param {*} $upload
 * @returns {*}
 */
function surveyService($q, $state, $upload) {
    const defaultTargeting = [{
            attribute: 'country',
            details: {
                type: 'US',
                countries: [],
            },
        }],
        defaultAudience = {
            sampleSize: '' + 10000,
            targetingReqs: defaultTargeting,
        };

    /* Single survey functions */
    /**
     * Gets a survey.
     *
     * @param {*} uuid
     * @returns {Promise<*>}
     */
    function getSurvey(uuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + uuid);
    }

    /**
     * Creates a survey.
     *
     * @param {*} params
     * @returns {Promise<*>}
     */
    function createSurvey(params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey', params || {});
    }

    /**
     * Updates a survey.
     *
     * @param {*} uuid
     * @param {*} updates
     * @returns {Promise<*>}
     */
    function updateSurvey(uuid, updates) {
        return httpDeferred.put(httpDeferred.dashboardHost + '/v1/survey/' + uuid, updates);
    }

    // STATUS
    /**
     * Updates the Survey's status.
     *
     * @param {*} action
     * @param {*} survey
     * @returns {Promise<*>}
     */
    // eslint-disable-next-line complexity
    function updateSurveyStatus(action, survey) {
        let surveyParams = {},
            surveyActions = surveyConstants.SURVEY_ACTIONS;

        switch (action) {
            case surveyActions.START:
                surveyParams.start = true;
                break;
            case surveyActions.PAUSE:
                surveyParams.paused = true;
                break;
            case surveyActions.RESTART:
                surveyParams.restart = true;
                break;
            case surveyActions.START_REVIEW:
                surveyParams.startedReview = true;
                break;
            case surveyActions.COMPLETE_REVIEW:
                surveyParams.reviewed = true;
                break;
            case surveyActions.REQUEST_PROGRAMMING:
                surveyParams.requestProgramming = true;
                break;
            case surveyActions.COMPLETE_PROGRAMMING:
                surveyParams.programmingComplete = true;
                break;
            case surveyActions.PANEL:
                surveyParams.panelFulfilled = true;
                break;
            case surveyActions.UNPANEL:
                surveyParams.panelFulfilled = false;
                break;
            case surveyActions.NETWORK:
                surveyParams.networkFulfilled = true;
                break;
            case surveyActions.UNNETWORK:
                surveyParams.networkFulfilled = false;
                break;
            case surveyActions.ADVANCED:
                surveyParams.advanced = true;
                surveyParams.surveyPackage = surveyConstants.PACKAGES.ADVANCED;
                break;
            case surveyActions.BASIC:
                surveyParams.advanced = false;
                surveyParams.surveyPackage = surveyConstants.PACKAGES.BASIC;
                break;
            case surveyActions.EXTENDED:
                surveyParams.advanced = false;
                surveyParams.surveyPackage = surveyConstants.PACKAGES.EXTENDED;
                break;
            case surveyActions.ABANDONED:
                surveyParams.abandoned = true;
                break;
            case surveyActions.DRAFT_COMPLETE:
                surveyParams.draftComplete = true;
                break;
            case surveyActions.APPROVE:
                return approveSurvey(survey.uuid);
            case surveyActions.UNAPPROVE:
                surveyParams.unapprove = true;
                break;
            case surveyActions.UNREVIEW:
                surveyParams.unreview = true;
                break;
            case surveyActions.START_QUOTE_PROCESS:
                surveyParams.quoteProcessStart = true;
                break;
            case surveyActions.ACCEPT_QUOTE:
                surveyParams.acceptQuote = true;
                break;
            case surveyActions.REQUEST_QUOTE:
                surveyParams.quoteRequested = true;
                break;
            default:
        }
        return updateSurvey(survey.uuid, surveyParams);
    }

    /**
     * Deletes a survey.
     *
     * @param {*} surveyUuid
     * @returns {Promise<*>}
     */
    function deleteSurvey(surveyUuid) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid);
    }

    /**
     * Gets a survey verbose.
     *
     * @param {*} uuid
     * @returns {*}
     */
    function getSurveyVerbose(uuid) {
        let deferred = $q.defer();

        httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + uuid).then(function(response) {
            getAudience(response.uuid).then(function(audience) {
                setAudienceInSurvey(response, audience);
                listScreeningQuestions(response._audienceId).then(function(screeners) {
                    response.screeningQuestions = screeners;
                    listQuestions(uuid).then(function(questions) {
                        response.questions = questions;
                        // eslint-disable-next-line max-nested-callbacks
                    });
                });
            });
        }).catch(function(reason) {
            deferred.reject('Error trying to get survey' + JSON.stringify(reason));
        });
        return deferred.promise;
    }

    /**
     * Gets or creates a survey.
     *
     * @param {*} uuid
     * @param {*} params
     * @returns {*}
     */
    function getOrCreateSurvey(uuid, params) {
        let deferred = $q.defer();
        params = params || {};
        params.uuid = uuid;

        getSurvey(uuid).then(function(response) {
            if (response.uuid) {
                deferred.resolve(response);
            }
            else {
                createSurvey(params).then(function(response) {
                    deferred.resolve(response);
                }, function(reason) {
                    deferred.reject(reason);
                });
            }
        }, function() {
            createSurvey(params).then(function(response) {
                deferred.resolve(response);
            }, function(reason) {
                deferred.reject(reason);
            });
        });
        return deferred.promise;
    }

    /* Survey list functions */

    /**
     * Gets surveys by retargeting id.
     *
     * @param {*} params
     * @returns {Promise<*>}
     */
    function listSurveysByRetargetingId(params) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/all' + U.buildParamString(params));
    }

    /**
     * Gets surveys by user id.
     *
     * @param {*} userId
     * @returns {Promise<*>}
     */
    function listSurveys(userId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey' + (userId ? '?userId=' + userId : ''));
    }

    /**
     * Gets shared surveys by user id.
     *
     * @param {*} userId
     * @returns {Promise<*>}
     */
    function listSharedSurveys(userId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/rest/survey/list-shared' + (userId ? '?userId=' + userId : ''));
    }

    /**
     * Gets surveys by params.
     * Params - max, offset, hideX
     *
     * @param {*} params
     * @returns {Promise<*>}
     */
    function listAllSurveys(params) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/all' + U.buildParamString(params));
    }

    /* Single survey functions */

    /**
     * Updates collection period.
     *
     * @param {*} uuid
     * @param {*} updates
     * @returns {Promise<*>}
     */
    function updateCollectionPeriod(uuid, updates) {
        return httpDeferred.put(httpDeferred.dashboardHost + '/v1/collection-period/' + uuid, updates);
    }

    /**
     * Get survey take configuration.
     *
     * @param {*} uuid
     * @returns {Promise<*>}
     */
    function getTakeConfig(uuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + uuid + '/survey-take-configuration');
    }

    /**
     * Approves survey.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function approveSurvey(surveyId) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/approve');
    }

    /**
     * Duplicates survey.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function duplicateSurvey(surveyId) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/duplicate');
    }

    /**
     * Uploads text.
     *
     * @param {*} surveyId
     * @param {*} label
     * @param {*} text
     * @param {*} adminOnly
     * @returns {*}
     */
    function uploadText(surveyId, label, text, adminOnly) {
        let deferred = $q.defer();
        $upload.upload({
            url: httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-attachment',
            method: 'POST',
            data: {
                name: label,
                adminOnly: adminOnly,
                text: text,
            },
        }).then(function(data) {
            deferred.resolve(data);
        }).catch(function(data) {
            if (data && data.msg) {
                deferred.reject({
                    error: data.msg,
                });
            }
            else {
                deferred.reject({
                    error: 'Error trying to upload text as attachment',
                });
            }
        });
        return deferred.promise;
    }

    /**
     * Gets survey attachments.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function getAttachments(surveyId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-attachment');
    }

    /**
     * Uploads attachment.
     *
     * @param {*} surveyId
     * @param {*} label
     * @param {*} file
     * @param {*} adminOnly
     * @returns {*}
     */
    function uploadAttachment(surveyId, label, file, adminOnly) {
        let deferred = $q.defer();
        $upload.upload({
            url: httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-attachment',
            method: 'POST',
            file: file,
            data: {
                name: label,
                adminOnly: adminOnly,
            },
            fileFormDataName: 'qqfile',
        }).then(function(data) {
            deferred.resolve(data);
        }).catch(function(data) {
            if (data && data.msg) {
                deferred.reject({
                    error: data.msg,
                });
            }
            else {
                deferred.reject({
                    error: 'Error trying to upload attachment',
                });
            }
        });
        return deferred.promise;
    }

    /**
     * Saves attachement.
     *
     * @param {*} surveyId
     * @param {*} label
     * @param {*} url
     * @param {*} adminOnly
     * @returns {Promise<*>}
     */
    function saveAttachment(surveyId, label, url, adminOnly) {
        const params = {
            name: label,
            url: url,
            adminOnly: adminOnly,
        };
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-attachment', params);
    }

    /**
     * Deletes attachment.
     *
     * @param {*} attachment
     * @returns {Promise<*>}
     */
    function deleteAttachment(attachment) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/survey-attachment/' + attachment.uuid);
    }

    /**
     * Gets survey quote.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function getSurveyQuote(surveyId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-quote');
    }

    /**
     * Creates survey quote.
     *
     * @param {*} surveyId
     * @param {*} params
     * @returns {Promise<*>}
     */
    function createSurveyQuote(surveyId, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-quote', params);
    }

    /**
     * Updates survey quote.
     *
     * @param {*} quoteId
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updateSurveyQuote(quoteId, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey-quote/' + quoteId, params);
    }

    /**
     * Creates collection period line.
     *
     * @param {*} collectionPeriodUuid
     * @param {*} params
     * @returns {Promise<*>}
     */
    function createCollectionPeriodLine(collectionPeriodUuid, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/collection-period/' + collectionPeriodUuid + '/collection-period-line', params);
    }

    /**
     * Updates collection period line.
     *
     * @param {*} collectionPeriodUuid
     * @param {*} lineItemUuid
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updateCollectionPeriodLine(collectionPeriodUuid, lineItemUuid, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/collection-period/' + collectionPeriodUuid + '/collection-period-line/' + lineItemUuid, params);
    }

    /**
     * Deletes collection period line.
     *
     * @param {*} collectionPeriodUuid
     * @param {*} lineItemUuid
     * @returns {Promise<string>}
     */
    function deleteCollectionPeriodLine(collectionPeriodUuid, lineItemUuid) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/collection-period/' + collectionPeriodUuid + '/collection-period-line/' + lineItemUuid).then(function() {
            return 'Survey quote line item deleted.';
        }).catch(function() {
            return 'Error trying to delete survey quote line item.';
        });
    }

    /**
     * Finish survey.
     *
     * @param {*} surveyUuid
     * @returns {Promise<*>}
     */
    function finishSurvey(surveyUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/finish');
    }

    /**
     * Gets results summary.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function getResultsSummary(surveyId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/results-summary');
    }

    /**
     * Get period results summary.
     *
     * @param {*} periodUuid
     * @returns {Promise<*>}
     */
    function getPeriodResultsSummary(periodUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/collection-period/' + periodUuid + '/results-summary');
    }

    /* QUESTION FUNCTIONS */
    /**
     * Creates question.
     *
     * @param {*} survey
     * @param {*} question
     * @param {*} simulateError
     * @returns {Promise<*>}
     */
    function createQuestion(survey, question, simulateError) {
        let updatedQuestion = question,
            createQuestionCall = question.screener ? createScreeningQuestion(survey._audienceId, question) : httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + survey.uuid + '/question', updatedQuestion, simulateError);
        return createQuestionCall.then(function(question) {
            return question;
        }, function() {
            return 'Error trying to get survey question';
        });
    }

    /**
     * Gets question.
     *
     * @param {*} questionUuid
     * @returns {Promise<*>}
     */
    function getQuestion(questionUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/question/' + questionUuid).then(function(question) {
            return question;
        }, function() {
            return 'Error trying to get survey question';
        });
    }

    /**
     * Updates question.
     *
     * @param {*} questionId
     * @param {*} question
     * @param {*} simulateError
     * @returns {Promise<*>}
     */
    function updateQuestion(questionId, question, simulateError) {
        return httpDeferred.put(httpDeferred.dashboardHost + '/v1/question/' + questionId, question, simulateError);
    }

    /**
     * Moves question after.
     *
     * @param {*} questionId
     * @param {*} referenceQuestionId
     * @returns {Promise<*>}
     */
    function moveQuestionAfter(questionId, referenceQuestionId) {
        const param = {
            referenceQuestion: referenceQuestionId,
        };
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/question/' + questionId + '/move-after' + U.buildParamString(param));
    }

    /**
     * Moves question before.
     *
     * @param {*} questionId
     * @param {*} referenceQuestionId
     * @returns {Promise<*>}
     */
    function moveQuestionBefore(questionId, referenceQuestionId) {
        const param = {
            referenceQuestion: referenceQuestionId,
        };
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/question/' + questionId + '/move-before' + U.buildParamString(param));
    }

    /**
     * Revert question.
     *
     * @param {*} questionId
     * @returns {Promise<*>}
     */
    function revertQuestion(questionId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/question/' + questionId + '/revert');
    }

    /**
     * Deletes question.
     *
     * @param {*} questionId
     * @returns {Promise<*>}
     */
    function deleteQuestion(questionId) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/question/' + questionId);
    }

    /**
     * Lists questions.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function listQuestions(surveyId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/question').then(function(questions) {
            return questions;
        }, function() {
            return 'Error trying to get survey questions';
        });
    }

    /**
     * Lists original questions.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function listOriginalQuestions(surveyId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/original-questions').then(function(questions) {
            return questions;
        }, function(error) {
            return error;
        });
    }

    /**
     * Gets original question.
     *
     * @param {*} questionId
     * @returns {Promise<*>}
     */
    function getOriginalQuestion(questionId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/question/' + questionId + '/original');
    }

    /* COLLECTION PERIOD FUNCTIONS */

    /**
     * Gets period.
     *
     * @param {*} uuid
     * @returns {Promise<*>}
     */
    function getPeriod(uuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/collection-period/' + uuid);
    }

    /**
     * Gets latest period.
     *
     * @param {*} surveyUuid
     * @returns {Promise<*>}
     */
    function getLatestPeriod(surveyUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/collection-period/LATEST');
    }

    /**
     * Lists periods.
     *
     * @param {*} surveyUuid
     * @returns {Promise<*>}
     */
    function listPeriods(surveyUuid) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/collection-period');
    }

    /**
     * Creates period.
     *
     * @param {*} surveyUuid
     * @param {*} params
     * @returns {Promise<*>}
     */
    function createPeriod(surveyUuid, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/collection-period', params);
    }

    /**
     * Updates period.
     *
     * @param {*} uuid
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updatePeriod(uuid, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/collection-period/' + uuid, params);
    }

    /**
     * Updates latest period.
     *
     * @param {*} surveyUuid
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updateLatestPeriod(surveyUuid, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyUuid + '/collection-period/LATEST', params);
    }

    /**
     * Finish period.
     *
     * @param {*} periodUuid
     * @returns {Promise<*>}
     */
    function finishPeriod(periodUuid) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/collection-period/' + periodUuid + '/finish').then(function(response) {
            return response;
        }, function(error) {
            return 'Unable to finish period. - ' + error;
        });
    }

    /* AUDIENCE FUNCTIONS */
    /**
     * Set audience in survey.
     *
     * @param {*} survey
     * @param {*} audience
     */
    function setAudienceInSurvey(survey, audience) {
        survey.targetingReqs = audience.targetingReqs;
        survey._audienceId = audience.uuid;

        _.forEach(survey.targetingReqs, function(req) {
            if (req.attribute === 'country') {
                survey.countryReq = req;
            }
            if (req.attribute === 'beaconPool') {
                survey.beaconPools = req.details.beaconPools;
            }
        });
    }

    /**
     * Create audience.
     *
     * @param {*} surveyId
     * @param {*} audienceParams
     * @returns {Promise<*>}
     */
    function createAudience(surveyId, audienceParams) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/audience', audienceParams || defaultAudience);
    }

    /**
     * Delete audience child.
     *
     * @param {*} audienceId
     * @param {*} child
     * @returns {Promise<*>}
     */
    function deleteAudienceChild(audienceId, child) {
        return httpDeferred.delete(httpDeferred.dashboardHost + '/v1/audience/' + audienceId + '/' + child);
    }

    /**
     * Update audience.
     *
     * @param {*} audienceId
     * @param {*} audienceParams
     * @returns {Promise<*>}
     */
    function updateAudience(audienceId, audienceParams) {
        return httpDeferred.put(httpDeferred.dashboardHost + '/v1/audience/' + audienceId, audienceParams);
    }

    /**
     * Get audience.
     *
     * @param {*} surveyId
     * @returns {Promise<*>}
     */
    function getAudience(surveyId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/audience');
    }

    /**
     * Create screening question.
     *
     * @param {*} audienceId
     * @param {*} question
     * @returns {Promise<*>}
     */
    function createScreeningQuestion(audienceId, question) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/audience/' + audienceId + '/question', question);
    }

    /**
     * List screening questions.
     *
     * @param {*} audienceId
     * @returns {Promise<*>}
     */
    function listScreeningQuestions(audienceId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/audience/' + audienceId + '/question');
    }

    /**
     * Update quote request.
     *
     * @param {*} surveyId
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updateQuoteRequest(surveyId, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-quote-request', params);
    }

    /**
     * Update take configuration.
     *
     * @param {*} surveyId
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updateTakeConfiguration(surveyId, params) {
        return httpDeferred.post(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-take-configuration', params);
    }

    /**
     * List panel details.
     *
     * @param {*} surveyId
     * @param {*} panelId
     * @returns {Promise<*>}
     */
    function listPanelDetails(surveyId, panelId) {
        return httpDeferred.get(httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-panel-details' + (panelId ? '?panelPartner=' + panelId : ''));
    }

    /**
     * Update panel details.
     *
     * @param {*} surveyId
     * @param {*} params
     * @returns {Promise<*>}
     */
    function updatePanelDetails(surveyId, params) {
        let url;
        if (params.uuid) {
            url = httpDeferred.dashboardHost + '/v1/survey-panel-details/' + params.uuid;
        }
        else {
            url = httpDeferred.dashboardHost + '/v1/survey/' + surveyId + '/survey-panel-details';
        }

        return httpDeferred.post(url, params);
    }

    return {
        /* Create, show, update, approve and delete surveys */
        createSurvey: createSurvey,
        getSurvey: getSurvey,
        getSurveyVerbose: getSurveyVerbose,
        getOrCreateSurvey: getOrCreateSurvey,
        updateSurvey: updateSurvey,
        listSurveys: listSurveys,
        listSharedSurveys: listSharedSurveys,
        listAllSurveys: listAllSurveys,
        approveSurvey: approveSurvey,
        duplicateSurvey: duplicateSurvey,
        deleteSurvey: deleteSurvey,
        updateSurveyStatus: updateSurveyStatus,
        finishSurvey: finishSurvey,

        /* Submit/update survey quote request */
        updateQuoteRequest: updateQuoteRequest,

        /* Submit/update survey take configuration */
        getTakeConfig: getTakeConfig,
        updateTakeConfiguration: updateTakeConfiguration,
        listPanelDetails: listPanelDetails,
        updatePanelDetails: updatePanelDetails,

        /* Results */
        getResultsSummary: getResultsSummary,

        /* List retargeting accounts */
        listSurveysByRetargetingId: listSurveysByRetargetingId,

        /* Functions for survey quote and line items */
        getSurveyQuote: getSurveyQuote,
        createSurveyQuote: createSurveyQuote,
        updateSurveyQuote: updateSurveyQuote,

        /* Create, show, update and delete questions */
        createQuestion: createQuestion,
        getQuestion: getQuestion,
        updateQuestion: updateQuestion,
        getOriginalQuestion: getOriginalQuestion,
        listQuestions: listQuestions,
        listOriginalQuestions: listOriginalQuestions,
        revertQuestion: revertQuestion,
        deleteQuestion: deleteQuestion,
        moveQuestionBefore: moveQuestionBefore,
        moveQuestionAfter: moveQuestionAfter,

        /* Create, show, update and delete audiences */
        setAudienceInSurvey: setAudienceInSurvey,
        createAudience: createAudience,
        updateAudience: updateAudience,
        getAudience: getAudience,
        deleteAudienceChild: deleteAudienceChild,
        createScreeningQuestion: createScreeningQuestion,
        listScreeningQuestions: listScreeningQuestions,

        /* Functions for collection periods */
        getPeriod: getPeriod,
        getLatestPeriod: getLatestPeriod,
        listPeriods: listPeriods,
        createPeriod: createPeriod,
        updatePeriod: updatePeriod,
        finishPeriod: finishPeriod,
        updateLatestPeriod: updateLatestPeriod,
        updateCollectionPeriod: updateCollectionPeriod,
        getPeriodResultsSummary: getPeriodResultsSummary,

        /* Functions for collection period lines */
        createCollectionPeriodLine: createCollectionPeriodLine,
        updateCollectionPeriodLine: updateCollectionPeriodLine,
        deleteCollectionPeriodLine: deleteCollectionPeriodLine,

        /* Attachment functions */
        getAttachments: getAttachments,
        uploadAttachment: uploadAttachment,
        saveAttachment: saveAttachment,
        deleteAttachment: deleteAttachment,
        uploadText: uploadText,

    };
}
