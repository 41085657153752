import analysisConfigConstants from '../analysis-config-constants';

const targetAudienceCutsConstants = {
    tableHeaders: [{
        name: '',
        selectAll: true,
    }, {
        name: 'Cut name',
        sortable: true,
        tooltip: true,
        columnType: 'text',
        sortField: 'name',
        value: 'name',
    }],

    tableOptions: {
        noSearch: true,
        nestedRows: [{
            nestedRowField: 'options',
            type: 'table',
            rowClass: 'overflow-visible',
            columns: [{
                iterable: true,
                columnType: 'input',
                inputType: 'checkbox',
                cellClass: 'padding-right-sm',
                eventName: 'update-cut-list',
                model: '_selected',
            }],
        }],
    },
    newCategoryModel: {
        category: analysisConfigConstants.TARGET_AUDIENCE_NAME,
        _selected: true,
        categoryType: analysisConfigConstants.TARGET_AUDIENCE,
    },
};

export default targetAudienceCutsConstants;
