angular.module('grid.preview', [])
    .directive('gridPreview', gridPreview);

function gridPreview() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/grid-preview.html',
        scope: {
            question: '=',
        },
    };
}
