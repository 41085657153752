angular.module('PaneStateManager', [])
    .factory('paneStateManager', paneStateManager);

function paneStateManager() {
    function togglePanes(type, pane) {
        var oldState = pane.collapsed[type];

        // Make all panes collapsed before dealing with the currently selected tab
        pane.collapsed = initializeCollapsedState();
        // If no type, expect the tab to be pressed, and return all as collapsed
        if (!type) {
            return;
        }
        // Invert the state of the currently selected tab
        pane.collapsed[type] = !oldState;
        // Allows us to know if any panes are open
        pane.collapsed.all = pane.collapsed.compare && pane.collapsed.filter && pane.collapsed.periods;
        // Force redraw collection periods rzslider to fix a width issue
        return pane;
    }

    function initializeCollapsedState(state) {
        let collapsedState;
        if (state) {
            collapsedState = {
                all: true,
            };
            collapsedState[state] = true;
        }
        else {
            collapsedState = {
                all: true,
                compare: true,
                filter: true,
                periods: true,
            };
        }
        return collapsedState;
    }

    function initializeSettings(state) {
        let settings;
        if (state) {
            settings = {};
            settings[state] = true;
        }
        else {
            settings = {
                compare: false,
                filter: false,
                periods: false,
            };
        }
        return settings;
    }

    return {
        togglePanes: togglePanes,
        initializeSettings: initializeSettings,
        initializeCollapsedState: initializeCollapsedState,
    };
}
