import '@uirouter/angularjs';
import '../common-components/modal-service';
import '../common-components/pub-sub';
import customVariablesService from '../custom-variables/custom-variables.api-service';
import '../charts-widget/chart-data-parser';
import '../filter-and-compare/filter-and-compare-model-manager';
import './stats-module';

angular.module('dashboardResults.chart', [
    'ui.router',
    'modalService',
    'pubSub',
    'filterAndCompare.modelManager',
    'StatsModule',
    'chart.dataParser',
])
    .controller('DashboardResultsChartCtrl', DashboardResultsChartCtrl)
    .directive('dashboardResultsChart', dashboardResultsChart);

/**
 *
 */
function dashboardResultsChart() {
    return {
        restrict: 'E',
        require: '^ChartWidgetCtrl',
        controller: 'DashboardResultsChartCtrl',
        controllerAs: 'DashboardResultsChartVM',
        scope: false,
    };
}
DashboardResultsChartCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'modalService',
    'pubSubService',
    'filterAndCompareModelManager',
    '$statsService',
    'chartDataParser',
];

/**
 * @param $rootScope
 * @param $scope
 * @param $state
 * @param modalService
 * @param pubSubService
 * @param filterAndCompareModelManager
 * @param $statsService
 * @param chartDataParser
 */
function DashboardResultsChartCtrl($rootScope, $scope, $state, modalService, pubSubService, filterAndCompareModelManager, $statsService, chartDataParser) {
    var DashboardResultsChartVM = this,
        ChartWidgetVM = $scope.ChartWidgetVM || $scope.TextChartVM;
    DashboardResultsChartVM.ux = {
        deleted: false,
    };
    DashboardResultsChartVM.canAddToReport = false;
    DashboardResultsChartVM.deleteVariable = deleteVariable;
    DashboardResultsChartVM.openEditor = openEditor;

    // Delete a variable
    /**
     * @param cv
     */
    function deleteVariable(cv) {
        customVariablesService.delete($rootScope.survey, cv).then(() => {
            let idx = _.findIndex($rootScope.survey._statsConfig.filters.customVariables.options, customVariable => {
                return customVariable.uuid === cv.uuid;
            });
            $rootScope.survey._statsConfig.filters.customVariables.options.splice(idx, 1);
            DashboardResultsChartVM.ux.deleted = true;
        });
    }

    // Show modal to edit an individual variable
    /**
     * @param cv
     * @param idx
     * @param $event
     */
    function openEditor(cv, idx, $event) {
        modalService.optionCustomVariableEditor(cv, $event).result.then(updatedCv => {
            if (!updatedCv) {
                return;
            }
            $rootScope.survey._statsConfig.filters.customVariables.options[idx] = customVariablesService.getConvertedStatsConfigObj(updatedCv, idx);
            filterAndCompareModelManager.filterModel().Custom$Variable.options.push($rootScope.survey._statsConfig.filters.customVariables.options[idx]);
            pubSubService.notify('update-chart-data-' + cv.code, [void 0, new Promise(resolve => {
                $statsService.getVariableTally($rootScope.survey, $rootScope.survey._statsConfig.filters.customVariables.options[idx]).then(response => {
                    resolve(chartDataParser.parseTallyData(response.countTally, response.numRespondents));
                }, resolve);
            })]);
            pubSubService.notify('custom-variable-filters-updated');
        });
    }
}
