const dashboardConstants = new Map([
    ['resultsViews', [{
        name: 'Charts',
        id: 'charts',
        view: 'charts',
        subViews: [{
            id: 'survey-results',
            name: 'Survey Results',
            view: 'surveyResultsCharts',
            parent: 'charts',
        },
        {
            id: 'respondents',
            name: 'Demographic Data',
            view: 'respondentCharts',
            parent: 'charts',
        },
        {
            value: 'variables',
            name: 'Variables',
            view: 'variableCharts',
            parent: 'charts',
        }],
    }, {
        name: 'Download',
        id: 'download',
        view: 'download',
    }]],
]);

export default dashboardConstants;
