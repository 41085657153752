import U from '../../../../../common/js/util';
import '../../../../../common/js/inc/sv-notify';
import '../ad-measurement.api-service';

angular
    .module('campaignForm.view', [
        'adMeasurement.apiService',
    ])
    .directive('campaignForm', campaignForm)
    .controller('CampaignFormCtrl', CampaignFormCtrl);

CampaignFormCtrl.inject = [
    '$rootScope',
    '$scope',
    '$notify',
    'adMeasurementApiService',
];

/**
 * Campaign form directive.
 *
 * @returns {object} Campaign form directive object
 */
function campaignForm() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/campaign-form.html',
        controller: 'CampaignFormCtrl',
        controllerAs: 'CampaignFormVM',
    };
}

/**
 * Controller for the campaiagn form.
 *
 * @param {object} $rootScope - Root scope of the application
 * @param {object} $scope - The scope for the campaign form
 * @param {object} $notify - Notification service
 * @param {object} adMeasurementApiService - Service handling ad measurement CRUD
 */
function CampaignFormCtrl($rootScope, $scope, $notify, adMeasurementApiService) {
    var CampaignFormVM = this;
    // The data format that the backend requires
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    // VM exposed variables
    CampaignFormVM.form = {
        expectedSurveyEndDate: {},
        expectedMediaStartDate: {},
        expectedMediaEndDate: {},
    };
    CampaignFormVM.datePickerOptions = {
        minDate: new Date($rootScope.survey.started),
        dateOptions: {
            endDate: {
                initDate: new Date(),
            },
        },
    };

    // VM exposed functions
    CampaignFormVM.enableSubmitButton = enableSubmitButton;
    CampaignFormVM.submitCampaignForm = submitCampaignForm;

    init();

    function init() {
        adMeasurementApiService.getCampaignForSurvey($rootScope.survey.user, $rootScope.survey)
            .then(campaign => {
                if (campaign) {
                    setCampaign(campaign);
                }
            }, error => {
                $notify.error(error.msg || error, {
                    clear: true,
                    timeout: 3000,
                });
            });
    }

    /**
     * Given a campaign, initialize the UI form.
     *
     * @param {object} campaign - The campaign object from the backend
     */
    function setCampaign(campaign) {
        $scope.$evalAsync(() => {
            CampaignFormVM.campaign = campaign;
            CampaignFormVM.form.expectedTotalImpressions = campaign.expectedImpressionCount;

            initializeDatePickerModel(CampaignFormVM.campaign.scheduledStart, 'expectedMediaStartDate');
            initializeDatePickerModel(CampaignFormVM.campaign.scheduledEnd, 'expectedMediaEndDate');
            initializeDatePickerModel(CampaignFormVM.campaign.expectedSurveyEndDate, 'expectedSurveyEndDate');
        });
    }

    /**
     * Initialize the model for a date picker.
     *
     * @param {Date} utcDate - A Javascript date object in UTC
     * @param {string} modelName - The date model name (there are three in this UI)
     */
    function initializeDatePickerModel(utcDate, modelName) {
        if (utcDate) {
            CampaignFormVM.form[modelName] = {
                endDate: U.applyUTCOffsetToDate(utcDate),
            };
        }
    }

    /**
     * Create or update the campaign, based on contents of the campaign form.
     */
    function submitCampaignForm() {
        const params = {
            surveyUuid: $rootScope.survey.uuid,
            uuid: CampaignFormVM.campaign ? CampaignFormVM.campaign.uuid : null,
            userId: $rootScope.auth.user.isAdmin ? $rootScope.survey.user.id : null,
            expectedImpressionCount: parseInt(CampaignFormVM.form.expectedTotalImpressions),
            scheduledStart: U.datetimeToUTCZeroISOString(CampaignFormVM.form.expectedMediaStartDate.endDate, dateFormat),
            scheduledEnd: U.datetimeToUTCZeroISOString(CampaignFormVM.form.expectedMediaEndDate.endDate, dateFormat),
            expectedSurveyEndDate: U.datetimeToUTCZeroISOString(CampaignFormVM.form.expectedSurveyEndDate.endDate, dateFormat),
        };

        adMeasurementApiService.saveCampaign(params).then(campaign => {
            setCampaign(campaign);
            $notify.success(`Campaign saved successfully!`);
        }, () => {
            $notify.error(`There was an error saving the campaign`);
        });
    }

    /**
     * Validate whether total impressions field is valid and ready for submission.
     *
     * @returns {boolean} Is total impression valid?
     */
    function isTotalImpressionsValid() {
        return CampaignFormVM.form.expectedTotalImpressions && CampaignFormVM.form.expectedTotalImpressions > 0;
    }

    /**
     * Validate whether survey end date field is valid and ready for submission.
     *
     * @returns {boolean} Is survey end date valid?
     */
    function isSurveyEndDateValid() {
        return CampaignFormVM.form.expectedSurveyEndDate && CampaignFormVM.form.expectedSurveyEndDate.endDate;
    }

    /**
     * Validate whether media start date field is valid and ready for submission.
     *
     * @returns {boolean} Is media start date valid?
     */
    function isMediaStartDateValid() {
        return CampaignFormVM.form.expectedMediaStartDate && CampaignFormVM.form.expectedMediaStartDate.endDate;
    }

    /**
     * Validate whether survey end date field is valid and ready for submission.
     *
     * @returns {boolean} Is media end date valid?
     */
    function isMediaEndDateValid() {
        return CampaignFormVM.form.expectedMediaEndDate && CampaignFormVM.form.expectedMediaEndDate.endDate;
    }

    /**
     * Validate that end date is after start date.
     *
     * @returns {boolean} Is media end date after media start date?
     */
    function isMediaEndDateAfterMediaStartDate() {
        return CampaignFormVM.form.expectedMediaEndDate.endDate.getTime() > CampaignFormVM.form.expectedMediaStartDate.endDate.getTime();
    }

    /**
     * Determines whether submit button should be enabled.
     *
     * @returns {boolean} Should submit button be enabled.
     */
    function enableSubmitButton() {
        return isTotalImpressionsValid() &&
            isSurveyEndDateValid() &&
            isMediaStartDateValid() &&
            isMediaEndDateValid() &&
            isMediaEndDateAfterMediaStartDate();
    }
}
