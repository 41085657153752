angular.module('svClearX', []).directive('svClearX', function() {
    return {
        restrict: 'CA',
        scope: {
            ngModel: '=',
        },
        link: function(scope, element, attrs) {
            var visibleX = false,
                mouseOver = false,
                focused = false;

            function toggleX(show) {
                visibleX = !!show;
                element.css('background-position', visibleX ? 'right 3px center' : 'right -10px center');
            }

            /* Add 'x' button, hidden off screen */
            element.css({
                background: 'url(https://media.upwave.com/clear-x.png) no-repeat right -10px center',
            });

            /* Add event handlers to show or hide 'x' */
            element.on('input', function() {
                toggleX(element.val() && (mouseOver || focused));
            });
            element.on('mouseenter', function() {
                mouseOver = true;
                toggleX(element.val());
            });
            element.on('focus', function() {
                if (!U.isTouchDevice()) {
                    return;
                }
                focused = true;
                toggleX(element.val());
            });
            element.on('mouseleave', function() {
                mouseOver = false;
                toggleX(false);
            });
            element.on('blur', function() {
                if (!U.isTouchDevice()) {
                    return;
                }
                focused = false;
                toggleX(false);
            });

            /* If the user mouses over the x icon, make it clickable */
            element.on('mousemove', function(e) {
                var clickable = visibleX && (this.offsetWidth - 15 < e.clientX - this.getBoundingClientRect().left);
                element.css('cursor', clickable ? 'pointer' : 'text');
            });

            /* If cursor over x, delete when clicked */
            element.on('click', function(e) {
                var clickable = visibleX && (this.offsetWidth - 15 < e.clientX - this.getBoundingClientRect().left);
                if (clickable) {
                    element.val('');
                    if (scope.ngModel) {
                        scope.$apply(function() {
                            scope.ngModel = '';
                        });
                    }
                    element.css({
                        cursor: 'text',
                    });
                    toggleX(false);
                }
            });
        },
    };
});
