import './audience-configuration';
import './audience-configuration-list';
import './beacon-control-configuration';
import './configure-exposure';
import './review-audience-configuration';
import './select-control';
import './window-days-modal';

angular.module('audienceConfiguration', [
    'audienceConfigurationList.view',
    'audienceConfiguration.windowDays',
    'audienceConfiguration.view',
    'audienceConfiguration.configureExposure',
    'audienceConfiguration.beaconControlConfiguration',
    'audienceConfiguration.selectControl',
    'audienceConfiguration.review',
]);
