angular.module('ranking.preview', [])
    .directive('rankingPreview', rankingPreview);

function rankingPreview() {
    return {
        restrict: 'E',
        templateUrl: 'survey-creation-templates/design/ranking-preview.html',
        scope: {
            question: '=',
        },
    };
}
