import _ from 'lodash';
import filterLogicConstants from '../filter-logic/filter-logic-constants';

const ALLOWED_FIRST_LEVEL_LOGIC = /^\$(lt|le|eq|ge|gt|any|in|fuzzy_contains|fuzzy_eq)$/,
    BEACON_REGEX = /(BeaconPool|ControlFor|ControlBeacon|ControlBeaconPool)\$/,
    allowedLogic = /^\$(or|and|not|bool)$/,
    QUESTION_REGEX = /^((Question|Screener)\$(\d+)(?:\|Line\$(\d+))?)/,
    questionChoiceRegex = /^((Question|Screener)\$(\d+)(?:\|Line\$(\d+))?)\|(?:Choice\$(\d+))$/,
    DEFAULT_FUZZY_THRESHOLD = 70;

/**
 * @param criteriaString
 */
function isQuestionCriteriaString(criteriaString) {
    return QUESTION_REGEX.test(criteriaString);
}

/**
 * @param logic
 */
function prepareLogic(logic) {
    if (!logic || _.isEmpty(logic)) {
        return [{
            criteria: [],
        }];
    }
    return convertFilterBy(logic, []);
}

/**
 * @param filterModel
 * @param id
 */
function getModelById(filterModel, id) {
    let options;
    if (filterModel && filterModel[id] && !filterModel[id].hasSubmenu) {
        return filterModel[id];
    }
    if (QUESTION_REGEX.test(id)) {
        return _.find(filterModel.Question$Choice.options, {
            id: id,
        });
    }
    _.forEach(filterModel, model => {
        if (model.hasSubmenu && model.id !== 'Question$Choice') {
            options = _.find(model.options, {
                id: id,
            });
            if (options) {
                return false;
            }
        }
    });
    return options;
}

/**
 * @param filterModel
 * @param parsedCriteria
 */
function setModelById(filterModel, parsedCriteria) {
    const model = getModelById(filterModel, parsedCriteria.id),
        logicPhrase = model && model.logicPhrase,
        map = {};
    if (_.isEmpty(parsedCriteria) || !model) {
        return;
    }
    _.forEach(parsedCriteria.criteria, id => {
        map[id] = true;
    });
    _.forEach(model.options, option => {
        option.set = map[option.id];
    });
    model.isAvailable = false;
    (_.find(logicPhrase, {
        value: parsedCriteria.logic,
    }) || logicPhrase[0]).selected = true;
}

/**
 * Given the filter JSON, parse the filter criteria in the format required for the filter UI, which contains info such as:
 * model id, logic, and selected criteria
 *
 * Example input:
 * {
 *   "$and":[
 *       { "$lt": ["Date (UTC)","2019-10-08T00:00:00.000Z"] },
 *       { "$gt": ["Date (UTC)","2019-10-01T00:00:00.000Z"] }
 *    ]
 * }
 *
 * Example output:
 * [{
 *     "id":"Date (UTC)",
 *     "criteria":["2019-10-08T00:00:00.000Z"],
 *     "logic":"$lt",
 *     "parentLogic":"$and"
 *  }, {
 *      "id":"Date (UTC)",
 *      "criteria":["2019-10-01T00:00:00.000Z"],
 *      "logic":"$gt",
 *      "parentLogic":"$and"
 * }]
 *
 * @param {object} filterBy - The filter json to be parsed
 * @param {Array} parsedCriteria - Empty parsedCritera array
 * @returns {Array} parsedCriteria - List of filter criteria
 *
 */
function convertFilterBy(filterBy, parsedCriteria) {
    if (!filterBy || _.isEmpty(filterBy)) {
        return parsedCriteria;
    }
    let error = checkLogicKeyError(filterBy);
    if (error) {
        console.error(error, JSON.stringify(filterBy));
        return;
    }
    const logic = _.keys(filterBy)[0],
        hasParentLogic = logic !== '$not' && !(ALLOWED_FIRST_LEVEL_LOGIC.test(logic) || allCriteriaBelongToSameKey(filterBy[logic])) || filterBy[logic].length < 2;

    _.forEach(hasParentLogic ? filterBy[logic] : [filterBy], filter => {
        error = checkLogicKeyError(filter);
        if (error) {
            console.error(error, filter);
            return false;
        }
        const key = _.keys(filter)[0],
            criteria = key === '$not' ? _.values(filter.$not)[0] : filter[key],
            isFirstLevelLogic = ALLOWED_FIRST_LEVEL_LOGIC.test(key),
            isGroupOfSameLogic = !isFirstLevelLogic && allCriteriaBelongToSameKey(criteria);

        // Handle special case, where top level logic is '$bool'. No processing is necessary so just return.
        if (key === '$bool') {
            return;
        }

        // Handle case of first level logic
        if (isFirstLevelLogic) {
            if (validateCriteria(key, criteria)) {
                console.error('Value must be an array of length 2', filter);
                return false;
            }
            if (/^(Demographic\$Creative_group|Demographic\$Period)$/.test(criteria[0])) {
                return;
            }
            const selected = getCriteriaFromFirstLevelItem(criteria);
            let criteriaLogic = logic;
            selected.criteria = Array.isArray(selected.criteria) ? selected.criteria : [selected.criteria];
            if (logicIsSupportedById(selected.id, key, selected.criteria)) {
                criteriaLogic = selected.not ? '$not' : key;
            }
            parsedCriteria.push({
                id: selected.id,
                criteria: selected.criteria,
                logic: criteriaLogic,
                parentLogic: hasParentLogic ? logic : void 0,
            });
        }
        // Handle case where we have multiple items of same logic (i.e multiple $or'd age ranges)
        else if (isGroupOfSameLogic) {
            let selected = [],
                skip,
                id;
            _.forEach(criteria, logics => {
                const key = _.keys(logics)[0],
                    parsed = getCriteriaFromFirstLevelItem(logics[key]);
                selected = selected.concat(parsed.criteria);
                if (id && id !== parsed.id) {
                    console.error(logics);
                }
                id = parsed.id;
                if (/^(Demographic\$Creative_group|Demographic\$Period)$/.test(id)) {
                    skip = true;
                    return false;
                }
            });
            if (skip) {
                return;
            }
            parsedCriteria.push({
                id,
                criteria: selected,
                logic: selected.length === criteria.length ? logicIsSupportedById(selected.id, key, selected) ? key : logic : 'EXACTLY',
                parentLogic: hasParentLogic ? logic : void 0,
            });
        }
        // Handle nested logic, which requires calling this function recursively
        else {
            const nestedParsedCriteria = [];
            parsedCriteria.push(nestedParsedCriteria);
            convertFilterBy({
                [key]: criteria,
            }, nestedParsedCriteria);
        }
    });

    return error ? void 0 : parsedCriteria;
}

/**
 * @param key
 * @param criteria
 */
function validateCriteria(key, criteria) {
    const isArray = Array.isArray(criteria);
    if (/^\$fuzzy_(contains|or)$/.test(key) && isArray && criteria.length === 3) {
        return false;
    }
    return key !== '$any' && (!isArray || criteria.length !== 2);
}

/**
 * @param json
 */
function parseCriteriaId(json) {
    let id = _.values(json)[0][0];
    const impressionParameterGroups = typeof id === 'string' && id.split(': ');
    const beaconIdMatches = typeof id === 'string' && id.match(BEACON_REGEX);

    if (QUESTION_REGEX.test(id)) {
        id = id.replace(/\|Choice\$\d+$/, '');
    }
    else if (impressionParameterGroups.length === 2) {
        id = impressionParameterGroups[0];
    }
    else if (beaconIdMatches && beaconIdMatches.length) {
        id = beaconIdMatches[0];
    }
    return id;
}

/**
 * @param {object[]} criteria - List of criteria objects where each object contains a logic key, and an array of criteria items
 * @description Returns truthy if all items in the criteria belong to the same id
 */
function allCriteriaBelongToSameKey(criteria) {
    let bool = true,
        prevId;

    _.forEach(criteria, json => {
        const id = parseCriteriaId(json);
        prevId = prevId || id;
        if (prevId !== id) {
            bool = false;
            return false;
        }
    });
    if (bool) {
        // Frequency and survey completion date use comparison operators (>, >=, <, <=...) to create custom value ranges, so
        // they operate differently than other items than are displayed as check boxes (like age or state, for example)
        bool = prevId === filterLogicConstants.criteriaTypes.FREQUENCY || prevId === filterLogicConstants.criteriaTypes.SURVEY_COMPLETION_DATE ? false : prevId;
    }
    return bool;
}

/**
 * @param id
 * @param logic
 * @param criteria
 */
function logicIsSupportedById(id, logic, criteria) {
    if (criteria.length > 1 && logic === '$eq') {
        return false;
    }
    if (id === 'Demographic$Gender' && logic !== '$eq') {
        return false;
    }
    if (QUESTION_REGEX.test(id) && logic === '$eq') {
        return false;
    }
    return true;
}

/**
 * @param filterBy
 */
function checkLogicKeyError(filterBy) {
    let keys = _.keys(filterBy),
        key = keys[0];
    if (keys.length !== 1) {
        return 'filterBy argument must be an object that contains only one key';
    }
    if (!allowedLogic.test(key) && !ALLOWED_FIRST_LEVEL_LOGIC.test(key)) {
        return 'filterBy key must be either $or, $and, $not, $lt, $le, $eq, $ge, $gt, $any, or $in';
    }
    if (key === '$not') {
        return _.size(filterBy[key]) ? false : 'Value must be non-empty object';
    }
    if (key !== '$bool' && (!Array.isArray(filterBy[key]) || filterBy[key].length < 1)) {
        return 'Value must be an non-empty array';
    }
}

/**
 * Given a first-level filter item (no $and or $or), which is used in the filter definition json,
 * convert to a filter criteria object, which is used for constructing the filter dropdowns.
 
 * Example input: ["Demographic$Gender","male"]
 *
 * Example output: {
 *    "id":"Demographic$Gender",
 *    "criteria":"male"
 * }
 *
 * @param {object} item -
 * @returns {object} filter criteria object
 */
function getCriteriaFromFirstLevelItem(item) {
    const impressionParameter = typeof item[0] === 'string' ? item[0].split(': ') : [];
    if (questionChoiceRegex.test(item[0])) {
        let questionParts = parseQuestionChoiceString(item[0]);
        return {
            id: questionParts.questionId,
            criteria: item[1] ? [item[0]] : [],
        };
    }
    if (QUESTION_REGEX.test(item[0])) {
        return {
            id: item[0],
            criteria: [item[1], item[2] || DEFAULT_FUZZY_THRESHOLD],
        };
    }
    if (/^BeaconPool\$/.test(item[0])) {
        return {
            id: 'Beacon$Pool',
            criteria: item[1] ? item[0] : [],
        };
    }
    if (/^ControlBeaconPool\$/.test(item[0])) {
        return {
            id: 'ControlBeaconPool$',
            criteria: item[1] ? item[0] : [],
        };
    }
    if (/^is_control/.test(item[0])) {
        return {
            id: 'Beacon$Pool',
            criteria: 'is_control',
            not: !item[1],
        };
    }
    if (/^ControlFor\$/.test(item[0])) {
        return {
            id: 'ControlFor$',
            criteria: item[1] ? item[0] : [],
        };
    }
    if (/^ImpressionParameterGroups\$/.test(item[0])) {
        const categoryAndGroup = item[0].replace('ImpressionParameterGroups$', '').split(': ');
        return {
            id: `ImpressionParameterGroups$${categoryAndGroup[0]}`,
            criteria: item[0],
        };
    }
    // This is hacky -- assume impression parameter groups have the category name separated by a `: `
    if (impressionParameter.length === 2) {
        return {
            id: `ImpressionParameterGroups$${impressionParameter[0]}`,
            criteria: `ImpressionParameterGroups$${item[0]}`,
        };
    }
    if (item[0] === 'Frequency' && typeof item[1] === 'string') {
        return {
            id: 'Frequency_',
            criteria: item[1],
        };
    }
    // Pre-existing filter model (originally created to create charts filters, then reused for custom cuts) expects gender ids to be lower case.
    // But newer analysis plan definitions are upper case. Backend can handle it either way, so to handle this, just convert to lower case so
    // ensure that filter model will operate correctly.
    if (item[0] === 'Demographic$Gender') {
        item[1] = item[1].toLowerCase();
    }
    return {
        id: item[0],
        criteria: item[1],
    };
}

/**
 * @param choiceString
 */
function parseQuestionChoiceString(choiceString) {
    var parts = choiceString.match(questionChoiceRegex);

    return {
        questionId: parts[1],
        screener: parts[2] === 'Screener',
        questionIndex: parts[3],
        choiceIndex: parts[5],
        gridLineIndex: parts[4],
    };
}

/**
 * @param legacy
 */
function convertLegacyFilterModel(legacy) {
    const parsedCriteria = [];
    let numValidOptions,
        selected;
    // Gender
    if (legacy.gender) {
        parsedCriteria.push({
            id: 'Demographic$Gender',
            logic: '$eq',
            criteria: [legacy.gender === 'female' ? 'Female' : legacy.gender === 'male' ? 'Male' : legacy.gender],
            parentLogic: '$and',
        });
    }
    // Age
    selected = [];
    numValidOptions = 0;
    _.forEach(legacy.ages, age => {
        if (!age.disabled) {
            numValidOptions++;
        }
        if (age.set) {
            selected.push(age.code);
        }
    });
    if (selected.length !== numValidOptions) {
        parsedCriteria.push({
            id: 'Demographic$Age',
            logic: '$or',
            criteria: selected,
            parentLogic: '$and',
        });
    }
    // Beacon pools
    if (legacy.beaconPools) {
        const selectedBP = [];
        _.forEach(legacy.beaconPools.options, bp => {
            if (bp.set) {
                selectedBP.push(bp.key);
            }
        });
        if (selectedBP.length !== legacy.beaconPools.options.length) {
            parsedCriteria.push({
                id: 'Beacon$Pool',
                logic: '$or',
                criteria: selectedBP,
                parentLogic: '$and',
            });
        }
    }
    // Question choice
    if (legacy.questionAnswer) {
        _.forEach(legacy.questionAnswer, question => {
            if (question.type === 'grid') {
                return;
            }
            const id = (question.screener ? 'Screener' : 'Question') + '$' + question.index + (question.gridType ? '|Line$' + (question.rowIndex + 1) : ''),
                selectedQuestionChoice = [];
            _.forEach(question.options, (choice, idx) => {
                if (choice.set) {
                    selectedQuestionChoice.push(id + '|Choice$' + (idx + 1));
                }
            });
            if (selectedQuestionChoice.length) {
                parsedCriteria.push({
                    id,
                    logic: '$or',
                    criteria: selectedQuestionChoice,
                    parentLogic: '$and',
                });
            }
        });
    }
    return parsedCriteria;
}

/**
 * @param deprecated
 */
function convertDeprecatedFilterModel(deprecated) {
    const parsedCriteria = [],
        selectedGender = _.filter((deprecated.Demographic$Gender || {}).options, 'set'),
        selectedAge = [];
    let numValidOptions = 0;
    // Gender
    if (selectedGender && selectedGender.length === 1 && selectedGender[0].id) {
        parsedCriteria.push({
            parentLogic: '$and',
            id: 'Demographic$Gender',
            logic: '$eq',
            criteria: [selectedGender[0].id],
        });
    }
    // Age
    _.forEach(deprecated.Demographic$Age.options, age => {
        if (!age.disabled) {
            numValidOptions++;
        }
        if (age.set) {
            selectedAge.push(age.id);
        }
    });
    if (selectedAge.length && selectedAge.length !== numValidOptions) {
        parsedCriteria.push({
            parentLogic: '$and',
            id: 'Demographic$Age',
            logic: '$or',
            criteria: selectedAge,
        });
    }
    // Beacon pools
    if (deprecated.Beacon$Pool) {
        const selectedBP = [];
        _.forEach(deprecated.Beacon$Pool.options, bp => {
            if (bp.set) {
                selectedBP.push(bp.key);
            }
        });
        if (selectedBP.length !== deprecated.Beacon$Pool.options.length) {
            parsedCriteria.push({
                parentLogic: '$and',
                id: 'Beacon$Pool',
                logic: '$or',
                criteria: selectedBP,
            });
        }
    }
    // Question choice
    if (deprecated.Question$Choice) {
        _.forEach(deprecated.Question$Choice.options, question => {
            const selectedQuestionChoice = [];
            _.forEach(question.options, (choice, idx) => {
                if (choice.set) {
                    selectedQuestionChoice.push(question.id + '|Choice$' + (idx + 1));
                }
            });
            if (selectedQuestionChoice.length) {
                parsedCriteria.push({
                    parentLogic: '$and',
                    id: question.id,
                    logic: '$or',
                    criteria: selectedQuestionChoice,
                });
            }
        });
    }
    // Region
    setParsedCriteriaForId('Demographic$Region', deprecated, parsedCriteria);
    // State
    setParsedCriteriaForId('Demographic$State', deprecated, parsedCriteria);
    return parsedCriteria;
}

/**
 * @param id
 * @param model
 * @param parsedCriteria
 */
function setParsedCriteriaForId(id, model, parsedCriteria) {
    const selected = [];
    _.forEach(model[id].options, option => {
        if (option.set) {
            selected.push(option.id);
        }
    });
    if (selected.length) {
        parsedCriteria.push({
            parentLogic: '$and',
            id: id,
            logic: '$or',
            criteria: selected,
        });
    }
}

export {
    QUESTION_REGEX,
    ALLOWED_FIRST_LEVEL_LOGIC,
    DEFAULT_FUZZY_THRESHOLD,
    convertFilterBy,
    checkLogicKeyError,
    getCriteriaFromFirstLevelItem,
    parseQuestionChoiceString,
    convertLegacyFilterModel,
    convertDeprecatedFilterModel,
    getModelById,
    setModelById,
    isQuestionCriteriaString,
    prepareLogic,
};
