/* eslint compat/compat: "off" */
import {
    convertPixelToFloat,
} from './export-service';

export default class ClickmapExportOptions {
    constructor(elem, scale, canvasWidth) {
        let clickmapWrapper = elem.firstElementChild,
            background = clickmapWrapper.firstElementChild,
            canvas = background.firstElementChild,
            styles = window.getComputedStyle(clickmapWrapper),
            fontHeight = convertPixelToFloat(styles.fontSize) * scale,
            zones = [];
        _.forEach(clickmapWrapper.querySelectorAll('.clickmap-zone'), function(zone) {
            zones.push({
                zoneArea: {
                    x: zone.offsetLeft * scale,
                    y: zone.offsetTop * scale,
                    width: zone.offsetWidth * scale,
                    height: zone.offsetHeight * scale,
                },
                text: zone.innerText,
            });
        });
        this.fontHeight = fontHeight;
        if (background.clientWidth * scale > canvasWidth) {
            const adjustedScale = canvasWidth / background.clientWidth;
            this.width = canvasWidth;
            this.height = background.clientHeight * adjustedScale;
        }
        else {
            this.width = background.clientWidth * scale;
            this.height = background.clientHeight * scale;
        }
        this.background = {
            src: background.style['background-image'].match(/^url\("?(.+)"?\)$/)[1],
            width: this.width,
            height: this.height,
        };
        this.canvas = {
            src: canvas.toDataURL(),
            width: canvas.width * scale,
            height: this.height,
            scale: scale,
        };
        this.zones = zones;
    }
}
