import '../../../../../../common/js/inc/sv-notify';
import '../../../common-components/loading-button';
import '../../../common-components/checkbox-indicator';
import '../../../common-components/pub-sub';
import '../../../common-components/display-table';
import '../analysis-config-manager';
import '../analysis-plan.service';
import '../analysis-config-service';
import targetAudienceCutsConstants from './target-audience-cuts-constants';

angular
    .module('targetAudienceCuts.view', [
        'displayTable',
        'svNotify',
        'pubSub',
        'checkboxIndicator',
        'analysisPlan.service',
        'analysisConfig.manager',
    ])
    .directive('targetAudienceCuts', targetAudienceCuts)
    .controller('TargetAudienceCutsCtrl', TargetAudienceCutsCtrl);

/**
 * Directive of the target audience cuts
 *
 * @returns {object}
 */
function targetAudienceCuts() {
    return {
        restrict: 'AE',
        templateUrl: 'dashboard-templates/ad-measurement/analysis-plan/cuts/target-audience-cuts.html',
        controller: 'TargetAudienceCutsCtrl',
        controllerAs: 'TargetAudienceCutsVM',
    };
}

TargetAudienceCutsCtrl.$inject = [
    '$scope',
    '$notify',
    'pubSubService',
    'analysisPlanService',
    'analysisConfigManager',
];

/**
 * The controller for target audience cuts
 *
 * @param {object} $scope
 * @param {object} $notify
 * @param {object} pubSubService
 * @param {object} analysisPlanService
 * @param {object} analysisConfigManager
 */
function TargetAudienceCutsCtrl($scope, $notify, pubSubService, analysisPlanService, analysisConfigManager) {
    const TargetAudienceCutsVM = this;

    // VM exposed variables
    TargetAudienceCutsVM.ux = {
        loading: false,
        save: saveTargetAudienceCuts,
    };

    TargetAudienceCutsVM.tableHeaders = targetAudienceCutsConstants.tableHeaders;
    TargetAudienceCutsVM.tableOptions = targetAudienceCutsConstants.tableOptions;
    const allTargetAudiences = analysisConfigManager.get('targetAudience').levels;

    init();

    function init() {
        TargetAudienceCutsVM.targetAudienceCuts = [];
        analysisPlanService.getAllCategoriesAndCuts().then(response => {
            const targetAudienceCutCategory = response.find(cut => cut.analysisCutCategoryType === 'targetAudience');
            let targetAudienceCuts = [];
            if (targetAudienceCutCategory && targetAudienceCutCategory.cuts.length > 0) {
                targetAudienceCutCategory._selected = true;
                TargetAudienceCutsVM.targetAudienceCutCategory = targetAudienceCutCategory;
                targetAudienceCuts = targetAudienceCutCategory.cuts;
            }

            $scope.$evalAsync(() => {
                TargetAudienceCutsVM.targetAudienceCuts = Array.from(targetAudienceCuts);
                TargetAudienceCutsVM.allTargetAudiences = allTargetAudiences.map(targetAudience => {
                    // If the cut version of the target audience exists,
                    // it should show selected(2) otherwise,
                    // it should show notSelected(0)
                    targetAudience.cut = targetAudienceCuts.find(cut => targetAudience.name === cut.name);
                    targetAudience._selected = targetAudience.cut ? 2 : 0;
                    return targetAudience;
                });
                decideSelectCheckbox();
                TargetAudienceCutsVM.ux.loading = false;
            });
        });
    }

    /**
     * Set the header checkbox:
     * _selected is used to decide what icon is used
     * 2 means all are selected (checkmark),
     * 1 means some are selected (dash),
     * 0 means that none is selected (empty)
     */
    function decideSelectCheckbox() {
        const numTargetAudienceCuts = TargetAudienceCutsVM.targetAudienceCuts.length;
        const numAllTargetAudiences = TargetAudienceCutsVM.allTargetAudiences.length;
        if (numTargetAudienceCuts === numAllTargetAudiences) {
            TargetAudienceCutsVM.tableHeaders[0]._selected = 2;
        }
        else if (numTargetAudienceCuts === 0) {
            TargetAudienceCutsVM.tableHeaders[0]._selected = 0;
        }
        else {
            TargetAudienceCutsVM.tableHeaders[0]._selected = 1;
        }
    }

    /**
     * Saves all the target audience cuts
     * If there is no existing target audience cut category,
     * Create a new category
     *
     */
    function saveTargetAudienceCuts() {
        TargetAudienceCutsVM.ux.loading = true;
        if (!TargetAudienceCutsVM.targetAudienceCutCategory) {
            TargetAudienceCutsVM.targetAudienceCutCategory = targetAudienceCutsConstants.newCategoryModel;
        }
        TargetAudienceCutsVM.targetAudienceCutCategory.options = [];
        TargetAudienceCutsVM.allTargetAudiences.forEach(targetAudience => {
            if (targetAudience.cut) {
                targetAudience.cut._selected = targetAudience._selected;
                TargetAudienceCutsVM.targetAudienceCutCategory.options.push(targetAudience.cut);
            }
            else {
                TargetAudienceCutsVM.targetAudienceCutCategory.options.push({
                    value: targetAudience.name,
                    type: {
                        value: 'all',
                    },
                    exposed: targetAudience.definitionJson,
                    _selected: targetAudience._selected,
                });
            }
        });
        analysisPlanService.saveCategoryAndCuts(TargetAudienceCutsVM.targetAudienceCutCategory).then(() => {
            $scope.$evalAsync(() => {
                TargetAudienceCutsVM.ux.loading = false;
                decideSelectCheckbox();
            });
        }, errorMsg => {
            $notify.error(`There was an error saving the cuts: ${errorMsg}`);
        });
    }
}
