export default {
    viewTypes: {
        DESIGN: 'surveyDesign',
        ATTACHMENTS: 'attachments',
        RESULTS: 'results',
        ADMEASUREMENT: 'adMeasurement',
        SHARING: 'sharing',
        PAYMENT: 'payment',
        CLIENTINFO: 'clientInfo',
        TAKE: 'take',
    },

    displayName: {
        surveyDesign: 'Survey Design',
        attachments: 'Attachments',
        adMeasurement: 'Ad Measurement',
        results: 'Results',
        sharing: 'Collaborate',
        payment: 'Payment',
        clientInfo: 'Client Info',
        take: 'Take/Panel Config',
    },

    paymentMethods: {
        creditCard: 'Credit Card',
        check: 'Check',
        paypal: 'PayPal',
        wireTransfer: 'Wire Transfer',
    },

    surveySteps: [{
        title: 'Analyst reviewing survey',
        instructions: 'Your survey is being reviewed by an experienced research analyst',
    },
    {
        title: 'Review & approve survey',
        instructions: 'Review analyst comments, ask any questions you may have, and <strong>approve</strong> to start collecting your results',
    },
    {
        title: 'Collecting results',
        instructions: 'Survey responses are being collected from only those that match your targeting criteria',
    },
    {
        title: 'Analyze your results!',
        instructions: 'We finished collecting results for your survey. Go analyze your results on our powerful dashboard',
    }],
};
