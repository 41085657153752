import '../common-components/sv-filters';
import '../survey/api-survey-service';
angular.module('PanelAdminModule', [
    'apiSurveyService', 'svFilters',
])
    .controller('PanelAdminCtrl', PanelAdminCtrl);
PanelAdminCtrl.$inject = [
    '$scope',
    '$rootScope',
    'surveyService',
    '$timeout',
];

function PanelAdminCtrl($scope, $rootScope, surveyService, $timeout) {
    $scope.sort = {
        criteria: null,
        reverse: false,
    };
    $rootScope.appData = {
        surveys: [],
    };
    $scope.ux = {
        ready: false,
        listRefreshing: false,
    };

    $scope.refreshSurveyList = refreshSurveyList;
    $scope.refreshSurveys = refreshSurveys;
    $scope.sortBy = sortBy;

    function refreshSurveyList() {
        if ($scope.ux.listRefreshing) {
            $timeout(function() {
                refreshSurveyList();
            }, 100);
        }
        else {
            refreshSurveys();
        }
    }

    function refreshSurveys() {
        var params = {
            showStarted: true,
            hideCompleted: true,
            hideCanceled: true,
            showPanel: true,
        };
        $scope.ux.listRefreshing = true;

        surveyService.listAllSurveys(params).then(function(surveys) {
            $scope.appData.surveys = surveys;
            var numSurveys = surveys.length,
                resultsLoaded = 0;

            _.forEach($scope.appData.surveys, function(survey) {
                surveyService.getPeriodResultsSummary(survey.latestCollectionPeriod.uuid).then(function(summary) {
                    $scope.$evalAsync(() => {
                        survey._numPendingResponses = summary.pending;
                        survey._numCurrentResponses = summary.responses;
                        survey._responsesNeeded = summary.responsesNeeded;
                        survey._progress = survey._numCurrentResponses / survey._responsesNeeded;
                        survey._totalLength = summary.totalLength;
                        survey._finishDate = summary.finishDate;
                        survey._totalAsked = summary.totalAsked;
                        survey._screenerFailures = summary.screenerFailures;

                        if (resultsLoaded++ === numSurveys - 1) {
                            // This is the last one, so now sort
                            $scope.sortBy('_progress');
                        }
                    });
                });

                surveyService.listPanelDetails(survey.uuid)
                    .then(function(details) {
                        survey._panels = details.map(function(elem) {
                            if (elem.partnerSelected) {
                                return elem.panelPartner;
                            }
                            return null;
                        }).join(', ');
                    });
            });
            setReady();
        }, setReady);
    }

    function setReady() {
        $scope.$evalAsync(() => {
            $scope.ux.ready = true;
            $scope.ux.listRefreshing = false;
        }, 0);
    }

    function sortBy(criteria) {
        if ($scope.sort.criteria === criteria) {
            $scope.sort.reverse = !$scope.sort.reverse;
        }
        else {
            $scope.sort.criteria = criteria;
        }
    }

    // Load the survey list
    refreshSurveyList();
}
