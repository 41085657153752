define([], (function() {
    var breakpoints = [
        {
            width: 1200,
            breakpoint: 'lg',
        },
        {
            width: 992,
            breakpoint: 'md',
        },
        {
            width: 768,
            breakpoint: 'sm',
        },
        {
            width: 480,
            breakpoint: 'xs',
        },
    ];

    angular.module('svBreakpoint', [])
        .directive('svBreakpoint', ['$timeout', function($timeout) {
            return {
                restrict: 'A',
                scope: {
                    svBreakpoint: '=',
                },
                link: function(scope, element, attrs) {
                    var win = $(window);

                    function getBreakpoint() {
                        var width = win.width(),
                            breakpoint = 'xxs';

                        for (var i in breakpoints) {
                            if (width >= breakpoints[i].width) {
                                breakpoint = breakpoints[i].breakpoint;
                                break;
                            }
                        }
                        $timeout(function() {
                            scope.svBreakpoint = breakpoint;
                        });
                    }
                    win.resize(getBreakpoint);
                    getBreakpoint();
                },
            };
        }]);
})());
