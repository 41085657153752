import './rating/options';

function errors(_q) {
    var errs = [];
    return errs;
}

angular.module('questionTypes.rating', [
    'rating.options',
])
    .service('questionTypes.rating.GlyphService', GlyphService)
    .service('questionTypes.rating.SliderService', SliderService);

GlyphService.$inject = ['ratingOptions'];

function GlyphService(ratingOptions) {
    function toString(q) {
        return (q.statement.text || '') + '\n' +
            (q.statement.image ? '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n' : '') +
            '[rating]\n* ' + q.details.length.toString() + ' ' + ratingOptions.map[q.details.glyph].name + 's\n' +
            (q.details.lowLabel ? '* 0 ' + ratingOptions.map[q.details.glyph].name + 's: ' + q.details.lowLabel + '\n' : '') +
            (q.details.highLabel ? '* ' + q.details.length.toString() + ' ' + ratingOptions.map[q.details.glyph].name + 's: ' + q.details.highLabel + '\n' : '');
    }
    return {
        basic: true,
        extended: true,
        advanced: true,
        id: 'rating',
        name: 'Rating',
        description: 'Rate on a scale',
        icon: 'far fa-star',
        screener: {
            adminOnly: true,
        },
        initialDetails: {
            length: 5,
            displayType: 'glyph',
            glyph: 'star',
        },
        toString: toString,
        errors: errors,
    };
}

function SliderService() {
    function toString(q) {
        return (q.statement.text || '') + '\n' +
            (q.statement.image ? '[image url: ' + q.statement.image.url + ' position: ' + q.statement.image.position + ']\n' : '') +
            '[rating slider]\n* 1 to ' + q.details.length.toString() + '\n' +
            (q.details.lowLabel ? '* 0 = ' + q.details.lowLabel + '\n' : '') +
            (q.details.highLabel ? '* ' + q.details.length.toString() + ' = ' + q.details.highLabel + '\n' : '');
    }
    return {
        basic: false,
        advanced: false,
        alwaysHide: true,
        id: 'rating',
        name: 'Rating Slider',
        description: 'Rate on a slider scale',
        icon: 'fas fa-arrows-h',
        initialDetails: {
            length: 5,
            displayType: 'slider',
        },
        toString: toString,
        errors: errors,
    };
}
