import '../common-components/viewport-pub-sub';
import '../common-components/pub-sub';
import '../results-components/stats-module';
import '../results-components/respondent-data-service';
import '../results-components/survey-results-service';
import '../charts/charts-service';
import filterLogicConstants from '../filter-logic/filter-logic-constants';
import filterLogicService from '../filter-logic/filter-logic-service';
import targetingAttributeAge from '../targeting-attributes/age';
import filterItemService from '../filter-and-compare/filter-item.service';
import '../targeting-attributes/age';
import './chart-data-parser';

angular.module('chartsWidget.service', [
    'viewportPubSub',
    'pubSub',
    'StatsModule',
    'respondentData.service',
    'surveyResults.service',
    'charts.service',
    'chart.dataParser',
])
    .service('chartWidgetService', chartWidgetService);
chartWidgetService.$inject = [
    '$rootScope',
    '$document',
    'ViewportPubSub',
    'pubSubService',
    'respondentDataService',
    '$statsService',
    'surveyResultsService',
    'chartDataParser',
    'myChartService',
];

/**
 * @param $rootScope
 * @param $document
 * @param ViewportPubSub
 * @param pubSubService
 * @param respondentDataService
 * @param $statsService
 * @param surveyResultsService
 * @param chartDataParser
 * @param myChartService
 */
function chartWidgetService($rootScope, $document, ViewportPubSub, pubSubService, respondentDataService, $statsService, surveyResultsService, chartDataParser, myChartService) {
    var inViewport = new ViewportPubSub(ViewportPubSub.PARTIALLY_VISIBLE, 'chart-wrapper');
    inViewport.setMaxUnresolved(4);

    /**
     * @param question
     * @param params
     */
    function getImageUrl(question, params) {
        if (!question.details || !question.details.statement || !question.details.statement.image) {
            return;
        }
        return question.details.statement.image.url;
    }

    /**
     * @param question
     * @param params
     * @param data
     */
    function getChartDetails(question, params, data) {
        var details = {
                questionType: question.type,
                questionDetails: question.details,
                isTracker: chartDataParser.isLineChart(params.collectionPeriod),
                isComparison: !!params.comparisonData && !chartDataParser.selfCharting(params.comparisonData, question.respondentDataType),
            },
            questionType = details.questionType,
            hidePlottableChart = chartDataParser.hidePlottableChart(question);

        /**
         * @param item
         */
        function getChartDetail(item) {
            item = Array.isArray(item) ? item[0] : item;
            var detail = {
                isLineChart: details.isTracker,
                hidePlottableChart: hidePlottableChart,
            };
            if (item.label) {
                detail.comparisonLabel = item.label;
            }
            if (item.click_data) {
                U.renameObjProperty(item, 'click_data', 'clickData');
            }
            switch (questionType) {
                case 'clickmap':
                    detail.clicks = item.clickData || null;
                    break;
                case 'longFreeResponse':
                case 'freeResponse':
                    detail.countTally = item.countTally;
                    detail.numRespondents = item.numRespondents;
                    break;
                case 'rating':
                    detail.metricTally = item.metricTally;
                    break;
                case 'ranking':
                    detail.averageRanking = U.objToArray(item.metricTally, {
                        sortBy: 'value',
                        keyName: 'label',
                    });
                    break;
                default:
                    break;
            }
            return detail;
        }
        if (chartDataParser.isFreeResponse(question.type)) {
            pubSubService.notify('refresh-wordcloud', []);
        }
        if (details.isComparison) {
            return {
                comparisonData: _.map(data, getChartDetail),
            };
        }
        else if (!details.isTracker) {
            // DEVNOTE: Temporary fix for snake case param conversion
            return getChartDetail(data);
        }
        return {
            isLineChart: details.isTracker,
            hidePlottableChart: hidePlottableChart,
        };
    }

    /**
     * @param chartData
     * @param question
     * @param params
     * @param oldChart
     * @param options
     */
    function getChart(chartData, question, params, oldChart, options) {
        if (!chartData) {
            return;
        }
        let oldChartObj = typeof oldChart === 'string' ? void 0 : oldChart,
            chartOptions = {
                isTracker: chartDataParser.isLineChart(params.collectionPeriods),
                isComparison: !!params.comparisonData && !question.customVariables && !chartDataParser.selfCharting(params.comparisonData, question.respondentDataType),
                selector: oldChart.renderContainer ? oldChart.renderContainer() : oldChart,
                isCustomVariableChart: !!question.customVariable,
            };
        // If we are switching between trackers and bars OR we its initialLoad, createChart is true
        if (shouldUpdateChartData(chartOptions.isTracker)) {
            return myChartService.updateChartData(chartData, question, chartOptions, oldChartObj, options);
        }
        // If it is still the same type of chart. then go through here.
        return myChartService.getChart(chartData, question, chartOptions, oldChartObj, options);
    }

    /**
     * @param isTracker
     * @param chart
     */
    function shouldUpdateChartData(isTracker, chart) {
        return !!chart && chart.isTracker() === isTracker;
    }

    /**
     * @param question
     * @param params
     */
    function getChartData(question, params) {
        if (question && question.respondentDataType) {
            return respondentDataService.getRespondentDataForType($rootScope.survey, question, params);
        }
        if (question && question.customVariable) {
            return $statsService.getVariableTally($rootScope.survey, question.customVariable, params);
        }
        return surveyResultsService.getDataForQuestion(question, params);
    }

    /**
     * @name getDisplayableFilterCompareData
     * @param survey
     * @param {object} params - Filter and compare data
     * @description
Creates a html string with human-readable info on filter and compare.
     */
    function getDisplayableFilterCompareData(survey, params) {
        const PLAIN_TEXT_CLASS = 'formatted-text__plain-text',
            STYLED_TEXT_CLASS = 'formatted-text__styled-text',
            STYLED_TEXT_OR_IMAGE_CLASS = 'formatted-text__styled-text formatted-text__styled-text--images-or-text-group',
            STYLED_QUESTION_CLASS = 'formatted-text__question-wrapper',
            STRING_SPAN = 'span';
        let filterSentence = '',
            hasQuestionAnswerFilter = false,
            questionMap = {},
            filterMap = {},
            isComparison = !!params.comparisonData,
            tallyParams = isComparison ? [params.tallyParams[0]] : params.tallyParams;

        _.forEach(survey._statsConfig.filters.questions.options, function(question) {
            questionMap[question.id] = question;
        });
        _.forEach(tallyParams, function(criteria) {
            filterLogicService.getFilteredObject(filterMap, criteria);
        });

        _.forEach(filterMap, function(selected, id) {
            if (id === filterLogicConstants.criteriaTypes.GENDER) {
                filterSentence += wrapContents(STRING_SPAN, selected[0] + 's', STYLED_TEXT_CLASS);
            }
            else if (id === filterLogicConstants.criteriaTypes.AGE) {
                let ageTargetingAttribute = _.find(survey.targetingReqs, {
                        attr: 'age',
                    }) || {},
                    numTotalAges = (ageTargetingAttribute.details || filterLogicConstants.Demographic$AgeOptions).length,
                    ageContent;
                if (numTotalAges === selected.length) {
                    return;
                }
                ageContent = 'ages ' + targetingAttributeAge.toString({
                    details: selected,
                });
                filterSentence += wrapContents(STRING_SPAN, filterSentence.length ? '' : 'people', PLAIN_TEXT_CLASS) + wrapContents(STRING_SPAN, ageContent, STYLED_TEXT_CLASS);
            }
            else if (id === filterLogicConstants.criteriaTypes.QUESTION) {
                let questionInfo = filterItemService.parseQuestionChoiceString(selected[0]),
                    question = questionMap[questionInfo.questionId],
                    selectedAnswers = [],
                    selectedOptions = {};
                _.forEach(selected, function(choice) {
                    selectedOptions[choice] = true;
                });
                _.forEach(question.options, function(item) {
                    if (!selectedOptions[item.id]) {
                        return;
                    }
                    if (item.image) {
                        selectedAnswers.push('<img src="' + item.image + '" alt="">');
                    }
                    selectedAnswers.push(item.name);
                });
                if (hasQuestionAnswerFilter) {
                    filterSentence += wrapContents(STRING_SPAN, 'and answered', PLAIN_TEXT_CLASS) + wrapContents(STRING_SPAN, joinBy('or', selectedAnswers), STYLED_TEXT_OR_IMAGE_CLASS) + wrapContents(STRING_SPAN, 'to the question', PLAIN_TEXT_CLASS) + '<q class="' + STYLED_QUESTION_CLASS + '">' + wrapContents(STRING_SPAN, question.name, STYLED_TEXT_CLASS) + '</q>';
                }
                else {
                    filterSentence += wrapContents(STRING_SPAN, filterSentence.length ? '' : 'People', PLAIN_TEXT_CLASS) + wrapContents(STRING_SPAN, 'who answered', PLAIN_TEXT_CLASS) + wrapContents(STRING_SPAN, joinBy('or', selectedAnswers), STYLED_TEXT_OR_IMAGE_CLASS) + wrapContents(STRING_SPAN, 'to the question', PLAIN_TEXT_CLASS) + '<q class="' + STYLED_QUESTION_CLASS + '">' + wrapContents(STRING_SPAN, question.name, STYLED_TEXT_CLASS) + '</q>';
                    hasQuestionAnswerFilter = true;
                }
            }
        });
        return filterSentence;
    }

    /**
     * Creates an HTML element with of the given tag type and class names
     *
     * @param  {string} type    - string of tag to build
     * @param  {string} cssClass - space delimited css classes to add to the generated tag
     * @returns {HTMLElement}     HTML element of @param type
     */
    function getHTMLTag(type, cssClass) {
        // Since $document is a jqLite wrapper around document we need to
        // get the 0th element which is the document itself
        var el = $document[0].createElement(type);

        if (cssClass) {
            // Append css classes to any that are there
            el.className += ' ' + cssClass.trim();
        }

        return el;
    }

    /**
     * Wraps text item with given tag type
     *
     * @param  {string} tag      - html tag to wrap the content inside of
     * @param  {string} content  - string that will be wrapped by new html tag
     * @param  {string} cssClass - list of classes space delimited that will be aded to the created html tag
     * @returns {string}          created html string, if no content provided return value is the empty string
     */
    function wrapContents(tag, content, cssClass) {
        if (!content) {
            return '';
        }
        var el = getHTMLTag(tag, cssClass || '');

        // Add content to element as html to preserve any nested elements
        el.innerHTML = content;

        // Return entire element as a string
        return el.outerHTML;
    }

    /**
     * @param operator
     * @param strArray
     */
    function joinBy(operator, strArray) {
        let last = strArray.length - 1,
            res = '';

        if (last < 1) {
            return strArray[0];
        }
        operator = (operator && operator.trim()) || 'or';
        if (last < 2) {
            return strArray[0] + ' ' + operator + ' ' + strArray[1];
        }
        _.forEach(strArray, function(str, idx) {
            if (idx === last) {
                res += operator + ' ' + str;
            }
            else {
                res += str + ', ';
            }
        });
        return res;
    }

    return {
        getChart: getChart,
        getChartDetails: getChartDetails,
        getImageUrl: getImageUrl,
        getChartData: getChartData,
        getDisplayableFilterCompareData: getDisplayableFilterCompareData,
        inViewport: inViewport,
    };
}
