import '@uirouter/angularjs';
import '../app-components/api-account-service';
angular.module('resetPassword.ctrl', ['apiAccountService'])
    .controller('resetPasswordCtrl', resetPasswordCtrl);
resetPasswordCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$location',
    '$stateParams',
    'accountService',
];

function resetPasswordCtrl($scope, $rootScope, $state, $location, $stateParams, accountService) {
    $scope.resetToken = $stateParams.token;
    $scope.formStatus = null;
    $scope.destination = $location.search().dest || '/account';
    $scope.userName = '';
    $scope.phone = '';

    $rootScope.authReady.then(function() {
        if ($state.current.name === 'inviteLanding') {
            if (!$rootScope.auth.user.anonymous) {
                $location.path($scope.destination).search('');
            }
        }
    });

    $scope.resetPassword = function(form) {
        if (form.$invalid) {
            $scope.formStatus = 'invalid';
        }
        else {
            $scope.ux.formStatus = 'inProgress';
            accountService.resetPassword($scope.resetToken, $scope.newPassword, $scope.userName, $scope.phone).then(
                function(response) {
                    if (response.error) {
                        $scope.formStatus = 'error';
                        return;
                    }
                    $scope.formStatus = 'success';
                    accountService.signIn(response.email, $scope.newPassword).then(function(res) {
                        if (!res.error) {
                            $location.path($scope.destination).search('');
                        }
                    });
                },
                function() {
                    $scope.formStatus = 'error';
                }
            );
        }
    };
}
